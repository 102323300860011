import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Card, Col, Row, Typography } from 'antd'
import React from 'react'
import Emoji from 'react-emoji-render'
import { Link } from 'react-router-dom'

import PageTitle from '../../../_components/PageTitle'
import ActionButton from './ActionButton'
import ConfigForm from './ConfigForm'
import ImportButton from './ImportButton'
import { GET_PERSONIO_INTEGRATION } from './queries'
import StatusIndicator from './StatusIndicator'

const { Paragraph, Title } = Typography

const TITLE_STYLE = { fontSize: 16, lineHeight: '28px', marginBottom: 16 }

const ListItem = ({ children }) => {
  return (
    <li>
      <FontAwesomeIcon
        icon={['fas', 'check-circle']}
        style={{ color: '#77779f', fontSize: '20px', marginRight: '6px' }}
      />
      <div style={{ fontSize: 16 }}>{children}</div>
    </li>
  )
}

export default () => {
  const { data: personioIntegrationData, loading: personioIntegrationLoading } =
    useQuery(GET_PERSONIO_INTEGRATION)

  return (
    <div className="integration">
      <Breadcrumb style={{ marginBottom: 8 }}>
        <Breadcrumb.Item>
          <Link to="/admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/admin">Integrations</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Personio</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle style={{ marginBottom: 24 }}>Personio</PageTitle>
      <Row type="flex" align="top">
        <Col span={16}>
          <Paragraph style={{ fontWeight: 300, marginBottom: 32 }}>
            Personio is an HR management platform designed to save time.
            Personio’s customers can now save time setting up and managing
            employee feedback and performance processes with Howamigoing’s
            smooooth integration.
            <Emoji
              text=":cup_with_straw:"
              style={{ fontSize: 20, lineHeight: '24px' }}
            />
          </Paragraph>
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {!personioIntegrationLoading && (
            <ActionButton status={personioIntegrationData.personio.status} />
          )}
        </Col>
      </Row>
      <Title level={4} style={{ marginBottom: 16 }}>
        What does Howamigoing’s Personio integration do?
      </Title>
      <Paragraph style={{ fontWeight: 300, marginBottom: 20 }}>
        Add our totally awesome Personio integration so you can:
      </Paragraph>
      <Row type="flex" align="stretch" style={{ marginBottom: 32 }} gutter={32}>
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Title level={4} style={TITLE_STYLE}>
              Simplify account set-up
            </Title>
            <ul>
              <ListItem>Sync your Personio account</ListItem>
              <ListItem>Pull across employee data</ListItem>
              <ListItem>
                Organise people automatically based on your team and manager
                allocations in Personio
              </ListItem>
            </ul>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Title level={4} style={TITLE_STYLE}>
              Get a discount
            </Title>
            <ul>
              <ListItem>Howamigoing is free for the first 3 months</ListItem>
              <ListItem>
                Personio customers get 20% off{' '}
                <a
                  href="https://howamigoing.com/pricing"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: 'underline' }}
                >
                  Howamigoing's plans
                </a>
              </ListItem>
            </ul>
          </Card>
        </Col>
      </Row>
      <Paragraph style={{ fontWeight: 300, marginBottom: 20 }}>
        Note: Howamigoing syncs data from Personio every 24 hours.
        {personioIntegrationData &&
          personioIntegrationData.personio.status === 'active' && (
            <ImportButton
              type="link"
              size="small"
              style={{
                fontSize: 16,
                background: 'none',
              }}
              disabled={personioIntegrationData.personio.error}
            >
              <span style={{ textDecoration: 'underline' }}>Sync now</span>
            </ImportButton>
          )}
      </Paragraph>
      <Title level={4} style={{ marginBottom: 10 }}>
        Status:
      </Title>
      {!personioIntegrationLoading && (
        <>
          <StatusIndicator
            status={personioIntegrationData.personio.status}
            error={personioIntegrationData.personio.error}
          />
          {personioIntegrationData.personio.status === 'active' && (
            <div style={{ marginTop: 24 }}>
              <Title level={4} style={{ marginBottom: 10 }}>
                Options:
              </Title>
              <ConfigForm
                inviteNewUsers={
                  personioIntegrationData.personio.config.inviteNewUsers
                }
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
