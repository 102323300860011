import moment from 'moment'

export const isScheduledCycle = startDate => {
  if (!startDate) return null
  const localStartDate = moment.isMoment(startDate)
    ? startDate
    : moment(startDate * 1000)
  return localStartDate.isAfter(moment.now())
}

export const isCompletedCycle = endDate => {
  if (!endDate) return null
  const localEndDate = moment.isMoment(endDate)
    ? endDate
    : moment(endDate * 1000)
  return localEndDate.isBefore(moment.now())
}
