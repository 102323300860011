import './styles.less'

import { Collapse, Icon } from 'antd'
import React from 'react'

// import ChevronCircleDown from '../_assets/img/dashboard/chevron-circle-down.svg'
import Dots from '../_assets/img/dashboard/dots.svg'

const panelStyle = {
  border: 0,
}

const iconContainerStyle = {
  backgroundColor: 'rgba(172,172,203,0.4)',
  borderRadius: '10px',
  height: 20,
  left: '0',
  lineHeight: '20px',
  top: '12px',
  width: 20,
}

const titleStyle = {
  background: 'none',
  color: '#77779f',
  fontFamily: 'europa, serif',
  fontSize: '18px',
  fontWeight: '500',
  lineHeight: '24px',
}

export default props => {
  const title = props.title

  const collapseProps = props.collapseProps || {}
  const panelProps = props.panelProps || {}

  const children = props.children

  return (
    <Collapse
      defaultActiveKey="default"
      bordered={false}
      expandIcon={({ isActive }) => (
        <div style={iconContainerStyle}>
          <Icon
            type="down"
            rotate={isActive ? 180 : 0}
            style={{ color: '#77779f', fontWeight: '700' }}
          />
        </div>
      )}
      expandIconPosition="left"
      className="dashboard-section"
      {...collapseProps}
    >
      <Collapse.Panel
        header={
          <div style={titleStyle}>
            {title}
            <img
              src={Dots}
              alt=""
              style={{
                marginLeft: '8px',
                position: 'relative',
                top: '-2px',
                width: '30px',
              }}
            />
          </div>
        }
        style={panelStyle}
        key={'default'}
        {...panelProps}
      >
        {children}
      </Collapse.Panel>
    </Collapse>
  )
}
