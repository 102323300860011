import { useEffect, useRef } from 'react'

const useMobileOptimized = shouldOptimize => {
  const viewportMeta = useRef(document.getElementById('viewport-meta'))

  const enableMobileOptimization = () => {
    return viewportMeta.current.setAttribute(
      'content',
      'width=device-width, initial-scale=1, shrink-to-fit=no',
    )
  }

  const enablePinchAndZoom = () => {
    return viewportMeta.current.setAttribute(
      'content',
      'initial-scale=0, shrink-to-fit=no',
    )
  }

  useEffect(() => {
    shouldOptimize ? enableMobileOptimization() : enablePinchAndZoom()
  }, [shouldOptimize])

  return shouldOptimize
}

export default useMobileOptimized
