import { Spin } from 'antd'
import React from 'react'

import HelpIcon from '../../_components/HelpIcon'

export default ({ number, unit, tooltip, loading }) => {
  return (
    <div className="insights__card">
      <div className="insights__card-number">{loading ? <Spin /> : number}</div>
      <div className="insights__card-unit">{unit}</div>
      {tooltip && (
        <span className="insights__card-tooltip">
          <HelpIcon tooltip={tooltip} />
        </span>
      )}
    </div>
  )
}
