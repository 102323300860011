import React from 'react'

export const context = {
  id: null,

  name: '',

  startDate: null,
  endDate: null,
  timezone: null,

  enrolledUsers: [],
  requestedUsers: [],
  selectedColleagues: [],

  selection: {
    teams: {},
    users: {},
  },

  minRequests: 2,
  maxRequests: null,

  form: null,

  totalNumberColleagues: null,

  setContext: details => {},
}

export default React.createContext()
