import { AvatarName } from '../../../_components/Avatar'
import React from 'react'
import Timestamp from '../Timestamp'

const Meta = ({ sender, recipient, sentAt }) => {
  return (
    <div className="good-vibe-card__meta-container">
      <div className="good-vibe-card__meta-value">
        <AvatarName user={sender}></AvatarName>{' '}
        <span style={{ color: '#1c1047' }}>sent Good vibes to</span>{' '}
        <AvatarName user={recipient}></AvatarName>
      </div>
      <div
        className="good-vibe-card__meta-value"
        style={{ marginLeft: 'auto' }}
      >
        <Timestamp date={sentAt} />
      </div>
    </div>
  )
}

export default Meta
