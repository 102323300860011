import { useMutation } from '@apollo/react-hooks'
import { Menu, notification } from 'antd'
import React, { useContext, useState } from 'react'

import HelpIcon from '../../../_components/HelpIcon'
import useSendInviteGQL from '../../../hooks/graphql/Users/useSendInviteGQL'
import { UserContext } from '../../../UserContext'
import EditUserModal from '../EditUserModal'
import RemoveUserModal from '../RemoveUserModal'
import { ACTIVATE_USER } from './queries'
import DeactivateUserModal from '../DeactivateUserModal';

export default ({ user, teams, onModalSubmit, onMenuClick = () => { } }) => {
  const [editUserModalVisible, setEditUserModalVisible] = useState(false)
  // we don't need to mirror the user here, because we already have the object
  //   received as a prop and it's updated in this component as soon as it changes
  // const [selectedUser, setSelectedUser] = useState(false)
  const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false)
  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false)

  const [resendInviteMutation] = useSendInviteGQL({
    refetchQueries: ['listPeopleUsers', 'peopleListStats'],
  })
  const [activateUser] = useMutation(ACTIVATE_USER, {
    refetchQueries: ['listPeopleUsers', 'peopleListStats'],
  })

  const currentUser = useContext(UserContext)

  const handleClick = (e, aUser) => {
    switch (e.key) {
      case 'edit':
        editColleague(aUser)
        break
      case 'resendInvite':
        resendInvite(aUser)
        break
      case 'activate':
        activateUser({
          variables: {
            id: aUser.id,
          },
        })
        break
      case 'deactivate':
        setDeactivateModalVisible(true)
        break
      case 'remove':
        setRemoveUserModalVisible(true)
        break
      default:
        return false
    }
    onMenuClick()
  }

  const editColleague = () => {
    setEditUserModalVisible(true)
  }

  const resendInvite = async toUser => {
    await resendInviteMutation({
      variables: {
        userIds: [toUser.id],
      },
    })

    notification.success({
      message: 'Invite sent!',
      description: 'The new invite was just sent!',
    })
  }

  const onEditUserCancel = () => setEditUserModalVisible(false)

  const onEditUserOk = values => {
    return onModalSubmit(values).then(() => {
      setEditUserModalVisible(false)
      currentUser.refetchUser()
    })
  }

  const onRemoveUserOk = () => setRemoveUserModalVisible(false)
  const onRemoveUserCancel = () => setRemoveUserModalVisible(false)
  const onDeactivateUserOkCancel = () => setDeactivateModalVisible(false)

  const canChangeOwner = currentUser.isOwner()
  const isCurrentUser = user.id === currentUser.id
  const canChangeStatus =
    !user.readonlyProperties ||
    !user.readonlyProperties.find(x => x.name === 'status')

  return (
    <>
      <Menu onClick={e => handleClick(e, user)}>
        <Menu.Item key="edit">Edit</Menu.Item>
        {(user.type === 'created' || user.type === 'invited') && (
          <Menu.Item key="resendInvite">
            {user.type === 'created' ? 'Send' : 'Resend'} invite
          </Menu.Item>
        )}
        {user.type !== 'deactivated' && !isCurrentUser && (
          <Menu.Item key="deactivate" disabled={!canChangeStatus}>
            <HelpIcon
              tooltip={
                !canChangeStatus ? 'Must be deactivated from Personio' : null
              }
            >
              Deactivate
            </HelpIcon>
          </Menu.Item>
        )}
        {user.type === 'deactivated' && !isCurrentUser && (
          <Menu.Item key="activate" disabled={!canChangeStatus}>
            <HelpIcon
              tooltip={
                !canChangeStatus ? 'Must be activated from Personio' : null
              }
            >
              Activate
            </HelpIcon>
          </Menu.Item>
        )}
        {!isCurrentUser &&
          !user.role !== 'OWNER' && (
            <Menu.Item key="remove" disabled={!canChangeStatus}>
              <HelpIcon
                tooltip={
                  !canChangeStatus ? 'Must be removed from Personio' : null
                }
              >
                Delete
              </HelpIcon>
            </Menu.Item>
          )}
      </Menu>
      {editUserModalVisible && (
        <EditUserModal
          user={user}
          teams={teams}
          visible={editUserModalVisible}
          onCancel={onEditUserCancel}
          onOk={onEditUserOk}
          canChangeOwner={canChangeOwner}
        />
      )}
      <RemoveUserModal
        user={user}
        visible={removeUserModalVisible}
        onOk={onRemoveUserOk}
        onCancel={onRemoveUserCancel}
      />
      <DeactivateUserModal
        user={user}
        visible={deactivateModalVisible}
        onOk={onDeactivateUserOkCancel}
        onCancel={onDeactivateUserOkCancel}
      />
    </>
  )
}
