import { Avatar as AntAvatar, Popover, Tooltip } from 'antd'
import React from 'react'

const DEFAULT_SPACING = 8

const AvatarName = ({ user, style }) => {
  if (!user) {
    return <span></span>
  }

  if (user.status === 'DEIDENTIFIED') {
    return <span style={style}>Removed user</span>
  }

  if (user.firstName && user.lastName) {
    return (
      <span style={style}>
        {user.firstName} {user.lastName}
      </span>
    )
  }

  if (user.firstName && !user.lastName) {
    return <span style={style}>{user.firstName}</span>
  }

  if (!user.firstName && user.lastName) {
    return <span style={style}>{user.lastName}</span>
  }

  if (!user.firstName && !user.lastName) {
    return <span style={style}>{user.email}</span>
  }
}

const AvatarJobTitle = ({ user, style }) => {
  if (!user || !user.jobTitle) {
    return <span></span>
  }
  if (user.jobTitle) {
    return <span style={style}>{user.jobTitle}</span>
  }
}

const Avatar = ({
  user,
  size = 'base',
  withRightMargin,
  withBottomMargin,
  disableTooltip = false,
  fallbackText,
  style,
}) => {
  if (!user) {
    return <span></span>
  }

  const elementStyle = {
    ...style,
    display: 'inline-block',
    marginRight: withRightMargin ? DEFAULT_SPACING : null,
    marginBottom: withBottomMargin ? DEFAULT_SPACING : null,
  }

  if (!user.avatarUrl) {
    let avatarLetters = ''
    let fieldToRender = 'fullname'

    if (fallbackText) {
      avatarLetters = fallbackText
    } else if (user.status === 'DEIDENTIFIED') {
      avatarLetters = '?'
    } else if (user.firstName && user.lastName) {
      avatarLetters =
        user.firstName[0].toUpperCase() + user.lastName[0].toUpperCase()
    } else if (user.firstName && !user.lastName) {
      avatarLetters = user.firstName[0].toUpperCase()
    } else if (!user.firstName && user.lastName) {
      avatarLetters = user.lastName[0].toUpperCase()
    } else {
      // this user only has their email set
      fieldToRender = 'email'
      const emailBeforeAt = user.email.split('@')[0]
      if (emailBeforeAt.length < 3) {
        avatarLetters = emailBeforeAt.toLowerCase()
      } else {
        avatarLetters = emailBeforeAt.slice(0, 2).toLowerCase()
      }
    }

    const tooltipContent = (() => {
      if (fieldToRender === 'fullname') {
        return user.status === 'DEIDENTIFIED'
          ? 'Removed user'
          : user.firstName + ' ' + user.lastName
      }
      return user.status === 'DEIDENTIFIED' ? 'Removed' : user.email
    })()

    if (disableTooltip === true) {
      return (
        <AntAvatar size={size} style={elementStyle}>
          {avatarLetters}
        </AntAvatar>
      )
    } else {
      return (
        <Tooltip title={<span>{tooltipContent}</span>}>
          <AntAvatar size={size} style={elementStyle}>
            {avatarLetters}
          </AntAvatar>
        </Tooltip>
      )
    }
  }

  return disableTooltip === true ? (
    <AntAvatar size={size} style={elementStyle} src={user.avatarUrl} />
  ) : (
    <Tooltip title={<AvatarName user={user} />}>
      <AntAvatar size={size} style={elementStyle} src={user.avatarUrl} />
    </Tooltip>
  )
}

const AvatarPlus = ({
  users,
  content,
  withRightMargin,
  withBottomMargin,
  size = 'base',
  style = {},
}) => {
  const avatar = (
    <AntAvatar
      size={size}
      style={{
        display: 'inline-block',
        marginRight: withRightMargin ? DEFAULT_SPACING : null,
        marginBottom: withBottomMargin ? DEFAULT_SPACING : null,
        ...style,
      }}
    >
      +{content}
    </AntAvatar>
  )

  return users && users.length > 0 ? (
    <Popover
      content={
        <div style={{ maxHeight: 320, overflow: 'auto' }}>
          {users.map(user => (
            <p key={user.id}>
              <AvatarName user={user} />
            </p>
          ))}
        </div>
      }
    >
      {avatar}
    </Popover>
  ) : (
    { avatar }
  )
}

export { Avatar, AvatarPlus, AvatarName, AvatarJobTitle }

export default Avatar
