import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useState } from 'react'

import UserDisplayName from '../../components/UserDisplayName'
import { GET_TEAMS_LIST } from '../queries'
import { GET_USERS_WITHOUT_A_TEAM } from '../TeamsList/queries'
import { REMOVE_USER_FROM_TEAM } from './queries'

export default ({
  user,
  teamId,
  teamName,
  visible,
  onOk,
  onError,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false)
  const [removeUserFromTeam] = useMutation(REMOVE_USER_FROM_TEAM, {
    refetchQueries: [
      {
        query: GET_TEAMS_LIST,
      },
      {
        query: GET_USERS_WITHOUT_A_TEAM,
      },
    ],
  })

  const handleSubmit = async evt => {
    evt.stopPropagation()
    try {
      setLoading(true)
      await removeUserFromTeam({
        variables: {
          userId: user.id,
          teamId,
        },
      })
      setLoading(false)
      if (onOk) {
        onOk()
      }
    } catch (e) {
      setLoading(false)
      if (onError) {
        onError(e)
      }
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        title="Remove user from team"
        visible={visible}
        onCancel={onCancel}
        onOk={handleSubmit}
        confirmLoading={loading}
        okText="Remove"
        cancelText="Cancel"
        cancelButtonProps={{ disabled: loading, type: 'ghost' }}
        closable={false}
        maskClosable={!loading}
        bodyStyle={{ padding: '32px' }}
        centered
      >
        <div style={{ fontWeight: 300 }}>
          Are you sure you want to remove{' '}
          <span style={{ fontWeight: 500 }}>
            <UserDisplayName user={user} />
          </span>{' '}
          from the <span style={{ fontWeight: 500 }}>{teamName}</span> team?
        </div>
      </Modal>
    </div>
  )
}
