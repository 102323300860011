import { Button, Card, Icon, Typography } from 'antd'
import React, { useCallback, useContext, useState } from 'react'

import BackButton from '../../_components/BackButton'
import { UserContext } from '../../UserContext'
import ColleagueList from './ColleagueList'
import PreviewForm from './PreviewForm'

const { Paragraph, Text, Title } = Typography

const DEFAULT_SPACING = 8

const ManagerSelectedTooltip = () => (
  <span className="small-letter">
    Your manager is
    <br />
    automatically included
    <br />
    in a 360 feedback event by default.
  </span>
)

const AlreadyGaveFeedbackTooltip = () => (
  <span className="small-letter">
    Your colleague
    <br />
    already gave you
    <br />
    feedback
  </span>
)

const RequestColleagues = ({
  visible = true,
  onSubmit,
  minRequests,
  maxRequests,
  modalForm,
  requests,
  mustAskUsers = [],
}) => {
  const currentUser = useContext(UserContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const alreadyResponded = requests.reduce((acc, req) => {
    if (req.respondedAt) {
      acc[req.askedTo.id] = { id: req.askedTo.id, teamId: req.askedTo.team ? req.askedTo.team.id : 'no-team' }
    }
    return acc
  }, {})
  const alreadyAsked = requests.reduce((acc, req) => {
    acc[req.askedTo.id] = { id: req.askedTo.id, teamId: req.askedTo.team ? req.askedTo.team.id : 'no-team' }
    return acc
  }, {})
  const mustAsk = mustAskUsers.reduce((acc, u) => {
    acc[u.id] = { id: u.id, teamId: u.team ? u.team.id : 'no-team' }
    return acc
  }, {})

  const [selection, setSelection] = useState(
    (() => {
      const disabled = {
        ...alreadyResponded,
        ...mustAsk
      }
      const checked = {
        ...alreadyAsked,
        ...alreadyResponded,
        ...mustAsk
      }
      return {
        users: checked,
        disabled,
        total: requests.length,
      }
    })(),
  )

  const handleSelect = useCallback(selection => {
    setSelection(selection)
  }, [])

  const excludeUserIds = [currentUser.id]

  const renderTooltip = entity => {
    if (!entity) {
      return
    }
    if (!entity.user) {
      return
    }
    const { user } = entity
    if (mustAsk[user.id]) {
      return <ManagerSelectedTooltip />
    }
    if (alreadyResponded[user.id]) {
      return <AlreadyGaveFeedbackTooltip />
    }
    return null
  }

  if (!visible) return null

  return (
    <div className="get-feedback-request">
      <Card className="top-title" style={{ marginBottom: DEFAULT_SPACING * 3 }}>
        <Text
          style={{
            fontFamily: 'museo, serif',
            color: '#1c1047',
            fontSize: '18px',
          }}
        >
          Ask colleagues for feedback
        </Text>
      </Card>

      <Card
        className="top-title"
        style={{ cursor: 'pointer', marginBottom: DEFAULT_SPACING * 3 }}
        onClick={() => setModalVisible(true)}
      >
        <Text
          style={{
            fontFamily: 'Europa, serif',
            color: '#f95c4b',
            fontSize: '16px',
          }}
        >
          <Icon type="eye" style={{ marginRight: DEFAULT_SPACING }} />
          Click here to preview the feedback questions that you'll be getting
          answers on.
        </Text>
      </Card>

      <PreviewForm
        form={modalForm}
        modalVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSelect={false}
      />

      <Title
        level={4}
        style={{
          color: '#1c1047',
          marginBottom: DEFAULT_SPACING,
        }}
      >
        Who would you like to receive feedback from?
      </Title>

      <Paragraph style={{ fontWeight: 300 }}>
        You need to ask a minimum of {minRequests} and a maximum of{' '}
        {maxRequests} people for feedback. Your manager is automatically
        included in a 360 feedback event by default.
      </Paragraph>

      <ColleagueList
        height={400}
        withSearch
        withSelectAll
        excludeUserIds={excludeUserIds}
        selection={selection}
        onSelect={handleSelect}
        renderTooltip={renderTooltip}
      />

      <div style={{ textAlign: 'right', marginTop: 32 }}>
        <BackButton />
        <Button
          type="primary"
          loading={isSubmitting}
          onClick={() => {
            setIsSubmitting(true)
            onSubmit(selection)
          }}
          disabled={
            selection.total < minRequests || selection.total > maxRequests
          }
        >
          {selection.total > 0 ? 'Update' : 'Send' } request
        </Button>
      </div>
    </div>
  )
}

export default RequestColleagues
