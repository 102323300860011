import gql from 'graphql-tag'

export const USER_PRIVATE_FEEDBACK = gql`
  query userPrivateFeedbackInsight(
    $userId: String!
    $startDate: String
    $endDate: String
  ) {
    userPrivateFeedbackInsight(
      userId: $userId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      key
      value
    }
  }
`
