import { Modal, Progress } from 'antd'
import React, { useState, useEffect } from 'react'

export default ({ showDownloadingModal }) => {

  const [downloadProgress, setDownloadProgress] = useState(0);

  useEffect(() => {
    let aux = 0;
    setDownloadProgress(0);
    const progressInterval = setInterval(() => {
      if (aux < 100) {
        setDownloadProgress(oldProgress => (oldProgress + 10));
        aux += 10;
      } else {
        clearInterval(progressInterval);
      }
    }, 1000);

    if (!showDownloadingModal) {
      setDownloadProgress(100);
      clearInterval(progressInterval);
    }

  }, [showDownloadingModal])

  return (
    <Modal
      visible={showDownloadingModal}
      closable={false}
      bodyStyle={{ padding: '32px 32px 8px', width: "480" }}
      centered
      footer={null}
    >
      <p style={{ color: "#281765", fontSize: "16px", lineHeight: "1.5" }}>
        Your CSV is being generated.
        Due to the volume of feedback, it may take a few minutes to process.
        Please sit tight.
      </p>
      <Progress percent={downloadProgress} strokeColor={"#77779f"} showInfo={false} />
    </Modal>
  )
}