import gql from 'graphql-tag'

export const COMPANY_360_FEEDBACK = gql`
  query companyThreeSixtyFeedbackInsight($startDate: String, $endDate: String) {
    companyThreeSixtyFeedbackInsight(startDate: $startDate, endDate: $endDate) {
      id
      key
      value
    }
  }
`
