import './top-menu.less'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Menu } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link, matchPath } from 'react-router-dom'

import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import { CYCLE_TYPES } from '../../pages/helpers'

export default ({ pathname, routes, type }) => {
  const user = useContext(UserContext)
  const [canSeeCreateCycle, setCanSeeCreateCycle] = useState(false)

  useEffect(() => {
    if (type === CYCLE_TYPES.threeSixty) {
      if (user.isAdmin() || user.isOwner()) {
        setCanSeeCreateCycle(true)
      }
    } else {
      setCanSeeCreateCycle(true)
    }
  }, [type])

  const selectedRoute = routes.routes.find(route =>
    matchPath(pathname, {
      path: route.path,
    }),
  )

  const selectedKey = selectedRoute ? selectedRoute.menuKey : null

  return (
    <div>
      <Menu className="top-menu" mode="horizontal" selectedKeys={[selectedKey]}>
        <Menu.Item key="ongoing">
          <Link to={routes.path + '/ongoing'}>In progress</Link>
        </Menu.Item>

        {canSeeCreateCycle && (
          <Menu.Item key="scheduled">
            <Link to={routes.path + '/scheduled'}>Coming up</Link>
          </Menu.Item>
        )}

        <Menu.Item key="completed">
          <Link to={routes.path + '/completed'}>Completed</Link>
        </Menu.Item>

        {canSeeCreateCycle && (
          <Menu.Item
            className="menu-item-with-button"
            key="create"
            style={{ float: 'right' }}
          >
            <Link
              to={routes.path + '/create/1'}
              onClick={() => {
                track(routes.path.substring(1) + '.cycle.createButton', {
                  user: user.email,
                  org: user.org.name,
                })
              }}
            >
              <Button type="primary" style={{ marginLeft: 'auto' }}>
                <FontAwesomeIcon
                  icon={['fal', 'plus']}
                  style={{ width: '1em', marginRight: 8 }}
                />
                {type === CYCLE_TYPES.personal && 'Request Private feedback'}
                {type === CYCLE_TYPES.threeSixty && 'Create new event'}
                {type === CYCLE_TYPES.pulse && 'Create Pulse survey'}
              </Button>
            </Link>
          </Menu.Item>
        )}
      </Menu>
    </div>
  )
}
