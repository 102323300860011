import gql from 'graphql-tag'

export const UPDATE_PERSONIO_INTEGRATION_CONFIG = gql`
  mutation updatePersonioIntegrationConfig($inviteNewUsers: Boolean!) {
    updatePersonioIntegrationConfig(inviteNewUsers: $inviteNewUsers) {
      status
      config {
        inviteNewUsers
        assignSupervisors
        onDeleteInPersonio
      }
    }
  }
`
