import { Button } from 'antd'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import AvatarPlusList from '../../../../_components/AvatarPlusList'
import { track } from '../../../../_helpers/analytics'
import { UserContext } from '../../../../UserContext'
import Overview from '../../../modals/Overview/team360'
import { isCompletedCycle, isScheduledCycle } from '../../helpers'

export default ({ cycle }) => {
  const location = useLocation()
  const user = useContext(UserContext)
  const [showModal, setShowModal] = useState(false)

  const isCompletedCycleCheck = isCompletedCycle(cycle.endDate)
  const isScheduledCycleCheck = isScheduledCycle(cycle.startDate)

  const enrolled =
    !cycle.managerReviews || user.isOwner() || user.isAdmin()
      ? cycle.enrolled
      : cycle.managerReviews

  const feedbackRequesters = enrolled.filter(x =>
    !isCompletedCycleCheck ? true : x.requests && x.requests.length > 0,
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '24px',
      }}
    >
      <div>
        <div
          style={{
            marginBottom: 16,
            color: '#77779f',
          }}
        >
          {isCompletedCycleCheck ? 'Event complete' : 'Event progress'}
        </div>
        <AvatarPlusList users={feedbackRequesters} limit={6} />
        <div
          style={{
            color: '#1c1047',
            fontFamily: 'museo',
            fontWeight: 300,
            marginTop: 4,
          }}
        >
          {feedbackRequesters.length} people{' '}
          {isCompletedCycleCheck ? 'gathered' : 'gathering'} feedback
        </div>
      </div>

      {isScheduledCycleCheck ? (
        <div style={{ color: '#77779f', fontWeight: 300 }}>
          Event progress data will be available to
          <br />
          you starting{' '}
          {moment
            .unix(cycle.startDate)
            .tz(cycle.timeZone)
            .format('DD MMM YYYY')}
        </div>
      ) : (
        <div>
          <Button
            type="light-purple"
            onClick={() => {
              if (!isCompletedCycleCheck && location.pathname === '/') {
                track('team360.cycle.progressDashboard', {
                  user: user.email,
                  org: user.org.name,
                  eventId: cycle.id,
                })
              } else if (isCompletedCycle) {
                track('team360.cycle.report', {
                  user: user.email,
                  org: user.org.name,
                  eventId: cycle.id,
                })
              }
              setShowModal(true)
            }}
          >
            {isCompletedCycleCheck ? 'View status' : 'View progress'}
          </Button>
          {showModal && (
            <Overview
              cycleId={cycle.id}
              showModal={showModal}
              handleCloseModal={() => setShowModal(false)}
            />
          )}
        </div>
      )}
    </div>
  )
}
