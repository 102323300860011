import './style.less'

import { Form } from 'antd'
import React, { useContext } from 'react'

import AutocompleteUser from '../../../_components/Form/AutocompleteUser'
import TextAreaFormItem from '../../../_components/Form/TextAreaFormItem'
import { UserContext } from '../../../UserContext'

const SendGoodVibeForm = ({
  defaultValues = {
    id: null,
    recipient: null,
    fact: null,
    feeling: null,
    lastSyncedAt: null,
  },
  form,
}) => {
  const currentUser = useContext(UserContext)
  const { getFieldDecorator, getFieldValue } = form

  const isCurrentUser = user => user.id === currentUser.id

  const recipient = getFieldValue('recipient') || defaultValues.recipient

  return (
    <Form
      layout="vertical"
      hideRequiredMark={true}
      colon={false}
      className="send-good-vibe-form"
    >
      <Form.Item label="Who do you want to send Good vibes to?">
        {getFieldDecorator('recipient', {
          initialValue: recipient,
          rules: [
            {
              required: true,
              message: 'Hold up! You need to say something here...',
            },
          ],
        })(
          <AutocompleteUser
            placeholder="Search for a colleague by name"
            exclusionRule={isCurrentUser}
          />,
        )}
      </Form.Item>
      <div style={{ position: 'relative' }}>
        <TextAreaFormItem
          max={280}
          min={2}
          autoSize={{ minRows: 3, maxRows: 4 }}
          required={true}
          form={form}
          name="fact"
          label="Fact"
          description="What happened and prompted this message?"
          placeholder="Eg. Your Linked ads campaign generated more than 50 leads in the last 7 days."
          initialValue={getFieldValue('fact') || defaultValues.fact}
        />
      </div>

      <div style={{ position: 'relative' }}>
        <TextAreaFormItem
          max={280}
          min={2}
          autoSize={{ minRows: 3, maxRows: 6 }}
          required={true}
          form={form}
          name="feeling"
          label="Feeling"
          description="What's your subjective view on why it delivered a positive outcome and deserves positive reinforcement?"
          placeholder="Eg. We're really lucky to have you on our team! It's impressive how you came up with such a successful campaign in your second month of work here."
          initialValue={getFieldValue('feeling') || defaultValues.feeling}
        />
      </div>
    </Form>
  )
}

export default Form.create({ name: 'SendGoodVibeForm' })(SendGoodVibeForm)
