export default ({ min, message }) => {
  return (rule, value, callback) => {
    if (!value) {
      return callback()
    }
    if (value.trim().length < min) {
      callback(message ? message : `Must be at least ${min} characters`)
    }
    return callback()
  }
}
