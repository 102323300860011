import { useMutation } from '@apollo/react-hooks'
import { Modal, notification } from 'antd'
import React, { useState } from 'react'

import { DEACTIVATE_USER } from './queries'

const DeactivateUserModal = ({ user, visible, onOk, onCancel }) => {
  const [loading, setLoading] = useState(false)

  const [deactivateUser] = useMutation(DEACTIVATE_USER, {
    refetchQueries: ['listPeopleUsers', 'peopleListStats'],
  })

  const handleOnOk = async () => {
    try {
      setLoading(true)
      await deactivateUser({
        variables: {
          id: user.id,
        },
      })
      notification.success({
        message: 'Success!',
        description: `${user.firstName || user.email} has been deactivate`,
      })

      if (onOk) {
        onOk()
      }
    } catch (e) {
      setLoading(false)
      notification.error({
        message: 'Oops!',
        description:
          'Something went wrong. Please contact us if you need some help.',
      })
    }
  }

  return (
    <Modal
      visible={visible}
      title={`Deactivate ${user.displayName}`}
      onCancel={onCancel}
      onOk={handleOnOk}
      okButtonProps={{ type: 'accent' }}
      confirmLoading={loading}
      okText="Deactivate"
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      width={656}
      destroyOnClose={true}
      centered
    >
      <p style={{ fontWeight: 300 }}>
        If deactivated, {user.displayName} won't be able to log in anymore.
        Though, their data will be kept and can be restored at a push of a button.
      </p>
    </Modal>
  )
}

export default DeactivateUserModal
