import gql from 'graphql-tag'

export const GET_REVIEW = gql`
  query getReview($reviewId: String!) {
    review(id: $reviewId) {
      id
      cycle {
        id
        settings
        form {
          id
          title
          recipients
          description
          sections
          formQuestions {
            id
            question {
              id
              titleVariants
              description
              template {
                id
                type
                settings
              }
            }
          }
        }
      }
      feedbacks {
        id
        submittedAt
        user {
          id
          firstName
          lastName
          email
          avatarUrl
          avatar
          status
        }
      }
    }
  }
`

export const FEEDBACK_REQUESTS = gql`
  query threeSixtyFeedbackRequests($reviewId: String!) {
    threeSixtyFeedbackRequests(id: $reviewId)
  }
`

export const UPDATE_FEEDBACK_REQUESTS = gql`
  mutation askForThreeSixtyFeedback(
    $cycleId: ID!
    $selection: UserSelection!
  ) {
    askForThreeSixtyFeedback(id: $cycleId, userSelection: $selection) {
      id
    }
  }
`
