import React, { useContext, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import PageTitle from '../_components/PageTitle'
import { UserContext } from '../UserContext'
import Testimonials from './Testimonials'
import Todos from './Todos'

export default () => {
  const currentUser = useContext(UserContext)

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (location.search === '?onboarded=1') {
      history.replace(window.location.pathname)
      window.hj && window.hj('trigger', 'onboarded-owner-comes-home')
    }
  }, [])

  return (
    <div className="dashboard">
      <PageTitle>Hola, {currentUser.firstName}!</PageTitle>

      <Testimonials />

      <Todos />
    </div>
  )
}
