import './style.less'

import React, { useEffect, useState } from 'react'

import Bar from './Bar'
import { CHECK_PASSWORD_STRENGTH } from './queries'
import { Input } from 'antd'
import randomMinMaxInteger from '../../../_helpers/randomMinMaxInteger'
import { useDebouncedCallback } from 'use-debounce/lib'
import { useLazyQuery } from '@apollo/react-hooks'

const scale = [
  { label: 'Weak', value: 'weak', color: '#e01c1c' },
  { label: 'Medium', value: 'medium', color: '#ffc021' },
  { label: 'Strong', value: 'strong', color: '#47d764' },
]

const messages = ['Calculating...']

const Component = ({ value, onChange, ...props }) => {
  // State
  const [fieldValue, setFieldValue] = useState(value ? value.value : '')
  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('Calculating...')

  // Queries / mutations
  const [fetchPasswordStrength, { data }] = useLazyQuery(
    CHECK_PASSWORD_STRENGTH,
  )

  // Side effects
  useEffect(() => {
    if (!data) {
      return
    }
    setLoading(false)
    emitChange({
      value: fieldValue,
      strength: data.strength,
    })
  }, [data])

  useEffect(() => {
    if (!loading) {
      return
    }
    const index = randomMinMaxInteger(0, messages.length - 1)
    setLoadingMessage(messages[index])
  }, [loading])

  useEffect(() => {
    setFieldValue(value ? value.value : '')
  }, [value])

  // Other hooks
  const [debouncedFetchPasswordStrength] = useDebouncedCallback(
    ({ password }) =>
      fetchPasswordStrength({
        variables: {
          password,
        },
      }),
    500,
  )

  const checkPasswordStrength = async ({ password }) => {
    setLoading(true)
    await debouncedFetchPasswordStrength({ password })
  }

  const handleOnChange = async evt => {
    const inputValue = evt.currentTarget.value
    if (!inputValue) {
      setFieldValue('')
      emitChange({ value: inputValue, strength: undefined })
      return
    }
    setFieldValue(inputValue)
    await checkPasswordStrength({ password: inputValue })
  }

  const emitChange = ({ value, strength }) => {
    if (!onChange) {
      return
    }
    onChange({
      value,
      strength,
    })
  }

  const strengthResponse = data ? data.checkPasswordStrength : undefined
  const strength =
    strengthResponse && fieldValue ? strengthResponse.strength : undefined
  const currentScale = strength
    ? scale.find(x => x.value === strength)
    : undefined
  const reason =
    strengthResponse && fieldValue
      ? strength === 'strong'
        ? 'Green light. Nice work!'
        : strengthResponse.reason
      : undefined
  const suggestions =
    strengthResponse && fieldValue ? strengthResponse.suggestions : []

  return (
    <div className="password-strength-input__container">
      <Input.Password onChange={handleOnChange} value={fieldValue} {...props} />
      {fieldValue.length > 0 && (
        <>
          <p className="password-strength-input__strength-value">
            Password strength:{' '}
            <strong>
              {currentScale && !loading ? currentScale.label : loadingMessage}
            </strong>
          </p>
          <Bar
            scale={scale}
            value={
              strengthResponse && !loading
                ? strengthResponse.strength
                : undefined
            }
          />
          {!loading && reason && (
            <p className="password-strength-input__hint">{reason}</p>
          )}
          {!loading && suggestions && suggestions.length ? (
            <ul className="password-strength-input__suggestions">
              {suggestions.map(x => (
                <li>{x}</li>
              ))}
            </ul>
          ) : null}
        </>
      )}
    </div>
  )
}

export default Component
