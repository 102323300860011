import gql from 'graphql-tag'

export const SEARCH_USERS = gql`
  query($limit: Int, $search: UserSearchInput) {
    users(offset: 0, limit: $limit, search: $search) {
      total
      users {
        id
        status
        firstName
        lastName
        displayName
        email
        emailStatus
        jobTitle
        avatarUrl
        role
        readonlyProperties
        manager {
          id
          firstName
          lastName
          email
          displayName
        }
        viewingManagers {
          id
          firstName
          lastName
          email
        }
        directReports {
          id
          firstName
          lastName
        }
        type
        isOwner
        isAdmin
        team {
          id
          name
          members {
            id
            user {
              id
              firstName
              lastName
              email
              status
            }
          }
        }
      }
    }
  }
`
