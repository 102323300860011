import { useApolloClient, useQuery } from '@apollo/react-hooks'
import { useEffect } from 'react'

import startsWith from '../../../_helpers/startsWith'
import { GET_TEAM, GET_USERS_WITHOUT_A_TEAM } from './queries'

const useTeam = id => {
  const client = useApolloClient()

  if (!id) {
    const noTeamQuery = useQuery(GET_USERS_WITHOUT_A_TEAM, {
      fetchPolicy: 'cache-network',
    })
    return {
      ...noTeamQuery,
      data: noTeamQuery.data
        ? {
            team: noTeamQuery.data.noTeam.users.reduce(
              (memo, x) => {
                memo.members.push({
                  id: `TeamMember${x.id}`,
                  user: {
                    ...x,
                  },
                })
                return memo
              },
              {
                name: 'Not part of a team',
                members: [],
              },
            ),
          }
        : undefined,
    }
  }

  useEffect(() => {
    return () => {
      Object.keys(client.cache.data.data).forEach(key => {
        if (!startsWith(key, 'Team')) {
          return
        }
        client.cache.data.delete(key)
      })
    }
  }, [])

  return useQuery(GET_TEAM, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-network',
  })
}

export default useTeam
