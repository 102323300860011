import { useMutation } from '@apollo/react-hooks'
import { Modal, notification } from 'antd'
import React, { useState } from 'react'

import { REMOVE_USER, USERS } from './queries'

const RemoveUserModal = ({ user, visible, onOk, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [removeUser] = useMutation(REMOVE_USER, {
    variables: {
      userId: user.id,
    },
    refetchQueries: [
      {
        query: USERS,
      },
      'getColleagues',
      'slackUsers',
      'askMeAnythings',
      'listPeopleUsers',
      'peopleListStats',
    ],
  })

  const handleOnOk = async () => {
    try {
      setLoading(true)
      await removeUser()
      notification.success({
        message: 'Success!',
        description: `${user.firstName || user.email} has been removed`,
      })

      if (onOk) {
        onOk()
      }
    } catch (e) {
      setLoading(false)
      notification.error({
        message: 'Oops!',
        description:
          'Something went wrong. Please contact us if you need some help.',
      })
    }
  }

  return (
    <Modal
      visible={visible}
      title="Remove from account"
      onCancel={onCancel}
      onOk={handleOnOk}
      okButtonProps={{ type: 'accent' }}
      confirmLoading={loading}
      okText="Remove"
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      width={656}
      destroyOnClose={true}
      centered
    >
      <p style={{ fontWeight: 300 }}>
        Are you sure? The user will disappear like a cold beer on a hot day and
        their details cannot be restored. Their data will be treated in line
        with our{' '}
        <a
          href="https://howamigoing.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          GDPR/ Privacy Policy
        </a>
        .
      </p>
    </Modal>
  )
}

export default RemoveUserModal
