import gql from 'graphql-tag'

export const GET_TODOS = gql`
  query todos {
    todos {
      id
      type
      dueBy
      target
      assignedTo {
        id
        firstName
        lastName
        email
        avatarUrl
      }
      assignedBy {
        id
        firstName
        lastName
        jobTitle
        email
        avatarUrl
      }
    }
  }
`

export const DISMISS_TODO = gql`
  mutation dismissTodo($todoId: String!) {
    dismissTodo(id: $todoId)
  }
`
