import { LandingPage, PageContainer } from './pages'

import AppRoute from '../AppRoutes/AppRoute'
import React from 'react'
import { Switch } from 'react-router-dom'

const Router = ({ basePath }) => {
  const pathWriter = path => {
    return `${basePath}${path}`
  }
  return (
    <PageContainer>
      <Switch>
        <AppRoute path={pathWriter('')} exact component={LandingPage} />
      </Switch>
    </PageContainer>
  )
}

export default Router
