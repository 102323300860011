import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import Avatar from '../../../../_components/Avatar'

const Item = ({ user, selected = false, selectable = true, onToggle }) => {
  const userDisplayName =
    user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.firstName

  return (
    <div
      className="invite-people-form-list-item-inner"
      style={{
        alignItems: 'center',
        cursor: selectable ? 'pointer' : '',
        display: 'flex',
        justifyContent: 'space-between',
      }}
      onClick={onToggle}
    >
      <div>
        <Avatar user={user} />
      </div>
      <div style={{ flex: '1 0 0', padding: '0 12px' }}>
        {userDisplayName && (
          <div
            style={{
              fontFamily: 'museo',
              fontSize: 14,
              color: '#281765',
              fontWeight: 500,
              lineHeight: '22px',
            }}
          >
            {userDisplayName}
          </div>
        )}

        <div
          style={{
            fontFamily: 'museo',
            fontSize: 14,
            color: '#281765',
            fontWeight: 300,
            lineHeight: '22px',
          }}
        >
          {user.email}
        </div>
      </div>
      {selectable &&
        (selected ? (
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            style={{ color: '#77779f', fontSize: '24px' }}
          />
        ) : (
          <div
            style={{
              border: 'solid 1px #77779f',
              borderRadius: 12,
              height: 24,
              width: 24,
            }}
          ></div>
        ))}
    </div>
  )
}

export default Item
