import React from 'react'

import FeedbackReceivedTitle from '../FeedbackReceivedTitle'
import YouReceivedNoFeedback from '../YouReceivedNoFeedback'

export default () => {
  return (
    <div>
      <FeedbackReceivedTitle />
      <YouReceivedNoFeedback />
    </div>
  )
}
