import './styles.less'

import { Button } from 'antd'
import Cookies from 'js-cookie'
import Desktop from '../_assets/img/desktop.svg'
import Logo from '../_assets/img/logo.svg'
import React from 'react'
import moment from 'moment'
import useMobileOptimized from '../hooks/device/useMobileOptimized'

const cookieKey = 'HAIGViewOnMobile'

export const canViewOnMobile = () => {
  return Cookies.get(cookieKey) === 'true'
}

const setViewOnMobilePreference = value => {
  Cookies.set(cookieKey, value, { expires: moment().add(1, 'year').unix() })
  document.location.reload()
}

export default () => {
  const onContinueClick = () => {
    setViewOnMobilePreference(true)
  }

  useMobileOptimized(true)

  return (
    <div className="mobile-support-component">
      <div className="mobile-support-component-inner">
        <img
          src={Logo}
          alt="How am I going?"
          className="mobile-support-component-logo"
        />
        <h1 className="mobile-support-component-heading">
          Our amazing app is not optimised for mobile yet. Please use desktop
          for the best experience :-)
        </h1>
        <img
          src={Desktop}
          alt="Desktop"
          className="mobile-support-component-desktop"
        />
        <p className="mobile-support-component-description">
          Don't have a desktop device handy?
        </p>
        <div className="mobile-support-component-button-container">
          <Button type="ghost" onClick={onContinueClick}>
            Continue on mobile
          </Button>
        </div>
      </div>
    </div>
  )
}
