import { Col, List, Modal, Row, Tag } from 'antd'
import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import {
  Avatar,
  AvatarJobTitle,
  AvatarName,
} from '../../../../_components/Avatar'

const STYLE_TAG_RED = {
  border: '1px solid #f95c4b',
  borderRadius: 12,
  color: '#f95c4b',
  padding: '5px 12px',
  fontSize: 14,
}
const STYLE_TAG_BLUE = {
  border: '1px solid #77779f',
  borderRadius: 12,
  color: '#77779f',
  padding: '5px 12px',
  fontSize: 14,
}

export default ({ cycle, showModal, handleCloseModal }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (cycle.requests) {
      const usersArr = cycle.requests.map(request => {
        const user = request.askedTo

        return {
          id: user.id,
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          jobTitle: user.jobTitle,
          avatarUrl: user.avatarUrl,
          status: user.status,
          gaveFeedback: !!request.respondedAt,
        }
      })

      setUsers(sortBy(usersArr, ['firstName', 'email']))
    }
  }, [cycle])

  return (
    <Modal
      title={
        <div style={{ fontFamily: 'museo', fontWeight: 500 }}>
          See how they're going
        </div>
      }
      bodyStyle={{ maxHeight: 400, padding: '24px 0 8px' }}
      footer={null}
      visible={showModal}
      onCancel={() => handleCloseModal()}
      centered
      width={520}
    >
      <div style={{ maxHeight: 352, overflow: 'scroll', padding: '0 32px' }}>
        <Row gutter={[24, 16]} style={{ color: '#77779f' }}>
          <Col
            span={16}
            className="font-primary color-secondary"
            style={{ fontSize: 14, fontWeight: 300 }}
          >
            Name
          </Col>
          <Col
            span={8}
            className="font-primary color-secondary"
            style={{ fontSize: 14, fontWeight: 300 }}
          >
            Answered the survey
          </Col>
        </Row>
        <List
          size="large"
          itemLayout="vertical"
          dataSource={users}
          split={false}
          renderItem={(user, index) => (
            <List.Item
              key={user.id}
              style={{
                padding: index === users.length - 1 ? '0 0 16px' : '0 0 20px',
              }}
            >
              <Row gutter={[24, 20]} type={'flex'} align={'middle'}>
                <Col span={16}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ flex: '0 0 48px' }}>
                      <Avatar user={user} />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1,
                        justifyContent: 'center',
                        overflow: 'hidden',
                        paddingLeft: '10px',
                      }}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <AvatarName
                          user={user}
                          style={{
                            fontFamily: 'museo',
                            color: '#281765',
                            fontWeight: 500,
                            fontSize: '14px',
                          }}
                        />
                      </div>
                      {user.jobTitle && (
                        <div
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <AvatarJobTitle
                            user={user}
                            style={{
                              color: '#6b6b8f',
                              fontWeight: 300,
                              fontSize: '14px',
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  {user.gaveFeedback ? (
                    <Tag
                      color="rgba(119, 119, 159, 0.12)"
                      style={STYLE_TAG_BLUE}
                    >
                      Yes
                    </Tag>
                  ) : (
                    <Tag color="rgba(249, 92, 75, 0.12)" style={STYLE_TAG_RED}>
                      No
                    </Tag>
                  )}
                </Col>
              </Row>
            </List.Item>
          )}
        ></List>
      </div>
    </Modal>
  )
}
