import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, notification } from 'antd'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import SuccessModalContext from '../../../_components/SuccessModal/context'
import ActivateModal from './ActivateModal'
import DeactivateModal from './DeactivateModal'

export default ({ status }) => {
  const successModal = useContext(SuccessModalContext)

  const [deactivateModalVisible, setDeactivateModalVisible] = useState(false)
  const [activateModalVisible, setActivateModalVisible] = useState(false)

  if (!status) {
    return null
  }

  const handleDeactivateOk = () => {
    notification.success({
      description: 'Integration? What integration?',
      message: `Doneburger!`,
    })
    setDeactivateModalVisible(false)
  }

  const handleDeactivateError = () => {
    notification.error({
      description: 'There was an error. Please try again.',
      message: `That didn't work out as planned`,
    })
  }

  const handleDeactivateCancel = () => {
    setDeactivateModalVisible(false)
  }

  const handleModalOk = () => {
    setActivateModalVisible(false)
    successModal.show({
      message: `Your Howamigoing and Personio accounts are now connected!`,
      button: (
        <Link to="/admin/people">
          <Button type="accent" onClick={() => successModal.hide()}>
            View people imported
          </Button>
        </Link>
      ),
    })
    // notification.success({
    //   description: 'Your Howamigoing and Personio accounts are now connected.',
    //   message: `Great success!`,
    // })
  }

  switch (status) {
    case 'inactive':
    case 'deactivated':
      return (
        <>
          <Button type="primary" onClick={() => setActivateModalVisible(true)}>
            <FontAwesomeIcon
              icon={['fal', 'plus']}
              style={{ width: '1em', marginRight: 8 }}
            />
            Connect
          </Button>
          <ActivateModal
            visible={activateModalVisible}
            onCancel={() => setActivateModalVisible(false)}
            onOk={handleModalOk}
          ></ActivateModal>
        </>
      )
    case 'active':
      return (
        <>
          <Button type="ghost" onClick={() => setDeactivateModalVisible(true)}>
            Disconnect Personio
          </Button>
          <DeactivateModal
            visible={deactivateModalVisible}
            onOk={handleDeactivateOk}
            onCancel={handleDeactivateCancel}
            onError={handleDeactivateError}
          />
        </>
      )
    default:
      return null
  }
}
