import { useApolloClient, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import axios from 'axios'
import deepClone from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { getSubdomain } from 'tldts'
import { useDebouncedCallback } from 'use-debounce'

import { Intercom, identify } from '../_helpers/analytics'
import useInterval from '../hooks/useInterval'
import {
  CURRENT_USER,
  KEEP_SESSION_ALIVE,
  RESET_PASSWORD,
  SEND_PASSWORD_RESET_LINK,
} from './queries'

const jwtDecode = require('jwt-decode')

const RENEW_SESSION_TIMEOUT = 20 // seconds
const REDEEM_MODAL_TIMEOUT = 5 * 60 // 5 minutes (in seconds)
const USER_ACTIVE_DEBOUNCE_TIMEOUT = 5 // seconds

export const UserContext = React.createContext()

const loginRequest = async ({ username, password }) => {
  const response = await axios.post(
    `/api/auth/login`,
    { username, password },
    {
      responseType: 'json',
    },
  )
  return response.data
}

const UserContextProvider = ({ children }) => {
  const client = useApolloClient()

  const setContext = newUserDetails => {
    const newState = merge(deepClone(userContext), newUserDetails)
    updateUserContext(newState)
    return newState
  }

  const setToken = token => {
    localStorage['HAIGToken'] = token
    const decodedToken = jwtDecode(token)
    setSessionExpiryDate(decodedToken.exp)
  }

  const defaultUserContext = {
    login: ({ username, password }) => {
      if (!username || !password) {
        throw new Error('Please enter a username and password')
      }

      return client
        .clearStore()
        .then(() => loginRequest({ username, password }))
        .then(response => {
          if (!response) {
            throw new Error('There was an error during login.')
          }
          setToken(response)
          return getUser()
        })
        .catch(e => {
          if (e && e.response) {
            const temporarilyBlocked = e.response.status === 429
            if (temporarilyBlocked) {
              const retryAfterSeconds = e.response.headers['retry-after']
              if (retryAfterSeconds) {
                const retryAfterMinutes = Math.round(
                  moment
                    .duration(parseInt(retryAfterSeconds, 10), 'seconds')
                    .asMinutes(),
                )
                throw new Error(
                  `Too many failed attempts. Please wait ${retryAfterMinutes} minute${
                    retryAfterMinutes === 1 ? '' : 's'
                  } before trying again`,
                )
              } else {
                throw new Error(
                  `Too many failed attempts. Please try again later`,
                )
              }
            }
          }
          throw new Error('Invalid credentials')
        })
    },

    logout: () => {
      client.clearStore().then(() => {
        if (localStorage['HAIGToken']) {
          localStorage.removeItem('HAIGToken')
        }

        updateUserContext({
          ...defaultUserContext,
          loading: false,
        })

        setSessionExpiryDate(false)

        Intercom.shutdown()
        Intercom.boot()
      })
    },

    sendPasswordResetLink: async email => {
      await sendPasswordResetLink({
        variables: {
          email,
        },
      })
    },

    resetPassword: ({ id, token, password }) => {
      return resetPassword({
        variables: {
          id,
          token,
          password,
        },
      }).then(response => {
        if (response.data && !!response.data.resetPassword) {
          return true
        } else {
          throw new Error('There was an error while resetting your password.')
        }
      })
    },

    isManager: function () {
      return this.directReports && this.directReports.length > 0
    },

    isViewingManager: function () {
      return this.viewingManagerOf && this.viewingManagerOf.length > 0
    },

    isAdmin: function () {
      return this.role === 'COMPANY_ADMIN' || this.role === 'OWNER'
    },

    isOwner: function () {
      return this.role === 'OWNER'
    },

    isSuperUser: function () {
      // TODO: Remove references to this function
      return false
    },

    isOnboarding: false,
    isAuthenticated: false,
    loading: true,
    lastActive: false,

    refetchUser: isImpersonated => {
      return client
        .query({
          query: CURRENT_USER,
          fetchPolicy: 'no-cache',
        })
        .then(response => {
          return updateUserData({ ...response.data, isImpersonated })
        })
    },
    renewSession: async () => {
      if (localStorage['HAIGToken']) {
        const response = await renewSession()

        if (response && response.data && response.data.token) {
          setToken(response.data.token)
        }
      }
    },

    isShownWBYHT: true,

    resetWBYHT: () => {
      setContext({
        isShownWBYHT: false,
      })
    },
  }

  const [userContext, updateUserContext] = useState(defaultUserContext)
  const [timeoutModalVisible, setTimeoutModalVisible] = useState(false)
  const [sessionExpiryDate, setSessionExpiryDate] = useState(false)

  const [getUser, { data: userData, error: errorData }] = useLazyQuery(
    CURRENT_USER,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [renewSession] = useMutation(KEEP_SESSION_ALIVE)
  const [sendPasswordResetLink] = useMutation(SEND_PASSWORD_RESET_LINK)
  const [resetPassword] = useMutation(RESET_PASSWORD)

  const updateUserData = userData => {
    if (!userData || !userData.user) {
      return
    }

    const fetchedUser = userData.user

    const isOnboarding = fetchedUser.type === 'invited' ? true : false

    if (localStorage['HAIGToken'] && fetchedUser.renewalToken) {
      setToken(fetchedUser.renewalToken)
    }

    const clonedUserDetails = deepClone(fetchedUser)
    identify(fetchedUser)

    const getHighestUserRole = () => {
      if (fetchedUser.type === 'owner' || fetchedUser.type === 'admin') {
        return 'Admin'
      }
      if (fetchedUser.directReports && fetchedUser.directReports.length > 0) {
        return 'Manager'
      }
      return 'User'
    }

    if (!userData.isImpersonated) {
      const IntercomOptions = {
        user_hash: fetchedUser.intercomUserHash,
        name: fetchedUser.firstName,
        created_at: fetchedUser.createdAt,
        email: fetchedUser.email,
        user_id: fetchedUser.id,
        job_title: fetchedUser.jobTitle,
        account_name: fetchedUser.org && fetchedUser.org.name,
        user_role: getHighestUserRole(),
        subdomain: getSubdomain(window.location.href),
      }

      Intercom.boot(IntercomOptions)
    }

    return setContext(
      merge(clonedUserDetails, {
        isAuthenticated: true,
        isOnboarding,
        loading: false,
      }),
    )
  }

  useEffect(() => {
    // on page load, fetch the user if we have a token for them
    // otherwise, initiate the log out procedure
    if (
      localStorage['HAIGToken'] &&
      window.location.pathname !== '/access' &&
      window.location.search.indexOf('?t=') === -1
    ) {
      getUser()
    } else {
      userContext.logout()
    }
  }, [])

  useEffect(() => {
    // when the user is loaded properly, set the context appropriately
    updateUserData(userData)
  }, [userData])

  useEffect(() => {
    // if the user did not load properly, initiate the log out procedure
    if (errorData) {
      userContext.logout()
    }
  }, [errorData])

  useInterval(() => {
    const now = Math.floor(Date.now() / 1000)

    // log out the user if their token has expired
    if (localStorage['HAIGToken']) {
      const decodedToken = jwtDecode(localStorage['HAIGToken'])

      if (decodedToken && decodedToken.exp && decodedToken.exp < now) {
        setTimeoutModalVisible(false)
        userContext.logout()
      }
    }

    // show the session expiration modal <REDEEM_MODAL_TIMEOUT> seconds
    // before automatically logging the user off

    if (
      sessionExpiryDate &&
      sessionExpiryDate - now > -1 &&
      sessionExpiryDate - now <= REDEEM_MODAL_TIMEOUT
    ) {
      setTimeoutModalVisible(true)
    }
  }, 1000)

  useInterval(() => {
    // renew the session if the user was active
    const now = Math.floor(Date.now() / 1000)

    if (now - userContext.lastActive <= RENEW_SESSION_TIMEOUT) {
      userContext.renewSession()
    }
  }, RENEW_SESSION_TIMEOUT * 1000)

  const [handleActivity] = useDebouncedCallback(() => {
    // the app rerenders when we update the context, so we
    // debounce this function in order to not update the context too often
    if (userContext.isAuthenticated) {
      const now = Math.floor(Date.now() / 1000)

      updateUserContext({
        ...userContext,
        lastActive: now,
      })
    }
  }, USER_ACTIVE_DEBOUNCE_TIMEOUT * 1000)

  const handleModalRenewal = () => {
    userContext.renewSession().then(() => {
      setTimeoutModalVisible(false)
    })
  }

  return (
    <div
      onClick={() => handleActivity()}
      onMouseMove={() => handleActivity()}
      onKeyPress={() => handleActivity()}
    >
      <UserContext.Provider value={userContext}>
        {children}
      </UserContext.Provider>
      <Modal
        title="Session about to expire!"
        visible={userContext.isAuthenticated && timeoutModalVisible}
        okText="Yes, please!"
        onOk={handleModalRenewal}
        closable={false}
        cancelButtonProps={{ style: { display: 'none' } }}
        centered
      >
        Hey! Your session is about to expire! Do you want to keep yourself
        logged in?
      </Modal>
    </div>
  )
}

export default UserContextProvider
