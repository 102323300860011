import './style.less'

import React, { useContext, useEffect, useState } from 'react'

import PageTitle from '../_components/PageTitle'
import Card from './components/Card'
import Section from './components/Section'
import IntegrationsContext from './Integrations/context'

const adminPath = path => {
  return `/admin/${path}`
}

const Home = () => {
  const integrationsContext = useContext(IntegrationsContext)

  const [integrations, setIntegrations] = useState()

  useEffect(() => {
    integrationsContext.fetch()
  }, [])

  useEffect(() => {
    if (integrationsContext.getIntegrations()) {
      const installedIntegrations = integrationsContext.getIntegrations()
      const integrationsArr = integrationsContext
        .getPossibleIntegrations()
        .map(
          integration => installedIntegrations[integration.type] || integration,
        )
      setIntegrations(integrationsArr)
    }
  }, [integrationsContext.getIntegrations()])

  return (
    <div className="admin-cards">
      <PageTitle style={{ marginBottom: 32 }}>Admin</PageTitle>

      <Section
        title="Workspace management"
        cards={[
          <Card type="people" link={adminPath('people')} />,
          <Card type="teams" link={adminPath('teams')} />,
          <Card type="company" link={adminPath('company')} />,
        ]}
      />

      <Section
        title="Integrations"
        cards={
          integrations &&
          integrations.map(integration => (
            <Card type="integration" integration={integration} />
          ))
        }
      />
    </div>
  )
}

export default () => {
  return <Home />
}
