import React from 'react'

import Form from './Form'

export default ({ form, cycleName, cycleType }) => {
  if (!form) {
    return <span></span>
  }

  return (
    <div className="give-feedback-form">
      <Form formStructure={form} cycleType={cycleType} cycleName={cycleName} />
    </div>
  )
}
