import React, { useState } from 'react'

import Item from './Item'
import { List } from 'antd'
import { sortBy } from 'lodash'

const Component = ({ users, onChange, defaultSelected = [] }) => {
  const [selectedUsers, setSelectedUsers] = useState(defaultSelected)
  const isSelected = user => !!selectedUsers.find(x => x.id === user.id)

  const onUserToggle = user => {
    const newSelectedUsers = !isSelected(user)
      ? [...selectedUsers, user]
      : selectedUsers.filter(x => x.id !== user.id)
    setSelectedUsers(newSelectedUsers)
    if (onChange) {
      onChange(newSelectedUsers.slice())
    }
  }

  return (
    <List
      className="user-selection-list"
      dataSource={sortBy(users, ['firstName', 'email'])}
      size="large"
      itemLayout="vertical"
      split={false}
      style={{ paddingBottom: '12px' }}
      renderItem={user => (
        <List.Item style={{ padding: '0 0 12px 0' }}>
          <Item
            user={user}
            selected={isSelected(user)}
            onToggle={() => onUserToggle(user)}
          />
        </List.Item>
      )}
    />
  )
}

export default Component
