import { Modal } from 'antd'
import React, { useRef, useState } from 'react'

import InfoBanner from '../../../_components/InfoBanner'
import Form from './Form'

const AskQuestionModal = ({
  visible,
  title = 'Ask an anonymous question',
  okText = 'Send question',
  onOk,
  cancelText = 'Cancel',
  onCancel,
  defaultValues = {},
}) => {
  const formRef = useRef(null)

  const [disabledForm, setDisabledForm] = useState(false)
  const [oking, setOking] = useState(false)

  const handleSubmit = () => {
    setDisabledForm(true)
    setOking(true)
    formRef.current.validateFields(async (errors, values) => {
      try {
        if (errors) {
          setDisabledForm(false)
          setOking(false)
          return
        }
        if (onOk) {
          await onOk({
            recipientId: values.recipient.id,
            question: values.question,
          })
        }
        setDisabledForm(false)
        setOking(false)
      } catch (e) {
        setDisabledForm(false)
        setOking(false)
      }
    })
  }

  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      onOk={handleSubmit}
      okButtonProps={{ loading: oking, disabled: disabledForm, type: 'accent' }}
      cancelText={cancelText}
      cancelButtonProps={{ type: 'ghost', disabled: disabledForm }}
      onCancel={onCancel}
      width={584}
      destroyOnClose={true}
      closable={!disabledForm}
      centered
    >
      <Form ref={formRef} defaultValues={defaultValues} />
      <InfoBanner
        info={
          <>
            While we do ensure your anonymity, please use this feature
            responsibly.
          </>
        }
        style={{ marginBottom: '16px' }}
      />
    </Modal>
  )
}

export default AskQuestionModal
