import './styles.less'

import { Button, Icon } from 'antd'
import React from 'react'

export default props => {
  return (
    <Button
      {...props}
      className={`more-actions-button-component ${props.className}`}
    >
      <Icon type="ellipsis" style={{ fontSize: '1.25rem' }} />
    </Button>
  )
}
