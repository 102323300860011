//import './top-menu.less'

import { Menu } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, matchPath } from 'react-router-dom'
import { getWallNotifications } from '../../utils'
import { TOTAL_AMAS_COUNT } from '../../pages/LandingPage/queries'
import { useMutation } from '@apollo/react-hooks'

export default ({ pathname, routes }) => {

  const [showNotifications, setShowNotifications] = useState({});

  const selectedRoute = routes.routes.find(route =>
    matchPath(pathname, {
      path: route.path,
    }),
  );

  const [totalAmasCount] = useMutation(TOTAL_AMAS_COUNT, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  });

  const wallNotifications = async () => {
    const response = await totalAmasCount();
    const wallNotifications = getWallNotifications(response);
    setShowNotifications(wallNotifications);
  }

  useEffect(() => { wallNotifications() }, [])

  const selectedKey = selectedRoute ? selectedRoute.menuKey : null

  const displayInline = { display: "inline" };

  const readNotification = (key) => {
    const notification = JSON.parse(localStorage.getItem(key))
    localStorage.setItem(key, JSON.stringify({ ...notification, showNotifications: false }));
  }

  const onReadNotifications = () => {
    if (selectedKey === "privateArea") readNotification("private");
    else if (selectedKey === "publicWall") readNotification("public");
  }

  onReadNotifications();

  const notification = (id) => <div id={id} style={displayInline} className="new-tag">1</div>;

  return (
    <div>
      <Menu className="top-menu" style={{ marginTop: 0, marginBottom: "1rem" }} mode="horizontal" selectedKeys={[selectedKey]}>
        <Menu.Item key="inbox">
          <Link style={displayInline} to={routes.path + '/inbox'}>Inbox</Link>
          {showNotifications.inbox && notification("inboxWall")}
        </Menu.Item>

        <Menu.Item key="privateArea">
          <Link onClick={onReadNotifications} style={displayInline} to={routes.path + '/privatearea'}>Private area </Link>
          {showNotifications.privateArea && selectedKey !== "privateArea" && notification("privateAreaWall")}
        </Menu.Item>

        <Menu.Item key="publicWall">
          <Link onClick={onReadNotifications} style={displayInline} to={routes.path + '/publicwall'}>Public wall</Link>
          {showNotifications.publicWall && selectedKey !== "publicWall" && notification("publicWall")}
        </Menu.Item>
      </Menu>
    </div>
  )
}
