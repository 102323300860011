import './style.less'

import { DatePicker, Tabs } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import PageTitle from '../../_components/PageTitle'
import { UserContext } from '../../UserContext'
import ThreeSixtyFeedback from './360Feedback'
import AskMeAnything from './AskMeAnything'
import GoodVibes from './GoodVibes'
import PrivateFeedback from './PrivateFeedback'
import PulseSurveys from './PulseSurveys'

const DEFAULT_TIME_PERIOD = [
  moment().utc().year(2020).dayOfYear(1),
  moment().utc(),
]
const DEFAULT_ACTIVE_TAB = 'goodVibes'

export default () => {
  const history = useHistory()
  const user = useContext(UserContext)

  const [timeInterval, setTimeInterval] = useState(DEFAULT_TIME_PERIOD)
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB)

  useEffect(() => {
    if (user.isAdmin() || user.isOwner() || user.isSuperUser()) {
      return
    }
    history.push('/home')
  }, [user])

  const handleTimeIntervalChange = value => {
    if (value.length === 0) {
      setTimeInterval(DEFAULT_TIME_PERIOD)
    } else {
      setTimeInterval([value[0].utc(), value[1].utc()])
    }
  }

  const handleTabChange = tab => {
    setActiveTab(tab)
  }

  return (
    <div className="insights">
      <div className="insights__title-container">
        <PageTitle style={{ marginBottom: 0 }}>Usage stats</PageTitle>
        <div className="insights__date-picker-container">
          <div className="insights__date-picker-label">Time period:</div>
          <DatePicker.RangePicker
            ranges={{
              'Last month': [
                moment().utc().subtract(30, 'days'),
                moment().utc(),
              ],
              'Last 3 months': [
                moment().utc().subtract(2, 'month'),
                moment().utc(),
              ],
              'Last 6 months': [
                moment().utc().subtract(5, 'month'),
                moment().utc(),
              ],
              'Last year': [moment().utc().subtract(1, 'year'), moment().utc()],
            }}
            onChange={handleTimeIntervalChange}
            value={timeInterval}
            format={'DD MMM YYYY'}
            disabledDate={current => {
              return current && current.isAfter(moment().utc(), 'day')
            }}
            className="insights__date-picker"
            dropdownClassName="insights__date-picker-dropdown"
          />
        </div>
      </div>

      <Tabs
        className="ant-tabs--thin"
        activeKey={activeTab}
        onChange={handleTabChange}
      >
        <Tabs.TabPane tab="Good vibes" key={'goodVibes'}>
          <GoodVibes
            timeInterval={timeInterval}
            isActive={activeTab === 'goodVibes'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Ask me anything" key={'askMeAnything'}>
          <AskMeAnything
            timeInterval={timeInterval}
            isActive={activeTab === 'askMeAnything'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Private feedback" key={'privateFeedback'}>
          <PrivateFeedback
            timeInterval={timeInterval}
            isActive={activeTab === 'privateFeedback'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="360 feedback" key={'threeSixtyFeedback'}>
          <ThreeSixtyFeedback
            timeInterval={timeInterval}
            isActive={activeTab === 'threeSixtyFeedback'}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Pulse surveys" key={'pulseSurveys'}>
          <PulseSurveys
            timeInterval={timeInterval}
            isActive={activeTab === 'pulseSurveys'}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
