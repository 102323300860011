import { Link, useHistory } from 'react-router-dom'
import React, { useContext, useEffect } from 'react'

import { Breadcrumb } from 'antd'
import PageTitle from '../../_components/PageTitle'
import PeopleList from './PeopleList'
import { UserContext } from '../../UserContext'

export default () => {
  const history = useHistory()
  const user = useContext(UserContext)

  useEffect(() => {
    if (user.isAdmin() || user.isOwner() || user.isSuperUser()) {
      return
    }
    history.push('/home')
  }, [user])

  return (
    <div className="company">
      <Breadcrumb style={{ marginBottom: 8 }}>
        <Breadcrumb.Item>
          <Link to="/admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>People</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle style={{ marginBottom: '32px' }}>People</PageTitle>

      <PeopleList />
    </div>
  )
}
