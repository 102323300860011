import { Button } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Avatar from '../../../_components/Avatar'
import { track } from '../../../_helpers/analytics'

export default ({ assignedTo }) => {
  const location = useLocation()

  const handleClick = () => {
    if (location.pathname === '/') {
      track('todo.personal360.opened', {
        provider: assignedTo.email,
        org: assignedTo.org.name,
      })
    }
  }

  return (
    <div className="dashboard-todo">
      <Avatar user={assignedTo} />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>See your blind spots</div>
        <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
          Get feedback on what you're missing and reassurance on what you're
          doing well
        </div>
      </div>
      <Link to="/personal360/create/1" onClick={handleClick}>
        <Button type="primary" size="small">
          Get feedback
        </Button>
      </Link>
    </div>
  )
}
