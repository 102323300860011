import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'

import IntegrationsContext from '../context'

const TEXT_STYLE = { color: '#281765', fontWeight: 300, lineHeight: '20px' }

export default () => {
  const integrationsContext = useContext(IntegrationsContext)

  const slackIntegration =
    integrationsContext.getIntegrations() &&
    integrationsContext.getIntegrations()['slack']

  if (!slackIntegration) return null

  switch (slackIntegration.status) {
    case 'inactive':
    case 'deactivated':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={['far', 'ban']}
            style={{ color: '#77779f', fontSize: '20px', marginRight: '8px' }}
          />
          <span style={TEXT_STYLE}>Inactive</span>
        </div>
      )

    case 'active':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={['far', 'check']}
            style={{ color: '#47d764', fontSize: '20px', marginRight: '8px' }}
          />
          <span style={TEXT_STYLE}>Active</span>
        </div>
      )

    default:
      return null
  }
}
