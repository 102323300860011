import { useMutation } from '@apollo/react-hooks'
import { Breadcrumb, Button, Card, notification } from 'antd'
import React, { useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import PageTitle from '../../../_components/PageTitle'
import { UserContext } from '../../../UserContext'
import EditCompanyForm from './EditCompanyForm'
import { UPDATE_ORG } from './queries'

export default () => {
  const user = useContext(UserContext)
  const [updateOrg] = useMutation(UPDATE_ORG)
  const [loading, setIsLoading] = useState(false)
  const formRef = useRef()

  const handleSubmit = () => {
    setIsLoading(true)
    formRef.current.validateFields(async (err, values) => {
      if (err) {
        setIsLoading(false)
        return
      }
      await updateOrg({
        variables: {
          id: user.org.id,
          changes: {
            name: values.orgName,
            timezone: values.timezone,
          },
        },
      })
      await user.refetchUser()
      onSuccess()
    })
  }

  const onSuccess = () => {
    setIsLoading(false)
    notification.success({
      message: 'Company updated!',
      description: 'The company was updated successfully',
    })
  }

  return (
    <>
      <Breadcrumb style={{ marginBottom: 8 }}>
        <Breadcrumb.Item>
          <Link to="/admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Company</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle style={{ marginBottom: '32px' }}>Company</PageTitle>

      <Card title="Details">
        <div style={{ width: '296px' }}>
          <EditCompanyForm
            ref={formRef}
            orgName={user.org.name}
            timezone={user.org.data.timezone}
            submit={handleSubmit}
          />
          <Button type="accent" onClick={handleSubmit} loading={loading}>
            Save
          </Button>
        </div>
      </Card>
    </>
  )
}
