import { useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Icon, Row, Typography, notification } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import Emoji from 'react-emoji-render'
import { Link } from 'react-router-dom'

import { ReactComponent as pencilButtonImg } from '../../../../_assets/img/pencil.svg'
import SuccessModalContext from '../../../../_components/SuccessModal/context'
import { track } from '../../../../_helpers/analytics'
import { UserContext } from '../../../../UserContext'
import { CYCLE_TYPES, formatSelection, getCycleBaseUrl } from '../../helpers'
import queries from '../../queries'
import ButtonsBottomPersonal360 from '../components/CycleCreationEditButtonsBottom'
import { DEFAULT_SPACING } from '../constants'
import Context from '../Context'
import StepsContext from '../Context/StepsContext'
import QUERIES from '../queries'

const { Title } = Typography

const sectionTitleType = 4
const gutterSize = 16
const COLOR_DARK = '#281765'
const STYLE_LABEL = { fontSize: 18, marginBottom: 0 }
const STYLE_LETTER_BIG = {
  fontSize: 14,
  fontWeight: 300,
  color: COLOR_DARK,
}
const STYLE_ROW = {
  borderBottom: '1px solid #e8e8e8',
  paddingBottom: DEFAULT_SPACING * 3,
  marginBottom: DEFAULT_SPACING * 3,
}

export default props => {
  const user = useContext(UserContext)

  const cycleContext = useContext(Context)

  const stepsContext = useContext(StepsContext)
  const successModal = useContext(SuccessModalContext)

  const [momentStartDate, setMomentStartDate] = useState(false)
  const [cycleStartsToday, setCycleStartsToday] = useState(false)
  const [timezone, setTimezone] = useState(false)

  const cycleType = cycleContext.type
  const cyclePath = getCycleBaseUrl(cycleType)

  useEffect(() => {
    if (cycleContext.timezone) {
      const momentTimezone = moment.tz(cycleContext.timezone)
      const gmt = momentTimezone.format('Z').split(':')
      setTimezone(
        cycleContext.timezone.split('_').join(' ') +
          ` (GMT ${gmt[0] + (gmt[1] !== '00' ? ':' + gmt[1] : '')})`,
      )
    }
    if (cycleContext.startDate && cycleContext.timezone) {
      const startDate = moment
        .unix(cycleContext.startDate)
        .tz(cycleContext.timezone)
      setMomentStartDate(startDate)
      setCycleStartsToday(
        startDate.isSame(moment.tz(cycleContext.timezone), 'day'),
      )
    }
  }, [])

  const createCycleData = {
    variables: {
      name: cycleContext.name,
      formId: cycleContext.form && cycleContext.form.id,
      timezone: cycleContext.timezone,
      startDate: cycleContext.startDate,
      endDate: cycleContext.endDate,
      userSelection: formatSelection(cycleContext.selection),
      minRequests:
        cycleContext.type === CYCLE_TYPES.threeSixty &&
        cycleContext.minRequests,
      maxRequests:
        cycleContext.type === CYCLE_TYPES.threeSixty &&
        cycleContext.maxRequests,
    },
  }

  if (cycleStartsToday) {
    createCycleData.refetchQueries = [
      {
        query: queries[cycleContext.type].GET_CYCLES,
        variables: {
          timing: 'running',
        },
      },
    ]
  } else {
    createCycleData.refetchQueries = [
      {
        query: queries[cycleContext.type].GET_SCHEDULED_CYCLES,
      },
    ]
  }

  const [createCycle] = useMutation(QUERIES[cycleType].CREATE, createCycleData)

  const [updateCycle] = useMutation(QUERIES[cycleType].UPDATE, {
    variables: {
      ...createCycleData.variables,
      cycleId: cycleContext.id,
    },
    refetchQueries: createCycleData.refetchQueries,
  })

  const submitCycle = () => {
    try {
      const handleSuccess = () => {
        if (cycleContext.id) {
          track(`${cycleType}.cycle.update`, {
            updater: user.email,
            org: user.org.name,
            cycleName: cycleContext.name,
          })
        } else {
          track(`${cycleType}.cycle.create`, {
            creator: user.email,
            org: user.org.name,
            cycleName: cycleContext.name,
          })
        }

        cycleStartsToday
          ? props.history.push(`${cyclePath}/ongoing`)
          : props.history.push(`${cyclePath}/scheduled`)

        const cycleCalling = (() => {
          switch (cycleType) {
            case CYCLE_TYPES.personal:
              return 'Private feedback'

            case CYCLE_TYPES.threeSixty:
              return '360 feedback'

            case CYCLE_TYPES.pulse:
              return 'Pulse survey'

            default:
              break
          }
        })()

        const message = (() => {
          if (cycleContext.id) {
            return `${cycleCalling} updated. Nice work!`
          }
          const startsToday =
            cycleContext.startDate &&
            moment.unix(cycleContext.startDate).isSame(moment(), 'day')
          if (startsToday) {
            return `${cycleCalling} launched. Nice work!`
          }
          return `${cycleCalling} scheduled. Nice work!`
        })()

        successModal.show({
          message,
        })
      }

      if (cycleContext.id) {
        updateCycle().then(handleSuccess)
      } else {
        createCycle().then(handleSuccess)
      }
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description: 'It appears an error ocurred. Please try again later.',
      })
      throw e
    }
  }

  const ButtonEdit = ({ step }) => {
    return (
      <Link
        to={
          cycleContext.id
            ? `${cyclePath}/edit/${cycleContext.id}/${step}`
            : `${cyclePath}/create/${step}`
        }
      >
        <Button
          type="link"
          style={{ padding: 0, height: 'auto', color: '#77779f', fontSize: 14 }}
          onClick={() => stepsContext.setStep(step - 1)}
        >
          <Icon
            component={pencilButtonImg}
            style={{
              fontSize: 12,
              color: '#77779f',
              position: 'relative',
              top: -2,
            }}
          />
          Edit
        </Button>
      </Link>
    )
  }

  return (
    momentStartDate && (
      <div className="new-cycle-summary">
        <Card style={{ marginBottom: DEFAULT_SPACING * 2 }}>
          <div style={{ padding: DEFAULT_SPACING * 2 }}>
            <Row gutter={gutterSize} style={STYLE_ROW}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  {cycleType === CYCLE_TYPES.pulse
                    ? 'Pulse survey form'
                    : 'Feedback form'}
                </Title>
              </Col>

              <Col span={14}>
                <div style={STYLE_LETTER_BIG} data-cy="event-form">
                  {cycleContext.form.title}
                </div>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <ButtonEdit step={1} />
              </Col>
            </Row>

            <Row gutter={gutterSize} style={STYLE_ROW}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  People selected
                </Title>
              </Col>
              <Col span={14}>
                <div style={STYLE_LETTER_BIG} data-cy="enrolled-users">
                  {cycleContext.selection.total}{' '}
                  {cycleContext.selection.total === 1 ? 'person' : 'people'}
                </div>
                {cycleType === CYCLE_TYPES.threeSixty && (
                  <div className="small-letter" style={{ color: COLOR_DARK }}>
                    People selected need to ask{' '}
                    <span style={{ color: '#f95c4b' }}>
                      between {cycleContext.minRequests} and{' '}
                      {cycleContext.maxRequests} colleagues
                    </span>{' '}
                    for feedback.
                  </div>
                )}
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <ButtonEdit step={2} />
              </Col>
            </Row>

            <Row gutter={gutterSize} style={STYLE_ROW}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  {cycleType === CYCLE_TYPES.pulse
                    ? 'Survey details'
                    : 'Event details'}
                </Title>
              </Col>
              <Col span={14}>
                <div className="small-letter" style={{ marginBottom: 4 }}>
                  Event name:{' '}
                  <span style={{ color: '#281765' }}>{cycleContext.name}</span>
                </div>
                <div className="small-letter" style={{ marginBottom: 4 }}>
                  Start date:{' '}
                  <span style={{ color: '#281765' }}>
                    {moment
                      .unix(cycleContext.startDate)
                      .tz(cycleContext.timezone)
                      .format('DD MMM YYYY')}
                  </span>
                </div>
                <div className="small-letter" style={{ marginBottom: 4 }}>
                  End date:{' '}
                  <span style={{ color: '#281765' }}>
                    {moment
                      .unix(cycleContext.endDate)
                      .tz(cycleContext.timezone)
                      .format('DD MMM YYYY')}
                  </span>
                </div>
                <div className="small-letter" style={{ marginBottom: 4 }}>
                  Event timezone:{' '}
                  <span style={{ color: '#281765' }}>{timezone}</span>
                </div>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <ButtonEdit step={3} />
              </Col>
            </Row>

            <Row gutter={gutterSize}>
              <Col span={6}>
                <Title level={sectionTitleType} style={STYLE_LABEL}>
                  Notifications
                </Title>
              </Col>
              <Col span={14}>
                <div style={STYLE_LETTER_BIG}>
                  {cycleType === CYCLE_TYPES.pulse ? (
                    <>
                      We'll take care of notifying people selected about this
                      survey. <Emoji text=":ok_hand:" />
                      <br />
                      They'll receive email reminders to respond on{' '}
                      <span style={{ color: '#f95c4b' }}>
                        Tuesdays and Fridays
                      </span>
                    </>
                  ) : (
                    <>
                      We'll take care of notifying your team about this event.{' '}
                      <Emoji text=":ok_hand:" />
                      <br />
                      They'll receive email reminders to{' '}
                      {cycleType === CYCLE_TYPES.threeSixty && 'request and '}
                      give feedback on
                      <br />
                      <span style={{ color: '#f95c4b' }}>
                        Tuesdays and Fridays
                      </span>
                      {cycleType === CYCLE_TYPES.threeSixty && (
                        <>
                          , and to view feedback{' '}
                          <span style={{ color: '#f95c4b' }}>
                            when the event ends
                          </span>
                        </>
                      )}
                    </>
                  )}
                  .
                </div>
              </Col>
              <Col span={4} style={{ textAlign: 'right' }}>
                <Button
                  type="link"
                  href="/account/notifications"
                  target="_blank"
                >
                  <FontAwesomeIcon
                    icon={['fal', 'external-link']}
                    style={{ marginRight: 6, fontSize: 12 }}
                  />
                  View details
                </Button>
              </Col>
            </Row>
          </div>
        </Card>
        <ButtonsBottomPersonal360
          step={4}
          handleContinueClick={submitCycle}
          contextId={cycleContext.id}
          startDate={cycleContext.startDate}
          typePath={cyclePath}
          fromWBYHT={cycleContext.fromWBYHT}
        />
      </div>
    )
  )
}
