import React from 'react'

import InfoMessage from './InfoMessage'

const ReadonlyFieldsBanner = ({ fields, style, user }) => {
  const codes = fields
    ? fields.reduce((memo, field) => {
        field.reasons.forEach(x => {
          const includesCode = memo.find(y => x.code === y)
          if (!includesCode) {
            memo.push(x.code)
          }
        })
        return memo
      }, [])
    : []

  const codeLabels = {
    personio: 'Personio',
    saml: `your company's SAML system`,
  }

  if (user.status === 'DEACTIVATED') {
    return (
      <InfoMessage style={style}>
        This person's account has been deactivated. Please reactivate it to make
        changes.
      </InfoMessage>
    )
  } else {
    return (
      <InfoMessage style={style}>
        You can edit the greyed out fields via{' '}
        {codes.map(x => codeLabels[x]).join(', ')}. Updates will appear here
        within 24 hours.
      </InfoMessage>
    )
  }
}

export default ReadonlyFieldsBanner
