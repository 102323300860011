import ActionMenu from '../ActionMenu'
import { Menu } from 'antd'
import Participant from '../Participant'
import React from 'react'

const Header = ({ currentUser, sender, recipient, onActionMenuClick }) => {
  const currentUserIsSender = sender.id === currentUser.id

  const canReportGoodVibe = !currentUserIsSender
  const canEditGoodVibe = currentUserIsSender
  const canDeleteGoodVibe =
    currentUserIsSender || currentUser.isAdmin() || currentUser.isOwner()

  return (
    <div className="good-vibe-card__header">
      <div className="good-vibe-card__receiver">
        <Participant user={recipient} />
      </div>
      <div className="good-vibe-card__actions-menu">
        <ActionMenu onClick={onActionMenuClick}>
          {canReportGoodVibe && (
            <Menu.Item key="report-abuse">Report abuse</Menu.Item>
          )}
          {canEditGoodVibe && <Menu.Item key="edit">Edit</Menu.Item>}
          {canDeleteGoodVibe && <Menu.Item key="delete">Delete</Menu.Item>}
        </ActionMenu>
      </div>
    </div>
  )
}

export default Header
