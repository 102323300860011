import gql from 'graphql-tag'

export const GET_PERSONIO_INTEGRATION = gql`
  query personioIntegration {
    personio: personioIntegration {
      status
      config {
        inviteNewUsers
        assignSupervisors
        onDeleteInPersonio
      }
      error
    }
  }
`
