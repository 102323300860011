import { Icon } from 'antd'
import React from 'react'

import slackSvg from '../../_assets/img/integrations/slack.svg'

const PersonioTab = ({ error }) => {
  return (
    <div className="invite-people-tab-name">
      <img src={slackSvg} alt="Add via Slack" />
      <div>Add via Slack </div>
      <div style={{ marginLeft: 8 }}>
        {error && (
          <Icon
            type="exclamation-circle"
            theme={'twoTone'}
            twoToneColor={'#f95c4b'}
          />
        )}
      </div>
      <div className="invite-people-tab-separator"></div>
    </div>
  )
}

export default PersonioTab
