import { Dropdown, Menu } from 'antd'
import React, { useState } from 'react'

import MoreActionsButton from '../../../_components/MoreActionsButton'

const ActionMenu = ({ children, onClick, ...props }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleVisibleChange = flag => {
    setDropdownVisible(flag)
  }

  const handleClick = e => {
    if (onClick) {
      onClick(e.key, e)
    }
  }

  return (
    <Dropdown
      overlay={<Menu onClick={handleClick}>{children}</Menu>}
      visible={dropdownVisible}
      onVisibleChange={handleVisibleChange}
    >
      <MoreActionsButton style={props.style} />
    </Dropdown>
  )
}

export default ActionMenu
