import { useQuery } from '@apollo/react-hooks'
import merge from 'lodash/merge'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { CYCLE_TYPES, getCycleBaseUrl } from '../../helpers'
import QUERIES from '../queries'
import Context from './'

const ContextProvider = ({
  cycleType = null,
  cycleId = null,
  formId = null,
  fromWBYHT,
  children,
}) => {
  const setContext = ({
    id,
    name,
    startDate,
    endDate,
    timezone,
    selectedColleagues,
    selection,
    minRequests,
    maxRequests,
    totalNumberColleagues,
    form,
  }) => {
    updateContext(prevState => {
      const newState = { ...prevState }

      if (selectedColleagues) {
        newState.selectedColleagues = []
      }

      if (form) {
        newState.form = null
      }

      const merged = merge(newState, {
        id,
        name,
        startDate,
        endDate,
        timezone,
        minRequests,
        maxRequests,
        totalNumberColleagues,
        form,
      })
      // Required to ensure all props inside the obj are merged
      if (selection) {
        merged.selection = JSON.parse(JSON.stringify(selection))
      }
      return merged
    })
  }

  const userTimezoneGuess = moment.tz.guess()

  const contextState = {
    // General props
    type: cycleType,
    id: cycleId,
    name: '',
    startDate: null,
    endDate: null,
    timezone: userTimezoneGuess,
    form: formId && {
      id: formId,
    },
    selectedColleagues: [],

    selection: {
      users: {},
      teams: {},
      total: 0,
    },

    // 360 specific props
    minRequests: 2,
    maxRequests: null,
    totalNumberColleagues: null,

    fromWBYHT,

    refetchTotalNumberOfColleagues: () => {
      refetchTotal()
    },

    setContext,
  }

  const [context, updateContext] = useState(contextState)

  const { data: usersInOrg, refetch: refetchTotal } = useQuery(
    QUERIES.GET_USERS_TOTAL,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const { data } = useQuery(QUERIES[cycleType].GET, {
    variables: {
      id: cycleId,
    },
    skip: !cycleId,
  })

  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (usersInOrg) {
      setContext({ totalNumberColleagues: usersInOrg.users.total })
    }
  }, [usersInOrg])

  useEffect(() => {
    if (data && data.cycle) {
      let selection = {
        users: {},
        teams: {},
        total: 0,
      }

      switch (cycleType) {
        case CYCLE_TYPES.personal:
        case CYCLE_TYPES.pulse:
          selection.users = data.cycle.requests.reduce((acc, x) => {
            acc[x.askedTo.id] = {
              id: x.askedTo.id,
              teamId: x.askedTo.team ? x.askedTo.team.id : 'no-team',
            }
            return acc
          }, {})
          selection.total = data.cycle.requests.length
          break

        case CYCLE_TYPES.threeSixty:
          selection.users = data.cycle.enrolled.reduce((acc, x) => {
            acc[x.id] = {
              id: x.id,
              teamId: x.teamId || 'no-team',
            }
            return acc
          }, {})
          selection.total = data.cycle.enrolled.length
          break

        default:
          break
      }

      setContext({
        id: cycleId,
        name: data.cycle.name,
        startDate: data.cycle.startDate,
        endDate: data.cycle.endDate,
        selection,
        minRequests: data.cycle.minRequests,
        maxRequests: data.cycle.maxRequests,
        form: data.cycle.form,
        timezone: data.cycle.timeZone,
      })
    }
  }, [data])

  useEffect(() => {
    // applies only to editing an existing cycle
    if (data && data.cycle) {
      // const updateObject = {}

      // // check if any of the fields of the cycle were updated

      // if (context.name !== data.cycle.name) {
      //   updateObject.name = data.cycle.name
      // }
      // if (context.startDate !== data.cycle.startDate) {
      //   updateObject.startDate = data.cycle.startDate
      // }
      // if (context.endDate !== data.cycle.endDate) {
      //   updateObject.endDate = data.cycle.endDate
      // }
      // if (context.timezone !== data.cycle.timeZone) {
      //   updateObject.timezone = data.cycle.timeZone
      // }
      // if (context.form && context.form.id !== data.cycle.form.id) {
      //   updateObject.formId = data.cycle.form.id
      // }
      // if (context.selection) {
      //   updateObject.userSelection = formatSelection(context.selection)
      // }
      // if (cycleType === CYCLE_TYPES.threeSixty) {
      //   if (context.minRequests !== data.cycle.minRequests) {
      //     updateObject.minRequests = data.cycle.minRequests
      //   }
      //   if (context.maxRequests !== data.cycle.maxRequests) {
      //     updateObject.maxRequests = data.cycle.maxRequests
      //   }
      // }

      // cycleUpdateContext.setCycleUpdateContext(updateObject)

      const queryParams = new URLSearchParams(location.search)

      if (queryParams.get('skipToConfirmStep')) {
        history.push(`${getCycleBaseUrl(cycleType)}/edit/${data.cycle.id}/4`)
      }
    }
  }, [data, context])

  return <Context.Provider value={context}>{children}</Context.Provider>
}

export default ContextProvider
