import gql from 'graphql-tag'

export const COMPANY_GOOD_VIBES = gql`
  query companyGoodVibesInsight($startDate: String, $endDate: String) {
    companyGoodVibesInsight(startDate: $startDate, endDate: $endDate) {
      id
      key
      value
    }
  }
`
