import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'

import Table from '../../../Table'
import Section from '../../Section'
import { USER_EVENTS_THREE_SIXTY } from './queries'
import TableColumns from './TableColumns'

const PAGE_SIZE = 10

export default ({ timeInterval, userId }) => {
  const [showSection, setShowSection] = useState(false)
  const [loading, setLoading] = useState(true)

  const [queryVariables, setQueryVariables] = useState({
    userId,
    startDate: timeInterval[0],
    endDate: timeInterval[1],
    offset: 0,
    limit: PAGE_SIZE,
  })

  const { data } = useQuery(USER_EVENTS_THREE_SIXTY, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,
    onCompleted: () => {
      setLoading(false)
    },
  })

  useEffect(() => {
    if (data) {
      setLoading(false)
      if (data.userThreeSixtyEventsInsight.hasPermissions) {
        setShowSection(true)
      }
    }
  }, [data])

  useEffect(() => {
    if (userId !== queryVariables.userId) {
      setQueryVariables({
        ...queryVariables,
        userId,
      })
    }
  }, [userId])

  useEffect(() => {
    if (
      timeInterval[0] === queryVariables.startDate &&
      timeInterval[1] === queryVariables.endDate
    ) {
      return
    }
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    })
  }, [timeInterval])

  const handlePageChange = page => {
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      offset: PAGE_SIZE * (page - 1),
    })
  }

  return (
    <>
      {showSection && (
        <Section
          title="Events"
          smallTitle
          withInfo="Who can see this?"
          withInfoTooltip="Only the user, their Manager and platform Admins can see event data"
        >
          <Table
            onPageChange={handlePageChange}
            columns={TableColumns}
            loading={loading}
            data={
              data &&
              data.userThreeSixtyEventsInsight &&
              data.userThreeSixtyEventsInsight.items
            }
            rowKey={record => record.id}
            total={
              data &&
              data.userThreeSixtyEventsInsight &&
              data.userThreeSixtyEventsInsight.total
            }
            itemsName="events"
            noHover
          />
        </Section>
      )}
    </>
  )
}
