import React, { useContext, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { SuccessModalProvider } from './_components/SuccessModal/context'
import { Intercom } from './_helpers/analytics'
import isDevice from './_helpers/isDevice'
import AccessByToken from './AccessByToken'
import AppLayout from './AppLayout'
import Bootstrap from './AppLoading'
import AppRoutes from './AppRoutes'
import LogIn from './LogIn'
import LogInWithProvider from './LogIn/LogInWithProvider'
import LogInWithSlack from './LogIn/LogInWithSlack'
import { canViewOnMobile } from './MobileSupport'
import MobileInterruptionGuard from './MobileSupport/MobileInterruptionGuard'
import Onboarding from './Onboarding/AppOnboarding'
import ResetPassword from './ResetPassword'
import ResetPasswordConfirmation from './ResetPassword/resetConfirm'
import ResetLinkConfirmation from './ResetPassword/resetEmailConfirmation'
import ResetPasswordWithToken from './ResetPassword/resetWithToken'
import SignUp from './SignUp'
import SignUpWithSlack from './SignUp/SignUpWithSlack'
import { UserContext } from './UserContext'

export default () => {
  const user = useContext(UserContext)

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (!user || user.loading) {
    return <Bootstrap />
  }

  Intercom.update()

  return (
    <SuccessModalProvider>
      <Switch>
        <Route path="/signup" exact component={SignUp} />
        <Route path="/signup/slack" component={SignUpWithSlack} />
        <Route path="/resetPassword/sent" component={ResetLinkConfirmation} />
        <Route
          path="/resetPassword/confirm"
          component={ResetPasswordConfirmation}
        />
        <Route
          path="/resetPassword/:userId"
          component={ResetPasswordWithToken}
        />
        <Route path="/resetPassword" exact component={ResetPassword} />
        <Route path="/login/provider" exact component={LogInWithProvider} />
        <Route path="/login/slack" exact component={LogInWithSlack} />
        <Route path="/login*" component={LogIn} />

        <Route path="/access" component={AccessByToken} />
        <Route path="/onboarding">
          <MobileInterruptionGuard>
            <Onboarding />
          </MobileInterruptionGuard>
        </Route>
        <Route>
          {user.isAuthenticated && (canViewOnMobile() || !isDevice()) ? (
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          ) : (
            <AppRoutes />
          )}
        </Route>
      </Switch>
    </SuccessModalProvider>
  )
}
