import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'

import UserSelectionList from '../UserSelectionList'
import { INVITE_USERS } from './queries'

const InvitePeopleModal = ({
  visible,
  users,
  title = 'Send invites',
  onOk,
  onCancel,
  cancelText = 'Cancel',
}) => {
  const [inviting, setInviting] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState(users)
  const [disabledForm, setDisabledForm] = useState(false)

  const [inviteUsers] = useMutation(INVITE_USERS, {
    refetchQueries: ['listPeopleUsers', 'peopleListStats'],
    awaitRefetchQueries: true,
  })

  const handleSubmit = async () => {
    try {
      setDisabledForm(true)
      setInviting(true)
      await inviteUsers({
        variables: {
          userIds: selectedUsers.map(x => x.id),
        },
      })
      setDisabledForm(false)
      setInviting(false)
      if (onOk) {
        onOk(selectedUsers)
      }
    } catch (e) {
      setDisabledForm(false)
      setInviting(false)
    }
  }

  const handleOnSelectedUsersChange = users => {
    setSelectedUsers(users)
  }

  useEffect(() => {
    setSelectedUsers(users)
  }, [users])

  const okText = `Send invite${
    selectedUsers.length > 1 ? `s (${selectedUsers.length})` : ''
  }`
  const noUsersSelected = selectedUsers.length === 0

  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      onOk={handleSubmit}
      okButtonProps={{
        loading: inviting,
        disabled: noUsersSelected || disabledForm,
        type: 'accent',
      }}
      cancelText={cancelText}
      cancelButtonProps={{ type: 'ghost', disabled: disabledForm }}
      onCancel={onCancel}
      width={560}
      destroyOnClose={true}
      closable={!disabledForm}
      centered
    >
      <p>The following people will be invited to join Howamigoing:</p>
      <UserSelectionList
        users={users}
        defaultSelected={selectedUsers}
        onChange={handleOnSelectedUsersChange}
      />
    </Modal>
  )
}

export default InvitePeopleModal
