import { Spin } from 'antd'
import React from 'react'

export default ({ title, number, loading }) => {
  return (
    <div className="up-stat">
      <div className="up-stat__title">{title}</div>
      <div className="up-stat__number">{loading ? <Spin /> : number}</div>
    </div>
  )
}
