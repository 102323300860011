import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React from 'react'

import PageTitle from '../../../_components/PageTitle'

const FeatureHeader = ({ title, buttonLabel, onButtonClick }) => {
  return (
    <div
      className="ask-me-anything-feature-header"
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '40px',
      }}
    >
      <PageTitle
        subtitle="Ask a question or send a comment or idea - it's totally anonymous!"
        subtitleLink="https://www.youtube.com/watch?v=J73mA4MX97c&list=PL1QVVAGN1US193g-u-R5tTqGFO7CPOUt3&index=2"
        style={{ marginBottom: 0 }}
      >
        {title}
      </PageTitle>
      <Button
        type="primary"
        style={{ marginLeft: 'auto' }}
        onClick={onButtonClick}
      >
        <FontAwesomeIcon
          icon={['fal', 'plus']}
          style={{ width: '1em', marginRight: 8 }}
        />
        {buttonLabel}
      </Button>
    </div>
  )
}

export default FeatureHeader
