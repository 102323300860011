import { Button, Icon, Modal } from 'antd'
import React, { useContext, useState } from 'react'

import { ReactComponent as timesIcon } from '../../../_assets/img/times.svg'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'

export default ({ cycle, onDelete }) => {
  const user = useContext(UserContext)

  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOk = async () => {
    setLoading(true)
    track(cycle.type + '.cycle.cancel', {
      user: user.email,
      org: user.org.name,
      cycleId: cycle.id,
      startDate: cycle.startDate,
      canceledAt: Date.now(),
    })
    await onDelete()
    setLoading(false)
    setModalVisible(false)
  }

  return (
    <>
      <Button
        type="link"
        style={{ color: '#281765', fontWeight: 500, marginLeft: '12px' }}
        onClick={() => setModalVisible(true)}
      >
        <span
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '8px',
            backgroundColor: '#f1f1f5',
            marginRight: '8px',
            display: 'inline-block',
          }}
        >
          <Icon
            component={timesIcon}
            style={{
              fontSize: '10px',
              verticalAlign: 'middle',
              lineHeight: 1,
            }}
          />
        </span>
        Cancel event
      </Button>

      <Modal
        title="Cancel event?"
        okText="Cancel event"
        cancelText="Keep event"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleOk}
        cancelButtonProps={{ disabled: loading }}
        okButtonProps={{ disabled: loading, loading, type: 'accent' }}
        centered
      >
        If you cancel this event, it will be deleted. You can create a new event
        anytime.
      </Modal>
    </>
  )
}
