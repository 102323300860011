import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge, Card } from 'antd'
import React, { useContext } from 'react'

import speedometer from '../../_assets/img/speedometer.svg'
import { UserContext } from '../../UserContext'

export default ({
  form,
  formType,
  contextFormId,
  showModalForForm,
  onCreateFormClick,
  style,
}) => {
  const currentUser = useContext(UserContext)
  const handleOnCardClick = () => {
    if (form.id === 'createForm') {
      return
    }
    showModalForForm(form)
  }

  const renderBadgeIcon = () =>
    form.id === 'createForm' ? (
      <span></span>
    ) : (
      <FontAwesomeIcon
        icon={['fas', 'check-circle']}
        className="icon"
        style={{ width: '1em', fontSize: 20, color: '#77779f' }}
      />
    )

  const renderCard = () => {
    const FormCard = (
      <Card
        className={
          contextFormId && form.id === contextFormId ? 'form-selected' : ''
        }
        bodyStyle={{
          width: '100%',
          cursor: 'pointer',
          paddingRight: 46,
        }}
        onClick={handleOnCardClick}
        style={style}
      >
        <div style={{ display: 'flex' }}>
          {form.isSentimentAnalysisActivated && (
            <div style={{ marginRight: '8px' }}>
              <img
                src={speedometer}
                alt="Sentiment analysis logo"
                style={{ display: 'block', paddingTop: '4px' }}
              />
            </div>
          )}
          <div
            style={{
              fontFamily: 'museo',
              fontSize: '14px',
              fontWeight: 500,
            }}
            data-cy="form-title"
          >
            {form.title}
          </div>
        </div>
      </Card>
    )
    if (contextFormId && form.id === contextFormId) {
      return <Badge count={renderBadgeIcon()}>{FormCard}</Badge>
    } else if (form.id === 'createForm') {
      const href = `mailto:${customFormEmailTo}?subject=${customFormEmailSubject}&body=${getCustomFormEmailBody(formType, currentUser.firstName, currentUser.lastName)}`
      return (
        <a
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onCreateFormClick}
        >
          {FormCard}
        </a>
      )
    } else {
      return FormCard
    }
  }

  return renderCard()
}

const customFormEmailTo = 'support@howamigoing.com'
const customFormEmailSubject = `I'd like a custom form please`
const getCustomFormEmailBody = (type, firstName, lastName) => {
  const eventTypeName = (() => {
    switch (type) {
      case '360':
        return '360 feedback'
      case 'personal':
        return 'Private feedback'
      case 'pulse':
        return 'Pulse survey'
      default:
        return ''
    }
  })()
  const personName = (() => {
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    if (firstName) {
      return firstName
    }
    return '[Your name]'
  })()
  const text = `Howdy Howmies!
  
Please create me a custom ${eventTypeName} form with the following questions and answer formats:
  
e.g. for a sliding scale response (up to 4 options)
  
Question: Do my habits and behaviours enhance our team culture?
Answer: 
No
Sometimes
Yes
  
e.g. for an open text response
  
Question: In what ways do my habits and behaviours enhance our team culture?
Answer: open text
  
Ideally I need this form to be ready by [date].
  
Thanks!
${personName}
`
  return encodeURIComponent(text)
}
