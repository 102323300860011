import './styles.less'

import { useQuery } from '@apollo/react-hooks'
import { Breadcrumb, Button, Icon, Input, Spin, notification } from 'antd'
import { uniqBy } from 'lodash'
import sortBy from 'lodash/sortBy'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import PageTitle from '../../_components/PageTitle'
import useTeams from '../../hooks/graphql/Teams/useTeams'
import { UserContext } from '../../UserContext'
import CreateTeamModal from './CreateTeamModal'
import { GET_USERS } from './queries'
import TeamsList from './TeamsList'

export default () => {
  const history = useHistory()
  const user = useContext(UserContext)

  const [modalVisible, setModalVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const {
    data: teamsData,
    loading: teamsLoading,
    refetch,
  } = useTeams({ noTeamName: 'Not part of a team' })

  const { data: usersData, loading: usersLoading } = useQuery(GET_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      name: searchTerm
    },
    skip: !searchTerm
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const [search] = useDebouncedCallback(val => {
    setSearchTerm(val)
  }, 500)

  const onModalOk = () => {
    notification.success({
      message: 'New team created!',
      description: 'The team was created successfully',
    })
    refetch()
    hideModal()
  }

  const onModalError = err => {
    notification.error({
      message: 'Oops!',
      description: 'There is already a team with that name.',
    })
  }

  const onModalCancel = () => {
    hideModal()
  }

  const teams = teamsData ? searchByName(teamsData.teams, usersData ? usersData.users.users : [], searchTerm) : []

  const loading = teamsLoading || usersLoading

  useEffect(() => {
    if (user.isAdmin() || user.isOwner() || user.isSuperUser()) {
      return
    }
    history.push('/home')
  }, [user])

  return (
    <div className="company">
      <Breadcrumb style={{ marginBottom: 8 }}>
        <Breadcrumb.Item>
          <Link to="/admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Teams</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle style={{ marginBottom: '32px' }}>Teams</PageTitle>

      <div className="teams-list">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '24px',
          }}
        >
          <div>
            <Input
              prefix={
                <Icon
                  type="search"
                  style={{ color: '#77779f', fontSize: '16px' }}
                />
              }
              suffix={false}
              placeholder="Search by a team or person's name"
              onChange={e => search(e.target.value)}
              loading={loading}
              style={{ width: 355 }}
            />
          </div>
          <div>
            <Button
              type="primary"
              icon="plus"
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={showModal}
            >
              Add team
            </Button>
          </div>
        </div>

        {loading && <Spin />}
        {!loading && (
          <TeamsList teams={teams} />
        )}

        <CreateTeamModal
          visible={modalVisible}
          onOk={onModalOk}
          onError={onModalError}
          onCancel={onModalCancel}
        />
      </div>
    </div>
  )
}

const searchByName = (allTeams, users, term) => {
  const teamsByTeamName = term
    ? allTeams.filter(team => team.name.toLowerCase().includes(term.toLowerCase()))
    : allTeams

  const teamsByUserName = users
    .filter(u => u.displayName.toLowerCase().includes(term.toLowerCase()))
    .reduce((acc, u) => {
      const team = u.team ? u.team : { id: 'no-team' }
      const matchingTeam = allTeams.find(t => t.id === team.id)
      if (!matchingTeam) {
        return acc
      }
      acc.push(matchingTeam)
      return acc
    }, [])

  const filteredTeams = uniqBy([...teamsByTeamName, ...teamsByUserName], t => t.id)

  return sortBy(filteredTeams, ['name'])
}
