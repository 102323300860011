import gql from 'graphql-tag'

export const USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      avatarUrl
      firstName
      lastName
      jobTitle
      email
      status
      manager {
        id
        firstName
        lastName
        email
        displayName
      }
      viewingManagers {
        id
        firstName
        lastName
        email
        displayName
      }
      directReports {
        id
        firstName
        lastName
        displayName
        email
      }
      team {
        id
        name
      }
    }
  }
`
