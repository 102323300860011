import './styles.less'

import { useMutation } from '@apollo/react-hooks'
import { Dropdown, Menu } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import IntegrationsContext from '../../../Admin/Integrations/context'
import UpdatableImage from '../UpdatableImage'
import { UPDATE_USER_AVATAR } from './queries'

const UserAvatar = ({ user, width = 72, height = 72 }) => {
  const integrationsContext = useContext(IntegrationsContext)

  const [updateAvatar] = useMutation(UPDATE_USER_AVATAR)
  const history = useHistory()

  const [image, setImage] = useState({
    src: user.avatarThumbnail,
    isPlaceholder: user.isPlaceholderAvatar,
  })

  const onLogoutClick = () => {
    user.logout()
    integrationsContext.reset()
    history.push('/login')
  }

  useEffect(() => {
    setImage({
      src: user.avatarThumbnail,
      isPlaceholder: user.isPlaceholderAvatar,
    })
  }, [user.avatarThumbnail])

  const onUpdateImageSubmit = val => {
    return updateAvatar({
      variables: {
        file: val.image.image,
        userId: user.id,
        thumbnail: {
          top: val.crop.top,
          left: val.crop.left,
          size: val.crop.width,
          currentWidth: val.image.width,
          currentHeight: val.image.height,
        },
      },
    })
      .then(() => user.refetchUser())
      .then(() =>
        setImage({
          src: user.avatarThumbnail,
          isPlaceholder: false,
        }),
      )
  }

  return (
    <div className="user-avatar">
      <div className="user-avatar-image-container" style={{ width, height }}>
        <UpdatableImage
          src={image.src}
          originalSrc={user.avatarFull}
          alt={`${user.firstName}'s avatar`}
          width={width}
          height={height}
          icon="camera"
          onSubmit={onUpdateImageSubmit}
          isPlaceholder={image.isPlaceholder}
        ></UpdatableImage>
      </div>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item key={'account'}>
              <Link to="/account">My account</Link>
            </Menu.Item>
            <Menu.Item key={'profile'}>
              <Link to="/profile">My activity</Link>
            </Menu.Item>
            <Menu.Item onClick={onLogoutClick}>Log out</Menu.Item>
          </Menu>
        }
      >
        <img
          alt="cog"
          className="user-avatar-icon"
          data-cy="user-cog"
          width="18"
          height="18"
          src="https://storage.googleapis.com/howamigoing-assets/app/icon-cog.svg"
        />
      </Dropdown>
    </div>
  )
}

export default UserAvatar
