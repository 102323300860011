import gql from 'graphql-tag'

export const GET_DRAFT = gql`
  query getDraft($feedbackId: String!) {
    draft: getDraft(feedbackId: $feedbackId) {
      id
      data
      lastSynced
    }
  }
`

export const SYNC_DRAFT = gql`
  mutation syncDraft($feedbackId: String!, $data: JSON!, $lastSynced: Float!) {
    syncDraft(
      syncDraftData: {
        feedbackId: $feedbackId
        data: $data
        lastSynced: $lastSynced
      }
    ) {
      id
      data
    }
  }
`
