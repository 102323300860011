import { Button, notification } from 'antd'
import React, { useContext, useState } from 'react'
import Emoji from 'react-emoji-render'
import { useHistory } from 'react-router-dom'

import ConfirmationModal from '../../../_components/ConfirmationModal'
import { track } from '../../../_helpers/analytics'
import GoodVibes from '../../../GoodVibes'
import useDismissTodo from '../../../hooks/graphql/Todos/useDismissTodo'
import { UserContext } from '../../../UserContext'
import DismissButton from './DismissButton'

const Icon = () => {
  return (
    <div
      style={{
        width: 48,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'solid 3px #ffffff',
        borderRadius: '20px',
        boxShadow: '0 8px 16px 0 rgba(40, 23, 101, 0.16)',
        fontSize: '20px',
      }}
    >
      <Emoji text=":star2:" />
    </div>
  )
}

export default ({ id }) => {
  const history = useHistory()
  const assignedTo = useContext(UserContext)
  const [dismissTodo] = useDismissTodo()
  const [askModalVisible, setAskModalVisible] = useState(false)
  const [dismissModalVisible, setDismissModalVisible] = useState(false)

  const showDismissModal = () => setDismissModalVisible(true)
  const hideDismissModal = () => setDismissModalVisible(false)

  const showAskModal = () => setAskModalVisible(true)
  const hideAskModal = () => setAskModalVisible(false)

  const handleDismissModalOk = async () => {
    try {
      await dismissTodo({
        variables: {
          todoId: id,
        },
        refetchQueries: ['todos'],
      })
      hideDismissModal()
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description:
          'There was a problem dismissing the todo. Please try again.',
      })
    }
  }

  const onSendGoodVibeClicked = () => {
    showAskModal()
    track('todo.goodVibe.opened', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  const onGoodVibeSent = ({ recipientId }) => {
    hideAskModal()
    track('todo.goodVibe.asked', {
      user: assignedTo.email,
      org: assignedTo.org.name,
      recipientId,
    })
    history.push('/goodVibes')
  }

  const onAskQuestionCancelled = () => {
    hideAskModal()
    track('todo.goodVibe.closed', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  return (
    <>
      <div className="dashboard-todo">
        <div className="dashboard-todo-icon">
          <Icon />
        </div>
        <div className="dashboard-todo-contents">
          <div style={{ fontFamily: 'museo, serif' }}>
            Who would you like to thank today?
          </div>
          <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
            Don't hold back... everyone loves to be praised for a job well done.
          </div>
        </div>
        <Button type="primary" size="small" onClick={onSendGoodVibeClicked}>
          Send Good vibes
        </Button>
        <DismissButton onClick={showDismissModal} />
      </div>
      <GoodVibes.SendGoodVibePage
        visible={askModalVisible}
        onOk={onGoodVibeSent}
        onCancel={onAskQuestionCancelled}
      />
      <ConfirmationModal
        visible={dismissModalVisible}
        onOk={handleDismissModalOk}
        onCancel={hideDismissModal}
        title="Are you sure?"
        okText="Yes, I'm sure"
      >
        Now's your chance to ask someone a question with complete anonymity.
      </ConfirmationModal>
    </>
  )
}
