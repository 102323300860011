import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import React from 'react'

import Stat from '../Stat'
import { USER_GOOD_VIBES } from './queries'

export default ({ timeInterval, userId }) => {
  const { data, loading } = useQuery(USER_GOOD_VIBES, {
    fetchPolicy: 'cache-first',
    variables: {
      userId,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    },
  })

  const stats = {
    vibesReceived: data
      ? data.userGoodVibesInsight.find(
          x => x.key === 'good_vibes_user_received',
        ).value
      : 0,
    vibesSent: data
      ? data.userGoodVibesInsight.find(x => x.key === 'good_vibes_user_sent')
          .value
      : 0,
    praiseGiven: data
      ? data.userGoodVibesInsight.find(
          x => x.key === 'good_vibes_user_praise_given',
        ).value
      : 0,
    commentsMade: data
      ? data.userGoodVibesInsight.find(
          x => x.key === 'good_vibes_user_comments_sent',
        ).value
      : 0,
  }

  return (
    <Card className="up__stats-card">
      <Stat
        title="Vibes received"
        number={stats.vibesReceived}
        loading={loading}
      />
      <Stat title="Vibes sent" number={stats.vibesSent} loading={loading} />
      <Stat title="Praise given" number={stats.praiseGiven} loading={loading} />
      <Stat
        title="Comments made"
        number={stats.commentsMade}
        loading={loading}
      />
    </Card>
  )
}
