import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useState } from 'react'

import { GET_TEAMS_LIST } from '../queries'
import { GET_USERS_WITHOUT_A_TEAM } from '../TeamsList/queries'
import { DELETE_TEAM } from './queries'

export default ({ teamId, visible, onOk, onError, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [deleteTeam] = useMutation(DELETE_TEAM, {
    refetchQueries: [
      {
        query: GET_TEAMS_LIST,
      },
      {
        query: GET_USERS_WITHOUT_A_TEAM,
      },
    ],
  })

  const handleSubmit = async evt => {
    evt.stopPropagation()
    try {
      setLoading(true)
      await deleteTeam({
        variables: {
          teamId,
        },
      })
      setLoading(true)
      if (onOk) {
        onOk(teamId)
      }
    } catch (e) {
      setLoading(false)
      if (onError) {
        onError(e)
      }
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        title="Delete team"
        visible={visible}
        onCancel={onCancel}
        onOk={handleSubmit}
        confirmLoading={loading}
        okText="Delete"
        cancelText="Cancel"
        cancelButtonProps={{ disabled: loading, type: 'ghost' }}
        closable={false}
        maskClosable={!loading}
        bodyStyle={{ padding: '32px' }}
        centered
      >
        <div style={{ fontWeight: 300 }}>
          Are you sure you want to delete this team?
        </div>
        <div style={{ fontWeight: 300 }}>
          Note, the individual users within the team won't be deleted.
        </div>
      </Modal>
    </div>
  )
}
