import { Button } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Avatar, { AvatarName } from '../../../_components/Avatar'
import { track } from '../../../_helpers/analytics'

export default ({ assignedTo, assignedBy }) => {
  const location = useLocation()

  const handleClick = () => {
    if (location.pathname === '/') {
      track('todo.receiveGoodVibe.view', {
        user: assignedTo.email,
        org: assignedTo.org.name,
      })
    }
  }

  return (
    <div className="dashboard-todo">
      <Avatar user={assignedBy} />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>High five!</div>
        <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
          You've been sent Good vibes by <AvatarName user={assignedBy} />
        </div>
      </div>
      <Link to={`/goodVibes`} onClick={handleClick}>
        <Button type="primary" size="small">
          Check 'em out
        </Button>
      </Link>
    </div>
  )
}
