import './style.less'

import { useApolloClient } from '@apollo/react-hooks'
import { Card } from 'antd'
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FixedSizeList, areEqual } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'
import { useDebouncedCallback } from 'use-debounce'

import SpinContainer from '../../../_components/SpinContainer'
import UsersEmptyState from '../../../_components/UsersEmptyState'
import useTeams from '../../../hooks/graphql/Teams/useTeams'
import { formatSelection } from '../../pages/helpers'
import CardHeader from './CardHeader'
import ListItem from './ListItem'
import NoResults from './NoResults'
import { GET_COLLEAGUES } from './queries'

const cardBodyStyle = { padding: 0 }

const PAGE_SIZE = 30

const ColleagueList = React.forwardRef(
(
  {
    height,
    withSearch,
    withSelectAll,
    excludeUserIds = [],
    renderTooltip,
    selection,
    onLoaded: onLoadedProp,
    onSelect,
    initialSelectedFilterEnabled,
    onInvited,
  },
  ref,
) => {
  const client = useApolloClient()

  const {
    data: teamsData,
    loading: teamsLoading,
    refetch: refetchTeams,
  } = useTeams({
    noTeamName: 'Not part of a team',
    variables: {
      excludeUserIds,
      userTypes: ['invited', 'active'],
    },
  })

  const listRef = useRef(null)
  const [list, setList] = useState({})
  const [maxListSize, setMaxListSize] = useState()
  const [maxSelectable, setMaxSelectable] = useState()
  const [searchText, setSearchText] = useState('')
  const [searchTeamId, setSearchTeamId] = useState()
  const [fetchingUsers, setFetchingUsers] = useState(true)
  const [selectedFilterEnabled, setSelectedFilterEnabled] = useState(
    initialSelectedFilterEnabled,
  )
  const [selectedTeams, setSelectedTeams] = useState(
    selection && selection.teams ? selection.teams : {},
  )
  const [selectedUsers, setSelectedUsers] = useState(
    selection && selection.users ? selection.users : {},
  )
  const [initializing, setInitializing] = useState(true)
  const [reloadingUsers, setReloadingUsers] = useState(false)

  const [tryLoadMoreUsers] = useDebouncedCallback((startIndex, stopIndex) => {
    if (!shouldLoadMoreUsers(startIndex, stopIndex)) {
      return Promise.resolve()
    }
    return loadUsers({
      offset: startIndex,
      existingUsers: list,
      searchTerm: searchText,
      teamId: searchTeamId,
      onlySelected: selectedFilterEnabled,
    })
  }, 800)

  const hasSelectedAll = () => {
    const selectedTeamIds = Object.keys(selectedTeams)
    return (
      teamsData &&
      Object.keys(teamsData.teams).length === selectedTeamIds.length &&
      selectedTeamIds
        .map(teamId => selectedTeams[teamId])
        .every(x => excludeExcludedUsers(x.excludedUsers).length === 0)
    )
  }

  const excludeExcludedUsers = (users = []) => {
    return users.filter(
      user => excludeUserIds.findIndex(uid => uid === user.id) === -1,
    )
  }

  const fetchUsers = async ({ offset, searchTerm, teamId, onlySelected }) => {
    setFetchingUsers(true)
    const formattedSelection = onlySelected
      ? formatSelection(selection)
      : undefined
    const result = await client.query({
      query: GET_COLLEAGUES,
      variables: {
        limit: PAGE_SIZE,
        offset,
        name: searchTerm || undefined,
        email: searchTerm || undefined,
        teamId: teamId && teamId !== 'no-team' ? teamId : undefined,
        hasTeam: teamId === 'no-team' ? false : undefined,
        excludeIds: excludeUserIds,
        selection: formattedSelection,
      },
      fetchPolicy: 'no-cache',
    })
    setFetchingUsers(false)
    return result.data.users
  }

  const shouldLoadMoreUsers = (startIndex, stopIndex) => {
    if (fetchingUsers) {
      return false
    }
    return [...Array(stopIndex - startIndex + 1)].some(i => !list[i])
  }

  const shiftUp = ({ collection, from, amount = 1 }) => {
    Object.keys(collection)
      .map(i => parseInt(i, 10))
      .filter(i => i >= from)
      .reverse()
      .forEach(i => (collection[i + amount] = collection[i]))
  }
  const shiftDown = ({ collection, from, amount = 1 }) => {
    Object.keys(collection)
      .map(i => parseInt(i, 10))
      .filter(i => i <= from)
      .forEach(i => (collection[i - amount] = collection[i]))
  }

  const loadUsers = async ({
    offset,
    existingUsers,
    searchTerm,
    teamId,
    onlySelected,
  }) => {
    const isReloadingUsers = Object.keys(existingUsers).length === 0
    if (isReloadingUsers) {
      setReloadingUsers(true)
    }
    const numHeadersBeforeOffset = [...Array(offset).keys()].reduce(
      (acc, i) => {
        const existingItem = existingUsers[i]
        if (!existingItem) {
          return acc
        }
        if (existingItem.team || existingItem.key === 'selectAll') {
          return acc + 1
        }
        return acc
      },
      0,
    )
    const fetchUsersResult = await fetchUsers({
      offset: offset - numHeadersBeforeOffset,
      searchTerm,
      teamId,
      onlySelected,
    })
    const newUsersMappedByIndex = fetchUsersResult.users.reduce(
      (memo, x, i) => {
        if (!x.team) {
          x.team = {
            id: 'no-team',
            name: 'No team',
          }
        }
        memo[offset + i] = {
          user: x,
          key: x.id,
        }
        return memo
      },
      {},
    )
    const shouldShowSelectAll = !searchTerm && !teamId && !onlySelected
    const newList = {
      ...existingUsers,
      ...newUsersMappedByIndex,
    }

    const iteration = i => {
      const current = newList[i]
      const prev = newList[i - 1]

      // Insert select all when not filtering by team
      if (i === 0 && shouldShowSelectAll && !current.selectAll) {
        shiftUp({ collection: newList, from: i, amount: 2 })
        newList[i] = { selectAll: true, key: 'selectAll' }
        const team = teamsData.teams.find(
          team => team.id === current.user.team.id,
        )
        newList[i + 1] = {
          team,
          key: team.id,
        }
        return i + 1
      }

      // Remove select all when not filtering by team
      if (i === 0 && !shouldShowSelectAll && current.selectAll) {
        shiftDown({ collection: newList, from: i })
        delete newList[-1]
        return i + 1
      }

      // Prevents duplicate team header when select all is removed
      if (i === 1 && !shouldShowSelectAll && prev.team) {
        return i + 1
      }

      if (
        (i === 0 || i === 1) &&
        !current.team &&
        !current.selectAll &&
        current.user.team
      ) {
        shiftUp({ collection: newList, from: i - 1 })
        const team = teamsData.teams.find(
          team => team.id === current.user.team.id,
        )
        newList[i] = {
          team,
          key: team.id,
        }
        return i + 1
      }

      if (
        prev &&
        current &&
        prev.user &&
        current.user &&
        prev.user.team &&
        current.user.team &&
        prev.user.team.id !== current.user.team.id
      ) {
        shiftUp({ collection: newList, from: i })
        const team = teamsData.teams.find(
          team => team.id === current.user.team.id,
        )
        newList[i] = {
          team,
          key: team.id,
        }
        return i + 1
      }

      return i + 1
    }

    let i = 0
    while (i < Object.keys(newList).length) {
      i = iteration(i)
    }

    setList(newList)
    const numHeaders = Object.keys(newList)
      .map(index => newList[index])
      .filter(x => !x.user).length
    const currentSize = Object.keys(newList).length
    let nextSize = currentSize + PAGE_SIZE
    if (nextSize - numHeaders > fetchUsersResult.total) {
      nextSize = fetchUsersResult.total + numHeaders
    }
    setMaxListSize(nextSize)

    if (isReloadingUsers) {
      if (listRef.current) {
        listRef.current.scrollTo(0)
      }
      setReloadingUsers(false)
    }
  }

  const getMaxSelectable = () => {
    return teamsData.teams
      .map(x => x.totalMembers)
      .reduce((total, count) => total + count, 0)
  }

  const onLoaded = () => {
    if (!onLoadedProp) return
    const newSelection = {
      users: selectedUsers,
      teams: selectedTeams,
      total: getNumSelected({ teams: selectedTeams, users: selectedUsers }),
      max: getMaxSelectable(),
      excludeUserIds,
      disabled: selection.disabled,
    }
    onSelect(newSelection)
  }

  const onSearchChange = val => {
    setSearchText(val)
    loadUsers({
      offset: 0,
      existingUsers: {},
      searchTerm: val,
      teamId: searchTeamId,
      onlySelected: selectedFilterEnabled,
    })
  }

  const onFilterChange = val => {
    setSearchTeamId(val)
    loadUsers({
      offset: 0,
      existingUsers: {},
      searchTerm: searchText,
      teamId: val,
      onlySelected: selectedFilterEnabled,
    })
  }

  const isSelectedUser = user => {
    if (!user) {
      return false
    }
    if (hasSelectedAll()) {
      return true
    }
    if (
      user.team &&
      selectedTeams[user.team.id] &&
      !selectedTeams[user.team.id].excludedUsers.find(x => x.id === user.id)
    ) {
      return true
    }
    return !!selectedUsers[user.id]
  }

  const isSelectedTeam = team => {
    if (hasSelectedAll()) {
      return true
    }
    return (
      selectedTeams[team.id] &&
      selectedTeams[team.id].excludedUsers.length === 0
    )
  }

  const isItemSelected = item => {
    if (!item) {
      return false
    }
    if (item.selectAll) {
      return hasSelectedAll()
    }
    if (item.team) {
      return isSelectedTeam(item.team)
    }
    if (item.user) {
      return isSelectedUser(item.user)
    }
    return false
  }

  const isItemDisabled = item => {
    if (!item) {
      return false
    }
    if (!item.user) {
      return false
    }
    return selection.disabled && selection.disabled[item.user.id]
  }

  const selectAll = () => {
    const allTeams =
      teamsData && teamsData.teams
        ? teamsData.teams.reduce((acc, team) => {
            acc[team.id] = {
              size: team.totalMembers, // Replace with actual team count
              excludedUsers: [],
            }
            return acc
          }, {})
        : undefined
    afterSelect({ teams: allTeams, users: {} })
  }

  const deselectAll = () => {
    afterSelect({ teams: {}, users: {} })
  }

  const selectTeam = team => {
    const newSelectedTeams = {
      ...selectedTeams,
      [team.id]: {
        size: team.totalMembers,
        excludedUsers: [],
      },
    }
    afterSelect({ teams: newSelectedTeams })
  }

  const deselectTeam = team => {
    const newSelectedTeams = {
      ...selectedTeams,
    }
    delete newSelectedTeams[team.id]
    const newSelectedUsers = Object.keys(selectedUsers)
      .map(userId => selectedUsers[userId])
      .filter(user => {
        if (!user.team) {
          return true
        }
        return !user.team.id === team.id
      })
      .reduce((memo, user) => {
        memo[user.id] = user
        return memo
      }, {})
    afterSelect({ teams: newSelectedTeams, users: newSelectedUsers })
  }

  const selectUser = user => {
    const selectedTeam =
      user.team && selectedTeams[user.team.id]
        ? selectedTeams[user.team.id]
        : undefined
    let newSelectedTeams
    if (selectedTeam) {
      newSelectedTeams = {
        ...selectedTeams,
        [user.team.id]: {
          ...selectedTeam,
          excludedUsers: selectedTeam.excludedUsers.filter(
            x => x.id !== user.id,
          ),
        },
      }
    }
    const newSelectedUsers = {
      ...selectedUsers,
      [user.id]: user,
    }
    afterSelect({ teams: newSelectedTeams, users: newSelectedUsers })
  }

  const deselectUser = user => {
    const hasTeam = !!user.team
    const selectedTeam = selectedTeams[user.team.id]
    const teamHasExcludedUsers =
      hasTeam && selectedTeam && selectedTeam.excludedUsers.length > 0
    let newSelectedTeams
    if (hasTeam && (isSelectedTeam(user.team) || teamHasExcludedUsers)) {
      const newSelectedTeam = {
        ...selectedTeam,
        excludedUsers: [...selectedTeam.excludedUsers, user],
      }
      newSelectedTeams = {
        ...selectedTeams,
        [user.team.id]: newSelectedTeam,
      }
      if (newSelectedTeam.size === newSelectedTeam.excludedUsers.length) {
        delete newSelectedTeams[user.team.id]
      }
    }

    const newSelectedUsers = {
      ...selectedUsers,
    }
    delete newSelectedUsers[user.id]
    afterSelect({ teams: newSelectedTeams, users: newSelectedUsers })
  }

  const toggleSelectItem = item => {
    if (!item) {
      return false
    }
    const isSelected = isItemSelected(item)
    if (item.selectAll) {
      return !isSelected ? selectAll() : deselectAll()
    }
    if (item.team) {
      return !isSelected ? selectTeam(item.team) : deselectTeam(item.team)
    }
    if (item.user) {
      return !isSelected ? selectUser(item.user) : deselectUser(item.user)
    }
    return false
  }

  const preserveDeselectableUsers = collection => {
    if (selection.disabled) {
      Object.keys(selection.disabled).forEach(userId => {
        if (collection[userId]) return
        collection[userId] = {
          ...selection.disabled[userId],
        }
      })
    }
    return collection
  }

  const afterSelect = ({ teams: teamsInput, users: usersInput }) => {
    let teams = teamsInput || selectedTeams
    let users = preserveDeselectableUsers(usersInput || selectedUsers)
    users = Object.keys(users).reduce((acc, uid) => {
      const u = users[uid]
      if (u.teamId && teams[u.teamId]) {
        return acc
      }
      acc[uid] = u
      return acc
    }, {})
    setSelectedTeams(teams)
    setSelectedUsers(users)

    if (!onSelect) {
      return
    }
    const newSelection = {
      users,
      teams,
      total: getNumSelected({ teams, users }),
      max: getMaxSelectable(),
      excludeUserIds,
      disabled: selection.disabled,
    }
    onSelect(newSelection)
  }

  const onItemSelected = useCallback(toggleSelectItem, [
    teamsData,
    selectedTeams,
    selectedUsers,
  ])
  const onItemDeselected = useCallback(toggleSelectItem, [
    teamsData,
    selectedTeams,
    selectedUsers,
  ])

  const getNumSelected = ({ teams, users }) => {
    const teamIds = Object.keys(teams)
    const totalFromSelectedTeams = teamIds
      .map(
        teamId =>
          teams[teamId].size -
          excludeExcludedUsers(teams[teamId].excludedUsers).length,
      )
      .reduce((total, teamCount) => total + (teamCount || 0), 0)
    const totalFromSelectedUsers = Object.keys(users)
      .map(userId => users[userId])
      .filter(user => {
        if (!user.team) {
          return true
        }
        return !teamIds.find(teamId => teamId === user.team.id)
      }).length
    return totalFromSelectedTeams + totalFromSelectedUsers
  }

  const keyExtractor = useCallback(
    index => {
      return list[index] ? list[index].key : `not_loaded_${index}`
    },
    [list],
  )

  const canSelect = item => {
    if (!item) {
      return false
    }
    if (item.team && item.team.id === 'no-team') {
      return false
    }
    if (item.team && selectedFilterEnabled) {
      return false
    }
    if (!item.team) {
      return false
    }
    return !searchText
  }

  const renderItem = ({ data, index, style }) => {
    return (
      <ListItem
        item={data[index]}
        index={index}
        style={style}
        selected={isItemSelected(data[index])}
        disabled={isItemDisabled(data[index])}
        enableSelect={canSelect(data[index])}
        onSelected={onItemSelected}
        onDeselected={onItemDeselected}
        tooltip={renderTooltip ? renderTooltip(data[index]) : undefined}
      />
    )
  }

  const isItemLoaded = useCallback(
    index => {
      return list[index]
    },
    [list],
  )

  const numSelected = getNumSelected({
    teams: selectedTeams,
    users: selectedUsers,
  })
  const hasResults = Object.keys(list).length > 0 && !initializing
  const mustInvite = maxSelectable < 2

  const teamListSorter = (a, b) => {
    if (a.id === 'no-team' || b.id === 'no-team') {
      return 1
    }
    return a.name > b.name ? 1 : -1
  }

  const filters = useMemo(
    () => [
      {
        label: 'All teams',
        loading: teamsLoading,
        options:
          teamsData &&
          teamsData.teams.sort(teamListSorter).map(x => {
            return {
              label: x.name,
              value: x.id,
            }
          }),
      },
    ],
    [teamsLoading],
    areEqual,
  )

  const setListRef = useCallback(
    setRef => ref => {
      if (!ref) {
        return
      }
      listRef.current = ref
      return setRef(ref)
    },
    [],
  )

  const reload = async () => {
    await refetchTeams()
    await loadUsers({
      offset: 0,
      existingUsers: list,
      searchTerm: searchText,
      teamId: searchTeamId,
      onlySelected: selectedFilterEnabled,
    })
    setTimeout(() => setMaxSelectable(getMaxSelectable()))
    if (onInvited) {
      onInvited()
    }
  }

  useEffect(() => {
    if (teamsLoading) {
      return
    }
    const handler = async () => {
      setMaxSelectable(getMaxSelectable())

      const selectedTeamsViaIndividualUsers = teamsData.teams.reduce((acc, team) => {
        const individualUsersSelectedForTeam = Object.keys(selectedUsers)
          .map(uid => selectedUsers[uid].teamId)
          .filter(teamId => teamId === team.id)
        if (individualUsersSelectedForTeam.length === team.totalMembers) {
          acc[team.id] = {
            id: team.id
          }
        }
        return acc
      }, {})

      if (Object.keys(selectedTeamsViaIndividualUsers).length > 0) {
        const newSelectedUsers = Object.keys(selectedUsers).reduce((acc, uid) => {
          const selectedUser = selectedUsers[uid]
          const teamId = selectedUsers[uid].teamId
          if (teamId && selectedTeamsViaIndividualUsers[teamId]) {
            return acc
          }
          acc[uid] = selectedUser
          return acc
        }, {})
        setSelectedUsers(newSelectedUsers)
      }

      const newSelectedTeams = {...selectedTeams, ...selectedTeamsViaIndividualUsers}
      if (Object.keys(newSelectedTeams).length > 0) {
        const updatedSelectedTeams = Object.keys(newSelectedTeams).reduce(
          (acc, teamId) => {
            const team = teamsData.teams.find(x => x.id === teamId)
            if (!team) {
              return acc
            }
            const excludedUsers = newSelectedTeams[teamId].excludedUsers || []
            acc[team.id] = {
              size: team.totalMembers,
              excludedUsers: [...excludedUsers],
            }
            return acc
          },
          {},
        )
        setSelectedTeams(updatedSelectedTeams)
      }
      await loadUsers({
        offset: 0,
        existingUsers: {},
        teamId: searchTeamId,
        onlySelected: selectedFilterEnabled,
      })
      setInitializing(false)
    }
    handler()
  }, [teamsLoading])

  useEffect(() => {
    if (initializing) return
    onLoaded()
  }, [initializing])

  const onNumSelectedPress = async enabled => {
    setSelectedFilterEnabled(enabled)
    await loadUsers({
      offset: 0,
      existingUsers: {},
      teamId: searchTeamId,
      onlySelected: enabled,
    })
  }

  useImperativeHandle(ref, () => {
    return {
      reload,
    }
  })

  return (
    <Card
      className="colleague-list"
      title={
        (withSearch || withSelectAll) && (
          <CardHeader
            enableSearch={withSearch}
            onSearchChange={onSearchChange}
            onFilterChange={onFilterChange}
            disabled={mustInvite}
            filters={filters}
            numSelected={numSelected}
            onNumSelectedPress={onNumSelectedPress}
          />
        )
      }
      bodyStyle={cardBodyStyle}
    >
      {mustInvite ? (
        <UsersEmptyState
          style={{ padding: '40px 0' }}
          onInvited={reload}
          orgUsersNumber={maxSelectable}
        />
      ) : (
        <SpinContainer loading={initializing || reloadingUsers}>
          {!hasResults ? (
            <NoResults
              style={{ height }}
              description={initializing ? 'Loading...' : undefined}
            />
          ) : (
            <InfiniteLoader
              loadMoreItems={tryLoadMoreUsers}
              isItemLoaded={isItemLoaded}
              minimumBatchSize={PAGE_SIZE}
              itemCount={maxListSize}
              threshold={0}
            >
              {({ onItemsRendered, ref: refSetter }) => {
                return (
                  <FixedSizeList
                    itemCount={maxListSize}
                    height={height}
                    itemSize={56}
                    itemKey={keyExtractor}
                    ref={setListRef(refSetter)}
                    onItemsRendered={onItemsRendered}
                    itemData={list}
                  >
                    {renderItem}
                  </FixedSizeList>
                )
              }}
            </InfiniteLoader>
          )}
        </SpinContainer>
      )}
    </Card>
  )
})

export default ColleagueList
