import { Button, Modal, notification } from 'antd'
import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import SlackIcon from '../../../_assets/img/integrations/slack.svg'
import { track } from '../../../_helpers/analytics'
import useDismissTodo from '../../../hooks/graphql/Todos/useDismissTodo'
import { UserContext } from '../../../UserContext'

const Icon = () => {
  return (
    <div
      style={{
        width: 48,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'solid 3px #ffffff',
        borderRadius: '20px',
        boxShadow: '0 8px 16px 0 rgba(40, 23, 101, 0.16)',
      }}
    >
      <img src={SlackIcon} alt="Slack" style={{ width: 22, height: 22 }} />
    </div>
  )
}

export default ({ id }) => {
  const assignedTo = useContext(UserContext)
  const [dismissTodo, { loading: dismissing }] = useDismissTodo()
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)

  const handleInstallSlack = () => {
    track('todo.installSlack.clicked', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  const handleDismissClick = () => {
    setVisibleConfirmModal(true)
  }

  const handleConfirmModalOk = () => {
    try {
      dismissTodo({
        variables: {
          todoId: id,
        },
        refetchQueries: ['todos'],
      })
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description:
          'There was a problem dismissing the todo. Please try again.',
      })
    }
  }

  return (
    <div className="dashboard-todo">
      <div className="dashboard-todo-icon">
        <Icon />
      </div>
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>
          Install our awesome Slack integration
        </div>
        <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
          Never miss out on notifications about your feedback events
        </div>
      </div>
      <Link to={`/admin/integrations/slack`} onClick={handleInstallSlack}>
        <Button type="primary" size="small">
          Install Slack
        </Button>
      </Link>
      <Button
        type="ghost"
        size="small"
        onClick={handleDismissClick}
        style={{ marginLeft: 8 }}
      >
        Dismiss
      </Button>
      <Modal
        title="Are you sure?"
        okText="Yes, I'm sure"
        cancelText="Cancel"
        visible={visibleConfirmModal}
        onCancel={() => setVisibleConfirmModal(false)}
        onOk={handleConfirmModalOk}
        cancelButtonProps={{ disabled: dismissing }}
        okButtonProps={{
          disabled: dismissing,
          loading: dismissing,
          type: 'accent',
        }}
        centered
      >
        You can always visit the Integrations page if you decide to change your
        mind later.
      </Modal>
    </div>
  )
}
