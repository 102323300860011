import gql from 'graphql-tag'

export const SEND_GOOD_VIBE = gql`
  mutation($recipientIds: [String!]!, $fact: String!, $feeling: String!) {
    sendGoodVibe(recipientIds: $recipientIds, fact: $fact, feeling: $feeling) {
      id
      recipients {
        id
        firstName
        lastName
        email
      }
      creator {
        id
        firstName
        lastName
        email
      }
      sentAt
      fact
      feeling
    }
  }
`

export const NUM_USERS = gql`
  query numUsers {
    users(offset: 0, limit: 0) {
      total
    }
  }
`
