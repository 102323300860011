import { Icon, Tooltip } from 'antd'
import React from 'react'

const SectionHeader = ({ tooltip }) => {
  return (
    <div className="notifications-page__section-header">
      <div>Notification</div>
      <div>
        When sent?
        <Tooltip title={tooltip} overlayClassName="notifications-page__tooltip">
          <Icon
            type="question-circle"
            style={{ color: '#77779f', marginLeft: '4px' }}
          />
        </Tooltip>
      </div>
    </div>
  )
}

export default SectionHeader
