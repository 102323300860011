import gql from 'graphql-tag'

const getDpr = () => {
  if (window.devicePixelRatio && window.devicePixelRatio > 1) {
    return 2
  }
  return 1
}

export const CURRENT_USER = gql`
  query currentUser {
    user: currentUser {
      id
      createdAt
      type
      role
      email
      emailStatus
      firstName
      lastName
      isPlaceholderAvatar
      avatarUrl
      avatarThumbnail: avatarUrl(dpr: ${getDpr()}, size: "thumbnail", fallbackToPlaceholder: true)
      avatarFull: avatarUrl(size: "full")
      jobTitle
      authMethod
      renewalToken
      intercomUserHash
      onboarded
      onboardingTasks
      notificationSettings
      org {
        id
        name
        data
        hasSAML
      }
      team {
        id,
        name
      }
      account {
        id
      }
      manager {
        id
        firstName
        lastName
        displayName
        email
      }
      directReports {
        id
        firstName
        lastName
        displayName
        email
      }
      viewingManagers {
        id
        firstName
        lastName
        displayName
        email
      }
      viewingManagerOf {
        id
        firstName
        lastName
        displayName
        email
      }
      readonlyProperties
      isSAMLUser
      isSentimentAnalysisActivated
    }
  }
`

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password)
  }
`

export const KEEP_SESSION_ALIVE = gql`
  mutation keepSessionAlive {
    token: keepSessionAlive
  }
`

export const SEND_PASSWORD_RESET_LINK = gql`
  mutation sendPasswordRestLink($email: String!) {
    sendPasswordRestLink(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($id: String!, $token: String!, $password: String!) {
    resetPassword(id: $id, token: $token, password: $password)
  }
`
