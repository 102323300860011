import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import React, { useState } from 'react'

import OptionsToggle, { OPTIONS } from '../../OptionsToggle'
import Stat from '../Stat'
import { USER_THREE_SIXTY } from './queries'

export default ({ timeInterval, userId, style }) => {
  const [option, setOption] = useState(OPTIONS.asGatherer)

  const { data, loading } = useQuery(USER_THREE_SIXTY, {
    fetchPolicy: 'cache-first',
    variables: {
      userId,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    },
  })

  const stats = {
    feedbackGatherer: {
      peopleAsked: data
        ? data.userThreeSixtyInsight.find(
            x => x.key === 'three_sixty_users_people_asked',
          ).value
        : 0,
      peopleResponded: data
        ? data.userThreeSixtyInsight.find(
            x => x.key === 'three_sixty_users_people_responded',
          ).value
        : 0,
      responseRate:
        (data
          ? Math.floor(
              data.userThreeSixtyInsight.find(
                x => x.key === 'three_sixty_gatherer_response_rate',
              ).value * 100,
            )
          : 0) + '%',
    },
    feedbackGiver: {
      requestsReceived: data
        ? data.userThreeSixtyInsight.find(
            x => x.key === 'three_sixty_users_requests_received',
          ).value
        : 0,
      requestsAnswered: data
        ? data.userThreeSixtyInsight.find(
            x => x.key === 'three_sixty_users_requests_answered',
          ).value
        : 0,
      responseRate:
        (data
          ? Math.floor(
              data.userThreeSixtyInsight.find(
                x => x.key === 'three_sixty_giver_response_rate',
              ).value * 100,
            )
          : 0) + '%',
    },
  }

  const handleContentChange = updatedContent => {
    setOption(updatedContent)
  }

  return (
    <div style={style}>
      <OptionsToggle onChange={handleContentChange} />
      <Card className="up__stats-card">
        {option === OPTIONS.asGatherer && (
          <>
            <Stat
              title="People asked"
              number={stats.feedbackGatherer.peopleAsked}
              loading={loading}
            />
            <Stat
              title="People responded"
              number={stats.feedbackGatherer.peopleResponded}
              loading={loading}
            />
            <Stat
              title="Response rate"
              number={stats.feedbackGatherer.responseRate}
              loading={loading}
            />
          </>
        )}
        {option === OPTIONS.asGiver && (
          <>
            <Stat
              title="Requests received"
              number={stats.feedbackGiver.requestsReceived}
              loading={loading}
            />
            <Stat
              title="Requests answered"
              number={stats.feedbackGiver.requestsAnswered}
              loading={loading}
            />
            <Stat
              title="Response rate"
              number={stats.feedbackGiver.responseRate}
              loading={loading}
            />
          </>
        )}
      </Card>
    </div>
  )
}
