import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import moment from 'moment'
import React, { useMemo } from 'react'

import { GET_USER_TOTALS } from './queries'
import ShowPeopleAction from './ShowPeopleAction'
import Stat from './Stat'

const ACTIVE_USERS_DAY_RANGE = 90

const Stats = ({ onStatClick, ...props }) => {

  const activeUsersDataRange = useMemo(() => {
    return {
      startDate: moment().subtract(ACTIVE_USERS_DAY_RANGE, 'days').format('YYYY-MM-DD'),
      endDate: moment(),
    }
  }, [])

  const { data: userStats, loading: loadingUserStats } = useQuery(
    GET_USER_TOTALS,
    {
      variables: {
        offset: 0,
        limit: 0,
        notInvitedSearch: {
          types: ['created'],
        },
        pendingInviteSearch: {
          types: ['invited'],
        },
        deactivateSearch: {
          types: ["deactivated"]
        },
        adminUserSearch: {
          types: ["admin"]
        },
        noManagerSearch: {
          hasManager: false,
        },
        noTeamSearch: {
          hasTeam: false,
        },
        activeUsersStartDate: activeUsersDataRange.startDate,
        activeUsersEndDate: activeUsersDataRange.endDate,
      },
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    },
  )

  const handleOnActionClick = name => {
    if (!onStatClick) {
      return
    }
    onStatClick(name)
  }

  return (
    <Card
      {...props}
      className={`stats__container${props.className ? ` ${props.className}` : ''
        }`}
    >
      <div className="stats__stat-row">
        <div className="stats__stat-container">
          <Stat
            className="stats__stat-main"
            value={userStats ? userStats.totalUsers.total : 0}
            description="people in total"
            loading={loadingUserStats}
          />
        </div>
        <div className="stats__stat-container">
          <Stat
            className="stats__stat"
            loading={loadingUserStats}
            value={userStats ? userStats.adminUsers.total : 0}
            description={userStats && userStats.adminUsers.total > 1 ? 'Admins' : 'Admin'}
          />
          <Stat
            className="stats__stat"
            value={userStats ? userStats.notInvited.total : 0}
            loading={loadingUserStats}
            description={'not yet invited'}
            tooltip="You need to send email invites so the people you've added can join your account. Click 'Show people' to send invites now."
            footer={
              userStats &&
              userStats.notInvited.total > 0 && (
                <ShowPeopleAction
                  onClick={() => handleOnActionClick('not-invited')}
                />
              )
            }
          />
        </div>
        <div className="stats__stat-container">
          <Stat
            className="stats__stat"
            loading={loadingUserStats}
            value={userStats ? userStats.totalUsers.total - userStats.deactivateUsers.total : 0}
            description={'Active'}
          />
          <Stat
            className="stats__stat"
            value={userStats ? userStats.pendingInvite.total : 0}
            loading={loadingUserStats}
            description={`invited, not joined`}
            tooltip="You can resend email invites by clicking 'Show people' so they can join your account."
          />
        </div>
        <div className="stats__stat-container">
          <Stat
            className="stats__stat"
            loading={loadingUserStats}
            value={userStats ? userStats.deactivateUsers.total : 0}
            description={'Deactivated'}
          />
          <Stat
            className="stats__stat"
            loading={loadingUserStats}
            value={userStats ? userStats.noManager.total : 0}
            description={'without a manager'}
            tooltip="Managers can view their direct report(s) 360 feedback and are automatically included their feedback requests."
          />
        </div>
        <div className="stats__stat-container">
          <Stat
            className="stats__stat"
            value={userStats ? userStats.activeUsers[0].value : 0}
            description="logged in recently"
            tooltip={`People that have logged in during the last ${ACTIVE_USERS_DAY_RANGE} days`}
            loading={loadingUserStats}
          />
          <Stat
            className="stats__stat"
            value={userStats ? userStats.noTeam.total : 0}
            loading={loadingUserStats}
            description={`not in a team`}
          />
        </div>
      </div>
    </Card>
  )
}

export default Stats
