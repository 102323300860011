import { useMutation, useQuery } from '@apollo/react-hooks'
import { notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import AddPeopleModal from '../../../_components/AddPeopleModal'
import SuccessModalContext from '../../../_components/SuccessModal/context'
import { SendGoodVibe } from '../../components'
import { NUM_USERS, SEND_GOOD_VIBE } from './queries'

const SendGoodVibePage = ({ visible, onOk, onCancel }) => {
  const successModal = useContext(SuccessModalContext)

  const [addPeopleModalVisible, setAddPeopleModalVisible] = useState(false)
  const showAddPeopleModal = () => setAddPeopleModalVisible(true)
  const hideAddPeopleModal = () => setAddPeopleModalVisible(false)

  const [sendGoodVibeModalVisible, setSendGoodVibeModalVisible] = useState(
    false,
  )
  const showSendGoodVibeModal = () => setSendGoodVibeModalVisible(true)
  const hideSendGoodVibeModal = () => setSendGoodVibeModalVisible(false)

  const [sendGoodVibe] = useMutation(SEND_GOOD_VIBE, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })

  const { data: numUsersResponse } = useQuery(NUM_USERS, {
    fetchPolicy: 'no-cache',
  })

  const handleOnSendGoodVibeOk = async ({ recipientId, fact, feeling }) => {
    try {
      await sendGoodVibe({
        variables: {
          recipientIds: [recipientId],
          fact,
          feeling,
        },
      })
      successModal.show({
        message: `Good vibes are on their way!`,
      })
      if (onOk) {
        onOk({ recipientId, fact, feeling })
      }
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
      throw e
    }
  }

  const handleOnAddPeopleSuccess = () => {
    hideAddPeopleModal()
  }

  const handleOnAddPeopleClose = () => {
    hideAddPeopleModal()
    if (onCancel) {
      onCancel()
    }
  }

  const handleOnSendGoodVibeClose = () => {
    hideSendGoodVibeModal()
    if (onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    if (!numUsersResponse) {
      return
    }
    const hasColleaguesToSendGoodVibe =
      numUsersResponse &&
      numUsersResponse.users &&
      numUsersResponse.users.total > 1

    if (hasColleaguesToSendGoodVibe && visible) {
      showSendGoodVibeModal()
      hideAddPeopleModal()
    } else if (!hasColleaguesToSendGoodVibe && visible) {
      showAddPeopleModal()
      hideSendGoodVibeModal()
    } else {
      hideAddPeopleModal()
      hideSendGoodVibeModal()
    }
  }, [visible, numUsersResponse])

  return (
    <>
      <SendGoodVibe.Modal
        visible={sendGoodVibeModalVisible}
        onCancel={handleOnSendGoodVibeClose}
        onOk={handleOnSendGoodVibeOk}
      />
      <AddPeopleModal
        visible={addPeopleModalVisible}
        onClose={handleOnAddPeopleClose}
        onSuccess={handleOnAddPeopleSuccess}
      />
    </>
  )
}

export default SendGoodVibePage
