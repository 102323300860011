import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col, Form, Input, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import trimmedEmailValidator from '../_helpers/trimmedEmailValidator'
import trimmedMinlengthValidator from '../_helpers/trimmedMinLengthValidator'

const { Text } = Typography

export default ({ user, form }) => {
  const { getFieldDecorator } = form

  const [disabledFields, setDisabledFields] = useState({})

  useEffect(() => {
    if (!user.readonlyProperties) {
      return
    }
    const disabledFieldsDict = user.readonlyProperties.reduce(
      (memo, { name, reasons }) => {
        let key = name
        memo[key] = { reasons }
        return memo
      },
      {},
    )
    setDisabledFields(disabledFieldsDict)
  }, [])

  const isDisabledField = key => {
    return disabledFields[key]
  }

  const disabledFieldReasons = key => {
    const disabledField = disabledFields[key]
    return disabledField ? disabledField.reasons : []
  }

  const disabledFieldHelpMessage = key => {
    const reasons = disabledFieldReasons(key)
    if (!reasons) {
      return null
    }
    const personioReason = reasons.find(x => x.code === 'personio')
    if (personioReason) {
      return (
        <a
          href={personioReason.editUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginLeft: 12, fontSize: 14 }}
        >
          <span>Edit</span>
          <FontAwesomeIcon
            icon={['fal', 'external-link']}
            style={{ marginLeft: 4, fontSize: 12 }}
          />
        </a>
      )
    }
    return undefined
  }

  return (
    <Card
      title={<Text>Your contact details</Text>}
      style={{ marginTop: 0, marginBottom: '32px' }}
    >
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item
            label={<>First name{disabledFieldHelpMessage('firstName')}</>}
          >
            {getFieldDecorator('firstName', {
              initialValue: user.firstName ? user.firstName : undefined,
              rules: [
                ...(!isDisabledField('firstName')
                  ? [{ required: true, message: 'This field is required' }]
                  : []),
                {
                  validator: trimmedMinlengthValidator({
                    min: 1,
                    message: 'Your first name must have at least 1 character',
                  }),
                },
              ],
            })(
              <Input
                placeholder="First name"
                disabled={isDisabledField('firstName')}
              />,
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label={<>Last name{disabledFieldHelpMessage('lastName')}</>}
          >
            {getFieldDecorator('lastName', {
              initialValue: user.lastName ? user.lastName : undefined,
              rules: [
                ...(!isDisabledField('lastName')
                  ? [{ required: true, message: 'This field is required' }]
                  : []),
                {
                  validator: trimmedMinlengthValidator({
                    min: 1,
                    message: 'Your last name must have at least 1 character',
                  }),
                },
              ],
            })(
              <Input
                placeholder="Last name"
                disabled={isDisabledField('lastName')}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item label={<>E-mail{disabledFieldHelpMessage('email')}</>}>
            {getFieldDecorator('email', {
              initialValue: user.email ? user.email : undefined,
              rules: [
                ...(!isDisabledField('email')
                  ? [{ required: true, message: 'This field is required' }]
                  : []),
                { validator: trimmedEmailValidator },
              ],
            })(
              <Input placeholder="Email" disabled={isDisabledField('email')} />,
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            label={<>Job title{disabledFieldHelpMessage('jobTitle')}</>}
          >
            {getFieldDecorator('jobTitle', {
              initialValue: user.jobTitle ? user.jobTitle : undefined,
              rules: [
                ...(!isDisabledField('jobTitle')
                  ? [{ required: true, message: 'This field is required' }]
                  : []),
                {
                  validator: trimmedMinlengthValidator({
                    min: 2,
                    message: 'Your job title must have at least 2 characters',
                  }),
                },
              ],
            })(
              <Input
                placeholder="Job title"
                disabled={isDisabledField('jobTitle')}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
