import { Select } from 'antd'
import * as momentTimezone from 'moment-timezone'
import React, { useEffect, useState } from 'react'

export default React.forwardRef(
  ({ value, onChange, placeholder, style }, ref) => {
    const [timeZonesData, setTimeZonesData] = useState([])
    const [paramsSelect, setParamsSelect] = useState({})

    useEffect(() => {
      //Get all regions from moment-timezone.js
      const timeZones = momentTimezone.tz.names()
      //Create an array with the timezones shortnames
      const abbreviations = [
        'Etc',
        'Universal',
        'Zulu',
        'W-Su',
        'ROC',
        'ROK',
        'PRC',
        'NZ',
        'GB',
      ]

      //Create an array with the name, the time offset in minutes and the select option
      let options = timeZones.map(timezoneName => {
        const timezone = momentTimezone.tz(timezoneName)
        const name = timezoneName.split('_').join(' ')
        const abbrev = timezone.zoneAbbr()

        //If the timezones shortname of the timezone is not in the array add it
        if (abbreviations.indexOf(abbrev) === -1) {
          abbreviations.push(abbrev.replace('+', '\\+'))
        }

        return {
          name: timezoneName,
          key: timezone._offset,
          population: timezone._z.population,
          option: (
            <Select.Option key={name.toLowerCase()} value={timezoneName}>
              (GMT {timezone.format('Z')}) {name}
            </Select.Option>
          ),
        }
      })
      //Create a regex with the timezone shortnames
      const regexAbbreviations = new RegExp(abbreviations.join('|'))

      //Filter the options with the regex to remove standart timezones and sort by the time offset
      options = options
        .filter(timezone => !regexAbbreviations.test(timezone.name))
        .sort((first, second) => first.key - second.key)

      setTimeZonesData(options.map(timezone => timezone.option))
    }, [])

    useEffect(() => {
      let defaultParamsSelect = {
        filterOption: filterOptionTimeZone,
        showSearch: true,
        placeholder: placeholder,
        onChange: onChange,
        style: style,
      }
      if (value) {
        defaultParamsSelect = { ...defaultParamsSelect, value: value }
      }
      setParamsSelect({ ...defaultParamsSelect })
    }, [value, onChange])

    const filterOptionTimeZone = (input, option) => {
      return option.key.indexOf(input.toLowerCase()) > -1
    }

    return (
      <Select {...paramsSelect} ref={ref}>
        {timeZonesData}
      </Select>
    )
  },
)
