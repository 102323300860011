import React, { useState } from 'react'

import Emoji from 'react-emoji-render'
import { EmptyState } from '../../components'
import SendGoodVibePage from '../SendGoodVibePage'

const LandingPageEmptyState = () => {
  const [modalVisible, setModalVisible] = useState()
  const showSendGoodVibeModal = () => setModalVisible(true)
  const hideSendGoodVibeModal = () => setModalVisible(false)

  return (
    <>
      <EmptyState
        heading={<Emoji text="Make your colleagues feel valued :star2:" />}
        buttonText="Send Good vibes"
        text={
          <>
            <p>
              Remember that day when you took the training wheels off your bike?
              After a few wobbly seconds you were away and your dad yelled out
              "Yessss, you did it!"... Felt good, right?
            </p>
            <p>
              Give your colleagues a boost with public praise and recognition by
              sending Good vibes.
            </p>
          </>
        }
        onButtonClick={showSendGoodVibeModal}
      />
      <SendGoodVibePage
        visible={modalVisible}
        onCancel={hideSendGoodVibeModal}
        onOk={hideSendGoodVibeModal}
      />
    </>
  )
}

export default LandingPageEmptyState
