import './style.less'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useState } from 'react'

import Modal from '../Modal'

const VisibilityInputButton = React.forwardRef(
  ({ value, onChange, ...props }, ref) => {
    const [modalVisible, setModalVisible] = useState(false)

    const onButtonClick = () => {
      setModalVisible(true)
    }

    const handleOnOk = value => {
      onChange(value)
      setModalVisible(false)
    }

    const handleOnClose = () => {
      setModalVisible(false)
    }

    return (
      <>
        <Button
          {...props}
          className="haig-visibility-input-button"
          type="ghost"
          style={{ marginLeft: '16px', ...props.style }}
          size="small"
          onClick={onButtonClick}
        >
          <FontAwesomeIcon
            icon={['fas', value === 'public' ? 'users' : 'user']}
            style={{ width: '1em', marginRight: 8 }}
          />
          Answer {value === 'public' ? 'publicly' : 'privately'}
          <FontAwesomeIcon
            icon={['fas', 'caret-down']}
            style={{ width: '1em', marginLeft: 8 }}
          />
        </Button>
        <Modal
          visible={modalVisible}
          onOk={handleOnOk}
          onCancel={handleOnClose}
          defaultValue={value}
          centered
        />
      </>
    )
  },
)

export default VisibilityInputButton
