import React, { useContext, useEffect } from 'react'

import AppLoading from '../AppLoading'
import { UserContext } from '../UserContext'
import gql from 'graphql-tag'
import { notification } from 'antd'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'
import useQuery from '../hooks/routing/useQuery'

const LOG_IN_WITH_SLACK = gql`
  mutation signInWithSlack($code: String!, $redirectUrl: String!) {
    signInWithSlack(authorizationCode: $code, redirectUrl: $redirectUrl)
  }
`

export default ({ history }) => {
  const user = useContext(UserContext)

  const queryParams = useQuery()
  const queryCode = queryParams.get('code')
  const queryError = queryParams.get('error')
  const redirect = queryParams.get('redirect')
  const redirectUrl = redirect ? redirect : '/welcome'

  const [logInWithSlack] = useMutation(LOG_IN_WITH_SLACK)

  const basePath = `${document.location.protocol}//${document.location.host}`

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await logInWithSlack({
          variables: {
            code: queryCode,
            redirectUrl: `${basePath}/login/slack?redirect=${redirectUrl}`,
          },
        })

        if (response && response.data && response.data.signInWithSlack) {
          track('accounts.user.login.slack', {
            email: response.data.signInWithSlack.email,
          })
          localStorage['HAIGToken'] = response.data.signInWithSlack.token
          await user.refetchUser()
          history.push(redirectUrl)
        }
      } catch (e) {
        if (e.networkError.result.errors) {
          const error = e.networkError.result.errors[0].message

          if (error.includes('HAIG User not found')) {
            notification.error({
              message: `That didn't work out as planned`,
              description: `You don't have an account with us. Please sign up or ask your manager to invite you to a Howamigoing workspace.`,
              duration: 0,
            })
          }

          if (error.includes('Slack User not found')) {
            notification.error({
              message: `That didn't work out as planned`,
              description: `Please login with email and password then (if needed, ask your Admin to) install our Slack integration so you can login with Slack next time.`,
              duration: 0,
            })
          }

          if (error.includes('Invalid authorization code')) {
            notification.error({
              message: `That didn't work out as planned`,
              description: `Your Slack authorization code is invalid. Please try again.`,
              duration: 0,
            })
          }

          if (error.includes('Insufficient slack permissions')) {
            notification.error({
              message: `That didn't work out as planned`,
              description: `You did not sign into Slack. Please try again.`,
              duration: 0,
            })
          }
        }
        history.push('/login')
      }
    }
    if (queryError) {
      if (queryError === 'access_denied') {
        notification.error({
          description:
            'You need to allow access for this to work. Please try again.',
          message: `That didn't work out as planned`,
          duration: 0,
        })
      } else {
        notification.error({
          description: 'There was an error. Please try again',
          message: `That didn't work out as planned`,
          duration: 0,
        })
      }
      history.push('/login')
    } else if (!queryCode) {
      notification.error({
        message: `That didn't work out as planned`,
        description: `Your Slack authorization code is missing. Please try again.`,
        duration: 0,
      })
      history.push('/login')
    } else {
      fetchData()
    }
  }, [])

  return <AppLoading />
}
