import React from 'react'

import Search from '../../../_components/Search'
import SelectPeopleToggle from './SelectPeopleToggle'

const CardHeader = ({
  enableSearch,
  onSearchChange,
  onFilterChange,
  filters,
  numSelected,
  disabled,
  initialSelectedFilterEnabled = false,
  onNumSelectedPress,
}) => {
  return (
    <>
      {enableSearch && (
        <Search
          placeholder="Search people"
          onChange={onSearchChange}
          onFilterChange={onFilterChange}
          filters={filters}
          disabled={disabled}
        />
      )}
      <div style={{ marginLeft: 'auto' }}>
        <SelectPeopleToggle
          numSelected={numSelected}
          initialValue={initialSelectedFilterEnabled}
          onChange={onNumSelectedPress}
        />
      </div>
    </>
  )
}

export default CardHeader
