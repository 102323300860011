import './style.less'

import { useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import { notification } from 'antd'
import React, { useState } from 'react'

import { DELETE_MULTIPLE_USERS } from '../../RemoveUserModal/queries'
import InvitePeopleModal from '../InvitePeopleModal'
import UpdateManagerModal from '../UpdateManagerModal'
import UpdateTeamModal from '../UpdateTeamModal'
import UpdateViewingManagerModal from '../UpdateViewingManagerModal'

const BulkActionBar = ({ users, onClear }) => {
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [updateManagerModalVisible, setUpdateManagerModalVisible] =
    useState(false)
  const [
    updateViewingManagersModalVisible,
    setUpdateViewingManagersModalVisible,
  ] = useState(false)
  const [updateTeamModalVisible, setUpdateTeamModalVisible] = useState(false)

  const selectedLabel = (() => {
    const noun = users.length === 1 ? 'person' : 'people'
    return `${users.length} ${noun} selected`
  })()

  const canInvite = user => user.type === 'created' || user.type === 'invited'
  const canUpdateTeam = user => {
    if (!user.readonlyProperties) {
      return true
    }
    return !user.readonlyProperties.find(x => x.name === 'team')
  }
  const canUpdateManager = user => {
    if (!user.readonlyProperties) {
      return true
    }
    return !user.readonlyProperties.find(x => x.name === 'manager')
  }

  const invitableUsers = users.filter(canInvite)
  const usersThatCanUpdateTeam = users.filter(canUpdateTeam)
  const usersThatCanUpdateManager = users.filter(canUpdateManager)

  const handleOnSendInvitesClick = () => {
    setInviteModalVisible(true)
  }

  const handleOnUpdateTeamClick = () => {
    setUpdateTeamModalVisible(true)
  }

  const handleOnSendInvitesOk = () => {
    setInviteModalVisible(false)
  }

  const handleOnSendInvitesCancel = () => {
    setInviteModalVisible(false)
  }

  const handleOnUpdateManagerClick = () => {
    setUpdateManagerModalVisible(true)
  }

  const handleOnUpdateManagerOk = () => {
    setUpdateManagerModalVisible(false)
  }

  const handleOnUpdateManagerCancel = () => {
    setUpdateManagerModalVisible(false)
  }

  const handleOnUpdateViewingManagersClick = () => {
    setUpdateViewingManagersModalVisible(true)
  }

  const handleOnUpdateViewingManagersOk = () => {
    setUpdateViewingManagersModalVisible(false)
  }

  const handleOnUpdateViewingManagersCancel = () => {
    setUpdateViewingManagersModalVisible(false)
  }

  const handleOnUpdateTeamOk = () => {
    setUpdateTeamModalVisible(false)
  }

  const handleOnUpdateTeamCancel = () => {
    setUpdateTeamModalVisible(false)
  }

  const [deleteMultipleUsers] = useMutation(DELETE_MULTIPLE_USERS, {
    refetchQueries: ['listPeopleUsers', 'peopleListStats'],
  })

  const handleRemovePeople = async () => {
    const removedUsers = await deleteMultipleUsers({
      variables: {
        ids: users.map(x => x.id),
      },
    })
    removedUsers.data.deleteMultipleUsers.forEach(removedUser => {
      if (removedUser.removed)
        notification.success({
          message: 'Success!',
          description: `User ${removedUser.email} has been removed.`,
        })
      else
        notification.error({
          message: 'Uh-oh... 🤔',
          description: `You cannot remove the owner.`,
        })
    })
    onClear()
  }

  return (
    <>
      <div className="bulk-action-bar__container">
        <FontAwesomeIcon
          className="bulk-action-bar__clear-icon"
          icon={['fas', 'times']}
          style={{ width: '1em' }}
          onClick={onClear}
        />
        <span className="bulk-action-bar__selected-label">{selectedLabel}</span>
        <div className="bulk-action-bar__actions">
          <Button
            size="small"
            type={usersThatCanUpdateManager.length === 0 ? 'ghost' : 'primary'}
            onClick={handleOnUpdateManagerClick}
            className="bulk-action-bar__action"
            disabled={usersThatCanUpdateManager.length === 0}
          >
            Update manager
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={handleOnUpdateViewingManagersClick}
            className="bulk-action-bar__action"
          >
            Update 360 viewer(s)
          </Button>
          <Button
            size="small"
            type={usersThatCanUpdateTeam.length === 0 ? 'ghost' : 'primary'}
            onClick={handleOnUpdateTeamClick}
            className="bulk-action-bar__action"
            disabled={usersThatCanUpdateTeam.length === 0}
          >
            Update team
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={handleRemovePeople}
            className="bulk-action-bar__action"
          >
            Permanently delete
          </Button>
          <Button
            disabled={invitableUsers.length === 0}
            size="small"
            type="ghost"
            onClick={handleOnSendInvitesClick}
            className="bulk-action-bar__action"
          >
            Send invite
            {invitableUsers.length > 1 ? `s (${invitableUsers.length})` : null}
          </Button>
        </div>
      </div>
      <UpdateManagerModal
        visible={updateManagerModalVisible}
        users={users}
        onOk={handleOnUpdateManagerOk}
        onCancel={handleOnUpdateManagerCancel}
      />
      <UpdateViewingManagerModal
        visible={updateViewingManagersModalVisible}
        users={users}
        onOk={handleOnUpdateViewingManagersOk}
        onCancel={handleOnUpdateViewingManagersCancel}
      />
      <UpdateTeamModal
        visible={updateTeamModalVisible}
        users={users}
        onOk={handleOnUpdateTeamOk}
        onCancel={handleOnUpdateTeamCancel}
      />
      <InvitePeopleModal
        visible={inviteModalVisible}
        users={invitableUsers}
        onOk={handleOnSendInvitesOk}
        onCancel={handleOnSendInvitesCancel}
      />
    </>
  )
}

export default BulkActionBar
