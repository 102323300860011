import React from 'react'
import Emoji from 'react-emoji-render'

const CommentAction = ({ pressed, ...props }) => {
  const iconClassModifier = pressed
    ? ' good-vibe-card__action-icon--active'
    : ''
  const textClassModifier = pressed
    ? ' good-vibe-card__action-label--active'
    : ''
  return (
    <div
      {...props}
      className="good-vibe-card__action good-vibe-card__action--comment"
    >
      <div className={`good-vibe-card__action-icon${iconClassModifier}`}>
        <Emoji text=":speech_balloon:" />
      </div>
      <div className={`good-vibe-card__action-label${textClassModifier}`}>
        Comment
      </div>
    </div>
  )
}

export default CommentAction
