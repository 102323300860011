import './style.less'

import { Card, Icon, notification } from 'antd'
import React, { useContext, useState } from 'react'

import Body from './Body'
import ConfirmationModal from '../../../_components/ConfirmationModal'
import Footer from './Footer'
import Header from './Header'
import Meta from './Meta'
import ReportAbuse from '../ReportAbuse'
import SendGoodVibe from '../SendGoodVibe'
import { UserContext } from '../../../UserContext'

const GoodVibeCard = ({
  id,
  sender,
  recipient,
  fact,
  feeling,
  sentAt,
  draft = {},
  comments = [],
  reactions = [],
  remoteSaver,
  reportedAbuse,
  onGoodVibeReportAbuse,
  onGoodVibeEdit,
  onGoodVibeDelete,
  onReactionAdd,
  onReactionDelete,
  onCommentAdd,
  onCommentEdit,
  onCommentDelete,
  onCommentReportAbuse,
  ...props
}) => {
  const currentUser = useContext(UserContext)

  const [reportAbuseModalVisible, setReportAbuseModalVisible] = useState(false)
  const showReportAbuseModal = () => setReportAbuseModalVisible(true)
  const hideReportAbuseModal = () => setReportAbuseModalVisible(false)

  const [editModalVisible, setEditModalVisible] = useState(false)
  const showEditModal = () => setEditModalVisible(true)
  const hideEditModal = () => setEditModalVisible(false)

  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const showDeleteModal = () => setDeleteModalVisible(true)
  const hideDeleteModal = () => setDeleteModalVisible(false)

  const [commentsVisible, setCommentsVisible] = useState(true)

  const handleReportAbuse = async abuse => {
    if (onGoodVibeReportAbuse) {
      await onGoodVibeReportAbuse(abuse)
    }
    hideReportAbuseModal()
  }

  const handleOnEditGoodVibeClose = () => {
    hideEditModal()
  }

  const handleOnEditGoodVibeOk = async ({ recipientId, fact, feeling }) => {
    if (onGoodVibeEdit) {
      await onGoodVibeEdit({
        recipientId,
        fact,
        feeling,
      })
    }
    hideEditModal()
  }

  const handleOnDeleteOk = async () => {
    if (onGoodVibeDelete) {
      await onGoodVibeDelete()
    }
    hideDeleteModal()
  }

  const onActionMenuClick = action => {
    switch (action) {
      case 'report-abuse':
        if (reportedAbuse) {
          notification.info({
            message: `You've already reported this`,
            description: `We'll get back to you shortly`,
          })
        } else {
          showReportAbuseModal()
        }
        break
      case 'edit':
        showEditModal()
        break
      case 'delete':
        showDeleteModal()
        break
      default:
    }
  }

  const toggleComments = () => {
    setCommentsVisible(!commentsVisible)
  }

  const handleOnCommentActionClick = () => {
    toggleComments()
  }

  const handleOnCommentCounterClick = () => {
    toggleComments()
  }

  const currentUserIsSender = sender.id === currentUser.id

  return (
    <div
      {...props}
      className={`good-vibe-card__container${
        props.className ? ` ${props.className}` : ''
      }`}
    >
      <Meta sender={sender} recipient={recipient} sentAt={sentAt} />
      <Card bodyStyle={{ padding: 0 }}>
        <div style={{ padding: '24px' }}>
          <Header
            currentUser={currentUser}
            sender={sender}
            recipient={recipient}
            onActionMenuClick={onActionMenuClick}
          />
          <Body
            fact={fact}
            feeling={feeling}
            reactions={reactions}
            comments={comments}
            onCommentCounterClick={handleOnCommentCounterClick}
          />
        </div>
        <Footer
          comments={comments}
          commentsVisible={commentsVisible}
          reactions={reactions}
          onReactionAdd={onReactionAdd}
          onReactionDelete={onReactionDelete}
          onCommentAdd={onCommentAdd}
          onCommentEdit={onCommentEdit}
          onCommentReportAbuse={onCommentReportAbuse}
          onCommentDelete={onCommentDelete}
          onCommentActionClick={handleOnCommentActionClick}
        />
      </Card>
      <ReportAbuse.Modal
        visible={reportAbuseModalVisible}
        onOk={handleReportAbuse}
        onCancel={hideReportAbuseModal}
      />
      <SendGoodVibe.Modal
        title="Edit Good vibe"
        okText="Save"
        visible={editModalVisible}
        onCancel={handleOnEditGoodVibeClose}
        onOk={handleOnEditGoodVibeOk}
        defaultValues={{
          id,
          recipientId: draft.recipientId || recipient.id,
          fact: draft.fact || fact,
          feeling: draft.feeling || feeling,
        }}
        lastSyncedAt={draft.lastSyncedAt}
        remoteSaver={remoteSaver}
      />
      <ConfirmationModal
        visible={deleteModalVisible}
        onOk={handleOnDeleteOk}
        onCancel={hideDeleteModal}
        title="Delete Good vibes post"
        width={512}
      >
        <p>
          Oh, that's kind of sad. Good vibes make people happy!
          <br />
          Please note that deleting the post will also erase all the comments
          written so far.
        </p>
        {!currentUserIsSender && (
          <p style={{ color: '#f95c4b', marginBottom: 0 }}>
            <Icon type="info-circle" /> You could also ask the author to amend
            the post and remove any sensitive information.
          </p>
        )}
      </ConfirmationModal>
    </div>
  )
}

export default GoodVibeCard
