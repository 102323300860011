import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Section from '../../Section'
import Table from '../../Table'
import { USER_PRIVATE_FEEDBACK } from './queries'
import TableColumns from './TableColumns'

const PAGE_SIZE = 10

export default ({ timeInterval, isActive }) => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const [queryVariables, setQueryVariables] = useState({
    startDate: timeInterval[0],
    endDate: timeInterval[1],
    offset: 0,
    limit: PAGE_SIZE,
  })

  const { data } = useQuery(USER_PRIVATE_FEEDBACK, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,
    skip: !isActive,
    onCompleted: () => {
      setLoading(false)
    },
  })

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (
      timeInterval[0] === queryVariables.startDate &&
      timeInterval[1] === queryVariables.endDate
    ) {
      return
    }
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    })
  }, [timeInterval])

  const handleSearchChange = value => {
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      name: value === '' ? undefined : value,
    })
  }

  const handleTeamChange = value => {
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      teamId: value === 'all-teams' ? undefined : value,
    })
  }

  const handlePageChange = page => {
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      offset: PAGE_SIZE * (page - 1),
    })
  }

  const handleRowClick = record => {
    history.push('/profile/' + record.user.id, { user: record.user })
  }

  return (
    <Section title="Individual stats">
      <Table
        withSearch
        withTeamPicker
        onSearchChange={handleSearchChange}
        onTeamChange={handleTeamChange}
        onPageChange={handlePageChange}
        columns={TableColumns}
        loading={loading}
        data={
          data &&
          data.usersPrivateFeedbackInsight &&
          data.usersPrivateFeedbackInsight.items
        }
        rowKey={record => record.id}
        total={
          data &&
          data.usersPrivateFeedbackInsight &&
          data.usersPrivateFeedbackInsight.total
        }
        onRow={record => {
          return {
            onClick: () => handleRowClick(record),
          }
        }}
        itemsName="people"
      />
    </Section>
  )
}
