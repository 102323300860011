import React from 'react'

export default ({ title, children }) => {
  return (
    <div className="up-details__section">
      <div className="up-details__section-title">{title}</div>
      <div className="up-details__section-content">{children}</div>
    </div>
  )
}
