import React from 'react'

const Bar = ({ scale, value, ...props }) => {
  const activeStepIndex = scale.findIndex(x => x.value === value)
  const isWithinActiveStep = stepIndex =>
    stepIndex <= activeStepIndex && stepIndex >= 0

  return (
    <div className="password-strength-input__bar-container" {...props}>
      {scale.map((step, i) => {
        const isActive = isWithinActiveStep(i)
        return (
          <div
            key={i}
            className={`password-strength-input__bar-step${
              isActive ? ' password-strength-input__bar-step--active' : ''
            }`}
            style={isActive ? { background: scale[activeStepIndex].color } : {}}
            aria-label={step.value}
          ></div>
        )
      })}
    </div>
  )
}

export default Bar
