import gql from 'graphql-tag'

export const UPDATE_ACCOUNT_DETAILS = gql`
  mutation updateAccountDetails(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $jobTitle: String
    $commsAccepted: Boolean
    $timeZone: String
    $currentPassword: String
    $newPassword: String
    $confirmPassword: String
  ) {
    updateCurrentUser(
      updateUserInput: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        email: $email
        commsAccepted: $commsAccepted
        timeZone: $timeZone
        currentPassword: $currentPassword
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    ) {
      id
      firstName
      lastName
      jobTitle
      email
      timeZone
    }
  }
`
