import { List } from 'antd'
import React from 'react'

import CycleCard from './CycleCard'

export default ({ cycles, handleDelete }) => {
  return (
    <div className="cycles-list" style={{ marginTop: 16 }}>
      <List
        size="large"
        itemLayout="vertical"
        grid={{
          gutter: 16,
        }}
        dataSource={cycles}
        renderItem={(cycle, i) => (
          <List.Item data-cy={`event-${i + 1}`}>
            <CycleCard cycle={cycle} handleDelete={handleDelete} />
          </List.Item>
        )}
      ></List>
    </div>
  )
}
