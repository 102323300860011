import React from 'react'

import { VERTICAL_MARGIN_DOUBLE } from '../CONSTANTS'
import RowNotReceivedFeedback from './RowNotReceivedFeedback'
import RowReceivedFeedback from './RowReceivedFeedback'

export default ({ cycle }) => {
  const userReceivedFeedback =
    cycle.requests &&
    cycle.requests.length > 1 &&
    cycle.requests.filter(x => x.respondedAt).length > 1

  return (
    <div style={{ marginBottom: VERTICAL_MARGIN_DOUBLE }}>
      {userReceivedFeedback ? (
        <RowReceivedFeedback
          feedbackRequests={cycle.requests}
          reviewId={cycle.reviewId}
        />
      ) : (
        <RowNotReceivedFeedback cycleType={cycle.type} />
      )}
    </div>
  )
}
