import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useRef, useState } from 'react'

import CreateTeamForm from './CreateTeamForm'
import { ADD_TEAM_MEMBERS, CREATE_TEAM } from './queries'

export default ({ visible, onOk, onError, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [okDisabled, setOkDisabled] = useState(true)

  const [createTeam] = useMutation(CREATE_TEAM)
  const [addTeamMembers] = useMutation(ADD_TEAM_MEMBERS)

  const formRef = useRef()

  const handleSubmit = () => {
    formRef.current.validateFields(async (err, values) => {
      if (err) return
      try {
        setLoading(true)
        const { data: teamData } = await createTeam({
          variables: {
            teamName: values.teamName,
          },
        })
        await addTeamMembers({
          variables: {
            teamId: teamData.createTeam.id,
            userIds: values.members.map(x => x.id),
          },
        })
        formRef.current.resetFields()
        setLoading(false)
        if (onOk) {
          onOk()
        }
      } catch (err) {
        setLoading(false)
        if (onError) {
          onError(err)
        }
      }
    })
  }

  const handleChange = e => {
    setOkDisabled(e.target.value.length === 0)
  }

  return (
    <Modal
      title="Add team"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: okDisabled,
        type: 'accent',
      }}
      confirmLoading={loading}
      okText="Add team"
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      closable={false}
      maskClosable={!loading}
      bodyStyle={{ padding: '32px 32px 8px' }}
      centered
    >
      <CreateTeamForm handleChange={handleChange} ref={formRef} />
    </Modal>
  )
}
