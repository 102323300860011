import PanelBodyRow from './PanelBodyRow'
import React from 'react'
import { Spin } from 'antd'
import sortBy from 'lodash/sortBy'
import useTeam from './useTeam'

const PanelBody = ({ teamId, teams }) => {
  const { data: teamData, loading: teamLoading } = useTeam(teamId)

  return (
    <div>
      {teamLoading && (
        <div className="team-list-v2__panel_body_row">
          <Spin style={{ marginBottom: 20 }} />
        </div>
      )}
      {!teamLoading &&
        teamData &&
        sortBy(
          teamData.team.members.map(x => x.user),
          ['firstName', 'email'],
        ).map(user => (
          <PanelBodyRow
            key={user.id}
            user={user}
            team={teamData.team}
            teams={teams}
          />
        ))}
    </div>
  )
}

export default PanelBody
