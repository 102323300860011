import { Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import Text from 'react-texty'

import { Avatar, AvatarName } from '../../../../_components/Avatar'
import extractDisplayName from '../../../../_helpers/extractDisplayName'
import { Label } from '../../Table'

const Creator = ({ data }) => {
  return (
    <>
      <Avatar
        user={data.cycle.creator}
        size={24}
        style={{
          flexShrink: 0,
          maxWidth: '100%',
          fontSize: 12,
          lineHeight: '22px',
        }}
        disableTooltip
      />
      <Label
        style={{
          marginLeft: '8px',
        }}
      >
        <Text tooltip={extractDisplayName(data.cycle.creator)}>
          <AvatarName
            user={data.cycle.creator}
            style={{
              color: '#281765',
              fontSize: 14,
              fontWeight: 400,
            }}
          />
        </Text>
      </Label>
    </>
  )
}

export default [
  {
    title: 'Start date',
    key: 'date',
    width: '13%',
    render: data => (
      <div style={{ paddingLeft: '24px' }}>
        {moment.unix(data.cycle.startDate).format('DD MMM YY')}
      </div>
    ),
  },
  {
    title: 'Event name',
    key: 'name',
    width: '22.5%',
    render: data => (
      <Label>
        <Text tooltip={data.cycle.name}>{data.cycle.name}</Text>
      </Label>
    ),
  },
  {
    title: 'Created by',
    key: 'creator',
    width: '20.5%',
    render: data => (
      <div style={{ alignItems: 'center', display: 'flex' }}>
        {data.cycle.creator.status === 'DEIDENTIFIED' ? (
          <Tooltip title="You cannot view this user's profile.">
            <Creator data={data} />
          </Tooltip>
        ) : (
          <Creator data={data} />
        )}
      </div>
    ),
  },
  {
    title: (
      <Tooltip title="Number of people asked to respond to the Pulse survey">
        <span style={{ cursor: 'help' }}>People surveyed</span>
      </Tooltip>
    ),
    key: 'asked',
    width: '13%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'pulse_surveys_people_asked',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of people who answered the Pulse survey">
        <span style={{ cursor: 'help' }}>People answered</span>
      </Tooltip>
    ),
    key: 'responded',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'pulse_surveys_people_responded',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of people who answered as a percentage of those asked">
        <span style={{ cursor: 'help' }}>Response rate</span>
      </Tooltip>
    ),
    key: 'score',
    width: '15%',
    render: data => {
      if (data && data.stats) {
        if (
          moment.unix(data.cycle.startDate).isBefore(moment()) &&
          moment.unix(data.cycle.endDate).isAfter(moment())
        ) {
          return 'Event in progress'
        }

        const stat = data.stats.find(
          x => x.key === 'pulse_surveys_response_rate',
        )

        const peopleRespondedStat = data.stats.find(
          x => x.key === 'pulse_surveys_people_responded',
        )

        if (peopleRespondedStat && peopleRespondedStat.value < 2) {
          return (
            <Tooltip title="Not enough people responded -- 2+ responses are required so we can remove names from comments to preserve anonymity">
              <span style={{ cursor: 'help', color: 'rgb(249, 92, 75)' }}>
                {Math.floor(stat.value * 100)}%
              </span>
            </Tooltip>
          )
        }

        return (stat ? Math.floor(stat.value * 100) : 0) + '%'
      }
      return '-'
    },
  },
]
