import './style.less'

import { useLazyQuery } from '@apollo/react-hooks'
import { DatePicker } from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import { UserContext } from '../../../UserContext'
import ThreeSixtyFeedback from './360Feedback'
import AskMeAnything from './AskMeAnything'
import GoodVibes from './GoodVibes'
import PrivateFeedback from './PrivateFeedback'
import PulseSurveys from './PulseSurveys'
import { USER } from './queries'
import Section from './Section'
import UserDetails from './UserDetails'

const DEFAULT_TIME_PERIOD = [
  moment().utc().year(2020).dayOfYear(1),
  moment().utc(),
]

export default () => {
  const currentUser = useContext(UserContext)

  const { id } = useParams()

  const location = useLocation()

  const [user, setUser] = useState()
  const [timeInterval, setTimeInterval] = useState(DEFAULT_TIME_PERIOD)

  const [fetchUser, { data }] = useLazyQuery(USER, {
    fetchPolicy: 'cache-first',
    variables: {
      id,
    },
  })

  useEffect(() => {
    if (location.state && location.state.user) {
      if (location.state.user.id === id) {
        setUser(location.state.user)
      }
    } else if (currentUser.id === id || !id) {
      setUser(currentUser)
    } else {
      fetchUser()
    }
  }, [location, currentUser])

  useEffect(() => {
    if (data && data.user) {
      setUser(data.user)
    }
  }, [data])

  const handleTimeIntervalChange = value => {
    if (value.length === 0) {
      setTimeInterval(DEFAULT_TIME_PERIOD)
    } else {
      setTimeInterval([value[0].utc(), value[1].utc()])
    }
  }

  return (
    <div className="user-profile">
      <UserDetails loadedUser={user} />

      <div className="user-profile__date-picker">
        <div className="insights__date-picker-container">
          <div className="insights__date-picker-label">Time period:</div>
          <DatePicker.RangePicker
            ranges={{
              'Last month': [
                moment().utc().subtract(30, 'days'),
                moment().utc(),
              ],
              'Last 3 months': [
                moment().utc().subtract(2, 'month'),
                moment().utc(),
              ],
              'Last 6 months': [
                moment().utc().subtract(5, 'month'),
                moment().utc(),
              ],
              'Last year': [moment().utc().subtract(1, 'year'), moment().utc()],
            }}
            onChange={handleTimeIntervalChange}
            value={timeInterval}
            format={'DD MMM YYYY'}
            disabledDate={current => {
              return current && current.isAfter(moment().utc(), 'day')
            }}
            className="insights__date-picker"
            dropdownClassName="insights__date-picker-dropdown"
          />
        </div>
      </div>

      <Section title="Good vibes">
        <GoodVibes timeInterval={timeInterval} userId={id || currentUser.id} />
      </Section>

      <Section title="Ask me anything">
        <AskMeAnything
          timeInterval={timeInterval}
          userId={id || currentUser.id}
        />
      </Section>

      <Section title="Private feedback">
        <PrivateFeedback
          timeInterval={timeInterval}
          userId={id || currentUser.id}
        />
      </Section>

      <Section title="360 feedback">
        <ThreeSixtyFeedback
          timeInterval={timeInterval}
          userId={id || currentUser.id}
        />
      </Section>

      <Section title="Pulse surveys">
        <PulseSurveys
          timeInterval={timeInterval}
          userId={id || currentUser.id}
        />
      </Section>
    </div>
  )
}
