import Avatar, { AvatarName } from '../../../_components/Avatar'
import React, { useContext } from 'react'

import ActionMenu from '../ActionMenu'
import { Menu } from 'antd'
import Timestamp from '../Timestamp'
import { UserContext } from '../../../UserContext'

const Item = ({ author, value, submittedAt, editedAt, onActionClick }) => {
  const currentUser = useContext(UserContext)

  const currentUserIsAuthor = author.id === currentUser.id

  const canReportComment = !currentUserIsAuthor
  const canEditComment = currentUserIsAuthor
  const canDeleteComment =
    currentUserIsAuthor ||
    currentUser.isAdmin() ||
    currentUser.isOwner() ||
    currentUser.isSuperUser()

  return (
    <div className="comment__container">
      <div className="comment__author">
        <Avatar user={author} />
      </div>
      <div className="comment__bubble">
        <div className="comment__bubble-header">
          <div className="comment__avatar-details">
            <div className="comment__avatar-name">
              <AvatarName user={author} />
            </div>
            {author.jobTitle && (
              <p className="comment__avatar-job-title">{author.jobTitle}</p>
            )}
          </div>
          <div className="comment__meta">
            <div className="comment__timestamp">
              <Timestamp date={submittedAt} />
            </div>
            <div className="comment__actions">
              <ActionMenu onClick={onActionClick}>
                {canReportComment && (
                  <Menu.Item key="report-abuse">Report abuse</Menu.Item>
                )}
                {canEditComment && <Menu.Item key="edit">Edit</Menu.Item>}
                {canDeleteComment && <Menu.Item key="delete">Delete</Menu.Item>}
              </ActionMenu>
            </div>
          </div>
        </div>
        <div className="comment__text">
          {value}
          {editedAt ? (
            <span
              style={{
                marginLeft: '.5em',
                fontWeight: 300,
                color: '#6b6b8f',
                fontSize: '14px',
              }}
            >
              (Edited)
            </span>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Item
