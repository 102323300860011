import { Alert, Icon, Typography } from 'antd'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import InvitePeopleButton from '../../../../_components/InvitePeopleButton'
import { track } from '../../../../_helpers/analytics'
import { UserContext } from '../../../../UserContext'
import ColleagueList from '../../../components/ColleagueList'
import { CYCLE_TYPES, getCycleBaseUrl } from '../../helpers'
import ButtonsBottom from '../components/CycleCreationEditButtonsBottom'
import Context from '../Context'
import StepsContext from '../Context/StepsContext'

const { Title } = Typography

const DEFAULT_SPACING = 8

export default props => {
  const user = useContext(UserContext)
  const cycleContext = useContext(Context)
  const stepsContext = useContext(StepsContext)

  const colleagueListRef = useRef(null)

  const [error, setError] = useState(false)

  const cyclePath = getCycleBaseUrl(cycleContext.type)

  useEffect(() => {
    if (
      cycleContext.type === CYCLE_TYPES.threeSixty &&
      cycleContext.selection.max
    ) {
      // const defaultMaxRequests = cycleContext.maxRequests
      //   ? cycleContext.maxRequests
      //   : cycleContext.selection.max >= 5
      //   ? 6
      //   : cycleContext.selection.max
      // cycleContext.setContext({
      //   maxRequests: defaultMaxRequests,
      // })
      if (error && error.type === 1) {
        setError(
          cycleContext.selection.max < 3
            ? {
                text: renderTotalNumberColleagueError(
                  cycleContext.selection.max,
                ),
                type: 1,
              }
            : null,
        )
      }
    }
  }, [cycleContext.selection.max])

  const renderTotalNumberColleagueError = number => {
    return `You need to invite at least ${3 - number} ${
      3 - number === 1 ? 'person' : 'people'
    } to use Howamigoing to start a 360 feedback event.`
  }

  const handleForm = () => {
    if (cycleContext.type === CYCLE_TYPES.personal) {
      if (cycleContext.selection.total < 2) {
        const errorMessage =
          'Please select at least 2 colleagues to start a Private feedback event.'
        setError({ text: errorMessage })
        throw new Error(errorMessage)
      }
    }

    if (cycleContext.type === CYCLE_TYPES.pulse) {
      if (cycleContext.selection.total < 2) {
        const errorMessage =
          'Please select at least 2 colleagues to start a Pulse survey event.'
        setError({ text: errorMessage })
        throw new Error(errorMessage)
      }
    }

    if (cycleContext.type === CYCLE_TYPES.threeSixty) {
      if (cycleContext.selection.max < 3) {
        const errorMessage = renderTotalNumberColleagueError(
          cycleContext.selection.max,
        )
        setError({
          text: errorMessage,
          type: 1,
        })
        throw new Error(errorMessage)
      }
      if (cycleContext.selection.total < 1) {
        const errorMessage =
          'Please select at least 1 colleague to start a 360.'
        setError({
          text: errorMessage,
          type: 2,
        })
        throw new Error(errorMessage)
      }
    }

    const cyclePath = getCycleBaseUrl(cycleContext.type)

    setError(null)
    track(`${cycleContext.type}.cycle.feedbackProvidersNextStep`, {
      user: user.email,
      org: user.org.name,
      amountSelectedColleagues: cycleContext.selectedColleagues.length,
      min:
        cycleContext.type === CYCLE_TYPES.threeSixty &&
        cycleContext.minRequests,
      max:
        cycleContext.type === CYCLE_TYPES.threeSixty &&
        cycleContext.maxRequests,
    })
    const newUrl = cycleContext.id
      ? `${cyclePath}/edit/${cycleContext.id}/3`
      : `${cyclePath}/create/3`
    stepsContext.setStep(2)
    props.history.push(newUrl)
  }

  const handleOnSelect = useCallback(selection => {
    cycleContext.setContext({
      selection,
    })
  }, [])
  const handleOnLoaded = useCallback(selection => {
    cycleContext.setContext({
      selection,
    })
  }, [])

  const handleOnInvited = async () => {
    await colleagueListRef.current.reload()
    cycleContext.refetchTotalNumberOfColleagues()
  }

  const handleColleagueListOnInvited = () => {
    cycleContext.refetchTotalNumberOfColleagues()
  }

  return (
    <div
      className="new-cycle-enroll-users"
      style={{ paddingTop: DEFAULT_SPACING }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          paddingBottom: DEFAULT_SPACING * 3,
        }}
      >
        {(() => {
          switch (cycleContext.type) {
            case CYCLE_TYPES.personal:
              return (
                <div>
                  <Title level={4} style={{ marginBottom: 0 }}>
                    Who would you like to receive feedback from?
                  </Title>
                </div>
              )

            case CYCLE_TYPES.pulse:
              return (
                <div>
                  <Title level={4} style={{ marginBottom: 0 }}>
                    Who should answer this survey?
                  </Title>
                </div>
              )

            case CYCLE_TYPES.threeSixty:
              return (
                <div>
                  <Title level={4} style={{ marginBottom: DEFAULT_SPACING }}>
                    Who is the feedback for?
                  </Title>
                  <div style={{ fontWeight: 300 }}>
                    <strong style={{ color: '#f95c4b' }}>Only</strong> select
                    the people who are to request feedback in this event.
                    <br />
                    Those selected will be able to request feedback from{' '}
                    <strong style={{ color: '#f95c4b' }}>any</strong> colleague
                    on Howamigoing.
                  </div>
                </div>
              )

            default:
              return <></>
          }
        })()}
        <InvitePeopleButton
          style={{ marginLeft: 'auto' }}
          data-cy="invite-more-to-event-button"
          forceEmailInvite={true}
          onInvited={handleOnInvited}
        />
      </div>

      <ColleagueList
        height={400}
        withSearch
        withSelectAll
        excludeUserIds={
          cycleContext.type === CYCLE_TYPES.personal ? [user.id] : undefined
        }
        selection={cycleContext.selection}
        onSelect={handleOnSelect}
        onLoaded={handleOnLoaded}
        onInvited={handleColleagueListOnInvited}
        ref={colleagueListRef}
      />

      {error && (
        <Alert
          style={{
            color: '#f95c4b',
            marginTop: DEFAULT_SPACING * 3,
            backgroundColor: 'rgba(249, 92, 75, 0.12)',
            borderColor: '#f95c4b',
          }}
          icon={<Icon type="close-circle" style={{ color: '#f95c4b' }} />}
          description={error.text}
          type="error"
          showIcon
        />
      )}

      <ButtonsBottom
        step={2}
        handleContinueClick={handleForm}
        contextId={cycleContext.id}
        typePath={cyclePath}
        fromWBYHT={cycleContext.fromWBYHT}
      />
    </div>
  )
}
