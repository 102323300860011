import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import React, { useEffect, useState } from 'react'

import { Avatar, AvatarName } from '../../../_components/Avatar'
import AutoSaveContextProvider from '../../../AutoSaveContext'
import SafePlaceNotice from '../../components/SafePlaceNotice'
import FormWrapper from './FormWrapper'
import GiveFeedbackContext from './giveFeedbackContext'
import { GET_FEEDBACK } from './queries'

export default ({ match, history }) => {
  const [user, setUser] = useState()
  const [form, setForm] = useState()
  const [cycleType, setCycleType] = useState()
  const [cycleName, setCycleName] = useState()

  const GiveFeedbackContextData = {
    feedbackId: match.params.id,
  }

  const { data, error, loading } = useQuery(GET_FEEDBACK, {
    variables: {
      feedbackId: match.params.id,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (data && data.feedback) {
      if (data.feedback.submittedAt) {
        history.push('/home')
      }

      setUser(data.feedback.review.user)
      setForm(data.feedback.review.cycle.form)
      setCycleType(data.feedback.review.cycle.type)
      setCycleName(data.feedback.review.cycle.name)
    }
  }, [data])

  if (loading) return <p>Loading…</p>
  if (error) return <p>Error!</p>

  return (
    <div className="give-feedback">
      <GiveFeedbackContext.Provider value={GiveFeedbackContextData}>
        <AutoSaveContextProvider>
          <Card className="top-title" style={{ marginBottom: 32 }}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
              {cycleType !== 'PULSE' && (
                <div>
                  <Avatar user={user} withRightMargin disableTooltip />
                </div>
              )}
              <div
                style={{
                  fontFamily: 'museo, serif',
                  color: '#1c1047',
                }}
              >
                <div style={{ fontSize: '14px', lineHeight: '22px' }}>
                  {cycleType === 'PULSE' ? (
                    'Answering survey'
                  ) : (
                    <>
                      Giving feedback to <AvatarName user={user} />
                    </>
                  )}
                </div>
                <div
                  style={{
                    color: '#f95c4b',
                    fontSize: '20px',
                    lineHeight: '28px',
                  }}
                >
                  {cycleName}
                </div>
              </div>
            </div>
          </Card>

          <SafePlaceNotice />

          {form && (
            <FormWrapper
              form={{ ...form, user: user }}
              cycleType={cycleType}
              cycleName={cycleName}
            />
          )}
        </AutoSaveContextProvider>
      </GiveFeedbackContext.Provider>
    </div>
  )
}
