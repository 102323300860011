import * as Components from './components'

import { LandingPage, SendGoodVibePage } from './pages'

import { default as Router } from './Router'
import { SendGoodVibe } from './components'

const Root = () => null

Root.Router = Router
Object.assign(Root, Components)
Object.assign(Root, { LandingPage, SendGoodVibePage })

export { SendGoodVibe, LandingPage, SendGoodVibePage, Router }

export default Root
