import React from 'react'

export default props => {
  return (
    <span
      style={{
        fontSize: '14px',
        fontWeight: 300,
        color: '#77779f',
        lineHeight: '22px',
      }}
      {...props}
    ></span>
  )
}
