import React, { useEffect, useState } from 'react'

import AppLoading from '../AppLoading'
import { SIGN_UP_WITH_SLACK } from './queries'
import moment from 'moment'
import { notification } from 'antd'
import { track } from '../_helpers/analytics'
import { useMutation } from '@apollo/react-hooks'
import useQuery from '../hooks/routing/useQuery'

export default ({ match, history }) => {
  const [timezone] = useState(() => moment.tz.guess())

  const queryParams = useQuery()
  const queryCode = queryParams.get('code')
  const queryError = queryParams.get('error')

  const [signUpWithSlack] = useMutation(SIGN_UP_WITH_SLACK)

  const basePath = `${document.location.protocol}//${document.location.host}`

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await signUpWithSlack({
          variables: {
            code: queryCode,
            redirectUrl: `${basePath}/signup/slack`,
            timezone,
          },
        })

        if (response && response.data && response.data.signUpWithSlack) {
          track('orgs.signup.slack', {
            firstName: response.data.signUpWithSlack.userFirstName,
            email: response.data.signUpWithSlack.userEmail,
            org: response.data.signUpWithSlack.orgName,
          })
          history.push(
            '/access?firstLogin=true&t=' + response.data.signUpWithSlack.token,
          )
        }
      } catch (e) {
        if (e.networkError.result.errors) {
          const error = e.networkError.result.errors[0].message

          const showError = description => {
            notification.error({
              message: `That didn't work out as planned`,
              description,
            })
          }

          if (error.includes('Invalid authorization code')) {
            showError(
              `Your Slack authorization code is invalid. Please try again.`,
            )
          } else if (
            error.includes('Insufficient app permissions') ||
            error.includes('App permissions required') ||
            error.includes('User permissions required') ||
            error.includes('Insufficient user permissions')
          ) {
            showError(
              `The permissions you've granted were insufficient. Please try again.`,
            )
          } else if (error.includes('Slack user already exists')) {
            showError(
              `Your Slack user is already part of a Howamigoing account. Please log in.`,
            )
          } else if (error.includes('User already exists')) {
            showError(
              `Your Slack email address belongs to another Howamigoing user. Please log in.`,
            )
          } else if (error.includes('Slack workspace is already integrated')) {
            showError(
              `This Slack workspace is already connected to another Howamigoing account.`,
            )
          } else {
            showError(`There was an error signing you up. Please try again.`)
          }
        }
        history.push('/signup')
      }
    }
    if (queryError) {
      if (queryError === 'access_denied') {
        notification.error({
          description:
            'You need to allow access for this to work. Please try again.',
          message: `That didn't work out as planned`,
        })
      } else {
        notification.error({
          description: 'There was an error. Please try again',
          message: `That didn't work out as planned`,
        })
      }
      history.push('/signup')
    } else if (!queryCode) {
      notification.error({
        message: `That didn't work out as planned`,
        description: `Your Slack authorization code is missing. Please try again.`,
      })
      history.push('/signup')
    } else {
      fetchData()
    }
  }, [])

  return <AppLoading />
}
