import { Dropdown } from 'antd'
import React, { useState } from 'react'

import MoreActionsButton from '../../../_components/MoreActionsButton'
import ColleagueMenu from './ColleagueMenu'

export default ({ colleague, teams, handleModalSubmit }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleVisibleChange = flag => {
    setDropdownVisible(flag)
  }

  return (
    <Dropdown
      overlay={
        <ColleagueMenu
          user={colleague}
          teams={teams}
          onModalSubmit={handleModalSubmit}
          onMenuClick={() => setDropdownVisible(false)}
        />
      }
      visible={dropdownVisible}
      onVisibleChange={handleVisibleChange}
    >
      <MoreActionsButton />
    </Dropdown>
  )
}
