import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useState } from 'react'

import ExclamationCircleIcon from '../../../../_assets/img/exclamation-circle.png'
import AutocompleteUser from '../../../../_components/Form/AutocompleteUser'
import { CHANGE_USERS_VIEWING_MANAGERS } from '../queries'

export default props => {
  const [viewingManagers, setViewingManagers] = useState([])
  const [loading, setLoading] = useState(false)
  const [okEnabled, setOkEnabled] = useState(false)

  const [changeUsersViewingManagers] = useMutation(
    CHANGE_USERS_VIEWING_MANAGERS,
    {
      refetchQueries: ['listPeopleUsers'],
    },
  )

  const handleSubmit = async () => {
    try {
      setOkEnabled(false)
      setLoading(true)
      await changeUsersViewingManagers({
        variables: {
          ids: props.users.map(x => x.id),
          viewingManagerIds: viewingManagers.map(x => x.id),
        },
      })
      setLoading(false)
      setOkEnabled(true)
      props.onOk()
      setViewingManagers([])
    } catch (e) {
      setOkEnabled(true)
      setLoading(false)
    }
  }

  const handleChange = val => {
    setViewingManagers(val)
    setOkEnabled(val && val.length > 0)
  }

  const handleCancel = () => {
    setViewingManagers([])
    setOkEnabled(false)
    props.onCancel()
  }

  return (
    <Modal
      title="Update 360 viewer(s)"
      visible={props.visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !okEnabled,
        type: 'accent',
      }}
      confirmLoading={loading}
      okText={`Update 360 viewer${viewingManagers && viewingManagers.length > 1 ? 's' : ''
        }`}
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      closable={false}
      maskClosable={!loading}
      bodyStyle={{ padding: '32px 32px 0' }}
      width={584}
      className="update-manager-modal"
      destroyOnClose
      centered
    >
      <div style={{ fontWeight: 500, marginBottom: 8 }}>
        Update 360 viewer(s) to:
      </div>
      <AutocompleteUser
        allowMultiple
        onSelect={handleChange}
        style={{ marginBottom: 32 }}
        withShadow
        value={viewingManagers}
      />

      <div style={{ display: 'flex', marginBottom: 32 }}>
        <div style={{ flex: '0 0 16px' }}>
          <img
            src={ExclamationCircleIcon}
            alt="Exclamation mark"
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div style={{ fontSize: '14px', lineHeight: '22px', paddingLeft: 8 }}>
          360 viewers can see the 360 feedback of the people they’re assigned
          to, but are not automatically asked to give 360 feedback to those
          people.
          <br />
          You can assign multiple 360 viewers to each person.
        </div>
      </div>
    </Modal>
  )
}
