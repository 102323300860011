import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'

import Card from '../../Card'
import Section from '../../Section'
import { COMPANY_360_FEEDBACK } from './queries'

export default ({ timeInterval, isActive }) => {
  const [loading, setLoading] = useState(true)

  const [numbers, setNumbers] = useState({})

  const { data } = useQuery(COMPANY_360_FEEDBACK, {
    fetchPolicy: 'cache-first',
    variables: {
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    },
    skip: !isActive,
  })

  useEffect(() => {
    if (data) {
      setNumbers({
        eventsStarted: data.companyThreeSixtyFeedbackInsight.find(
          x => x.key === 'three_sixty_events_started',
        ).value,
        peopleWhoReceivedFeedback: data.companyThreeSixtyFeedbackInsight.find(
          x => x.key === 'three_sixty_people_with_feedback',
        ).value,
        feedbackSubmissions: data.companyThreeSixtyFeedbackInsight.find(
          x => x.key === 'three_sixty_feedback_submissions',
        ).value,
      })
      setLoading(false)
    }
  }, [data])

  return (
    <Section title="Company stats">
      <div className="insights__card-container">
        <Card
          number={numbers.eventsStarted}
          unit="360 feedback events"
          tooltip="Number of 360 feedback events started across the company"
          loading={loading}
        />
        <Card
          number={numbers.peopleWhoReceivedFeedback}
          unit="people with feedback"
          tooltip="Number of people across the company who received 2+ responses in a 360 feedback event (so their feedback was released)"
          loading={loading}
        />
        <Card
          number={numbers.feedbackSubmissions}
          unit="feedback submissions"
          tooltip="Number of 360 feedback requests which have been responded to across the company"
          loading={loading}
        />
      </div>
    </Section>
  )
}
