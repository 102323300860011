import { useMutation } from '@apollo/react-hooks'
import { Input, Modal, Radio, Typography, notification } from 'antd'
import React, { useContext, useState } from 'react'

import { UserContext } from '../../../../UserContext'
import { GET_PERSONIO_INTEGRATION } from '../queries'
import { DEACTIVATE_PERSONIO_INTEGRATION } from './queries'

const { Paragraph } = Typography

const DeactivateModal = ({ visible, onOk, onCancel, onError }) => {
  const currentUser = useContext(UserContext)
  const [reason, setReason] = useState()
  const [input, setInput] = useState()
  const [loading, setLoading] = useState(false)
  const [deactivatePersonioIntegration] = useMutation(
    DEACTIVATE_PERSONIO_INTEGRATION,
    {
      refetchQueries: [{ query: GET_PERSONIO_INTEGRATION }],
      onCompleted: () => currentUser.refetchUser(),
    },
  )

  const onSubmit = async () => {
    if (!reason) {
      notification.error({
        description: 'Please select a reason',
        message: `Uh-oh!`,
      })
    }
    try {
      setLoading(true)
      const response = await deactivatePersonioIntegration({
        variables: {
          reason: reason === 'radio_input' ? input : reason,
        },
      })
      if (!response.data.deactivatePersonioIntegration.success && onError) {
        onError(response.data.deactivatePersonioIntegration.message)
        return
      }
      if (onOk) {
        onOk()
      }
    } catch (e) {
      console.error(e)
      if (onError) {
        onError(e)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      className="deactivate-slack-modal"
      title="Disconnect Personio"
      visible={visible}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Disconnect Personio"
      cancelText="Cancel"
      okButtonProps={{ loading, type: 'accent' }}
      cancelButtonProps={{ type: 'ghost' }}
      bodyStyle={{ fontWeight: 300, padding: '32px' }}
      destroyOnClose={true}
      centered
    >
      <Paragraph style={{ marginBottom: 16 }}>
        No biggie, but before you disconnect… please help us understand why you
        don’t want to use the Personio integration:
      </Paragraph>
      <Radio.Group onChange={e => setReason(e.target.value)} value={reason}>
        <Radio value="It's not you, it's us. We don't use Personio anymore">
          It's not you, it's us. We don't use Personio anymore
        </Radio>
        <Radio value="radio_input">
          <Input
            placeholder="Other reasons? Type ‘em here."
            onChange={e => setInput(e.target.value)}
            onFocus={() => setReason('radio_input')}
          />
        </Radio>
      </Radio.Group>
    </Modal>
  )
}

export default DeactivateModal
