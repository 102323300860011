const heap = window.heap
const hotjar = window.hj

// Set the Intercom APP_ID
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID
  ? `${process.env.REACT_APP_INTERCOM_APP_ID}`
  : null

let identity = false
let identificationInterval = false
let identificationHotjarInterval = false

export const track = (eventId, properties) => {
  if (!heap.loaded) return // Disable the tracking functionality until Heap has fully loaded
  if (properties) {
    heap.track(eventId, properties)
  } else heap.track(eventId)
}

export const identify = user => {
  identity = {
    userId: user.id,
    email: user.email,
    accountId: user.account.id,
    org: user.org.name,
  }
}
;(() => {
  // This is equivalent of the DOM onLoad() trigger. The reason this is coded using an interval, is because it might happen that we want to identify the user
  // before the Heap SDK has actually loaded, causing a general React error for a undefined method.
  identificationInterval = setInterval(() => {
    if (heap.loaded && identity) {
      heap.identify(identity.userId)
      heap.addUserProperties({
        email: identity.email,
        accountId: identity.accountId,
        org: identity.org,
      })
      clearInterval(identificationInterval)
    }
  }, 250)
  identificationHotjarInterval = setInterval(() => {
    if (typeof hotjar === 'function' && identity) {
      hotjar('identify', identity.userId, {
        email: identity.email,
        accountId: identity.accountId,
        org: identity.org,
      })
      clearInterval(identificationHotjarInterval)
    }
  }, 250)
})()

// prettier-ignore
const load = () => {
  INTERCOM_APP_ID &&
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
}

// Initializes Intercom
const boot = (options = {}) => {
  window &&
    INTERCOM_APP_ID &&
    window.Intercom &&
    window.Intercom('boot', { app_id: INTERCOM_APP_ID, ...options })
}

// This method just calls Intercom('update'), which should be run on every page
// change. This does two things:
// 1. Send an update to Intercom to create an impression on the current URL
// 2. Fetch any messages that should be delivered based on the URL and user
const update = () => {
  window && INTERCOM_APP_ID && window.Intercom && window.Intercom('update')
}

// Clears user session and unloads messages
const shutdown = () => {
  window && INTERCOM_APP_ID && window.Intercom && window.Intercom('shutdown')
}

export const Intercom = {
  load,
  boot,
  update,
  shutdown,
}
