import React, { useContext } from 'react'

import AMACard from './AMACard'
import { UserContext } from '../../../UserContext'
import sortBy from 'lodash/sortBy'

const AMACardList = ({
  heading,
  amas,
  editingIds,
  onActionClick,
  onEditAnswerSave,
  onEditAnswerCancel,
}) => {
  const currentUser = useContext(UserContext)
  const getAmaState = ama => {
    const isResponding = ama.recipient.id === currentUser.id && !ama.respondedAt
    if (isResponding) {
      return 'responding'
    }
    const isEditing =
      ama.recipient.id === currentUser.id &&
      !!ama.respondedAt &&
      !!editingIds.find(id => id === ama.id)
    if (isEditing) {
      return 'editing'
    }
    return 'readonly'
  }
  if (!amas) {
    return null
  }
  if (amas.length === 0) {
    return null
  }
  return (
    <div style={{ marginBottom: '40px' }}>
      {heading && (
        <div
          style={{
            fontSize: '16px',
            color: '#77779f',
            marginBottom: '24px',
          }}
        >
          {heading}
        </div>
      )}
      <div>
        {sortBy(
          amas,
          ama => -(ama.editedAt || ama.respondedAt || ama.askedAt),
        ).map(ama => (
          <AMACard
            key={ama.id}
            ama={ama}
            status={getAmaState(ama)}
            onActionClick={onActionClick}
            onEditAnswerSave={onEditAnswerSave}
            onEditAnswerCancel={onEditAnswerCancel}
          />
        ))}
      </div>
    </div>
  )
}

export default AMACardList
