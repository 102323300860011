import gql from 'graphql-tag'

export const USER_AMA = gql`
  query usersAskMeAnythingInsight(
    $startDate: String
    $endDate: String
    $offset: Int
    $limit: Int
    $teamId: ID
    $name: String
  ) {
    usersAskMeAnythingInsight(
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      limit: $limit
      teamId: $teamId
      name: $name
    ) {
      id
      items {
        id
        user {
          id
          avatarUrl
          firstName
          lastName
          jobTitle
          email
          status
          manager {
            id
            firstName
            lastName
            email
            displayName
          }
          viewingManagers {
            id
            firstName
            lastName
            email
            displayName
          }
          directReports {
            id
            firstName
            lastName
            displayName
            email
          }
          team {
            id
            name
          }
        }
        stats {
          id
          key
          value
        }
      }
      total
    }
  }
`
