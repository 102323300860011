import './style.less'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Form, Icon, Input, List } from 'antd'
import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import balloonsSVG from '../../_assets/img/balloons.svg'
import Avatar from '../Avatar'
import { ADD_SLACK_USERS, GET_COLLEAGUES, GET_SLACK_USERS } from './queries'

const SECTION_TITLE_STYLE = {
  color: '#281765',
  fontSize: 16,
  fontWeight: 300,
  lineHeight: '24px',
}

const SlackUsersEmptyState = ({ title, description, ...props }) => {
  const defaultContainerStyles = {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '32px',
    marginRight: '32px',
  }
  return (
    <div
      {...props}
      style={{
        ...defaultContainerStyles,
        ...(props ? props.style : undefined),
      }}
    >
      <img
        src={balloonsSVG}
        alt="Balloons"
        style={{
          display: 'block',
          marginRight: '24px',
          maxWidth: '96px',
          flexShrink: 0,
        }}
      />
      <div>
        <div style={{ color: '#6b6b8f', fontSize: '14px', fontWeight: 500 }}>
          {title}
        </div>
        <div style={{ color: '#6b6b8f', fontSize: '14px', fontWeight: 300 }}>
          {description}
        </div>
      </div>
    </div>
  )
}

const ColleagueItemList = ({ user, selectedUsers, onToggle }) => {
  const [isSelected, setIsSelected] = useState(false)
  const [isSelectable] = useState(!!selectedUsers)

  useEffect(() => {
    if (selectedUsers) {
      if (selectedUsers.find(selectedUser => selectedUser.id === user.id)) {
        setIsSelected(true)
      } else {
        setIsSelected(false)
      }
    }
  }, [selectedUsers])

  const userDisplayName =
    user.firstName && user.lastName
      ? `${user.firstName} ${user.lastName}`
      : user.firstName

  return (
    <div
      className="invite-people-form-list-item-inner"
      style={{
        alignItems: 'center',
        cursor: isSelectable ? 'pointer' : '',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 32px',
      }}
      onClick={onToggle}
    >
      <div>
        <Avatar user={user} />
      </div>
      <div style={{ flex: '1 0 0', padding: '0 12px' }}>
        {userDisplayName && (
          <div
            style={{
              fontFamily: 'museo',
              fontSize: 14,
              color: '#281765',
              fontWeight: 500,
              lineHeight: '22px',
            }}
          >
            {userDisplayName}
          </div>
        )}

        <div
          style={{
            fontFamily: 'museo',
            fontSize: 14,
            color: '#281765',
            fontWeight: 300,
            lineHeight: '22px',
          }}
        >
          {user.email}
        </div>
      </div>
      {isSelectable &&
        (isSelected ? (
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            style={{ color: '#77779f', fontSize: '24px' }}
          />
        ) : (
          <div
            style={{
              border: 'solid 1px #77779f',
              borderRadius: 12,
              height: 24,
              width: 24,
            }}
          ></div>
        ))}
    </div>
  )
}

export default ({
  height,
  onSubmit,
  onCancel,
  cancelLabel = 'Cancel',
  buttonsRightAligned = false,
  disabled = false,
  showPreviouslyInvited = true,
}) => {
  const [usersToInvite, setUsersToInvite] = useState([])

  const [invitableUsers, setInvitableUsers] = useState([])
  const [previouslyInvitedUsers, setPreviouslyInvitedUsers] = useState([])

  const [searchText, setSearchText] = useState('')

  const [selectedAll, setSelectedAll] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  const [inviteCheckboxValue, setInviteCheckboxValue] = useState(false)

  const [
    previouslyInvitedUsersHeight,
    setPreviouslyInvitedUsersHeight,
  ] = useState(0)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [invitePeople] = useMutation(ADD_SLACK_USERS, {
    refetchQueries: ['getColleagues', 'slackUsers', 'peopleListStats'],
  })

  const { data: colleaguesData, error: fetchColleaguesError } = useQuery(
    GET_COLLEAGUES,
    {
      fetchPolicy: 'network-only',
    },
  )

  const { data, error: fetchSlackUsersError, loading } = useQuery(
    GET_SLACK_USERS,
    {
      fetchPolicy: 'network-only',
    },
  )

  const isSlackOnlyUser = user => user.id === user.slackUserId

  const getInvitableUsers = slackUsers => {
    return slackUsers.filter(isSlackOnlyUser)
  }

  const getPreviouslyInvitedUsers = users => {
    return users.filter(user => user.invitedAt && !isSlackOnlyUser(user))
  }

  useEffect(() => {
    if (data && data.slackUsers) {
      const invitable = getInvitableUsers(data.slackUsers)
      setInvitableUsers(invitable)

      const previouslyInvited = getPreviouslyInvitedUsers(data.slackUsers)
      setPreviouslyInvitedUsers(previouslyInvited)
    }
  }, [data])

  const onSearch = e => {
    const searchTerm = e.target.value

    setSearchText(searchTerm)

    const searchInUsersArray = users => {
      const normalizedSearchTerm = searchTerm.toLowerCase()

      return users.filter(user => {
        return (
          (user.firstName &&
            user.firstName.toLowerCase().includes(normalizedSearchTerm)) ||
          (user.lastName &&
            user.lastName.toLowerCase().includes(normalizedSearchTerm)) ||
          (user.firstName &&
            user.lastName &&
            (user.firstName + ' ' + user.lastName)
              .toLowerCase()
              .includes(normalizedSearchTerm)) ||
          (user.email &&
            user.email.toLowerCase().includes(normalizedSearchTerm))
        )
      })
    }

    const invitable = searchInUsersArray(getInvitableUsers(data.slackUsers))
    setInvitableUsers(invitable)

    const invited = searchInUsersArray(
      getPreviouslyInvitedUsers(data.slackUsers),
    )
    setPreviouslyInvitedUsers(invited)

    if (invited.length > 0) {
      setPreviouslyInvitedUsersHeight('auto')
    }
  }

  const selectAllClick = () => {
    setSelectedAll(true)
    const users = getInvitableUsers(data.slackUsers)
    setUsersToInvite(users)
    setErrorMessage(checkForErrors(users))
  }

  const deselectAllClick = () => {
    setSelectedAll(false)
    setUsersToInvite([])
    setErrorMessage(checkForErrors([]))
  }

  const onUserToggle = user => {
    const newUsersToInvite = [...usersToInvite]

    const foundUserIndex = usersToInvite.findIndex(
      userToInvite => userToInvite.id === user.id,
    )

    if (foundUserIndex > -1) {
      // remove user
      newUsersToInvite.splice(foundUserIndex, 1)
    } else {
      // add user
      newUsersToInvite.push(user)
    }

    setUsersToInvite(newUsersToInvite)
    setErrorMessage(checkForErrors(newUsersToInvite))
  }

  const toggleInvitedUsers = () => {
    if (previouslyInvitedUsersHeight === 0) {
      setPreviouslyInvitedUsersHeight('auto')
    } else {
      setPreviouslyInvitedUsersHeight(0)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      setIsSubmitting(true)
      setErrorMessage('')
      if (!usersToInvite) {
        throw new Error('No users have been selected to invite')
      }
      if (usersToInvite.length === 0) {
        throw new Error('No users have been selected to invite')
      }
      await invitePeople({
        variables: {
          slackUserIds: usersToInvite.map(x => x.slackUserId),
          invite: inviteCheckboxValue,
        },
      })
      if (onSubmit) {
        onSubmit(usersToInvite)
      }
      setIsSubmitting(false)
    } catch (e) {
      setIsSubmitting(false)
      setErrorMessage('Sorry, something went wrong! Please try again.')
    }
  }

  const handleCancel = () => {
    setIsCancelling(true)
    if (onCancel) {
      onCancel()
    }
  }

  const checkForErrors = selectedUsers => {
    if (colleaguesData && colleaguesData.users.total === 1) {
      if (selectedUsers.length === 1) {
        return 'Please select at least 1 more person to get started. More is better!'
      }
      if (selectedUsers.length === 0) {
        return 'You need to select at least two people to get started'
      }
    }
    if (
      colleaguesData &&
      colleaguesData.users.total === 2 &&
      selectedUsers.length === 0
    ) {
      return 'You need to select at least one person'
    }
    if (selectedUsers.length === 0) {
      return 'You need to select at least one person'
    }
    return ''
  }

  const handleOnInviteNowChange = evt => {
    setInviteCheckboxValue(evt.target.checked)
  }

  const hasInlineErrorMessage = errorMessage && errorMessage.length > 0

  const CancelButton = () => {
    return (
      <Button
        type="ghost"
        disabled={disabled || isSubmitting || isCancelling}
        onClick={handleCancel}
        loading={isCancelling}
        style={{ marginRight: '10px' }}
      >
        {cancelLabel}
      </Button>
    )
  }

  if (fetchSlackUsersError || fetchColleaguesError) {
    return (
      <div className="has-error">
        <p className="ant-form-explain" style={{ marginBottom: 24 }}>
          {fetchSlackUsersError.message || fetchColleaguesError.message}
        </p>
        <CancelButton />
      </div>
    )
  }
  if (loading)
    return <div style={{ fontWeight: 300, padding: '0 32px' }}>Loading...</div>

  return (
    <>
      <div className="invite-people-form-slack">
        <div
          className="invite-people-form-header"
          style={{
            display: 'flex',
            alignItems: 'center',
            borderBottom: 'solid 1px #e9e8ef',
            justifyContent: 'space-between',
            margin: '0 32px 16px',
            padding: '0 0 24px',
          }}
        >
          <Input
            prefix={
              <Icon
                type="search"
                style={{ color: '#77779f', fontSize: '16px' }}
              />
            }
            suffix={false}
            placeholder="Search by name or email"
            onChange={onSearch}
            style={{
              width: 240,
            }}
          />
        </div>
        <div
          style={{
            maxHeight: height || 'auto',
            overflowY: 'auto',
            overscrollBehaviorY: 'contain',
          }}
          className="invite-people-form-list-container"
        >
          <div
            className="invite-people-form-title"
            style={{ display: 'flex', margin: '0 32px 16px' }}
          >
            <div
              style={{
                ...SECTION_TITLE_STYLE,
                fontWeight: 500,
              }}
            >
              People on Slack you can add
            </div>
            <div style={{ marginLeft: 'auto' }}>
              {invitableUsers &&
                invitableUsers.length > 0 &&
                searchText === '' &&
                (selectedAll ? (
                  <Button
                    type="link"
                    onClick={deselectAllClick}
                    style={{ height: 'auto', fontWeight: 300, padding: 0 }}
                  >
                    Deselect all
                  </Button>
                ) : (
                  <Button
                    type="link"
                    onClick={selectAllClick}
                    style={{ height: 'auto', fontWeight: 300, padding: 0 }}
                  >
                    Select all
                  </Button>
                ))}
            </div>
          </div>
          {invitableUsers && invitableUsers.length > 0 && (
            <List
              className="invite-people-form-list"
              dataSource={sortBy(invitableUsers, ['firstName'])}
              size="large"
              itemLayout="vertical"
              split={false}
              style={{ paddingBottom: '12px' }}
              renderItem={user => (
                <List.Item
                  className="invite-people-form-list-item"
                  style={{ padding: '0 0 12px 0' }}
                >
                  <ColleagueItemList
                    user={user}
                    selectedUsers={usersToInvite}
                    onToggle={() => onUserToggle(user)}
                  />
                </List.Item>
              )}
            />
          )}
          {searchText === '' &&
            invitableUsers &&
            invitableUsers.length === 0 && (
              <SlackUsersEmptyState
                title="Congratulations! You now have a full house."
                description="When more people join your Slack workspace, invite them to Howamigoing here."
                style={{ marginBottom: '24px' }}
              />
            )}
          {showPreviouslyInvited &&
            previouslyInvitedUsers &&
            previouslyInvitedUsers.length > 0 && (
              <div>
                <div
                  style={{
                    alignItems: 'flex-start',
                    color: '#1c1047',
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: 18,
                  }}
                >
                  <div
                    style={{
                      ...SECTION_TITLE_STYLE,
                      margin: '0 0 16px 32px',
                      fontWeight: 500,
                    }}
                  >
                    People on Slack already added
                  </div>
                  {searchText === '' && (
                    <Button
                      type="link"
                      onClick={toggleInvitedUsers}
                      style={{
                        color: '#77779f',
                        fontWeight: 300,
                        height: '24px',
                        lineHeight: '24px',
                        padding: '0 32px 0 0',
                      }}
                    >
                      {previouslyInvitedUsersHeight === 0 ? 'Show' : 'Hide'}
                    </Button>
                  )}
                </div>
                <AnimateHeight height={previouslyInvitedUsersHeight}>
                  <List
                    dataSource={sortBy(previouslyInvitedUsers, ['firstName'])}
                    size="large"
                    itemLayout="vertical"
                    split={false}
                    renderItem={user => (
                      <List.Item style={{ padding: '0 0 12px 0' }}>
                        <ColleagueItemList user={user} />
                      </List.Item>
                    )}
                  />
                </AnimateHeight>
              </div>
            )}
        </div>
        <div className="invite-people-form-invite-field">
          <Form.Item help="You need to send invites so the people you've added can join your account. If you don't send email invites now, you can do so later via Admin > People.">
            <Checkbox
              onChange={handleOnInviteNowChange}
              checked={inviteCheckboxValue}
            >
              Send invites now
            </Checkbox>
          </Form.Item>
        </div>
        {hasInlineErrorMessage ? (
          <div
            style={{ margin: '0px 32px' }}
            className={`invite-people-form-error-container ${
              hasInlineErrorMessage ? 'has-error' : ''
            }`}
          >
            <div className="ant-form-explain">{errorMessage}</div>
          </div>
        ) : null}
        <div
          style={
            buttonsRightAligned
              ? {
                  justifyContent: 'flex-end',
                  display: 'flex',
                  margin: '24px 32px 16px',
                }
              : { margin: '24px 32px 16px' }
          }
        >
          {buttonsRightAligned && <CancelButton />}
          <Button
            type="accent"
            htmlType="submit"
            disabled={
              disabled ||
              isSubmitting ||
              isCancelling ||
              checkForErrors(usersToInvite).length > 0 ||
              usersToInvite.length === 0
            }
            loading={isSubmitting}
            onClick={handleSubmit}
            data-cy="invite-people-slack-form-submit-button"
          >
            {inviteCheckboxValue
              ? usersToInvite.length > 1
                ? 'Send invites'
                : 'Send invite'
              : usersToInvite.length > 1
              ? 'Add people'
              : 'Add person'}
          </Button>
          {!buttonsRightAligned && <CancelButton />}
        </div>
      </div>
    </>
  )
}
