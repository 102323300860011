import React from 'react'

import { Avatar, AvatarPlus } from './Avatar'

export default ({ users, limit, containerStyles = {} }) => {
  const moveRestToShow = () => {
    rest.forEach(x => toShow.push(x))
    rest = []
  }

  const toShow = users ? users.slice(0, limit) : []
  let rest = users
    ? users.slice(limit).sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
    : []
  if (rest.length === 1) {
    moveRestToShow()
  }

  return (
    <div style={{ display: 'flex', ...containerStyles }}>
      {toShow.length > 0 &&
        toShow.map(user => (
          <Avatar user={user} withBottomMargin withRightMargin key={user.id} />
        ))}
      {rest.length > 0 && (
        <AvatarPlus
          users={rest}
          content={rest.length}
          withBottomMargin
          withRightMargin
        ></AvatarPlus>
      )}
    </div>
  )
}
