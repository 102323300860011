import { Button, Card, Typography } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'

import SpeechBubble from '../../_assets/img/speech-bubble.svg'

const { Paragraph } = Typography

export default props => {
  const history = useHistory()

  const {
    text,
    author,
    location,
    hookTitle,
    hookDescription,
    button,
    route,
    routeState,
  } = props

  const handleClick = () => {
    history.push(route, { ...routeState, fromWBYHT: true })
  }

  return (
    <div style={{ flexDirection: 'row', display: 'flex', }}>
      <Card
        bordered={false}
        bodyStyle={{
          padding: '0px',
          display: 'flex',
        }}
        style={{
          width: "65%",
          marginRight: 15,
          borderRadius: 24
        }}
      >
        <div style={{ flexDirection: 'row', display: 'flex', }}>
          <Paragraph
            style={{
              color: '#281765',
              fontWeight: 300,
              margin: 24,
              fontSize: 14,
              lineHeight: '24px',
              width: "70%",
            }}
          >
            {text}
          </Paragraph>
          <div style={{
            fontSize: '18px',
            backgroundImage: `url(${SpeechBubble})`,
            backgroundSize: "cover",
            width: '30%',
            height: '10rem',
            paddingTop: 47,
            paddingLeft: 34,
          }}>
            <p
              style={{
                color: '#FFFFFF',
                fontFamily: 'museo',
                lineHeight: '28px',
                fontWeight: 300,
                fontSize: 16,
                marginBottom: -4,
              }}
            >
              {author}
            </p>
            <p
              style={{
                color: '#72170E',
                display: 'block',
                fontWeight: 200,
                fontSize: 12,
                lineHeight: '22px',
              }}
            >
              {location}
            </p>
          </div>
        </div>
      </Card>
      <Card
        bordered={false}
        bodyStyle={{ display: 'flex', flexDirection: 'column', padding: "1.5rem" }}
        style={{ width: "30%", borderRadius: 24 }}
      >
        <div
          style={{
            fontSize: '16px',
            marginBottom: 10,
            fontFamily: 'Museo, serif',
            fontWeight: 500,
            color: '#281765',
          }}
        >
          {hookTitle}
        </div>
        <div
          style={{
            fontSize: '14px',
            lineHeight: '24px',
            fontWeight: 300,
            marginBottom: 16,
            color: '#281765',
          }}
        >
          {hookDescription}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            type="accent-invert"
            size="small"
            onClick={handleClick}
            style={{ whiteSpace: "normal", height: "auto", padding: "0.2rem 0.6rem" }}
          >
            {button}
          </Button>
        </div>
      </Card>
    </div>
  )
}
