import { Tooltip } from 'antd'
import React from 'react'
import Text from 'react-texty'

import { Avatar, AvatarName } from '../../../../_components/Avatar'
import extractDisplayName from '../../../../_helpers/extractDisplayName'
import { Label } from '../../Table'

export default [
  {
    title: 'Name',
    key: 'name',
    width: '24%',
    render: data => (
      <div
        style={{ alignItems: 'center', display: 'flex', paddingLeft: '24px' }}
      >
        <Avatar
          user={data.user}
          size={24}
          style={{
            flexShrink: 0,
            maxWidth: '100%',
            fontSize: 12,
            lineHeight: '22px',
          }}
          disableTooltip
        />
        <Label
          style={{
            marginLeft: '8px',
          }}
        >
          <Text tooltip={extractDisplayName(data.user)}>
            <AvatarName
              user={data.user}
              style={{
                color: '#281765',
                fontSize: 14,
                fontWeight: 400,
              }}
            />
          </Text>
        </Label>
      </div>
    ),
  },
  {
    title: (
      <Tooltip title="Number of Pulse surveys this person was asked to answer">
        <span style={{ cursor: 'help' }}>Surveys received</span>
      </Tooltip>
    ),
    key: 'sent',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'pulse_survey_users_requests_received',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of Pulse surveys this person answered">
        <span style={{ cursor: 'help' }}>Surveys answered</span>
      </Tooltip>
    ),
    key: 'receivedFeedback',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'pulse_survey_users_requests_answered',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of Pulse surveys answered as a percentage of those they were asked to answer">
        <span style={{ cursor: 'help' }}>Response rate</span>
      </Tooltip>
    ),
    key: 'rate',
    width: '12%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'pulse_survey_giver_response_rate',
        )
        return (stat ? Math.floor(stat.value * 100) : 0) + '%'
      }
      return '0%'
    },
  },
]
