import './styles.less'

import { useMutation } from '@apollo/react-hooks'
import React, { useContext } from 'react'
import { useHistory, withRouter } from 'react-router-dom'

import logo from '../_assets/img/logo.svg'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import useQuery from '../hooks/routing/useQuery'
import { UserContext } from '../UserContext'
import { SET_ONBOARDING_STATUS } from './queries'
import Onboarding from '.'

const AppOnboarding = () => {
  const user = useContext(UserContext)
  const history = useHistory()
  const query = useQuery()

  useMobileOptimized(false)

  const [finishOnboardingMutation] = useMutation(SET_ONBOARDING_STATUS, {
    variables: {
      onboardingStatus: false,
    },
  })

  const finishOnboarding = async () => {
    const redirect = query.has('redirect') ? query.get('redirect') : undefined
    await finishOnboardingMutation()
    await user.refetchUser()
    history.push(redirect ? redirect : '/welcome?onboarded=1')
  }

  return (
    <div id="onboarding">
      <div style={{ padding: '40px 0 0' }}>
        <img
          src={logo}
          alt="Howamigoing"
          style={{ display: 'block', margin: '0 auto', maxHeight: '40px' }}
        />
      </div>
      <Onboarding finishOnboarding={finishOnboarding} />
    </div>
  )
}

export default withRouter(AppOnboarding)
