import { Card, Input, Select, Table } from 'antd'
import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import Pagination from '../../_components/Pagination'
import useTeams from '../../hooks/graphql/Teams/useTeams'

export const Label = ({ children, style }) => {
  return (
    <div
      style={{
        display: 'inline-block',
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        verticalAlign: 'middle',
        ...style,
      }}
    >
      {children}
    </div>
  )
}

export default ({
  withSearch,
  searchPlaceholder,
  withTeamPicker,
  data,
  columns,
  rowKey,
  onRow,
  loading,
  onSearchChange,
  onTeamChange,
  onPageChange,
  total,
  itemsName,
  noHover,
}) => {
  const [currentPage, setCurrentPage] = useState(1)

  const { data: teamsData /* loading: teamsLoading */ } = useTeams({
    noTeamName: 'Not part of a team',
    variables: {
      userTypes: ['invited', 'active'],
    },
    skip: !withTeamPicker,
  })

  const [debouncedSearchChange] = useDebouncedCallback(val => {
    onSearchChange(val)
  }, 500)

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    onPageChange(page, pageSize)
  }

  return (
    <div className="insights__table">
      {(withSearch || withTeamPicker) && (
        <div className="insights__filter-container">
          {withSearch && (
            <Input
              className="insights__search-input"
              suffix={false}
              placeholder={searchPlaceholder || 'Search people'}
              onChange={evt => debouncedSearchChange(evt.target.value)}
            />
          )}
          {withTeamPicker && (
            <Select
              defaultValue={''}
              onChange={onTeamChange}
              dropdownMatchSelectWidth
              className="insights__select"
              dropdownClassName="insights__dropdown"
            >
              <Select.Option key="all-teams" value="">
                All teams
              </Select.Option>
              {teamsData &&
                teamsData.teams &&
                teamsData.teams.map(team => (
                  <Select.Option key={team.id} value={team.id}>
                    {team.name}
                  </Select.Option>
                ))}
            </Select>
          )}
        </div>
      )}
      <div className={`card-table ${noHover ? 'card-table--no-hover' : ''}`}>
        <Card>
          <Table
            dataSource={data}
            columns={columns}
            rowKey={rowKey}
            loading={loading}
            pagination={false}
            onRow={onRow}
          />
          {total > 0 && (
            <div className="insights__pagination">
              <Pagination
                onChange={handlePageChange}
                total={total}
                withPageSizeChanger={false}
                pageSize={10}
                current={currentPage}
                itemsName={itemsName}
              />
            </div>
          )}
        </Card>
      </div>
    </div>
  )
}
