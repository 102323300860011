import React from 'react'

import Emoji from 'react-emoji-render'
import { EmptyState } from '../../components'

const LandingPageEmptyState = ({ showAskQuestionModal }) => {

  return (
    <>
      <EmptyState
        heading={<Emoji text="Anything bugging you? :thinking_face:" />}
        text={
          <span>
            There must be something you’ve always wanted to ask. Pick a
            colleague, type in your question and the answer will come. And hey,
            our lips are sealed: we’ll keep you{' '}
            <span color="#281765">anonymous</span>.
          </span>
        }
        buttonText="Ask an anonymous question"
        onButtonClick={showAskQuestionModal}
      />
    </>
  )
}

export default LandingPageEmptyState
