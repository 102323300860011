import React from 'react'

import { VERTICAL_MARGIN_DOUBLE } from './CONSTANTS'

const FeedbackReceivedTitle = () => {
  return (
    <div style={{ marginBottom: VERTICAL_MARGIN_DOUBLE, color: '#77779f' }}>
      Feedback you received
    </div>
  )
}

export default FeedbackReceivedTitle
