import { Menu } from 'antd'
import React, { useState } from 'react'

import HelpIcon from '../../../_components/HelpIcon'
import AddUserToTeamModal from '../AddUserToTeamModal'
import RemoveUserFromTeamModal from '../RemoveUserFromTeamModal'

export default ({ user, team, teams, onClick }) => {
  const [
    removeUserFromTeamModalVisible,
    setRemoveUserFromTeamModalVisible,
  ] = useState(false)

  const [addUserToTeamModalVisible, setAddUserToTeamModalVisible] = useState(
    false,
  )

  const handleClick = params => {
    params.domEvent.stopPropagation()
    switch (params.key) {
      case 'remove':
        showRemoveUserModal()
        break

      case 'add':
        showAddUserModal()
        break

      default:
        break
    }
    if (onClick) {
      onClick(params.domEvent)
    }
  }

  const showAddUserModal = () => setAddUserToTeamModalVisible(true)
  const hideAddUserModal = () => setAddUserToTeamModalVisible(false)

  const showRemoveUserModal = () => setRemoveUserFromTeamModalVisible(true)
  const hideRemoveUserModal = () => setRemoveUserFromTeamModalVisible(false)

  const hasTeam = !!team.id
  const canChangeTeam =
    !user.readonlyProperties ||
    (user.readonlyProperties &&
      !user.readonlyProperties.find(x => x.name === 'team'))

  return (
    <>
      <Menu onClick={handleClick} className="company-team-menu">
        {!hasTeam ? (
          <Menu.Item key={'add'} disabled={!canChangeTeam}>
            <HelpIcon
              tooltip={
                !canChangeTeam ? 'Must be added to the team via Personio' : null
              }
            >
              Add to team
            </HelpIcon>
          </Menu.Item>
        ) : (
          <Menu.Item key={'remove'} disabled={!canChangeTeam}>
            <HelpIcon
              tooltip={
                !canChangeTeam
                  ? 'Must be removed from the team via Personio'
                  : null
              }
            >
              Remove from team
            </HelpIcon>
          </Menu.Item>
        )}
      </Menu>
      <RemoveUserFromTeamModal
        user={user}
        teamId={team.id}
        teamName={team.name}
        visible={removeUserFromTeamModalVisible}
        onOk={hideRemoveUserModal}
        onCancel={hideRemoveUserModal}
      />
      <AddUserToTeamModal
        user={user}
        teams={teams}
        visible={addUserToTeamModalVisible}
        onOk={hideAddUserModal}
        onCancel={hideAddUserModal}
      />
    </>
  )
}
