import { useLazyQuery } from '@apollo/react-hooks'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'

import speedometer from '../../../../_assets/img/speedometer.svg'
import { DEFAULT_SPACING } from '../CONSTANTS'
import MultiChoiceAnswers from '../MultiChoiceAnswers'
import { GET_QUESTION } from '../queries'
import TextAnswers from '../TextAnswers'
import SentimentAnalysis from '../TextAnswers/SentimentAnalysis'

export default ({
  section,
  question,
  index,
  peopleAsked,
  reviewId,
  teamsArray,
}) => {
  const [questionDetails, setQuestionDetails] = useState(question)
  const [paginationEnabled, setPaginationEnabled] = useState(true)

  const [selectedSentiment, setSelectedSentiment] = useState(
    !!question.sentimentAnalysis ? 'positive' : 'regular',
  )

  const [fetchResponses, { data, loading }] = useLazyQuery(GET_QUESTION, {
    fetchPolicy: 'no-cache',
    variables: {
      reviewId,
      formQuestionId: question.id,
      responseType: selectedSentiment,
      offset: 0,
      limit: 10,
    },
    onCompleted: () => {
      setPaginationEnabled(true)
    },
  })

  useEffect(() => {
    if (data) {
      setQuestionDetails(data.question)
    }
  }, [data])

  const handleClick = sentiment => {
    if (sentiment !== selectedSentiment) {
      setPaginationEnabled(false)
      setSelectedSentiment(sentiment)
      fetchResponses()
    }
  }

  const onPageChange = (page, pageSize) => {
    fetchResponses({
      variables: {
        offset: page * pageSize - pageSize,
        limit: pageSize,
      },
    })
  }

  const onPageShowSizeChange = size => {
    fetchResponses({
      variables: {
        offset: 0,
        limit: size,
      },
    })
  }

  if (
    teamsArray &&
    teamsArray.length > 0 &&
    questionDetails.totalAnswers <= 2
  ) {
    return (
      <div
        style={{
          fontSize: 18,
          lineHeight: '28px',
          paddingTop: DEFAULT_SPACING * 4,
          paddingBottom: DEFAULT_SPACING * 4,
          borderBottomWidth: 1,
          borderBottom: '1px solid #cac8dc',
        }}
      >
        <p
          style={{
            fontSize: 18,
          }}
        >
           {questionDetails.title}
        </p>
        <p
          style={{
            fontFamily: 'Europa',
            fontSize: 14,
            fontWeight: 300,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.57,
            letterSpacing: 'normal',
            color: '#77779f',
          }}
        >
          Your filter is too narrow{' '}
          <span role="img" aria-label="shrug">
            🤷‍♂️
          </span>
        </p>
        <p
          style={{
            fontFamily: 'Europa',
            fontSize: 16,
            fontWeight: 300,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal',
            color: '#1c1047',
          }}
        >
          We only show survey results when the data set includes 2 or more
          people. Please try a different filter that includes more people.
        </p>
        <Button
          className="ama-empty-state__button"
          type="primary"
          style={{ marginLeft: 'auto' }}
        >
          Learn more about anonymity
        </Button>
      </div>
    )
  } else {
    return (
      <div
        style={{
          borderBottom:
            section.questions.length - 1 === index ? 0 : '1px solid #e9e8ef',
          paddingBottom:
            section.questions.length - 1 === index ? 0 : DEFAULT_SPACING * 3,
          marginBottom:
            section.questions.length - 1 === index
              ? DEFAULT_SPACING * 2
              : DEFAULT_SPACING * 4,
        }}
      >
        <div
          style={{
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              fontSize: 18,
              lineHeight: '28px',
              marginBottom: 6,
              paddingRight: DEFAULT_SPACING * 4,
            }}
          >
            {questionDetails.title}
            {!questionDetails.required && ' (Optional)'}
          </div>
          {!!questionDetails.sentimentAnalysis && (
            <img
              src={speedometer}
              alt="Sentiment analysis logo"
              style={{ display: 'block', flex: '0 0 40px', paddingTop: '4px' }}
            />
          )}
        </div>

        <div
          style={{
            marginBottom: DEFAULT_SPACING * 4,
          }}
        >
          <div className="small-letter">
            {peopleAsked === questionDetails.totalAnswers
              ? 'Everybody answered'
              : `${questionDetails.totalAnswers} out of ${peopleAsked} answered`}
          </div>

          {questionDetails.sentimentAnalysis && (
            <SentimentAnalysis
              details={questionDetails.sentimentAnalysis}
              selected={selectedSentiment}
              onClick={handleClick}
            />
          )}
        </div>

        <div>
          {questionDetails.type === 'MULTI_CHOICE' && (
            <MultiChoiceAnswers answers={questionDetails.responses} />
          )}
          {(questionDetails.type === 'TEXT' ||
            questionDetails.type === 'SHORT_TEXT') && (
              <TextAnswers
                question={questionDetails}
                selectedSentiment={selectedSentiment}
                loadingAnswers={loading}
                paginationEnabled={paginationEnabled}
                onPageChange={onPageChange}
                onPageShowSizeChange={onPageShowSizeChange}
              />
            )}
        </div>
      </div>
    )
  }
}
