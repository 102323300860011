import React from 'react'

import Participant from '../Participant'

const AnonymousQuestion = ({ children }) => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <Participant />
      <div
        style={{
          fontSize: '16px',
          fontWeight: 300,
          color: '#1c1047',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default AnonymousQuestion
