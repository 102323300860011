import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useState } from 'react'

import AutocompleteTeam from '../../components/AutocompleteTeam'
import UserDisplayName from '../../components/UserDisplayName'
import { GET_TEAMS_LIST } from '../queries'
import { GET_TEAM, GET_USERS_WITHOUT_A_TEAM } from '../TeamsList/queries'
import { ADD_TEAM_MEMBER, GET_TEAMS } from './queries'

export default ({ user, visible, onOk, onError, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [teamId, setTeamId] = useState(null)

  const { data: teamsData, loading: teamsLoading } = useQuery(GET_TEAMS, {
    fetchPolicy: 'cache-and-network',
  })
  const [addTeamMember] = useMutation(ADD_TEAM_MEMBER, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_TEAMS_LIST,
      },
      {
        query: GET_USERS_WITHOUT_A_TEAM,
      },
      {
        query: GET_TEAM,
        variables: {
          id: teamId,
        },
      },
    ],
  })

  const handleSubmit = async () => {
    try {
      setLoading(true)
      await addTeamMember({
        variables: {
          teamId,
          memberId: user.id,
        },
      })
      setLoading(false)
      if (onOk) {
        onOk()
      }
    } catch (e) {
      setLoading(false)
      if (onError) {
        onError(e)
      }
    }
  }

  const handleChange = val => {
    setTeamId(val)
  }

  return (
    <Modal
      title="Add user to team"
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !teamId || loading,
        type: 'accent',
      }}
      confirmLoading={loading}
      okText="Add to team"
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      closable={false}
      maskClosable={!loading}
      bodyStyle={{ fontWeight: 300, padding: '32px 32px 0' }}
      width={584}
      className="update-user-team-modal"
      destroyOnClose
      centered
    >
      <div style={{ marginBottom: 8 }}>
        Add{' '}
        <span style={{ fontWeight: 500 }}>
          <UserDisplayName user={user} />
        </span>{' '}
        to team:
      </div>

      {!teamsLoading && teamsData && (
        <AutocompleteTeam
          onSelect={handleChange}
          style={{ marginBottom: 32 }}
          teams={teamsData.teams}
        />
      )}
    </Modal>
  )
}
