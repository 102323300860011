import './style.less'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, List, Modal, Row, Slider, Typography } from 'antd'
import { Tooltip } from 'antd'
import React from 'react'

import speedometer from '../../../_assets/img/speedometer.svg'

const { TextArea } = Input
const { Title } = Typography

const DEFAULT_SPACING = 8

export default ({ form, modalVisible, onClose, onSelect }) => {
  const footerButtons = [
    <Button type="ghost" key="close" onClick={() => onClose()}>
      Cancel
    </Button>,
  ]

  onSelect &&
    footerButtons.push(
      <Button key="use" type="accent" onClick={onSelect}>
        Use this form
      </Button>,
    )

  const getQuestionsForSection = section => {
    return section.formQuestions.map(formQuestion => {
      const fullFormQuestion = form.formQuestions.find(
        fq => fq.id === formQuestion.id,
      )

      if (fullFormQuestion) {
        const questionDetails = fullFormQuestion.question
        let questionInput

        if (questionDetails.template.type === 'MULTI_CHOICE') {
          const optionsArr = questionDetails.template.settings.options
          const optionsToSet = {}
          const step = 100 / (optionsArr.length - 1)

          optionsArr.forEach((option, index) => {
            if (index === optionsArr.length - 1) {
              optionsToSet[100] = option
            } else {
              optionsToSet[index * step] = option
            }
          })

          questionInput = (
            <div style={{ paddingBottom: DEFAULT_SPACING * 2 }}>
              <Slider
                marks={optionsToSet}
                step={null}
                tipFormatter={null}
                initialValue={0}
                disabled
              />
            </div>
          )
        }

        if (
          ['TEXT', 'SHORT_TEXT'].indexOf(questionDetails.template.type) > -1
        ) {
          questionInput = (
            <div>
              <TextArea
                disabled
                placeholder={formQuestion.placeholder}
                style={{
                  backgroundColor: 'white',
                  boxShadow: 'rgba(119, 119, 159, 0.12) 0px 16px 24px 0px',
                  resize: 'none',
                }}
              />
              <div
                className="small-letter"
                style={{
                  textAlign: 'right',
                  marginTop: DEFAULT_SPACING * 2.5,
                }}
              >
                {questionDetails.template.settings.characterLimit} characters
                remaining
              </div>
            </div>
          )
        }

        return (
          <div key={formQuestion.id} style={{ marginTop: DEFAULT_SPACING * 2 }}>
            <div
              style={{
                fontWeight: 300,
                fontSize: 18,
                color: '#1c1047',
                marginBottom: DEFAULT_SPACING * 2,
              }}
            >
              {fullFormQuestion.isSentimentAnalysisActivated ? (
                <div
                  style={{
                    alignItems: 'flex-start',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      fontSize: 18,
                      lineHeight: '28px',
                      marginBottom: 6,
                      paddingRight: DEFAULT_SPACING * 4,
                    }}
                  >
                    {questionDetails.titleVariants[0].title}
                    {!formQuestion.required && ' (Optional)'}
                  </div>
                  <div style={{ cursor: 'pointer', flex: '0 0 32px' }}>
                    <Tooltip
                      title={
                        'Sentiment analysis is available for this question'
                      }
                    >
                      <img
                        src={speedometer}
                        alt="Sentiment analysis logo"
                        style={{
                          display: 'block',
                          paddingTop: '6px',
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <>
                  {questionDetails.titleVariants[0].title}
                  {!formQuestion.required && ' (Optional)'}
                </>
              )}
            </div>
            {questionInput}
          </div>
        )
      } else {
        return <span></span>
      }
    })
  }

  return (
    <Modal
      title={
        <div style={{ fontFamily: 'museo', fontWeight: 500 }}>Preview form</div>
      }
      visible={modalVisible}
      centered
      onCancel={onClose}
      footer={footerButtons}
      afterClose={() => onClose()}
      width={720}
      style={{ marginTop: DEFAULT_SPACING * 3 }}
      destroyOnClose
    >
      {form && (
        <div>
          <Row
            style={{
              marginBottom: DEFAULT_SPACING * 3,
              borderBottom: '1px solid #cac8dc',
              paddingBottom: DEFAULT_SPACING * 3,
            }}
          >
            <div
              style={{
                borderRadius: 16,
                backgroundColor: 'rgb(218, 218, 229, 0.2)',
                fontWeight: 400,
                fontFamily: 'Europa',
                lineHeight: 2,
                padding: DEFAULT_SPACING * 3,
              }}
            >
              <div>Who is this for?</div>
              <div style={{ fontWeight: 300, lineHeight: 1.5 }}>
                {form.recipients}
              </div>
              <div style={{ marginTop: DEFAULT_SPACING }}>
                How is this helpful?
              </div>
              <div style={{ fontWeight: 300, lineHeight: 1.5 }}>
                {form.description}
              </div>
            </div>
          </Row>
          <div>
            <div
              style={{
                fontFamily: 'Europa',
                fontWeight: 400,
                padding: 9,
                borderRadius: 6,
                borderColor: '#34DBE6',
                borderWidth: 1,
                borderStyle: 'solid',
                backgroundColor: 'rgb(52, 219, 230, 0.2)',
                fontSize: 14,
              }}
            >
              <FontAwesomeIcon
                icon={['fal', 'info-circle']}
                style={{
                  color: '#34DBE6',
                  fontSize: 14,
                  marginRight: 4,
                }}
              />
              People invited to give feedback are going to see the below.
            </div>
          </div>
          <div>
            <div
              style={{
                paddingTop: 24,
                fontFamily: 'museo',
                color: '#281765',
                fontWeight: 500,
                lineHeight: 1.3,
                fontSize: 24,
                marginRight: 4,
              }}
            >
              {form && form.title}
            </div>
            <List
              itemLayout="vertical"
              dataSource={form && form.sections}
              renderItem={(section, index) => (
                <List.Item key={index} style={{ padding: '32px 0' }}>
                  <Title
                    level={4}
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    {section.title}
                  </Title>
                  <Row>{getQuestionsForSection(section)}</Row>
                </List.Item>
              )}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
