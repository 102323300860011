import React from 'react'

import DropdownForTeam from './DropdownForTeam'

const PanelHeader = ({ teamId, title, count, canDelete }) => {
  const haveTeam = !!teamId
  return (
    <div className="team-list-v2__panel-header">
      <div>
        <span className="team-list-v2__panel-header-title">{title}</span>
        <span className="team-list-v2__panel-header-meta">
          {count} member
          {count !== 1 ? 's' : null}
        </span>
      </div>
      {haveTeam && (
        <div className="team-list-v2__panel-action-button">
          <DropdownForTeam teamId={teamId} canDelete={canDelete} />
        </div>
      )}
    </div>
  )
}

export default PanelHeader
