import {
  Card,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  InputNumber,
  Row,
  Tooltip,
  Typography,
} from 'antd'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'

import SelectTimezones from '../../../../_components/SelectTimezones'
import { track } from '../../../../_helpers/analytics'
import { UserContext } from '../../../../UserContext'
import { CYCLE_TYPES, getCycleBaseUrl } from '../../helpers'
import ButtonsBottomPersonal360 from '../components/CycleCreationEditButtonsBottom'
import { DEFAULT_SPACING, MINIMUM_CYCLE_LENGTH_DAYS } from '../constants'
import Context from '../Context'
import StepsContext from '../Context/StepsContext'

const { Title } = Typography

const STYLE_LAST_INPUT = {
  boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
  borderRadius: 16,
}

const StepDetails = props => {
  const user = useContext(UserContext)
  const cycleContext = useContext(Context)
  const stepsContext = useContext(StepsContext)

  const minInput = useRef(null)
  const maxInput = useRef(null)

  const [nameValidationStatus, setNameValidationStatus] = useState('')
  const [nameHelp, setNameHelp] = useState('')

  const [startDateValidationStatus, setStartDateValidationStatus] = useState('')
  const [startDateHelp, setStartDateHelp] = useState('')
  const [endDateValidationStatus, setEndDateValidationStatus] = useState('')
  const [endDateHelp, setEndDateHelp] = useState('')

  const [timezoneValidationStatus, setTimezoneValidationStatus] = useState('')
  const [timezoneHelp, setTimezoneHelp] = useState('')
  const [timezoneSelected, setTimezoneSelected] = useState(null)

  const cycleType = cycleContext.type
  const cyclePath = getCycleBaseUrl(cycleType)

  useEffect(() => {
    if (!cycleContext.name && cycleContext.form) {
      cycleContext.setContext({
        name: cycleContext.form.title + moment.tz().format(' (MMM YYYY)'),
      })
    }
  }, [])

  useEffect(() => {
    // By default AntD had the local timezone for moment.js
    // so we need to do this to set the correct timezone
    // when no timezone is selected
    setTimezoneSelected(
      cycleContext.timezone ? cycleContext.timezone : moment.tz.guess(),
    )
  }, [cycleContext.timezone])

  const convertMomentTimezone = (oldMoment, newTimezone) => {
    return moment.tz(oldMoment.format('YYYY-MM-DD HH:mm'), newTimezone)
  }

  // if cycle is starting today, have it start right now
  // otherwise, have cycle start at 9AM on the day
  const setCycleDateStart = (startDate, timezone) => {
    let fixedStartDate
    const now = moment.tz(timezone)
    if (startDate) {
      fixedStartDate = !startDate.isSame(now, 'day')
        ? startDate.set('hour', 9).startOf('hour')
        : now
    }

    cycleContext.setContext({
      startDate: fixedStartDate ? fixedStartDate.unix() : null,
    })
  }

  // cycle ends at 17:00 on the last day
  const setCycleDateEnd = (endDate, timezone) => {
    cycleContext.setContext({
      endDate: endDate
        ? endDate.tz(timezone).set('hour', 23).set('minute', 59).unix()
        : null,
    })
  }

  const startDateChange = (startDate, startDateString) => {
    startDate
      ? setCycleDateStart(
          moment.tz(startDateString, 'DD-MMM-YYYY', timezoneSelected),
          timezoneSelected,
        )
      : cycleContext.setContext({ startDate: null })
    cycleContext.setContext({ endDate: null })
  }

  const endDateChange = (endDate, endDateString) => {
    endDate
      ? setCycleDateEnd(
          moment.tz(endDateString, 'DD-MMM-YYYY', timezoneSelected),
          timezoneSelected,
        )
      : cycleContext.setContext({ endDate: null })
  }

  // When timezone changes the cycleDates have to change
  // because the timestamp isn't the same in different timezones
  // so we recalculate
  const setTimezone = timezone => {
    let changeDayStart
    let currentStartDate
    if (cycleContext.startDate) {
      currentStartDate = moment
        .unix(cycleContext.startDate)
        .tz(timezoneSelected)
      const currentDayOldTimezone = moment.tz(timezoneSelected)
      const currentDayNewTimezone = moment.tz(timezone).format('YYYY-MM-DD')

      //Check if with the new timezone the day is changing
      if (
        currentStartDate.isSame(currentDayOldTimezone, 'day') &&
        moment
          .tz(currentDayNewTimezone, timezoneSelected)
          .isAfter(currentDayOldTimezone, 'day')
      ) {
        currentStartDate = moment
          .unix(cycleContext.startDate)
          .tz(timezoneSelected)
          .add(1, 'day')
        changeDayStart = true
      }
      // Create the news moment objects from the timestamps with the last timezone that we had
      setCycleDateStart(
        convertMomentTimezone(currentStartDate, timezone),
        timezone,
      )
    }
    if (cycleContext.endDate) {
      let currentEndDate = moment
        .unix(cycleContext.endDate)
        .tz(timezoneSelected)
      if (
        changeDayStart &&
        currentEndDate.isBefore(
          currentStartDate
            .clone()
            .add(MINIMUM_CYCLE_LENGTH_DAYS, 'days')
            .startOf('day'),
          'day',
        )
      ) {
        currentEndDate.add(1, 'day')
      }
      setCycleDateEnd(convertMomentTimezone(currentEndDate, timezone), timezone)
    }
    cycleContext.setContext({ timezone: timezone })
  }

  const handleForm = () => {
    let hasError = false

    if (cycleContext.name === '') {
      setNameValidationStatus('error')
      setNameHelp('Please enter a name')
      hasError = true
    } else {
      setNameValidationStatus('')
      setNameHelp('')
    }

    if (cycleContext.startDate === null) {
      setStartDateValidationStatus('error')
      setStartDateHelp('Please select a start date')
      hasError = true
    } else {
      setStartDateValidationStatus('')
      setStartDateHelp('')
    }

    if (cycleContext.endDate === null) {
      setEndDateValidationStatus('error')
      setEndDateHelp('Please select an end date')
      hasError = true
    } else {
      setEndDateValidationStatus('')
      setEndDateHelp('')
    }

    if (cycleContext.timezone === null) {
      setTimezoneValidationStatus('error')
      setTimezoneHelp('Please select a time zone')
      hasError = true
    } else {
      setTimezoneValidationStatus('')
      setTimezoneHelp('')
    }

    if (
      cycleType === CYCLE_TYPES.threeSixty &&
      cycleContext.minRequests !== minInput.current.props.value
    ) {
      cycleContext.setContext({ minRequests: minInput.current.props.value })
    }

    if (
      cycleType === CYCLE_TYPES.threeSixty &&
      cycleContext.maxRequests !== maxInput.current.props.value
    ) {
      cycleContext.setContext({ maxRequests: maxInput.current.props.value })
    }

    if (!hasError) {
      track(`${cycleType}.cycle.finalDetailsNextStep`, {
        user: user.email,
        org: user.org.name,
        cycleName: cycleContext.name,
        startDate: cycleContext.startDate,
        endDate: cycleContext.endDate,
        timezone: cycleContext.timezone,
      })
      const newUrl = cycleContext.id
        ? `${cyclePath}/edit/${cycleContext.id}/4`
        : `${cyclePath}/create/4`
      stepsContext.setStep(3)
      props.history.push(newUrl)
    }
  }

  const disabledStartDate = current => {
    current = convertMomentTimezone(current, timezoneSelected).startOf('day')
    return current && current.isBefore(moment.tz(timezoneSelected), 'day')
  }

  const disabledEndDate = current => {
    current = convertMomentTimezone(current, timezoneSelected).startOf('day')
    if (cycleContext.startDate) {
      return (
        current &&
        current.isBefore(
          moment
            .unix(cycleContext.startDate)
            .tz(timezoneSelected)
            .add(MINIMUM_CYCLE_LENGTH_DAYS, 'days'),
          'day',
        )
      )
    }
  }

  const totalNumberColleagues = cycleContext.totalNumberColleagues

  const handleMinimumNumberChange = value => {
    if (!value) {
      value = 2
    }
    const normalizedValue = parseInt(value)
    cycleContext.setContext({ minRequests: normalizedValue })
    track(`${cycleType}.cycle.minimum`, {
      user: user.email,
      org: user.org.name,
      min: normalizedValue,
    })
    if (cycleContext.maxRequests < normalizedValue && normalizedValue <= 50) {
      cycleContext.setContext({ maxRequests: normalizedValue })
    }
  }

  const handleMaximumNumberChange = value => {
    if (!value) {
      value = 2
    }
    cycleContext.setContext({ maxRequests: parseInt(value) })
    track(`${cycleType}.cycle.maximum`, {
      user: user.email,
      org: user.org.name,
      max: value,
    })
  }

  return (
    <div
      className="new-cycle-details"
      style={{ paddingTop: DEFAULT_SPACING * 2 }}
    >
      <Form colon={false}>
        <Card
          style={{
            marginBottom: DEFAULT_SPACING * 2,
            padding: `${DEFAULT_SPACING}px ${DEFAULT_SPACING}px 0 `,
          }}
        >
          <div>
            <Title
              level={4}
              style={{
                color: '#1c1047',
                marginBottom: DEFAULT_SPACING,
                lineHeight: '32px',
              }}
            >
              {cycleContext.type === CYCLE_TYPES.pulse
                ? 'Let’s give the survey a name'
                : 'Let’s give the event a name'}
            </Title>
            <div style={{ marginBottom: DEFAULT_SPACING * 2, fontWeight: 300 }}>
              {cycleContext.type === CYCLE_TYPES.pulse
                ? `This makes it easier to view survey answers when you've used the same form for multiple times.`
                : `This makes it easier to view feedback when you've used the same
              form for multiple events.`}
            </div>
            <Form.Item
              validateStatus={nameValidationStatus}
              help={nameHelp}
              style={{ marginBottom: DEFAULT_SPACING * 3, width: 356 }}
            >
              <Input
                value={cycleContext.name}
                style={STYLE_LAST_INPUT}
                onChange={e =>
                  cycleContext.setContext({ name: e.target.value })
                }
                data-cy="event-name"
              />
            </Form.Item>
          </div>
          {cycleType === CYCLE_TYPES.threeSixty && (
            <div
              style={{
                paddingTop: 24,
                marginTop: 32,
                borderTop: '1px solid #dadae5',
              }}
            >
              <Title
                level={4}
                style={{
                  color: '#1c1047',
                  marginBottom: DEFAULT_SPACING,
                  lineHeight: '32px',
                }}
              >
                Confirm how many colleagues people can request feedback from
              </Title>
              <div style={{ marginBottom: DEFAULT_SPACING, fontWeight: 300 }}>
                People can ask between{' '}
                <InputNumber
                  size="small"
                  ref={minInput}
                  min={2}
                  max={
                    totalNumberColleagues >= 3 ? totalNumberColleagues - 1 : 2
                  }
                  precision={0}
                  value={cycleContext.minRequests}
                  // defaultValue={cycleContext.minRequests}
                  onChange={handleMinimumNumberChange}
                  onPressEnter={e => {
                    minInput.current.blur()
                  }}
                />{' '}
                and{' '}
                <InputNumber
                  ref={maxInput}
                  size="small"
                  onPressEnter={e => {
                    maxInput.current.blur()
                  }}
                  precision={0}
                  min={cycleContext.minRequests ? cycleContext.minRequests : 2}
                  max={
                    totalNumberColleagues >= 51 ? 50 : totalNumberColleagues - 1
                  }
                  value={
                    cycleContext.maxRequests
                      ? cycleContext.maxRequests
                      : totalNumberColleagues >= 7
                      ? 6
                      : totalNumberColleagues - 1
                  }
                  onChange={handleMaximumNumberChange}
                />{' '}
                colleagues for feedback.
              </div>
              <div
                style={{ marginBottom: DEFAULT_SPACING * 2, fontWeight: 300 }}
              >
                Please note that each person's Manager is{' '}
                <strong style={{ color: '#f95c4b' }}>
                  automatically included
                </strong>{' '}
                in their feedback request.
              </div>
            </div>
          )}
          <div
            style={{
              paddingTop: 24,
              marginTop: 32,
              borderTop: '1px solid #dadae5',
            }}
          >
            <Row gutter={16}>
              <Col span={24}>
                <Title
                  level={4}
                  style={{ marginBottom: DEFAULT_SPACING, lineHeight: '32px' }}
                >
                  {cycleContext.type === CYCLE_TYPES.pulse
                    ? `Now finally, let’s set the survey timing`
                    : `Now finally, let’s set the event timing`}
                </Title>
                <div
                  style={{
                    marginBottom: DEFAULT_SPACING * 2,
                    fontWeight: 300,
                    color: '#1c1047',
                  }}
                >
                  During the event, colleagues asked to{' '}
                  {cycleContext.type === CYCLE_TYPES.pulse
                    ? 'answer'
                    : 'give feedback'}{' '}
                  will get reminders on Tuesdays at 3pm and Fridays at 9am.
                </div>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={startDateValidationStatus}
                  help={startDateHelp}
                  label={
                    <span style={{ fontWeight: 300 }}>
                      Start date
                      <Tooltip title="If your event starts today it will begin immediately. If it starts on a future date it will begin at 9am in the event timezone.">
                        <Icon
                          type="question-circle"
                          size={16}
                          style={{
                            color: '#77779f',
                            marginLeft: '6px',
                            position: 'relative',
                            top: '1px',
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                >
                  <DatePicker
                    disabledDate={disabledStartDate}
                    format={'DD MMM YYYY'}
                    style={STYLE_LAST_INPUT}
                    value={
                      cycleContext.startDate &&
                      moment.unix(cycleContext.startDate).tz(timezoneSelected)
                    }
                    placeholder="Select date"
                    onChange={startDateChange}
                    data-cy="event-start-date"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  validateStatus={endDateValidationStatus}
                  help={endDateHelp}
                  label={
                    <span style={{ fontWeight: 300 }}>
                      End date
                      <Tooltip title="The event needs to run for 1-2 weeks so everyone has time to respond to feedback requests. The event will end at 11:59pm in the event timezone.">
                        <Icon
                          type="question-circle"
                          size={16}
                          style={{
                            color: '#77779f',
                            marginLeft: '6px',
                            position: 'relative',
                            top: '1px',
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                >
                  <DatePicker
                    disabled={cycleContext.startDate ? false : true}
                    disabledDate={disabledEndDate}
                    style={STYLE_LAST_INPUT}
                    format={'DD MMM YYYY'}
                    value={
                      cycleContext.endDate &&
                      moment.unix(cycleContext.endDate).tz(timezoneSelected)
                    }
                    placeholder="Select date"
                    onChange={endDateChange}
                    data-cy="event-end-date"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span style={{ fontWeight: 300 }}>
                      Event timezone
                      <Tooltip title="The event timezone determines the start and end times, and when reminder emails are sent.">
                        <Icon
                          type="question-circle"
                          size={16}
                          style={{
                            color: '#77779f',
                            marginLeft: '6px',
                            position: 'relative',
                            top: '1px',
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                  validateStatus={timezoneValidationStatus}
                  help={timezoneHelp}
                  style={{ marginBottom: 0 }}
                >
                  <div>
                    <SelectTimezones
                      value={cycleContext.timezone}
                      onChange={setTimezone}
                      placeholder="Select timezone"
                      style={STYLE_LAST_INPUT}
                    />
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
        <Form.Item>
          <ButtonsBottomPersonal360
            step={3}
            handleContinueClick={handleForm}
            contextId={cycleContext.id}
            typePath={cyclePath}
            fromWBYHT={cycleContext.fromWBYHT}
          />
        </Form.Item>
      </Form>
    </div>
  )
}

export default withRouter(StepDetails)
