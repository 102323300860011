import gql from 'graphql-tag'

export const GOOD_VIBES = gql`
  query goodVibes($offset: Int, $limit: Int, $search: GoodVibeSearchInput) {
    goodVibes(
      offset: $offset
      limit: $limit
      search: $search
    ) {
      total
      goodVibes {
        id
        recipients {
          id
          firstName
          lastName
          jobTitle
          avatarUrl
          email
          status
        }
        creator {
          id
          firstName
          lastName
          jobTitle
          avatarUrl
          email
          status
        }
        sentAt
        editedAt
        fact
        feeling
        draft {
          recipientIds
          fact
          feeling
          lastSyncedAt
        }
        reportedAbuse {
          id
          abuse
          reportedAt
          reporter {
            id
            firstName
            lastName
            email
            status
          }
        }
        comments {
          id
          value
          submittedAt
          editedAt
          author {
            id
            firstName
            lastName
            jobTitle
            avatarUrl
            email
            status
          }
          reportedAbuse {
            id
            abuse
            reportedAt
            reporter {
              id
              firstName
              lastName
              email
              status
            }
          }
        }
        reactions {
          id
          reaction
          submittedAt
          author {
            id
            firstName
            lastName
            jobTitle
            avatarUrl
            email
            status
          }
        }
      }
    }
  }
`

export const EDIT_GOOD_VIBE = gql`
  mutation(
    $goodVibeId: String!
    $recipientIds: [String!]!
    $fact: String!
    $feeling: String!
  ) {
    editGoodVibe(
      goodVibeId: $goodVibeId
      recipientIds: $recipientIds
      fact: $fact
      feeling: $feeling
    ) {
      id
      recipients {
        id
        firstName
        lastName
        email
      }
      creator {
        id
        firstName
        lastName
        email
      }
      sentAt
      fact
      feeling
    }
  }
`

export const SAVE_GOOD_VIBE_DRAFT = gql`
  mutation(
    $goodVibeId: String!
    $recipientIds: [String!]!
    $fact: String!
    $feeling: String!
  ) {
    saveGoodVibeDraft(
      goodVibeId: $goodVibeId
      recipientIds: $recipientIds
      fact: $fact
      feeling: $feeling
    ) {
      id
      recipients {
        id
        firstName
        lastName
        email
      }
      creator {
        id
        firstName
        lastName
        email
      }
      sentAt
      fact
      feeling
    }
  }
`

export const DELETE_GOOD_VIBE = gql`
  mutation($goodVibeId: String!) {
    deleteGoodVibe(goodVibeId: $goodVibeId) {
      id
      recipients {
        id
        firstName
        lastName
        email
      }
      creator {
        id
        firstName
        lastName
        email
      }
      sentAt
      fact
      feeling
    }
  }
`

export const SUBMIT_GOOD_VIBE_COMMENT = gql`
  mutation($goodVibeId: String!, $value: String!) {
    submitGoodVibeComment(goodVibeId: $goodVibeId, value: $value) {
      id
      author {
        id
        firstName
        lastName
        email
      }
      value
      submittedAt
      editedAt
    }
  }
`

export const EDIT_GOOD_VIBE_COMMENT = gql`
  mutation($commentId: String!, $value: String!) {
    editGoodVibeComment(commentId: $commentId, value: $value) {
      id
      author {
        id
        firstName
        lastName
        email
      }
      value
      submittedAt
      editedAt
    }
  }
`

export const DELETE_GOOD_VIBE_COMMENT = gql`
  mutation($commentId: String!) {
    deleteGoodVibeComment(commentId: $commentId)
  }
`

export const ADD_GOOD_VIBE_REACTION = gql`
  mutation($goodVibeId: String!, $reaction: String!) {
    addGoodVibeReaction(goodVibeId: $goodVibeId, reaction: $reaction) {
      id
      author {
        id
        firstName
        lastName
        email
      }
      reaction
      submittedAt
    }
  }
`

export const DELETE_GOOD_VIBE_REACTION = gql`
  mutation($reactionId: String!) {
    deleteGoodVibeReaction(reactionId: $reactionId) {
      id
    }
  }
`

export const REPORT_GOOD_VIBE_ABUSE = gql`
  mutation($goodVibeId: String!, $abuse: String!) {
    reportGoodVibeAbuse(goodVibeId: $goodVibeId, abuse: $abuse)
  }
`

export const REPORT_GOOD_VIBE_COMMENT_ABUSE = gql`
  mutation($commentId: String!, $abuse: String!) {
    reportGoodVibeCommentAbuse(commentId: $commentId, abuse: $abuse)
  }
`

export const SEE_GOOD_VIBE = gql`
  mutation seenGoodVibes($goodVibeIds: [String!]!) {
    seenGoodVibes(goodVibeIds: $goodVibeIds)
  }
`
