import { Card, Col, Form, Input, Row, Typography } from 'antd'

import PasswordStrengthInput from '../_components/Form/PasswordStrengthInput'
import React from 'react'

const { Text } = Typography

export default ({ form }) => {
  const { getFieldValue, getFieldDecorator, setFields } = form

  const validatePasswords = (rule, value, callback) => {
    const currentPassword = getFieldValue('currentPassword')
    const newPassword = getFieldValue('newPassword')
    const newPasswordValue = newPassword ? newPassword.value : ''
    const confirmPassword = getFieldValue('confirmPassword')

    let hasErrors = false

    if (!currentPassword && !newPasswordValue && !confirmPassword) {
      return true
    }

    if (currentPassword && !newPasswordValue && !newPasswordValue) {
      return true
    }

    if (
      (currentPassword && (!newPasswordValue || !confirmPassword)) ||
      (newPasswordValue && (!currentPassword || !confirmPassword)) ||
      (confirmPassword && (!currentPassword || !newPasswordValue))
    ) {
      hasErrors = true
    } else if (currentPassword === newPasswordValue) {
      hasErrors = true
    } else if (newPasswordValue !== confirmPassword) {
      hasErrors = true
    } else if (newPassword.strength === 'weak') {
      hasErrors = true
    }

    if (hasErrors) {
      callback(true)

      setFields({
        currentPassword: {
          value: currentPassword,
          error: [new Error(true)],
        },
        newPassword: {
          value: newPassword,
          error: [new Error(true)],
        },
        confirmPassword: {
          value: confirmPassword,
          error: [new Error(true)],
        },
      })
    }

    return true
  }

  return (
    <Card
      title={<Text>Your privacy settings</Text>}
      style={{ marginTop: 16, marginBottom: '40px' }}
    >
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item label="Current password" validateStatus={''} help={''}>
            {getFieldDecorator('currentPassword', {
              rules: [
                {
                  validator: validatePasswords,
                },
              ],
            })(<Input.Password placeholder="Enter current password" />)}
          </Form.Item>
          <Form.Item label="New password" validateStatus={''} help={''}>
            {getFieldDecorator('newPassword', {
              rules: [
                {
                  validator: validatePasswords,
                },
              ],
            })(<PasswordStrengthInput placeholder="Enter new password" />)}
          </Form.Item>
          <Form.Item label="Confirm new password" validateStatus={''} help={''}>
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  validator: validatePasswords,
                },
              ],
            })(<Input.Password placeholder="Confirm new password" />)}
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
