import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import AvatarPlusList from '../../../../_components/AvatarPlusList'
import FeedbackReceivedTitle from '../FeedbackReceivedTitle'

const CONTAINER_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const LABEL_STYLE = {
  color: '#1c1047',
  fontFamily: 'museo',
  fontWeight: 300,
}

export default ({ feedbacks, reviewId }) => {
  return (
    <div style={CONTAINER_STYLE}>
      <div>
        <FeedbackReceivedTitle />
        <AvatarPlusList users={feedbacks.map(x => x.askedTo)} limit={6} />
        <div>
          <div style={LABEL_STYLE}>
            {feedbacks.length + ' '}{' '}
            {feedbacks.length > 1 ? 'people' : 'person'} gave you feedback
          </div>
        </div>
      </div>

      <Link to={'/team360/viewFeedback/' + reviewId}>
        <Button type="light-purple">View feedback</Button>
      </Link>
    </div>
  )
}
