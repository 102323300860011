import gql from 'graphql-tag'

export const GET_TEAM = gql`
  query team($id: String!) {
    team(id: $id) {
      id
      name
      readonlyProperties
      members {
        id
        user {
          id
          firstName
          lastName
          displayName
          email
          status
          jobTitle
          avatarUrl
          readonlyProperties
        }
      }
    }
  }
`

export const EDIT_TEAM = gql`
  mutation editTeam(
    $teamId: String!
    $teamName: String!
    $teamMembers: [String!]!
  ) {
    editTeamName(teamId: $teamId, teamName: $teamName) {
      id
      name
    }
    editTeamMembers(teamId: $teamId, memberIds: $teamMembers) {
      id
      members {
        id
        user {
          id
        }
      }
    }
  }
`
