import React, { useCallback } from 'react'

import ListGroup from './ListGroup'
import ListHeader from './ListHeader'
import ListUser from './ListUser'
import Skeleton from './Skeleton'

const ListItem = ({
  item,
  index,
  style,
  selected,
  disabled,
  enableSelect = true,
  onSelected,
  onDeselected,
  tooltip,
}) => {
  const handleOnSelected = useCallback(() => onSelected(item), [])
  const handleOnDeselected = useCallback(() => onDeselected(item), [])

  if (!item) {
    return <Skeleton key={`skeleton_${index}`} style={style} />
  }
  if (item.selectAll) {
    return (
      <ListHeader
        selected={selected}
        onSelected={handleOnSelected}
        onDeselected={handleOnDeselected}
        data-cy="select-all"
        style={style}
      />
    )
  }
  if (item.team) {
    return (
      <ListGroup
        title={item.team.name}
        selected={selected}
        enableSelect={enableSelect}
        data-cy="team"
        height={56}
        onSelected={handleOnSelected}
        onDeselected={handleOnDeselected}
        selectedText={item.key === 'no-team' ? 'Select people' : 'Select team'}
        deselectedText={
          item.key === 'no-team' ? 'Deselect people' : 'Deselect team'
        }
        style={style}
      />
    )
  }
  if (item.user) {
    return (
      <ListUser
        user={item.user}
        selected={selected}
        disabled={disabled}
        managersMandatory={false}
        onSelected={handleOnSelected}
        onDeselected={handleOnDeselected}
        data-cy="colleague"
        tooltip={tooltip}
        height={56}
        style={style}
      />
    )
  }
  return null
}

export default ListItem
