import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'

import Card from '../../Card'
import Section from '../../Section'
import { COMPANY_AMA } from './queries'

export default ({ timeInterval, isActive }) => {
  const [loading, setLoading] = useState(true)

  const [numbers, setNumbers] = useState({})

  const { data } = useQuery(COMPANY_AMA, {
    fetchPolicy: 'cache-first',
    variables: {
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    },
    skip: !isActive,
  })

  useEffect(() => {
    if (data) {
      setNumbers({
        amaAsked: data.companyAskMeAnythingInsight.find(
          x => x.key === 'ama_org_asked',
        ).value,
        publicAMAAnswered: data.companyAskMeAnythingInsight.find(
          x => x.key === 'public_ama_org_answered',
        ).value,
        privateAMAAnswered: data.companyAskMeAnythingInsight.find(
          x => x.key === 'private_ama_org_answered',
        ).value,
      })
      setLoading(false)
    }
  }, [data])

  return (
    <Section title="Company stats">
      <div className="insights__card-container">
        <Card
          number={numbers.amaAsked}
          unit="anonymous questions"
          tooltip="Number of AMAs sent across the company"
          loading={loading}
        />
        <Card
          number={numbers.publicAMAAnswered}
          unit="public answers"
          tooltip="Number of AMAs which everyone can see the answer to"
          loading={loading}
        />
        <Card
          number={numbers.privateAMAAnswered}
          unit="private answers"
          tooltip="Number of AMAs that only the person who asked can see the answer to"
          loading={loading}
        />
      </div>
    </Section>
  )
}
