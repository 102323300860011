import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ExternalLink = ({ href, icon, children, ...props }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
      style={{ marginLeft: 12, fontSize: 14, ...props.style }}
    >
      <span>{children}</span>
      {icon ? (
        icon
      ) : (
        <FontAwesomeIcon
          icon={['fal', 'external-link']}
          style={{ marginLeft: 4, fontSize: 12 }}
        />
      )}
    </a>
  )
}

export default ExternalLink
