import './style.less'

import Counter from './Counter'
import Form from './Form'
import Input from './Input'
import Item from './Item'
import List from './List'

const Comment = () => null

Comment.List = List
Comment.Item = Item
Comment.Input = Input
Comment.Form = Form
Comment.Counter = Counter

export default Comment
