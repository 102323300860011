// import React from 'react'
import AskMeAnythingRoutes from './AskMeAnythingRoutes'
import { LandingPage } from './pages'

export const AMARoutes = {
  path: '/askMeAnything',
  component: AskMeAnythingRoutes,
  routes: [
    {
      path: '/askMeAnything/inbox',
      component: LandingPage,
      menuKey: 'inbox',
    },
    {
      path: '/askMeAnything/privatearea',
      component: LandingPage,
      menuKey: 'privateArea',
    },
    {
      path: '/askMeAnything/publicwall',
      component: LandingPage,
      menuKey: 'publicWall',
    },
  ],
}
