import React, { useState } from 'react'

import { Dropdown } from 'antd'
import MoreActionsButton from '../../../_components/MoreActionsButton'
import TeamMemberMenu from './TeamMemberMenu'
import { useImperativeHandle } from 'react'

export default React.forwardRef(({ user, teams, team }, ref) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleVisibleChange = visible => {
    visible ? showDropdown() : hideDropdown()
  }

  const showDropdown = () => {
    setDropdownVisible(true)
  }

  const hideDropdown = () => {
    setDropdownVisible(false)
  }

  useImperativeHandle(ref, () => {
    return {
      open: showDropdown.bind(this),
      close: hideDropdown.bind(this),
      isOpen: dropdownVisible,
    }
  })

  return (
    <Dropdown
      className="dropdown-menu"
      overlay={
        <TeamMemberMenu
          user={user}
          team={team}
          teams={teams}
          onClick={hideDropdown}
        />
      }
      visible={dropdownVisible}
      onVisibleChange={handleVisibleChange}
      ref={ref}
    >
      <MoreActionsButton onClick={e => e.stopPropagation()} />
    </Dropdown>
  )
})
