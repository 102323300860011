import {
  Personal360CreateEditRoutes,
  Personal360Routes,
  PulseCreateEditRoutes,
  PulseRoutes,
  Team360CreateEditRoutes,
  Team360Routes,
} from './routes'

export const CYCLE_TYPES = {
  personal: 'personal',
  threeSixty: '360',
  pulse: 'pulse',
}

export const getCycleBaseUrl = type => {
  switch (type) {
    case CYCLE_TYPES.personal:
      return '/personal360'

    case CYCLE_TYPES.threeSixty:
      return '/team360'

    case CYCLE_TYPES.pulse:
      return '/pulse'

    default:
      break
  }
}

export const getCycleTypeFromUrl = location => {
  const path = location.pathname.split('/')[1]

  switch (path) {
    case 'personal360':
      return CYCLE_TYPES.personal

    case 'team360':
      return CYCLE_TYPES.threeSixty

    case 'pulse':
      return CYCLE_TYPES.pulse

    default:
      break
  }
}

export const getRoutes = cycleType => {
  switch (cycleType) {
    case CYCLE_TYPES.personal:
      return Personal360Routes

    case CYCLE_TYPES.threeSixty:
      return Team360Routes

    case CYCLE_TYPES.pulse:
      return PulseRoutes

    default:
      break
  }
}

export const getCreateEditRoutes = cycleType => {
  switch (cycleType) {
    case CYCLE_TYPES.personal:
      return Personal360CreateEditRoutes

    case CYCLE_TYPES.threeSixty:
      return Team360CreateEditRoutes

    case CYCLE_TYPES.pulse:
      return PulseCreateEditRoutes

    default:
      break
  }
}

export const findEnrolledManagees = ({ cycle, user }) => {
  const users = [...user.directReports, ...user.viewingManagerOf]

  const enrolledManagees = cycle.enrolled.filter(x =>
    users.find(y => y.id === x.id),
  )

  return enrolledManagees.length > 0 ? enrolledManagees : null
}

export const formatSelection = selection => {
  const users = selection.users || {}
  const teams = selection.teams || {}
  return {
    userIds: Object.keys(users),
    teamIds: Object.keys(teams).filter(teamId => teamId !== 'no-team'),
    excludeUserIds: Object.keys(teams).reduce((acc, teamId) => {
      return [...acc, ...teams[teamId].excludedUsers.map(user => user.id)]
    }, selection.excludeUserIds || []),
    includeNoTeam: !!Object.keys(teams).find(teamId => teamId === 'no-team'),
  }
}
