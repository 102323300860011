import React from 'react'

import AnswerAnonymousQuestionTodo from './AnswerAnonymousQuestionTodo'
import AskAnonymousQuestionTodo from './AskAnonymousQuestionTodo'
import GiveFeedbackTodo from './GiveFeedbackTodo'
import InstallSlackTodo from './InstallSlackTodo'
import InvitePeopleTodo from './InvitePeopleTodo'
import ReceiveGoodVibeTodo from './ReceiveGoodVibeTodo'
import RequestFeedbackTodo from './RequestFeedbackTodo'
import RequestPrivateFeedbackTodo from './RequestPrivateFeedbackTodo'
import SendGoodVibeTodo from './SendGoodVibeTodo'
import ViewExampleFeedbackTodo from './ViewExampleFeedbackTodo'
import ViewFeedbackTodo from './ViewFeedbackTodo'

export default ({ todo }) => {
  switch (todo.type) {
    case 'GiveFeedback':
      return (
        <GiveFeedbackTodo
          feedbackId={todo.target.feedback.id}
          requester={todo.assignedBy}
          cycleName={todo.target.cycle.name}
          cycleType={todo.target.cycle.type}
        />
      )

    case 'RequestFeedback':
      return (
        <RequestFeedbackTodo
          cycleId={todo.target.cycle.id}
          minRequests={todo.target.cycle.minRequests}
          reviewId={todo.target.review.id}
        />
      )

    case 'InvitePeople':
      return <InvitePeopleTodo />

    case 'RequestPrivateFeedback':
      return <RequestPrivateFeedbackTodo assignedTo={todo.assignedTo[0]} />

    case 'ViewFeedbackTodo':
      return (
        <ViewFeedbackTodo
          reviewId={todo.target.review.id}
          cycleName={todo.target.review.cycle.name}
          cycleType={todo.target.review.cycle.type}
          assignedTo={todo.assignedTo[0]}
        />
      )

    case 'ViewExampleFeedbackTodo':
      return (
        <ViewExampleFeedbackTodo
          id={todo.id}
          review={todo.target.review}
          assignedBy={todo.assignedBy}
          assignedTo={todo.assignedTo[0]}
        />
      )

    case 'InstallSlack':
      return <InstallSlackTodo id={todo.id} assignedTo={todo.assignedTo} />
    case 'AskAnonymousQuestion':
      return <AskAnonymousQuestionTodo id={todo.id} />

    case 'AnswerAnonymousQuestion':
      return <AnswerAnonymousQuestionTodo id={todo.target.ama.id} />

    case 'SendGoodVibe':
      return <SendGoodVibeTodo id={todo.id} assignedTo={todo.assignedTo} />

    case 'ReceiveGoodVibe':
      return (
        <ReceiveGoodVibeTodo
          id={todo.id}
          assignedTo={todo.assignedTo}
          assignedBy={todo.assignedBy}
          goodVibeId={todo.target.goodVibe.id}
        />
      )

    default:
      return null
  }
}
