import gql from 'graphql-tag'

export const EVENTS_THREE_SIXTY = gql`
  query eventsThreeSixtyInsight(
    $startDate: String
    $endDate: String
    $offset: Int
    $limit: Int
  ) {
    eventsThreeSixtyInsight(
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      limit: $limit
    ) {
      id
      items {
        id
        cycle {
          id
          name
          startDate
          endDate
        }
        stats {
          id
          key
          value
        }
      }
      total
    }
  }
`
