import './style.less'

import { Input } from 'antd'
import React from 'react'

const { TextArea } = Input

const FormTextarea = React.forwardRef(
  ({ value, placeholder, rows, onChange, ...props }, ref) => {
    const handleChange = e => {
      const value = e.target.value
      onChange(value)
    }

    return (
      <div className="haig-textarea-container form-question form-question--text">
        <TextArea
          {...props}
          className="haig-textarea"
          style={props.style}
          rows={rows ? rows : 3}
          placeholder={placeholder}
          onChange={handleChange}
          ref={ref}
          value={value}
        />
      </div>
    )
  },
)

export default FormTextarea
