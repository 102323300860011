import { useMutation } from '@apollo/react-hooks'
import { Button, Modal, notification } from 'antd'
import React, { useContext, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import AutosaveIndicator from '../../../_components/AutosaveIndicator'
import SuccessModalContext from '../../../_components/SuccessModal/context'
import { Answer } from '../../components'
import { ANSWER_QUESTION, DECLINE_QUESTION, TOTAL_AMAS_COUNT } from './queries'
import useDraft from './useDraft'
import { getWallNotifications } from '../../utils'

const RespondToQuestion = ({ id, recipient, question, answer, draft }) => {
  const formRef = useRef(null)
  const lastChangedFieldRef = useRef(null)

  const successModal = useContext(SuccessModalContext)

  const [disabledForm, setDisabledForm] = useState(false)
  const [savingAnswer, setSavingAnswer] = useState(false)
  const [declining, setDeclining] = useState(false)
  const [declineModalVisible, setDeclineModalVisible] = useState(false)
  const [autosaveVisible, setAutosaveVisible] = useState()
  const autosaveTimeoutId = useRef(null)

  const [answerQuestion] = useMutation(ANSWER_QUESTION, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })
  const [declineQuestion] = useMutation(DECLINE_QUESTION, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })
  const [totalAmasCount] = useMutation(TOTAL_AMAS_COUNT, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  });

  const loadedDraft = {
    lastSyncedAt: draft ? draft.lastSyncedAt : undefined,
    syncedValue: draft
      ? { answer: draft.answer, visibility: draft.visibility }
      : undefined,
  }

  const {
    data: draftData,
    loading: loadingDraft,
    error: draftError,
    save: saveDraft,
  } = useDraft(id, loadedDraft, {
    onSaved: () => {
      if (lastChangedFieldRef.current === 'answer') {
        showAndDelayHideAutosave(3000)
      }
    },
  })

  const showDeclineModal = () => {
    setDeclineModalVisible(true)
  }

  const hideDeclineModal = () => {
    setDeclineModalVisible(false)
  }

  const onDecline = async () => {
    try {
      setDisabledForm(true)
      setDeclining(true)
      await declineQuestion({
        variables: {
          amaId: id,
        },
      })
      setDisabledForm(false)
      setDeclining(false)
      notification.success({
        message: 'Gotcha!',
        description: `The question has been declined`,
      })
    } catch (e) {
      setDisabledForm(false)
      setDeclining(false)
      notification.error({
        message: 'Oops! Something went wrong...',
        description: 'Please try again',
      })
    }
  }

  const onAnswerClick = async () => {
    setDisabledForm(true)
    setSavingAnswer(true)

    formRef.current.validateFields(async (errors, values) => {
      try {
        if (errors) {
          setDisabledForm(false)
          setSavingAnswer(false)
          return
        }
        await answerQuestion({
          variables: {
            amaId: id,
            answer: values.answer,
            visibility: values.visibility,
          },
        });
        const response = await totalAmasCount();
        const inboxQuestions = parseInt(localStorage.getItem('inbox'));
        // this is the last answered question
        if (inboxQuestions === 1) {
          document.getElementById("inboxWall").style.display = "none";
          getWallNotifications(response);
        }
        setDisabledForm(false)
        setSavingAnswer(false)
        successModal.show({
          message: `Answer sent!`,
        })
      } catch (e) {
        setDisabledForm(false)
        setSavingAnswer(false)
        notification.error({
          message: 'Oops! Something went wrong...',
          description: 'Please try again',
          duration: 0,
        })
      }
    })
  }

  const [handleFormChange] = useDebouncedCallback(
    async ({ values, lastChangedField }) => {
      lastChangedFieldRef.current = lastChangedField
      await saveDraft({
        answer: values.answer ? values.answer : '',
        visibility: values.visibility,
      })
    },
    200,
  )

  const showAndDelayHideAutosave = waitMs => {
    setAutosaveVisible(true)
    if (autosaveTimeoutId.current) {
      clearTimeout(autosaveTimeoutId.current)
      autosaveTimeoutId.current = null
    }
    autosaveTimeoutId.current = setTimeout(() => {
      setAutosaveVisible(false)
    }, waitMs)
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Answer.Form
          ref={formRef}
          recipient={recipient}
          question={question}
          answer={answer}
          defaultValues={
            draftData ? draftData : { visibility: 'public', answer: '' }
          }
          onChange={handleFormChange}
          disabled={loadingDraft}
        />
        {
          <AutosaveIndicator
            visible={autosaveVisible || draftError}
            text={draftError ? draftError : 'Saved'}
            theme={draftError ? 'error' : 'success'}
            style={{ position: 'absolute', bottom: 0 }}
          />
        }
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '32px',
        }}
      >
        <Button
          type="ghost"
          onClick={showDeclineModal}
          loading={declining}
          disabled={disabledForm}
        >
          Decline question
        </Button>
        <Button
          type="accent"
          onClick={onAnswerClick}
          style={{ marginLeft: '10px' }}
          loading={savingAnswer}
          disabled={disabledForm}
        >
          Answer
        </Button>
      </div>
      <Modal
        visible={declineModalVisible}
        destroyOnClose={true}
        title="Decline question"
        cancelButtonProps={{ type: 'ghost' }}
        okText="Decline"
        onOk={onDecline}
        onCancel={hideDeclineModal}
        centered
      >
        Are you sure you don't want to answer this question? We'll notify the
        person who asked it if you choose to decline.
      </Modal>
    </>
  )
}

export default RespondToQuestion
