import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import isEqual from 'lodash/isEqual'
import React, { useContext, useEffect, useState } from 'react'

import Loader from '../../../_components/Loader'
import { track } from '../../../_helpers/analytics'
import useSeeReview from '../../../hooks/graphql/Reviews/useSeeReview'
import { UserContext } from '../../../UserContext'
import SentimentAnalysisBanner from '../../components/SentimentAnalysisBanner'
import FeedbackAnswers from './FeedbackAnswers/FeedbackAnswers'
import FeedbackBanner from './FeedbackBanner'
import { GET_FEEDBACK_RESPONSES } from './queries'
import Title from './Title'

export default ({ match, history }) => {
  const user = useContext(UserContext)

  const reviewId = match.params.reviewId
  const [teamIds, setTeamsIds] = useState([])
  const [loading, setLoading] = useState(true)

  const [queryVariables, setQueryVariables] = useState({
    reviewId,
    teamIds: [],
  })

  const { data, error } = useQuery(GET_FEEDBACK_RESPONSES, {
    fetchPolicy: 'no-cache',
    variables: queryVariables,
    onCompleted: () => {
      setLoading(false)
    },
  })

  const handleTeamChange = value => {
    let arr = []

    if (value === 'all-teams') {
      if (isEqual(arr, teamIds)) {
        return
      }
      setTeamsIds(arr)
      setLoading(true)
      setQueryVariables({
        ...queryVariables,
        teamIds: arr.map(x => x),
      })
    } else {
      arr = teamIds
      if (!arr.includes(value)) {
        //checking weather array contain the id
        arr.push(value) //adding to array because value doesnt exists
      } else {
        arr.splice(arr.indexOf(value), 1) //deleting
      }
      setTeamsIds(arr)
      setLoading(true)
      setQueryVariables({
        ...queryVariables,
        teamIds: arr.map(x => x),
      })
    }
  }

  const [hasSentimentAnalysisQuestions, setHasSentimentAnalysisQuestions] =
    useState(false)

  useSeeReview(!!data, reviewId, 3000)

  useEffect(() => {
    if (data && data.finishedReview && data.finishedReview.totalFeedbacks) {
      if (data.finishedReview.totalFeedbacks < 2) {
        history.push('/')
      } else {
        let cycleType = ''
        switch (data.finishedReview.cycle.type) {
          case 'THREESIXTY':
            cycleType = 'team360'
            break
          case 'PERSONAL':
            cycleType = 'personal360'
            break
          case 'PROBATION':
            cycleType = 'probation'
            break
          case 'PULSE':
            cycleType = 'pulse'
            break
          default:
            cycleType = 'team360'
        }
        // At this point, we know the feedback is correct to be seen
        track(cycleType + '.feedback.view', {
          user: user.email,
          org: user.org.name,
          reviewId,
        })
        if (!!data.finishedReview.questions.find(x => x.sentimentAnalysis)) {
          setHasSentimentAnalysisQuestions(true)
        }
      }
    }
  }, [data])

  if (loading)
    return (
      <Loader />
    )
  if (error) return <p>Error!</p>

  return (
    <div className="feedback-response">
      <div className="top-title">
        <Card bodyStyle={{ padding: '20px 32px 26px' }}>
          <Title
            teamsArray={teamIds}
            onTeamChange={handleTeamChange}
            preTitle={
              data.finishedReview.cycle.type !== 'PULSE' &&
              'Feedback you received'
            }
            cycle={data.finishedReview.cycle}
            review={data.finishedReview}
          />
        </Card>
      </div>
      <FeedbackBanner cycleType={data.finishedReview.cycle.type} />
      {hasSentimentAnalysisQuestions && (
        <SentimentAnalysisBanner
          target="question"
          style={{ marginTop: '24px' }}
        />
      )}
      <FeedbackAnswers teamsArray={teamIds} review={data.finishedReview} />
    </div>
  )
}
