import { Button } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { UserContext } from '../../UserContext'
import AddPeopleBySlackForm from '../AddPeopleBySlackForm'

const SlackBody = ({ error, status, onSuccess, onCancel }) => {
  const currentUser = useContext(UserContext)

  if (error) {
    return (
      <div style={{ padding: '0 32px' }}>
        Oops! There was an issue with finding your Slack colleagues, please
        email us at{' '}
        <a href="mailto:support@howamigoing.com">support@howamigoing.com</a>.
      </div>
    )
  }

  return (
    <div
      style={{
        padding: `0 ${status !== 'active' ? '32px' : 0}`,
        fontWeight: 300,
      }}
    >
      {status !== 'active' ? (
        <>
          {currentUser.isAdmin() || currentUser.isOwner()
            ? `Activate our Slack integration so the members of your Slack workspace appear here. Then you can invite them to Howamigoing with one click.`
            : `Ask your Howamigoing Admin to activate our Slack integration so the members of your Slack workspace appear here. Then you can invite them with one click.`}

          <div
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              marginTop: '32px',
            }}
          >
            <Button
              type="ghost"
              style={{ marginRight: '10px' }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            {currentUser.isAdmin() && (
              <Link to="/admin/integrations/slack">
                <Button type="accent">Activate Slack integration</Button>
              </Link>
            )}
          </div>
        </>
      ) : (
        <AddPeopleBySlackForm
          height={500}
          cancelLabel="Cancel"
          onSubmit={onSuccess}
          onCancel={onCancel}
          buttonsRightAligned
        />
      )}
    </div>
  )
}

export default SlackBody
