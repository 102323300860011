import { Card } from 'antd'
import { groupBy } from 'lodash'
import moment from 'moment'
import React from 'react'

import Clock from '../../_assets/img/dashboard/clock.svg'
import useGetTodosGQL from '../../hooks/graphql/Todos/useGetTodosGQL'
import Section from '../Section'
import EmptyState from './EmptyState'
import TodoGroup from './TodoGroup'

const ClockIcon = () => <img src={Clock} alt="Clock" />

export default () => {
  const isExampleFeedback = todo => todo.type === 'ViewExampleFeedbackTodo'
  const isInvitePeopleTodo = todo => todo.type === 'InvitePeople'
  const isRequestPrivateFeedbackTodo = todo =>
    todo.type === 'RequestPrivateFeedback'
  const isViewFeedbackTodo = todo => todo.type === 'ViewFeedbackTodo'
  const isInstallSlackTodo = todo => todo.type === 'InstallSlack'
  const isAskAnonymousQuestionTodo = todo =>
    todo.type === 'AskAnonymousQuestion'
  const isAnswerAnonymousQuestionTodo = todo =>
    todo.type === 'AnswerAnonymousQuestion'
  const isSendGoodVibeTodo = todo => todo.type === 'SendGoodVibe'
  const isReceiveGoodVibeTodo = todo => todo.type === 'ReceiveGoodVibe'
  const hasDueDate = todo => !!todo.dueBy

  const { data, loading, error } = useGetTodosGQL({
    fetchPolicy: 'no-cache',
    pollInterval: 30000,
  })

  if (loading) {
    return <span>Loading...</span>
  }

  if (error) {
    return <span>There was an error loading your to-do list.</span>
  }

  const todosWithDueDate = data.todos.filter(hasDueDate).map(todo => {
    return {
      ...todo,
      dueBy: moment.unix(todo.dueBy),
    }
  })
  const todosByDueDate = groupBy(todosWithDueDate, x => x.dueBy.unix())
  const todosByHour = Object.keys(todosByDueDate)
    .sort((a, b) => (a < b ? -1 : 1))
    .map(dueBy => {
      return {
        todos: todosByDueDate[dueBy],
        dueBy: moment.unix(dueBy),
      }
    })

  const todosCurrentUserFeedback = data.todos.filter(isViewFeedbackTodo)
  const todosDueAsap = data.todos.filter(
    x => isAnswerAnonymousQuestionTodo(x) || isReceiveGoodVibeTodo(x),
  )

  const newAccountTodos = []

  const invitePeopleTodo = data.todos.find(isInvitePeopleTodo)
  if (invitePeopleTodo) {
    newAccountTodos.push(invitePeopleTodo)
  }
  const requestPrivateFeedbackTodo = data.todos.find(
    isRequestPrivateFeedbackTodo,
  )
  if (requestPrivateFeedbackTodo) {
    newAccountTodos.push(requestPrivateFeedbackTodo)
  }
  const exampleFeedbackTodo = data.todos.find(isExampleFeedback)
  if (exampleFeedbackTodo) {
    newAccountTodos.push(exampleFeedbackTodo)
  }
  const installSlackTodo = data.todos.find(isInstallSlackTodo)
  if (installSlackTodo) {
    newAccountTodos.push(installSlackTodo)
  }
  const sendGoodVibeTodo = data.todos.find(isSendGoodVibeTodo)
  if (sendGoodVibeTodo) {
    newAccountTodos.push(sendGoodVibeTodo)
  }
  const askAnonymousQuestionTodo = data.todos.find(isAskAnonymousQuestionTodo)
  if (askAnonymousQuestionTodo) {
    newAccountTodos.push(askAnonymousQuestionTodo)
  }

  return (
    <Section title={`On your to-do list`}>
      {data.todos && data.todos.length === 0 ? (
        <EmptyState />
      ) : (
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          <div>
            {todosDueAsap.length > 0 && (
              <TodoGroup
                title="Due asap"
                icon={<ClockIcon />}
                todos={todosDueAsap}
                key={'due-asap'}
              />
            )}
            {todosCurrentUserFeedback.length > 0 && (
              <TodoGroup
                title="View your feedback"
                icon={<ClockIcon />}
                todos={todosCurrentUserFeedback}
                key={'view-your-feedback'}
              />
            )}
            {todosByHour.map(group => (
              <TodoGroup
                title={group.dueBy.format('[Due] MMM Do, HH:mm')}
                icon={<ClockIcon />}
                todos={group.todos}
                key={group.dueBy.unix()}
              />
            ))}
            {newAccountTodos.length > 0 && (
              <TodoGroup
                title="Getting started"
                icon={<ClockIcon />}
                todos={newAccountTodos}
                key={'account-setup'}
              />
            )}
          </div>
        </Card>
      )}
    </Section>
  )
}
