import React from 'react'

import Avatar from '../../../_components/Avatar'

const AnonymousAvatar = () => {
  return (
    <Avatar
      user={{
        avatarUrl:
          'https://storage.googleapis.com/howamigoing-assets/app/ama-puppy.png',
      }}
      disableTooltip={true}
      fallbackText="?"
    ></Avatar>
  )
}

export default AnonymousAvatar
