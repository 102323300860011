import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const InputRadio = ({ icon, label, hint, selected, ...props }) => {
  return (
    <div
      {...props}
      className={
        'visibility-input-radio' +
        (selected ? ' visibility-input-radio--selected' : '')
      }
    >
      <div className="visibility-input-radio__icon">{icon}</div>
      <div className="visibility-input-radio__text">
        <div className="visibility-input-radio__label">{label}</div>
        <div className="visibility-input-radio__hint">{hint}</div>
      </div>
      <div className="visibility-input-radio__check">
        {selected ? <FontAwesomeIcon icon={['fas', 'check-circle']} /> : null}
      </div>
    </div>
  )
}

export default InputRadio
