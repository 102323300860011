import gql from 'graphql-tag'

export const ADD_PEOPLE = gql`
  mutation addPeople($emails: [String!]!, $invite: Boolean) {
    addPeople(emails: $emails, invite: $invite)
  }
`

export const GET_COLLEAGUES = gql`
  query addPeopleByEmailUsers($offset: Int, $limit: Int) {
    users(offset: $offset, limit: $limit) {
      total
    }
  }
`

export const GET_COMPANY_USERS = gql`
  query {
    org {
      users {
        id
        status
        firstName
        lastName
        email
        jobTitle
        avatarUrl
        userRoles {
          id
          role {
            id
            name
            type
          }
        }
        orgRelationsFrom {
          id
          type
          to {
            id
          }
        }
        orgRelationsTo {
          id
          type
          from {
            id
            firstName
            lastName
            email
          }
        }
        groupUsers {
          id
          group {
            id
            name
            type
          }
        }
      }
    }
  }
`
