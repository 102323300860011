import React from 'react'
import { useLocation } from 'react-router-dom'

import { RouteWithSubRoutes } from '../../../AppRoutes'
import { getCreateEditRoutes, getCycleTypeFromUrl } from '../helpers'
import PageTitle from './components/PageTitle'
import Steps from './components/Steps'
import CycleContextProvider from './Context/ContextProvider'
import CycleStepsContextProvider from './Context/StepsContextProvider'

const DEFAULT_SPACING = 8

export default props => {
  const cycleId = props.match.params.cycleId
  const location = useLocation()

  const cycleType = getCycleTypeFromUrl(location)
  const cycleRoutes = getCreateEditRoutes(cycleType)

  return (
    <div>
      <PageTitle cycleType={cycleType} />
      <CycleContextProvider
        cycleType={cycleType}
        cycleId={cycleId}
        formId={location.state && location.state.formId}
        fromWBYHT={location.state && location.state.fromWBYHT}
      >
        <CycleStepsContextProvider>
          <div className="new-cycle" style={{ marginTop: 40 }}>
            <Steps />
            <div style={{ marginTop: DEFAULT_SPACING * 4 }}>
              {cycleRoutes.routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}
            </div>
          </div>
        </CycleStepsContextProvider>
      </CycleContextProvider>
    </div>
  )
}
