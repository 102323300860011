import { Icon, Tooltip } from 'antd'

import React from 'react'

const Timestamp = ({ date, tooltipPrefix, tooltipSuffix, ...props }) => {
  const title = () => {
    const prefix = tooltipPrefix ? `${tooltipPrefix} ` : ''
    const suffix = tooltipSuffix ? ` ${tooltipSuffix}` : ''
    const dateTime = date.format('DD MMMM HH:mm')
    const timeZone = date.format('(zz Z)')
    return (
      <>
        {prefix}
        {dateTime}
        <br />
        {timeZone}
        {suffix}
      </>
    )
  }

  return (
    <span className="ama-timestamp__container" style={props.style}>
      <Icon style={{ marginRight: '8px' }} type="clock-circle" />
      <Tooltip title={title()} overlayClassName="tooltip--dark">
        {date.fromNow()}
      </Tooltip>
    </span>
  )
}

export default Timestamp
