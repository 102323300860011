import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import React, { useRef, useState } from 'react'

import InfoMessage from '../../../../_components/InfoMessage'
import Form from '../Form'

const VisibilityModal = ({
  visible,
  defaultValue = 'public',
  title = 'How would you like to answer?',
  okText = 'Save',
  cancelText = 'Cancel',
  onOk,
  onCancel,
}) => {
  const formRef = useRef(null)

  const [disabledForm, setDisabledForm] = useState(false)
  const [oking, setOking] = useState(false)

  const handleSubmit = () => {
    try {
      setDisabledForm(true)
      setOking(true)
      formRef.current.validateFields(async (errors, values) => {
        if (errors) {
          return
        }
        if (onOk) {
          await onOk(values.visibility)
        }
        setDisabledForm(false)
        setOking(false)
      })
    } catch (e) {
      setDisabledForm(false)
      setOking(false)
    }
  }

  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      onOk={handleSubmit}
      okButtonProps={{ loading: oking, disabled: disabledForm, type: 'accent' }}
      cancelText={cancelText}
      cancelButtonProps={{ type: 'ghost', disabled: disabledForm }}
      onCancel={onCancel}
      width={584}
      destroyOnClose={true}
      centered
    >
      <Form ref={formRef} defaultValues={{ visibility: defaultValue }} />
      <InfoMessage icon={<FontAwesomeIcon icon={['fas', 'sliders-h']} />}>
        Don't worry, you can change the visibility of your answer after posting
        it.
      </InfoMessage>
    </Modal>
  )
}

export default VisibilityModal
