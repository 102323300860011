import { Dropdown } from 'antd'
import React, { useState } from 'react'

import MoreActionsButton from '../../../_components/MoreActionsButton'
import TeamMenu from './TeamMenu'

export default ({ teamId, canDelete }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false)

  const handleVisibleChange = visible => {
    visible ? showDropdown() : hideDropdown()
  }

  const showDropdown = () => {
    setDropdownVisible(true)
  }

  const hideDropdown = () => {
    setDropdownVisible(false)
  }

  return (
    <Dropdown
      className="dropdown-menu"
      overlay={
        <TeamMenu
          teamId={teamId}
          canDelete={canDelete}
          onClick={hideDropdown}
        />
      }
      visible={dropdownVisible}
      onVisibleChange={handleVisibleChange}
    >
      <MoreActionsButton onClick={e => e.stopPropagation()} />
    </Dropdown>
  )
}
