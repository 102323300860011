import React from 'react'

const Counter = ({ count, ...props }) => {
  return (
    <div {...props} className="comment__counter">
      {count} comment{count !== 1 && 's'}
    </div>
  )
}

export default Counter
