import React from 'react'

export default ({ children, title }) => {
  return (
    <div className="insights__section">
      <div className="insights__title">{title}</div>
      {children}
    </div>
  )
}
