import React from 'react'

import { DEFAULT_SPACING } from '../CONSTANTS'
import SentimentBox from './SentimentBox'

export default ({ details, selected = 'positive', onClick }) => {
  if (!details) {
    return <></>
  }

  return (
    <div
      style={{
        display: 'flex',
        margin: '0 -16px',
        paddingTop: DEFAULT_SPACING * 2,
      }}
    >
      <SentimentBox
        type="positive"
        tooltip="Where the overall sentiment within the response is clearly positive."
        percentage={details.positive}
        selected={selected === 'positive'}
        onClick={() => onClick('positive')}
      />
      <SentimentBox
        type="neutral"
        tooltip="Where the response contains no emotion, or a mix of positive and negative sentiment."
        percentage={details.neutral}
        selected={selected === 'neutral'}
        onClick={() => onClick('neutral')}
      />
      <SentimentBox
        type="negative"
        tooltip="Where the overall sentiment within the response is clearly negative."
        percentage={details.negative}
        selected={selected === 'negative'}
        onClick={() => onClick('negative')}
      />
    </div>
  )
}
