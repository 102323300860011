import React, { useEffect, useState } from 'react'

import SuccessModal from './index'

const SuccessModalContext = React.createContext()

export const SuccessModalProvider = ({ children }) => {
  const [message, setMessage] = useState(null)
  const [visible, setVisibility] = useState(false)
  const [renderModal, setRenderModal] = useState(false)
  const [src, setSrc] = useState(null)
  const [confetti, setConfetti] = useState(true)
  const [button, setButton] = useState(null)

  useEffect(() => {
    if (renderModal) {
      return
    }
    const handler = async () => {
      const image = await loadImage(
        `https://europe-west2-haig-265311.cloudfunctions.net/gifs?id=random&category=success&t=${Date.now()}`,
      )
      setSrc(image.src)
    }
    handler()
  }, [renderModal])

  const api = {
    show: ({ message, button, confetti = true }) => {
      setMessage(message)
      setVisibility(true)
      setRenderModal(true)
      setConfetti(confetti)
      setButton(button)
      setTimeout(() => {
        setVisibility(false)
      }, 4000)
    },
    hide: () => {
      setMessage(null)
      setVisibility(false)
      setTimeout(() => {
        setRenderModal(false)
      }, 2000)
    },
  }

  const loadImage = src => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve(img)
      }
      img.onerror = reject
      img.src = src
    })
  }

  return (
    <>
      <SuccessModalContext.Provider value={api}>
        {children}
      </SuccessModalContext.Provider>
      {renderModal && (
        <SuccessModal
          visible={visible}
          message={message}
          src={src}
          button={button}
          onClose={api.hide}
          confetti={confetti}
        />
      )}
    </>
  )
}

export default SuccessModalContext
