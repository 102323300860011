import './style.less'

import React, { useContext } from 'react'

import AutocompleteUser from '../../../_components/Form/AutocompleteUser'
import { Form } from 'antd'
import TextAreaFormItem from '../../../_components/Form/TextAreaFormItem'
import { UserContext } from '../../../UserContext'

const AskQuestionForm = ({
  form,
  defaultValues = { recipient: undefined, question: '' },
}) => {
  const { getFieldDecorator } = form
  const currentUser = useContext(UserContext)

  const isCurrentUser = user => user.id === currentUser.id

  return (
    <Form
      layout="vertical"
      hideRequiredMark={true}
      colon={false}
      className="ask-question-form"
    >
      <Form.Item label="Who would you like to send this question to?">
        {getFieldDecorator('recipient', {
          initialValue: defaultValues.recipient
            ? defaultValues.recipient
            : undefined,
          rules: [
            {
              required: true,
              message: 'Hold up! You need to say something here...',
            },
          ],
        })(
          <AutocompleteUser
            placeholder="Search a colleague by name"
            exclusionRule={isCurrentUser}
          />,
        )}
      </Form.Item>
      <TextAreaFormItem
        max={280}
        min={2}
        required={true}
        form={form}
        name="question"
        label="Sooo, what's the question? Please keep it short, Twitter-ish short."
        placeholder="Type your question here"
      />
    </Form>
  )
}

export default Form.create({ name: 'AskQuestionForm' })(AskQuestionForm)
