import gql from 'graphql-tag'

export const GET_TEAMS_LIST = gql`
  query teams(
    $excludeUserIds: [String!]
    $teamUserTypes: [String!]
    $userTypes: [String!]
  ) {
    teams(
      search: { excludeUserIds: $excludeUserIds, userTypes: $teamUserTypes }
    ) {
      id
      name
      totalMembers
      readonlyProperties
    }
    noTeam: users(
      offset: 0
      limit: 0
      search: { hasTeam: false, types: $userTypes, excludeIds: $excludeUserIds }
    ) {
      total
    }
  }
`
