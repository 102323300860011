import React, { useContext } from 'react'

import { Card } from 'antd'
import EmptyState from '../../_assets/img/dashboard/empty-state.svg'
import { UserContext } from '../../UserContext'

export default () => {
  const user = useContext(UserContext)

  return (
    <Card
      bordered={false}
      bodyStyle={{
        alignItems: 'center',
        background: `url(${EmptyState}) no-repeat calc(100% - 32px) 100%`,
        color: '#281765',
        display: 'flex',
        height: '160px',
        padding: '32px',
      }}
    >
      <div>
        <div style={{ marginBottom: '2px', position: 'relative' }}>
          Nothing to see here, {user.firstName}.
        </div>
        <div
          style={{
            color: '#6b6b8f',
            fontSize: '14px',
            fontWeight: 300,
            maxWidth: '185px',
          }}
        >
          Why not learn something new about yourself?
          <br />
          Get Private feedback now!
        </div>
      </div>
    </Card>
  )
}
