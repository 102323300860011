export default user => {
  if (!user) {
    return ''
  }

  if (user.status === 'DEIDENTIFIED') {
    return 'Removed user'
  }

  if (user.firstName && user.lastName) {
    return user.firstName + ' ' + user.lastName
  }

  if (user.firstName && !user.lastName) {
    return user.firstName
  }

  if (!user.firstName && user.lastName) {
    return user.lastName
  }

  if (!user.firstName && !user.lastName) {
    return user.email
  }
}
