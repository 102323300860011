import { Icon } from 'antd'
import React from 'react'

const Loader = ({ text = 'Loading...', style = {} }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...style,
      }}
    >
      <Icon type="sync" spin style={{ display: 'block', marginBottom: text ? 8 : undefined }} />
      {text}
    </div>
  )
}

export default Loader