import React from 'react'

import PageTitle from '../../../_components/PageTitle'
import { CYCLE_TYPES } from '../helpers'

export default ({ cycleType, ...props }) => {
  switch (cycleType) {
    case CYCLE_TYPES.personal:
      return (
        <PageTitle
          subtitle="Request feedback on anything at any time and the responses stay private to you"
          subtitleLink="https://www.youtube.com/watch?v=YLKqMEbB29k&list=PL1QVVAGN1US193g-u-R5tTqGFO7CPOUt3&index=3&t=1s"
          {...props}
        >
          Private feedback
        </PageTitle>
      )

    case CYCLE_TYPES.threeSixty:
      return (
        <PageTitle
          subtitle="Feedback from everyone you work with that's visible to you and your manager"
          subtitleLink="https://www.youtube.com/watch?v=5hlhjC34tL0&list=PL1QVVAGN1US193g-u-R5tTqGFO7CPOUt3&index=4"
          {...props}
        >
          360 feedback
        </PageTitle>
      )

    case CYCLE_TYPES.pulse:
      return (
        <PageTitle
          subtitle="Short surveys that give fast, accurate insights into your team or business"
          subtitleLink="https://www.youtube.com/watch?v=mjgvN6JjuT8&list=PL1QVVAGN1US193g-u-R5tTqGFO7CPOUt3&index=5"
          {...props}
        >
          Pulse surveys
        </PageTitle>
      )

    default:
      return <></>
  }
}
