import gql from 'graphql-tag'

export const GET_TEAM = gql`
  query team($id: String!) {
    team(id: $id) {
      id
      name
      members {
        id
        user {
          id
          firstName
          lastName
          email
          status
          jobTitle
          avatarUrl
          readonlyProperties
        }
      }
    }
  }
`

export const GET_USERS_WITHOUT_A_TEAM = gql`
  query usersWithoutATeam {
    noTeam: users(offset: 0, limit: 0, search: { hasTeam: false }) {
      total
      users {
        id
        firstName
        lastName
        email
        status
        jobTitle
        avatarUrl
        readonlyProperties
      }
    }
  }
`
