import { Layout } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks'
import SideMenu from './_components/SideMenu/SideMenu'
import AppNotificationBar from './AppNotificationBar'
import useMobileOptimized from './hooks/device/useMobileOptimized'
import { UserContext } from './UserContext'
import { TOTAL_AMAS_COUNT } from './AskMeAnything/pages/LandingPage/queries'
import { setNotifications } from './AskMeAnything/utils';

const { Content } = Layout

const SIDE_MENU_WIDTH = 230

export default ({ children }) => {
  const [notificationBarVisibility, setNotificationBarVisibility] =
    useState(false)
  const [amasNotification, setAmasNotifications] = useState(false)
  const [notificationBarType, setNotificationBarType] = useState()

  const user = useContext(UserContext)

  const [totalAmasCount] = useMutation(TOTAL_AMAS_COUNT, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  });

  const haveNotifications = () => {
    const privateNotifications = JSON.parse(localStorage.getItem("private"))
    const publicNotifications = JSON.parse(localStorage.getItem("public"))
    const test = (
      (parseInt(localStorage.getItem("inbox")) > 0) ||
      (privateNotifications ? privateNotifications.showNotifications : false) ||
      (publicNotifications ? publicNotifications.showNotifications : false)
    )
    setAmasNotifications(test);
  }

  useEffect(() => {
    document.title = 'Howamigoing'
    totalAmasCount().then(response => {
      setNotifications(response);
      haveNotifications();
    });
  }, [])

  useEffect(() => {
    // we need to know when to show the bar in this component
    // because we want to style surrounding components properly
    if (user.emailStatus === 'BOUNCED') {
      setNotificationBarType('EMAIL_BOUNCED')
      setNotificationBarVisibility(true)
      return
    }
    if (user.emailStatus === 'PENDING_VERIFICATION') {
      setNotificationBarType('EMAIL_PENDING_VERIFICATION')
      setNotificationBarVisibility(true)
      return
    }
    if (window.location.href.indexOf('verify=1') > -1) {
      setNotificationBarType('EMAIL_VERIFIED')
      setNotificationBarVisibility(true)
    }
  }, [user.emailStatus])

  const history = useHistory()

  useMobileOptimized(false)

  const hideBar = () => {
    // remove verify parameter when hiding the bar
    const currentUrl = window.location.pathname

    history.push(currentUrl.slice().replace('?verify=1', ''))

    setNotificationBarVisibility(false)
  }

  return (
    <div className="app app-main">
      {notificationBarVisibility && (
        <AppNotificationBar type={notificationBarType} hide={hideBar} />
      )}
      <div className="app-container">
        <div
          style={{
            display: 'flex',
            paddingTop: notificationBarVisibility ? '40px' : 0,
          }}
        >
          <div
            style={{
              bottom: 0,
              position: 'fixed',
              top: notificationBarVisibility ? '40px' : 0,
              width: `${SIDE_MENU_WIDTH}px`,
            }}
          >
            <SideMenu amasNotification={amasNotification} />
          </div>
          <div
            style={{ paddingLeft: `${SIDE_MENU_WIDTH + 60}px`, width: '100%' }}
          >
            <Layout>
              <Content>
                <div className="container">{children}</div>
              </Content>
            </Layout>
          </div>
        </div>
      </div>
    </div>
  )
}
