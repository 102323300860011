import React, { useState } from 'react'

import Loader from '../../../../_components/Loader'
import Pagination from '../../../../_components/Pagination'
import { DEFAULT_SPACING } from '../CONSTANTS'

const BULLET_COLORS = {
  regular: '#77779F',
  positive: '#4BC463',
  negative: '#E01C1C',
  neutral: '#FFC021',
}

export default ({
  question,
  selectedSentiment = 'regular',
  onPageChange,
  onPageShowSizeChange,
  loadingAnswers,
  paginationEnabled,
}) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page)
    onPageChange(page, pageSize)
  }

  const handlePageShowSizeChange = (current, size) => {
    setCurrentPage(1)
    onPageShowSizeChange(size)
    setPageSize(size)
  }

  return (
    <div className="feedback-question">
      <div
        style={{
          minHeight: question.responsePagination.total > 10 ? '360px' : 0,
          maxHeight: question.responsePagination.total < 10 ? 'none' : '360px',
          overflowY: 'scroll',
        }}
      >
        {loadingAnswers ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '360px',
              justifyContent: 'center',
            }}
          >
            <Loader text="Loading responses..." />
          </div>
        ) : (
          <>
            {question.responses.length === 0 && 'No answers available.'}
            {question.responses.length > 0 &&
              question.responses.map((answer, index) => (
                <div
                  key={index}
                  style={{
                    fontSize: 16,
                    fontWeight: 300,
                    color: '#1c1047',
                    marginBottom: DEFAULT_SPACING * 2,
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <span
                    style={{
                      color: BULLET_COLORS[selectedSentiment],
                      fontWeight: 'bold',
                    }}
                  >
                    •
                  </span>{' '}
                  {answer}
                </div>
              ))}
          </>
        )}
      </div>
      {paginationEnabled && question.responsePagination.total > 10 && (
        <Pagination
          onChange={handlePageChange}
          onShowSizeChange={handlePageShowSizeChange}
          total={question.responsePagination.total}
          withPageSizeChanger={true}
          pageSizeOptions={['10', '25', '50']}
          pageSize={pageSize}
          current={currentPage}
          itemsName={`${
            selectedSentiment === 'regular' ? '' : selectedSentiment
          } answers`}
        />
      )}
    </div>
  )
}
