import gql from 'graphql-tag'

export const DEACTIVATE_USER = gql`
  mutation deactivateUser($id: ID!) {
    deactivateUser(id: $id) {
      id
      status
      type
    }
  }
`
