import React, { useRef } from 'react'

import AvatarListItem from '../../../_components/AvatarListItem'
import DropdownForTeamMember from './DropdownForTeamMember'

const PanelBodyRow = ({ user, team, teams }) => {
  const dropdownRef = useRef(null)
  const containerRef = useRef(null)

  const showDropdown = () => {
    dropdownRef.current.open()
  }

  const closeDropdown = () => {
    dropdownRef.current.close()
  }

  const handleOnContainerClick = evt => {
    if (evt.target !== containerRef.current) {
      return
    }
    dropdownRef.current.isOpen ? closeDropdown() : showDropdown()
  }

  return (
    <div
      className="team-list-v2__panel_body_row"
      onClick={handleOnContainerClick}
      ref={containerRef}
    >
      <AvatarListItem user={user} />
      <div className="team-list-v2__panel_body_row_action_button">
        <DropdownForTeamMember
          user={user}
          team={team}
          teams={teams}
          ref={dropdownRef}
        />
      </div>
    </div>
  )
}

export default PanelBodyRow
