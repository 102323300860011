import { Col, Progress, Row } from 'antd'
import React from 'react'

import { DEFAULT_SPACING } from '../CONSTANTS'

export default ({ answers }) => {
  return (
    <Row>
      <Col span={16}>
        {answers.map(answer => (
          <div key={answer.name}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: DEFAULT_SPACING * 0.5,
                color: '#1c1047',
                fontWeight: 300,
              }}
            >
              {answer.name}
              <div style={{ fontWeight: 'normal' }}>
                {Math.round(answer.percentage)}%
                <span
                  style={{
                    fontWeight: 300,
                    fontSize: 12,
                  }}
                >
                  {' '}
                  ({answer.count} {answer.count === 1 ? 'answer' : 'answers'})
                </span>
              </div>
            </div>
            <Progress
              style={{
                marginBottom: DEFAULT_SPACING * 1.5,
              }}
              percent={Math.round(answer.percentage)}
              showInfo={false}
              strokeColor="#77779f"
              strokeWidth={20}
            />
          </div>
        ))}
      </Col>
    </Row>
  )
}
