import React from 'react'

import Checkbox from './Checkbox'

const ListHeader = ({
  selected,
  onSelected,
  onDeselected,
  height = 56,
  style = {},
}) => {
  return (
    <div style={style}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 24px',
          height,
        }}
      >
        <div style={{ marginLeft: 'auto' }}>
          <Checkbox
            selected={selected}
            onSelect={onSelected}
            onDeselect={onDeselected}
            selectedText="Select all"
            deselectedText="Deselect all"
          />
        </div>
      </div>
    </div>
  )
}

export default ListHeader
