import { useMutation } from '@apollo/react-hooks'
import { Card, Typography } from 'antd'
import React, { useContext, useState } from 'react'

import AddPeopleByEmailForm from '../../_components/AddPeopleByEmailForm'
import AddPeopleBySlackForm from '../../_components/AddPeopleBySlackForm'
import { track } from '../../_helpers/analytics'
import { UserContext } from '../../UserContext'
import { SET_INVITED } from '../queries'

const { Title } = Typography

export default ({ onSubmit, onSkip }) => {
  const user = useContext(UserContext)
  const [formDisabled, setFormDisabled] = useState(false)

  const [setInvited] = useMutation(SET_INVITED)

  const handleSkip = async () => {
    try {
      setFormDisabled(true)
      await setInvited({
        variables: {
          invited: false,
        },
      })
      track('admin.onboarding.invitePeopleSkipped', {
        user: user.email,
        org: user.org.name,
      })
      if (onSkip) {
        onSkip()
      }
    } catch (e) {
      setFormDisabled(false)
      throw e
    }
  }

  const handleSubmit = async newUsers => {
    try {
      setFormDisabled(true)
      await setInvited({
        variables: {
          invited: true,
        },
      })
      track('admin.onboarding.invitePeople', {
        user: user.email,
        org: user.org.name,
        numberPeopleInvited: newUsers.length,
        method: user.authMethod,
      })
      if (onSubmit) {
        onSubmit()
      }
    } catch (e) {
      setFormDisabled(false)
      throw e
    }
  }

  return (
    <div className="onboarding-invite-people">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontSize: '24px', fontWeight: 500 }}
        >
          It takes two to tango (and to get feedback). Let's add some people to
          get the ball rolling...
        </Title>

        {user.authMethod === 'slack' && (
          <AddPeopleBySlackForm
            height={280}
            cancelLabel="Skip for now"
            onSubmit={handleSubmit}
            onCancel={handleSkip}
            buttonsRightAligned
            disabled={formDisabled}
            showPreviouslyInvited={false}
          />
        )}
        {user.authMethod === 'password' && (
          <AddPeopleByEmailForm
            cancelLabel="Skip for now"
            onCancel={handleSkip}
            onSubmit={handleSubmit}
          />
        )}
      </Card>
    </div>
  )
}
