import gql from 'graphql-tag'

export const GET_USERS = gql`
  query listPeopleUsers($offset: Int, $limit: Int, $search: UserSearchInput, $page: String) {
    users(offset: $offset, limit: $limit, search: $search, page: $page) {
      total
      users {
        id
        status
        firstName
        lastName
        displayName
        email
        emailStatus
        jobTitle
        avatarUrl
        role
        readonlyProperties
        manager {
          id
          firstName
          lastName
          email
          displayName
        }
        viewingManagers {
          id
          firstName
          lastName
          email
          displayName
        }
        directReports {
          id
          firstName
          lastName
          displayName
          email
        }
        type
        isOwner
        isAdmin
        team {
          id
          name
          members {
            id
            user {
              id
              firstName
              lastName
              displayName
              email
              status
            }
          }
        }
      }
    }
  }
`

export const GET_TEAMS = gql`
  query listTeams {
    teams {
      id
      name
    }
  }
`

export const EDIT_USER = gql`
  mutation updateUserInfo(
    $id: ID!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $teamId: String
    $role: String
    $managerId: String
    $viewingManagerIds: [String!]
    $directReportIds: [String!]
    $email: String!
  ) {
    updateUserDetails(
      id: $id
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
    ) {
      id
      firstName
      lastName
      jobTitle
    }
    changeUserTeam(id: $id, teamId: $teamId) {
      id
      team {
        id
        name
      }
    }
    changeUserRole(id: $id, role: $role) {
      id
      type
    }
    changeUserManager(id: $id, managerId: $managerId) {
      id
      manager {
        id
        firstName
        lastName
        email
      }
    }
    changeUserViewingManagers(id: $id, viewingManagerIds: $viewingManagerIds) {
      id
      viewingManagers {
        id
        firstName
        lastName
        email
      }
    }
    changeUserDirectReports(id: $id, directReportIds: $directReportIds) {
      id
      directReports {
        id
        firstName
        lastName
        email
      }
    }
    changeUserEmail(id: $id, email: $email) {
      id
      email
      emailStatus
    }
  }
`

export const CHANGE_USERS_MANAGER = gql`
  mutation changeMultipleUsersManager($ids: [ID!]!, $managerId: String!) {
    changeMultipleUsersManager(ids: $ids, managerId: $managerId)
  }
`

export const CHANGE_USERS_VIEWING_MANAGERS = gql`
  mutation changeMultipleUsersViewingManagers(
    $ids: [ID!]!
    $viewingManagerIds: [String!]!
  ) {
    changeMultipleUsersViewingManagers(
      ids: $ids
      viewingManagerIds: $viewingManagerIds
    )
  }
`

export const ADD_TEAM_MEMBERS = gql`
  mutation addTeamMembers($teamId: String!, $userIds: [String!]!) {
    addTeamMembers(addTeamMembersData: { teamId: $teamId, userIds: $userIds })
  }
`

export const ACTIVATE_USER = gql`
  mutation activateUser($id: ID!) {
    activateUser(id: $id) {
      id
      status
      type
    }
  }
`
