import './style.less'

import { Input } from 'antd'
import React, { useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import Filter from './Filter'

const Search = ({
  filters = [],
  placeholder = 'Search',
  debounce = 500,
  disabled = false,
  onChange,
  onFilterChange,
}) => {
  const [debouncedSearchChange] = useDebouncedCallback(val => {
    if (onChange) {
      onChange(val)
    }
  }, debounce)

  const handleOnInputChange = useCallback(evt => {
    debouncedSearchChange(evt.target.value)
  }, [])

  const handleOnFilterChange = val => {
    if (onFilterChange) {
      onFilterChange(val)
    }
  }

  const addonBefore = filters && (
    <>
      {filters.map((filter, i) => (
        <Filter
          key={i}
          label={filter.label}
          options={filter.options}
          loading={filter.loading}
          disabled={disabled}
          onOptionChange={handleOnFilterChange}
        />
      ))}
    </>
  )

  const className = (() => {
    const base = 'search__input'
    const classes = [base]
    if (filters) {
      classes.push(`${base}--has-filters`)
    }
    if (disabled) {
      classes.push(`${base}--disabled`)
    }
    return classes.join(' ')
  })()

  return (
    <Input
      addonBefore={addonBefore}
      suffix={false}
      placeholder={placeholder}
      className={className}
      onChange={handleOnInputChange}
      disabled={disabled}
    />
  )
}

export default Search
