import { Button, Row } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import BackButton from '../../../../_components/BackButton'
import { getCycleBaseUrl, getCycleTypeFromUrl } from '../../helpers'

const DEFAULT_SPACING = 8

export default ({
  step,
  handleContinueClick,
  contextId,
  startDate,
  fromWBYHT,
}) => {
  const location = useLocation()
  const cycleType = getCycleTypeFromUrl(location)
  const typePath = getCycleBaseUrl(cycleType)

  const [createButtonDisabled, setCreateButtonDisabled] = useState(false)

  const backRoute = () => {
    if (step === 1) {
      if (fromWBYHT) {
        return '/home'
      } else {
        return contextId ? typePath + '/scheduled' : typePath + '/ongoing'
      }
    } else {
      return contextId
        ? `${typePath}/edit/${contextId}/${step - 1}`
        : `${typePath}/create/${step - 1}`
    }
  }

  const buttonName = (() => {
    switch (step) {
      case 4:
        const cycleTypeLabel = cycleType === 'pulse' ? 'survey' : 'event'
        return startDate && moment.unix(startDate).isSame(moment(), 'day')
          ? `Launch ${cycleTypeLabel} now!`
          : `Schedule ${cycleTypeLabel}`
      default:
        return 'Next step'
    }
  })()

  const handleSubmitClick = () => {
    setCreateButtonDisabled(true)
    try {
      handleContinueClick()
    } catch (e) {
      setCreateButtonDisabled(false)
    }
  }

  return (
    <Row
      type="flex"
      justify="space-between"
      style={{ marginTop: DEFAULT_SPACING * 3 }}
    >
      <div style={{ marginLeft: 'auto' }}>
        <BackButton route={backRoute()} data-cy="previous-step" />
        <Button
          type="primary"
          onClick={handleSubmitClick}
          data-cy="next-step"
          disabled={createButtonDisabled}
        >
          {buttonName}
        </Button>
      </div>
    </Row>
  )
}
