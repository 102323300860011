import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default ({ cycleType, style }) => {
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: 'rgba(52, 219, 230, 0.15)',
        border: 'solid 1px #34DBE6',
        borderRadius: '6px',
        color: '#051617',
        display: 'flex',
        fontSize: '14px',
        lineHeight: '22px',
        margin: '24px 0',
        padding: '9px 16px',
        ...style,
      }}
    >
      <FontAwesomeIcon
        icon={['fal', 'info-circle']}
        style={{
          color: '#34dbe6',
          fontSize: 16,
          marginRight: 8,
        }}
      />
      <div style={{ marginRight: 'auto' }}>
        <>
          We’ve removed people’s names from their comments and randomised the
          order in which comments appear.
          <br />
          This makes the {cycleType === 'PULSE' ? 'answers' : 'feedback'}{' '}
          (almost) anonymous because it gives people psychological safety when
          sharing their honest insights. 😇
        </>
      </div>
    </div>
  )
}
