import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox, Dropdown, Menu } from 'antd'
import React from 'react'

import useTeams from '../../hooks/graphql/Teams/useTeams'

export default ({ onTeamChange, teamsArray, teamCounts }) => {
  const { data: teamsData /* loading: teamsLoading */ } = useTeams({
    noTeamName: 'Not part of a team',
    variables: {
      userTypes: ['invited', 'active'],
    },
  })

  if (teamsArray) {
    const menu = (
      <Menu>
        <Menu.Item
          onClick={e => {
            setTimeout(onTeamChange('all-teams'))
          }}
          key="all-teams"
          value="all-teams"
        >
          {' '}
          All teams
        </Menu.Item>
        {teamsData &&
          teamsData.teams &&
          teamsData.teams.map(team => {
            const checked = teamsArray.includes(team.id)

            const teamCount = teamCounts.find(x => x.id === team.id)

            return (
              <Menu.Item
                className="multi-teams__select__item"
                key={team.id}
                value={team.id}
              >
                <Checkbox
                  className="multi-teams__select__option"
                  checked={checked}
                  value={team.id}
                  key={team.id}
                >
                  {team.name} {teamCount ? '(' + teamCount.count + ')' : '(0)'}
                </Checkbox>
              </Menu.Item>
            )
          })}
      </Menu>
    )

    return (
      <div className="multi-teams">
        <Dropdown.Button
          className="dropdown-btn"
          icon={
            <FontAwesomeIcon
              icon={['fal', false ? 'chevron-up' : 'chevron-down']}
              style={{ fontSize: 10 }}
            />
          }
          onChange={e => {
            setTimeout(onTeamChange(e.target.value))
          }}
          overlay={menu}
        >
          {teamsArray.length === 1 ? (
            <>Showing responses from {teamsArray.length} team</>
          ) : teamsArray.length >= 2 ? (
            <>Showing responses from {teamsArray.length} teams</>
          ) : (
            <>Showing responses from all teams</>
          )}
        </Dropdown.Button>
      </div>
    )
  } else {
    return <></>
  }
}
