import { Steps } from 'antd'
import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import { getCycleBaseUrl } from '../../../helpers'
import CycleContext from '../../Context'
import StepsContext from '../../Context/StepsContext'
import checkStepInvalid from './checkStepInvalid'

const { Step } = Steps

const steps = [
  { title: 'Select the form' },
  { title: 'Select the people' },
  { title: 'Finalise' },
  { title: 'Launch' },
]

const StepsComponent = props => {
  const context = useContext(CycleContext)
  const stepsContext = useContext(StepsContext)

  const cycleTypeUrl = getCycleBaseUrl(context.type)

  const baseUrl = context.id
    ? `${cycleTypeUrl}/edit/` + context.id + '/'
    : `${cycleTypeUrl}/create/`

  useEffect(() => {
    const locationStepNumber = props.location.pathname.split('/').pop()

    if (
      locationStepNumber > 1 &&
      checkStepInvalid(locationStepNumber - 1, context)
    ) {
      stepsContext.setStep(0)
      props.history.push(baseUrl + 1)
    }

    stepsContext.setStep(locationStepNumber - 1)
  }, [props.history.location.key])

  const setStep = newStep => {
    if (checkStepInvalid(newStep, context)) {
      return
    }

    stepsContext.setStep(newStep)
    props.history.push(baseUrl + (newStep + 1))
  }

  return (
    <Steps
      className="steps-cycle-creation"
      current={stepsContext.currentStep}
      onChange={setStep}
    >
      {steps.map((step, index) => (
        <Step
          status={
            index < stepsContext.currentStep
              ? 'finish'
              : index === stepsContext.currentStep
              ? 'process'
              : 'wait'
          }
          title={step.title}
          key={index}
        />
      ))}
    </Steps>
  )
}

export default withRouter(StepsComponent)
