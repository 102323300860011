import { useMutation } from '@apollo/react-hooks'
import { notification } from 'antd'
import React from 'react'

import { CYCLE_TYPES } from '../../pages/helpers'
import queries from '../../pages/queries'
import CycleFinished from '../CycleFinished'
import CycleRunning from '../CycleRunning'
import CycleScheduled from '../CycleScheduled'
import { isCompletedCycle, isScheduledCycle } from '../helpers'
import { DELETE_CYCLE, DELETE_CYCLE_PERSONAL, DELETE_CYCLE_PULSE } from './queries'

export default props => {
  const cycle = props.cycle
  const isScheduledCycleCheck = isScheduledCycle(cycle.startDate)
  const isCompletedCycleCheck = isCompletedCycle(cycle.endDate)

  const [deleteCycleRegular] = useMutation(DELETE_CYCLE, {
    variables: {
      cycleId: cycle.id,
    },
    refetchQueries: [
      {
        query: queries[CYCLE_TYPES.threeSixty].GET_SCHEDULED_CYCLES,
      },
    ],
  })

  const [deleteCyclePersonal] = useMutation(DELETE_CYCLE_PERSONAL, {
    variables: {
      cycleId: cycle.id,
    },
    refetchQueries: [
      {
        query: queries[CYCLE_TYPES.personal].GET_SCHEDULED_CYCLES,
      },
    ],
  })

  const [deletePulseSurvey] = useMutation(DELETE_CYCLE_PULSE, {
    variables: {
      cycleId: cycle.id,
    },
    refetchQueries: [
      {
        query: queries[CYCLE_TYPES.pulse].GET_SCHEDULED_CYCLES,
      },
    ],
  })

  const handleDelete = () => {
    let deleteFunction

    switch (cycle.type) {
      case CYCLE_TYPES.threeSixty:
        deleteFunction = deleteCycleRegular
        break
      case CYCLE_TYPES.personal:
        deleteFunction = deleteCyclePersonal
        break
      case CYCLE_TYPES.pulse:
        deleteFunction = deletePulseSurvey
        break
      default:
        notification.error({
          message: 'Oops!',
          description: 'Deleting this type of event is not supported.',
        })
    }

    deleteFunction()
      .then(() => {
        props.handleDelete(cycle.id)
      })
      .catch(() => {
        notification.error({
          message: 'Oops!',
          description: 'It appears an error ocurred. Please try again later.',
        })
      })
  }

  if (isScheduledCycleCheck) {
    return <CycleScheduled cycle={cycle} handleDelete={handleDelete} />
  }

  if (isCompletedCycleCheck) {
    return <CycleFinished cycle={cycle} />
  } else {
    return <CycleRunning cycle={cycle} />
  }
}
