import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import Section from '../../Section'
import Table from '../../Table'
import { EVENTS_PRIVATE_FEEDBACK } from './queries'
import TableColumns from './TableColumns'

const PAGE_SIZE = 10

export default ({ timeInterval, isActive }) => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  const [queryVariables, setQueryVariables] = useState({
    startDate: timeInterval[0],
    endDate: timeInterval[1],
    offset: 0,
    limit: PAGE_SIZE,
  })

  const { data } = useQuery(EVENTS_PRIVATE_FEEDBACK, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,
    skip: !isActive,
    onCompleted: () => {
      setLoading(false)
    },
  })

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (
      timeInterval[0] === queryVariables.startDate &&
      timeInterval[1] === queryVariables.endDate
    ) {
      return
    }
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    })
  }, [timeInterval])

  const handlePageChange = page => {
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      offset: PAGE_SIZE * (page - 1),
    })
  }

  const handleRowClick = record => {
    if (record.cycle.creator.status !== 'DEIDENTIFIED') {
      history.push('/profile/' + record.cycle.creator.id)
    }
  }

  return (
    <Section title="Events">
      <Table
        onPageChange={handlePageChange}
        columns={TableColumns}
        loading={loading}
        data={
          data &&
          data.eventsPrivateFeedbackInsight &&
          data.eventsPrivateFeedbackInsight.items
        }
        rowKey={record => record.id}
        total={
          data &&
          data.eventsPrivateFeedbackInsight &&
          data.eventsPrivateFeedbackInsight.total
        }
        onRow={record => {
          return {
            onClick: () => handleRowClick(record),
          }
        }}
        itemsName="events"
      />
    </Section>
  )
}
