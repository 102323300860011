import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Form, Modal, Typography, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'

import { Avatar, AvatarName } from '../../../_components/Avatar'
import BackButton from '../../../_components/BackButton'
import SuccessModalContext from '../../../_components/SuccessModal/context'
import { track } from '../../../_helpers/analytics'
import { AutoSaveContext } from '../../../AutoSaveContext'
import { GET_TODOS } from '../../../hooks/graphql/Todos/queries'
import { UserContext } from '../../../UserContext'
import { CYCLE_TYPES } from '../helpers'
import queries from '../queries'
import FormQuestionMultiChoice from './FormQuestionMultiChoice'
import FormQuestionText from './FormQuestionText'
import GiveFeedbackContext from './giveFeedbackContext'
import { COMPLETE_FEEDBACK } from './queries'

const { Paragraph, Title } = Typography

const createForm = ({
  history,
  form,
  formStructure,
  cycleName,
  cycleType: cycleTypeProp,
}) => {
  const user = useContext(UserContext)
  const giveFeedbackContext = useContext(GiveFeedbackContext)
  const autoSaveContext = useContext(AutoSaveContext)
  const successModal = useContext(SuccessModalContext)

  const [cycleType, setCycleType] = useState({})
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    switch (cycleTypeProp) {
      case 'THREESIXTY':
        setCycleType({ track: 'team360', query: '360' })
        break
      case 'PERSONAL':
        setCycleType({ track: 'personal360', query: 'personal' })
        break
      case 'PULSE':
        setCycleType({ track: 'pulse', query: 'pulse' })
        break
      default:
        setCycleType({ track: 'team360', query: '360' })
    }
  }, [cycleTypeProp])

  const getAnswers = () => {
    const values = form.getFieldsValue()
    return Object.keys(values).map(key => {
      return {
        formQuestionId: key,
        data: { value: values[key] },
      }
    })
  }

  const [createFeedbackMutation] = useMutation(COMPLETE_FEEDBACK, {
    variables: {
      feedbackId: giveFeedbackContext.feedbackId,
      responses: getAnswers(),
    },
    refetchQueries: [
      {
        query: queries[CYCLE_TYPES.personal].GET_CYCLES,
        variables: {
          timing: 'running',
        },
      },
      {
        query: queries[CYCLE_TYPES.threeSixty].GET_CYCLES,
        variables: {
          timing: 'running',
        },
      },
      {
        query: queries[CYCLE_TYPES.pulse].GET_CYCLES,
        variables: {
          timing: 'running',
        },
      },
      {
        query: GET_TODOS,
      },
    ],
  })

  const { getFieldDecorator } = form

  const getQuestion = sectionFormQuestion => {
    const formQuestion = formStructure.formQuestions.find(
      fq => fq.id === sectionFormQuestion.id,
    )
    return formQuestion
      ? Object.assign(
          {
            formQuestionId: sectionFormQuestion.id,
            isSentimentAnalysisActivated:
              formQuestion.isSentimentAnalysisActivated,
            ...sectionFormQuestion,
          },
          formQuestion.question,
        )
      : undefined
  }

  const submitForm = () => {
    createFeedbackMutation()
      .then(() => {
        autoSaveContext.clearDraft(giveFeedbackContext.feedbackId)
        setModalVisible(false)
        track(cycleType.track + '.feedback.submit', {
          provider: user.email,
          org: user.org.name,
          feedbackId: giveFeedbackContext.feedbackId,
        })
        history.push('/home')
        successModal.show({
          message: `Awesome, thanks! We'll pass that on :-)`,
        })
      })
      .catch(() => {
        notification.error({
          message: 'Oops!',
          description:
            'There was a problem submitting your feedback. Please try again. Let us know if you need any help :)',
        })
      })
  }

  const handleSubmitAttempt = e => {
    e.preventDefault()
    form.validateFieldsAndScroll({ scroll: { offsetTop: 70 } }, err => {
      if (!err) {
        track(cycleType.track + '.feedback.submitAttempt', {
          provider: user.email,
          org: user.org.name,
          feedbackId: giveFeedbackContext.feedbackId,
        })
        setModalVisible(true)
      }
    })
  }

  useEffect(() => {
    if (
      giveFeedbackContext &&
      giveFeedbackContext.feedbackId &&
      autoSaveContext &&
      !autoSaveContext.enabled
    ) {
      autoSaveContext.enable(giveFeedbackContext.feedbackId, form)
    }
  }, [])

  return (
    <div>
      <Form layout="vertical" onSubmit={handleSubmitAttempt}>
        {formStructure.sections.map((section, sectionIndex) => (
          <Card
            title={
              <Title level={4} style={{ marginBottom: 0 }}>
                {section.title}
              </Title>
            }
            style={{ marginBottom: '32px' }}
            key={sectionIndex}
          >
            <div style={{ padding: '0px 8px' }}>
              {section.formQuestions.map(sectionFormQuestion => {
                const question = getQuestion(sectionFormQuestion)

                switch (question.template.type) {
                  case 'SHORT_TEXT':
                  case 'TEXT':
                    return (
                      <FormQuestionText
                        key={question.formQuestionId}
                        question={question}
                        fieldDecorator={getFieldDecorator}
                        target="other"
                      />
                    )

                  case 'MULTI_CHOICE':
                    return (
                      <FormQuestionMultiChoice
                        key={question.formQuestionId}
                        question={question}
                        fieldDecorator={getFieldDecorator}
                        target="other"
                      />
                    )

                  default:
                    return <div></div>
                }
              })}
            </div>
          </Card>
        ))}
        <Form.Item style={{ textAlign: 'right', marginTop: 8 }}>
          <BackButton style={{ marginRight: 8 }} />
          <Button type="primary" shape="round" size="large" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Modal
        title={
          <div
            style={{
              margin: `${cycleTypeProp === 'PULSE' ? '-5px' : '-10px'} 0`,
            }}
          >
            {cycleTypeProp !== 'PULSE' && (
              <Avatar
                user={formStructure.user}
                withRightMargin
                disableTooltip
              />
            )}
            <div
              style={{
                fontFamily: 'museo, serif',
                color: '#1c1047',
                fontSize: '18px',
                wordBreak: 'break-word',
              }}
            >
              {cycleTypeProp === 'PULSE' ? (
                <>
                  <div
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    Answering survey
                  </div>
                  <div style={{ lineHeight: '24px' }}>{cycleName}</div>
                </>
              ) : (
                <>
                  Giving feedback to{' '}
                  <AvatarName
                    user={formStructure.user}
                    style={{ color: '#f95c4b' }}
                  />
                </>
              )}
            </div>
          </div>
        }
        visible={modalVisible}
        centered
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button
            key="back"
            type="ghost"
            onClick={() => {
              track(cycleType.track + '.feedback.submitLater', {
                provider: user.email,
                org: user.org.name,
                feedbackId: giveFeedbackContext.feedbackId,
              })
              setModalVisible(false)
            }}
          >
            Submit later
          </Button>,
          <Button key="submit" type="accent" onClick={submitForm}>
            Submit now
          </Button>,
        ]}
        style={{ textAlign: 'center' }}
      >
        <Paragraph style={{ textAlign: 'left' }}>
          {cycleTypeProp === 'PULSE' ? (
            <>
              Are you happy with your answers? <br />
              Once you submit your answers you can no longer edit them.
            </>
          ) : (
            <>
              Are you happy with your feedback? <br />
              Once you submit feedback you can no longer edit it.
            </>
          )}
        </Paragraph>
      </Modal>
    </div>
  )
}

export default withRouter(Form.create({ name: 'createFeedback' })(createForm))
