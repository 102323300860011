import { Card } from 'antd'
import React from 'react'

import CycleCardTitle from '../CycleCardTitle'
import CycleProgress from '../CycleProgress'
import CancelEventButton from './CancelEventButton'
import EditEventButton from './EditEventButton'

export default ({ cycle, handleDelete }) => {
  return (
    <Card
      title={<CycleCardTitle cycle={cycle} />}
      bodyStyle={{
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      }}
      style={{ marginBottom: 32 }}
    >
      <div>
        <div style={{ padding: '0 24px 24px' }}>
          <CycleProgress cycle={cycle} />
        </div>
        <div
          style={{
            backgroundColor: '#FAFAFA',
            borderRadius: '0 0 16px 16px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0 24px',
            height: '64px',
          }}
        >
          <EditEventButton cycle={cycle} />

          <CancelEventButton cycle={cycle} onDelete={handleDelete} />
        </div>
      </div>
    </Card>
  )
}
