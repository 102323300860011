import { Form, Input } from 'antd'
import React from 'react'

import AutocompleteUser from '../../../_components/Form/AutocompleteUser'
import AutocompleteUserOption from '../../components/AutocompleteUserOption'

const CreateTeamForm = ({ form, handleChange }) => {
  const { getFieldDecorator } = form

  const disableUserRule = user => {
    if (!user.readonlyProperties) {
      return false
    }
    return !!user.readonlyProperties.find(x => x.name === 'team')
  }

  return (
    <Form colon={false}>
      <Form.Item label="Team name">
        {getFieldDecorator('teamName', {
          rules: [{ required: true, message: 'This field is required!' }],
        })(<Input onKeyUp={handleChange} placeholder="Enter team name" />)}
      </Form.Item>
      <Form.Item label="Members">
        {getFieldDecorator('members', {
          initialValue: [],
        })(
          <AutocompleteUser
            allowMultiple
            disableRule={disableUserRule}
            userTypes={[
              'created',
              'deactivated',
              'invited',
              'onboarding',
              'standard',
              'admin',
              'owner',
            ]}
            renderOption={user => <AutocompleteUserOption user={user} />}
          />,
        )}
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'CreateTeamForm' })(CreateTeamForm)
