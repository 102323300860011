import React from 'react'

import { CYCLE_TYPES } from '../../pages/helpers'
import CycleProgressPersonal from './personal'
import CycleProgressPulse from './pulse'
import CycleProgressTeam360 from './team360'

export default ({ cycle }) => {
  if (cycle.type === CYCLE_TYPES.personal) {
    return <CycleProgressPersonal cycle={cycle} />
  }

  if (cycle.type === CYCLE_TYPES.threeSixty) {
    return <CycleProgressTeam360 cycle={cycle} />
  }

  if (cycle.type === CYCLE_TYPES.pulse) {
    return <CycleProgressPulse cycle={cycle} />
  }

  return <></>
}
