import gql from 'graphql-tag'

export const GET_SLACK_USERS = gql`
  query slackUsers {
    slackUsers {
      id
      slackUserId
      status
      invitedAt
      email
      firstName
      lastName
      avatarUrl
    }
  }
`

export const ADD_SLACK_USERS = gql`
  mutation addPeopleViaSlack($slackUserIds: [String!]!, $invite: Boolean!) {
    addPeopleViaSlack(slackUserIds: $slackUserIds, invite: $invite)
  }
`

export const GET_COLLEAGUES = gql`
  query addPeopleBySlackUsers($offset: Int, $limit: Int) {
    users(offset: $offset, limit: $limit) {
      total
    }
  }
`
