import { useQuery } from '@apollo/react-hooks'
import React, { useContext, useEffect, useState } from 'react'
import Emoji from 'react-emoji-render'
import { Switch, useLocation } from 'react-router-dom'

import AppRoute from './../../../../AppRoutes/AppRoute'
import Pagination from '../../../../_components/Pagination'
import { UserContext } from '../../../../UserContext'
import CyclesList from '../../../components/CyclesList'
import { CYCLE_TYPES, getCycleTypeFromUrl } from '../../helpers'
import queries from '../../queries'
import Title from '../Title'

const PAGE_SIZE = 10
const getOffset = pageNumber => pageNumber * PAGE_SIZE - PAGE_SIZE

export default ({ menu, routes }) => {
  const user = useContext(UserContext)

  const location = useLocation()

  const [cycles, setCycles] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const cycleType = getCycleTypeFromUrl(location)

  const {
    data: dataCycles,
    error: errorCycles,
    loading: loadingCycles,
  } = useQuery(queries[cycleType].GET_CYCLES, {
    variables: {
      timing: 'running',
      offset: getOffset(pageNumber),
      limit: PAGE_SIZE,
    },
  })

  useEffect(() => {
    if (
      dataCycles &&
      dataCycles.cycles.items &&
      dataCycles.cycles.items.length > 0
    ) {
      setCycles(
        dataCycles.cycles.items.map(x => {
          return {
            ...x,
            type: cycleType,
          }
        }),
      )
    }
  }, [dataCycles])

  const onPageChange = newPageNumber => {
    setPageNumber(newPageNumber)
    window.scroll({
      top: 0,
    })
  }

  return (
    <div className="cycles">
      <Title cycleType={cycleType} />

      {menu}

      {loadingCycles ? (
        <p>Loading...</p>
      ) : (
        <>
          {errorCycles ? (
            <p>Error!</p>
          ) : (
            <>
              {cycles && cycles.length > 0 ? (
                <>
                  <CyclesList cycles={cycles} />
                  {dataCycles.cycles.total > PAGE_SIZE && (
                    <Pagination
                      current={pageNumber}
                      onChange={onPageChange}
                      total={dataCycles.cycles.total}
                      pageSize={PAGE_SIZE}
                      disabled={loadingCycles}
                      itemsName={
                        cycleType === CYCLE_TYPES.pulse ? 'surveys' : 'events'
                      }
                    />
                  )}
                </>
              ) : (
                <div
                  style={{
                    color: '#77779f',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 300,
                  }}
                >
                  <div style={{ margin: '16px 0 0' }}>
                    Nothing to see here.
                    <Emoji text={':shrug:'} style={{ fontSize: '24px' }} />
                  </div>
                  <div>
                    {cycleType === CYCLE_TYPES.personal &&
                      'To get the ball rolling please create a new event.'}
                    {cycleType === CYCLE_TYPES.threeSixty &&
                      (user.isOwner() ||
                        user.isManager() ||
                        user.isAdmin() ||
                        user.isSuperUser()
                        ? 'To get the ball rolling please create a new event.'
                        : 'Ask your Manager or your company’s Howamigoing account owner to create a new 360 feedback event, or go to Private feedback to request some feedback for yourself.')}
                    {cycleType === CYCLE_TYPES.pulse &&
                      'To get the ball rolling please create a new event.'}
                  </div>
                </div>
              )}
            </>
          )}

          {routes && (
            <Switch>
              {routes.map((route, routeIndex) => {
                return (
                  <AppRoute
                    path={route.path}
                    render={props => (
                      <route.component {...props} cycles={cycles} />
                    )}
                    key={routeIndex}
                  />
                )
              })}
            </Switch>
          )}
        </>
      )}
    </div>
  )
}
