import React from 'react'
import Emoji from 'react-emoji-render'

const NoSearchResults = () => {
  return (
    <>
      <p style={{
        color: '#6b6b8f',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: 'normal',
        marginBottom: 4,
      }}>
        Oh no! 
        <Emoji text={` :see_no_evil: `} style={{ fontSize: 20 }} />
        We didn't find any results which match that filter.
      </p>
      <p style={{
        color: '#6b6b8f',
        fontSize: '14px',
        lineHeight: '22px',
        fontWeight: 300
      }}>
        Please try another one.
      </p>
    </>
  )
}

export default NoSearchResults
