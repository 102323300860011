import './style.less'

import React, { useEffect, useRef, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from 'antd'

const AutosaveIcon = ({ theme }) => {
  if (theme === 'error') {
    return (
      <Icon
        type="exclamation-circle"
        theme={'twoTone'}
        twoToneColor={'#f5222d'}
        style={{ fontSize: '14px' }}
      />
    )
  }
  return (
    <FontAwesomeIcon
      icon={{ iconName: 'check-circle', prefix: 'fas' }}
      style={{ fontSize: '14px' }}
    />
  )
}

const AutosaveIndicator = ({
  visible,
  text = 'Saving',
  theme = 'success',
  delayExit,
  ...props
}) => {
  const visibilityTimeoutId = useRef(null)
  const [managedVisibility, setManagedVisibility] = useState(visible)
  const visibleClassName = managedVisibility
    ? 'autosave-indicator__container--visible'
    : ''
  const themeClassName = (() => {
    if (theme === 'error') {
      return 'autosave-indicator__container--error'
    }
    return 'autosave-indicator__container--success'
  })()

  useEffect(() => {
    if (visible) {
      setManagedVisibility(true)
      return
    }
    if (delayExit && !visible) {
      clearTimeout(visibilityTimeoutId.current)
      visibilityTimeoutId.current = setTimeout(() => {
        setManagedVisibility(false)
      }, delayExit)
    } else {
      setManagedVisibility(false)
    }
    return () => clearTimeout(visibilityTimeoutId.current)
  }, [visible])

  return (
    <div
      {...props}
      className={`autosave-indicator__container ${visibleClassName} ${themeClassName}`}
    >
      <AutosaveIcon theme={theme} />
      <span className="autosave-indicator__text">{text}</span>
    </div>
  )
}

export default AutosaveIndicator
