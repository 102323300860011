import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input, notification } from 'antd'
import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import AddPeopleModal from '../../../_components/AddPeopleModal'
import CardTable from '../../../_components/CardTable'
import { PaginationMeta } from '../../../_components/Pagination'
import Tag from '../../../_components/Tag'
import useUpdateUserDetailsGQL from '../../../hooks/graphql/Users/useUpdateUserDetailsGQL'
import BulkActionBar from './BulkActionBar'
import DropdownForUser from './DropdownForUser'
import FloatingActionBar from './FloatingActionBar'
import { GET_TEAMS, GET_USERS } from './queries'
import Stats from './Stats'
import TableColumns from './TableColumns'

const PeopleList = () => {
  const [selectedUsers, setSelectedUsers] = useState([])
  const [addPeopleModalVisible, setAddPeopleModalVisible] = useState(false)
  const [page, setPage] = useState(1)
  const [searchParams, setSearchParams] = useState(undefined)
  const [debouncedSearchChange] = useDebouncedCallback(val => {
    onSearchChange(val)
  }, 500)
  const pageSize = 10
  const offset = page * pageSize - pageSize

  const {
    data: paginatedUsers,
    loading: loadingUsers,
    refetch: refetchUsers,
  } = useQuery(GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      offset,
      limit: pageSize,
      search: searchParams,
      page: 'peopleList'
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: teamsData, refetch: refetchTeams } = useQuery(GET_TEAMS, {
    fetchPolicy: 'network-only',
  })

  const users = paginatedUsers ? paginatedUsers.users.users : []
  const numUsers = paginatedUsers ? paginatedUsers.users.total : 0
  const teams = teamsData ? teamsData.teams : []

  const [updateUserDetails] = useUpdateUserDetailsGQL({
    refetchQueries: ['peopleListStats'],
    fetchPolicy: 'no-cache',
  })

  const showAddPeopleModal = () => {
    setAddPeopleModalVisible(true)
  }

  const hideAddPeopleModal = () => {
    setAddPeopleModalVisible(false)
  }

  const onSearchChange = val => {
    const newSearch = { name: val, email: val }
    setSearchParams({
      ...searchParams,
      ...newSearch,
    })
    setPage(1)
    refetchUsers({
      variables: {
        offset,
        page,
        search: newSearch,
      },
    })
  }

  const handleModalSubmit = async values => {
    try {
      await updateUserDetails({
        variables: {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          jobTitle: values.jobTitle,
          teamId: values.teamId,
          role: values.role,
          managerId: values.managerId,
          viewingManagerIds: values.viewingManagerIds,
          directReportIds: values.directReportIds,
          email: values.email,
        },
      })

      refetchUsers({
        variables: {
          limit: pageSize - 1,
          offset: offset,
          search: searchParams,
        },
      })
      refetchTeams()
    } catch (e) {
      if (
        e.networkError.result.errors &&
        e.networkError.result.errors[0].message.includes(
          'Cannot update email for this account',
        )
      ) {
        notification.error({
          message: `Uh-oh... 🤔`,
          description: `You cannot update to an email with that email domain. Please enter a different email address.`,
        })
      }
      if (
        e.networkError.result.errors &&
        e.networkError.result.errors[0].message.includes(
          `a domain that is not the same as the company's approved email domains`,
        )
      ) {
        notification.error({
          message: `Uh-oh... 🕵🏻‍♂️🤔`,
          description: `Please enter an email address that has an accepted email domain for your account.`,
        })
      }
      throw new Error()
    }
  }

  const columns = [
    ...TableColumns,
    {
      title: '',
      key: 'operation',
      width: '5%',
      render: colleague => {
        return (
          <span>
            <DropdownForUser
              colleague={colleague}
              teams={teams}
              handleModalSubmit={handleModalSubmit}
            />
          </span>
        )
      },
    },
  ]

  const onUsersAdded = () => {
    return refetchUsers({
      variables: {
        limit: pageSize - 1,
        offset,
        search: searchParams,
      },
    }).finally(() => {
      hideAddPeopleModal()
    })
  }

  const addSelectedUsers = usersToAdd => {
    const newSelections = usersToAdd.filter(
      x => !selectedUsers.find(y => y.id === x.id),
    )
    setSelectedUsers([...selectedUsers, ...newSelections])
  }

  const removeSelectedUsers = usersToRemove => {
    const selectionsWithoutRemoved = selectedUsers.filter(
      x => !usersToRemove.find(y => y.id === x.id),
    )
    setSelectedUsers(selectionsWithoutRemoved)
  }

  const rowSelection = {
    selectedRowKeys: selectedUsers.map(x => x.id),
    onSelect: (row, selected) => {
      if (selected) {
        addSelectedUsers([row])
        return
      }
      removeSelectedUsers([row])
    },
    onSelectAll: (selected, selectedRows) => {
      if (selected) {
        addSelectedUsers(selectedRows)
        return
      }
      // because selectedRows is empty on Deselect All,
      //   we'll be removing the current fetched page
      removeSelectedUsers(users)
    },
    getCheckboxProps: row => {
      return {
        disabled: false,
        name: row.id,
      }
    },
  }

  const handleBulkActionClear = () => {
    setSelectedUsers([])
  }

  const getFilterSearchParam = name => {
    switch (name) {
      case 'not-invited':
        return { types: ['created'] }
      case 'pending-invite':
        return { types: ['invited'] }
      case 'no-manager':
        return { hasManager: false }
      case 'no-team':
        return { hasTeam: false }
      default:
        return undefined
    }
  }

  const handleOnStatClick = name => {
    setPage(1)
    setSearchParams({
      ...searchParams,
      ...getFilterSearchParam(name),
    })
    refetchUsers({
      variables: {
        limit: pageSize,
        offset: 1,
        search: searchParams,
      },
    })
  }

  const handleFilterClose = name => {
    setPage(1)
    const newSearchParams = {
      ...searchParams,
    }
    const removedSearchParam = Object.keys(getFilterSearchParam(name))[0]
    delete newSearchParams[removedSearchParam]
    setSearchParams({
      ...newSearchParams,
    })
  }

  const handleOnPaginationChange = page => {
    const newOffset = page * pageSize - pageSize
    setPage(page)
    refetchUsers({
      variables: {
        limit: pageSize,
        offset: newOffset,
        search: searchParams,
      },
    })
  }

  const hasSearchParams =
    searchParams &&
    Object.keys(searchParams).filter(x => x !== 'name' && x !== 'email')
      .length > 0

  const filters = []
  if (hasSearchParams) {
    if (searchParams.types && searchParams.types.find(x => x === 'created')) {
      filters.push({
        label: 'Not invited',
        key: 'not-invited',
      })
    }
    if (searchParams.types && searchParams.types.find(x => x === 'invited')) {
      filters.push({
        label: 'Pending invite',
        key: 'pending-invite',
      })
    }
    if (searchParams.hasManager === false) {
      filters.push({
        label: 'No manager',
        key: 'no-manager',
      })
    }
    if (searchParams.hasTeam === false) {
      filters.push({
        label: 'No team',
        key: 'no-team',
      })
    }
  }

  return (
    <div className="peopleTableContainer">
      <Stats style={{ marginBottom: '24px' }} onStatClick={handleOnStatClick} />

      <div style={{ display: 'flex', marginBottom: '24px' }}>
        <Input
          addonBefore={
            hasSearchParams
              ? filters.map((filter, i) => {
                  return (
                    <Tag
                      key={filter.key}
                      closable={true}
                      editable={false}
                      selected={false}
                      style={{ marginRight: i < filters.length - 1 ? 8 : 0 }}
                      onClose={() => handleFilterClose(filter.key)}
                      value={filter.label}
                    />
                  )
                })
              : null
          }
          suffix={false}
          placeholder={
            hasSearchParams ? 'Name or email' : 'Search by name or email'
          }
          className="people-list__search-input"
          onChange={evt => debouncedSearchChange(evt.target.value)}
        />
        <div style={{ marginLeft: 'auto' }}>
          <Button
            type="primary"
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={showAddPeopleModal}
          >
            <FontAwesomeIcon
              icon={['fal', 'plus']}
              style={{ width: '1em', marginRight: 8 }}
            />
            Add people
          </Button>
        </div>
      </div>

      <CardTable
        rowSelection={rowSelection}
        dataSource={users}
        columns={columns}
        rowKey={record => record.id}
        loading={loadingUsers}
        pagination={{
          defaultPageSize: pageSize,
          showSizeChanger: false,
          pageSizeOptions: ['10'],
          onChange: handleOnPaginationChange,
          total: numUsers,
          current: page,
          showTotal: (total, range) => <PaginationMeta range={range} total={total} suffix="people" />
        }}
      ></CardTable>

      <AddPeopleModal
        visible={addPeopleModalVisible}
        onClose={hideAddPeopleModal}
        onSuccess={onUsersAdded}
      />

      <FloatingActionBar visible={selectedUsers.length > 0}>
        <BulkActionBar users={selectedUsers} onClear={handleBulkActionClear} />
      </FloatingActionBar>
    </div>
  )
}

export default PeopleList