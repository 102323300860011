import './style.less'

import { Button } from 'antd'
import React from 'react'

import graphicSrc from '../../../_assets/img/writing-question.svg'

const EmptyState = ({ heading, text, buttonText, onButtonClick }) => {
  return (
    <div className="ama-empty-state__container">
      <img
        className="ama-empty-state__graphic"
        src={graphicSrc}
        alt="Empty state graphic"
      ></img>
      {heading && <div className="ama-empty-state__heading">{heading}</div>}
      {text && <div className="ama-empty-state__text">{text}</div>}
      {buttonText && onButtonClick && (
        <Button
          className="ama-empty-state__button"
          type="primary"
          style={{ marginLeft: 'auto' }}
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  )
}

export default EmptyState
