import { useMutation, useQuery } from '@apollo/react-hooks'
import { notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import AddPeopleModal from '../../../_components/AddPeopleModal'
import SuccessModalContext from '../../../_components/SuccessModal/context'
import { AskQuestion } from '../../components'
import { ASK_QUESTION, NUM_USERS } from './queries'

const AskQuestionPage = ({ visible, onOk, onCancel }) => {
  const successModal = useContext(SuccessModalContext)

  const [addPeopleModalVisible, setAddPeopleModalVisible] = useState(false)
  const showAddPeopleModal = () => setAddPeopleModalVisible(true)
  const hideAddPeopleModal = () => setAddPeopleModalVisible(false)

  const [askQuestionModalVisible, setAddQuestionModalVisible] = useState(false)
  const showAskQuestionModal = () => setAddQuestionModalVisible(true)
  const hideAskQuestionModal = () => setAddQuestionModalVisible(false)

  const [askQuestion] = useMutation(ASK_QUESTION, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })

  const { data: numUsersResponse } = useQuery(NUM_USERS, {
    fetchPolicy: 'no-cache',
  })

  const handleOnAskQuestionOk = async ({ recipientId, question }) => {
    try {
      await askQuestion({
        variables: {
          recipientId,
          question,
        },
      })
      successModal.show({
        message: `Question sent!`,
      })
      if (onOk) {
        onOk({ recipientId, question })
      }
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
      throw e
    }
  }

  const handleOnAddPeopleSuccess = () => {
    hideAddPeopleModal()
  }

  const handleOnAddPeopleClose = () => {
    hideAddPeopleModal()
    if (onCancel) {
      onCancel()
    }
  }

  const handleOnAskQuestionClose = () => {
    hideAskQuestionModal()
    if (onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    if (!numUsersResponse) {
      return
    }
    const hasColleaguesToAskQuestion =
      numUsersResponse &&
      numUsersResponse.users &&
      numUsersResponse.users.total > 1

    if (hasColleaguesToAskQuestion && visible) {
      showAskQuestionModal()
      hideAddPeopleModal()
    } else if (!hasColleaguesToAskQuestion && visible) {
      showAddPeopleModal()
      hideAskQuestionModal()
    } else {
      hideAddPeopleModal()
      hideAskQuestionModal()
    }
  }, [visible, numUsersResponse])

  return (
    <>
      <AskQuestion.Modal
        visible={askQuestionModalVisible}
        onCancel={handleOnAskQuestionClose}
        onOk={handleOnAskQuestionOk}
      />
      <AddPeopleModal
        visible={addPeopleModalVisible}
        onClose={handleOnAddPeopleClose}
        onSuccess={handleOnAddPeopleSuccess}
      />
    </>
  )
}

export default AskQuestionPage
