import { Button } from 'antd'
import React, { useState } from 'react'

import EditTeamModal from '../EditTeamModal'

const EmptyTeam = ({ teamId, teamName }) => {
  const [editTeamModalVisible, setEditTeamModalVisible] = useState(false)
  const showEditModal = () => setEditTeamModalVisible(true)
  const hideEditModal = () => setEditTeamModalVisible(false)

  return (
    <>
      <div
        style={{
          padding: '0 0 28px 40px',
        }}
      >
        <div
          style={{
            fontSize: '14px',
            color: '#77779f',
            fontWeight: 300,
            marginBottom: 16,
          }}
        >
          The <span style={{ fontWeight: 400 }}>{teamName}</span> team is
          looking a bit empty, don't you think?
        </div>
        <Button
          className="ama-empty-state__button"
          type="primary"
          icon="plus"
          onClick={showEditModal}
          size="small"
        >
          Add members
        </Button>
      </div>
      <EditTeamModal
        teamId={teamId}
        visible={editTeamModalVisible}
        onOk={hideEditModal}
        onCancel={hideEditModal}
      />
    </>
  )
}

export default EmptyTeam
