import { useMutation } from '@apollo/react-hooks'
import { Button, notification } from 'antd'
import React, { useState } from 'react'

import { IMPORT_PERSONIO_ACCOUNT } from './queries'

const ImportButton = ({ type = 'primary', children, ...props }) => {
  const [importPersonioAccount] = useMutation(IMPORT_PERSONIO_ACCOUNT)
  const [importing, setImporting] = useState(false)

  const handleImportClick = async () => {
    try {
      setImporting(true)
      await importPersonioAccount()
      notification.success({
        message: 'Done and dusted',
        description: `Your Personio peeps have been synced with your Howamigoing account`,
      })
    } catch (e) {
      console.error(e)
      notification.error({
        message: 'Oops, something went wrong',
        description: e.message,
      })
    } finally {
      setImporting(false)
    }
  }

  return (
    <Button
      {...props}
      type={type}
      onClick={handleImportClick}
      loading={importing}
    >
      {children}
    </Button>
  )
}

export default ImportButton
