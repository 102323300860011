import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import React, { useRef, useState } from 'react'

import InfoMessage from '../../../_components/InfoMessage'
import Form from './Form'

const ReportAbuseModal = ({
  visible,
  defaultValue = '',
  title = 'Why are you reporting this?',
  okText = 'Report',
  cancelText = 'Cancel',
  onOk,
  onCancel,
}) => {
  const formRef = useRef(null)

  const [disabledForm, setDisabledForm] = useState(false)
  const [oking, setOking] = useState(false)

  const handleSubmit = () => {
    setDisabledForm(true)
    setOking(true)
    formRef.current.validateFields(async (errors, values) => {
      try {
        if (errors) {
          setDisabledForm(false)
          setOking(false)
          return
        }
        if (onOk) {
          await onOk(values.abuse)
        }
        setDisabledForm(false)
        setOking(false)
      } catch (e) {
        setDisabledForm(false)
        setOking(false)
      }
    })
  }

  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      onOk={handleSubmit}
      okButtonProps={{ loading: oking, disabled: disabledForm, type: 'accent' }}
      cancelText={cancelText}
      cancelButtonProps={{ type: 'ghost', disabled: disabledForm }}
      onCancel={onCancel}
      width={584}
      destroyOnClose={true}
      centered
    >
      <Form ref={formRef} defaultValues={{ abuse: defaultValue }} />
      <InfoMessage icon={<FontAwesomeIcon icon={['fas', 'bell-slash']} />}>
        Don't worry, we don’t notify the person you’re reporting.
      </InfoMessage>
    </Modal>
  )
}

export default ReportAbuseModal
