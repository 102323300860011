import { Button } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { track } from '../../../_helpers/analytics'
import AMA from '../../../AskMeAnything'
import { UserContext } from '../../../UserContext'

export default ({ amaId }) => {
  const assignedTo = useContext(UserContext)

  const handleClick = () => {
    track('todo.answerAnonymousQuestion.view', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  return (
    <div className="dashboard-todo">
      <AMA.AnonymousAvatar />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>
          You've been asked an anonymous question
        </div>
        <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
          Ask me anything
        </div>
      </div>
      <Link to="/askMeAnything" onClick={handleClick}>
        <Button type="primary" size="small">
          Answer it now
        </Button>
      </Link>
    </div>
  )
}
