import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Typography, notification } from 'antd'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import slackIcon from '../../_assets/img/integrations/slack.svg'
import { SET_AUTH_METHOD } from '../queries'

const SetAuthMethod = ({ onSubmit }) => {
  const [loading, setLoading] = useState(false)

  const handleSlackButtonClick = () => {
    loginViaUrl('slack')
  }

  const handlePasswordButtonClick = () => {
    loginViaUrl('password')
  }

  const [setAuthMethod] = useMutation(SET_AUTH_METHOD)

  const loginViaUrl = async authMethod => {
    try {
      setLoading(true)
      const response = await setAuthMethod({
        variables: {
          authMethod,
        },
      })
      localStorage.setItem('HAIGToken', response.data.token)
      onSubmit()
    } catch (e) {
      setLoading(false)
      notification.warn({
        message: 'Oops! Something went wrong here',
        description: `Please try again. If you're still having a problem, please contact support`,
      })
    }
  }

  return (
    <div className="onboarding-set-auth-method">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Typography.Title
          level={2}
          style={{
            fontSize: '32px',
            fontWeight: 500,
            marginBottom: '24px',
            textAlign: 'center',
          }}
        >
          Thanks for joining us! Please create your account.
        </Typography.Title>

        <Typography.Paragraph
          style={{
            fontWeight: 300,
            marginBottom: '24px',
            textAlign: 'center',
          }}
        >
          Hopefully you're a human :-) Please confirm by continuing with Slack
          or with your email address.
        </Typography.Paragraph>

        <Button
          type="ghost"
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            lineHeight: '20px',
            width: '100%',
          }}
          onClick={handleSlackButtonClick}
          disabled={loading}
        >
          <img
            alt="Continue with Slack"
            src={slackIcon}
            style={{ height: 24, marginRight: 12 }}
          />
          Continue with Slack
        </Button>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            margin: '0 auto',
            width: '80%',
          }}
        >
          <div
            style={{ borderBottom: '1px solid #e9e8ef', flex: '1 0 0' }}
          ></div>
          <div style={{ color: '#77779f', padding: '24px 20px' }}>or</div>
          <div
            style={{ borderBottom: '1px solid #e9e8ef', flex: '1 0 0' }}
          ></div>
        </div>

        <Button
          type="accent"
          size="large"
          htmlType="submit"
          onClick={handlePasswordButtonClick}
          style={{ width: '100%' }}
          disabled={loading}
        >
          Continue with email
        </Button>
      </Card>
    </div>
  )
}

export default withRouter(SetAuthMethod)
