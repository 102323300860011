import { Icon, Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'

import { isCompletedCycle, isScheduledCycle } from './helpers'

const getTooltipTitle = (date, timezone) =>
  date.tz(timezone).format(`DD MMMM HH:mm [\n${timezone}] (zz Z)`)

const CycleDate = ({ startDate, endDate, timezone }) => {
  const isScheduled = isScheduledCycle(startDate)
  const isCompleted = isCompletedCycle(endDate)

  const tooltipTitle = isScheduled
    ? getTooltipTitle(startDate, timezone)
    : getTooltipTitle(endDate, timezone)

  return (
    <Tooltip title={tooltipTitle} overlayClassName="tooltip--dark">
      {isScheduled ? (
        <span>Starts in: {moment().to(startDate, true)}</span>
      ) : isCompleted ? (
        <span>Ended: {endDate.from(moment.now())}</span>
      ) : (
        <span>Ends in: {moment().to(endDate, true)}</span>
      )}
    </Tooltip>
  )
}

const Title = ({ cycle }) => {
  const startDate = moment(cycle.startDate * 1000)
  const endDate = moment(cycle.endDate * 1000)

  const userTimezone = moment.tz.guess()

  return (
    <div style={styleContainerTitle}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={styleTitleCycle} data-cy="event-name">
          {cycle.name}
        </span>
      </div>
      <div style={styleDateInfo}>
        <span style={{ cursor: 'pointer' }}>
          <Icon style={styleIconClock} type="clock-circle" />
          <CycleDate
            startDate={startDate}
            endDate={endDate}
            timezone={userTimezone}
          />
        </span>
      </div>
    </div>
  )
}

const styleIconClock = { marginRight: 8 }
const styleDateInfo = {
  fontSize: 14,
  color: '#77779f',
  fontFamily: 'Europa',
  fontWeight: 300,
}
const styleTitleCycle = {
  fontWeight: 500,
  fontFamily: 'museo',
  lineHeight: 1.78,
}
const styleContainerTitle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export default Title
