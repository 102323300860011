import { Col, Row, Tooltip } from 'antd'
import React, { useMemo } from 'react'

import AvatarListItem from '../../../_components/AvatarListItem'
import Checkbox from './Checkbox'

const checkboxContainerStyle = { marginLeft: 'auto', paddingRight: 0 }

const ListUser = ({
  user,
  height = 56,
  selected,
  disabled,
  onSelected,
  onDeselected,
  tooltip = null,
  style,
}) => {
  const containerStyle = useMemo(() => {
    return {
      paddingLeft: 24,
      paddingRight: 24,
      height,
      backgroundColor: selected ? 'rgba(218, 218, 229, 0.48)' : 'transparent',
      ...style,
    }
  }, [selected, style])

  return (
    <Row gutter={0} type="flex" align="middle" style={containerStyle}>
      <Col>
        <AvatarListItem user={user} />
      </Col>
      <Col style={checkboxContainerStyle}>
        <Tooltip title={tooltip}>
          <Checkbox
            selected={selected}
            disabled={disabled}
            onSelect={onSelected}
            onDeselect={onDeselected}
            data-cy="select-colleague"
          />
        </Tooltip>
      </Col>
    </Row>
  )
}

export default ListUser
