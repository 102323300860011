import './style.less'

import { Card, Collapse, Empty, Icon } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import React from 'react'

import EmptyTeam from './EmptyTeam'
import PanelBody from './PanelBody'
import PanelHeader from './PanelHeader'

const TeamList = ({ teams }) => {
  const defaultOpenPanels = teams.reduce((memo, x) => {
    if (x.totalMembers === 0) {
      memo.push(x.id)
    }
    return memo
  }, [])

  const noTeam = teams.find(t => t.id === 'no-team')

  return (
    <div className="team-list-v2__container">
      <Card bodyStyle={{ padding: 0 }}>
        {teams.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {teams.length > 0 && (
          <Collapse
            className="team-list-v2__collapse-container"
            bordered={false}
            expandIconPosition="left"
            expandIcon={panelProps => {
              return (
                <Icon
                  type={panelProps.isActive ? 'caret-up' : 'caret-down'}
                  style={{ color: '#a29db4', fontSize: 14 }}
                />
              )
            }}
            defaultActiveKey={defaultOpenPanels}
          >
            {teams
              .filter(team => team.id !== 'no-team')
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(team => {
                const title = team.name
                const count = team.totalMembers
                const canDelete = !team.readonlyProperties
                return (
                  <Collapse.Panel
                    header={
                      <PanelHeader
                        teamId={team.id}
                        title={title}
                        count={count}
                        canDelete={canDelete}
                      />
                    }
                    key={team.id}
                  >
                    {count === 0 && (
                      <EmptyTeam teamId={team.id} teamName={team.name} />
                    )}
                    {count > 0 && <PanelBody teamId={team.id} teams={teams} />}
                  </Collapse.Panel>
                )
              })}
            {noTeam && (
              <CollapsePanel
                header={
                  <PanelHeader title="Not part of a team" count={noTeam.totalMembers} />
                }
              >
                <PanelBody teams={teams} />
              </CollapsePanel>
            )}
          </Collapse>
        )}
      </Card>
    </div>
  )
}

export default TeamList
