import React from 'react'

import PageTitle from '../../../../_components/PageTitle'
import { CYCLE_TYPES } from '../../helpers'

export default ({ cycleType }) => {
  switch (cycleType) {
    case CYCLE_TYPES.personal:
      return <PageTitle>Request Private feedback</PageTitle>

    case CYCLE_TYPES.threeSixty:
      return <PageTitle>360 feedback event setup</PageTitle>

    case CYCLE_TYPES.pulse:
      return <PageTitle>Pulse survey setup</PageTitle>

    default:
      return <></>
  }
}
