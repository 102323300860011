import { Tabs } from 'antd'
import React, { useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'

import PageTitle from '../_components/PageTitle'
import AccountForm from './AccountForm'
import Notifications from './Notifications'

export default () => {
  const accountTabKey = 'account'
  const notificationsTabKey = 'notifications'

  const history = useHistory()

  const isNotificationsPage = useRouteMatch('/account/notifications')

  const [defaultActiveKey] = useState(
    isNotificationsPage ? notificationsTabKey : accountTabKey,
  )

  const handleTabChange = key => {
    switch (key) {
      case accountTabKey:
        history.push('/account')
        break

      case notificationsTabKey:
        history.push('/account/notifications')
        break

      default:
        break
    }
  }

  return (
    <div className="account">
      <PageTitle style={{ marginBottom: '24px' }}>My account</PageTitle>

      <Tabs
        defaultActiveKey={defaultActiveKey}
        onChange={handleTabChange}
        className="ant-tabs--thin"
      >
        <Tabs.TabPane tab="Profile" key={accountTabKey}>
          <AccountForm />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notifications" key={notificationsTabKey}>
          <Notifications />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
