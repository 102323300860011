import './styles.less'

import { useLazyQuery } from '@apollo/react-hooks'
import { Button, Col, Layout, Row, Typography, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import slackIcon from '../_assets/img/integrations/slack.svg'
import logo from '../_assets/img/logo.svg'
import { Intercom } from '../_helpers/analytics'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import { GET_SLACK_SIGN_UP_LINK } from './queries'
import SignUpForm from './SignUpForm'

export default () => {
  const history = useHistory()

  const [loading, setLoading] = useState()

  const basePath = `${document.location.protocol}//${document.location.host}`

  const [getSlackSignUpLink] = useLazyQuery(GET_SLACK_SIGN_UP_LINK, {
    fetchPolicy: 'network-only',
    variables: {
      redirectUrl: `${basePath}/signup/slack`,
    },
    onCompleted: data => {
      if (data && data.signUpWithSlackUrl) {
        window.location.href = data.signUpWithSlackUrl
      }
      setLoading(false)
    },
    onError: error => {
      notification.error({
        message: `That didn't work out as planned`,
        description: `There was an error signing you up. Please try again.`,
      })
      setLoading(false)
    },
  })

  useEffect(() => {
    document.title = 'Sign up | Howamigoing'
    Intercom.update()
  }, [])

  useMobileOptimized(true)

  const signUpFinished = data => {
    history.push('/access?firstLogin=true&t=' + data.signUpOrg)
  }

  const handleSlackButtonClick = async () => {
    setLoading(true)
    getSlackSignUpLink()
  }

  return (
    <Layout id="signup-page">
      <Layout.Content className="signup-content">
        <Row>
          <img
            src={logo}
            alt="Howamigoing"
            style={{
              margin: '0 auto 40px',
              maxHeight: '40px',
              display: 'block',
            }}
          />
        </Row>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ height: '100%', marginBottom: '40px' }}
        >
          <Col
            className="column-wrapper"
            style={{
              backgroundColor: '#F7F7FA',
              borderRadius: '20px',
              display: 'flex',
            }}
          >
            <Row type="flex">
              <Col sm={24} md={24} className="signup-form">
                <Typography.Title
                  level={2}
                  style={{
                    fontSize: '32px',
                    fontWeight: 500,
                    marginBottom: '24px',
                    textAlign: 'center',
                  }}
                >
                  Lovely to see you! Please create your account.
                </Typography.Title>
                <Typography.Paragraph
                  className="signup-paragraph"
                  style={{
                    fontWeight: 300,
                    marginBottom: '24px',
                    textAlign: 'center',
                  }}
                >
                  Hopefully you're a human :-) Please confirm by continuing with
                  Slack or with your email address.
                </Typography.Paragraph>
                <Button
                  type="ghost"
                  size="large"
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    lineHeight: '20px',
                    width: '100%',
                  }}
                  disabled={loading}
                  onClick={handleSlackButtonClick}
                >
                  <img
                    alt="Continue with Slack"
                    src={slackIcon}
                    className={loading ? 'animation--rotate' : ''}
                    style={{ height: 24, marginRight: 12 }}
                  />
                  Continue with Slack
                </Button>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 auto',
                    width: '80%',
                  }}
                >
                  <div
                    style={{ borderBottom: '1px solid #e9e8ef', flex: '1 0 0' }}
                  ></div>
                  <div style={{ color: '#77779f', padding: '24px 20px' }}>
                    or
                  </div>
                  <div
                    style={{ borderBottom: '1px solid #e9e8ef', flex: '1 0 0' }}
                  ></div>
                </div>
                <SignUpForm finishSignUp={signUpFinished} />
              </Col>
            </Row>
          </Col>
          <Col
            sm={24}
            md={22}
            lg={18}
            xl={16}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '40px',
            }}
          >
            <div style={{ marginRight: '8px' }}>Already have an account?</div>
            <Link to="/">
              <Button type="ghost" size="small">
                Sign in
              </Button>
            </Link>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  )
}
