import gql from 'graphql-tag'

export const ASK_ME_ANYTHINGS = gql`
  query askMeAnythings($offset: Int, $limit: Int, $search: AMASearchInput) {
    askMeAnythings(
      offset: $offset
      limit: $limit
      search: $search
    ) {
      total
      amas {
        id
        recipient {
          id
          firstName
          lastName
          avatarUrl
          email
          status
        }
        asker {
          id
        }
        reportedAbuse {
          id
          abuse
          reportedAt
          reporter {
            id
            firstName
            lastName
            status
          }
        }
        visibility
        askedAt
        editedAt
        respondedAt
        question
        answer
        draft {
          answer
          visibility
          lastSyncedAt
        }
      }
    }
  }
`

export const ANSWER_QUESTION = gql`
  mutation($amaId: String!, $answer: String!, $visibility: String!) {
    answerQuestion(amaId: $amaId, answer: $answer, visibility: $visibility) {
      id
      recipient {
        id
        firstName
        lastName
        email
        status
      }
      asker {
        id
        firstName
        lastName
        email
        status
      }
      reportedAbuse {
        id
        abuse
        reportedAt
        reporter {
          id
          firstName
          lastName
          status
        }
      }
      visibility
      askedAt
      respondedAt
      question
      answer
      draft {
        answer
        visibility
        lastSyncedAt
      }
    }
  }
`

export const EDIT_ANSWER = gql`
  mutation($amaId: String!, $answer: String!, $visibility: String!) {
    editAnswer(amaId: $amaId, answer: $answer, visibility: $visibility) {
      id
      recipient {
        id
        firstName
        lastName
        email
        status
      }
      asker {
        id
        firstName
        lastName
        email
        status
      }
      reportedAbuse {
        id
        abuse
        reportedAt
        reporter {
          id
          firstName
          lastName
          email
          status
        }
      }
      visibility
      askedAt
      respondedAt
      question
      answer
      draft {
        answer
        visibility
        lastSyncedAt
      }
    }
  }
`

export const SAVE_DRAFT_ANSWER = gql`
  mutation($amaId: String!, $answer: String!, $visibility: String!) {
    saveDraftAnswer(amaId: $amaId, answer: $answer, visibility: $visibility) {
      id
      recipient {
        id
        firstName
        lastName
        email
        status
      }
      asker {
        id
        firstName
        lastName
        email
        status
      }
      reportedAbuse {
        id
        abuse
        reportedAt
        reporter {
          id
          firstName
          lastName
          email
          status
        }
      }
      visibility
      askedAt
      respondedAt
      question
      answer
      draft {
        answer
        visibility
        lastSyncedAt
      }
    }
  }
`

export const DELETE_ANSWER = gql`
  mutation($amaId: String!) {
    deleteAnswer(amaId: $amaId) {
      id
      recipient {
        id
        firstName
        lastName
        email
        status
      }
      asker {
        id
        firstName
        lastName
        email
        status
      }
      reportedAbuse {
        id
        abuse
        reportedAt
        reporter {
          id
          firstName
          lastName
          email
          status
        }
      }
      visibility
      askedAt
      respondedAt
      question
      answer
      draft {
        answer
        visibility
        lastSyncedAt
      }
    }
  }
`

export const DECLINE_QUESTION = gql`
  mutation($amaId: String!) {
    declineQuestion(amaId: $amaId) {
      id
      recipient {
        id
        firstName
        lastName
        email
        status
      }
      asker {
        id
        firstName
        lastName
        email
        status
      }
      reportedAbuse {
        id
        abuse
        reportedAt
        reporter {
          id
          firstName
          lastName
          email
          status
        }
      }
      visibility
      askedAt
      respondedAt
      question
      answer
      draft {
        answer
        visibility
        lastSyncedAt
      }
    }
  }
`

export const DELETE_QUESTION = gql`
  mutation($amaId: String!) {
    deleteQuestion(amaId: $amaId)
  }
`

export const TOTAL_AMAS_COUNT = gql`
  mutation {
    totalAmasCount
  }
`

export const REPORT_ABUSE = gql`
  mutation($amaId: String!, $abuse: String!) {
    reportQuestionAbuse(amaId: $amaId, abuse: $abuse)
  }
`

export const ASK_QUESTION = gql`
  mutation($recipientId: String!, $question: String!) {
    askQuestion(recipientId: $recipientId, question: $question) {
      id
      recipient {
        id
        firstName
        lastName
        email
        status
      }
      asker {
        id
        firstName
        lastName
        email
        status
      }
      reportedAbuse {
        id
        abuse
        reportedAt
        reporter {
          id
          firstName
          lastName
          email
          status
        }
      }
      visibility
      askedAt
      respondedAt
      question
      answer
      draft {
        answer
        visibility
        lastSyncedAt
      }
    }
  }
`
