import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import { Intercom } from '../_helpers/analytics'
import Account from '../Account'
import Admin from '../Admin'
import AdminCompany from '../Admin/Company/EditCompany'
import AdminInsights from '../Admin/Insights'
import PeopleList from '../Admin/Insights/PeopleList'
import UserProfile from '../Admin/Insights/UserProfile'
import AdminIntegrationsPersonio from '../Admin/Integrations/Personio'
import AdminIntegrationsSlack from '../Admin/Integrations/Slack'
import AdminPeople from '../Admin/People'
import AdminTeams from '../Admin/Teams'
import Dashboard from '../Dashboard'
import {
  Personal360Routes,
  PulseRoutes,
  Team360Routes,
} from '../Feedback/pages/routes'
import { AMARoutes } from '../AskMeAnything/routes'
import GoodVibes from '../GoodVibes'
import { UserContext } from '../UserContext'
import Welcome from '../Welcome'
import AppRoute from './AppRoute'

export const RouteWithSubRoutes = route => {
  return (
    <AppRoute
      path={route.path}
      render={props => {
        return (
          <route.component {...props} menu={route.menu} routes={route.routes} />
        )
      }}
    />
  )
}

const AppRoutes = () => {
  const user = useContext(UserContext)

  const history = useHistory()

  useEffect(() => {
    return history.listen(() => {
      Intercom.update()
    })
  }, [history])

  return (
    <>
      <Switch>
        <RouteWithSubRoutes key={'feedback'} {...Team360Routes} />

        <RouteWithSubRoutes key={'personal'} {...Personal360Routes} />

        <RouteWithSubRoutes key={'pulse'} {...PulseRoutes} />

        <RouteWithSubRoutes key={'askmeanything'} {...AMARoutes} />

        <AppRoute
          path="/goodVibes"
          render={props => <GoodVibes.Router basePath={props.match.path} />}
        />

        <AppRoute path="/account" exact component={Account} />

        <AppRoute path="/account/notifications" component={Account} />

        <AppRoute path="/profile" exact component={UserProfile} />

        <AppRoute path="/profile/:id" component={UserProfile} />

        <AppRoute path="/activity" component={PeopleList} />

        <AppRoute path="/admin" exact component={Admin} />

        <AppRoute path="/admin/insights" component={AdminInsights} />

        <AppRoute path="/admin/people" component={AdminPeople} />

        <AppRoute path="/admin/teams" component={AdminTeams} />

        <AppRoute path="/admin/company" component={AdminCompany} />

        <AppRoute
          path="/admin/integrations/slack"
          component={AdminIntegrationsSlack}
        />
        <AppRoute
          path="/admin/integrations/personio"
          component={AdminIntegrationsPersonio}
        />

        <AppRoute path="/welcome" component={Welcome} />

        <AppRoute path="/home" exact component={Dashboard} />

        <Route exact path="/">
          {user.isAuthenticated ? (
            <Redirect to="/home" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>

        {/* TODO: Replace the contents of this route with a 404 page */}
        <Route>
          {user.isAuthenticated ? (
            <Redirect to="/home" />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
      </Switch>
    </>
  )
}

export default AppRoutes
