import { Button, Icon, Tooltip } from 'antd'
import React from 'react'
import { useLocation } from 'react-router-dom'

export default ({ review, reviews, teams, style }) => {
  const location = useLocation()

  const isExampleFeedback = location.search && location.search === '?example=1'

  let reviewIds = []
  let teamIds = []

  if (teams){
    teamIds = teams.map(t => t)
  }

  if (review) {
    reviewIds = [review.id]
  } else if (reviews && Array.isArray(reviews)) {
    reviewIds = reviews.map(r => r.id)
  }

  const openPDF = () => {
    // TODO replace for environment variable
    window.open(
      `https://app.howamigoing.com/api/reports/feedback?token=${
        localStorage['HAIGToken']
      }&reviews=${reviewIds.join(',')}&teamIds=${teamIds.join(',')}`,
      '_blank',
    )
  }

  const ButtonIcon = () => {
    return (
      <Icon
        type="file-pdf"
        style={{
          color: '#6b6b8f',
          fontSize: 12,
          marginRight: -2,
          position: 'relative',
          top: '-1px',
        }}
      />
    )
  }

  if (reviewIds.length > 0) {
    if (isExampleFeedback) {
      return (
        <Tooltip
          title="Sorry! You can't download Julian's feedback but you'll be able to download your own :-)"
          placement="bottom"
          overlayClassName="tooltip--dark tooltip--dark-big"
        >
          <Button
            type="ghost"
            size="small"
            style={{
              borderColor: 'rgba(119, 119, 159, 0.5)',
              color: '#77779f',
              fontSize: 14,
              ...style,
            }}
            disabled
          >
            <ButtonIcon />
            Download PDF
          </Button>
        </Tooltip>
      )
    } else {
      return (
        <Button
          type="ghost"
          size="small"
          onClick={openPDF}
          style={{
            borderColor: 'rgba(119, 119, 159, 0.5)',
            color: '#77779f',
            fontSize: 14,
            ...style,
          }}
        >
          <ButtonIcon />
          {reviewIds.length > 1 ? 'Download aggregated PDF' : 'Download PDF'}
        </Button>
      )
    }
  } else {
    return <></>
  }
}
