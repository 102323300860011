import '../ResetPassword/style.less'

import { Button, Card, Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import Logo from '../_assets/img/logo.svg'
import useMobileOptimized from '../hooks/device/useMobileOptimized'

export default () => {
  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useMobileOptimized(true)

  return (
    <Row
      type="flex"
      justify="center"
      align="top"
      style={{ minHeight: '100vh' }}
      className="screen-container"
    >
      <Col span={7} className="col-login">
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>
        <Card className="screen-container-card">
          <div
            className="font-secondary color-primary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 12,
            }}
          >
            That’s it!
          </div>
          <div
            className="color-primary"
            style={{ fontWeight: 300, marginBottom: 24 }}
          >
            Your password has been changed.
          </div>
          <Link to={'/login'}>
            <Button type="accent" size="large">
              Log in here
            </Button>
          </Link>
        </Card>
      </Col>
    </Row>
  )
}
