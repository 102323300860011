import { useLazyQuery } from '@apollo/react-hooks'
import { Icon, Select, Spin } from 'antd'
import { isArray, uniqBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import UserDisplayName from '../../UserDisplayName'
import { SEARCH_USERS } from './queries'

const AutocompleteUser = React.forwardRef(
  (
    {
      allowMultiple,
      onSelect,
      onChange,
      style,
      withShadow,
      exclusionRule = () => false,
      disableRule = () => false,
      userTypes = ['invited', 'onboarding', 'standard', 'admin', 'owner'],
      value,
      maxResults = 10,
      disabled,
      renderOption,
      placeholder = (
        <div>
          <Icon type="search" /> Search by name or email
        </div>
      ),
    },
    ref,
  ) => {
    const userCache = useRef({})
    const [searchedUsers, setSearchedUsers] = useState()
    const [fetching, setFetching] = useState(false)

    const [debouncedSearchChange] = useDebouncedCallback(val => {
      onSearchChange(val)
    }, 500)

    const [fetchUsers] = useLazyQuery(SEARCH_USERS, {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        setFetching(false)
        if (data.users && data.users.users) {
          data.users.users.forEach(user => {
            userCache.current[user.id] = user
          })
          const filteredUsers = data.users.users.filter(
            user => !exclusionRule(user),
          )
          const disabledUsers = Object.keys(userCache.current)
            .map(id => userCache.current[id])
            .filter(disableRule)
          setSearchedUsers(
            uniqBy([...filteredUsers, ...disabledUsers], user => user.id),
          )
        }
      },
    })

    useEffect(() => {
      if (value) {
        const users = isArray(value) ? value : [value]
        users.forEach(user => {
          userCache.current[user.id] = user
        })
        setSearchedUsers(users)
      }
    }, [value])

    const onSearchChange = val => {
      const newSearch = {
        name: val,
        email: val,
        types: userTypes,
      }
      setFetching(true)
      fetchUsers({
        variables: {
          limit: maxResults,
          search: newSearch,
        },
      })
    }

    const handleChange = val => {
      // Add to in memory cache
      searchedUsers
        .filter(x =>
          isArray(val) ? !!val.find(y => y === x.id) : x.id === val,
        )
        .forEach(user => {
          userCache.current[user.id] = user
        })
      const users = allowMultiple
        ? val.map(id => userCache.current[id])
        : userCache.current[val]

      if (typeof onSelect === 'function') {
        onSelect(users)
      }
      if (typeof onChange === 'function') {
        onChange(users)
      }
    }

    const handleBlur = () => {
      setSearchedUsers(allowMultiple ? value : [])
    }
    const getValue = () => {
      if (allowMultiple) {
        return value ? value.map(x => x.id) : []
      }
      return value ? value.id : undefined
    }

    return (
      <div style={style}>
        <Select
          className={`update-people-autocomplete ${
            withShadow ? 'ant-select--with-shadow' : ''
          }`}
          mode={allowMultiple ? 'multiple' : 'default'}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          placeholder={placeholder}
          filterOption={false}
          onSearch={debouncedSearchChange}
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ width: '100%' }}
          showSearch={!allowMultiple}
          showArrow={false}
          allowClear={!allowMultiple}
          clearIcon={<Icon type="close-circle" />}
          ref={ref}
          value={getValue()}
          disabled={disabled}
          optionLabelProp="label"
        >
          {searchedUsers &&
            searchedUsers.map(u => {
              return (
                <Select.Option
                  key={u.id}
                  value={u.id}
                  label={u.displayName || u.email}
                  disabled={disableRule(u)}
                >
                  {renderOption ? (
                    renderOption(u)
                  ) : (
                    <UserDisplayName user={u} />
                  )}
                </Select.Option>
              )
            })}
        </Select>
      </div>
    )
  },
)

export default AutocompleteUser
