import gql from 'graphql-tag'

export const CREATE_TEAM = gql`
  mutation createTeam($teamName: String!) {
    createTeam(teamName: $teamName) {
      id
      name
    }
  }
`

export const ADD_TEAM_MEMBERS = gql`
  mutation addTeamMembers($teamId: String!, $userIds: [String!]!) {
    addTeamMembers(addTeamMembersData: { teamId: $teamId, userIds: $userIds })
  }
`
