import './styles.less'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { AutoSaveContext } from './index'

// Component used as a prop in the HOC to show a "Changes saved" badge
// whenever drafts are saved
const AutoSaveIndicator = ({ visible }) => {
  return (
    <div className={`autosave-indicator ${visible ? 'visible' : ''}`}>
      <FontAwesomeIcon
        icon={{ iconName: 'check-circle', prefix: 'fas' }}
        style={{ fontSize: '14px' }}
      />
      <span>Feedback saved automatically</span>
    </div>
  )
}

export default Component =>
  ({ children, ...props }) => {
    const autoSaveContext = useContext(AutoSaveContext)
    const [autoSaveIndicatorVisible, setAutoSaveIndicatorVisible] =
      useState(false)

    // We override whatever onChange the component had set
    // and execute ours first. Afterwards, if there is any
    // from the component itself, we run it too
    const handleOnChange = e => {
      if (autoSaveContext.enabled) {
        let value = e.value
        autoSaveContext.syncField(props.question.formQuestionId, value)
      }

      if (props.onChange) {
        props.onChange(e)
      }

      setAutoSaveIndicatorVisible(true)
    }

    // Hide the "Changes saved" indicator with a debounce
    // to avoid a crazy animation
    const [hideIndicator] = useDebouncedCallback(() => {
      setAutoSaveIndicatorVisible(false)
    }, 2500)

    useEffect(() => {
      if (autoSaveIndicatorVisible) {
        hideIndicator()
      }
    }, [autoSaveIndicatorVisible])

    return (
      <Component
        {...props}
        onChange={handleOnChange}
        autoSaveIndicator={
          <AutoSaveIndicator visible={autoSaveIndicatorVisible} />
        }
        fields={autoSaveContext.fields}
      >
        {children}
      </Component>
    )
  }
