import AvatarPlusList from '../../../_components/AvatarPlusList'
import { PraisedIcon } from './Icons'
import React from 'react'
import uniqBy from 'lodash/uniqBy'

const supportedIcons = {
  raised_hands: <PraisedIcon />,
}

const isSupportedReaction = reaction => !!supportedIcons[reaction.type]
const groupByReactionType = (memo, reaction) => {
  if (!memo[reaction.type]) {
    memo[reaction.type] = []
  }
  memo[reaction.type].push(reaction)
  return memo
}

const Counter = ({ reactions }) => {
  const reactionsByType = reactions
    ? reactions.filter(isSupportedReaction).reduce(groupByReactionType, {})
    : []

  const reactionTypes = Object.keys(reactionsByType)
  const reactors = uniqBy(
    reactions.map(x => x.author),
    x => x.id,
  )

  return (
    <div className="reaction__counter-container">
      {reactionTypes.length === 1 && (
        <>
          <div key={reactionTypes[0]} className="reaction__counter-icon">
            {supportedIcons[reactionTypes[0]]}
          </div>
          <div className="reaction__counter-avatar-list-container">
            <AvatarPlusList users={reactors} limit={3} />
          </div>
        </>
      )}
      {reactionTypes.length > 1
        ? reactionTypes.map(reactionType => (
            <div key={reactionType} className="reaction__counter-icon">
              {supportedIcons[reactionType]}
            </div>
          ))
        : null}
    </div>
  )
}

export default Counter
