import { CYCLE_TYPES } from '../../../helpers'

export default (step, context) => {
  switch (context.type) {
    case CYCLE_TYPES.personal:
    case CYCLE_TYPES.pulse:
      return (
        (step > 0 && context.form === null) ||
        (step > 1 && context.selection.total < 2) ||
        (step > 2 &&
          (context.name === '' ||
            context.startDate === null ||
            context.endDate === null ||
            context.timezone === null))
      )

    case CYCLE_TYPES.threeSixty:
      return (
        (step > 0 && context.form === null) ||
        (step > 1 &&
          (context.selection.total < 1 ||
            (context.maxRequests !== null &&
              (context.maxRequests < 2 ||
                context.minRequests > context.maxRequests ||
                context.minRequests < 2)))) ||
        (step > 2 &&
          (!context.name ||
            !context.startDate ||
            !context.endDate ||
            !context.timezone))
      )

    default:
      return true
  }
}
