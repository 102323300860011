import { Button, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import Text from 'react-texty'

import Overview from '../../../../Feedback/modals/Overview/team360'
import { Label } from '../../Table'

const ViewEvent = ({ data }) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      {showModal && (
        <Overview
          cycleId={data.cycle.id}
          showModal={showModal}
          handleCloseModal={() => setShowModal(false)}
        />
      )}
      <Button
        type="link"
        style={{ color: '#281765', textDecoration: 'underline' }}
        onClick={() => setShowModal(true)}
      >
        View event
      </Button>
    </div>
  )
}

export default [
  {
    title: 'Start date',
    key: 'date',
    width: '13%',
    render: data => (
      <div style={{ paddingLeft: '24px' }}>
        {moment.unix(data.cycle.startDate).format('DD MMM YY')}
      </div>
    ),
  },
  {
    title: 'Event name',
    key: 'name',
    width: '26%',
    render: data => (
      <Label>
        <Text tooltip={data.cycle.name}>{data.cycle.name}</Text>
      </Label>
    ),
  },
  {
    title: (
      <Tooltip title="Number of people asked to participate in this event">
        <span style={{ cursor: 'help' }}>People enrolled</span>
      </Tooltip>
    ),
    key: 'asked',
    width: '18%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_event_people_enrolled',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of people who received 2+ responses (so their feedback was released)">
        <span style={{ cursor: 'help' }}>People with feedback</span>
      </Tooltip>
    ),
    key: 'responded',
    width: '18%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_feedback_event_people_with_feedback',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of people who received feedback as a percentage of those who asked for feedback ">
        <span style={{ cursor: 'help' }}>Success rate</span>
      </Tooltip>
    ),
    key: 'score',
    width: '15%',
    render: data => {
      if (data && data.stats) {
        if (
          moment.unix(data.cycle.startDate).isBefore(moment()) &&
          moment.unix(data.cycle.endDate).isAfter(moment())
        ) {
          return 'Event in progress'
        }

        const stat = data.stats.find(
          x => x.key === 'three_sixty_event_success_rate',
        )

        const receivedFeedbackStat = data.stats.find(
          x => x.key === 'three_sixty_feedback_event_people_with_feedback',
        )

        if (receivedFeedbackStat && receivedFeedbackStat.value === 0) {
          return (
            <Tooltip title="Not enough people responded -- 2+ responses are required so we can remove names from comments to preserve anonymity">
              <span style={{ cursor: 'help', color: 'rgb(249, 92, 75)' }}>
                {Math.floor(stat.value * 100)}%
              </span>
            </Tooltip>
          )
        }

        return (stat ? Math.floor(stat.value * 100) : 0) + '%'
      }
      return '-'
    },
  },
  {
    title: '',
    key: 'view',
    width: '10%',
    render: data => <ViewEvent data={data} />,
  },
]
