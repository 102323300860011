import gql from 'graphql-tag'

export const COMPANY_PRIVATE_FEEDBACK = gql`
  query companyPrivateFeedbackInsight($startDate: String, $endDate: String) {
    companyPrivateFeedbackInsight(startDate: $startDate, endDate: $endDate) {
      id
      key
      value
    }
  }
`
