import gql from 'graphql-tag'

export const ASK_QUESTION = gql`
  mutation($recipientId: String!, $question: String!) {
    askQuestion(recipientId: $recipientId, question: $question) {
      id
      recipient {
        id
        firstName
        lastName
        email
      }
      asker {
        id
        firstName
        lastName
        email
      }
      reportedAbuse {
        id
        abuse
        reportedAt
        reporter {
          id
          firstName
          lastName
          email
        }
      }
      visibility
      askedAt
      respondedAt
      question
      answer
    }
  }
`

export const NUM_USERS = gql`
  query numUsers {
    users(offset: 0, limit: 0) {
      total
    }
  }
`
