import React from 'react'
import Todo from './Todo'

export default ({ title, icon, todos }) => {
  return (
    <div className="dashboard-todo-group">
      <div
        style={{
          color: '#77779f',
          display: 'flex',
          fontSize: '14px',
          fontWeight: 300,
          lineHeight: '16px',
          marginBottom: '24px',
        }}
      >
        {icon ? (
          <span style={{ marginRight: 8, display: 'flex' }}>{icon}</span>
        ) : null}
        {title ? `${title} ` : null}
      </div>
      {todos.map(todo => (
        <Todo todo={todo} key={todo.id} />
      ))}
    </div>
  )
}
