import { Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import Text from 'react-texty'

import { Label } from '../../../Table'

export default [
  {
    title: 'Start date',
    key: 'date',
    width: '13.5%',
    render: data => (
      <div style={{ paddingLeft: '24px' }}>
        {moment.unix(data.cycle.startDate).format('DD MMM YY')}
      </div>
    ),
  },
  {
    title: 'Event name',
    key: 'name',
    width: '14.5%',
    render: data => (
      <Label>
        <Text tooltip={data.cycle.name}>{data.cycle.name}</Text>
      </Label>
    ),
  },
  {
    title: (
      <Tooltip title="Number of others this person asked for feedback across all their 360 events">
        <span style={{ cursor: 'help' }}>People asked</span>
      </Tooltip>
    ),
    key: 'sent',
    width: '14.5%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_user_people_asked',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of responses to this person's feedback requests across all their 360 events">
        <span style={{ cursor: 'help' }}>People responded</span>
      </Tooltip>
    ),
    key: 'receivedFeedback',
    width: '14.5%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_user_people_responded',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of 360 feedback requests this person received from others">
        <span style={{ cursor: 'help' }}>Req. received</span>
      </Tooltip>
    ),
    key: 'receivedRequest',
    width: '14.5%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_user_requests_received',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of 360 feedback requests this person responded to">
        <span style={{ cursor: 'help' }}>Req. answered</span>
      </Tooltip>
    ),
    key: 'givenFeedback',
    width: '14.5%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_user_requests_answered',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of 360 feedback requests answered as a percentage of those received">
        <span style={{ cursor: 'help' }}>Response rate</span>
      </Tooltip>
    ),
    key: 'rate',
    width: '14%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_giver_user_response_rate',
        )
        return (stat ? Math.floor(stat.value * 100) : 0) + '%'
      }
      return '0%'
    },
  },
]
