import { useMutation } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useState } from 'react'

import ExclamationCircleIcon from '../../../../_assets/img/exclamation-circle.png'
import AutocompleteUser from '../../../../_components/Form/AutocompleteUser'
import PrintUsers from '../../../components/PrintUsers'
import { CHANGE_USERS_MANAGER } from '../queries'
import UsersStatus from './UsersStatus'

export default props => {
  const [manager, setManager] = useState()
  const [loading, setLoading] = useState(false)
  const [okEnabled, setOkEnabled] = useState(false)

  const [changeUsersManager] = useMutation(CHANGE_USERS_MANAGER, {
    refetchQueries: ['listPeopleUsers', 'peopleListStats'],
  })

  const handleSubmit = async () => {
    try {
      setOkEnabled(false)
      setLoading(true)
      await changeUsersManager({
        variables: {
          ids: props.users.map(x => x.id),
          managerId: manager.id,
        },
      })
      setLoading(false)
      props.onOk()
      setManager()
    } catch (e) {
      setOkEnabled(true)
      setLoading(false)
    }
  }

  const handleChange = val => {
    setManager(val)
    setOkEnabled(!!val)
  }

  const handleCancel = () => {
    setManager()
    setOkEnabled(false)
    props.onCancel()
  }

  const canUpdateManager = user => {
    if (!user.readonlyProperties) {
      return true
    }
    return !user.readonlyProperties.find(x => x.name === 'manager')
  }
  const usersWithReadonlyManagerProperty = props.users.filter(
    x => !canUpdateManager(x),
  )

  return (
    <Modal
      title="Update manager"
      visible={props.visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !okEnabled,
        type: 'accent',
      }}
      confirmLoading={loading}
      okText="Update manager"
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      closable={false}
      maskClosable={!loading}
      bodyStyle={{ padding: '32px 32px 0' }}
      width={584}
      className="update-manager-modal"
      destroyOnClose
      centered
    >
      <UsersStatus users={props.users} />

      <div style={{ fontWeight: 500, marginBottom: 8 }}>Update manager to:</div>
      <AutocompleteUser
        onSelect={handleChange}
        style={{ marginBottom: 32 }}
        withShadow
        value={manager}
      />

      {usersWithReadonlyManagerProperty.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <PrintUsers users={usersWithReadonlyManagerProperty} />
          won't be updated because they're integrated with Personio.
        </div>
      )}

      <div style={{ display: 'flex', marginBottom: 32 }}>
        <div style={{ flex: '0 0 16px' }}>
          <img
            src={ExclamationCircleIcon}
            alt="Exclamation mark"
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div style={{ fontSize: '14px', lineHeight: '22px', paddingLeft: 8 }}>
          Managers can view their direct report(s) 360 feedback and are
          automatically included in their direct report(s) feedback requests.
          <br />
          You can assign one Manager per user.
        </div>
      </div>
    </Modal>
  )
}
