import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar } from 'antd'
import React from 'react'

export default () => {
  const icon = (
    <FontAwesomeIcon icon={['fal', 'users']} style={{ width: '1em' }} />
  )
  return (
    <Avatar
      icon={icon}
      size={48}
      style={{
        background: 'linear-gradient(135deg, #695d93, #281765)',
        borderWidth: '3px',
        lineHeight: '44px',
      }}
    />
  )
}
