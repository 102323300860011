import { Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import Text from 'react-texty'

import { Label } from '../../../Table'

export default [
  {
    title: 'Start date',
    key: 'date',
    width: '13%',
    render: data => (
      <div style={{ paddingLeft: '24px' }}>
        {moment.unix(data.cycle.startDate).format('DD MMM YY')}
      </div>
    ),
  },
  {
    title: 'Event name',
    key: 'name',
    width: '22.5%',
    render: data => (
      <Label>
        <Text tooltip={data.cycle.name}>{data.cycle.name}</Text>
      </Label>
    ),
  },
  {
    title: (
      <Tooltip title="Number of people asked to give feedback">
        <span style={{ cursor: 'help' }}>People asked</span>
      </Tooltip>
    ),
    key: 'asked',
    width: '13%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'private_feedback_user_people_asked',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of people who have given feedback">
        <span style={{ cursor: 'help' }}>People responded</span>
      </Tooltip>
    ),
    key: 'responded',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'private_feedback_user_people_responded',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of people who responded as a percentage of those asked to give feedback">
        <span style={{ cursor: 'help' }}>Response rate</span>
      </Tooltip>
    ),
    key: 'score',
    width: '15%',
    render: data => {
      if (data && data.stats) {
        if (
          moment.unix(data.cycle.startDate).isBefore(moment()) &&
          moment.unix(data.cycle.endDate).isAfter(moment())
        ) {
          return 'Event in progress'
        }

        const stat = data.stats.find(
          x => x.key === 'private_feedback_giver_user_response_rate',
        )

        const peopleRespondedStat = data.stats.find(
          x => x.key === 'private_feedback_user_people_responded',
        )

        if (peopleRespondedStat && peopleRespondedStat.value < 2) {
          return (
            <Tooltip title="Not enough people responded -- 2+ responses are required so we can remove names from comments to preserve anonymity ">
              <span style={{ cursor: 'help', color: 'rgb(249, 92, 75)' }}>
                {Math.floor(stat.value * 100)}%
              </span>
            </Tooltip>
          )
        }

        return (stat ? Math.floor(stat.value * 100) : 0) + '%'
      }
      return '-'
    },
  },
]
