import { Button, Form } from 'antd'
import React, { useState } from 'react'

import Avatar from '../../../_components/Avatar'
import Input from './Input'

const CommentForm = ({
  author,
  placeholder = 'Add a comment',
  onSubmit,
  onCancel,
  onFocus,
  form,
  initialValue = '',
  submitLabel = 'Submit',
  cancelLabel = 'Cancel',
  required,
}) => {
  const [submitting, setSubmitting] = useState(false)

  const handleOnSubmit = evt => {
    evt.preventDefault()
    setSubmitting(true)
    form.validateFields(async (err, values) => {
      if (err) {
        setSubmitting(false)
        return
      }
      try {
        if (onSubmit) {
          await onSubmit(values.value)
        }
        setSubmitting(false)
        form.resetFields()
      } catch (e) {
        setSubmitting(false)
      }
    })
  }

  const handleOnCancel = () => {
    form.resetFields()
    if (onCancel) {
      onCancel()
    }
  }

  const value = form.getFieldValue('value') || initialValue
  const showButtons = value && value.length > 0

  return (
    <Form
      onSubmit={handleOnSubmit}
      className="comment__form-container"
      style={showButtons ? { marginBottom: '20px' } : undefined}
    >
      {author && (
        <div className="comment__form-author">
          <Avatar user={author} />
        </div>
      )}
      <div className="comment__form-value">
        <Input
          name="value"
          placeholder={placeholder}
          form={form}
          initialValue={initialValue}
          onFocus={onFocus}
          max={280}
          disabled={submitting}
          required={required}
        />
        {showButtons && (
          <div className="comment__form-buttons-container">
            <Button
              className="comment__form-button"
              type="ghost"
              size="small"
              onClick={handleOnCancel}
              disabled={submitting}
            >
              {cancelLabel}
            </Button>
            <Button
              className="comment__form-button"
              type="primary"
              size="small"
              htmlType="submit"
              loading={submitting}
            >
              {submitLabel}
            </Button>
          </div>
        )}
      </div>
    </Form>
  )
}

export default Form.create({ name: 'CommentForm' })(CommentForm)
