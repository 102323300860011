import { faFileCsv, faFilePdf } from '@fortawesome/pro-light-svg-icons'
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu, Tooltip } from 'antd'
import axios from 'axios'
import React from 'react'
import { useLocation } from 'react-router-dom'

export default ({
  review,
  reviews,
  style,
  setShowDownloadingModal,
  teamsArray,
}) => {
  const location = useLocation()

  const isExampleFeedback = location.search && location.search === '?example=1'

  let reviewIds = []

  if (review) {
    reviewIds = [review.id]
  } else if (reviews && Array.isArray(reviews)) {
    reviewIds = reviews.map(r => r.id)
  }

  const onHandleDownload = item => {
    setShowDownloadingModal(true)
    const url = `${process.env.REACT_APP_BASE_URL}/api/reports/feedback?token=${
      localStorage['HAIGToken']
    }&reviews=${reviewIds.join(',')}&teams=${teamsArray.join(
      ',',
    )}&downloadType=${item.key}`

    if (item.key === 'pdf') {
      window.open(url, '_blank')
      setTimeout(function () {
        setShowDownloadingModal(false)
      }, 1500)
    } else {
      const url = `${
        process.env.REACT_APP_BASE_URL
      }/api/reports/feedback-feedback?token=${
        localStorage['HAIGToken']
      }&reviewIds=${reviewIds.join(',')}&teams=${teamsArray.join(
        ',',
      )}&downloadType=${item.key}`
      const headers = {
        headers: { Authorization: localStorage['HAIGToken'] },
        responseType: 'arraybuffer',
      }
      axios.get(`${url}`, headers).then(response => {
        setShowDownloadingModal(false)
        const buf = new Uint8Array(response.data).buffer
        const buftype =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        const blobFile = new Blob([buf], { type: buftype })

        const link = document.createElement('a')
        document.body.appendChild(link)
        var url = window.URL.createObjectURL(blobFile)

        link.setAttribute('href', url)
        const startDate = new Date(review.cycle.startDate * 1000)
        const month = startDate.getMonth()
        link.setAttribute(
          'download',
          `${startDate.getFullYear()}.${month + 1 < 10 ? '0' : ''}${
            month + 1
          } ${review.cycle.name}.csv`,
        )
        link.click()
        setShowDownloadingModal(false)
      })
    }
  }

  const iconsStyle = { color: '#77779f', fontSize: 13, marginRight: '0.4rem' }

  if (reviewIds.length > 0) {
    if (isExampleFeedback) {
      return (
        <Tooltip
          title="Sorry! You can't download Julian's feedback but you'll be able to download your own :-)"
          placement="bottom"
          overlayClassName="tooltip--dark tooltip--dark-big"
        >
          <Button
            type="ghost"
            size="small"
            style={{
              borderColor: 'rgba(119, 119, 159, 0.5)',
              color: '#77779f',
              fontSize: 14,
              ...style,
            }}
            disabled
          >
            <FontAwesomeIcon icon={faArrowToBottom} style={iconsStyle} />
            Download data
          </Button>
        </Tooltip>
      )
    } else {
      return (
        <div className="multi-teams">
          <Dropdown.Button
            className="dropdown-btn"
            icon={
              <FontAwesomeIcon
                icon={['fal', 'chevron-down']}
                style={{ fontSize: 10 }}
              />
            }
            overlay={
              <Menu>
                <Menu.Item onClick={onHandleDownload} key="pdf" value="pdf">
                  <FontAwesomeIcon icon={faFilePdf} style={iconsStyle} />
                  Download PDF
                </Menu.Item>
                <Menu.Item onClick={onHandleDownload} key="csv" value="csv">
                  <FontAwesomeIcon
                    icon={faFileCsv}
                    style={{ ...iconsStyle, fontSize: 14 }}
                  />
                  Download CSV
                </Menu.Item>
              </Menu>
            }
          >
            <div style={{ color: '#77779f' }}>
              <FontAwesomeIcon icon={faArrowToBottom} style={iconsStyle} />
              <span>Download data</span>
            </div>
          </Dropdown.Button>
        </div>
      )
    }
  } else {
    return <></>
  }
}
