import React, { useContext, useState } from 'react'

import Comment from '../Comment'
import CommentAction from './CommentAction'
import PraiseAction from './PraiseAction'
import { UserContext } from '../../../UserContext'

const Footer = ({
  comments,
  commentsVisible,
  reactions,
  onReactionAdd,
  onReactionDelete,
  onCommentAdd,
  onCommentEdit,
  onCommentDelete,
  onCommentReportAbuse,
  onCommentActionClick,
}) => {
  const currentUser = useContext(UserContext)

  const [showAddCommentButtons, setShowAddCommentButtons] = useState(false)
  const [submittingPraise, setSubmittingPraise] = useState(false)

  const handleOnAddCommentFormSubmit = async value => {
    if (onCommentAdd) {
      await onCommentAdd(value)
    }
    setShowAddCommentButtons(false)
  }

  const currentUserReaction = reactions
    ? reactions.find(x => x.author.id === currentUser.id)
    : undefined

  const handleOnPraiseActionClick = async () => {
    try {
      if (submittingPraise) {
        return
      }
      setSubmittingPraise(true)
      if (currentUserReaction) {
        await onReactionDelete({
          id: currentUserReaction.id,
          type: 'raised_hands',
        })
      } else {
        await onReactionAdd({
          type: 'raised_hands',
        })
      }
      setSubmittingPraise(false)
    } catch (e) {
      setSubmittingPraise(false)
    }
  }

  const handleCommentActionClick = () => {
    if (onCommentActionClick) {
      onCommentActionClick()
    }
  }

  return (
    <div className="good-vibe-card__footer-container">
      <div className="good-vibe-card__footer-actions">
        <PraiseAction
          pressed={!!currentUserReaction}
          onClick={handleOnPraiseActionClick}
          disabled={submittingPraise}
        />
        <CommentAction
          pressed={commentsVisible}
          onClick={handleCommentActionClick}
        />
      </div>
      {commentsVisible && (
        <div className="good-vibe-card__footer-comments-container">
          <div className="good-vibe-card__footer-add-comment-form-container">
            <Comment.Form
              author={currentUser}
              onSubmit={handleOnAddCommentFormSubmit}
              onCancel={() => setShowAddCommentButtons(false)}
              submitLabel="Send"
              showButtons={showAddCommentButtons}
              onFocus={() => setShowAddCommentButtons(true)}
            />
          </div>

          <div className="good-vibe-card__footer-comments">
            <Comment.List
              comments={comments}
              onEdit={onCommentEdit}
              onDelete={onCommentDelete}
              onReportAbuse={onCommentReportAbuse}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default Footer
