import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getSubdomain } from 'tldts'

import AppLoading from '../AppLoading'
import useQuery from '../hooks/routing/useQuery'

export default () => {
  const history = useHistory()
  const queryParams = useQuery()
  const redirectPath = queryParams.get('path')
  const redirectUrl = redirectPath ? redirectPath : '/welcome'

  const path = '/api/auth/provider/login?redirect=' + redirectUrl

  useEffect(() => {
    if (
      getSubdomain(window.location.href) ===
      process.env.REACT_APP_BASE_SUBDOMAIN
    ) {
      history.push('/login')
    } else {
      // in prod, the api is the same path as the frontend
      window.location.href =
        process.env.NODE_ENV === 'production'
          ? path
          : process.env.REACT_APP_BASE_URL + path
    }
  }, [])

  return <AppLoading />
}
