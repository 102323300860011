import './styles.less'

import { Checkbox, Col, Form, Icon, Input, Row, Select, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'

import { Avatar } from '../../../_components/Avatar'
import ExternalLink from '../../../_components/ExternalLink'
import AutocompleteUser from '../../../_components/Form/AutocompleteUser'
import Label from '../../../_components/HelpIcon'
import ReadonlyFieldsBanner from '../../../_components/ReadonlyFieldsBanner'
import trimmedEmailValidator from '../../../_helpers/trimmedEmailValidator'
import trimmedMinLengthValidator from '../../../_helpers/trimmedMinLengthValidator'
import AutocompleteUserOption from '../../components/AutocompleteUserOption'
import FormFieldDescription from './FormFieldDescription'

const EditUserForm = ({ user, teams, form, canChangeOwner }) => {
  const { getFieldDecorator } = form
  const [disabledFields, setDisabledFields] = useState({})

  const teamId = user.team ? user.team.id : undefined

  const matchesEditingUser = x => user.id === x.id

  useEffect(() => {
    if (!user.readonlyProperties) {
      return
    }
    const disabledFieldsDict = user.readonlyProperties.reduce(
      (memo, { name, reasons }) => {
        let key = name
        if (key === 'team') {
          key = 'teamId'
        }
        if (key === 'manager') {
          memo['directReports'] = { reasons }
        }
        memo[key] = { reasons }
        return memo
      },
      {},
    )
    setDisabledFields(disabledFieldsDict)
  }, [])

  const isDisabledField = key => {
    if (user.status === 'DEACTIVATED') {
      return true
    }

    return disabledFields[key]
  }

  const disabledFieldReasons = key => {
    const disabledField = disabledFields[key]
    return disabledField ? disabledField.reasons : []
  }

  const disabledFieldHelpMessage = key => {
    const reasons = disabledFieldReasons(key)
    if (!reasons) {
      return null
    }
    const editableReason = reasons.find(x => !!x.editUrl)
    if (editableReason) {
      return <ExternalLink href={editableReason.editUrl}>Edit</ExternalLink>
    }
    return undefined
  }

  const disableDirectReportRule = user => {
    if (!user.readonlyProperties) {
      return false
    }
    return !!user.readonlyProperties.find(x => x.name === 'manager')
  }

  const onDirectReportsChange = directReports => {
    if (!directReports || directReports.length === 0) {
      return
    }
    const selectedManager = form.getFieldValue('manager')
    if (!selectedManager) {
      return
    }
    const includesManager = directReports.find(x => x.id === selectedManager.id)
    if (!includesManager) {
      return
    }
    form.setFieldsValue({
      manager: null,
    })
  }

  const onManagerChange = manager => {
    if (!manager) {
      return
    }
    const selectedDirectReports = form.getFieldValue('directReports')
    if (!selectedDirectReports || selectedDirectReports.length === 0) {
      return
    }
    form.setFieldsValue({
      directReports: selectedDirectReports.filter(x => x.id !== manager.id),
    })
  }

  return (
    <Form layout="vertical" hideRequiredMark={true}>
      {(user.readonlyProperties && user.readonlyProperties.length > 0) ||
        (user.status === 'DEACTIVATED' && (
          <ReadonlyFieldsBanner
            user={user}
            fields={user.readonlyProperties}
            style={{ marginBottom: 24 }}
          />
        ))}
      <Row type="flex" gutter={16} style={{ marginBottom: '8px' }}>
        <Col
          className="edit-user-avatar-container"
          style={{ flex: '0 0 80px', flexDirection: 'row' }}
        >
          <Avatar
            user={user}
            size={80}
            disableTooltip={true}
            style={{
              borderRadius: '28px',
              borderWidth: '5px',
            }}
          />
        </Col>
        <Col style={{ flex: '1 1 auto', width: '200px' }}>
          <Row type="flex" gutter={16}>
            <Col md={12}>
              <Form.Item
                label={<>First name{disabledFieldHelpMessage('firstName')}</>}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('firstName', {
                  initialValue: user.firstName,
                  rules: [
                    {
                      validator: trimmedMinLengthValidator({
                        min: 1,
                        message: 'Please enter at least 1 character',
                      }),
                    },
                  ],
                })(<Input disabled={isDisabledField('firstName')} />)}
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item
                label={<>Last name{disabledFieldHelpMessage('lastName')}</>}
                style={{ marginBottom: 0 }}
              >
                {getFieldDecorator('lastName', {
                  initialValue: user.lastName,
                  rules: [
                    {
                      validator: trimmedMinLengthValidator({
                        min: 1,
                        message: 'Please enter at least 1 character',
                      }),
                    },
                  ],
                })(<Input disabled={isDisabledField('lastName')} />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item
            label={<>E-mail{disabledFieldHelpMessage('email')}</>}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('email', {
              initialValue: user.email,
              rules: [{ validator: trimmedEmailValidator }],
            })(
              <Input
                autoCorrect="off"
                autoCapitalize="none"
                disabled={isDisabledField('email')}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item
            label={<>Job title{disabledFieldHelpMessage('jobTitle')}</>}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('jobTitle', {
              initialValue: user.jobTitle,
              rules: [
                {
                  validator: trimmedMinLengthValidator({
                    min: 2,
                    message: 'Please enter at least 2 characters',
                  }),
                },
              ],
            })(<Input disabled={isDisabledField('jobTitle')} />)}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item
            label={<>Team{disabledFieldHelpMessage('teamId')}</>}
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('teamId', {
              initialValue: teamId,
            })(
              <Select
                allowClear
                clearIcon={<Icon type="close-circle" />}
                disabled={isDisabledField('teamId')}
              >
                {teams
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(team => (
                    <Select.Option key={team.id} value={team.id}>
                      {team.name}
                    </Select.Option>
                  ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item
            label={
              <>
                <Label
                  tooltip="Managers can view their direct report(s) 360 feedback and are automatically included their direct report(s) feedback requests.
You can assign one Manager per user."
                >
                  Manager
                </Label>
                {disabledFieldHelpMessage('manager')}
              </>
            }
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('manager', {
              initialValue: user.manager ? user.manager : undefined,
            })(
              <AutocompleteUser
                exclusionRule={matchesEditingUser}
                disabled={isDisabledField('manager')}
                userTypes={[
                  'created',
                  'deactivated',
                  'invited',
                  'onboarding',
                  'standard',
                  'admin',
                  'owner',
                ]}
                onChange={onManagerChange}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item
            label={
              <>
                <Label tooltip="360 viewer(s) can see the 360 feedback a user received. You can assign one or more 360 viewer(s) per user.">
                  360 viewer(s)
                </Label>
                {disabledFieldHelpMessage('viewingManagers')}
              </>
            }
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('viewingManagers', {
              initialValue: user.viewingManagers ? user.viewingManagers : [],
            })(
              <AutocompleteUser
                allowMultiple
                exclusionRule={matchesEditingUser}
                disabled={isDisabledField('viewingManagers')}
                userTypes={[
                  'created',
                  'deactivated',
                  'invited',
                  'onboarding',
                  'standard',
                  'admin',
                  'owner',
                ]}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Form.Item
            label={
              <>
                <Label tooltip="A Direct report is someone who reports into a Manager. Direct report(s) are prompted to request feedback from their Manager during a 360 feedback event, and all their 360 feedback is visible to their Manager.">
                  Direct report(s)
                </Label>
                {disabledFieldHelpMessage('directReports')}
              </>
            }
            style={{ marginBottom: 0 }}
          >
            {getFieldDecorator('directReports', {
              initialValue: user.directReports ? user.directReports : [],
            })(
              <AutocompleteUser
                allowMultiple
                exclusionRule={matchesEditingUser}
                disableRule={disableDirectReportRule}
                disabled={isDisabledField('directReports')}
                renderOption={user => <AutocompleteUserOption user={user} />}
                onChange={onDirectReportsChange}
                userTypes={[
                  'created',
                  'deactivated',
                  'invited',
                  'onboarding',
                  'standard',
                  'admin',
                  'owner',
                ]}
              />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '16px', fontWeight: 300 }}>
        <Col>User privileges</Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item
            extra={
              <FormFieldDescription>
                Admins can add, edit and delete other users and set up
                company-wide events (e.g. 360 feedback). Each company account
                can have multiple Admins.
              </FormFieldDescription>
            }
          >
            {getFieldDecorator('isAdmin', {
              initialValue: user.isAdmin,
              valuePropName: 'checked',
            })(
              <Checkbox style={{ fontWeight: 300 }} disabled={user.isOwner}>
                Admin
              </Checkbox>,
            )}
          </Form.Item>
        </Col>
        <Col
          md={12}
          style={{ visibility: canChangeOwner ? 'visible' : 'hidden' }}
        >
          <Tooltip title="Please contact support@howamigoing.com to change the owner">
            <Form.Item
              extra={
                <FormFieldDescription>
                  The Owner has all Admin privileges plus they can close the
                  company’s Howamigoing account. There is only 1 Owner for each
                  company account.
                </FormFieldDescription>
              }
            >
              {getFieldDecorator('isOwner', {
                initialValue: user.isOwner,
                valuePropName: 'checked',
              })(<Checkbox disabled={canChangeOwner}>Owner</Checkbox>)}
            </Form.Item>
          </Tooltip>
        </Col>
      </Row>
    </Form>
  )
}

export default Form.create({ name: 'EditUserForm' })(EditUserForm)
