import { Button, Card, Icon, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { Avatar } from '../../../_components/Avatar'
import OrgAvatar from '../../../_components/OrgAvatar'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import Overview from '../../modals/Overview/team360'
import { CYCLE_TYPES, getCycleBaseUrl } from '../../pages/helpers'
import CycleCardTitle from '../CycleCardTitle'
import CycleProgress from '../CycleProgress/'

const { Paragraph, Text } = Typography

const DEFAULT_PX_UNIT = 8

export default ({ cycle }) => {
  const user = useContext(UserContext)
  const location = useLocation()

  const cyclePath = getCycleBaseUrl(cycle.type)

  const [canSeeProgress, setCanSeeProgress] = useState(false)
  const [canSeeTask, setCanSeeTask] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const [enrolledUser, setEnrolledUser] = useState()
  const [
    feedbacksCurrentUserWasRequested,
    setFeedbacksCurrentUserWasRequested,
  ] = useState([])
  const [feedbackCurrentUserRequested, setFeedbackCurrentUserRequested] =
    useState([])

  useEffect(() => {
    if (cycle.enrolled) {
      // 360
      const enrolledUser = cycle.enrolled.find(x => x.id === user.id)
      if (enrolledUser) {
        setEnrolledUser(enrolledUser)
        setFeedbackCurrentUserRequested(enrolledUser.requests.filter(r => r.status !== 'DEIDENTIFIED'))
      }

      setFeedbacksCurrentUserWasRequested(
        cycle.enrolled
          .filter(e => e.status !== 'DEIDENTIFIED')
          .reduce((acc, enrolled) => {
            const currentUserAskedByEnrolled = enrolled.requests.find(r => {
              return r.askedTo.id === user.id
            })
            if (!currentUserAskedByEnrolled) {
              return acc
            }
            const hasResponded = !!currentUserAskedByEnrolled.respondedAt
            if (hasResponded) {
              return acc
            }
            acc.push(currentUserAskedByEnrolled)
            return acc
          }, [])
      )
    }

    if (cycle.requests) {
      // private
      // pulse

      if (cycle.type === CYCLE_TYPES.personal) {
        setEnrolledUser(cycle)
        setFeedbackCurrentUserRequested(cycle.requests)
      }

      if (cycle.type === CYCLE_TYPES.pulse) {
        const foundRequestForUser = cycle.requests.find(
          x => x.askedTo.id === user.id && x.respondedAt === null,
        )
        if (foundRequestForUser) {
          setFeedbacksCurrentUserWasRequested([foundRequestForUser])
        }
      }
    }
  }, [cycle])

  useEffect(() => {
    if (cycle.type === CYCLE_TYPES.personal) {
      if (user.id === cycle.creator.id) {
        setCanSeeProgress(true)
        setCanSeeTask(false)
      }
    }
    if (cycle.type === CYCLE_TYPES.pulse) {
      setCanSeeProgress(true)
      const foundRequestForUser = !!cycle.requests.find(
        x => x.askedTo.id === user.id && x.respondedAt === null,
      )
      setCanSeeTask(foundRequestForUser)
    }
    if (
      cycle.type === CYCLE_TYPES.threeSixty &&
      (user.isAdmin() ||
        user.isOwner() ||
        (user.isManager() && user.id === cycle.creator.id))
    ) {
      const isEnrolled = !!cycle.enrolled.find(
        enrolledUser => enrolledUser.id === user.id,
      )
      const hasBeenAsked = cycle.enrolled.reduce((acc, enrolledUser) => {
        if (acc) return acc
        acc = !!enrolledUser.requests.find(r => r.askedTo.id === user.id)
        return acc
      }, false)
      setCanSeeProgress(true)
      setCanSeeTask(isEnrolled || hasBeenAsked)
    }
  }, [cycle])

  return (
    <Card title={<CycleCardTitle cycle={cycle} />} style={{ marginBottom: 32 }}>
      <div className="cycle-list-item">
        {canSeeProgress && (
          <div
            style={{
              borderBottom:
                canSeeTask ? '1px solid #e8e8e8' : '',
              marginBottom:
                canSeeTask ? DEFAULT_PX_UNIT * 2 : '',
            }}
          >
            <CycleProgress cycle={cycle} />
          </div>
        )}
        {canSeeTask && (
          <div>
            {enrolledUser || feedbacksCurrentUserWasRequested.length > 0 ? (
              <div
                style={{
                  marginBottom: DEFAULT_PX_UNIT * 3,
                  color: '#77779f',
                }}
              >
                Your tasks
              </div>
            ) : (
              <div>No tasks pending.</div>
            )}

            {enrolledUser && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom:
                    feedbacksCurrentUserWasRequested.length > 0 &&
                    DEFAULT_PX_UNIT * 3,
                  paddingBottom: DEFAULT_PX_UNIT * 3,
                  borderBottom:
                    feedbacksCurrentUserWasRequested.length > 0 &&
                    '1px solid #f3f2f7',
                }}
              >
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <OrgAvatar />
                    <div style={{ marginLeft: DEFAULT_PX_UNIT * 2 }}>
                      <Paragraph
                        style={{
                          fontFamily: 'museo, serif',
                          fontWeight: 300,
                          marginBottom: 0,
                        }}
                      >
                        {feedbackCurrentUserRequested.length > 0
                          ? `You requested feedback from 
                                ${feedbackCurrentUserRequested.length} people`
                          : `Request feedback from at least
                                ${cycle.minRequests} people`}
                      </Paragraph>
                      <Button
                        type="link"
                        style={{
                          display: 'flex',
                          fontSize: 14,
                          color: '#f95c4b',
                          padding: 0,
                          height: 'auto',
                        }}
                        onClick={() => setShowModal(true)}
                      >
                        <Icon type="eye" style={{ fontSize: 16 }} />
                        <span
                          style={{
                            lineHeight: '16px',
                            marginLeft: '6px',
                            textDecoration: 'underline',
                          }}
                        >
                          See how others are going
                        </span>
                      </Button>

                      {showModal && (
                        <Overview
                          cycleId={cycle.id}
                          showModal={showModal}
                          handleCloseModal={() => setShowModal(false)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <Link
                    to={cyclePath + '/requestFeedback/' + enrolledUser.reviewId}
                    onClick={() => {
                      if (location.pathname === '/home') {
                        track(
                          cycle.type + '.feedback.requestFeedbackDashboard',
                          {
                            user: user.email,
                            org: user.org.name,
                            reviewId: enrolledUser.reviewId,
                          },
                        )
                      }
                    }}
                  >
                    <Button type="primary" shape="round" size="default">
                      {feedbackCurrentUserRequested.length > 0
                        ? `Edit request`
                        : `Request feedback`}
                    </Button>
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
        {feedbacksCurrentUserWasRequested.length > 0 &&
          feedbacksCurrentUserWasRequested.map((feedback, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom:
                  cycle.type === CYCLE_TYPES.personal
                    ? DEFAULT_PX_UNIT * 2
                    : index === feedbacksCurrentUserWasRequested.length - 1
                    ? 0
                    : DEFAULT_PX_UNIT * 3,
                paddingBottom:
                  cycle.type === CYCLE_TYPES.personal
                    ? 0
                    : index === feedbacksCurrentUserWasRequested.length - 1
                    ? DEFAULT_PX_UNIT * 2
                    : DEFAULT_PX_UNIT * 3,
                borderBottom:
                  cycle.type === CYCLE_TYPES.personal
                    ? 'none'
                    : index === feedbacksCurrentUserWasRequested.length - 1
                    ? 'none'
                    : '1px solid #f3f2f7',
              }}
              key={feedback.id}
            >
              <div style={{ display: 'flex', alignItems: 'stretch' }}>
                {cycle.type === CYCLE_TYPES.pulse ? (
                  <OrgAvatar />
                ) : (
                  <Avatar user={feedback.askedBy} />
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: DEFAULT_PX_UNIT * 2,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'museo',
                      fontWeight: 300,
                      color: '#1c1047',
                    }}
                  >
                    {cycle.type === CYCLE_TYPES.pulse ? (
                      'Please answer this Pulse survey'
                    ) : (
                      <>
                        {feedback.askedBy.firstName} {feedback.askedBy.lastName}{' '}
                        asked you for feedback
                      </>
                    )}
                  </Text>
                  <Text style={{ fontSize: 14, fontWeight: 300 }}>
                    {cycle.type === CYCLE_TYPES.pulse
                      ? cycle.name
                      : feedback.askedBy.jobTitle}
                  </Text>
                </div>
              </div>
              <div>
                <Link
                  to={cyclePath + '/giveFeedback/' + feedback.id}
                  onClick={() => {
                    const dashboard =
                      location.pathname === '/home' ? 'Dashboard' : ''
                    track(cycle.type + '.feedback.giveFeedback' + dashboard, {
                      provider: user.email,
                      org: user.org.name,
                      feedbackId: feedback.id,
                    })
                  }}
                >
                  <Button type="primary" shape="round" size="default">
                    {cycle.type === CYCLE_TYPES.pulse
                      ? 'Answer survey'
                      : 'Give feedback'}
                  </Button>
                </Link>
              </div>
            </div>
          ))}
      </div>
    </Card>
  )
}
