import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal } from 'antd'
import React, { useState } from 'react'

import ExclamationCircleIcon from '../../../../_assets/img/exclamation-circle.png'
import AutocompleteTeam from '../../../components/AutocompleteTeam'
import PrintUsers from '../../../components/PrintUsers'
import { ADD_TEAM_MEMBERS, GET_TEAMS } from '../queries'

export default ({ users, visible, onOk, onCancel }) => {
  const [teamId, setTeamId] = useState()
  const [loading, setLoading] = useState(false)
  const [okEnabled, setOkEnabled] = useState(false)

  const { data: teamsData, loading: loadingTeams } = useQuery(GET_TEAMS)
  const [addTeamMembersToTeam] = useMutation(ADD_TEAM_MEMBERS, {
    refetchQueries: ['listPeopleUsers', 'peopleListStats'],
  })

  const handleSubmit = async () => {
    try {
      setOkEnabled(false)
      setLoading(true)
      await addTeamMembersToTeam({
        variables: {
          teamId,
          userIds: users.map(x => x.id),
        },
      })
      setLoading(false)
      onOk()
      setTeamId()
    } catch (e) {
      setOkEnabled(true)
      setLoading(false)
    }
  }

  const handleChange = val => {
    setTeamId(val)
    setOkEnabled(val && val.length > 0)
  }

  const handleCancel = () => {
    setTeamId()
    setOkEnabled(false)
    onCancel()
  }

  const canUpdateTeam = user => {
    if (!user.readonlyProperties) {
      return true
    }
    return !user.readonlyProperties.find(x => x.name === 'team')
  }
  const usersWithDifferentTeam = users
    .filter(x => teamId && x.team && x.team.id !== teamId)
    .filter(canUpdateTeam)
  const usersWithReadonlyTeamProperty = users.filter(x => !canUpdateTeam(x))
  const teams = teamsData
    ? teamsData.teams
        .filter(x => !!x.name)
        .sort((a, b) => (a.name < b.name ? -1 : 1))
    : []

  return (
    <Modal
      title="Update team"
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: !okEnabled,
        type: 'accent',
      }}
      confirmLoading={loading}
      okText="Update team"
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      closable={false}
      maskClosable={!loading}
      bodyStyle={{ padding: '32px 32px 0' }}
      width={584}
      className="update-manager-modal"
      destroyOnClose
      centered
    >
      <div style={{ fontWeight: 500, marginBottom: 8 }}>Update team to:</div>
      <AutocompleteTeam
        onSelect={handleChange}
        style={{ marginBottom: 32 }}
        withShadow
        teams={teams}
        disabled={loadingTeams}
      />

      {usersWithDifferentTeam.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <PrintUsers users={usersWithDifferentTeam} />
          will be assigned to the team.
        </div>
      )}
      {usersWithReadonlyTeamProperty.length > 0 && (
        <div style={{ marginBottom: 24 }}>
          <PrintUsers users={usersWithReadonlyTeamProperty} />
          won't be updated because they're integrated with Personio.
        </div>
      )}

      <div style={{ display: 'flex', marginBottom: 32 }}>
        <div style={{ flex: '0 0 16px' }}>
          <img
            src={ExclamationCircleIcon}
            alt="Exclamation mark"
            style={{ maxWidth: '100%' }}
          />
        </div>
        <div style={{ fontSize: '14px', lineHeight: '22px', paddingLeft: 8 }}>
          Assigning people to their team means you'll be able to filter by team
          in other parts of Howamigoing. This makes it easier to set up and
          manage feedback events, and to view how different teams are engaging
          with the feedback process.
        </div>
      </div>
    </Modal>
  )
}
