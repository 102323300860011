const defaultHook = {
  hookTitle: 'Spread the love',
  hookDescription: `Praise takes a moment to give but lasts a lifetime.`,
  button: 'Send Good vibes',
  route: '/team360/create/1',
  routeState: {},
}

export default [
  {
    text: `We didn’t have to do any research to understand the appropriate 
      questions - Howamigoing is opinionated. To do this properly would 
      have likely cost thousands and 3 months. It saves masses of time vs 
      Google forms and manually chasing people for feedback`,
    author: `Chris"`,
    location: 'London',
    ...defaultHook,
  },
  {
    text: `It’s made feedback ‘normal’, something we’d been trying to do for 
      a couple of years. And it has opened so many doors, we do so many things 
      now that we couldn't before. Will we ever stop using it? No, no way. 
      That’d be going backwards.`,
    author: `Anna`,
    location: 'Portsmouth',
    ...defaultHook,
  },
  {
    text: `Before we were using Microsoft Forms. But what you have is intelligence 
      around questions. And anonymity and the nice presentation of data. So there’s 
      better output and it’s easier for us to know what to take action on.`,
    author: `Jason`,
    location: 'Christchurch',
    ...defaultHook,
  },
  {
    text: `The positive we’ve seen from using Howamigoing is at a cultural level. 
      We’ve come from a point where feedback was feared but now it’s valued and expected."`,
    author: `Graeme`,
    location: 'Armidale',
    ...defaultHook,
  },
  {
    text: `Compared to previous 360s in my career, this is just so easy. It's helping us 
      to 'make feedback our friend', because without that, we can't change and grow.`,
    author: `Shaun`,
    location: 'Melbourne',
    ...defaultHook,
  },
  {
    text: `My HR Manager and I are spending 3-4 fewer days each quarter on setting up 
      peer to peer and pulse feedback cycles. This frees me up to focus on taking 
      action on the insights. It'd be saving the business at least $25k p.a. 
      just in man hours.`,
    author: `David`,
    location: 'Singapore',
    ...defaultHook,
  },
  {
    text: `We weren't sure if it was the right time to do a 360 feedback process, 
      I guess based on past experiences. But then it only took 3 weeks, and everyone 
      just felt better afterwards. So I'm glad we did it, turned out to be a pivotal 
      learning exercise that we really needed.`,
    author: `Simon`,
    location: 'Surrey',
    ...defaultHook,
  },
  {
    text: `I'd say it saves me and other managers around 6-10 hours per 360 feedback process, 
      mainly just from not having to chase people up and collate all the feedback using 
      Google Doc & Sheets. For the juniors that are new or aren't used to giving feedback, 
      the anonymity really helps them to be honest.`,
    author: `Gemma`,
    location: 'London',
    ...defaultHook,
  },
  {
    text: `If we are to motivate our Millennial people, drive down costly turnover and cultivate a 
      more productive workforce, we have to invest in continuous feedback. 
      Howamigoing is our partner in that.`,
    author: `Willie`,
    location: 'Hong Kong',
    ...defaultHook,
  },
  {
    text: `What I like about Howamigoing, there are lots of prompts and suggestions as to what 
      to talk about for 360 and Pulse. Without having performance management professionals 
      in the business, it’s very helpful to have a guide for great baseline standards.`,
    author: `Nikhil`,
    location: 'Singapore',
    ...defaultHook,
  },
  {
    text: `It was so nice to see that 57% of the branch staff felt more confident 
      AND more valued after the last 360 feedback process, and that nobody 
      felt less confident or less valued.`,
    author: `Zyda`,
    location: 'London',
    ...defaultHook,
  },
  {
    text: `I was surprised by how easy it was to set up. Like 5 mins to invite people. 
      The UI is fresh, fun to work with. Other tools feel like they are built for 
      statisticians, but Howamigoing is for users, for HR.`,
    author: `Roland`,
    location: 'Berlin',
    ...defaultHook,
  },
  {
    text: `We've had virtually no staff turnover apart from retirements and a couple of 
      COVID-related redundancies. Howamigoing definitely contributes to this. 
      This saves the business >£50k p.a. vs if we had normal attrition rates.`,
    author: `Liz`,
    location: 'Cambridge',
    ...defaultHook,
  },
  {
    text: `What I really like is that it’s really clean. I looked at quite a few tools 
      but Howamigoing won because of the UX/UI, that’s so important for this area. 
      And I really loved how easy it was to test and try it out.`,
    author: `Dmitri`,
    location: 'Berlin',
    ...defaultHook,
  },
  {
    text: `I’m building out our first attempt at a light-weight feedback process.  
      Howamigoing was the only one that felt it was the right combo of light-weight, 
      but also actionable and constructive.  Others felt very fluffy, 
      but filled with things that don’t help people.`,
    author: `Loren`,
    location: 'New York',
    ...defaultHook,
  },
  {
    text: `There’s always a concern with anonymity, you tend to expect the worst, 
      but from that perspective there was nothing like that, it was all encouraging. 
      The questions you supplied really helped with this, was really key that 
      they were geared toward being constructive, so people answered helpfully.`,
    author: `Reuben`,
    location: 'London',
    ...defaultHook,
  },
  {
    text: `Everyone feels more valued, you know we don’t take time to say these 
      things out loud, this is helpful, positive reinforcement. It’s motivating, 
      I felt that and others in the team felt that when you receive the feedback 
      you are pumped to work for the company and do your best.`,
    author: `Charlotte`,
    location: 'Cambridge',
    ...defaultHook,
  },
]
