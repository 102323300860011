import { Switch } from 'antd'
import React, { useState } from 'react'

const SelectPeopleToggle = ({ initialValue, numSelected, onChange }) => {
  const [selected, setSelected] = useState(initialValue)

  const handleOnSelectedToggle = () => {
    if (numSelected === 0) return
    const newVal = !selected
    setSelected(newVal)
    if (onChange) {
      onChange(newVal)
    }
  }

  const disabled = numSelected === 0

  return (
    <div>
      <Switch checked={selected} disabled={disabled} onChange={handleOnSelectedToggle} />
      <span style={disabled ? textStyleDisabled : textStyleClickable} onClick={handleOnSelectedToggle}>Show only selected ({numSelected})</span>
    </div>
  )
}

const textStyle = {
  fontSize: 14,
  color: '#281765',
  fontFamily: 'Europa, sans-serif',
  marginLeft: 8,
}

const textStyleDisabled = {
  ...textStyle,
  cursor: 'not-allowed',
}

const textStyleClickable = {
  ...textStyle,
  cursor: 'pointer'
}



export default SelectPeopleToggle
