import { Button } from 'antd'
import React from 'react'
import { withRouter } from 'react-router-dom'

const DEFAULT_SPACING = 8

const BackButton = ({ history, route, onClick, style }) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    route ? history.push(route) : history.goBack()
  }

  return (
    <Button
      type="ghost"
      style={style ? style : { marginRight: DEFAULT_SPACING }}
      onClick={handleClick}
    >
      Back
    </Button>
  )
}

export default withRouter(BackButton)
