import './ResetPassword/style.less'

import { Button, Card, Col, Row } from 'antd'
import React, { useEffect } from 'react'

import Logo from './_assets/img/logo.svg'
import useMobileOptimized from './hooks/device/useMobileOptimized'

export default ({ location }) => {
  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useMobileOptimized(true)

  return (
    <Row
      type="flex"
      justify="center"
      align="top"
      style={{ minHeight: '100vh' }}
      className="screen-container"
    >
      <Col span={7} className="col-login" style={{ textAlign: 'center' }}>
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>
        <Card className="screen-container-card" style={{ minHeight: 452 }}>
          <div
            className="font-secondary color-primary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              lineHeight: '32px',
              marginBottom: 12,
            }}
          >
            We've moved!
          </div>
          <div
            className="color-primary"
            style={{ fontWeight: 300, marginBottom: 24 }}
          >
            Please visit us at our new home
          </div>
          <img
            src="https://storage.googleapis.com/howamigoing-assets/app/gifs/redirect-friends.gif"
            alt="Redirection"
            style={{ marginBottom: '32px', maxWidth: '235px', width: '100%' }}
          />
          <a
            href={`https://${process.env.REACT_APP_BASE_SUBDOMAIN}.howamigoing.com/login`}
            rel="noopener noreferrer"
          >
            <Button type="accent" size="large" style={{ marginBottom: '12px' }}>
              Take me there now
            </Button>
          </a>
        </Card>
      </Col>
    </Row>
  )
}
