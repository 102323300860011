import { Icon } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'

import AvatarPlusList from '../../../_components/AvatarPlusList'
import CheckboxSelect from '../../../_components/Teams/CheckboxSelect'
import DownloadDropdown from './DownloadDropdown'
import DownloadingStateModal from './DownloadingStateModal'

const stylePreTitle = {
  fontSize: 14,
  fontWeight: 300,
  color: '#77779f',
  lineHeight: '22px',
}

const styleTitleCycle = {
  fontSize: 20,
  fontWeight: 500,
  fontFamily: 'museo',
  lineHeight: '30px',
  marginBottom: 10,
}

const styleIconClock = { fontSize: 12, marginRight: 8 }
const styleDateInfo = {
  fontSize: 12,
  color: '#77779f',
  fontWeight: 300,
}

export default ({
  preTitle,
  cycle,
  reviews,
  review,
  onTeamChange,
  teamsArray,
}) => {
  const reviewFeedbackGivers = review && review.feedbackGivers
  const [showDownloadingModal, setShowDownloadingModal] = useState(false)

  const teamCountsLocal =
    review && review.teamCounts !== undefined ? review.teamCounts : []
  const teamsArrayLocal = teamsArray !== undefined ? teamsArray : []

  return (
    <div style={reviewFeedbackGivers && { marginBottom: '-8px' }}>
      <div
        style={{
          alignItems: 'flex-start',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          {preTitle && <div style={stylePreTitle}>{preTitle}</div>}
          <div style={styleTitleCycle}>{cycle.name}</div>
          {cycle.endDate && (
            <div style={styleDateInfo}>
              <span>
                <Icon style={styleIconClock} type="clock-circle" />
                <span>
                  Ended: {moment.unix(cycle.endDate).from(moment.now())}
                </span>
              </span>
            </div>
          )}
        </div>
        <DownloadDropdown
          review={review}
          reviews={reviews}
          setShowDownloadingModal={setShowDownloadingModal}
          teamsArray={teamsArrayLocal}
        />
        <DownloadingStateModal showDownloadingModal={showDownloadingModal} />
      </div>

      <div
        style={{
          marginTop: 24,
          paddingTop: 24,
          borderTop: '1px solid #dadae5',
        }}
      >
        <CheckboxSelect
          teamCounts={teamCountsLocal}
          teamsArray={teamsArrayLocal}
          onTeamChange={onTeamChange}
        ></CheckboxSelect>
      </div>
      {reviewFeedbackGivers && (
        <div
          style={{
            borderTop: '1px solid #e9e8ef',
            marginTop: '16px',
            paddingTop: '10px',
          }}
        >
          <div
            style={{
              color: '#281765',
              fontSize: '14px',
              fontWeight: '300',
              lineHeight: '22px',
              marginBottom: '9px',
            }}
          >
            Combined {cycle.type === 'PULSE' ? 'answers' : 'feedback'} from:
          </div>
          <AvatarPlusList users={reviewFeedbackGivers} limit={6} />
        </div>
      )}
    </div>
  )
}
