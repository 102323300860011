import React, { useContext } from 'react'

import ActionMenu from '../ActionMenu'
import { AvatarName } from '../../../_components/Avatar'
import { Menu } from 'antd'
import Timestamp from '../Timestamp'
import { UserContext } from '../../../UserContext'

const Header = ({
  respondedAt,
  askedAt,
  editedAt,
  asker,
  recipient,
  visibility,
  reportedAbuse,
  answer,
  status,
  onActionClick,
}) => {
  const currentUser = useContext(UserContext)

  const isCurrentUsersAnswer = recipient.id === currentUser.id
  const isCurrentUsersQuestion = asker && asker.id === currentUser.id

  const currentUserIsAsker = asker && asker.id === currentUser.id
  const currentUserIsRecipient = recipient.id === currentUser.id

  const canDeleteQuestion = currentUserIsAsker && !respondedAt
  const canReportAbuse =
    !currentUserIsAsker ||
    (currentUserIsAsker && !!respondedAt && visibility !== 'pending')
  const canEditAnswer =
    currentUserIsRecipient && !!respondedAt && status !== 'editing'
  const canDeleteAnswer = currentUserIsRecipient && !!respondedAt
  const canChangeVisibility =
    currentUserIsRecipient && !!respondedAt && status !== 'editing'

  const ActorLabel = () => {
    if (isCurrentUsersQuestion && reportedAbuse) {
      return 'You'
    }
    if (isCurrentUsersQuestion && !respondedAt) {
      return 'You'
    }
    if (isCurrentUsersAnswer) {
      return 'You'
    }
    return <AvatarName user={recipient} />
  }

  const VisibilityLabel = () => {
    if (reportedAbuse) {
      return 'privately'
    }
    if (visibility === 'public') {
      return 'publicly'
    }
    if (visibility === 'private') {
      return 'privately'
    }
    return null
  }

  const ActionLabel = () => {
    if (reportedAbuse) {
      return 'reported'
    }
    if (!respondedAt && currentUser.id === asker.id) {
      return 'asked'
    }
    if (!respondedAt && currentUser.id === recipient.id) {
      return 'received'
    }
    if (!answer) {
      return 'declined'
    }
    if (answer) {
      return 'answered'
    }
  }

  const TargetLabel = () => {
    if (isCurrentUsersQuestion && !respondedAt) {
      return <AvatarName user={recipient} />
    }
    return null
  }

  return (
    <div
      style={{
        display: 'flex',
        fontFamily: 'Europa, sans-serif',
        fontSize: '14px',
        lineHeight: '14px',
        color: '#77779f',
        fontWeight: 300,
        minHeight: '32px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <ActorLabel />{' '}
          <span style={{ color: '#281765' }}>
            <VisibilityLabel /> <ActionLabel /> <TargetLabel />{' '}
            {isCurrentUsersQuestion && !!respondedAt ? 'your' : 'an'} anonymous
            question
          </span>
        </div>
      </div>
      <div
        style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}
      >
        <Timestamp
          date={editedAt || respondedAt || askedAt}
          tooltipPrefix={
            editedAt ? 'Answer edited:' : respondedAt ? 'Answered:' : 'Asked:'
          }
        />
        {(canDeleteQuestion ||
          canEditAnswer ||
          canDeleteAnswer ||
          canChangeVisibility ||
          canReportAbuse) && (
          <ActionMenu style={{ marginLeft: '16px' }} onClick={onActionClick}>
            {canDeleteQuestion && (
              <Menu.Item key="delete-question">Delete question</Menu.Item>
            )}
            {canEditAnswer && (
              <Menu.Item key="edit-answer">Edit answer</Menu.Item>
            )}
            {canDeleteAnswer && (
              <Menu.Item key="delete-answer">Delete answer</Menu.Item>
            )}
            {canChangeVisibility && (
              <Menu.Item key="change-visibility">Change visibility</Menu.Item>
            )}
            {canReportAbuse && (
              <Menu.Item key="report-abuse">Report abuse</Menu.Item>
            )}
          </ActionMenu>
        )}
      </div>
    </div>
  )
}

export default Header
