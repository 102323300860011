import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Input, Typography } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import { track } from '../../_helpers/analytics'
import { UserContext } from '../../UserContext'
import { SET_JOB_TITLE } from '../queries'

const { Title, Text } = Typography

const SetJobTitle = ({ onSubmit, onSkip }) => {
  const user = useContext(UserContext)
  const [jobTitle, setJobTitle] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSkipping, setIsSkipping] = useState(false)

  const [saveJobTitle] = useMutation(SET_JOB_TITLE, {
    variables: {
      jobTitle,
    },
  })

  const submitOnEnter = evt => {
    if (evt.key !== 'Enter') {
      return
    }
    submit()
  }

  useEffect(() => {
    if (user.jobTitle) {
      setJobTitle(user.jobTitle)
    }
  }, [user])

  const submit = async () => {
    setIsSubmitting(true)
    await saveJobTitle()
    track(
      user.isOwner() ? 'admin.onboarding.jobTitle' : 'user.onboarding.jobTitle',
      {
        user: user.email,
        org: user.org.name,
        jobTitle,
      },
    )
    setIsSubmitting(false)
    if (onSubmit) {
      onSubmit()
    }
  }

  const skip = async () => {
    setIsSkipping(true)
    track(
      user.isOwner()
        ? 'admin.onboarding.jobTitleSkipped'
        : 'user.onboarding.jobTitleSkipped',
      {
        user: user.email,
        org: user.org.name,
      },
    )
    await saveJobTitle()
    setIsSkipping(false)
    if (onSkip) {
      onSkip()
    }
  }

  return (
    <div className="onboarding-set-job-title">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontSize: '24px', fontWeight: 500 }}
        >
          So tell us {user.firstName}, what's your role at {user.org.name}?
        </Title>

        <div>
          <Text style={{ fontWeight: 300 }}>I'm a</Text>
          <Input
            onChange={e => setJobTitle(e.target.value)}
            style={{
              marginTop: '8px',
              boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
            }}
            defaultValue={user.jobTitle}
            placeholder="Enter your role"
            onKeyUp={submitOnEnter}
          />
        </div>

        <div style={{ marginTop: '32px' }}>
          <Button
            onClick={submit}
            disabled={
              isSubmitting || isSkipping || !jobTitle || jobTitle === ''
            }
            loading={isSubmitting}
            type="accent"
            size="large"
          >
            Continue
          </Button>
          <Button
            type="ghost"
            size="large"
            disabled={isSubmitting || isSkipping}
            loading={isSkipping}
            onClick={() => skip()}
            style={{ marginLeft: '10px' }}
          >
            Skip for now
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default withRouter(SetJobTitle)
