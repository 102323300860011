import React from 'react'

import Avatar, { AvatarName } from '../../../_components/Avatar'

const ReadonlyAnswer = ({ recipient, answer, editedAt }) => {
  return (
    <>
      <div style={{ marginBottom: '20px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '12px',
          }}
        >
          <div style={{ marginRight: '12px' }}>
            <Avatar user={recipient} disableTooltip={true}></Avatar>
          </div>
          <AvatarName user={recipient}></AvatarName>
        </div>
        <div
          style={{
            fontSize: '16px',
            fontWeight: 300,
            color: '#1c1047',
            whiteSpace: 'pre-wrap',
          }}
        >
          {answer ? (
            answer
          ) : (
            <span style={{ color: '#6b6b8f' }}>Awaiting a response...</span>
          )}
          {editedAt ? (
            <span
              style={{
                marginLeft: '.5em',
                fontWeight: 300,
                color: '#6b6b8f',
                fontSize: '14px',
              }}
            >
              (Edited)
            </span>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default ReadonlyAnswer
