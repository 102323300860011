import './style.less'

import { Card, List, Typography } from 'antd'
import React, { useEffect, useState } from 'react'

import { DEFAULT_SPACING } from '../CONSTANTS'
import Question from './Question'

const { Title } = Typography

export default ({ review, teamsArray }) => {
  const [sections, setSections] = useState([])

  useEffect(() => {
    if (review) {
      const newSections = review.cycle.form.sections.map(section => {
        const questions = section.formQuestions.map(formQuestion => {
          return review.questions.find(x => x.id === formQuestion.id)
        })

        return {
          name: section.title,
          questions,
        }
      })
      setSections(newSections)
    }
  }, [review])

  return (
    <div className="feedback-answers">
      <List
        size="large"
        itemLayout="vertical"
        grid={{
          gutter: DEFAULT_SPACING * 2,
        }}
        style={{ marginTop: DEFAULT_SPACING * 3 }}
        dataSource={sections}
        renderItem={(section, index) => (
          <List.Item key={index} style={{ marginBottom: 40 }}>
            <Card
              title={
                <Title level={4} style={{ marginBottom: 0 }}>
                  {section.name}
                </Title>
              }
            >
              <div>
                {section.questions.map((question, index) => (
                  <Question
                    teamsArray={teamsArray}
                    section={section}
                    question={question}
                    key={question.id}
                    index={index}
                    peopleAsked={review.totalFeedbacks}
                    reviewId={review.id}
                  />
                ))}
              </div>
            </Card>
          </List.Item>
        )}
      ></List>
    </div>
  )
}
