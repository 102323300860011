import { useMutation } from '@apollo/react-hooks'
import { Button, Form, notification } from 'antd'
import React, { useContext } from 'react'

import ReadonlyFieldsBanner from '../_components/ReadonlyFieldsBanner'
import { UserContext } from '../UserContext'
import AccountDetails from './AccountDetails'
import PrivacySettings from './PrivacySettings'
import { UPDATE_ACCOUNT_DETAILS } from './queries'

const hasErrors = fieldsError => {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const AccountForm = ({ form }) => {
  const user = useContext(UserContext)
  const [updateUserDetails, { loading }] = useMutation(UPDATE_ACCOUNT_DETAILS)

  const { validateFields, getFieldsError } = form

  const handleSubmit = e => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (err) {
        notification.error({
          message: 'Oops!',
          description: 'It appears an error ocurred. Please try again later.',
        })
        return
      }

      const updateUserData = Object.assign({ id: user.id }, values)

      if (!updateUserData.newPassword || !updateUserData.confirmPassword) {
        delete updateUserData.currentPassword
        delete updateUserData.newPassword
        delete updateUserData.confirmPassword
      }

      if (updateUserData.email && updateUserData.email === user.email) {
        delete updateUserData.email
      }

      const newPasswordValue = updateUserData.newPassword
        ? updateUserData.newPassword.value
        : undefined

      updateUserDetails({
        variables: { ...updateUserData, ...{ newPassword: newPasswordValue } },
      })
        .then(() => {
          return user.refetchUser()
        })
        .then(() => {
          form.resetFields([
            'currentPassword',
            'newPassword',
            'confirmPassword',
          ])
          notification.success({
            message: 'Doneburger!',
            description: `Your details were updated successfully.`,
          })
        })
        .catch(err => {
          if (
            err.networkError.result.errors &&
            err.networkError.result.errors[0].message.includes(
              'duplicate key value',
            )
          ) {
            notification.error({
              message: 'Oops!',
              description: `Looks like that email is already in use. Please type a different email address.`,
            })
          } else {
            notification.error({
              message: 'Oops!',
              description: err.networkError.result.errors[0].message,
            })
          }
        })
    })
  }

  return (
    <Form onSubmit={handleSubmit} colon={false}>
      {user.readonlyProperties && user.readonlyProperties.length > 0 && (
        <ReadonlyFieldsBanner
          fields={user.readonlyProperties}
          style={{ marginBottom: 24 }}
        />
      )}

      <AccountDetails user={user} form={form} />

      {user.authMethod === 'password' && <PrivacySettings form={form} />}

      <Form.Item>
        <Button
          loading={loading}
          disabled={loading || hasErrors(getFieldsError())}
          type="primary"
          htmlType="submit"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'AccountForm' })(AccountForm)
