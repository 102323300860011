import { useMutation, useQuery } from '@apollo/react-hooks'
import { notification } from 'antd'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { GET_DRAFT, SYNC_DRAFT } from './queries'

export const AutoSaveContext = React.createContext()

export default ({ children }) => {
  const updateContext = data => {
    setContext(prevState => merge(cloneDeep(prevState), data))
  }

  const defaultContext = {
    // This method enables the whole context functionality,
    // and must be called from within the Form that wants to benefit from it
    enable(feedbackId, form) {
      setContext({
        ...context,
        feedbackId,
        form,

        enabled: true,
      })
    },

    // Callback from the HOC called every time any field changes its value
    syncField(questionId, value) {
      updateContext({
        data: {
          [questionId]: value,
        },
      })
    },

    clearDraft(feedbackId = null) {
      localStorage.removeItem(feedbackId ? feedbackId : this.feedbackId)

      updateContext({
        feedbackId: false,
        form: false,
        data: false,
        enabled: false,
      })

      lastSynced.current = null
      dataLoaded.current = false
      fieldsLoaded.current = false
      canSave.current = false
    },

    feedbackId: false,
    form: false,
    data: false,
    enabled: false,
    fields: null,
  }

  const [context, setContext] = useState(defaultContext)
  const lastSynced = useRef(null)
  const dataLoaded = useRef(false) // Flag for when data is loaded either from the server or localStorage
  const fieldsLoaded = useRef(false) // Flag for when data has been set into the Form fields
  const canSave = useRef(false) // Flag to enable listening to changes to save the data

  // This query fetches the draft from the server (if any) after the
  // context has been enabled and as long data has not being loaded already
  const { data: loadedDraftData, error: loadedDraftError } = useQuery(
    GET_DRAFT,
    {
      variables: {
        feedbackId: context.feedbackId,
      },
      skip: !context.enabled || dataLoaded.current,
      fetchPolicy: 'network-only',
    },
  )

  // This mutation sends the local version of the draft to the server
  const [syncDraft] = useMutation(SYNC_DRAFT, {
    variables: {
      feedbackId: context.feedbackId,
      data: context.data,
      lastSynced: lastSynced.current,
    },
    fetchPolicy: 'no-cache',
  })

  // This method is responsible from getting the data from both sources (local and remote)
  // and then comparing the lastSynced timestamps to determine which is more recent, load it
  // into the context, and update it against the server if needed
  const loadDraft = async () => {
    const localData = loadDraftLocally()
    lastSynced.current =
      localData && localData.lastSynced ? localData.lastSynced : 0
    const remoteData = loadDraftRemotely()

    let verifiedData = { data: {}, lastSynced: Math.floor(Date.now() / 1000) }

    if (localData && remoteData) {
      if (
        remoteData.lastSynced &&
        remoteData.lastSynced > localData.lastSynced
      ) {
        // If the draft coming from the backend is more updated than the version we have, we overwrite the local version and reload everything
        const value = {
          lastSynced: remoteData.lastSynced,
          data: remoteData.data,
        }

        localStorage.setItem(context.feedbackId, JSON.stringify(value))
        verifiedData = value
      } else if (remoteData.lastSynced === localData.lastSynced) {
        verifiedData = localData
      } else {
        verifiedData = localData

        await syncDraft({
          variables: {
            feedbackId: context.feedbackId,
            data: verifiedData.data,
            lastSynced: lastSynced.current,
          },
        })
      }
    } else if (localData) {
      verifiedData = localData
      await syncDraft({
        variables: {
          feedbackId: context.feedbackId,
          data: verifiedData.data,
          lastSynced: lastSynced.current,
        },
      })
    } else if (remoteData) {
      // If we don't have any draft in the browser, but the backend is responding with one, we persist it for future sessions
      const value = {
        lastSynced: remoteData.lastSynced,
        data: remoteData.data,
      }

      localStorage.setItem(context.feedbackId, JSON.stringify(value))
      verifiedData = value
    }

    dataLoaded.current = true
    updateContext(verifiedData)
    lastSynced.current = verifiedData.lastSynced
  }

  // Loads the latest draft from the localStorage
  const loadDraftLocally = () => {
    const data = localStorage.getItem(context.feedbackId)

    if (data) {
      try {
        return JSON.parse(data)
      } catch (err) {
        notification.error({
          message: 'Oops!',
          description: 'It appears an error ocurred. Please try again later.',
        })
      }
    }
  }

  // The loading process starts after the GraphQL is executed.
  // Because of that, this method only fetches the object from the query response
  const loadDraftRemotely = () => {
    return loadedDraftData &&
      loadedDraftData.draft &&
      loadedDraftData.draft.length === 1 &&
      !loadedDraftError
      ? loadedDraftData.draft[0]
      : false
  }

  // Loads the fetched data into the Form by using the context.form,
  // containing all AntD Form API methods
  const loadFields = () => {
    context.form.setFieldsValue(Object.assign({}, context.data))
    context.form.validateFields(Object.keys(context.data))
    fieldsLoaded.current = true
    updateContext({ ...context, fields: context.data })
  }

  // Handles saving the drafts to both localStorage (first and right away)
  // and to the server (with a debounce)
  const saveDraft = async () => {
    saveDraftLocally()
    await saveDraftRemotely()
  }

  // Saves the draft into localStorage, updating the lastSynced flag
  // and transforming the object into a JSON string
  const saveDraftLocally = () => {
    lastSynced.current = Math.floor(Date.now() / 1000)

    const value = {
      lastSynced: lastSynced.current,
      data: context.data,
    }

    localStorage.setItem(context.feedbackId, JSON.stringify(value))
  }

  // Debounce callback that calls the syncDraft mutation to the server
  // so it doesn't need to happen at every type / click
  const [saveDraftRemotely] = useDebouncedCallback(async () => {
    await syncDraft()
  }, 3000)

  // This useEffect runs the loading draft procedure
  useEffect(() => {
    async function fetchData() {
      if (loadedDraftData && !loadedDraftError && !dataLoaded.current) {
        await loadDraft()
      }
    }

    fetchData()
  }, [loadedDraftData, loadedDraftError])

  // This useEffect sets the value of the fields after everything has loaded
  useEffect(() => {
    if (
      context.enabled &&
      dataLoaded.current &&
      !fieldsLoaded.current &&
      context.data &&
      context.form
    ) {
      loadFields()
    }
  }, [context.data])

  // This useEffect runs the saving draft procedure after everything has loaded
  useEffect(() => {
    if (canSave.current) {
      saveDraft()
    }

    if (
      context.enabled &&
      dataLoaded.current &&
      fieldsLoaded.current &&
      context.data
    ) {
      canSave.current = true
    }
  }, [context.data])

  return (
    <AutoSaveContext.Provider value={context}>
      {children}
    </AutoSaveContext.Provider>
  )
}
