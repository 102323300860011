import { useEffect, useRef, useState } from 'react'

import { SEE_GOOD_VIBE } from './queries'
import { useMutation } from '@apollo/react-hooks'

const createTimer = ms => {
  let timeoutId
  const onComplete = new Promise(resolve => {
    timeoutId = setTimeout(() => {
      resolve()
    }, ms)
  })
  return {
    onComplete,
    cancel: () => clearTimeout(timeoutId),
  }
}

const useSeeGoodVibes = (isSeeing, goodVibeIds, window) => {
  const timer = useRef(null)
  const [hasSeen, setHasSeen] = useState(false)
  const [sendSeen] = useMutation(SEE_GOOD_VIBE)

  const handleOnSeen = () => {
    setHasSeen(true)
    sendSeen({
      variables: {
        goodVibeIds,
      },
    })
  }

  useEffect(() => {
    if (hasSeen || !isSeeing) {
      return
    }
    if (timer.current) {
      timer.current.cancel()
    }
    timer.current = createTimer(window || 3000)
    timer.current.onComplete.then(handleOnSeen)
  }, [isSeeing])

  useEffect(() => {
    return () => {
      if (timer.current) {
        timer.current.cancel()
      }
    }
  }, [])
}

export default useSeeGoodVibes
