import { Modal } from 'antd'
import React, { useState } from 'react'

const ConfirmationModal = ({
  visible,
  title = 'Delete',
  okText = 'Delete',
  cancelText = 'Cancel',
  onOk,
  onCancel,
  children,
  width = 584,
}) => {
  const [disabled, setDisabled] = useState(false)
  const [oking, setOking] = useState(false)
  const [canceling, setCanceling] = useState(false)

  const handleSubmit = async () => {
    try {
      setDisabled(true)
      setOking(true)
      if (onOk) {
        await onOk()
      }
      setDisabled(false)
      setOking(false)
    } catch (e) {
      setDisabled(false)
      setOking(false)
    }
  }

  const handleCanceling = async () => {
    try {
      setDisabled(true)
      setCanceling(true)
      if (onCancel) {
        await onCancel()
      }
      setDisabled(false)
      setCanceling(false)
    } catch (e) {
      setDisabled(false)
      setCanceling(false)
    }
  }

  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      onOk={handleSubmit}
      okButtonProps={{ loading: oking, disabled, type: 'accent' }}
      cancelText={cancelText}
      cancelButtonProps={{ loading: canceling, type: 'ghost', disabled }}
      onCancel={handleCanceling}
      width={width}
      centered
    >
      {children}
    </Modal>
  )
}

export default ConfirmationModal
