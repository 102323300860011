import { Checkbox as AntdCheckbox, Button } from 'antd'
import React from 'react'

const Checkbox = ({
  selected = false,
  disabled = false,
  onSelect,
  onDeselect,
  selectedText = null,
  deselectedText = null,
  ...props
}) => {
  const handleOnCheckboxChange = () => {
    if (selected) {
      if (onDeselect) {
        onDeselect()
      }
      return
    }
    if (onSelect) {
      onSelect()
    }
  }
  return (
    <div
      {...props}
      style={{
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
      }}
    >
      <Button
        type="link"
        onClick={handleOnCheckboxChange}
        disabled={disabled}
        style={{
          fontWeight: 500,
          color: '#281765',
        }}
      >
        {selected ? deselectedText : selectedText}
      </Button>
      <AntdCheckbox
        checked={selected}
        disabled={disabled}
        onChange={handleOnCheckboxChange}
        className="ant-checkbox-sm"
        style={{ marginLeft: 8, cursor: 'pointer' }}
      />
    </div>
  )
}

export default Checkbox
