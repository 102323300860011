import { Tooltip } from 'antd'
import React from 'react'

import PersonioIcon from '../../_assets/img/integrations/personio.svg'
import ExternalLink from '../../_components/ExternalLink'
import UserDisplayName from '../../_components/UserDisplayName'

const AutocompleteUserOption = ({ user }) => {
  const readonlyTeam = user.readonlyProperties
    ? user.readonlyProperties.find(x => x.name === 'team')
    : undefined
  const editableReason = readonlyTeam
    ? readonlyTeam.reasons.find(x => !!x.editUrl)
    : undefined
  return (
    <>
      <UserDisplayName user={user} />
      {editableReason && (
        <ExternalLink
          href={editableReason.editUrl}
          icon={
            editableReason.code === 'personio' ? (
              <Tooltip title="Click to edit via Personio">
                <img
                  src={PersonioIcon}
                  alt="Personio"
                  style={{ width: 14, height: 14 }}
                />
              </Tooltip>
            ) : null
          }
        />
      )}
    </>
  )
}

export default AutocompleteUserOption
