import { Button, Modal, notification } from 'antd'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Avatar from '../../../_components/Avatar'
import { track } from '../../../_helpers/analytics'
import useDismissTodo from '../../../hooks/graphql/Todos/useDismissTodo'
import DismissButton from './DismissButton'

export default ({ id, review, assignedBy, assignedTo }) => {
  const location = useLocation()
  const [dismissTodo, { loading: dismissing }] = useDismissTodo()
  const [visibleConfirmModal, setVisibleConfirmModal] = useState(false)

  const handleClick = () => {
    if (location.pathname === '/') {
      track('feedback.viewExample', {
        provider: assignedTo.email,
        org: assignedTo.org.name,
      })
    }
  }

  const handleDismissClick = () => {
    setVisibleConfirmModal(true)
  }

  const handleConfirmModalOk = async () => {
    try {
      await dismissTodo({
        variables: {
          todoId: id,
        },
        refetchQueries: ['todos'],
      })
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description:
          'There was a problem dismissing the todo. Please try again.',
      })
    }
  }

  const PrintOrgName = () => {
    const orgName = review.user.org.name
    if (orgName.toLowerCase().startsWith('the')) {
      return orgName
    }
    return `the ${orgName}`
  }

  return (
    <div className="dashboard-todo">
      <Avatar user={assignedBy} />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>
          View <PrintOrgName /> {review.user.jobTitle}’s feedback
        </div>
        <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
          See what to expect when you get feedback, courtesy of{' '}
          {review.user.firstName}. Yeow!
        </div>
      </div>
      <Link
        to={`/team360/viewFeedback/${review.id}?example=1`}
        onClick={handleClick}
      >
        <Button type="primary" size="small">
          View feedback
        </Button>
      </Link>
      <DismissButton onClick={handleDismissClick} />
      <Modal
        title="Are you sure?"
        okText="Yes, I'm sure"
        cancelText="Cancel"
        visible={visibleConfirmModal}
        onCancel={() => setVisibleConfirmModal(false)}
        onOk={handleConfirmModalOk}
        cancelButtonProps={{ disabled: dismissing }}
        okButtonProps={{
          disabled: dismissing,
          loading: dismissing,
          type: 'accent',
        }}
        centered
      >
        {review.user.firstName} doesn't share his feedback with everyone... only
        awesome people like you!
      </Modal>
    </div>
  )
}
