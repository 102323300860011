import './style.less'

import { Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'

import ConfettiOverlay from './ConfettiOverlay'

const SuccessModal = ({
  message,
  button,
  visible,
  onClose,
  src,
  confetti = true,
}) => {
  const [confettiVisible, setConfettiVisible] = useState(null)
  const [loading, setLoading] = useState(true)
  const [image, setImage] = useState(null)

  const loadImage = src => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve(img)
      }
      img.onerror = reject
      img.src = src
    })
  }

  useEffect(() => {
    const handler = async () => {
      setLoading(true)
      const image = await loadImage(src)
      setLoading(false)
      setImage(image)
      setConfettiVisible(true)
      setTimeout(() => {
        setConfettiVisible(false)
      }, 3000)
    }
    handler()
  }, [src])

  return (
    <>
      <Modal
        visible={visible}
        centered
        width={384}
        footer={null}
        onCancel={onClose}
        closable={!loading}
        maskClosable={!loading}
        destroyOnClose={true}
      >
        <div className="success-modal-component-image-container">
          {!loading && image && (
            <img
              src={image.src}
              alt="Success gif"
              height={224}
              className="success-modal-component-image"
            />
          )}
          {loading && <Spin size="large" />}
        </div>
        <p
          className={
            'success-modal-component-message' +
            (image ? ' success-modal-component-message-revealed' : '')
          }
        >
          {message}
        </p>
        {button && (
          <div className="success-modal-component-button-container">
            {button}
          </div>
        )}
      </Modal>
      {confetti && <ConfettiOverlay visible={confettiVisible} />}
    </>
  )
}

export default SuccessModal
