import { Tooltip } from 'antd'
import React from 'react'
import Text from 'react-texty'

import { Avatar, AvatarName } from '../../../../_components/Avatar'
import extractDisplayName from '../../../../_helpers/extractDisplayName'
import { Label } from '../../Table'

export default [
  {
    title: 'Name',
    key: 'name',
    width: '34%',
    render: data => (
      <div
        style={{ alignItems: 'center', display: 'flex', paddingLeft: '24px' }}
      >
        <Avatar
          user={data.user}
          size={24}
          style={{
            flexShrink: 0,
            maxWidth: '100%',
            fontSize: 12,
            lineHeight: '22px',
          }}
          disableTooltip
        />
        <Label
          style={{
            marginLeft: '8px',
          }}
        >
          <Text tooltip={extractDisplayName(data.user)}>
            <AvatarName
              user={data.user}
              style={{
                color: '#281765',
                fontSize: 14,
                fontWeight: 400,
                display: 'block',
              }}
            />
          </Text>
        </Label>
      </div>
    ),
  },
  {
    title: (
      <Tooltip title="Number of AMAs this person received">
        <span style={{ cursor: 'help' }}>Questions received</span>
      </Tooltip>
    ),
    key: 'received',
    width: '22%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(x => x.key === 'ama_user_received')
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of AMAs this person answered such that everyone can see their response">
        <span style={{ cursor: 'help' }}>Answered publicly</span>
      </Tooltip>
    ),
    key: 'givenPublic',
    width: '22%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'ama_user_answered_publicly',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of AMAs this person answered directly to the person who asked">
        <span style={{ cursor: 'help' }}>Answered privately</span>
      </Tooltip>
    ),
    key: 'givenPrivate',
    width: '22%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'ama_user_answered_privately',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
]
