import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React from 'react'

const Information = ({ info, style, withTooltip, ...restProps }) => {
  return (
    <div {...restProps}>
      <div
        className={`info-banner ${
          withTooltip ? 'info-banner--with-tooltip' : ''
        }`}
        style={style}
      >
        <FontAwesomeIcon
          icon={['fal', 'info-circle']}
          style={{
            color: '#34dbe6',
            fontSize: 14,
            marginRight: 4,
          }}
        />
        {info}
      </div>
    </div>
  )
}

export default ({ info, style, withTooltip }) => {
  if (withTooltip) {
    return (
      <Tooltip title={<span>{withTooltip}</span>}>
        <Information info={info} withTooltip={true} style={style} />
      </Tooltip>
    )
  } else {
    return <Information info={info} style={style} />
  }
}
