import Comment from '../Comment'
import React from 'react'
import Reaction from '../Reaction'
import VibeStatement from './VibeStatement'

const Body = ({
  fact,
  feeling,
  reactions,
  comments,
  onCommentCounterClick,
}) => {
  return (
    <div className="good-vibe-card__body">
      <div style={{ display: 'flex' }}>
        <div className="good-vibe-card__statement-container">
          <VibeStatement heading="Fact" statement={fact} />
        </div>
        <div className="good-vibe-card__statement-separator"></div>
        <div className="good-vibe-card__statement-container">
          <VibeStatement heading="Feeling" statement={feeling} />
        </div>
      </div>
      <div className="good-vibe-card__interaction-container">
        {reactions && reactions.length > 0 && (
          <div className="good-vibe-card__interaction">
            <Reaction.Counter reactions={reactions} />
          </div>
        )}
        {comments && comments.length > 0 && (
          <div className="good-vibe-card__interaction">
            <Comment.Counter
              count={comments.length}
              onClick={onCommentCounterClick}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Body
