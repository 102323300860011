import gql from 'graphql-tag'

export const GET_SLACK_INTEGRATION = gql`
  query slackIntegration($redirectUrl: String!) {
    slack: slackIntegration(redirectUrl: $redirectUrl) {
      id
      status
      installUrl
    }
  }
`

export const INSTALL_SLACK_INTEGRATION = gql`
  mutation installSlackIntegration(
    $authorizationCode: String!
    $state: String!
    $redirectUrl: String!
  ) {
    slackDetails: installSlackIntegration(
      authorizationCode: $authorizationCode
      state: $state
      redirectUrl: $redirectUrl
    )
  }
`

export const DEACTIVATE_SLACK_INTEGRATION = gql`
  mutation deactivateSlackIntegration($reason: String!) {
    deactivateSlackIntegration(reason: $reason)
  }
`

export const GET_PERSONIO_INTEGRATION = gql`
  query personioIntegration {
    personio: personioIntegration {
      id
      status
      config {
        inviteNewUsers
        assignSupervisors
        onDeleteInPersonio
      }
      employeeListUrl
    }
  }
`

export const ACTIVATE_PERSONIO_INTEGRATION = gql`
  mutation activatePersonioIntegration(
    $clientId: String!
    $clientSecret: String!
  ) {
    activatePersonioIntegration(
      clientId: $clientId
      clientSecret: $clientSecret
    )
  }
`

export const DEACTIVATE_PERSONIO_INTEGRATION = gql`
  mutation deactivatePersonioIntegration($reason: String!) {
    deactivatePersonioIntegration(reason: $reason)
  }
`
