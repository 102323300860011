import { uniqBy } from 'lodash'

export const getParticipants = cycle => {
  const enrolledUsers = cycle.reviews
    .filter(x => x.user && x.user.status !== 'DEIDENTIFIED')
    .map(x => x.user)
  const requestedGivers = getAllFeedback(cycle)
    .map(x => x.user)
    .filter(user => user && user.status !== 'DEIDENTIFIED')
  return uniqBy([...enrolledUsers, ...requestedGivers], x => x.id)
}

export const findFeedbackRequests = (user, cycle) => {
  if (!user) {
    return []
  }
  const review = cycle.reviews.find(x => x.user && x.user.id === user.id)
  if (!review) {
    return
  }
  return review.feedbacks.filter(x => !!x.user)
}

export const findFeedbackGiven = (user, cycle) => {
  if (!user) {
    return []
  }
  return getAllFeedback(cycle)
    .filter(
      feedback =>
        feedback.user && feedback.user.id === user.id && feedback.submittedAt,
    )
    .map(feedback => {
      const review = getReviewFromFeedback(feedback, cycle)
      return review
    })
}

export const findFeedbackToGive = (user, cycle) => {
  if (!user) {
    return []
  }
  return getAllFeedback(cycle)
    .filter(
      feedback =>
        feedback.user && feedback.user.id === user.id && !feedback.submittedAt,
    )
    .map(feedback => {
      const review = getReviewFromFeedback(feedback, cycle)
      return review
    })
}

export const findSubmittedFeedback = (user, cycle) => {
  const review = cycle.reviews.find(x => x.user.id === user.id)
  if (!review) {
    return
  }
  return review.feedbacks.filter(x => !!x.submittedAt)
}

const getAllFeedback = cycle => {
  return cycle.reviews.reduce((memo, review) => {
    if (!review.feedbacks) {
      return memo
    }
    review.feedbacks.filter(x => !!x.user).forEach(x => memo.push(x))
    return memo
  }, [])
}

const getReviewFromFeedback = (feedback, cycle) => {
  return cycle.reviews.find(review => {
    return review.feedbacks.find(x => x.id === feedback.id)
  })
}
