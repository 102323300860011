import { Layout } from 'antd'
import React, { useContext, useState } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'

import useQuery from '../hooks/routing/useQuery'
import { UserContext } from '../UserContext'
import StepCounter from './StepCounter'
import StepAuthMethod from './Steps/StepAuthMethod'
import StepFullName from './Steps/StepFullName'
import StepInvitePeople from './Steps/StepInvitePeople'
import StepJobTitle from './Steps/StepJobTitle'
import StepPassword from './Steps/StepPassword'
import StepTeam from './Steps/StepTeam'
import StepWelcomeTerms from './Steps/StepWelcomeTerms'

const { Content } = Layout

const getSteps = user => {
  if (!user) {
    return []
  }
  const steps = [
    { name: 'authMethod', showStepCount: false },
    { name: 'welcome', showStepCount: false },
    { name: 'password', showStepCount: true },
    { name: 'fullName', showStepCount: true },
    { name: 'jobTitle', showStepCount: true },
    { name: 'team', showStepCount: true },
    { name: 'invite', showStepCount: true },
  ]
    .reduce((memo, step) => {
      if (step.name === 'welcome') {
        const requiresWelcomeStep = user.onboardingTasks.find(
          x => x.name === 'terms' || x.name === 'comms',
        )
        if (requiresWelcomeStep) {
          memo.push({
            name: step.name,
            action: requiresWelcomeStep.action ? 'complete' : undefined,
            showStepCount: step.showStepCount,
          })
          return memo
        }
      }
      const task = user.onboardingTasks.find(x => x.name === step.name)
      if (!task) {
        return memo
      }
      memo.push({
        name: task.name,
        action: task.action,
        showStepCount: step.showStepCount,
      })
      return memo
    }, [])
    .map((step, i) => {
      return {
        ...step,
        path: getStepPath(step.name),
      }
    })
  return steps
}

const getStepPath = name => {
  const prefix = '/onboarding'
  switch (name) {
    case 'authMethod':
      return `${prefix}/set-auth-method`
    case 'password':
      return `${prefix}/set-password`
    case 'fullName':
      return `${prefix}/set-full-name`
    case 'jobTitle':
      return `${prefix}/set-job-title`
    case 'team':
      return `${prefix}/set-team`
    case 'invite':
      return `${prefix}/invite-people`
    case 'welcome':
    default:
      return `${prefix}/welcome`
  }
}

const hasActionedStep = step => {
  return !!step.action
}

const hasCompletedOnboarding = user => {
  return user.onboarded
}

const OnboardingRoute = ({
  path,
  render,
  component: Component,
  currentStep,
}) => {
  const user = useContext(UserContext)
  return (
    <Route
      path={path}
      render={props => {
        const { location } = props
        if (hasCompletedOnboarding(user)) {
          const query = new URLSearchParams(location.search)
          const redirectPath = query.get('redirect')
          return <Redirect to={redirectPath || '/home'} />
        }
        const targetPath = currentStep.path
        if (!location.pathname.startsWith(targetPath)) {
          const query = new URLSearchParams(location.search)
          const redirectPath = query.get('redirect')
          const redirectParams = redirectPath ? `?redirect=${redirectPath}` : ''
          return (
            <Redirect
              to={{
                pathname: targetPath,
                search: redirectParams,
                state: { from: location },
              }}
            />
          )
        }
        return render ? render(props) : <Component {...props} />
      }}
    />
  )
}

export default ({ finishOnboarding }) => {
  const path = page => `/onboarding/${page}*`
  const user = useContext(UserContext)
  const query = useQuery()
  const history = useHistory()

  if (!user.isAuthenticated) {
    return (
      <Content className="app-onboarding">
        <p style={{ textAlign: 'center' }}>
          Please click the link in your invitation email to continue
        </p>
      </Content>
    )
  }

  const [steps, setSteps] = useState(getSteps(user))

  const onSubmit = async () => {
    const redirect = query.has('redirect')
      ? `?redirect=${query.get('redirect')}`
      : ''
    const refetchedUser = await user.refetchUser()
    const recalculatedSteps = getSteps(refetchedUser)
    setSteps(recalculatedSteps)
    const nextStep = recalculatedSteps.find(x => !hasActionedStep(x))
    if (!nextStep) {
      finishOnboarding()
      return
    }
    history.push(`${nextStep.path}${redirect}`)
  }

  const currentStep = steps.find(x => !hasActionedStep(x))
  const totalCountedSteps = steps.filter(x => x.showStepCount)
  const currentCountedStepPosition =
    totalCountedSteps.findIndex(x => x.name === currentStep.name) + 1

  return (
    <Content className="app-onboarding">
      <Switch>
        <OnboardingRoute
          path={path('set-auth-method')}
          currentStep={currentStep}
          render={() => <StepAuthMethod onSubmit={onSubmit} />}
        />
        <OnboardingRoute
          path={path('welcome')}
          currentStep={currentStep}
          render={() => <StepWelcomeTerms onSubmit={onSubmit} />}
        />
        <OnboardingRoute
          path={path('set-full-name')}
          currentStep={currentStep}
          render={() => <StepFullName onSubmit={onSubmit} />}
        />
        <OnboardingRoute
          path={path('set-password')}
          currentStep={currentStep}
          render={() => <StepPassword onSubmit={onSubmit} />}
        />
        <OnboardingRoute
          path={path('set-job-title')}
          currentStep={currentStep}
          render={() => <StepJobTitle onSubmit={onSubmit} onSkip={onSubmit} />}
        />
        <OnboardingRoute
          path={path('set-team')}
          currentStep={currentStep}
          component={() => <StepTeam onSubmit={onSubmit} onSkip={onSubmit} />}
        />
        <OnboardingRoute
          path={path('invite-people')}
          currentStep={currentStep}
          render={() => (
            <StepInvitePeople onSubmit={onSubmit} onSkip={onSubmit} />
          )}
        />
        <OnboardingRoute currentStep={currentStep} path="/" />
      </Switch>
      {currentStep &&
        totalCountedSteps.length > 1 &&
        currentStep.showStepCount && (
          <StepCounter
            currentStepNumber={currentCountedStepPosition}
            totalSteps={totalCountedSteps.length}
          />
        )}
    </Content>
  )
}
