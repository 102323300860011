import gql from 'graphql-tag'

export const GET_TEAMS_LIST = gql`
  query {
    teams {
      id
      name
      totalMembers
      readonlyProperties
    }
    noTeam: users(offset: 0, limit: 0, search: { hasTeam: false }) {
      total
    }
  }
`

export const GET_USERS = gql`
  query getUsers($name: String) {
    users(limit: 10, search: { name: $name }) {
      users {
        id
        displayName
        team {
          id
          name
        }
      }
    }
  }
`
