import { useMutation } from '@apollo/react-hooks'
import { Form, Input, Modal } from 'antd'
import { Icon, notification } from 'antd'
import gql from 'graphql-tag'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Emoji from 'react-emoji-render'

import trimmedEmailValidator from './_helpers/trimmedEmailValidator'
import { UserContext } from './UserContext/'

const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationEmail {
    resendVerificationEmail
  }
`

const UPDATE_EMAIL = gql`
  mutation updateEmail($id: ID!, $email: String) {
    updateCurrentUser(updateUserInput: { id: $id, email: $email }) {
      id
    }
  }
`

const EmailForm = ({ form, handleChange, modalVisible }) => {
  const { getFieldDecorator } = form

  const user = useContext(UserContext)

  useEffect(() => {
    form.setFieldsValue({ email: user.email })
  }, [modalVisible])

  return (
    <Form>
      <Form.Item>
        {getFieldDecorator('email', {
          rules: [{ validator: trimmedEmailValidator }],
        })(
          <Input
            onKeyUp={handleChange}
            type="email"
            placeholder="Enter email"
          />,
        )}
      </Form.Item>
    </Form>
  )
}

const UpdateEmailForm = Form.create({ name: 'UpdateEmailForm' })(EmailForm)

const Button = ({ action, text, withUnderline = true, style }) => {
  return (
    <button
      href="#"
      className="link-button"
      style={{
        color: '#ffffff',
        display: 'inline-block',
        marginLeft: '16px',
        textDecoration: withUnderline ? 'underline' : 'none',
        ...style,
      }}
      onClick={action}
    >
      {text}
    </button>
  )
}

export default ({ type, hide }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [okDisabled, setOkDisabled] = useState(true)

  const [resendVerificationEmail] = useMutation(RESEND_VERIFICATION_EMAIL)
  const [updateEmail] = useMutation(UPDATE_EMAIL)

  const formRef = useRef()

  const user = useContext(UserContext)

  let text = ''
  let buttonText = ''
  let buttonAction = () => {}
  let isHideable = false

  switch (type) {
    case 'EMAIL_BOUNCED':
      text = `Oops! We couldn’t deliver your verification e-mail to ${user.email}.`
      buttonText = 'Update email'
      buttonAction = () => {
        setModalVisible(true)
      }
      break

    case 'EMAIL_PENDING_VERIFICATION':
      text = `Please check your inbox and verify your email address: ${user.email}`
      buttonText = 'Resend email'
      buttonAction = async () => {
        resendVerificationEmail()
          .then(() => {
            notification.success({
              message: 'Email sent...',
              description: 'Go check your inbox now!',
            })
          })
          .catch(() => {
            notification.warn({
              message: 'Uh-oh!',
              description: `The email didn't get through. Please try again.`,
            })
          })
      }
      break

    case 'EMAIL_VERIFIED':
      text = (
        <>
          <Emoji text=":tada:" style={{ marginRight: 10 }} />
          Nice one! Email verification complete.
          <Emoji text=":tada:" style={{ marginLeft: 10 }} />
        </>
      )
      isHideable = true
      break

    default:
      break
  }

  const handleUpdateEmailInputChange = e => {
    formRef.current.validateFields((err, values) => {
      if (err || values.email === user.email) {
        setOkDisabled(true)
      } else {
        setOkDisabled(false)
      }
    })
  }

  const handleUpdateEmail = () => {
    formRef.current.validateFields((err, values) => {
      if (err) return

      setLoading(true)

      updateEmail({
        variables: {
          id: user.id,
          email: values.email,
        },
      })
        .then(() => {
          return user.refetchUser()
        })
        .then(() => {
          if (hide) {
            hide()
          }
          notification.success({
            message: 'Email updated!',
            description:
              'Please check your inbox and click the link in the email we sent you.',
          })
        })
        .catch(e => {
          if (
            e.networkError.result.errors &&
            e.networkError.result.errors[0].message.includes(
              'Cannot update email for this user',
            )
          ) {
            notification.error({
              message: `Uh-oh... 🤔`,
              description: `You cannot update to an email with that email domain. Please enter a different email address.`,
            })
          } else if (
            e.networkError.result.errors &&
            e.networkError.result.errors[0].message.includes(
              `a domain that is not the same as your company's approved email domains`,
            )
          ) {
            notification.error({
              message: `Uh-oh... 🤔`,
              description: `Please enter an email address that has an accepted email domain for your account.`,
            })
          } else if (
            e.networkError.result.errors &&
            e.networkError.result.errors[0].message.includes(
              'duplicate key value',
            )
          ) {
            notification.error({
              message: `Oops!`,
              description: `Looks like that email is already in use. Please type a different email address.`,
            })
          } else {
            notification.error({
              message: 'Uh-oh!',
              description: `The email didn't get through. Please try again.`,
            })
          }
          setLoading(false)
        })
    })
  }

  return (
    <div
      style={{
        backgroundColor: type === 'EMAIL_BOUNCED' ? '#f95c4b' : '#6b6b8f',
        color: '#FFFFFF',
        fontSize: '14px',
        height: '40px',
        left: 0,
        lineHeight: '40px',
        position: 'fixed',
        right: 0,
        textAlign: 'center',
        top: 0,
        zIndex: 1000,
      }}
    >
      {text}
      {buttonText && <Button action={buttonAction} text={buttonText} />}
      {hide && isHideable && (
        <Button
          text={<Icon type="close" />}
          withUnderline={false}
          style={{ float: 'right', paddingRight: '20px' }}
          action={() => {
            hide()
          }}
        />
      )}
      <Modal
        title="Update email"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={handleUpdateEmail}
        okButtonProps={{
          disabled: okDisabled,
          type: 'accent',
        }}
        confirmLoading={loading}
        okText="Update"
        cancelText="Cancel"
        cancelButtonProps={{ disabled: loading, type: 'ghost' }}
        closable={false}
        maskClosable={!loading}
        bodyStyle={{ padding: '32px 32px 8px' }}
        centered
      >
        <UpdateEmailForm
          ref={formRef}
          handleChange={handleUpdateEmailInputChange}
          modalVisible={modalVisible}
        />
      </Modal>
    </div>
  )
}
