import React from 'react'
import Emoji from 'react-emoji-render'

const PraiseAction = ({ pressed, disabled, ...props }) => {
  const text = pressed ? 'Praised' : 'Praise'
  const icon = <Emoji text=":raised_hands:" />
  const iconClassModifier = pressed
    ? ' good-vibe-card__action-icon--active'
    : ''
  const textClassModifier = pressed
    ? ' good-vibe-card__action-label--active'
    : ''
  const containerClassModifier = disabled
    ? ' good-vibe-card__action--disabled'
    : pressed
    ? ' good-vibe-card__action--active'
    : ''

  return (
    <div
      {...props}
      className={`good-vibe-card__action good-vibe-card__action--praise${containerClassModifier}`}
    >
      <div className={`good-vibe-card__action-icon${iconClassModifier}`}>
        {icon}
      </div>
      <div className={`good-vibe-card__action-label${textClassModifier}`}>
        {text}
      </div>
    </div>
  )
}

export default PraiseAction
