import React, { useEffect, useRef, useState } from 'react'

import { Slider } from 'antd'
import { interpolateRound } from 'd3-interpolate'
import { scaleLinear } from 'd3-scale'

const RAW_VALUE_MIN = 0
const RAW_VALUE_MAX = 100

const MultiChoiceSlider = React.forwardRef(
  ({ value = 0, options, onChange }, ref) => {
    const normalizerRef = useRef(
      scaleLinear()
        .domain([RAW_VALUE_MIN, RAW_VALUE_MAX])
        .interpolate(interpolateRound),
    )
    const [marks, setMarks] = useState({})

    const handleChange = rawValue => {
      const normalizedValue = normalizeValue(rawValue)
      if (onChange) {
        onChange(normalizedValue)
      }
    }

    const normalizeValue = rawValue => {
      if (typeof rawValue === 'undefined') {
        return
      }
      return normalizerRef.current(rawValue)
    }
    const denormalizeValue = normalizedValue => {
      if (typeof normalizedValue === 'undefined') {
        return
      }
      return normalizerRef.current.invert(normalizedValue)
    }

    useEffect(() => {
      if (!options) {
        setMarks({})
        return
      }
      normalizerRef.current.range([0, options.length - 1])
      const steps = options.reduce((memo, option, index) => {
        const key = denormalizeValue(index)
        memo[key] = option
        return memo
      }, {})
      setMarks(steps)
    }, [options])

    return (
      <Slider
        step={null}
        tipFormatter={null}
        onChange={handleChange}
        value={denormalizeValue(value)}
        marks={marks}
        ref={ref}
      />
    )
  },
)

export default MultiChoiceSlider
