import './style.less'

import { Form } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import FieldDescription from '../../../_components/Form/FieldDescription'
import trimmedMinlengthValidator from '../../../_helpers/trimmedMinLengthValidator'
import TextArea from '../TextArea'

const TextAreaFormItem = React.forwardRef((props, ref) => {
  const {
    name: fieldName,
    label,
    description,
    help,
    placeholder,
    max: charLimit,
    min,
    required,
    form,
    initialValue,
    onChange,
    disabled,
    rows = 3,
    autoSize = null,
    onFocus,
  } = props
  const { getFieldDecorator, getFieldError } = form

  const [textAreaChars, setTextAreaChars] = useState(null)

  const pluralize = numCharacters => {
    if (numCharacters === 1) {
      return `${numCharacters} character`
    }
    return `${numCharacters} characters`
  }

  const charLimitValidator = () => {
    return (rule, value, callback) => {
      if (!charLimit) {
        return callback()
      }
      if (!value) {
        return callback()
      }
      if (value.length === 0) {
        return callback()
      }
      const remainingChars = charLimit - value.length
      if (value.length > charLimit) {
        return callback(`${pluralize(Math.abs(remainingChars))} too long`)
      }
      return callback()
    }
  }

  const helpText = (() => {
    if (help) {
      return help
    }
    const errors = getFieldError(fieldName)
    if (errors && errors.length > 0) {
      return errors[0].message
    }
    const value = form.getFieldValue(fieldName)
    if (value && value.length !== charLimit) {
      return `${pluralize(charLimit - textAreaChars)} remaining`
    }
    if (value && value.length === charLimit) {
      return `No characters remaining`
    }
    return undefined
  })()

  const validationRules = []
  if (charLimit) {
    validationRules.push({
      validator: charLimitValidator(charLimit),
    })
  }
  if (required) {
    validationRules.push({
      required: true,
      message: 'Hold up! You need to say something here...',
    })
  }

  if (min) {
    validationRules.push({
      validator: trimmedMinlengthValidator({
        min,
        message: `Must be at least ${min} character${min === 1 ? '' : 's'}`,
      }),
    })
  }

  const handleOnChange = value => {
    const fieldValue = value ? value : ''
    setFieldValue(fieldValue)
    validateField()
    if (onChange) {
      onChange(value)
    }
  }

  const validateField = () => {
    form.validateFields([fieldName], (errors, values) => {
      const value = values[fieldName]
      updateChars(value)
    })
  }

  const updateChars = value => {
    if (!charLimit) {
      return
    }
    if (value.length === textAreaChars) {
      return
    }
    if (value.length <= charLimit) {
      setTextAreaChars(value.length)
      return
    }
    if (textAreaChars !== null) {
      setTextAreaChars(null)
      return
    }
  }

  const setFieldValue = value => {
    form.setFieldsValue({
      [fieldName]: value,
    })
  }

  useEffect(() => {
    const fieldValue = initialValue ? initialValue : ''
    updateChars(fieldValue)
  }, [])

  const fieldErrors = getFieldError(fieldName)

  return (
    <Form.Item
      label={label}
      help={helpText}
      className="text-area-form-item"
      ref={ref}
      validateStatus={
        fieldErrors && fieldErrors.length > 0 ? 'error' : undefined
      }
    >
      {description && <FieldDescription>{description}</FieldDescription>}

      {getFieldDecorator(fieldName, {
        initialValue,
        rules: validationRules,
      })(
        <TextArea
          placeholder={placeholder}
          onChange={handleOnChange}
          disabled={disabled}
          rows={rows}
          autoSize={autoSize}
          onFocus={onFocus}
        />,
      )}
    </Form.Item>
  )
})

TextAreaFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
}

export default TextAreaFormItem
