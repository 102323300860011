import { default as Form } from './Form'
import { default as Modal } from './Modal'

const Root = () => {
  return null
}

Root.Form = Form
Root.Modal = Modal

export default Root
