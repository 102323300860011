import Emoji from 'react-emoji-render'
import React from 'react'

const ColleaguesReceivedNoFeedback = ({ numColleagues }) => {
  return (
    <>
      <p>
        Oh no! Your colleague{numColleagues > 1 && 's'} didn't get enough
        feedback. <Emoji text=":woman-facepalming:" />
      </p>
      <p>
        They need at least 2 people to respond to their request for feedback and
        they didn't get enough responses this time.
      </p>
    </>
  )
}

export default ColleaguesReceivedNoFeedback
