// import { Form, Input } from 'antd'

import React from 'react'
import TextAreaFormItem from '../../../_components/Form/TextAreaFormItem'

const Component = ({
  placeholder = 'Add a comment',
  form,
  name,
  initialValue = '',
  max = 280,
  disabled,
  required,
  onFocus,
}) => {
  return (
    <div className="comment__input-value-container">
      <TextAreaFormItem
        name={name}
        placeholder={placeholder}
        form={form}
        rows={1}
        required={required}
        max={max}
        autoSize={{ minRows: 1, maxRows: 10 }}
        initialValue={initialValue}
        onFocus={onFocus}
        disabled={disabled}
      />
    </div>
  )
}

export default Component
