import React from 'react'

import Company from './Company'
import Individual from './Individual'

export default ({ timeInterval, isActive }) => {
  return (
    <div className="insights__page">
      <Company timeInterval={timeInterval} isActive={isActive} />
      <Individual timeInterval={timeInterval} isActive={isActive} />
    </div>
  )
}
