import { useEffect, useRef, useState } from 'react'

import gql from 'graphql-tag'
import { useMutation } from '@apollo/react-hooks'

export const SEE_REVIEW = gql`
  mutation seeReview($reviewId: String!) {
    seeReview(id: $reviewId)
  }
`

const createTimer = ms => {
  let timeoutId
  const onComplete = new Promise(resolve => {
    timeoutId = setTimeout(() => {
      resolve()
    }, ms)
  })
  return {
    onComplete,
    cancel: () => clearTimeout(timeoutId),
  }
}

const useSeeReview = (isSeeing, reviewId, window) => {
  const timer = useRef(null)
  const [hasSeen, setHasSeen] = useState(false)
  const [sendSeen] = useMutation(SEE_REVIEW)

  const handleOnSeen = () => {
    setHasSeen(true)
    sendSeen({
      variables: {
        reviewId,
      },
    })
  }

  useEffect(() => {
    if (hasSeen || !isSeeing) {
      return
    }
    if (timer.current) {
      timer.current.cancel()
    }
    timer.current = createTimer(window || 3000)
    timer.current.onComplete.then(handleOnSeen)
  }, [isSeeing])

  useEffect(() => {
    return () => {
      if (timer.current) {
        timer.current.cancel()
      }
    }
  }, [])
}

export default useSeeReview
