import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import React from 'react'

import Stat from '../Stat'
import { USE_PULSE_SURVEYS } from './queries'

export default ({ timeInterval, userId }) => {
  const { data, loading } = useQuery(USE_PULSE_SURVEYS, {
    fetchPolicy: 'cache-first',
    variables: {
      userId,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    },
  })

  const stats = {
    requestsReceived: data
      ? data.userPulseSurveyInsight.find(x => x.key === 'pulse_survey_users_requests_received')
          .value
      : 0,
    requestsAnswered: data
      ? data.userPulseSurveyInsight.find(
          x => x.key === 'pulse_survey_users_requests_answered',
        ).value
      : 0,
    responseRate:
      (data
        ? Math.floor(
            data.userPulseSurveyInsight.find(
              x => x.key === 'pulse_survey_giver_response_rate',
            ).value * 100,
          )
        : 0) + '%',
  }

  return (
    <Card className="up__stats-card">
      <Stat
        title="Surveys received"
        number={stats.requestsReceived}
        loading={loading}
      />
      <Stat
        title="Surveys answered"
        number={stats.requestsAnswered}
        loading={loading}
      />
      <Stat
        title="Response rate"
        number={stats.responseRate}
        loading={loading}
      />
    </Card>
  )
}
