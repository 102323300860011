import gql from 'graphql-tag'

export const GET_USER_TOTALS = gql`
  query peopleListStats(
    $offset: Int
    $limit: Int
    $notInvitedSearch: UserSearchInput
    $pendingInviteSearch: UserSearchInput
    $deactivateSearch: UserSearchInput
    $adminUserSearch: UserSearchInput
    $noManagerSearch: UserSearchInput
    $noTeamSearch: UserSearchInput
    $activeUsersStartDate: String!
    $activeUsersEndDate: String!
  ) {
    totalUsers: users(offset: $offset, limit: $limit) {
      total
    }
    notInvited: users(
      offset: $offset
      limit: $limit
      search: $notInvitedSearch
    ) {
      total
    }
    pendingInvite: users(
      offset: $offset
      limit: $limit
      search: $pendingInviteSearch
    ) {
      total
    }
    deactivateUsers: users(
      offset: $offset
      limit: $limit
      search: $deactivateSearch
    ) {
      total
    }
    adminUsers: users(
      offset: $offset
      limit: $limit
      search: $adminUserSearch
    ) {
      total
    }
    noManager: users(offset: $offset, limit: $limit, search: $noManagerSearch) {
      total
    }
    noTeam: users(offset: $offset, limit: $limit, search: $noTeamSearch) {
      total
    }
    activeUsers: companyAdminInsight(startDate: $activeUsersStartDate, endDate: $activeUsersEndDate) {
      id
      key
      value
    }
  }
`
