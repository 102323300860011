import gql from 'graphql-tag'

export const REMOVE_USER_FROM_TEAM = gql`
  mutation removeUserFromTeam($userId: String!, $teamId: String!) {
    removeUserFromTeam(userId: $userId, teamId: $teamId) {
      id
      members {
        id
        user {
          id
        }
      }
    }
  }
`
