import React from 'react'

import Avatar, { AvatarName } from '../../../_components/Avatar'
import AnonymousAvatar from '../AnonymousAvatar'

const Participant = ({ user, avatarText }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '12px',
      }}
    >
        {user ? (
          <>
            <div style={{ marginRight: '12px' }}>
              <Avatar
                user={user}
                disableTooltip={true}
                fallbackText={avatarText}
              />
              </div>
            <AvatarName user={user} />
          </>
        ) : (
          <>
            <div style={{ marginRight: '12px' }}>
              <AnonymousAvatar />
            </div>
            <AvatarName user={{ firstName: 'Anonymous' }} />
          </>
        )}
    </div>
  )
}

export default Participant
