import gql from 'graphql-tag'

export const USE_PULSE_SURVEYS = gql`
  query userPulseSurveyInsight(
    $userId: String!
    $startDate: String
    $endDate: String
  ) {
    userPulseSurveyInsight(
      userId: $userId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      key
      value
    }
  }
`
