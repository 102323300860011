import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import sortBy from 'lodash/sortBy'
import React, { useContext, useEffect, useState } from 'react'

import { UserContext } from '../../../../UserContext'
import SentimentAnalysisBanner from '../../../components/SentimentAnalysisBanner'
import FeedbackBanner from '../FeedbackBanner'
import { GET_MANAGER_REVIEWS } from '../queries'
import Title from '../Title'
import ViewFeedbackSection from './ViewFeedbackSection'

const isDirectReport = ({ manager, directReport }) => {
  if (!manager.directReports) {
    return false
  }
  return !!manager.directReports.find(x => x.id === directReport.id)
}

const isThreeSixtyViewerOf = ({ threeSixtyViewer, user }) => {
  if (!threeSixtyViewer.viewingManagerOf) {
    return false
  }
  return !!threeSixtyViewer.viewingManagerOf.find(x => x.id === user.id)
}

export default props => {
  const currentUser = useContext(UserContext)
  const [reviews, setReviews] = useState([])
  const [hasSentimentAnalysisQuestions, setHasSentimentAnalysisQuestions] =
    useState(false)

  const { data, error, loading } = useQuery(GET_MANAGER_REVIEWS, {
    variables: {
      cycleId: props.match.params.cycleId,
    },
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (data && data.reviewsForManager) {
      if (data.reviewsForManager.length === 0) {
        props.history.push('/home')
      } else {
        setReviews(
          sortBy(data.reviewsForManager, [
            review => review.user.firstName,
            review => review.user.email,
          ]),
        )
        if (
          !!data.reviewsForManager[0].questions.find(x => x.sentimentAnalysis)
        ) {
          setHasSentimentAnalysisQuestions(true)
        }
      }
    }
  }, [data])

  if (loading) return <p>Loading…</p>
  if (error) return <p>Error!</p>

  const directReportReviews = reviews.filter(review => {
    return isDirectReport({ manager: currentUser, directReport: review.user })
  })
  const threeSixtyViewerReviews = reviews.filter(review => {
    return (
      isThreeSixtyViewerOf({
        threeSixtyViewer: currentUser,
        user: review.user,
      }) &&
      !directReportReviews.find(
        directReportReview => directReportReview.id === review.id,
      )
    )
  })

  const sections = []
  if (directReportReviews.length > 0) {
    sections.push({
      title: 'Your direct report(s) feedback',
      reviews: directReportReviews,
    })
  }
  if (threeSixtyViewerReviews.length > 0) {
    sections.push({
      title: 'Other feedback you can view',
      reviews: threeSixtyViewerReviews,
    })
  }

  return (
    <div className="feedback-response">
      {reviews && reviews.length > 0 && (
        <div>
          <div className="top-title">
            <Card bodyStyle={{ padding: '20px 32px 26px' }}>
              <Title
                preTitle="Other people's feedback"
                cycle={reviews[0].cycle}
                reviews={reviews}
              />
            </Card>
          </div>
          <FeedbackBanner cycleType={reviews[0].cycle.type} />
          {hasSentimentAnalysisQuestions && (
            <SentimentAnalysisBanner
              target="question"
              style={{ marginTop: '24px' }}
            />
          )}
          {sections.map((section, i) => {
            return (
              <ViewFeedbackSection
                key={i}
                title={section.title}
                reviews={section.reviews}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}
