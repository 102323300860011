import React from 'react'

export default ({ user }) => {
  if (user.firstName && user.lastName) {
    return (
      <span>
        {user.firstName} {user.lastName}
      </span>
    )
  }

  if (user.firstName) {
    return <span>{user.firstName}</span>
  }

  return <span>{user.email}</span>
}
