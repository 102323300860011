import { Icon, Select } from 'antd'
import React, { useState } from 'react'

export default ({ teams, onSelect, style }) => {
  const [searchedTeams, setSearchedTeams] = useState(teams)

  const handleSearch = value => {
    setSearchedTeams(teams.filter(t => t.name.toLowerCase().includes(value)))
  }

  const handleChange = value => {
    if (typeof onSelect === 'function') {
      onSelect(value)
    }
  }

  return (
    <div style={style}>
      <Select
        className={`update-team-autocomplete`}
        placeholder={
          <div>
            <Icon type="search" /> Search by team name
          </div>
        }
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        style={{ width: '100%' }}
        showSearch
        showArrow={false}
        allowClear={true}
        clearIcon={<Icon type="close-circle" />}
      >
        {searchedTeams &&
          searchedTeams
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map(t => <Select.Option key={t.id}>{t.name}</Select.Option>)}
      </Select>
    </div>
  )
}
