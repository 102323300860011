import gql from 'graphql-tag'

export const GET_COLLEAGUES = gql`
  query getColleagues(
    $offset: Int,
    $limit: Int,
    $name: String,
    $email: String,
    $teamId: String,
    $hasTeam: Boolean,
    $excludeIds: [String!]
    $selection: UserSelection
  ) {
    users(
      offset: $offset
      limit: $limit
      search: {
        types: ["invited", "onboarding", "standard", "admin", "owner"]
        order: { name: "ASC", team: "ASC" }
        email: $email,
        name: $name,
        teamId: $teamId
        excludeIds: $excludeIds
        hasTeam: $hasTeam
      }
      selection: $selection
    ) {
      total
      users {
        id
        firstName
        lastName
        email
        avatarUrl
        jobTitle
        status
        type
        manager {
          id
        }
        team {
          id
          name
        }
      }
    }
  }
`

export const GET_TEAMS_LIST = gql`
  query {
    teams {
      id
      name
      totalMembers
    }
  }
`
