import React from 'react'
import { Switch, useLocation } from 'react-router-dom'

import { RouteWithSubRoutes } from '../../AppRoutes'
import CycleMenu from '../components/CycleMenu'
import { getCycleTypeFromUrl, getRoutes } from './helpers'

export default () => {
  const location = useLocation()

  const cycleType = getCycleTypeFromUrl(location)

  const routes = getRoutes(cycleType)

  return (
    <div className="cycles">
      <Switch>
        {routes.routes.map((route, i) => {
          return (
            <RouteWithSubRoutes
              key={i}
              menu={
                route.menuKey ? (
                  <CycleMenu
                    type={cycleType}
                    routes={routes}
                    pathname={location.pathname}
                  />
                ) : (
                  ''
                )
              }
              {...route}
            />
          )
        })}
      </Switch>
    </div>
  )
}
