import gql from 'graphql-tag'

import { CYCLE_TYPES } from './helpers'

export const GET_CYCLES_PERSONAL = gql`
  query getPrivateFeedbackEvents($timing: String, $offset: Int, $limit: Int) {
    cycles: getPrivateFeedbackEvents(
      timing: $timing
      offset: $offset
      limit: $limit
    ) {
      id
      items {
        id
        name
        startDate
        endDate
        timeZone
        creator {
          id
        }
        requests {
          id
          askedBy {
            id
          }
          askedTo {
            id
            avatarUrl
            firstName
            lastName
            jobTitle
            email
            status
          }
          askedAt
          respondedAt
        }
        reviewId
      }
      total
    }
  }
`

export const GET_SCHEDULED_CYCLES_PERSONAL = gql`
  query getPrivateFeedbackEvents($offset: Int, $limit: Int) {
    cycles: getPrivateFeedbackEvents(
      timing: "scheduled"
      offset: $offset
      limit: $limit
    ) {
      id
      items {
        id
        name
        startDate
        endDate
        timeZone
        creator {
          id
        }
        requests {
          id
          askedBy {
            id
          }
          askedTo {
            id
            avatarUrl
            firstName
            lastName
            jobTitle
            email
            status
          }
          askedAt
          respondedAt
        }
        reviewId
      }
      total
    }
  }
`

export const GET_CYCLES_THREESIXTY = gql`
  query getThreeSixtyFeedbackEvents(
    $timing: String
    $offset: Int
    $limit: Int
  ) {
    cycles: getThreeSixtyFeedbackEvents(
      timing: $timing
      offset: $offset
      limit: $limit
    ) {
      id
      items {
        id
        name
        startDate
        endDate
        timeZone
        minRequests
        maxRequests
        creator {
          id
        }
        enrolled {
          id
          avatarUrl
          avatar
          firstName
          lastName
          jobTitle
          email
          status
          requests {
            id
            askedBy {
              id
              avatarUrl
              firstName
              lastName
              jobTitle
              email
              status
            }
            askedTo {
              id
              avatarUrl
              firstName
              lastName
              jobTitle
              email
              status
            }
            askedAt
            respondedAt
          }
          reviewId
        }
      }
      total
    }
  }
`

export const GET_SCHEDULED_CYCLES_THREESIXTY = gql`
  query getThreeSixtyFeedbackEvents($offset: Int, $limit: Int) {
    cycles: getThreeSixtyFeedbackEvents(
      timing: "scheduled"
      offset: $offset
      limit: $limit
    ) {
      id
      items {
        id
        name
        startDate
        endDate
        timeZone
        minRequests
        maxRequests
        creator {
          id
        }
        enrolled {
          id
          avatarUrl
          firstName
          lastName
          jobTitle
          email
          status
          requests {
            id
            askedBy {
              id
              avatarUrl
              firstName
              lastName
              jobTitle
              email
              status
            }
            askedTo {
              id
              avatarUrl
              firstName
              lastName
              jobTitle
              email
              status
            }
            askedAt
            respondedAt
          }
          reviewId
        }
      }
      total
    }
  }
`

export const GET_CYCLES_PULSE = gql`
  query getPulseSurveys($timing: String, $offset: Int, $limit: Int) {
    cycles: getPulseSurveys(timing: $timing, offset: $offset, limit: $limit) {
      id
      items {
        id
        name
        startDate
        endDate
        timeZone
        creator {
          id
        }
        requests {
          id
          askedBy {
            id
          }
          askedTo {
            id
            avatarUrl
            firstName
            lastName
            jobTitle
            email
            status
          }
          askedAt
          respondedAt
        }
        reviewId
      }
      total
    }
  }
`

export const GET_SCHEDULED_CYCLES_PULSE = gql`
  query getPulseSurveys($offset: Int, $limit: Int) {
    cycles: getPulseSurveys(
      timing: "scheduled"
      offset: $offset
      limit: $limit
    ) {
      id
      items {
        id
        name
        startDate
        endDate
        timeZone
        creator {
          id
        }
        requests {
          id
          askedBy {
            id
          }
          askedTo {
            id
            avatarUrl
            firstName
            lastName
            jobTitle
            email
            status
          }
          askedAt
          respondedAt
        }
        reviewId
      }
      total
    }
  }
`

export default {
  [CYCLE_TYPES.personal]: {
    GET_CYCLES: GET_CYCLES_PERSONAL,
    GET_SCHEDULED_CYCLES: GET_SCHEDULED_CYCLES_PERSONAL,
  },
  [CYCLE_TYPES.threeSixty]: {
    GET_CYCLES: GET_CYCLES_THREESIXTY,
    GET_SCHEDULED_CYCLES: GET_SCHEDULED_CYCLES_THREESIXTY,
  },
  [CYCLE_TYPES.pulse]: {
    GET_CYCLES: GET_CYCLES_PULSE,
    GET_SCHEDULED_CYCLES: GET_SCHEDULED_CYCLES_PULSE,
  },
}
