import { Tooltip } from 'antd'
import React from 'react'
import Text from 'react-texty'

import { Avatar, AvatarName } from '../../../../_components/Avatar'
import extractDisplayName from '../../../../_helpers/extractDisplayName'
import { Label } from '../../Table'

export default [
  {
    title: 'Name',
    key: 'name',
    width: '24%',
    render: data => (
      <div
        style={{ alignItems: 'center', display: 'flex', paddingLeft: '24px' }}
      >
        <Avatar
          user={data.user}
          size={24}
          style={{
            flexShrink: 0,
            maxWidth: '100%',
            fontSize: 12,
            lineHeight: '22px',
          }}
          disableTooltip
        />
        <Label
          style={{
            marginLeft: '8px',
          }}
        >
          <Text tooltip={extractDisplayName(data.user)}>
            <AvatarName
              user={data.user}
              style={{
                color: '#281765',
                fontSize: 14,
                fontWeight: 400,
              }}
            />
          </Text>
        </Label>
      </div>
    ),
  },
  {
    title: (
      <Tooltip title="Number of others this person asked for feedback across all their 360 events">
        <span style={{ cursor: 'help' }}>People asked</span>
      </Tooltip>
    ),
    key: 'sent',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_users_people_asked',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of responses to this person's feedback requests across all their 360 events">
        <span style={{ cursor: 'help' }}>People responded</span>
      </Tooltip>
    ),
    key: 'receivedFeedback',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_users_people_responded',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of 360 feedback requests this person received from others">
        <span style={{ cursor: 'help' }}>Requests received</span>
      </Tooltip>
    ),
    key: 'receivedRequest',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_users_requests_received',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of 360 feedback requests this person responded to">
        <span style={{ cursor: 'help' }}>Requests answered</span>
      </Tooltip>
    ),
    key: 'givenFeedback',
    width: '16%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_users_requests_answered',
        )
        return stat ? stat.value : 0
      }
      return 0
    },
  },
  {
    title: (
      <Tooltip title="Number of 360 feedback requests answered as a percentage of those received">
        <span style={{ cursor: 'help' }}>Response rate</span>
      </Tooltip>
    ),
    key: 'rate',
    width: '12%',
    render: data => {
      if (data && data.stats) {
        const stat = data.stats.find(
          x => x.key === 'three_sixty_giver_response_rate',
        )
        return (stat ? Math.floor(stat.value * 100) : 0) + '%'
      }
      return '0%'
    },
  },
]
