import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'
import React, { useContext, useEffect } from 'react'

import AppLoading from '../AppLoading'
import useQuery from '../hooks/routing/useQuery'
import { UserContext } from '../UserContext'

const ACCESS_BY_TOKEN = gql`
  mutation accessByToken($token: String!, $isFirstTimeLogin: Boolean) {
    token: accessByToken(token: $token, isFirstTimeLogin: $isFirstTimeLogin)
  }
`

export default ({ match, history }) => {
  const user = useContext(UserContext)
  const query = useQuery()
  const token = query.get('t')
  const isFirstTimeLogin = !!query.get('firstLogin') || undefined

  const [accessByToken] = useMutation(ACCESS_BY_TOKEN)

  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useEffect(() => {
    if (!user.isAuthenticated) {
      return
    }
    const decodedAccessToken = jwtDecode(token)
    const path = decodedAccessToken.path ? decodedAccessToken.path : '/'
    history.push(path)
  }, [user])

  useEffect(() => {
    async function fetchData() {
      if (!token) {
        return
      }

      try {
        const response = await accessByToken({
          variables: {
            token: token,
            isFirstTimeLogin: isFirstTimeLogin,
          },
        })

        if (response && response.data && response.data.token) {
          const decodedAccessToken = jwtDecode(token)
          const isImpersonated = decodedAccessToken.impersonatorId
          localStorage.setItem('HAIGToken', response.data.token)
          await user.refetchUser(isImpersonated)
        }
      } catch (e) {
        if (e.networkError.result.errors) {
          const error = e.networkError.result.errors[0].message

          if (error.includes('Must use provider login')) {
            history.push('/login/provider')
          }
        }
      }
    }
    fetchData()
  }, [])

  return <AppLoading />
}
