import { Button } from 'antd'
import React from 'react'

const DismissButton = props => {
  return (
    <Button
      {...props}
      type="ghost"
      size="small"
      style={{ marginLeft: 8, ...props.style }}
    >
      {props.children ? props.children : 'Dismiss'}
    </Button>
  )
}

export default DismissButton
