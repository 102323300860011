import { useEffect, useState } from 'react'

import { SAVE_DRAFT_ANSWER } from './queries'
import debounce from 'lodash/debounce'
import moment from 'moment'
import { useMutation } from '@apollo/react-hooks'

const key = id => `haig_draft_${id}`

const useDraft = (
  id,
  { syncedValue: serverData, lastSyncedAt: lastSyncedWithServerAt },
  { onSaved, onError },
) => {
  const [loading, setLoading] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState(null)
  const [saveToServer] = useMutation(SAVE_DRAFT_ANSWER)

  const debouncedSaveToServer = debounce(async args => {
    try {
      setError(null)
      setSaving(true)
      await saveToServer(args)
      setSaving(false)
      if (onSaved) {
        onSaved()
      }
    } catch (e) {
      setSaving(false)
      setError(`There was a problem saving the draft`)
      if (onError) {
        onError(e)
      }
    }
  }, 3000)

  const saveDraft = async ({ visibility, answer }) => {
    try {
      setError(null)
      writeToLocalStorage({ visibility, answer })
      await debouncedSaveToServer({
        variables: {
          amaId: id,
          visibility,
          answer,
        },
      })
    } catch (e) {
      setSaving(false)
      setError(`There was a problem saving the draft`)
      if (onError) {
        onError(e)
      }
    }
  }

  const readFromLocalStorage = () => {
    setError(null)
    const localDraft = localStorage.getItem(key(id))
    if (!localDraft) {
      return
    }
    return JSON.parse(localDraft)
  }

  const writeToLocalStorage = ({ answer, visibility, lastSyncedAt }) => {
    const objToStore = {
      data: {
        answer,
        visibility,
      },
      lastSyncedAt: lastSyncedAt ? lastSyncedAt : moment.utc().unix(),
    }
    localStorage.setItem(key(id), JSON.stringify(objToStore))
  }

  const syncDraft = async () => {
    try {
      setError(null)
      const localDraft = readFromLocalStorage()
      const remoteData =
        serverData && lastSyncedWithServerAt
          ? {
              data: serverData,
              lastSyncedAt: lastSyncedWithServerAt,
            }
          : undefined

      if (localDraft && remoteData) {
        let verifiedData = {
          data: {
            answer: '',
            visibility: undefined,
          },
          lastSyncedAt: moment.utc().unix(),
        }
        if (
          remoteData.lastSyncedAt &&
          remoteData.lastSyncedAt > localDraft.lastSyncedAt
        ) {
          // If the draft coming from the backend is more updated than the version we have, we overwrite the local version and reload everything
          writeToLocalStorage({
            answer: remoteData.data.answer,
            visibility: remoteData.data.visibility,
            lastSyncedAt: remoteData.lastSyncedAt,
          })
          verifiedData = remoteData
        } else if (remoteData.lastSyncedAt === localDraft.lastSyncedAt) {
          verifiedData = localDraft
        } else {
          verifiedData = localDraft
          await saveDraft({
            answer: verifiedData.data.answer,
            visibility: verifiedData.data.visibility,
          })
        }

        setData(verifiedData.data)
        return
      }

      if (localDraft) {
        await saveDraft({
          answer: localDraft.data.answer,
          visibility: localDraft.data.visibility,
        })
        setData(localDraft.data)
        return
      }

      if (remoteData) {
        // If we don't have any draft in the browser, but the backend is responding with one, we persist it for future sessions
        writeToLocalStorage({
          answer: serverData.answer,
          visibility: serverData.visibility,
          lastSyncedAt: lastSyncedWithServerAt,
        })
        setData(serverData)
      }
    } catch (e) {
      setError('Unable to save draft')
      if (onError) {
        onError(e)
      }
    }
  }

  useEffect(() => {
    const init = async () => {
      setLoading(true)
      await syncDraft()
      setLoading(false)
    }
    init()
  }, [])

  return { save: saveDraft, saving, loading, error, data }
}

export default useDraft
