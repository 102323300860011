import sortBy from 'lodash/sortBy'
import React from 'react'

import UserDisplayName from './UserDisplayName'

const PrintUsers = ({ users }) => {
  return (
    <>
      {sortBy(users, ['firstName', 'email']).map((user, i) => {
        const isLast = i + 1 === users.length
        const isSecondLast = i + 2 === users.length
        return (
          <>
            {isLast && !isSecondLast && i !== 0 ? ' and ' : null}
            <span style={{ fontWeight: 500 }}>
              <UserDisplayName key={user.id} user={user} />
            </span>
            {!isLast && !isSecondLast ? ', ' : null}
          </>
        )
      })}{' '}
    </>
  )
}

export default PrintUsers
