import { useMutation, useQuery } from '@apollo/react-hooks'
import { Button, Checkbox, Form, Typography, notification } from 'antd'
import React, { useState } from 'react'

import isEmail from '../../_helpers/isEmail'
import TagInput from '../TagInput'
import { ADD_PEOPLE, GET_COLLEAGUES } from './queries'

const { Text } = Typography

const AddPeopleByEmailForm = ({
  form,
  onSubmit,
  onCancel,
  cancelLabel = 'Cancel',
  preSubmitText,
  buttonsRightAligned = false,
  forceEmailInvite = false,
}) => {
  const { getFieldDecorator, validateFields, getFieldError } = form

  const [tagInputValue, setTagInputValue] = useState([])
  const [checkboxValue, setCheckboxValue] = useState(forceEmailInvite)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isCancelling, setIsCancelling] = useState(false)
  const [addPeople] = useMutation(ADD_PEOPLE, {
    refetchQueries: ['getColleagues', 'peopleListStats'],
    awaitRefetchQueries: true,
  })
  const { data: colleaguesData } = useQuery(GET_COLLEAGUES, {
    variables: {
      offset: 0,
      limit: 0,
    },
  })

  const handleSubmit = async e => {
    e.preventDefault()

    validateFields(async (err, values) => {
      if (err) {
        return
      }

      try {
        const { emails, invite } = values

        setIsSubmitting(true)
        await addPeople({
          variables: {
            emails,
            invite,
          },
        })

        setIsSubmitting(false)

        if (onSubmit) {
          onSubmit(emails)
        }
      } catch (e) {
        if (
          e.networkError.result.errors &&
          e.networkError.result.errors[0].message.includes(
            'Cannot add email to this account',
          )
        ) {
          notification.error({
            message: `Uh-oh... 🤔`,
            description: `You cannot add an email with that email domain. Please enter a different email address.`,
          })
        }
        if (
          e.networkError.result.errors &&
          e.networkError.result.errors[0].message.includes(
            `a domain that is not the same as your company's approved email domains`,
          )
        ) {
          notification.error({
            message: `Uh-oh... 🤔`,
            description: `Please enter an email address that has an accepted email domain for your account.`,
          })
        }
        setIsSubmitting(false)
      }
    })
  }

  const handleCancel = () => {
    setIsCancelling(true)
    if (onCancel) {
      onCancel()
    }
  }

  const isTouched = field => {
    return form.isFieldTouched(field)
  }

  const hasErrors = field => {
    const errors = getFieldError(field)
    if (!errors) {
      return false
    }
    return errors.length > 0
  }

  const checkEmail = (currentValue, collection) => {
    if (!currentValue) {
      return 'No email specified'
    }
    if (!isEmail(currentValue)) {
      return `This email address isn't valid`
    }
    if (
      collection.filter(x => x.toLowerCase() === currentValue.toLowerCase())
        .length > 1
    ) {
      return 'This email has been duplicated'
    }
    return
  }

  const checkAllEmails = (rule, emails, callback) => {
    if (colleaguesData && colleaguesData.users.total === 1) {
      if (emails.length === 1) {
        return callback(
          'Please enter at least 1 more email address to get started. More is better!',
        )
      }
      if (emails.length === 0) {
        return callback(
          'You need to enter at least two email addresses to get started',
        )
      }
    }
    if (
      colleaguesData &&
      colleaguesData.users.total === 2 &&
      emails.length === 0
    ) {
      return callback('You need to enter at least one email address')
    }
    if (emails.length === 0) {
      return callback('You need to enter at least one email address')
    }
    const maxEmailAddresses = 100
    if (emails.length > maxEmailAddresses) {
      return callback(
        `Oops! You can only add up to ${maxEmailAddresses} people at a time`,
      )
    }
    const invalidEmails = emails.filter(email => checkEmail(email, emails))
    if (invalidEmails.length > 0) {
      return callback(
        `You need to correct the invalid email address${
          invalidEmails.length > 1 ? 'es' : ''
        }`,
      )
    }
    return callback()
  }

  const extra = preSubmitText ? (
    <Text
      style={{
        fontSize: '14px',
        fontWeight: 300,
        marginTop: 16,
        color: '#6b6b8f',
      }}
    >
      {preSubmitText}
    </Text>
  ) : null

  const handleChange = values => {
    setTagInputValue(values)
  }

  const handleCheckboxChange = e => {
    setCheckboxValue(e.target.checked)
  }

  return (
    <Form hideRequiredMark colon={false} onSubmit={handleSubmit}>
      <Form.Item label="Email addresses" extra={extra}>
        {getFieldDecorator('emails', {
          initialValue: [],
          required: true,
          rules: [{ validator: checkAllEmails }],
        })(
          <TagInput
            placeholder="name1@company.com, name2@company.com ..."
            style={{ minHeight: 96, maxHeight: 374 }}
            tagValidator={checkEmail}
            onChange={handleChange}
            data-cy="invite-people-input"
          />,
        )}
      </Form.Item>
      <Form.Item help="You need to send email invites so the people you've added can join your account. If you don't send email invites now, you can do so later via Admin > People.">
        {getFieldDecorator('invite', {
          initialValue: forceEmailInvite,
          valuePropName: 'checked',
        })(
          <Checkbox onChange={handleCheckboxChange} disabled={forceEmailInvite}>
            Send email invites now
          </Checkbox>,
        )}
      </Form.Item>
      <div
        style={
          buttonsRightAligned
            ? {
                justifyContent: 'flex-end',
                display: 'flex',
                marginTop: '24px',
              }
            : { marginTop: '24px' }
        }
      >
        {buttonsRightAligned && (
          <Button
            type="ghost"
            disabled={isSubmitting || isCancelling}
            onClick={handleCancel}
            loading={isCancelling}
            style={{ marginRight: '10px' }}
          >
            {cancelLabel}
          </Button>
        )}
        <Button
          type="accent"
          htmlType="submit"
          disabled={
            isSubmitting ||
            isCancelling ||
            hasErrors('emails') ||
            !isTouched('emails')
          }
          loading={isSubmitting}
          data-cy="invite-people-form-submit-button"
        >
          {checkboxValue
            ? tagInputValue.length > 1
              ? 'Send invites'
              : 'Send invite'
            : tagInputValue.length > 1
            ? 'Add people'
            : 'Add person'}
        </Button>
        {!buttonsRightAligned && (
          <Button
            type="ghost"
            disabled={isSubmitting || isCancelling}
            onClick={handleCancel}
            loading={isCancelling}
            style={{ marginLeft: '10px' }}
          >
            {cancelLabel}
          </Button>
        )}
      </div>
    </Form>
  )
}

export default Form.create({ name: 'AppPeopleByEmailForm' })(
  AddPeopleByEmailForm,
)
