import React from 'react'

import UserPlaceholder from '../_assets/img/user-placeholder.svg'
import InvitePeopleButton from './InvitePeopleButton'

const PlaceholderAvatar = props => {
  const style = {
    width: 48,
    height: 48,
    borderRadius: 20,
    backgroundColor: '#e9e7f0',
    ...(props.style ? props.style : props.style),
  }
  return (
    <div {...props} style={style}>
      <img src={UserPlaceholder} alt="User placeholder" />
    </div>
  )
}

const PlaceholderLine = ({ width, ...props }) => {
  const style = {
    width: width ? width : '127px',
    height: '10px',
    borderRadius: '8px',
    backgroundColor: '#e9e7f0',
    ...(props.style ? props.style : undefined),
  }
  return <div {...props} style={style}></div>
}

const PlaceholderPerson = props => {
  const containerStyle = {
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    ...(props ? props.style : undefined),
  }

  const lineContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '11px',
  }

  return (
    <div {...props} style={containerStyle}>
      <PlaceholderAvatar />
      <div style={lineContainerStyle}>
        <PlaceholderLine width={150} style={{ marginBottom: '8px' }} />
        <PlaceholderLine width={127} />
      </div>
    </div>
  )
}

const UsersEmptyState = ({ style, onInvited, ...props }) => {
  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...(style ? style : undefined),
  }

  const numberOfPeopleRequiredText = () => {
    if (props.orgUsersNumber === 1) {
      return '2 other people'
    }

    if (props.orgUsersNumber === 2) {
      return '1 other person'
    }
  }

  return (
    <div {...props} style={containerStyle}>
      <PlaceholderPerson style={{ marginBottom: 16 }} />
      <PlaceholderPerson style={{ marginLeft: 33, marginBottom: 24 }} />
      <h3 style={{ fontSize: 20, marginBottom: 8 }}>
        It feels kinda lonely in here ...
      </h3>
      <p style={{ color: '#6b6b8f', textAlign: 'center' }}>
        You need at least {numberOfPeopleRequiredText()} in your account to
        start gathering feedback. More is better!
        <br />
        The people you invite will appear here.
      </p>
      <InvitePeopleButton onInvited={onInvited} forceEmailInvite={true} />
    </div>
  )
}

export default UsersEmptyState
