import React, { useContext } from 'react'

import { UserContext } from '../../../UserContext'
import { CYCLE_TYPES, findEnrolledManagees } from '../../pages/helpers'
import CycleProgress from '../CycleProgress'
import { VERTICAL_MARGIN_DOUBLE } from './CONSTANTS'

export default ({ cycle }) => {
  const user = useContext(UserContext)

  const canViewCycleProgress =
    user.isAdmin() ||
    user.isOwner() ||
    (user.isManager() && cycle.creator.id === user.id)

  if (cycle.type === CYCLE_TYPES.threeSixty && canViewCycleProgress) {
    const isUserEnrolled = cycle.enrolled.find(x => x.id === user.id)

    const enrolledManagees = findEnrolledManagees({ cycle, user })

    return (
      <div
        style={{
          borderBottom:
            !isUserEnrolled && !enrolledManagees ? 'none' : '1px solid #e8e8e8',
          marginBottom:
            !isUserEnrolled && !enrolledManagees
              ? '-4px'
              : VERTICAL_MARGIN_DOUBLE,
        }}
      >
        <CycleProgress cycle={cycle} />
      </div>
    )
  } else {
    return <></>
  }
}
