import gql from 'graphql-tag'

export const SET_INVITED = gql`
  mutation setOnboardingInvited($invited: Boolean!) {
    setOnboardingInvited(invited: $invited) {
      id
      type
      role
      onboarded
      onboardingTasks
    }
  }
`

export const SET_AUTH_METHOD = gql`
  mutation setOnboardingAuthMethod($authMethod: String!) {
    token: setOnboardingAuthMethod(authMethod: $authMethod)
  }
`

export const SET_TERMS = gql`
  mutation setTerms($acceptance: Boolean!) {
    setTermsAcceptance(termsAcceptance: $acceptance) {
      id
      type
      role
      onboarded
      onboardingTasks
    }
  }
`

export const SET_COMMS = gql`
  mutation setComms($acceptance: Boolean) {
    setCommsAcceptance(commsAcceptance: $acceptance) {
      id
      type
      role
      onboarded
      onboardingTasks
    }
  }
`

export const SET_JOB_TITLE = gql`
  mutation setOnboardingJobTitle($jobTitle: String) {
    setOnboardingJobTitle(jobTitle: $jobTitle) {
      id
      type
      role
      onboarded
      onboardingTasks
    }
  }
`

export const SET_FULL_NAME = gql`
  mutation setFullName($firstName: String, $lastName: String) {
    setOnboardingFullName(firstName: $firstName, lastName: $lastName) {
      id
      type
      role
      onboarded
      onboardingTasks
    }
  }
`

export const SET_PASSWORD = gql`
  mutation setPassword($password: String!) {
    setOnboardingPassword(password: $password) {
      id
      type
      role
      onboarded
      onboardingTasks
    }
  }
`

export const SET_TEAM = gql`
  mutation setOnboardingTeam($team: String) {
    setOnboardingTeam(team: $team) {
      id
      type
      role
      onboarded
      onboardingTasks
    }
  }
`

export const INVITE_PEOPLE = gql`
  mutation invitePeople($emails: [String!]!) {
    invitePeople(invitedPeople: { users: $emails })
  }
`

export const SET_ONBOARDING_STATUS = gql`
  mutation setOnboardingStatus($onboardingStatus: Boolean!) {
    setOnboardingStatus(onboardingStatus: $onboardingStatus)
  }
`
