import React, { useState } from 'react'

import { FeatureHeader } from '../components'
import SendGoodVibePage from './SendGoodVibePage'

const PageContainer = ({ children }) => {
  const [modalVisible, setModalVisible] = useState()
  const showSendGoodVibeModal = () => setModalVisible(true)
  const hideSendGoodVibeModal = () => setModalVisible(false)

  return (
    <>
      <div className="send-good-vibe-root__container">
        <div className="send-good-vibe-root__header">
          <FeatureHeader
            title="Good vibes"
            buttonLabel="Send Good vibes"
            onButtonClick={showSendGoodVibeModal}
          />
        </div>
        <div className="send-good-vibe-root__content">{children}</div>
      </div>
      <SendGoodVibePage
        visible={modalVisible}
        onCancel={hideSendGoodVibeModal}
        onOk={hideSendGoodVibeModal}
      />
    </>
  )
}

export default PageContainer
