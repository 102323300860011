import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Input, Typography, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { track } from '../../_helpers/analytics'
import { UserContext } from '../../UserContext'
import { SET_FULL_NAME } from '../queries'

const { Title, Text } = Typography

export default ({ onSubmit }) => {
  const user = useContext(UserContext)
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [saveFullName] = useMutation(SET_FULL_NAME, {
    variables: {
      id: user.id,
      firstName,
      lastName,
    },
  })

  const submitOnEnter = evt => {
    if (evt.key !== 'Enter') {
      return
    }
    submit()
  }

  useEffect(() => {
    if (user.firstName) {
      setFirstName(user.firstName)
    }
    if (user.lastName) {
      setLastName(user.lastName)
    }
  }, [user])

  const submit = async () => {
    if (firstName && firstName.length > 0 && lastName && lastName.length > 0) {
      setIsSubmitting(true)
      const { data } = await saveFullName()

      if (data.setOnboardingFullName.id) {
        track('user.onboarding.fullName', {
          user: user.email,
          org: user.org.name,
          firstName,
          lastName,
        })

        if (onSubmit) {
          onSubmit()
        }
      } else {
        setIsSubmitting(false)
      }
    } else {
      notification.warn({
        message: 'There is a problem with your name.',
        description: 'You did not specify any name. Please check again.',
      })
    }
  }

  return (
    <div className="onboarding-set-full-name">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontWeight: 500, fontSize: '24px' }}
        >
          Hey there! First things first:
          <br /> what should we call you?
        </Title>

        <div>
          <Text style={{ fontWeight: 300 }}>First name</Text>
          <Input
            onChange={e => setFirstName(e.target.value)}
            placeholder="Harriet"
            defaultValue={user.firstName}
            style={{ marginTop: '8px' }}
            onKeyUp={submitOnEnter}
          />
        </div>

        <div style={{ marginTop: '16px' }}>
          <Text style={{ fontWeight: 300 }}>Last name</Text>
          <Input
            onChange={e => setLastName(e.target.value)}
            placeholder="Howmie"
            defaultValue={user.lastName}
            style={{ marginTop: '8px' }}
            onKeyUp={submitOnEnter}
          />
        </div>

        <div style={{ marginTop: '32px' }}>
          <Button
            disabled={isSubmitting || !firstName || !lastName}
            loading={isSubmitting}
            onClick={submit}
            type="accent"
            size="large"
          >
            Continue
          </Button>
        </div>
      </Card>
    </div>
  )
}
