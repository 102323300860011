import './style.less'

import React from 'react'

const FloatingActionBar = ({ visible, children }) => {
  return (
    <div className="floating-action-bar__container">
      <div
        className={`floating-action-bar__inner floating-action-bar__inner--${
          visible ? 'visible' : 'hidden'
        }`}
      >
        {children}
      </div>
    </div>
  )
}

export default FloatingActionBar
