import React from 'react'

const FieldDescription = props => {
  return (
    <p
      {...props}
      style={{ color: '#1c1047', fontWeight: 300, ...props.style }}
    ></p>
  )
}

export default FieldDescription
