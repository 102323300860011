import './style.less'

import { Pagination as AntdPagination } from 'antd'
import React from 'react'

const Pagination = ({
  current,
  onChange,
  onShowSizeChange,
  total,
  itemsName,
  pageSize,
  withPageSizeChanger,
  pageSizeOptions = null,
  disabled,
}) => {
  const range = [
    current * pageSize - pageSize + 1,
    current * pageSize > total ? total : current * pageSize,
  ]
  return (
    <div className="pagination">
      <PaginationMeta range={range} total={total} suffix={itemsName} />
      <AntdPagination
        current={current}
        onChange={onChange}
        onShowSizeChange={onShowSizeChange}
        pageSize={pageSize}
        showSizeChanger={withPageSizeChanger}
        pageSizeOptions={pageSizeOptions}
        total={total}
        disabled={disabled}
        style={{ marginLeft: 'auto' }}
      />
    </div>
  )
}

export const PaginationMeta = ({ range, total, suffix }) => {
  return (
    <div className="pagination__meta">
      <span className="pagination__range">
        {range[0]}-{range[1]}
      </span>{' '}
      of {total}
      {suffix && ` ${suffix}`}
    </div>
  )
}

export default Pagination
