import { Form, Input } from 'antd'
import { sortBy } from 'lodash'
import React, { useEffect, useState } from 'react'

import ExternalLink from '../../../_components/ExternalLink'
import AutocompleteUser from '../../../_components/Form/AutocompleteUser'
import ReadonlyFieldsBanner from '../../../_components/ReadonlyFieldsBanner'
import AutocompleteUserOption from '../../components/AutocompleteUserOption'

const EditTeamForm = ({ form, team, handleChange }) => {
  const { getFieldDecorator } = form

  const [disabledFields, setDisabledFields] = useState({})

  useEffect(() => {
    if (!team.readonlyProperties) {
      return
    }
    const disabledFieldsDict = team.readonlyProperties.reduce(
      (memo, { name, reasons }) => {
        let key = name
        if (key === 'name') {
          key = 'teamName'
        }
        memo[key] = { reasons }
        return memo
      },
      {},
    )
    setDisabledFields(disabledFieldsDict)
  }, [])

  const isDisabledField = key => {
    return disabledFields[key]
  }

  const disabledFieldReasons = key => {
    const disabledField = disabledFields[key]
    return disabledField ? disabledField.reasons : []
  }

  const disabledFieldHelpMessage = key => {
    const reasons = disabledFieldReasons(key)
    if (!reasons) {
      return null
    }
    const editableReason = reasons.find(x => !!x.editUrl)
    if (editableReason) {
      return <ExternalLink href={editableReason.editUrl}>Edit</ExternalLink>
    }
    return undefined
  }

  const disableUserRule = user => {
    if (!user.readonlyProperties) {
      return false
    }
    return !!user.readonlyProperties.find(x => x.name === 'team')
  }

  return (
    <Form colon={false}>
      {team.readonlyProperties && team.readonlyProperties.length > 0 && (
        <ReadonlyFieldsBanner
          fields={team.readonlyProperties}
          style={{ marginBottom: 24 }}
        />
      )}
      <Form.Item label={<>Team name{disabledFieldHelpMessage('teamName')}</>}>
        {getFieldDecorator('teamName', {
          initialValue: team.name,
          rules: [{ required: true, message: 'This field is required!' }],
        })(
          <Input
            onKeyUp={handleChange}
            placeholder="Enter team name"
            disabled={isDisabledField('teamName')}
          />,
        )}
      </Form.Item>
      <Form.Item label="Members">
        {getFieldDecorator('members', {
          initialValue: team.members
            ? sortBy(
                team.members.map(x => x.user),
                ['firstName', 'email'],
              )
            : [],
        })(
          <AutocompleteUser
            allowMultiple
            onSelect={handleChange}
            disableRule={disableUserRule}
            userTypes={[
              'created',
              'deactivated',
              'invited',
              'standard',
              'admin',
              'owner',
            ]}
            renderOption={user => <AutocompleteUserOption user={user} />}
          />,
        )}
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'EditTeamForm' })(EditTeamForm)
