import React from 'react'

import isDevice from '../_helpers/isDevice'
import MobileSupport, { canViewOnMobile } from './index'

const MobileInterruptionGuard = ({ children }) => {
  if (isDevice() && !canViewOnMobile()) {
    return (
      <>
        <MobileSupport />
      </>
    )
  }
  return children
}

export default MobileInterruptionGuard
