import { AnonymousQuestion, Visibility } from '../../components'
import { Card, ReportAbuse } from '../../components'
import {
  DELETE_ANSWER,
  DELETE_QUESTION,
  EDIT_ANSWER,
  REPORT_ABUSE,
} from './queries'
import React, { useState } from 'react'

import Answer from './Answer'
import ConfirmationModal from '../../../_components/ConfirmationModal'
import moment from 'moment'
import { notification } from 'antd'
import { useMutation } from '@apollo/react-hooks'

const AMACard = ({
  ama,
  status,
  onActionClick,
  onEditAnswerSave,
  onEditAnswerCancel,
}) => {
  const [visibilityModalVisible, setVisibilityModalVisible] = useState(false)
  const showVisibilityModal = () => setVisibilityModalVisible(true)
  const hideVisibilityModal = () => setVisibilityModalVisible(false)

  const [deleteQuestionModalVisible, setDeleteQuestionModalVisible] = useState(
    false,
  )
  const showDeleteQuestionModal = () => setDeleteQuestionModalVisible(true)
  const hideDeleteQuestionModal = () => setDeleteQuestionModalVisible(false)

  const [deleteAnswerModalVisible, setDeleteAnswerModalVisible] = useState(
    false,
  )
  const showDeleteAnswerModal = () => setDeleteAnswerModalVisible(true)
  const hideDeleteAnswerModal = () => setDeleteAnswerModalVisible(false)

  const [reportAbuseModalVisible, setReportAbuseModalVisible] = useState(false)
  const showReportAbuseModal = () => setReportAbuseModalVisible(true)
  const hideReportAbuseModal = () => setReportAbuseModalVisible(false)

  const [editAnswer] = useMutation(EDIT_ANSWER, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })
  const [deleteQuestion] = useMutation(DELETE_QUESTION, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })
  const [deleteAnswer] = useMutation(DELETE_ANSWER, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })
  const [reportAbuse] = useMutation(REPORT_ABUSE, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })

  const handleActionClick = action => {
    switch (action) {
      case 'change-visibility':
        showVisibilityModal()
        break
      case 'delete-question':
        showDeleteQuestionModal()
        break
      case 'delete-answer':
        showDeleteAnswerModal()
        break
      case 'report-abuse':
        if (ama.reportedAbuse) {
          notification.info({
            message: `You've already reported this`,
            description: `We'll get back to you shortly`,
          })
        } else {
          showReportAbuseModal()
        }
        break
      default:
    }
    if (onActionClick) {
      onActionClick(action, ama)
    }
  }

  const handleChangeVisibility = async visibility => {
    try {
      await editAnswer({
        variables: {
          amaId: ama.id,
          answer: ama.answer,
          visibility,
        },
      })
      hideVisibilityModal()
      notification.success({
        message: 'All good!',
        description: `Your answer is now ${visibility}`,
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
      throw e
    }
  }

  const handleDeleteQuestion = async () => {
    try {
      await deleteQuestion({
        variables: {
          amaId: ama.id,
        },
      })
      hideDeleteQuestionModal()
      notification.success({
        message: `It's gone!`,
        description: 'Your question has been deleted',
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
      throw e
    }
  }

  const handleDeleteAnswer = async () => {
    try {
      await deleteAnswer({
        variables: {
          amaId: ama.id,
        },
      })
      hideDeleteAnswerModal()
      notification.success({
        message: `All righty then!`,
        description: 'Your answer was deleted',
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
      throw e
    }
  }

  const handleReportAbuse = async abuse => {
    try {
      await reportAbuse({
        variables: {
          amaId: ama.id,
          abuse,
        },
      })
      hideReportAbuseModal()
      notification.success({
        message: 'Gotcha!',
        description: 'The question has been reported',
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
      throw e
    }
  }

  return (
    <Card
      className="ask-me-anything-card"
      key={ama.id}
      header={
        <Card.Header
          respondedAt={
            ama.respondedAt ? moment.unix(ama.respondedAt) : undefined
          }
          askedAt={ama.askedAt ? moment.unix(ama.askedAt) : undefined}
          editedAt={ama.editedAt ? moment.unix(ama.editedAt) : undefined}
          asker={ama.asker}
          recipient={ama.recipient}
          visibility={ama.visibility}
          reportedAbuse={ama.reportedAbuse}
          answer={ama.answer}
          status={status}
          onActionClick={action => handleActionClick(action)}
        />
      }
    >
      <AnonymousQuestion>{ama.question}</AnonymousQuestion>

      <Answer
        id={ama.id}
        answer={ama.answer}
        editedAt={ama.editedAt}
        question={ama.question}
        recipient={ama.recipient}
        visibility={ama.visibility}
        draft={ama.draft}
        status={status}
        onEditAnswerSave={onEditAnswerSave}
        onEditAnswerCancel={onEditAnswerCancel}
      />

      <Visibility.Modal
        visible={visibilityModalVisible}
        defaultValue={ama.visibility}
        onOk={handleChangeVisibility}
        onCancel={hideVisibilityModal}
      />

      <ConfirmationModal
        visible={deleteQuestionModalVisible}
        onOk={handleDeleteQuestion}
        onCancel={hideDeleteQuestionModal}
        title="Delete question"
      >
        Are you sure you want to delete this question?
      </ConfirmationModal>

      <ConfirmationModal
        visible={deleteAnswerModalVisible}
        onOk={handleDeleteAnswer}
        onCancel={hideDeleteAnswerModal}
        title="Delete Ask me anything"
      >
        Are you sure you want to do this? Have you considered editing
        the response, or asking the responder to edit their response? When
        the pretty orange Delete button below gets hit,
        all persons involved will be notified.
      </ConfirmationModal>

      <ReportAbuse.Modal
        visible={reportAbuseModalVisible}
        onOk={handleReportAbuse}
        onCancel={hideReportAbuseModal}
      />
    </Card>
  )
}

export default React.memo(AMACard)
