import isEmail from './isEmail'

export default (rule, value, callback) => {
  if (!value) {
    return callback('Please enter an email address')
  }
  if (!isEmail(value.trim())) {
    callback('Please enter a valid email address')
  }
  return callback()
}
