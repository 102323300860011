import './style.less'

import { Progress, Tooltip } from 'antd'
import React, { useState } from 'react'

import HelpIcon from '../../../../_components/HelpIcon'
import { DEFAULT_SPACING } from '../CONSTANTS'

const possibleTypes = {
  positive: {
    name: 'Positive',
    mainColor: '#4BC463',
    textColor: '#2D763B',
  },
  neutral: {
    name: 'Neutral',
    mainColor: '#FFC021',
    textColor: '#B38617',
  },
  negative: {
    name: 'Negative',
    mainColor: '#E01C1C',
    textColor: '#9D1414',
  },
}

export default ({ type, tooltip, percentage, selected = false, onClick }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const sentimentInfo = possibleTypes[type]

  const handleClick = () => {
    if (percentage > 0) {
      onClick()
    }
  }

  const handleMouseEnter = () => {
    if (percentage === 0) {
      setTooltipVisible(true)
    }
  }

  const handleMouseLeave = () => {
    if (percentage === 0) {
      setTooltipVisible(false)
    }
  }

  return (
    <Tooltip title="No responses to show :)" visible={tooltipVisible}>
      <div
        style={{
          border: selected ? 'solid 1px #dadae5' : 'solid 1px transparent',
          borderRadius: '16px',
          cursor: 'pointer',
          margin: '0 16px',
          padding: '16px 16px 24px',
        }}
        className={`sentiment-box sentiment-box--${type}`}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          style={{
            display: 'flex',
            fontSize: '16px',
            justifyContent: 'space-between',
            lineHeight: '24px',
            marginBottom: DEFAULT_SPACING,
          }}
        >
          <div style={{ fontWeight: selected ? 400 : 300 }}>
            {sentimentInfo.name}
            {tooltip && <HelpIcon tooltip={tooltip} />}
          </div>
          <div style={{ color: sentimentInfo.textColor }}>{percentage}%</div>
        </div>
        <Progress
          percent={percentage}
          showInfo={false}
          strokeColor={sentimentInfo.mainColor}
          strokeWidth={12}
        />
      </div>
    </Tooltip>
  )
}
