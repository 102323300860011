import React from 'react'

const Skeleton = ({ style }) => {
  return (
    <div className="colleague-list__skeleton-container" style={style}>
      <div className="colleague-list__skeleton-avatar"></div>
      <div className="colleague-list__skeleton-content">
        <div className="colleague-list__skeleton-bar colleague-list__skeleton-bar--thick colleague-list__skeleton-bar--short"></div>
        <div className="colleague-list__skeleton-bar"></div>
      </div>
    </div>
  )
}

export default Skeleton
