import gql from 'graphql-tag'

export const UPDATE_ORG = gql`
  mutation($id: String!, $changes: UpdateOrgInput!) {
    updateOrg(id: $id, changes: $changes) {
      id
      name
      data
    }
  }
`
