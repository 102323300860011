import React, { forwardRef } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'antd'
import InputRadio from '../InputRadio'

const options = [
  {
    label: 'Publicly',
    value: 'public',
    hint: 'Anyone in the company can see your answer',
    icon: <FontAwesomeIcon icon={['fas', 'users']} style={{ width: '30px' }} />,
  },
  {
    label: 'Privately',
    value: 'private',
    hint: 'Only the one who asked you the question can see your answer',
    icon: <FontAwesomeIcon icon={['fas', 'user']} style={{ width: '30px' }} />,
  },
]

const VisibilityForm = ({ form, defaultValues = {}, ...props }, ref) => {
  const { getFieldDecorator } = form

  return (
    <Form {...props} ref={ref}>
      <Form.Item>
        {getFieldDecorator('visibility', {
          initialValue: defaultValues.visibility
            ? defaultValues.visibility
            : 'public',
          required: true,
        })(
          <InputRadio>
            {options.map((option, i) => (
              <InputRadio.Item
                key={i}
                icon={option.icon}
                label={option.label}
                hint={option.hint}
                value={option.value}
              />
            ))}
          </InputRadio>,
        )}
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'VisiblityForm' })(
  forwardRef(VisibilityForm),
)
