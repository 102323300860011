import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'

import Section from '../../Section'
import Table from '../../Table'
import { EVENTS_THREE_SIXTY } from './queries'
import TableColumns from './TableColumns'

const PAGE_SIZE = 10

export default ({ timeInterval, isActive }) => {
  const [loading, setLoading] = useState(true)

  const [queryVariables, setQueryVariables] = useState({
    startDate: timeInterval[0],
    endDate: timeInterval[1],
    offset: 0,
    limit: PAGE_SIZE,
  })

  const { data } = useQuery(EVENTS_THREE_SIXTY, {
    fetchPolicy: 'cache-first',
    variables: queryVariables,
    skip: !isActive,
    onCompleted: () => {
      setLoading(false)
    },
  })

  useEffect(() => {
    if (data) {
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (
      timeInterval[0] === queryVariables.startDate &&
      timeInterval[1] === queryVariables.endDate
    ) {
      return
    }
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    })
  }, [timeInterval])

  const handlePageChange = page => {
    setLoading(true)
    setQueryVariables({
      ...queryVariables,
      offset: PAGE_SIZE * (page - 1),
    })
  }

  return (
    <Section title="Events">
      <Table
        onPageChange={handlePageChange}
        columns={TableColumns}
        loading={loading}
        data={
          data &&
          data.eventsThreeSixtyInsight &&
          data.eventsThreeSixtyInsight.items
        }
        rowKey={record => record.id}
        total={
          data &&
          data.eventsThreeSixtyInsight &&
          data.eventsThreeSixtyInsight.total
        }
        itemsName="events"
        noHover
      />
    </Section>
  )
}
