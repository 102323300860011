import gql from 'graphql-tag'

export const CHECK_PASSWORD_STRENGTH = gql`
  query checkPasswordStrength($password: String!) {
    checkPasswordStrength(password: $password) {
      strength
      reason
      suggestions
    }
  }
`
