import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card as AntCard, Tooltip } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import GSuiteIcon from '../../_assets/img/integrations/gsuite.svg'
import PersonioIcon from '../../_assets/img/integrations/personio.svg'
import SlackIcon from '../../_assets/img/integrations/slack.svg'
import TeamsIcon from '../../_assets/img/integrations/teams.svg'
import { UserContext } from '../../UserContext'

const Card = props => {
  return (
    <div className="admin-card">
      <AntCard {...props}></AntCard>
    </div>
  )
}

const IntegrationCard = ({ integration }) => {
  const user = useContext(UserContext)

  let cardIcon = null

  const isIntegrationDisabled = !!user.org.hasSAML

  switch (integration.type) {
    case 'slack':
      cardIcon = <img src={SlackIcon} alt="Slack" />
      break

    case 'teams':
      cardIcon = <img src={TeamsIcon} alt="Microsoft Teams" />
      break

    case 'gsuite':
      cardIcon = <img src={GSuiteIcon} alt="GSuite" />
      break

    case 'personio':
      cardIcon = <img src={PersonioIcon} alt="Personio" />
      break

    default:
      break
  }

  if (integration.isComingSoon) {
    return (
      <Tooltip title="Coming soon">
        <Card bodyStyle={{ opacity: 0.3 }}>
          {cardIcon}
          {integration.name}
        </Card>
      </Tooltip>
    )
  } else if (isIntegrationDisabled && integration.type !== 'personio') {
    return (
      <Tooltip title="Not available for your account">
        <Card bodyStyle={{ opacity: 0.3 }}>
          {cardIcon}
          {integration.name}
        </Card>
      </Tooltip>
    )
  } else {
    return (
      <Link to={'/admin/integrations/' + integration.type}>
        <Card>
          {cardIcon}
          {integration.name}
        </Card>
      </Link>
    )
  }
}

const AdminCard = ({ type }) => {
  switch (type) {
    case 'insights_feature_usage':
      return (
        <Card>
          <FontAwesomeIcon
            icon={['fal', 'user-clock']}
            style={{
              width: '1em',
              marginRight: 12,
              color: '#c9c6d5',
              fontSize: 32,
            }}
          />
          Feature usage
        </Card>
      )

    case 'people':
      return (
        <Card>
          <FontAwesomeIcon
            icon={['fal', 'address-book']}
            style={{
              width: '1em',
              marginRight: 12,
              color: '#c9c6d5',
              fontSize: 32,
            }}
          />
          People
        </Card>
      )

    case 'teams':
      return (
        <Card>
          <FontAwesomeIcon
            icon={['fal', 'users-class']}
            style={{
              width: '1em',
              marginRight: 12,
              color: '#c9c6d5',
              fontSize: 32,
            }}
          />
          Teams
        </Card>
      )

    case 'company':
      return (
        <Card>
          <FontAwesomeIcon
            icon={['fal', 'building']}
            style={{
              width: '1em',
              marginRight: 12,
              color: '#c9c6d5',
              fontSize: 32,
            }}
          />
          Company
        </Card>
      )

    case 'notifications':
      return (
        <Card>
          <FontAwesomeIcon
            icon={['fal', 'bell']}
            style={{
              width: '1em',
              marginRight: 12,
              color: '#c9c6d5',
              fontSize: 32,
            }}
          />
          Notifications
        </Card>
      )

    default:
      return null
  }
}

export default ({ type, integration, link }) => {
  if (type === 'integration') {
    return <IntegrationCard integration={integration} />
  } else {
    return (
      <Link to={link}>
        <AdminCard type={type} />
      </Link>
    )
  }
}
