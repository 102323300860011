import { cloneElement } from 'react'

const Container = ({ value, onChange, children }) => {
  const handleOnClick = (val, evt) => {
    evt.currentTarget.focus()
    if (val === value) {
      return
    }
    onChange(val)
  }

  const handleOnFocus = (val, evt) => {
    if (val === value) {
      return
    }
    onChange(val)
  }

  return children.map((child, i) => {
    return cloneElement(child, {
      tabIndex: i + 1,
      selected: child.props.value === value,
      onFocus: evt => handleOnFocus(child.props.value, evt),
      onClick: evt => handleOnClick(child.props.value, evt),
    })
  })
}

export default Container
