import { Button } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import AvatarPlusList from '../../../_components/AvatarPlusList'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import { findEnrolledManagees } from '../../pages/helpers'
import { getCycleBaseUrl } from '../../pages/helpers'
import ColleaguesReceivedNoFeedback from '../ColleaguesReceivedNoFeedback'
import { VERTICAL_MARGIN_DOUBLE } from './CONSTANTS'

const CONTAINER_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: VERTICAL_MARGIN_DOUBLE,
}

const TITLE_STYLE = { marginBottom: VERTICAL_MARGIN_DOUBLE, color: '#77779f' }

const COLLEAGUES_STYLE = {
  color: '#1c1047',
  fontFamily: 'museo',
  fontWeight: 300,
}

const RowOtherFeedback = ({ cycle }) => {
  const currentUser = useContext(UserContext)

  const path = getCycleBaseUrl(cycle.type)

  const enrolledManagees = findEnrolledManagees({ cycle, user: currentUser })

  if (!enrolledManagees) {
    return <></>
  }

  const hasManageesForViewwing =
    enrolledManagees.filter(x => {
      return x.requests.length > 0
    }).length > 0

  const handleViewFeedbackClick = () => {
    track(path.substring(1) + '.feedback.viewDirectReport', {
      user: currentUser.email,
      org: currentUser.org.name,
      eventId: cycle.id,
    })
  }

  return (
    <div style={CONTAINER_STYLE}>
      <div>
        <div style={TITLE_STYLE}>Other people's feedback you can view</div>
        {hasManageesForViewwing ? (
          <>
            <AvatarPlusList users={enrolledManagees} limit={6} />
            <div style={COLLEAGUES_STYLE}>
              {enrolledManagees.length} colleague
              {enrolledManagees.length !== 1 && 's'}
            </div>
          </>
        ) : (
          <ColleaguesReceivedNoFeedback
            numColleagues={enrolledManagees ? enrolledManagees.length : 0}
          />
        )}
      </div>
      {hasManageesForViewwing && (
        <div>
          <Link
            to={{
              pathname: path + '/viewFeedbackManager/' + cycle.id,
            }}
            onClick={handleViewFeedbackClick}
          >
            <Button type="light-purple">View feedback</Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default RowOtherFeedback
