import { useMutation, useQuery } from '@apollo/react-hooks'
import { Modal, Spin } from 'antd'
import { uniq } from 'lodash'
import React, { useRef, useState } from 'react'

import { GET_TEAMS_LIST } from '../queries'
import { GET_USERS_WITHOUT_A_TEAM } from '../TeamsList/queries'
import EditTeamForm from './EditTeamForm'
import { EDIT_TEAM, GET_TEAM } from './queries'

export default ({ teamId, visible, onOk, onError, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [okDisabled, setOkDisabled] = useState(true)
  const formRef = useRef()

  const { data: teamData, loading: teamLoading } = useQuery(GET_TEAM, {
    variables: {
      id: teamId,
    },
    fetchPolicy: 'cache-network',
  })

  const [editTeam] = useMutation(EDIT_TEAM, {
    refetchQueries: [
      { query: GET_TEAMS_LIST },
      { query: GET_TEAM, variables: { id: teamId } },
      { query: GET_USERS_WITHOUT_A_TEAM },
    ],
  })

  const handleSubmit = () => {
    formRef.current.validateFields(async (err, values) => {
      if (err) return

      try {
        setLoading(true)

        const refetchQueries = [
          { query: GET_TEAMS_LIST },
          { query: GET_TEAM, variables: { id: teamId } },
          { query: GET_USERS_WITHOUT_A_TEAM },
        ]

        const newUsersWithExistingTeam = values.members.filter(
          x => x.team && x.team.id !== teamId,
        )
        if (newUsersWithExistingTeam.length > 0) {
          refetchQueries.push(
            ...uniq(newUsersWithExistingTeam.map(x => x.team.id)).map(x => {
              return { query: GET_TEAM, variables: { id: x } }
            }),
          )
        }

        await editTeam({
          variables: {
            teamId,
            teamName: values.teamName,
            teamMembers: values.members.map(x => x.id),
          },
          refetchQueries,
        })
        setLoading(false)
        if (onOk) {
          onOk()
        }
      } catch (e) {
        setLoading(false)
        if (onError) {
          onError(e)
        }
      }
    })
  }

  const handleChange = e => {
    setOkDisabled(false)
  }

  const handleOnCancel = e => {
    formRef.current.resetFields()
    if (onCancel) {
      onCancel(e)
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <Modal
        title="Edit team"
        visible={visible}
        onCancel={handleOnCancel}
        onOk={handleSubmit}
        okButtonProps={{
          disabled: okDisabled,
          type: 'accent',
        }}
        confirmLoading={loading}
        okText="Save changes"
        cancelText="Cancel"
        cancelButtonProps={{ disabled: loading, type: 'ghost' }}
        closable={false}
        maskClosable={false}
        bodyStyle={{ paddingBottom: '8px', paddingTop: '32px' }}
        centered
      >
        {teamLoading && <Spin />}
        {!teamLoading && teamData && (
          <EditTeamForm
            team={teamData.team}
            handleChange={handleChange}
            ref={formRef}
          />
        )}
      </Modal>
    </div>
  )
}
