import gql from 'graphql-tag'

export const EVENTS_PRIVATE_FEEDBACK = gql`
  query eventsPrivateFeedbackInsight(
    $startDate: String
    $endDate: String
    $offset: Int
    $limit: Int
  ) {
    eventsPrivateFeedbackInsight(
      startDate: $startDate
      endDate: $endDate
      offset: $offset
      limit: $limit
    ) {
      id
      items {
        id
        cycle {
          id
          name
          startDate
          endDate
          creator {
            id
            avatarUrl
            firstName
            lastName
            jobTitle
            email
            status
          }
        }
        stats {
          id
          key
          value
        }
      }
      total
    }
  }
`
