import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Breadcrumb, Card, Col, Row, Typography, notification } from 'antd'
import React, { useContext, useEffect } from 'react'
import Emoji from 'react-emoji-render'
import { Link, useHistory } from 'react-router-dom'

import PageTitle from '../../../_components/PageTitle'
import useQuery from '../../../hooks/routing/useQuery'
import IntegrationsContext from '../context'
import ActionButton from './ActionButton'
import StatusIndicator from './StatusIndicator'

const { Paragraph, Title } = Typography

const TITLE_STYLE = { fontSize: 16, lineHeight: '28px', marginBottom: 16 }

const ListItem = ({ children }) => {
  return (
    <li>
      <FontAwesomeIcon
        icon={['fas', 'check-circle']}
        style={{ color: '#77779f', fontSize: '20px', marginRight: '6px' }}
      />
      <div style={{ fontSize: 16 }}>{children}</div>
    </li>
  )
}

export default () => {
  const integrationsContext = useContext(IntegrationsContext)

  const history = useHistory()

  const queryParams = useQuery()
  const queryCode = queryParams.get('code')
  const queryState = queryParams.get('state')
  const queryError = queryParams.get('error')

  const basePath = `${document.location.protocol}//${document.location.host}`
  useEffect(() => {
    if (queryError) {
      if (queryError === 'access_denied') {
        notification.error({
          description:
            'You need to allow access to your Slack workspace. Please try again',
          message: `That didn't work out as planned`,
        })
      } else {
        notification.error({
          description: 'There was an error. Please try again',
          message: `That didn't work out as planned`,
        })
      }
      history.replace(window.location.pathname)
      integrationsContext.fetch()
    } else if (queryCode && queryState) {
      integrationsContext
        .installSlackIntegration({
          code: queryCode,
          state: queryState,
          redirectUrl: `${basePath}/admin/integrations/slack`,
        })
        .then(() => {
          notification.success({
            description: 'Howamigoing is now added to your Slack workspace',
            message: `Great success!`,
          })
        })
        .catch(err => {
          if (err.message) {
            notification.error({
              description: err.message,
              message: `That didn't work out as planned`,
            })
          } else {
            notification.error({
              description: 'There was an error. Please try again',
              message: `That didn't work out as planned`,
            })
          }
        })
        .finally(() => {
          history.replace(window.location.pathname)
        })
    } else {
      integrationsContext.fetch()
    }
  }, [])

  return (
    <div className="integration">
      <Breadcrumb style={{ marginBottom: 8 }}>
        <Breadcrumb.Item>
          <Link to="/admin">Admin</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/admin">Integrations</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Slack</Breadcrumb.Item>
      </Breadcrumb>
      <PageTitle style={{ marginBottom: 24 }}>Slack</PageTitle>
      <Row type="flex" align="top">
        <Col span={16}>
          <Paragraph style={{ fontWeight: 300, marginBottom: 32 }}>
            Slack replaces email for many teams. So if you’d rather receive your
            Howamigoing notifications via Slack, now you can.{' '}
            <Emoji
              text=":raised_hands:"
              style={{ fontSize: 20, lineHeight: '24px', marginLeft: 4 }}
            />
          </Paragraph>
        </Col>
        <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {queryCode || queryState ? <span>Loading...</span> : <ActionButton />}
        </Col>
      </Row>
      <Title level={4} style={{ marginBottom: 16 }}>
        What does Howamigoing’s Slack integration do?
      </Title>
      <Paragraph style={{ fontWeight: 300, marginBottom: 20 }}>
        Add our totally awesome Slack integration for your team to:
      </Paragraph>
      <Row type="flex" align="stretch" style={{ marginBottom: 32 }} gutter={32}>
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Title level={4} style={TITLE_STYLE}>
              Simplify access to Howamigoing
            </Title>
            <ul>
              <ListItem>Sign in with 1 click using Slack</ListItem>
              <ListItem>Invite people from your Slack workspace</ListItem>
            </ul>
          </Card>
        </Col>
        <Col span={12}>
          <Card style={{ height: '100%' }}>
            <Title level={4} style={TITLE_STYLE}>
              Get notified in Slack
            </Title>
            <ul>
              <ListItem>When you need to ask for feedback</ListItem>
              <ListItem>When someone asks you to give feedback</ListItem>
              <ListItem>When feedback is ready to view</ListItem>
            </ul>
          </Card>
        </Col>
      </Row>
      <Title level={4} style={{ marginBottom: 10 }}>
        Status:
      </Title>
      {queryCode || queryState ? <span>Loading...</span> : <StatusIndicator />}
    </div>
  )
}
