import React from 'react'

const VibeStatement = ({ heading, statement }) => {
  return (
    <div>
      <h3 className="good-vibe-card__statement-heading">{heading}</h3>
      <div className="good-vibe-card__statement-body">{statement}</div>
    </div>
  )
}

export default VibeStatement
