import React from 'react'

import Personal360Overview from '../modals/Overview/personal360'
import Team360Overview from '../modals/Overview/team360'
import CreateEdit from './CreateEdit'
import CreateEditStepConfirm from './CreateEdit/StepConfirm'
import CreateEditStepDetails from './CreateEdit/StepDetails'
import CreateEditStepEnrolment from './CreateEdit/StepRequest'
import CreateEditStepSelectForm from './CreateEdit/StepSelectForm'
import CycleRoutes from './CycleRoutes'
import GiveFeedback from './Give/'
import CompletedFeedback from './List/Completed'
import OngoingFeedback from './List/Ongoing'
import ScheduledFeedback from './List/Scheduled'
import RequestFeedback from './Request'
import ViewManagerFeedback from './View/ViewManagerFeedback/ViewManagerFeedback'
import ViewSingleFeedback from './View/ViewSingleFeedback'

export const Team360CreateEditRoutes = {
  path: ['/team360/create', '/team360/edit/:cycleId'],
  component: CreateEdit,
  routes: [
    {
      path: ['/team360/create/1', '/team360/edit/:cycleId/1'],
      component: CreateEditStepSelectForm,
    },
    {
      path: ['/team360/create/2', '/team360/edit/:cycleId/2'],
      component: CreateEditStepEnrolment,
    },
    {
      path: ['/team360/create/3', '/team360/edit/:cycleId/3'],
      component: CreateEditStepDetails,
    },
    {
      path: ['/team360/create/4', '/team360/edit/:cycleId/4'],
      component: CreateEditStepConfirm,
    },
  ],
}

export const Team360Routes = {
  path: '/team360',
  component: CycleRoutes,
  routes: [
    {
      path: '/team360/ongoing',
      component: OngoingFeedback,
      menuKey: 'ongoing',
      routes: [
        {
          path: '/team360/ongoing/:cycleId/progress',
          component: ({ match, history }) => {
            const cycleId = match.params.cycleId

            const handleModalClose = () => {
              history.push('/team360/ongoing')
            }

            return (
              <Team360Overview
                cycleId={cycleId}
                showModal={true}
                handleCloseModal={handleModalClose}
              />
            )
          },
        },
      ],
    },
    {
      path: '/team360/scheduled',
      component: ScheduledFeedback,
      menuKey: 'scheduled',
    },
    {
      path: '/team360/completed',
      component: CompletedFeedback,
      menuKey: 'completed',
    },
    {
      path: '/team360/requestFeedback/:id',
      component: RequestFeedback,
    },
    {
      path: '/team360/viewFeedback/:reviewId',
      component: ViewSingleFeedback,
    },
    {
      path: '/team360/viewFeedbackManager/:cycleId',
      component: ViewManagerFeedback,
    },
    {
      path: '/team360/giveFeedback/:id',
      component: GiveFeedback,
    },
    Team360CreateEditRoutes,
  ],
}

export const Personal360CreateEditRoutes = {
  path: ['/personal360/create', '/personal360/edit/:cycleId'],
  component: CreateEdit,
  routes: [
    {
      path: ['/personal360/create/1', '/personal360/edit/:cycleId/1'],
      component: CreateEditStepSelectForm,
    },
    {
      path: ['/personal360/create/2', '/personal360/edit/:cycleId/2'],
      component: CreateEditStepEnrolment,
    },
    {
      path: ['/personal360/create/3', '/personal360/edit/:cycleId/3'],
      component: CreateEditStepDetails,
    },
    {
      path: ['/personal360/create/4', '/personal360/edit/:cycleId/4'],
      component: CreateEditStepConfirm,
    },
  ],
}

export const Personal360Routes = {
  path: '/personal360',
  component: CycleRoutes,
  routes: [
    {
      path: '/personal360/ongoing',
      component: OngoingFeedback,
      menuKey: 'ongoing',
      routes: [
        {
          path: '/personal360/ongoing/:cycleId/progress',
          component: ({ cycles, match, history }) => {
            if (!cycles) {
              return null
            }
            const cycleId = match.params.cycleId
            const cycle = cycles.find(x => x.id === cycleId)
            if (!cycle) {
              return null
            }

            const handleModalClose = () => {
              history.push('/personal360/ongoing')
            }

            return (
              <Personal360Overview
                cycle={cycle}
                showModal={true}
                handleCloseModal={handleModalClose}
              />
            )
          },
        },
      ],
    },
    {
      path: '/personal360/scheduled',
      component: ScheduledFeedback,
      menuKey: 'scheduled',
    },
    {
      path: '/personal360/completed',
      component: CompletedFeedback,
      menuKey: 'completed',
    },
    {
      path: '/personal360/viewFeedback/:reviewId',
      component: ViewSingleFeedback,
    },
    {
      path: '/personal360/giveFeedback/:id',
      component: GiveFeedback,
    },
    Personal360CreateEditRoutes,
  ],
}

export const PulseCreateEditRoutes = {
  path: ['/pulse/create', '/pulse/edit/:cycleId'],
  component: CreateEdit,
  routes: [
    {
      path: ['/pulse/create/1', '/pulse/edit/:cycleId/1'],
      component: CreateEditStepSelectForm,
    },
    {
      path: ['/pulse/create/2', '/pulse/edit/:cycleId/2'],
      component: CreateEditStepEnrolment,
    },
    {
      path: ['/pulse/create/3', '/pulse/edit/:cycleId/3'],
      component: CreateEditStepDetails,
    },
    {
      path: ['/pulse/create/4', '/pulse/edit/:cycleId/4'],
      component: CreateEditStepConfirm,
    },
  ],
}

export const PulseRoutes = {
  path: '/pulse',
  component: CycleRoutes,
  routes: [
    {
      path: '/pulse/ongoing',
      component: OngoingFeedback,
      menuKey: 'ongoing',
    },
    {
      path: '/pulse/scheduled',
      component: ScheduledFeedback,
      menuKey: 'scheduled',
    },
    {
      path: '/pulse/completed',
      component: CompletedFeedback,
      menuKey: 'completed',
    },
    {
      path: '/pulse/viewFeedback/:reviewId',
      component: ViewSingleFeedback,
    },
    {
      path: '/pulse/giveFeedback/:id',
      component: GiveFeedback,
    },
    PulseCreateEditRoutes,
  ],
}
