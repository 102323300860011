import React from 'react'
import { Link } from 'react-router-dom'

import { VERTICAL_MARGIN_DOUBLE } from '../CONSTANTS'
import YouReceivedNoFeedback from '../YouReceivedNoFeedback'

const STYLE = {
  marginBottom: VERTICAL_MARGIN_DOUBLE,
  color: '#77779f',
}

const LINK_STYLE = {
  color: '#f95c4b',
  textDecoration: 'underline',
}

export default () => {
  return (
    <div style={STYLE}>
      <YouReceivedNoFeedback />
      <p>
        Want to{' '}
        <Link to="/personal360/create/1" style={LINK_STYLE}>
          try again
        </Link>
        ?
      </p>
    </div>
  )
}
