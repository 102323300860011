import React from 'react'

const ShowPeopleAction = ({ onClick }) => {
  return (
    <span className="stats__action-link" onClick={onClick}>
      Show people
    </span>
  )
}

export default ShowPeopleAction
