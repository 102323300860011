import { Spin } from 'antd'
import React from 'react'

const SpinContainer = ({ loading, children }) => {
  return (
    <div className="ant-spin-nested-loading">
      {loading && (
        <Spin
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      <div className={`ant-spin-container${loading ? ' ant-spin-blur' : ''}`}>
        {children}
      </div>
    </div>
  )
}

export default SpinContainer
