import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { List, Row, Tooltip, notification } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import QuestionCircle from '../../../../_assets/img/question-circle.svg'
import { track } from '../../../../_helpers/analytics'
import { UserContext } from '../../../../UserContext'
import FormCard from '../../../components/FormCard'
import PreviewForm from '../../../components/PreviewForm'
import SentimentAnalysisBanner from '../../../components/SentimentAnalysisBanner'
import { getCycleBaseUrl, getCycleTypeFromUrl } from '../../helpers'
import ButtonsBottom from '../components/CycleCreationEditButtonsBottom'
import Context from '../Context'
import StepsContext from '../Context/StepsContext'
import { GET_FORMS } from '../queries'

const CategoryTitleStyle = {
  fontFamily: 'Europa, sans-serif',
  fontWeight: 'normal',
  fontSize: 16,
  color: '#77779f',
  marginBottom: 24,
  lineHeight: '1em',
}

export default props => {
  const user = useContext(UserContext)
  const context = useContext(Context)
  const stepsContext = useContext(StepsContext)

  const location = useLocation()

  const [defaultForms, setDefaultForms] = useState([])
  const [orgForms, setOrgForms] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [modalForm, setModalForm] = useState({})

  const cycleType = getCycleTypeFromUrl(location)
  const typePath = getCycleBaseUrl(cycleType)

  const { data, loading, error } = useQuery(GET_FORMS, {
    variables: {
      type: cycleType,
    },
  })

  useEffect(() => {
    if (data) {
      if (data.defaultForms && data.defaultForms.length > 0) {
        setDefaultForms(data.defaultForms)
      }
      const customForms = [
        ...data.formsFromOrg,
        {
          id: 'createForm',
          title: '+ Create new form',
          type: cycleType,
        },
      ]
      setOrgForms(customForms)

      if (location.state && location.state.formId) {
        const formToPreview =
          data.defaultForms.find(form => form.id === location.state.formId) ||
          data.formsFromOrg.find(form => form.id === location.state.formId)

        if (formToPreview) {
          setModalForm(formToPreview)
          setModalVisible(true)
        }
      }
    }
  }, [data])

  const handleContinueClick = () => {
    if (context.form) {
      track(`${cycleType}.cycle.selectFormNextStep`, {
        user: user.email,
        org: user.org.name,
        formName: context.form.title,
        formId: context.form.id,
      })
      const newUrl = context.id
        ? `${typePath}/edit/${context.id}/2`
        : `${typePath}/create/2`
      stepsContext.setStep(1)
      props.history.push(newUrl)
    } else {
      notification.warning({
        message: 'Oops!',
        description: 'Please select a form before continuining.',
      })
      throw new Error()
    }
  }

  const showModalForForm = form => {
    if (form.id !== 'createForm') {
      setModalForm(form)
      setModalVisible(true)
    }
  }

  const handleFormSelection = () => {
    context.setContext({ form: modalForm })
    setModalVisible(false)

    track(`${cycleType}.cycle.selectFormFromPreview`, {
      user: user.email,
      org: user.org.name,
      formName: modalForm.title,
      formId: modalForm.id,
    })
  }

  const toggleFormSelection = form => {
    if (form.id !== 'createForm') {
      context.setContext({
        form: context.form && form.id === context.form.id ? null : form,
      })
      if (context.form && form.id !== context.form.id) {
        track(`${cycleType}.cycle.selectForm`, {
          user: user.email,
          org: user.org.name,
          formName: form.title,
          formId: form.id,
        })
      }
    }
  }

  const onCreateFormClick = () => {
    track(`${cycleType}.cycle.createForm`, {
      user: user.email,
      org: user.org.name,
      roles:
        user.userRoles &&
        user.userRoles.length > 0 &&
        user.userRoles.map(userRole => userRole.role.name).join(','),
    })
  }

  const groupedByCategory = defaultForms.reduce((memo, form) => {
    const categoryId = form.category.id
    if (!memo[categoryId]) {
      memo[categoryId] = {
        category: form.category,
        forms: [form],
      }
      return memo
    }
    memo[categoryId].forms.push(form)
    return memo
  }, {})

  const sortCategories = (a, b) => (a.category.name > b.category.name ? 1 : -1)

  return (
    <div className="new-cycle-select-form">
      <Row>
        {loading ? (
          <span>Loading your forms...</span>
        ) : error ? (
          <span>There was an error loading your forms.</span>
        ) : (
          <div>
            <SentimentAnalysisBanner target="form" />
            {Object.keys(groupedByCategory)
              .map(categoryId => groupedByCategory[categoryId])
              .sort(sortCategories)
              .map(item => {
                return (
                  <Row style={{ marginBottom: 32 }} key={item.category.id}>
                    <div style={CategoryTitleStyle}>
                      {item.category.name}
                      {item.category.description && (
                        <Tooltip
                          title={item.category.description}
                          overlayClassName="tooltip--dark"
                        >
                          <img
                            src={QuestionCircle}
                            alt="explanation"
                            style={{
                              cursor: 'pointer',
                              height: 16,
                              marginLeft: 6,
                              width: 16,
                            }}
                          />
                        </Tooltip>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'grid',
                        gridAutoRows: '1fr',
                        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
                        rowGap: 16,
                        columnGap: 16,
                      }}
                      className="form-list"
                    >
                      {item.forms.map((defaultForm, i) => (
                        <FormCard
                          key={defaultForm.id}
                          data-cy={`default-form-${i + 1}`}
                          form={defaultForm}
                          contextFormId={context.form ? context.form.id : false}
                          showModalForForm={showModalForForm}
                          toggleFormSelection={toggleFormSelection}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        />
                      ))}
                    </div>
                  </Row>
                )
              })}
            {orgForms && orgForms.length > 0 && (
              <Row>
                <div style={CategoryTitleStyle}>My custom forms</div>
                <List
                  className="form-list"
                  size="large"
                  itemLayout="vertical"
                  grid={{ gutter: 16, column: 3 }}
                  dataSource={orgForms}
                  renderItem={orgForm => (
                    <List.Item key={orgForm.id}>
                      <FormCard
                        form={orgForm}
                        formType={cycleType}
                        contextFormId={context.form ? context.form.id : false}
                        showModalForForm={showModalForForm}
                        toggleFormSelection={toggleFormSelection}
                        onCreateFormClick={onCreateFormClick}
                      />
                    </List.Item>
                  )}
                />
              </Row>
            )}
          </div>
        )}
      </Row>

      <PreviewForm
        form={modalForm}
        modalVisible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSelect={handleFormSelection}
      />
      <ButtonsBottom
        step={1}
        handleContinueClick={handleContinueClick}
        contextId={context.id}
        fromWBYHT={context.fromWBYHT}
      />
    </div>
  )
}
