import { Modal } from 'antd'
import React, { useRef, useState } from 'react'

import InfoBanner from '../../../_components/InfoBanner'
import Form from './Form'

const SendGoodVibeModal = ({
  visible,
  title = 'Send Good vibes',
  okText = 'Send Good vibes',
  onOk,
  cancelText = 'Cancel',
  onCancel,
  defaultValues = {
    id: null,
    recipient: null,
    fact: null,
    feeling: null,
  },
  lastSyncedAt,
  remoteSaver,
}) => {
  const formRef = useRef(null)

  const [disabledForm, setDisabledForm] = useState(false)
  const [oking, setOking] = useState(false)

  const handleSubmit = () => {
    setDisabledForm(true)
    setOking(true)
    formRef.current.validateFields(async (errors, values) => {
      try {
        if (errors) {
          setDisabledForm(false)
          setOking(false)
          return
        }
        if (onOk) {
          await onOk({
            recipientId: values.recipient.id,
            fact: values.fact,
            feeling: values.feeling,
          })
        }
        setDisabledForm(false)
        setOking(false)
        clearFormDraft()
      } catch (e) {
        setDisabledForm(false)
        setOking(false)
      }
    })
  }

  const clearFormDraft = () => {
    if (
      formRef.current.clearFormDraft &&
      (!defaultValues || (defaultValues && !defaultValues.id))
    ) {
      formRef.current.clearFormDraft()
    }
  }

  return (
    <Modal
      title={title}
      visible={visible}
      okText={okText}
      onOk={handleSubmit}
      okButtonProps={{ loading: oking, disabled: disabledForm, type: 'accent' }}
      cancelText={cancelText}
      cancelButtonProps={{ type: 'ghost', disabled: disabledForm }}
      onCancel={onCancel}
      width={560}
      destroyOnClose={true}
      closable={!disabledForm}
      centered
    >
      <Form
        ref={formRef}
        defaultValues={defaultValues}
        entityId={
          defaultValues && defaultValues.id ? defaultValues.id : 'new_good_vibe'
        }
        serverData={{
          recipientId: defaultValues.recipientId,
          fact: defaultValues.fact,
          feeling: defaultValues.feeling,
        }}
        lastSyncedAt={lastSyncedAt}
        saver={remoteSaver}
        registerClearDraft
      />
      <InfoBanner
        info={`Please don't reveal anything confidential. This post will appear on the
        Good vibes wall where others can see it and contribute.`}
        style={{ marginTop: '20px' }}
      />
    </Modal>
  )
}

export default SendGoodVibeModal
