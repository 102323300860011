import { Empty } from 'antd'
import React from 'react'

const NoResults = ({ description = 'No results found', style }) => {
  return (
    <div className="colleague-list__no-results-container" style={style}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />
    </div>
  )
}

export default NoResults
