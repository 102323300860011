import React from 'react'
import Emoji from 'react-emoji-render'

import { CYCLE_TYPES } from '../../pages/helpers'

const YouReceivedNoFeedback = ({ cycleType }) => {
  return (
    <>
      <p>
        Oh no! You didn't get enough feedback.{' '}
        <Emoji text=":woman-facepalming:" />
      </p>
      <p>
        {cycleType === CYCLE_TYPES.pulse ? (
          <>
            You need at least 2 people to respond to your pulse survey and you
            didn't get enough responses this time.
          </>
        ) : (
          <>
            You need at least 2 people to respond to your request for feedback
            and you didn't get enough responses this time.
          </>
        )}
      </p>
    </>
  )
}

export default YouReceivedNoFeedback
