import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { Input } from 'antd'
import React, { useState } from 'react'
import Emoji from 'react-emoji-render'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import CardTable from '../../../_components/CardTable'
import PageTitle from '../../../_components/PageTitle'
import { PaginationMeta } from '../../../_components/Pagination'
import { GET_USERS } from '../../People/PeopleList/queries'
import TableColumns from './TableColumns'

const PeopleList = () => {
  const [page, setPage] = useState(1)
  const [searchParams, setSearchParams] = useState({
    types: ['standard', 'admin', 'owner', 'onboarding'],
  })
  const [debouncedSearchChange] = useDebouncedCallback(val => {
    onSearchChange(val)
  }, 500)
  const pageSize = 10
  const offset = page * pageSize - pageSize

  const history = useHistory()

  const {
    data: paginatedUsers,
    loading: loadingUsers,
    refetch: refetchUsers,
  } = useQuery(GET_USERS, {
    fetchPolicy: 'no-cache',
    variables: {
      offset,
      limit: pageSize,
      search: searchParams,
      page: 'adminInsights'
    },
    notifyOnNetworkStatusChange: true,
  })

  const users = paginatedUsers ? paginatedUsers.users.users : []
  const numUsers = paginatedUsers ? paginatedUsers.users.total : 0

  const onSearchChange = val => {
    const newSearch = { name: val, email: val }
    setSearchParams({
      ...newSearch,
    })
    setPage(1)
    refetchUsers({
      variables: {
        offset,
        page,
        search: newSearch,
      },
    })
  }

  const handleOnPaginationChange = page => {
    const newOffset = page * pageSize - pageSize
    setPage(page)
    refetchUsers({
      variables: {
        limit: pageSize,
        offset: newOffset,
        search: searchParams,
      },
    })
  }

  const handleRowClick = record => {
    history.push('/profile/' + record.id, { user: record })
  }

  return (
    <div className="activity-people-container">
      <PageTitle
        subtitle={
          <>
            See who the feedback heroes are in your company{' '}
            <Emoji text=":superhero:" style={{ fontSize: 18, marginLeft: 2 }} />
          </>
        }
      >
        Colleague activity
      </PageTitle>

      <div style={{ marginBottom: '24px' }}>
        <Input
          suffix={false}
          placeholder={'Search by name or email'}
          className="people-list__search-input"
          onChange={evt => debouncedSearchChange(evt.target.value)}
        />
      </div>

      <CardTable
        dataSource={users}
        columns={TableColumns}
        rowKey={record => record.id}
        loading={loadingUsers}
        pagination={{
          defaultPageSize: pageSize,
          showSizeChanger: false,
          pageSizeOptions: ['10'],
          onChange: handleOnPaginationChange,
          total: numUsers,
          current: page,
          showTotal: (total, range) => (
            <PaginationMeta range={range} total={total} suffix="people" />
          ),
        }}
        onRow={record => {
          return {
            onClick: () => handleRowClick(record),
          }
        }}
      ></CardTable>
    </div>
  )
}

export default PeopleList
