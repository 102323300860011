import '../ResetPassword/style.less'
import './styles.less'

import { useLazyQuery } from '@apollo/react-hooks'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Typography,
  notification,
} from 'antd'
import axios from 'axios'
import gql from 'graphql-tag'
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDomain, getSubdomain } from 'tldts'

import slackIcon from '../_assets/img/integrations/slack.svg'
import Logo from '../_assets/img/logo.svg'
import { track } from '../_helpers/analytics'
import trimmedEmailValidator from '../_helpers/trimmedEmailValidator'
import AppLoading from '../AppLoading'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import useQuery from '../hooks/routing/useQuery'
import { UserContext } from '../UserContext'

const { Text } = Typography

const GET_SLACK_SIGN_IN_LINK = gql`
  query signInWithSlackUrl($redirectUrl: String) {
    signInWithSlackUrl(redirectUrl: $redirectUrl)
  }
`

const loginSubdomainRequest = async ({ email }) => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL +
      `/api/auth/provider/subdomain?email=${email}`,
    {
      responseType: 'json',
    },
  )

  return response.data
}

const loginDetailsRequest = async () => {
  const response = await axios.get(
    process.env.REACT_APP_BASE_URL + `/api/auth/provider/login/details`,
    {
      responseType: 'json',
    },
  )
  return response.data
}

const LogIn = ({ history, form }) => {
  const subdomain = getSubdomain(window.location.href)

  const user = useContext(UserContext)

  const [subdomainLoginDetails, setSubdomainLoginDetails] = useState()
  const [loading, setLoading] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const [isSAMLLoginAttempt, setIsSAMLLoginAttempt] = useState(false)

  const [showSAMLErrorModal, setShowSAMLErrorModal] = useState(false)

  const query = useQuery()
  const redirect = query.get('redirect')
  const redirectUrl = redirect ? redirect : '/welcome'

  const basePath = `${document.location.protocol}//${document.location.host}`

  const isCustomSubdomain =
    subdomain && subdomain !== process.env.REACT_APP_BASE_SUBDOMAIN

  const [getSlackSignInLink] = useLazyQuery(GET_SLACK_SIGN_IN_LINK, {
    fetchPolicy: 'network-only',
    variables: {
      redirectUrl: `${basePath}/login/slack?redirect=${redirectUrl}`,
    },
    onCompleted: data => {
      if (data && data.signInWithSlackUrl) {
        window.location.href = data.signInWithSlackUrl
      }
      setLoading(false)
    },
    onError: error => {
      notification.error({
        message: `That didn't work out as planned`,
        description: `There was an error signing you in. Please try again.`,
      })
      setLoading(false)
    },
  })

  useMobileOptimized(true)

  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useEffect(() => {
    if (!user.isAuthenticated) {
      return
    }
    track('accounts.user.login.password', { email: user.email })
    history.push(redirectUrl)
  }, [user.isAuthenticated])

  useEffect(() => {
    if (isCustomSubdomain) {
      const redirectToBaseSubdomain = () => {
        const domain = getDomain(window.location.href)
        window.location.href = `${window.location.protocol}//${
          process.env.REACT_APP_BASE_SUBDOMAIN
        }.${domain}${window.location.port ? `:${window.location.port}` : ''}`
      }

      loginDetailsRequest()
        .then(data => {
          if (!data) {
            redirectToBaseSubdomain()
          }
          setSubdomainLoginDetails(data)
        })
        .catch(() => {
          redirectToBaseSubdomain()
        })
    }
  }, [])

  useEffect(() => {
    const error = query.get('error')
    if (error) {
      if (error === 'noUser') {
        setShowSAMLErrorModal(true)
      } else if (error === 'deactivatedUser') {
        notification.error({
          message: `Oops!`,
          description: `Unfortunately, your user was deactivated.`,
        })
      } else {
        notification.error({
          message: `That didn't work out as planned`,
          description: `There was an error signing you in. Please try again.`,
        })
      }
    }
  }, [])

  const { getFieldDecorator, validateFields, getFieldError } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFields((errors, values) => {
      if (!errors) {
        setIsSubmitting(true)
        if (isSAMLLoginAttempt) {
          loginSubdomainRequest({ email: values.email })
            .then(data => {
              if (data.success) {
                const domain = getDomain(window.location.href)

                window.location.href = `${window.location.protocol}//${
                  data.subdomain
                }.${domain}${
                  window.location.port ? `:${window.location.port}` : ''
                }`
              } else {
                notification.error({
                  message: `That didn't work out as planned`,
                  description:
                    data.message ||
                    `There was an error signing you in. Please try again.`,
                })
              }
            })
            .catch(err => {
              notification.error({
                message: `That didn't work out as planned`,
                description: `There was an error signing you in. Please try again.`,
              })
            })
            .finally(() => {
              setIsSubmitting(false)
            })
        } else {
          user
            .login({ username: values.email, password: values.password })
            .catch(err => {
              notification.error({ message: 'Oops!', description: err.message })
            })
            .finally(() => {
              setIsSubmitting(false)
            })
        }
      } else {
        setEmailError(getFieldError('email'))
        setPasswordError(getFieldError('password'))
      }
    })
  }

  const handleSlackButtonClick = async () => {
    setLoading(true)
    getSlackSignInLink()
  }

  const handleLoginTypeChangeClick = () => {
    setIsSAMLLoginAttempt(!isSAMLLoginAttempt)
  }

  if (isCustomSubdomain && !subdomainLoginDetails) {
    return <AppLoading />
  }

  return (
    <div className="screen-container" style={{ minHeight: '100vh' }}>
      <Row
        type="flex"
        justify="center"
        align="top"
        style={{ marginBottom: '40px' }}
      >
        <Col
          span={7}
          className="col-login"
          style={{ marginBottom: !isCustomSubdomain ? 140 : 110 }}
        >
          {isCustomSubdomain && subdomainLoginDetails.logoUrl && (
            <div className="logo-container">
              <img
                src={subdomainLoginDetails.logoUrl}
                alt="Company logo"
                style={{ maxHeight: '40px' }}
              />
            </div>
          )}
          {(!isCustomSubdomain ||
            (isCustomSubdomain && !subdomainLoginDetails.logoUrl)) && (
            <div className="logo-container">
              <img
                src={Logo}
                alt="How am I going?"
                style={{ maxHeight: '40px' }}
              />
            </div>
          )}

          <Card className="screen-container-card">
            <div
              className="font-secondary color-primary"
              style={{
                fontWeight: 500,
                fontSize: 24,
                marginBottom: 12,
              }}
            >
              Knock-knock! Who's there?
            </div>
            <div
              className="color-primary"
              style={{ fontWeight: 300, marginBottom: '24px' }}
            >
              {isCustomSubdomain ? (
                <span>
                  We know, it's you. But just to confirm, please click on the
                  button below to log in.
                </span>
              ) : (
                <span>
                  We know, it's you. But just to confirm, please enter your
                  details below or log in with Slack.
                </span>
              )}
            </div>
            {isCustomSubdomain ? (
              <Button
                type="accent"
                size="large"
                onClick={() =>
                  history.push(`/login/provider?path=${redirectUrl}`)
                }
              >
                Log in with SSO
              </Button>
            ) : (
              <>
                <Button
                  type="ghost"
                  size="large"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    lineHeight: '20px',
                    margin: '0 auto',
                    width: '100%',
                  }}
                  disabled={loading}
                  onClick={handleSlackButtonClick}
                >
                  <img
                    alt="Sign in with Slack"
                    src={slackIcon}
                    style={{ height: 24, marginRight: 12 }}
                  />
                  Log in with Slack
                </Button>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 auto',
                    width: '80%',
                  }}
                >
                  <div
                    style={{ borderBottom: '1px solid #e9e8ef', flex: '1 0 0' }}
                  ></div>
                  <div style={{ color: '#77779f', padding: '24px 20px' }}>
                    or
                  </div>
                  <div
                    style={{ borderBottom: '1px solid #e9e8ef', flex: '1 0 0' }}
                  ></div>
                </div>
                <Form
                  layout="horizontal"
                  onSubmit={handleSubmit}
                  hideRequiredMark
                  colon={false}
                  className="login-form"
                >
                  <Form.Item
                    style={{ marginBottom: 16 }}
                    label={<Text style={{ fontWeight: 300 }}>Email</Text>}
                    help={emailError || ''}
                    validateStatus={emailError ? 'error' : ''}
                  >
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          validator: trimmedEmailValidator,
                        },
                      ],
                    })(
                      <Input
                        style={{
                          boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                        }}
                        placeholder="harriet@howamigoing.com"
                        autoCorrect="off"
                        autoCapitalize="none"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 24,
                      display: isSAMLLoginAttempt ? 'none' : 'block',
                    }}
                    label={<Text style={{ fontWeight: 300 }}>Password</Text>}
                    help={passwordError || ''}
                    validateStatus={passwordError ? 'error' : ''}
                  >
                    {getFieldDecorator('password', {
                      rules: [
                        {
                          required: !isSAMLLoginAttempt,
                          message: 'You need to fill your password!',
                        },
                      ],
                    })(
                      <Input.Password
                        style={{
                          boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                          borderRadius: '12px',
                        }}
                        placeholder="MyFirstPuppy123"
                      />,
                    )}
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Button
                      loading={isSubmitting}
                      type="accent"
                      size="large"
                      htmlType="submit"
                      style={{ marginRight: 16, marginTop: 8 }}
                    >
                      Log in
                    </Button>
                    <Button
                      type="link"
                      style={{
                        display: isSAMLLoginAttempt ? 'none' : 'block',
                        marginTop: 8,
                        padding: 0,
                      }}
                    >
                      <Link to="/resetPassword" style={{ color: '#ec5747' }}>
                        Oops, I forgot my password
                      </Link>
                    </Button>
                  </div>
                </Form>
                <div
                  className="color-secondary"
                  style={{
                    fontSize: '14px',
                    marginTop: '24px',
                    textAlign: 'center',
                  }}
                >
                  You can also{' '}
                  <Button
                    type="link"
                    style={{
                      color: '#ec5747',
                      height: 'auto',
                    }}
                    onClick={handleLoginTypeChangeClick}
                  >
                    {isSAMLLoginAttempt
                      ? 'login with email and password.'
                      : 'continue with SAML SSO.'}
                  </Button>
                </div>
              </>
            )}
          </Card>
        </Col>
        {!isCustomSubdomain && (
          <Col
            span={24}
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 'auto',
              marginTop: 0,
            }}
          >
            <div style={{ marginRight: '8px' }}>Don't have an account?</div>
            <Link to="/signup">
              <Button type="ghost" size="small">
                Sign up
              </Button>
            </Link>
          </Col>
        )}
      </Row>
      {isCustomSubdomain && subdomainLoginDetails.logoUrl && (
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>
      )}
      <Modal
        title="Sorry!"
        okText={'Contact owner'}
        cancelText={'Cancel'}
        visible={showSAMLErrorModal}
        onCancel={() => {
          setShowSAMLErrorModal(false)
          history.push(basePath + '/login')
        }}
        onOk={() => {
          window.open(
            `mailto:${query.get('ownerEmail')}?subject=${query.get(
              'ownerName',
            )}, please invite me to Howamigoing`,
          )
        }}
        centered
      >
        You're not able to login to Howamigoing yet. Please contact{' '}
        {query.get('orgName')}'s Howamigoing{' '}
        <a
          href={`mailto:${query.get('ownerEmail')}?subject=${query.get(
            'ownerName',
          )}, please invite me to Howamigoing`}
        >
          account owner ({query.get('ownerName')})
        </a>{' '}
        to request access :-)
      </Modal>
    </div>
  )
}

export default Form.create({ name: 'loginForm' })(LogIn)
