import React, { useContext } from 'react'

import { UserContext } from '../../../../UserContext'
import RowNotReceivedFeedback from '../threeSixty/RowNotReceivedFeedback'
import RowReceivedFeedback from '../threeSixty/RowReceivedFeedback'

export default ({ cycle }) => {
  const currentUser = useContext(UserContext)

  const userEnrolled = cycle.enrolled.find(x => x.id === currentUser.id)

  const userReceivedFeedback =
    userEnrolled &&
    userEnrolled.requests &&
    userEnrolled.requests.length > 1 ?
    userEnrolled.requests.filter(x => x.respondedAt) : []

  return (
    <div>
      {userReceivedFeedback.length > 1 ? (
        <RowReceivedFeedback
          feedbacks={userReceivedFeedback}
          reviewId={userEnrolled.reviewId}
        />
      ) : (
        <RowNotReceivedFeedback />
      )}
    </div>
  )
}
