import { useMutation } from '@apollo/react-hooks'
import { AutoComplete, Button, Card, Typography, notification } from 'antd'
import React, { useContext, useState } from 'react'

import { track } from '../../_helpers/analytics'
import { UserContext } from '../../UserContext'
import { SET_TEAM } from '../queries'

const { Title, Text } = Typography

const teams = [
  'Board',
  'C-Suite',
  'Design',
  'Engineering',
  'Finance',
  'IT',
  'Marketing',
  'Non-executive',
  'Operations',
  'People',
  'Product',
  'Sales',
]

export default ({ onSubmit, onSkip }) => {
  const user = useContext(UserContext)
  const [team, setTeam] = useState(false)
  const [autoCompleteDataSource, setAutoCompleteDataSource] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSkipping, setIsSkipping] = useState(false)

  const [saveTeam] = useMutation(SET_TEAM)

  const submit = async () => {
    if (team && team.length > 0) {
      setIsSubmitting(true)
      await saveTeam({
        variables: {
          team,
        },
      })

      track('admin.onboarding.team', {
        user: user.email,
        org: user.org.name,
        team,
      })

      setIsSubmitting(false)

      if (onSubmit) {
        onSubmit()
      }
    } else {
      notification.warn({
        message: 'There is a problem with the team.',
        description: 'You did not specify any team. Please check again.',
      })
    }
  }

  const skip = async () => {
    setIsSkipping(true)
    await saveTeam({
      variables: {
        team: undefined,
      },
    })
    track('admin.onboarding.teamSkipped', {
      user: user.email,
      org: user.org.name,
    })
    setIsSkipping(false)
    skipStep()
  }

  const skipStep = () => {
    if (onSkip()) {
      onSkip()
    }
  }

  const onSearch = value => {
    setAutoCompleteDataSource(
      value === ''
        ? []
        : teams.filter(team =>
            team.toLowerCase().includes(value.toLowerCase()),
          ),
    )
  }

  return (
    <div className="onboarding-set-team">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontSize: '24px', fontWeight: 500 }}
        >
          Nice! And which team or department are you in?
        </Title>

        <div>
          <Text style={{ fontWeight: 300 }}>Team</Text>
          <AutoComplete
            placeholder="E.g.: Marketing, Design, Sales, etc."
            dataSource={autoCompleteDataSource}
            onSearch={onSearch}
            onChange={value => setTeam(value)}
            className="onboarding-set-team-autocomplete"
            style={{
              marginTop: '8px',
              width: '100%',
            }}
          />
        </div>

        <div style={{ marginTop: '4vh' }}>
          <Button
            disabled={isSubmitting || isSkipping || !team || team === ''}
            loading={isSubmitting}
            onClick={submit}
            type="accent"
            size="large"
          >
            Create team
          </Button>
          <Button
            type="ghost"
            size="large"
            disabled={isSubmitting || isSkipping}
            loading={isSkipping}
            onClick={() => skip()}
            style={{ marginLeft: '10px' }}
          >
            Skip for now
          </Button>
        </div>
      </Card>
    </div>
  )
}
