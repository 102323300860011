import React, { useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { getCreateEditRoutes, getCycleTypeFromUrl } from '../../helpers'
import StepsContext from './StepsContext'

const StepsContextProvider = ({ children }) => {
  const location = useLocation()

  const cycleType = getCycleTypeFromUrl(location)
  const cycleRoutes = getCreateEditRoutes(cycleType)

  const selectedRouteIndex = cycleRoutes.routes.findIndex(route =>
    matchPath(location.pathname, { path: route.path }),
  )

  const contextState = {
    currentStep: selectedRouteIndex,
    setStep: i => {
      updateContext({
        ...contextState,
        currentStep: i,
      })
    },
  }

  const [context, updateContext] = useState(contextState)

  return (
    <StepsContext.Provider value={context}>{children}</StepsContext.Provider>
  )
}

export default StepsContextProvider
