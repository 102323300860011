import { Button } from 'antd'
import React, { useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Avatar from '../../../_components/Avatar'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'

const getPrefix = (cycleType) => {
  switch (cycleType) {
    case '360':
      return 'team360'
    case 'personal':
      return 'personal360'
    case 'pulse':
      return 'pulse'
    default:
      return ''
  }
}

export default ({ requester, feedbackId, cycleName, cycleType }) => {
  const user = useContext(UserContext)
  const location = useLocation()

  const prefix = getPrefix(cycleType)
  const linkPrefix = `/${prefix}`

  const handleClick = () => {
    if (location.pathname === '/') {
      track(prefix + '.feedback.giveFeedbackToDo', {
        provider: user.email,
        org: user.org.name,
        feedbackId,
      })
    }
  }

  const getRequesterName = () => {
    if (requester.firstName && requester.lastName) {
      return `${requester.firstName}`
    }
    if (requester.firstName) {
      return requester.firstName
    }
    return requester.email
  }

  const getHeading = () => {
    if (cycleType === 'pulse') {
      return 'Please answer this Pulse survey'
    }
    return `${getRequesterName(requester)} asked you for feedback`
  }

  const getButtonLabel = () => {
    if (cycleType === 'pulse') {
      return 'Answer survey'
    }
    return 'Give feedback'
  }

  return (
    <div className="dashboard-todo">
      <Avatar user={requester} />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>
          {getHeading()}
        </div>
        {cycleName && (
          <div style={{ color: '#6b6b8f', fontSize: '14px' }}>{cycleName}</div>
        )}
      </div>
      <Link
        to={linkPrefix + '/giveFeedback/' + feedbackId}
        onClick={handleClick}
      >
        <Button type="primary" size="small">
          {getButtonLabel()}
        </Button>
      </Link>
    </div>
  )
}
