import gql from 'graphql-tag'

export const ADD_TEAM_MEMBER = gql`
  mutation addTeamMember($teamId: String!, $memberId: String!) {
    addTeamMember(teamId: $teamId, memberId: $memberId) {
      id
      members {
        id
        user {
          id
        }
      }
    }
  }
`

export const GET_TEAMS = gql`
  query teams {
    teams {
      id
      name
    }
  }
`
