import { Answer } from '../../components'
import EditAnswer from './EditAnswer'
import React from 'react'
import RespondToQuestion from './RespondToQuestion'
import moment from 'moment'

const AnswerContainer = ({
  id,
  recipient,
  question,
  answer,
  visibility,
  status,
  draft,
  editedAt,
  onEditAnswerSave,
  onEditAnswerCancel,
}) => {
  switch (status) {
    case 'responding':
      return (
        <RespondToQuestion
          id={id}
          recipient={recipient}
          question={question}
          answer={answer}
          draft={draft}
        />
      )
    case 'editing':
      return (
        <EditAnswer
          id={id}
          recipient={recipient}
          visibility={visibility}
          answer={answer}
          draft={draft}
          onSave={() => onEditAnswerSave(id)}
          onCancel={() => onEditAnswerCancel(id)}
        />
      )
    case 'readonly':
      return (
        <Answer.Readonly
          recipient={recipient}
          question={question}
          answer={answer}
          editedAt={editedAt ? moment.unix(editedAt) : undefined}
        />
      )
    default:
      return 'Unknown state'
  }
}

export default AnswerContainer
