import { Spin, Tooltip } from 'antd'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const TooltipIcon = ({ title }) => {
  return (
    <Tooltip title={title}>
      <FontAwesomeIcon
        icon={['fal', 'question-circle']}
        style={{ width: '1em', marginLeft: '4px', cursor: 'pointer' }}
      />
    </Tooltip>
  )
}

const Stat = ({ value, description, tooltip, footer, loading, ...props }) => {
  const hasValue = value === 0 || value

  return (
    <div
      {...props}
      className={`stat__container${
        props.className ? ` ${props.className}` : ''
      }`}
    >
      {loading && <Spin />}
      {!loading && hasValue && <h5 className="stat__value">{value}</h5>}
      {description && (
        <p className="stat__description">
          {description}
          {tooltip && <TooltipIcon title={tooltip} />}
        </p>
      )}
      {footer && footer}
    </div>
  )
}

export default Stat
