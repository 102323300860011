import React from 'react'
import Emoji from 'react-emoji-render'

const Rule = ({ title, emoji, explanation, frequency }) => {
  return (
    <div className="notifications-page-rule">
      <div className="notifications-page-rule__details">
        <div className="notifications-page-rule__title">
          {title}
          <span className="notifications-page-rule__title-emoji">
            <Emoji text={emoji} />
          </span>
        </div>
        <div className="notifications-page-rule__explanation">
          {explanation}
        </div>
      </div>
      <div className="notifications-page-rule__frequency">{frequency}</div>
    </div>
  )
}

export default Rule
