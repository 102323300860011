import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import React from 'react'

import Stat from '../Stat'
import { USER_AMA } from './queries'

export default ({ timeInterval, userId }) => {
  const { data, loading } = useQuery(USER_AMA, {
    fetchPolicy: 'cache-first',
    variables: {
      userId,
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    },
  })

  const stats = {
    questionsReceived: data
      ? data.userAskMeAnythingInsight.find(x => x.key === 'ama_user_received')
          .value
      : 0,
    answeredPublicly: data
      ? data.userAskMeAnythingInsight.find(
          x => x.key === 'ama_user_answered_publicly',
        ).value
      : 0,
    answeredPrivately: data
      ? data.userAskMeAnythingInsight.find(
          x => x.key === 'ama_user_answered_privately',
        ).value
      : 0,
  }

  return (
    <Card className="up__stats-card">
      <Stat
        title="Questions received"
        number={stats.questionsReceived}
        loading={loading}
      />
      <Stat
        title="Answered publicly"
        number={stats.answeredPublicly}
        loading={loading}
      />
      <Stat
        title="Answered privately"
        number={stats.answeredPrivately}
        loading={loading}
      />
    </Card>
  )
}
