import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import React, { useState } from 'react'

import {
  DEACTIVATE_SLACK_INTEGRATION,
  GET_SLACK_INTEGRATION,
  INSTALL_SLACK_INTEGRATION,
} from './queries'

const IntegrationsContext = React.createContext()

const INTEGRATION_TYPES = [
  {
    type: 'slack',
    name: 'Slack',
  },
  {
    type: 'personio',
    name: 'Personio',
  },
  {
    type: 'teams',
    name: 'Microsoft Teams',
    isComingSoon: true,
  },
  {
    type: 'gsuite',
    name: 'G Suite',
    isComingSoon: true,
  },
]

export const IntegrationsProvider = ({ children }) => {
  const [integrations, setIntegrations] = useState()

  const adminIntegrationUrl = `${document.location.protocol}//${document.location.host}/admin/integrations/slack`

  const [getSlack] = useLazyQuery(GET_SLACK_INTEGRATION, {
    variables: {
      redirectUrl: adminIntegrationUrl,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (data && data.slack) {
        const slackIntegrationObj = INTEGRATION_TYPES.find(
          i => i.type === 'slack',
        )
        setIntegrations({
          ...integrations,
          slack: {
            ...slackIntegrationObj,
            ...data.slack,
          },
        })
      }
    },
  })

  const [installSlackIntegration] = useMutation(INSTALL_SLACK_INTEGRATION)
  const [deactivateSlackIntegration] = useMutation(DEACTIVATE_SLACK_INTEGRATION)

  const api = {
    fetch: () => {
      if (!integrations) {
        getSlack()
      }
    },

    reset: () => {
      setIntegrations()
    },

    getIntegrations: () => integrations,
    getPossibleIntegrations: () => INTEGRATION_TYPES,

    installSlackIntegration: ({ code, state, redirectUrl }) => {
      return installSlackIntegration({
        variables: {
          authorizationCode: code,
          state,
          redirectUrl,
        },
      })
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.slackDetails &&
            !response.data.slackDetails.success
          ) {
            if (
              response.data.slackDetails.message.includes('users_not_found')
            ) {
              throw new Error(
                'Oh no! Your Howamigoing email doesn’t match your Slack email. To make the integration work, please update either email so they’re the same.',
              )
            }
            throw new Error()
          }
        })
        .catch(e => {
          throw e
        })
        .finally(() => {
          getSlack()
        })
    },

    deactivateSlackIntegration: ({ reason }) => {
      return deactivateSlackIntegration({
        variables: {
          reason,
        },
      }).then(response => {
        getSlack()
        return response.data
      })
    },
  }

  return (
    <IntegrationsContext.Provider value={api}>
      {children}
    </IntegrationsContext.Provider>
  )
}

export default IntegrationsContext
