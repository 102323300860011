import { useQuery } from '@apollo/react-hooks'
import cloneDeep from 'lodash/cloneDeep'
import { useMemo } from 'react'

import { GET_TEAMS_LIST } from './queries'

export default ({ noTeamName = 'No team', ...props } = {}) => {
  const variables = useMemo(() => {
    if (!props.variables) {
      return {}
    }
    const userTypes = props.variables.userTypes
      ? props.variables.userTypes.reduce((acc, type) => {
          switch (type) {
            case 'active':
              acc.push('standard', 'admin', 'owner')
              break
            case 'invited':
              acc.push('invited', 'onboarding')
              break
            default:
              acc.push(type)
          }
          return acc
        }, [])
      : undefined
    return {
      ...props.variables,
      teamUserTypes: props.variables.userTypes,
      userTypes,
    }
  }, [props.variables])

  const query = useQuery(GET_TEAMS_LIST, {
    ...props,
    variables,
    fetchPolicy: 'cache-and-network',
  })

  const result = useMemo(() => {
    const teams = query.data ? query.data.teams : []
    const noTeam =
      query.data && query.data.noTeam.total > 0
        ? [
            {
              id: 'no-team',
              name: noTeamName,
              totalMembers: query.data.noTeam.total,
            },
          ]
        : []
    const data = [...teams, ...noTeam]
    return {
      ...query,
      data: query.loading ? undefined : { teams: cloneDeep(data) },
    }
  }, [query.data, query.loading, noTeamName])

  return result
}
