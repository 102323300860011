import gql from 'graphql-tag'

export const INVITE_USERS = gql`
  mutation($userIds: [String!]!) {
    inviteUsers(userIds: $userIds) {
      id
      type
      status
    }
  }
`
