import { Icon } from 'antd'
import React from 'react'

import personioSvg from '../../_assets/img/integrations/personio.svg'

const PersonioTab = ({ error }) => {
  return (
    <div className="invite-people-tab-name">
      <img src={personioSvg} alt="Add via Personio" />
      <div>Add via Personio </div>
      <div style={{ marginLeft: 8 }}>
        {error && (
          <Icon
            type="exclamation-circle"
            theme={'twoTone'}
            twoToneColor={'#f95c4b'}
          />
        )}
      </div>
    </div>
  )
}

export default PersonioTab
