import './styles.less'

import { Card, Table } from 'antd'
import React from 'react'

export default props => {
  return (
    <div className="card-table">
      <Card>
        <Table {...props} />
      </Card>
    </div>
  )
}
