import './style.less'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import Search from '../../../_components/LandingPageSearch'
import Loader from '../../../_components/Loader'
import Pagination from '../../../_components/Pagination'
import GoodVibeCard from '../../components/GoodVibeCard'
import EmptyState from './EmptyState'
import {
  ADD_GOOD_VIBE_REACTION,
  DELETE_GOOD_VIBE,
  DELETE_GOOD_VIBE_COMMENT,
  DELETE_GOOD_VIBE_REACTION,
  EDIT_GOOD_VIBE,
  EDIT_GOOD_VIBE_COMMENT,
  GOOD_VIBES,
  REPORT_GOOD_VIBE_ABUSE,
  REPORT_GOOD_VIBE_COMMENT_ABUSE,
  SAVE_GOOD_VIBE_DRAFT,
  SUBMIT_GOOD_VIBE_COMMENT,
} from './queries'
import useSeeGoodVibes from './useSeeGoodVibes'

const PAGE_SIZE = 10
const getOffset = (pageNumber) => pageNumber * PAGE_SIZE - PAGE_SIZE

const LandingPage = () => {
  const initialized = useRef(false)
  const hasGoodVibesInAccount = useRef(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [searchParams, setSearchParams] = useState({
    personName: undefined,
    teamId: undefined,
    endDate: undefined,
    includeNoTeam: undefined,
  })

  const { data: response, error, loading } = useQuery(GOOD_VIBES, {
    fetchPolicy: 'no-cache',
    pollInterval: 10000,
    variables: {
      search: {
        personName: searchParams.personName || undefined,
        teamId: searchParams.teamId || undefined,
        endDate: searchParams.endDate || undefined,
        includeNoTeam: searchParams.includeNoTeam || undefined,
      },
      offset: getOffset(pageNumber),
      limit: PAGE_SIZE,
    },
    onCompleted: () => initialized.current = true
  })
  const [editGoodVibe] = useMutation(EDIT_GOOD_VIBE, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [deleteGoodVibe] = useMutation(DELETE_GOOD_VIBE, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [submitGoodVibeComment] = useMutation(SUBMIT_GOOD_VIBE_COMMENT, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [editGoodVibeComment] = useMutation(EDIT_GOOD_VIBE_COMMENT, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [deleteGoodVibeComment] = useMutation(DELETE_GOOD_VIBE_COMMENT, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [addGoodVibeReaction] = useMutation(ADD_GOOD_VIBE_REACTION, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [deleteGoodVibeReaction] = useMutation(DELETE_GOOD_VIBE_REACTION, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [saveGoodVibeDraft] = useMutation(SAVE_GOOD_VIBE_DRAFT, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [reportGoodVibeAbuse] = useMutation(REPORT_GOOD_VIBE_ABUSE, {
    refetchQueries: ['goodVibes'],
    awaitRefetchQueries: true,
  })
  const [reportGoodVibeCommentAbuse] = useMutation(
    REPORT_GOOD_VIBE_COMMENT_ABUSE,
    {
      refetchQueries: ['goodVibes'],
      awaitRefetchQueries: true,
    },
  )

  useSeeGoodVibes(
    !!response,
    response ? response.goodVibes.goodVibes.map(x => x.id) : [],
    3000,
  )

  const handleOnGoodVibeReportAbuse = async (id, value) => {
    try {
      await reportGoodVibeAbuse({
        variables: {
          goodVibeId: id,
          abuse: value,
        },
      })
      notification.success({
        message: 'Gotcha!',
        description: 'The Good vibe has been reported',
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const handleOnGoodVibeEdit = async (id, values) => {
    try {
      await editGoodVibe({
        variables: {
          goodVibeId: id,
          recipientIds: [values.recipientId],
          fact: values.fact,
          feeling: values.feeling,
        },
      })
      notification.success({
        message: 'Great!',
        description: `Your Good vibe has been edited`,
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const handleOnGoodVibeDelete = async id => {
    try {
      await deleteGoodVibe({
        variables: {
          goodVibeId: id,
        },
      })
      notification.success({
        message: `It's gone!`,
        description: 'The Good vibe has been deleted',
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const handleOnCommentAdd = async (goodVibeId, value) => {
    try {
      await submitGoodVibeComment({
        variables: {
          goodVibeId,
          value,
        },
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const handleOnCommentEdit = async (id, value) => {
    try {
      await editGoodVibeComment({
        variables: {
          commentId: id,
          value,
        },
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const handleOnCommentDelete = async id => {
    try {
      await deleteGoodVibeComment({
        variables: {
          commentId: id,
        },
      })
      notification.success({
        message: `It's gone!`,
        description: 'The comment has been deleted',
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const handleOnCommentReportAbuse = async value => {
    try {
      await reportGoodVibeCommentAbuse({
        variables: {
          commentId: value.id,
          abuse: value.abuse,
        },
      })
      notification.success({
        message: 'Gotcha!',
        description: 'The comment has been reported',
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
      throw e
    }
  }

  const handleOnReactionAdd = async (id, values) => {
    try {
      await addGoodVibeReaction({
        variables: {
          goodVibeId: id,
          reaction: values.type,
        },
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const handleOnReactionDelete = async id => {
    try {
      await deleteGoodVibeReaction({
        variables: {
          reactionId: id,
        },
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }

  const [goodVibeDraftSaver] = useDebouncedCallback(async (id, values) => {
    try {
      await saveGoodVibeDraft({
        variables: {
          goodVibeId: id,
          recipientIds: [values.recipient.id],
          fact: values.fact,
          feeling: values.feeling,
        },
      })
    } catch (e) {
      notification.error({
        message: 'Oops! Something went wrong',
        description: 'Please try again later',
      })
    }
  }, 1000)

  const onPageChange = (newPageNumber) => {
    setPageNumber(newPageNumber)
    window.scroll({
      top: 0
    })
  }

  const [onSearchFormSubmit] = useDebouncedCallback(({ personName, teamId, endDate }) => {
    const newParams = {
      personName: personName || undefined,
      teamId: teamId || undefined,
      endDate: endDate || undefined,
      includeNoTeam: teamId && teamId === 'no-team' ? true : undefined
    }
    setSearchParams(newParams)
    setPageNumber(1)
  }, 400)

  useEffect(() => {
    if (!response) return
    if (response.goodVibes.total > 0) {
      hasGoodVibesInAccount.current = true
    }
  }, [response])

  if (loading && !initialized.current) {
    return <Loader />
  }
  if (error) {
    return <p>Oops! Something went wrong. Please try again.</p>
  }

  const { goodVibes } = response.goodVibes

  return (
    <>
      <div className="good-vibes-landing-page">
      {(goodVibes.length > 0 || hasGoodVibesInAccount.current) && (
          <Search.Form onSubmit={onSearchFormSubmit} loading={loading} />
        )}

        {goodVibes.length === 0 && hasGoodVibesInAccount.current ? (
          <Search.NoResults />
        ) : goodVibes.length === 0 ? (
          <EmptyState />
        ) : (
          <div>
          { goodVibes.map(x => {
            return (
              <GoodVibeCard
                className="good-vibes-landing-page__card"
                key={x.id}
                id={x.id}
                sender={x.creator}
                recipient={x.recipients[0]}
                fact={x.fact}
                feeling={x.feeling}
                sentAt={moment.unix(x.sentAt)}
                remoteSaver={values => goodVibeDraftSaver(x.id, values)}
                reportedAbuse={x.reportedAbuse}
                draft={
                  x.draft
                    ? {
                        recipientId: x.draft.recipientIds
                          ? x.draft.recipientIds[0]
                          : null,
                        fact: x.draft.fact,
                        feeling: x.draft.feeling,
                        lastSyncedAt: x.draft.lastSyncedAt,
                      }
                    : undefined
                }
                comments={x.comments.map(comment => {
                  return {
                    id: comment.id,
                    submittedAt: moment.unix(comment.submittedAt),
                    editedAt: comment.editedAt
                      ? moment.unix(comment.editedAt)
                      : undefined,
                    value: comment.value,
                    author: comment.author,
                    reportedAbuse: comment.reportedAbuse,
                  }
                })}
                reactions={x.reactions.map(reaction => {
                  return {
                    id: reaction.id,
                    reactedAt: moment.unix(reaction.reactedAt),
                    type: reaction.reaction,
                    author: reaction.author,
                  }
                })}
                onGoodVibeReportAbuse={abuse =>
                  handleOnGoodVibeReportAbuse(x.id, abuse)
                }
                onGoodVibeEdit={values => handleOnGoodVibeEdit(x.id, values)}
                onGoodVibeDelete={() => handleOnGoodVibeDelete(x.id)}
                onReactionAdd={values => handleOnReactionAdd(x.id, values)}
                onReactionDelete={values =>
                  handleOnReactionDelete(values.id, values)
                }
                onCommentAdd={values => handleOnCommentAdd(x.id, values)}
                onCommentEdit={handleOnCommentEdit}
                onCommentDelete={handleOnCommentDelete}
                onCommentReportAbuse={handleOnCommentReportAbuse}
              />
            )
          }) }
          </div>
        )}
        { response.goodVibes.total > 10 && (
          <div className="good-vibes-landing-page__pagination">
            <Pagination
              current={pageNumber}
              onChange={onPageChange}
              total={response.goodVibes.total}
              pageSize={PAGE_SIZE}
              disabled={loading}
              itemsName="Good vibes"
            />
          </div>
        )}
      </div>
    </>
  )
}

export default LandingPage
