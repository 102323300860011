import { Progress, Typography } from 'antd'
import React from 'react'

const StepCounter = ({ currentStepNumber, totalSteps }) => {
  return (
    <div style={{ marginTop: '48px' }}>
      <Typography.Text style={{ fontSize: '14px', fontWeight: 300 }}>
        Step {currentStepNumber} out of {totalSteps}
      </Typography.Text>
      <Progress
        style={{
          marginTop: '8px',
        }}
        strokeColor={'#77779f'}
        percent={(currentStepNumber * 100) / totalSteps}
        showInfo={false}
      />
    </div>
  )
}

export default StepCounter
