import gql from 'graphql-tag'

const cycleObject = `
    cycle {
        id,
        name,
        type,
        startDate,
        endDate,
        form {
            id,
            sections
        }
    }
`

const questionFields = `
    id,
    title,
    type,
    required,
    settings,
    responses,
    responsePagination {
        id,
        offset,
        limit,
        total
    },
    sentimentAnalysis,
    totalAnswers
`

export const GET_FEEDBACK_RESPONSES = gql`
    query getReview ($reviewId: String!, $teamIds: [String!]) {
        finishedReview(id: $reviewId, teamIds: $teamIds) {
            id,
            ${cycleObject},
            questions {
                ${questionFields}
            },
            feedbackGivers {
                id,
                firstName,
                lastName,
                avatarUrl,
                avatar,
                email,
                jobTitle   
            }
            totalFeedbacks,
            teamCounts {
                id,
                count
            },

        }
    }
`

export const GET_MANAGER_REVIEWS = gql`
    query getManagerReviewsForCycle($cycleId: ID!) {
        reviewsForManager(cycleReviewsForUsers: {id: $cycleId}) {
            id,
            user {
                id,
                firstName,
                lastName,
                avatarUrl,
                avatar,
                email,
                jobTitle
            },
            ${cycleObject},
            questions {
                ${questionFields}
            },
            feedbackGivers {
                id,
                firstName,
                lastName,
                avatarUrl,
                avatar,
                email,
                jobTitle   
            }
        }
    }
`

export const GET_QUESTION = gql`
  query question(
    $reviewId: String!
    $formQuestionId: String!
    $responseType: String!
    $offset: Int!
    $limit: Int!
  ) {
    question(
      reviewId: $reviewId
      formQuestionId: $formQuestionId
      responseType: $responseType
      offset: $offset
      limit: $limit
    ) {
      ${questionFields}
    }
  }
`
