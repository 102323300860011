import React, { useEffect, useState } from 'react'

import Section from '../Section'
import Testimonial from './Testimonial'
import Testimonials from './testimonials'

export default () => {
  const [testimonial, setTestimonial] = useState(null)

  useEffect(() => {
    let index = localStorage.getItem('HAIGTestimonialsIndex') || 0

    if (index !== null) {
      // try to parse the value into an int
      index = parseInt(index)
      if (isNaN(index)) {
        // if value is not parseable, reset to 0
        index = null
      }
    }

    if (
      index === null ||
      index === Testimonials.length - 1 ||
      index > Testimonials.length - 1
    ) {
      // index does not exist or it is the last entry in the array
      setTestimonial(Testimonials[0])
      localStorage.setItem('HAIGTestimonialsIndex', 0)
    } else {
      setTestimonial(Testimonials[index + 1])
      localStorage.setItem('HAIGTestimonialsIndex', index + 1)
    }
  }, [])

  if (!testimonial) {
    return null
  }

  return (
    <Section title={`Featured stories`}>
      <Testimonial {...testimonial} />
    </Section>
  )
}
