import { default as Form } from './Form'
import { default as InputButton } from './InputButton'
import { default as InputRadio } from './InputRadio'
import { default as Modal } from './Modal'

const Visibility = {
  Form,
  InputButton,
  InputRadio,
  Modal,
}

export default Visibility
