import { Modal, notification } from 'antd'
import React, { useRef, useState } from 'react'

import ActivateForm from './ActivateForm'

const ActivateModal = ({ visible, onOk, onError, onCancel }) => {
  const [loading, setLoading] = useState(false)
  const [footerVisible, setFooterVisible] = useState(false)
  const form = useRef(null)

  const handleOnSubmitClick = async () => {
    setLoading(true)
    form.current.submit(err => {
      setLoading(false)
      if (err) {
        return handleOnSubmitError(err)
      }
      if (onOk) {
        return onOk()
      }
    })
  }

  const handleOnSubmitError = error => {
    setLoading(false)
    let message
    switch (error.message) {
      case 'Invalid credentials':
        message = 'Your credentials have changed. Please restart the setup'
        break
      case 'Missing attributes':
        message = `Your attributes have changed. Please reinstate the missing attributes and try again`
        break
      default:
        message = `We're honestly not sure what happened. Please contact us so we can help you out`
    }
    notification.error({
      message: 'Oops, something went wrong',
      description: message,
    })
    if (onError) {
      onError(error)
    }
  }

  return (
    <Modal
      className="activate-personio-modal"
      title="Connect to Personio"
      visible={visible}
      onCancel={onCancel}
      onOk={handleOnSubmitClick}
      okText={
        !loading ? (
          <>Connect &amp; import people</>
        ) : (
          'This may take a moment...'
        )
      }
      cancelText="Cancel"
      okButtonProps={{ loading, type: 'accent' }}
      cancelButtonProps={{ type: 'ghost' }}
      footer={footerVisible ? undefined : null}
      destroyOnClose={true}
      centered
    >
      <ActivateForm
        wrappedComponentRef={form}
        onSubmitStart={() => setLoading(true)}
        onSubmitEnd={() => setLoading(false)}
        onError={() => setLoading(false)}
        onValid={() => setFooterVisible(true)}
      />
    </Modal>
  )
}

export default ActivateModal
