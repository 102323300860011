import { Col, Row, Typography } from 'antd'
import React from 'react'

export default ({ title, cards }) => {
  if (!cards) {
    return <></>
  }

  return (
    <div>
      <Typography.Title
        level={4}
        style={{ fontFamily: 'Europa', marginBottom: 24, fontSize: 20 }}
      >
        {title}
      </Typography.Title>
      <Row gutter={32}>
        {cards.map((card, index) => (
          <Col span={8} key={index} style={{ marginBottom: 32 }}>
            {card}
          </Col>
        ))}
      </Row>
    </div>
  )
}
