import { Col, Icon, Row } from 'antd'
import React from 'react'

export default () => {
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ height: '100vh' }}
    >
      <Col style={{ textAlign: 'center' }}>
        <Icon type="sync" spin style={{ display: 'block', marginBottom: 8 }} />
        Loading...
      </Col>
    </Row>
  )
}
