import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu, Spin } from 'antd'
import React, { useState } from 'react'

const Filter = ({ options, label, loading, onOptionChange, disabled }) => {
  if ((options && options.length > 0) || loading) {
    return (
      <OptionsFilter
        emptyLabel={label}
        options={options}
        onOptionClick={onOptionChange}
        loading={loading}
        disabled={disabled}
      />
    )
  }
  return null
}

const Label = ({ children }) => {
  return children
}

const OptionsFilter = ({
  emptyLabel,
  loading,
  disabled,
  options,
  onOptionClick,
}) => {
  const [value, setValue] = useState()
  const handleOptionClick = val => {
    setValue(val)
    if (onOptionClick) {
      onOptionClick(val)
    }
  }

  const selectedOption = options && options.find(x => x.value === value)

  return (
    <Dropdown
      trigger={['click']}
      disabled={loading || disabled}
      overlay={
        <Menu className="search__filter-dropdown">
          <Menu.Item onClick={() => handleOptionClick()}>
            {emptyLabel}
          </Menu.Item>
          {options &&
            options.map(option => {
              return (
                <Menu.Item
                  key={option.value}
                  onClick={() => handleOptionClick(option.value)}
                >
                  {option.label}
                </Menu.Item>
              )
            })}
        </Menu>
      }
    >
      <div className="search__filter search__filter--has-action">
        <Label>{selectedOption ? selectedOption.label : emptyLabel}</Label>
        {loading ? (
          <div className="search__filter-action">
            <Spin size="small" />
          </div>
        ) : (
          <div className="search__filter-action">
            <FontAwesomeIcon
              icon={['fal', false ? 'chevron-up' : 'chevron-down']}
              style={{ fontSize: 10 }}
            />
          </div>
        )}
      </div>
    </Dropdown>
  )
}

export default Filter
