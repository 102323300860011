import { useMutation } from '@apollo/react-hooks'
import { Button, Checkbox, Form, notification } from 'antd'
import React, { useState } from 'react'

import { UPDATE_PERSONIO_INTEGRATION_CONFIG } from './queries'

const ConfigForm = ({ form, inviteNewUsers }) => {
  const [saving, setSaving] = useState(false)
  const [updatePersonioConfig] = useMutation(UPDATE_PERSONIO_INTEGRATION_CONFIG)

  const handleOnSubmit = evt => {
    evt.preventDefault()

    form.validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        setSaving(true)
        await updatePersonioConfig({
          variables: {
            inviteNewUsers: values.inviteNewUsers,
          },
        })
        setSaving(false)
        notification.success({
          description: 'Your changes have been updated',
          message: `Great success!`,
        })
      } catch (e) {
        setSaving(false)
        notification.error({
          description: 'Please try again later or contact support',
          message: `Oops! Something went wrong`,
        })
      }
    })
  }

  return (
    <Form onSubmit={handleOnSubmit}>
      <Form.Item>
        {form.getFieldDecorator('inviteNewUsers', {
          initialValue: inviteNewUsers,
          valuePropName: 'checked',
        })(
          <Checkbox>
            When new team members are added in Personio, automatically invite
            them to Howamigoing
          </Checkbox>,
        )}
      </Form.Item>
      <Form.Item>
        <Button type="primary" loading={saving} htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'PersonioConfigForm' })(ConfigForm)
