import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import speedometer from '../../_assets/img/speedometer.svg'

export default ({ target, style }) => {
  const localTarget = target || 'form'

  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: 'rgba(52, 219, 230, 0.15)',
        border: 'solid 1px #34DBE6',
        borderRadius: '6px',
        color: '#051617',
        display: 'flex',
        fontSize: '14px',
        lineHeight: '22px',
        marginBottom: '24px',
        padding: '9px 16px',
        ...style,
      }}
    >
      <FontAwesomeIcon
        icon={['fal', 'info-circle']}
        style={{
          color: '#34dbe6',
          fontSize: 16,
          marginRight: 8,
        }}
      />
      <div style={{ marginRight: 'auto' }}>
        {localTarget === 'form' && (
          <>
            The
            <img
              src={speedometer}
              alt="Sentiment analysis logo"
              style={{ display: 'inline-block', margin: '0 8px' }}
            />
            symbol indicates sentiment analysis is available for one or more
            questions in this form.
          </>
        )}
        {localTarget === 'question' && (
          <>
            <img
              src={speedometer}
              alt="Sentiment analysis logo"
              style={{ display: 'inline-block', margin: '0 8px' }}
            />
            Sentiment analysis is active for some questions, which changes the
            way responses are displayed.
          </>
        )}
      </div>
      <div>
        <a
          href="https://help.howamigoing.com/en/articles/5404364-what-is-sentiment-analysis"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#0b464a', textDecoration: 'underline' }}
        >
          Learn more
        </a>
      </div>
    </div>
  )
}
