import '../ResetPassword/style.less'

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Typography,
  notification,
} from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import Logo from '../_assets/img/logo.svg'
import trimmedEmailValidator from '../_helpers/trimmedEmailValidator'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import { UserContext } from '../UserContext'

const { Text } = Typography

const ResetPassword = ({ form }) => {
  const user = useContext(UserContext)
  const history = useHistory()

  useMobileOptimized(true)

  const {
    getFieldDecorator,
    validateFields,
    getFieldError,
    getFieldValue,
  } = form

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [repeatEmailError, setRepeatEmailError] = useState('')

  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useMobileOptimized(true)

  const handleSubmit = evt => {
    evt.preventDefault()

    validateFields(async (errors, values) => {
      try {
        if (!errors) {
          setEmailError(null)
          setRepeatEmailError(null)
          setIsSubmitting(true)
          await user.sendPasswordResetLink(values.email)
          setIsSubmitting(false)
          history.push('/resetPassword/sent', { email: values.email })
        } else {
          setEmailError(getFieldError('email'))
          setRepeatEmailError(getFieldError('repeatEmail'))
        }
      } catch (err) {
        setIsSubmitting(false)
        setEmailError(null)
        setRepeatEmailError(null)
        const userNotExist = err.networkError.result.errors.find(
          x => x.message === 'User was not found!',
        )
        if (userNotExist) {
          notification.error({
            message: 'Oh no!',
            description: `We don't recognise your email address. To reset your password, please use the email address you've used to login before.`,
            duration: 7000,
          })
          return
        }
        notification.error({
          message: 'Oh no!',
          description: `Please try again. If you're still having problems, please contact us.`,
          duration: 7000,
        })
      }
    })
  }

  const checkEmailsMatch = ({ value }) => {
    return getFieldValue('email') === getFieldValue('repeatEmail')
  }

  return (
    <Row
      type="flex"
      justify="center"
      align="top"
      style={{ minHeight: '100vh' }}
      className="screen-container"
    >
      <Col span={7} className="col-login">
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>
        <Card className="screen-container-card">
          <div
            className="font-secondary color-primary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 12,
            }}
          >
            Forgot your password?
          </div>
          <div
            className="color-primary"
            style={{ fontWeight: 300, marginBottom: '24px' }}
          >
            It happens to the best of us. Enter your email and we’ll set you up
            with a new password in no time.
          </div>
          <Form
            layout="horizontal"
            onSubmit={handleSubmit}
            hideRequiredMark
            colon={false}
            className="reset-password-form"
          >
            <Form.Item
              style={{ marginBottom: 16 }}
              label={<Text style={{ fontWeight: 300 }}>Email</Text>}
              help={emailError || ''}
              validateStatus={emailError ? 'error' : ''}
            >
              {getFieldDecorator('email', {
                rules: [{ validator: trimmedEmailValidator }],
              })(
                <Input
                  style={{
                    boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                  }}
                  placeholder="harriet@howamigoing.com"
                  autoCorrect="off"
                  autoCapitalize="none"
                />,
              )}
            </Form.Item>
            <Form.Item
              style={{ marginBottom: 24 }}
              label={<Text style={{ fontWeight: 300 }}>Confirm email</Text>}
              help={repeatEmailError || ''}
              validateStatus={repeatEmailError ? 'error' : ''}
            >
              {getFieldDecorator('repeatEmail', {
                rules: [
                  {
                    validator: checkEmailsMatch,
                    message: 'Both emails need to match!',
                  },
                ],
              })(
                <Input
                  style={{
                    boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
                  }}
                  placeholder="harriet@howamigoing.com"
                  autoCorrect="off"
                  autoCapitalize="none"
                />,
              )}
            </Form.Item>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Button
                disabled={isSubmitting}
                loading={isSubmitting}
                type="accent"
                size="large"
                htmlType="submit"
                style={{ marginRight: 16, marginTop: 8 }}
              >
                Get reset link
              </Button>
              <Button type="link" style={{ marginTop: 8, padding: 0 }}>
                <Link to="/login" style={{ color: '#ec5747' }}>
                  Actually, I remember it
                </Link>
              </Button>
            </div>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default Form.create({ name: 'resetPasswordForm' })(ResetPassword)
