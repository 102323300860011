import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useState } from 'react'

import ActivateModal from './ActivateModal'

const TEXT_STYLE = {
  color: '#281765',
  fontWeight: 300,
  fontSize: 16,
  lineHeight: '20px',
}

export default ({ status, error }) => {
  const [visibleActivateModal, setVisibleActivateModal] = useState(false)

  const showActivateModal = () => {
    setVisibleActivateModal(true)
  }

  const hideActivateModal = () => {
    setVisibleActivateModal(false)
  }

  if (!status) return null

  if (error && error.code === 'expired_account') {
    return (
      <>
        <p style={{ color: '#e01c1c', fontWeight: 500 }}>
          <FontAwesomeIcon
            icon={['far', 'ban']}
            style={{ color: '#e01c1c', fontSize: '20px', marginRight: '8px' }}
          />{' '}
          Your Personio account has expired
          <a
            href={error.details.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 8 }}
          >
            <Button
              size="small"
              type="ghost"
              href={error.details.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Fix now
            </Button>
          </a>
        </p>
        <ActivateModal
          visible={visibleActivateModal}
          onOk={hideActivateModal}
          onCancel={hideActivateModal}
        />
      </>
    )
  }

  if (error && error.code === 'invalid_credentials') {
    return (
      <>
        <p style={{ color: '#e01c1c', fontWeight: 500 }}>
          <FontAwesomeIcon
            icon={['far', 'ban']}
            style={{ color: '#e01c1c', fontSize: '20px', marginRight: '8px' }}
          />{' '}
          There's a problem with your credentials
          <Button
            size="small"
            type="ghost"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 8 }}
            onClick={showActivateModal}
          >
            Fix now
          </Button>
        </p>
        <ActivateModal
          visible={visibleActivateModal}
          onOk={hideActivateModal}
          onCancel={hideActivateModal}
        />
      </>
    )
  }

  if (error && error.code === 'missing_attributes') {
    return (
      <>
        <p style={{ color: '#e01c1c', fontWeight: 500 }}>
          <FontAwesomeIcon
            icon={['far', 'ban']}
            style={{ color: '#e01c1c', fontSize: '20px', marginRight: '8px' }}
          />{' '}
          Your Personio account is missing the attribute(s) below
          <a
            href={error.details.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginLeft: 8 }}
          >
            <Button
              size="small"
              type="ghost"
              href={error.details.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Fix now
            </Button>
          </a>
        </p>
        <ul style={TEXT_STYLE}>
          {error.details.attributes.map(x => {
            return (
              <li
                style={{
                  ...TEXT_STYLE,
                  marginTop: 10,
                  display: 'list-item',
                  listStyleType: 'disc',
                  marginLeft: '1.5em',
                }}
              >
                {x.label}
              </li>
            )
          })}
        </ul>
      </>
    )
  }

  if (error) {
    return (
      <p style={{ color: '#e01c1c', fontWeight: 500 }}>
        <FontAwesomeIcon
          icon={['far', 'ban']}
          style={{ color: '#e01c1c', fontSize: '20px', marginRight: '8px' }}
        />{' '}
        There was a problem with your Personio integration. Please contact
        support
      </p>
    )
  }

  switch (status) {
    case 'inactive':
    case 'deactivated':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={['far', 'ban']}
            style={{ color: '#77779f', fontSize: '20px', marginRight: '8px' }}
          />
          <span style={TEXT_STYLE}>Inactive</span>
        </div>
      )

    case 'active':
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={['far', 'check']}
            style={{ color: '#47d764', fontSize: '20px', marginRight: '8px' }}
          />
          <span style={TEXT_STYLE}>Active</span>
        </div>
      )

    default:
      return null
  }
}
