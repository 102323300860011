
const getStorageItem = (key) => JSON.parse(localStorage.getItem(key));

export const setNotifications = (response) => {
  const { totalPublic, totalPrivate, totalUnread } = response.data.totalAmasCount.data;
  const defaultValues = { total: 0, showNotifications: false };
  const previousPrivate = getStorageItem("private") || defaultValues;
  const previousPublic = getStorageItem("public") || defaultValues;
  if (totalPrivate > previousPrivate.total)
    localStorage.setItem('private', JSON.stringify({ total: totalPrivate, showNotifications: true }));

  if (totalPublic > previousPublic.total)
    localStorage.setItem('public', JSON.stringify({ total: totalPublic, showNotifications: true }));

  localStorage.setItem('inbox', totalUnread);
}

export const getWallNotifications = (response) => {
  setNotifications(response);
  const privateWall = localStorage.getItem('private');
  const publicWall = localStorage.getItem('public');
  const inboxWall = localStorage.getItem('inbox');
  const notifications = {
    "inbox": parseInt(inboxWall) > 0,
    "privateArea": privateWall ? JSON.parse(privateWall).showNotifications : false,
    "publicWall": publicWall ? JSON.parse(publicWall).showNotifications : false
  };
  const sideBarNotification = document.getElementById("totalWalls");
  if (sideBarNotification) {
    if (!notifications.inbox && !notifications.privateArea && !notifications.publicWall)
      sideBarNotification.style.display = "none";
  }
  return notifications;
}