import { Menu } from 'antd'
import React, { useState } from 'react'

import HelpIcon from '../../../_components/HelpIcon'
import DeleteTeamModal from '../DeleteTeamModal'
import EditTeamModal from '../EditTeamModal'

export default ({ teamId, onClick, canDelete }) => {
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)

  const handleClick = (key, evt) => {
    evt.stopPropagation()
    switch (key) {
      case 'edit':
        showEditTeamModal()
        break
      case 'delete':
        showDeleteTeamModal()
        break
      default:
    }
    if (onClick) {
      onClick(evt)
    }
  }

  const showEditTeamModal = () => setEditModalVisible(true)
  const closeEditTeamModal = () => setEditModalVisible(false)

  const showDeleteTeamModal = () => setDeleteModalVisible(true)
  const closeDeleteTeamModal = () => setDeleteModalVisible(false)

  return (
    <>
      <Menu
        onClick={param => handleClick(param.key, param.domEvent)}
        className="company-team-menu"
      >
        <Menu.Item key={'edit'}>Edit team</Menu.Item>
        <Menu.Item key={'delete'} disabled={!canDelete}>
          <HelpIcon
            tooltip={!canDelete ? 'Must be deleted from Personio' : null}
          >
            Delete team
          </HelpIcon>
        </Menu.Item>
      </Menu>
      <EditTeamModal
        teamId={teamId}
        visible={editModalVisible}
        onOk={closeEditTeamModal}
        onCancel={closeEditTeamModal}
      />
      <DeleteTeamModal
        teamId={teamId}
        visible={deleteModalVisible}
        onOk={closeDeleteTeamModal}
        onCancel={closeDeleteTeamModal}
      />
    </>
  )
}
