import './styles.less'

import { Empty, Spin } from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import Croppie from 'croppie'
import debounce from 'lodash/debounce'

export default ({
  src,
  cropWidth = 200,
  cropHeight = 200,
  shape = 'square',
  onChange = ({ image, left, top, width, height }) => undefined,
  onLoadStart = () => undefined,
  onLoadComplete = image => undefined,
  onLoadError = () => undefined,
}) => {
  const containerEle = useRef(null)
  const previewEle = useRef(null)
  const croppie = useRef(null)
  const file = useRef(null)
  const [loadingImage, setLoadingImage] = useState(false)

  const handleOnChange = (file, evt) => {
    if (!onChange || loadingImage) {
      return
    }
    onChange({
      image: file,
      crop: {
        left: parseInt(evt.points[0]),
        top: parseInt(evt.points[1]),
        width: parseInt(evt.points[3]) - parseInt(evt.points[1]),
        height: parseInt(evt.points[2]) - parseInt(evt.points[0]),
      },
    })
  }

  const getImageDimensions = dataUrl => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
        resolve({ width: img.width, height: img.height })
      }
      img.onerror = reject
      img.src = dataUrl
    })
  }

  const readFile = path => {
    return fetch(path)
      .then(response => {
        return Promise.all([
          response.blob(),
          Promise.resolve(response.headers.get('Content-Type')),
        ])
      })
      .then(([blob, type]) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()

          reader.onload = () => {
            const dataUrl = reader.result
            resolve({
              blob,
              type,
              dataUrl,
            })
          }
          reader.onerror = err => reject(err)
          reader.readAsDataURL(blob)
        })
      })
      .then(({ blob, type, dataUrl }) => {
        return Promise.all([
          Promise.resolve(blob),
          Promise.resolve(type),
          Promise.resolve(dataUrl),
          getImageDimensions(dataUrl),
        ])
      })
      .then(([blob, type, dataUrl, dimensions]) => {
        const file = new Blob([blob], {
          type: type,
        })
        return {
          file: {
            image: file,
            width: dimensions.width,
            height: dimensions.height,
          },
          dataUrl,
        }
      })
  }

  useEffect(() => {
    if (!previewEle || !src) {
      return
    }
    setLoadingImage(true)
    onLoadStart()
    readFile(src)
      .then(result => {
        if (!croppie.current) {
          croppie.current = new Croppie(previewEle.current, {
            viewport: {
              width: cropWidth,
              height: cropHeight,
              type: shape,
            },
            update: (res =>
              debounce(evt => {
                handleOnChange(file.current, evt)
              }, 300))(result),
          })
        }

        file.current = result.file

        croppie.current.bind({
          url: result.dataUrl,
          enforceBoundary: true,
          zoom: 0,
        })

        onLoadComplete(result.file)
      })
      .catch(e => {
        onLoadError(e)
      })
      .finally(() => setLoadingImage(false))
  }, [src])

  useEffect(() => {
    return () => {
      if (croppie.current) {
        croppie.current.destroy()
      }
    }
  }, [])

  return (
    <div className="crop-image" ref={containerEle}>
      <div ref={previewEle} className="crop-image-preview"></div>
      {!loadingImage && !src && (
        <Empty description="No image selected" className="crop-image-empty" />
      )}
      {loadingImage && <Spin size="large" className="crop-image-loader" />}
    </div>
  )
}
