import { useMutation } from '@apollo/react-hooks'
import { Button, Col, Form, Input, Row, Typography, notification } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'

import { track } from '../_helpers/analytics'
import randomMinMaxInteger from '../_helpers/randomMinMaxInteger'
import trimmedEmailValidator from '../_helpers/trimmedEmailValidator'
import trimmedMinLengthValidator from '../_helpers/trimmedMinLengthValidator'
import { SIGN_UP } from './queries'

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const referralPlaceholderOptions = [
  `It came to me in a dream`,
  `I heard it whispered in the trees`,
  `Referral said you're the SpaceX of feedback`,
  `Referral said 'better than sliced bread'`,
  `E.g. Referral said 'better than The Beatles'`,
  `Referral: 'better than a cold beer on a hot day'`,
  `Someone on LinkedIn said you're awesome`,
  `A friend recommended drinking the Kool-Aid`,
  `E.g. I heard Beyonce loves Howamigoing!`,
]

const SignUp = ({ form, finishSignUp }) => {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    validateFields,
    isFieldTouched,
  } = form
  const [firstName, setFirstName] = useState(null)
  const [email, setEmail] = useState(null)
  const [org, setOrg] = useState(null)
  const [referral, setReferral] = useState(null)
  const [timezone] = useState(() => moment.tz.guess())
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [referralPlaceholder, setReferralPlaceholder] = useState(null)

  const [formHeight, setFormHeight] = useState(0)
  const [formExpanded, setFormExpanded] = useState(false)

  const firstNameError =
    isFieldTouched('firstname') && getFieldError('firstname')
  const emailError = isFieldTouched('email') && getFieldError('email')
  const orgError = isFieldTouched('org') && getFieldError('org')
  const referralError = isFieldTouched('referral') && getFieldError('referral')

  const [signUp] = useMutation(SIGN_UP, {
    variables: {
      firstName,
      email,
      org,
      referral,
      timezone,
    },
    errorPolicy: 'all',
  })

  useEffect(() => {
    const randomReferralIndex = randomMinMaxInteger(
      0,
      referralPlaceholderOptions.length - 1,
    )
    setReferralPlaceholder(referralPlaceholderOptions[randomReferralIndex])
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    if (formExpanded) {
      validateFields(async (error, values) => {
        if (!error) {
          setIsSubmitting(true)
          signUp()
            .then(response => {
              track('orgs.signup.earlyaccess', {
                firstName,
                email,
                org,
                referral,
              })
              setIsSubmitting(false)
              finishSignUp(response.data)
            })
            .catch(err => {
              setIsSubmitting(false)

              // TODO: feels like error handling is getting too fiddly
              if (
                err.networkError.result.errors &&
                err.networkError.result.errors[0].message.includes(
                  'duplicate key value',
                )
              ) {
                notification.error({
                  message: `Oops!`,
                  description: `Looks like you're already with us. Please check your email for a link to verify your account.`,
                })
              } else if (
                err.networkError.result.errors &&
                err.networkError.result.errors[0].message.includes(
                  'Cannot create account with this email',
                )
              ) {
                notification.error({
                  message: `Well, well, well... 🕵🏻‍♂️🤔`,
                  description: `Your company already has a Howamigoing account. Please ask your HR team to invite you :-)`,
                })
              } else {
                notification.error({
                  message: 'Oops!',
                  description: 'An error has occurred. Please try again.',
                })
              }
            })
        }
      })
    } else {
      setFormHeight('auto')
    }
  }

  return (
    <Form hideRequiredMark colon={false}>
      <Row type="flex">
        <AnimateHeight
          id="signup-email-form"
          height={formHeight}
          onAnimationEnd={() => {
            setFormExpanded(true)
            validateFields()
          }}
        >
          <Col xs={24} sm={24} md={24}>
            <Typography.Title
              level={4}
              style={{
                fontWeight: 500,
                marginBottom: '16px',
              }}
            >
              Create your account
            </Typography.Title>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Form.Item
              validateStatus={firstNameError ? 'error' : ''}
              help={firstNameError || ''}
              label={'First name'}
            >
              {getFieldDecorator('firstname', {
                rules: [
                  { required: true, message: 'Please enter your first name' },
                  {
                    validator: trimmedMinLengthValidator({
                      min: 1,
                      message: 'Your first name must be at least 1 character',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder="Harriet"
                  onChange={e => setFirstName(e.target.value)}
                />,
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item
              validateStatus={emailError ? 'error' : ''}
              help={emailError || ''}
              label={'Email'}
            >
              {getFieldDecorator('email', {
                rules: [{ validator: trimmedEmailValidator }],
              })(
                <Input
                  placeholder="harriet@puppy.com"
                  onChange={e => setEmail(e.target.value)}
                  autoCorrect="off"
                  autoCapitalize="none"
                />,
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item
              validateStatus={orgError ? 'error' : ''}
              help={orgError || ''}
              label={'Company'}
            >
              {getFieldDecorator('org', {
                rules: [
                  {
                    required: true,
                    message: `Please enter your company's name`,
                  },
                  {
                    validator: trimmedMinLengthValidator({
                      min: 2,
                      message: `Your company's name must be at least 2 characters`,
                    }),
                  },
                ],
              })(
                <Input
                  placeholder="Puppy Co."
                  onChange={e => setOrg(e.target.value)}
                />,
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={24}>
            <Form.Item
              validateStatus={referralError ? 'error' : ''}
              help={referralError || ''}
              label={'How’d you hear about us?'}
              style={{ marginBottom: '40px' }}
            >
              {getFieldDecorator('referral', {
                rules: [
                  {
                    required: true,
                    message: `Please let us know where you found us`,
                  },
                  {
                    validator: trimmedMinLengthValidator({
                      min: 2,
                      message: `Please enter at least 2 characters`,
                    }),
                  },
                ],
              })(
                <Input
                  placeholder={referralPlaceholder}
                  onChange={e => setReferral(e.target.value)}
                />,
              )}
            </Form.Item>
          </Col>
        </AnimateHeight>
        <Col xs={24} sm={24} md={24}>
          <Form.Item className="signup-form-button" style={{ marginTop: 0 }}>
            <Button
              type="accent"
              size="large"
              htmlType="submit"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={
                formExpanded && (isSubmitting || hasErrors(getFieldsError()))
              }
              style={{ width: '100%' }}
              aria-expanded={formHeight !== 0}
              aria-controls="signup-email-form"
            >
              {formExpanded ? 'Awesome, now let me in!' : 'Continue with email'}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default Form.create({ name: 'signUpForm' })(SignUp)
