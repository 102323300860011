import { useMutation } from '@apollo/react-hooks'
import { Button, notification } from 'antd'
import React, { useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import AutosaveIndicator from '../../../_components/AutosaveIndicator'
import { Answer } from '../../components'
import { EDIT_ANSWER } from './queries'
import useDraft from './useDraft'

const EditAnswer = ({
  id,
  recipient,
  answer,
  visibility,
  draft,
  onCancel,
  onSave,
}) => {
  const formRef = useRef(null)
  const lastChangedFieldRef = useRef(null)

  const [disabledForm, setDisabledForm] = useState(false)
  const [savingAnswer, setSavingAnswer] = useState(false)
  const [autosaveVisible, setAutosaveVisible] = useState()
  const autosaveTimeoutId = useRef(null)

  const [editAnswer] = useMutation(EDIT_ANSWER, {
    refetchQueries: ['askMeAnythings'],
    awaitRefetchQueries: true,
  })

  const loadedDraft = {
    lastSyncedAt: draft ? draft.lastSyncedAt : undefined,
    syncedValue: draft
      ? { answer: draft.answer, visibility: draft.visibility }
      : undefined,
  }

  const {
    data: draftData,
    loading: loadingDraft,
    error: draftError,
    save: saveDraft,
  } = useDraft(id, loadedDraft, {
    onSaved: () => {
      if (lastChangedFieldRef.current === 'answer') {
        showAndDelayHideAutosave(3000)
      }
    },
  })

  const onSaveClick = async () => {
    setDisabledForm(true)
    setSavingAnswer(true)
    formRef.current.validateFields(async (errors, values) => {
      try {
        if (errors) {
          setDisabledForm(false)
          setSavingAnswer(false)
          return
        }
        await editAnswer({
          variables: {
            amaId: id,
            answer: values.answer,
            visibility: values.visibility,
          },
        })
        setDisabledForm(false)
        setSavingAnswer(false)
        notification.success({
          message: `Changes saved!`,
          description: 'Your answer has been edited',
        })
        if (onSave) {
          onSave()
        }
      } catch (e) {
        setDisabledForm(false)
        setSavingAnswer(false)
        notification.error({
          message: 'Oops! Something went wrong...',
          description: 'Please try again',
          duration: 0,
        })
      }
    })
  }

  const [handleFormChange] = useDebouncedCallback(
    async ({ values, lastChangedField }) => {
      lastChangedFieldRef.current = lastChangedField
      await saveDraft({
        answer: values.answer ? values.answer : '',
        visibility: values.visibility,
      })
    },
    200,
  )

  const showAndDelayHideAutosave = waitMs => {
    setAutosaveVisible(true)
    if (autosaveTimeoutId.current) {
      clearTimeout(autosaveTimeoutId.current)
      autosaveTimeoutId.current = null
    }
    autosaveTimeoutId.current = setTimeout(() => {
      setAutosaveVisible(false)
    }, waitMs)
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Answer.Form
          ref={formRef}
          recipient={recipient}
          defaultValues={draftData ? draftData : { visibility, answer }}
          onChange={handleFormChange}
          disabled={loadingDraft}
        />
        {
          <AutosaveIndicator
            visible={autosaveVisible || draftError}
            text={draftError ? draftError : 'Saved'}
            theme={draftError ? 'error' : 'success'}
            style={{ position: 'absolute', bottom: 0 }}
          />
        }
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '32px',
        }}
      >
        <Button type="ghost" onClick={onCancel} disabled={disabledForm}>
          Cancel
        </Button>
        <Button
          type="accent"
          onClick={onSaveClick}
          style={{ marginLeft: '10px' }}
          loading={savingAnswer}
          disabled={disabledForm}
        >
          Save
        </Button>
      </div>
    </>
  )
}

export default EditAnswer
