import { Modal } from 'antd'
import React, { useRef, useState } from 'react'

import EditUserForm from './EditUserForm'

export default ({ onOk, user, visible, onCancel, teams, canChangeOwner }) => {
  const [loading, setLoading] = useState(false)
  const formRef = useRef()

  const handleSubmit = () => {
    formRef.current.validateFields(async (err, values) => {
      try {
        if (err) return
        setLoading(true)

        const role = (() => {
          if (values.isAdmin) {
            return 'admin'
          }
          if (values.isOwner) {
            return 'owner'
          }
          return 'user'
        })()

        await onOk({
          id: user.id,
          ...values,
          managerId: values.manager ? values.manager.id : null,
          viewingManagerIds: values.viewingManagers
            ? values.viewingManagers.map(x => x.id)
            : [],
          directReportIds: values.directReports
            ? values.directReports.map(x => x.id)
            : [],
          role,
          teamId: values.teamId ? values.teamId : null,
        })
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    })
  }

  return (
    <Modal
      className="edit-user-modal"
      title={false}
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
      okButtonProps={{
        disabled: loading,
        type: 'accent',
      }}
      confirmLoading={loading}
      okText="Save"
      cancelText="Cancel"
      cancelButtonProps={{ disabled: loading, type: 'ghost' }}
      closable={false}
      maskClosable={false}
      width={656}
      centered
    >
      <EditUserForm
        ref={formRef}
        submit={handleSubmit}
        user={user}
        teams={teams}
        canChangeOwner={canChangeOwner}
      />
    </Modal>
  )
}
