import './styles.less'

import { useLazyQuery } from '@apollo/react-hooks'
import { Col, List, Modal, Popover, Row, Tag, Tooltip } from 'antd'
import { sortBy } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'

import {
  Avatar,
  AvatarJobTitle,
  AvatarName,
} from '../../../../_components/Avatar'
import {
  findFeedbackGiven,
  findFeedbackRequests,
  findFeedbackToGive,
  findSubmittedFeedback,
  getParticipants,
} from './calculateCycleProgress'
import { GET_CYCLE_OVERVIEW } from './queries'

const POPOVER_STYLE = {
  maxHeight: 320,
  overflow: 'auto',
  padding: '0 16px',
  margin: '0 -16px',
}

const STYLE_TAG_RED = {
  border: '1px solid #f95c4b',
  borderRadius: 12,
  color: '#f95c4b',
  padding: '5px 12px',
  fontSize: 14,
}
const STYLE_TAG_BLUE = {
  border: '1px solid #77779f',
  borderRadius: 12,
  color: '#77779f',
  padding: '5px 12px',
  fontSize: 14,
}

const NotApplicable = ({ reason }) => {
  return (
    <Tooltip title={reason}>
      <span style={{ fontSize: 14, cursor: 'pointer' }}>N/A</span>
    </Tooltip>
  )
}

export default ({ cycleId, showModal, handleCloseModal }) => {
  const [users, setUsers] = useState([])
  const [indentAmount, setIndentAmount] = useState(0)
  const listContainerRef = useRef(null)

  const [fetchOverview, { data, error, loading }] = useLazyQuery(
    GET_CYCLE_OVERVIEW,
    {
      variables: {
        cycleId: cycleId,
      },
      fetchPolicy: 'network-only',
    },
  )

  useEffect(() => {
    if (showModal === true) {
      fetchOverview()
    }
  }, [showModal])

  useEffect(() => {
    if (!data) {
      return
    }
    const { cycle } = data
    const participants = getParticipants(cycle)
    const usersArr = participants.map(participant => {
      const received = findSubmittedFeedback(participant, cycle)
      return {
        id: participant.id,
        email: participant.email,
        firstName: participant.firstName,
        lastName: participant.lastName,
        jobTitle: participant.jobTitle,
        avatarUrl: participant.avatarUrl,
        enrolled: !!cycle.reviews.find(x => x.user.id === participant.id),
        feedbacks: {
          requests: findFeedbackRequests(participant, cycle),
          responses: {
            toGive: findFeedbackToGive(participant, cycle),
            given: findFeedbackGiven(participant, cycle),
          },
          received,
        },
      }
    })
    setUsers(sortBy(usersArr, ['firstName', 'email']))
  }, [data])

  useEffect(() => {
    if (!listContainerRef.current) {
      return
    }
    const amount =
      listContainerRef.current.offsetWidth -
      listContainerRef.current.clientWidth
    if (amount === indentAmount) {
      return
    }
    setIndentAmount(amount)
  }, [listContainerRef.current])

  const renderPopoverList = source => {
    const activeUsers = source
      .filter(x => x.user.status !== 'DEIDENTIFIED')
      .sort((a, b) => (a.user.firstName > b.user.firstName ? 1 : -1))
    const deidentifiedUsers = source.filter(
      x => x.user.status === 'DEIDENTIFIED',
    )
    const orderedSource = [...activeUsers, ...deidentifiedUsers]
    return (
      <List
        size="default"
        itemLayout="vertical"
        dataSource={orderedSource}
        renderItem={feedback => (
          <List.Item
            key={feedback.user.id}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              padding: '8px 0',
            }}
            className="overview-popover-list-avatar-item"
          >
            <Avatar
              style={{
                borderRadius: '16px',
                borderWidth: '2px',
              }}
              user={feedback.user}
              size={40}
            />
            <AvatarName user={feedback.user} style={{ margin: '0 0 0 8px' }} />
          </List.Item>
        )}
      />
    )
  }

  return (
    <Modal
      title={
        <div style={{ fontFamily: 'museo', fontWeight: 500 }}>
          See how others are going
        </div>
      }
      destroyOnClose={true}
      style={{ top: 20 }}
      bodyStyle={{ paddingLeft: 0 }}
      footer={null}
      visible={showModal}
      onCancel={() => handleCloseModal()}
      centered
      width={800}
    >
      {loading ? (
        <div style={{ paddingLeft: '32px' }}>Loading...</div>
      ) : error ? (
        <div style={{ paddingLeft: '32px' }}>Error!</div>
      ) : (
        <div className="feedback-overview">
          <Row
            gutter={12}
            style={{ color: '#77779f', marginBottom: 24, paddingLeft: '32px' }}
          >
            <Col
              span={12}
              className="font-primary color-secondary"
              style={{ fontSize: 14, fontWeight: 300 }}
            >
              Name
            </Col>
            <Col
              span={3}
              className="font-primary color-secondary"
              style={{ fontSize: 14, fontWeight: 300 }}
            >
              Requested
            </Col>
            <Col
              span={3}
              className="font-primary color-secondary"
              style={{ fontSize: 14, fontWeight: 300 }}
            >
              Received
            </Col>
            <Col
              span={3}
              className="font-primary color-secondary"
              style={{ fontSize: 14, fontWeight: 300 }}
            >
              Gave
            </Col>
            <Col
              span={3}
              className="font-primary color-secondary"
              style={{ fontSize: 14, fontWeight: 300 }}
            >
              Owes
            </Col>
          </Row>
          <div
            style={{
              maxHeight: '50vh',
              overflowY: 'auto',
              overflowX: 'visible',
              marginRight: -indentAmount,
            }}
            ref={listContainerRef}
          >
            <List
              size="large"
              itemLayout="vertical"
              dataSource={users}
              split={false}
              className="overflow-protection"
              renderItem={user => (
                <List.Item key={user.id} style={{ padding: '0 0 16px' }}>
                  <Row gutter={12} type={'flex'} align={'middle'}>
                    <Col span={2}>
                      <Avatar user={user} />
                    </Col>
                    <Col span={10}>
                      <div style={{ paddingLeft: 8 }}>
                        <div
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <AvatarName
                            user={user}
                            style={{
                              fontFamily: 'museo',
                              color: '#1c1047',
                              fontWeight: 300,
                              fontSize: '14px',
                            }}
                          />
                        </div>
                        <div
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <AvatarJobTitle
                            user={user}
                            style={{
                              color: '#281765',
                              fontWeight: 300,
                              fontSize: '14px',
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col span={3}>
                      {user.feedbacks.requests &&
                      user.feedbacks.requests.length > 0 ? (
                        <Popover
                          overlayClassName="overview-popover"
                          getPopupContainer={trigger =>
                            trigger.closest('.ant-modal-wrap')
                          }
                          content={
                            <div style={POPOVER_STYLE}>
                              {renderPopoverList(user.feedbacks.requests)}
                            </div>
                          }
                        >
                          <Tag
                            color={
                              user.feedbacks.requests.length >= 2
                                ? 'rgba(119, 119, 159, 0.12)'
                                : 'rgba(249, 92, 75, 0.12)'
                            }
                            style={{
                              ...(user.feedbacks.requests.length >= 2
                                ? STYLE_TAG_BLUE
                                : STYLE_TAG_RED),
                              ...{ cursor: 'pointer' },
                            }}
                          >
                            {user.feedbacks.requests.length}
                          </Tag>
                        </Popover>
                      ) : user.feedbacks.requests &&
                        user.feedbacks.requests.length === 0 ? (
                        <Tag
                          color={'rgba(249, 92, 75, 0.12)'}
                          style={STYLE_TAG_RED}
                        >
                          0
                        </Tag>
                      ) : (
                        <NotApplicable reason="This person wasn't enrolled in the event, so they didn't have to request feedback." />
                      )}
                    </Col>
                    <Col span={3}>
                      {user.feedbacks.received &&
                      user.feedbacks.received.length > 0 ? (
                        <Popover
                          overlayClassName="overview-popover"
                          getPopupContainer={trigger =>
                            trigger.closest('.ant-modal-wrap')
                          }
                          content={
                            <div style={POPOVER_STYLE}>
                              {user.feedbacks.received.length > 0 && (
                                <div>
                                  {renderPopoverList(user.feedbacks.received)}
                                </div>
                              )}
                            </div>
                          }
                        >
                          <Tag
                            color={
                              user.feedbacks.received.length >= 2
                                ? 'rgba(119, 119, 159, 0.12)'
                                : 'rgba(249, 92, 75, 0.12)'
                            }
                            style={{
                              ...(user.feedbacks.received.length >= 2
                                ? STYLE_TAG_BLUE
                                : STYLE_TAG_RED),
                              ...{ cursor: 'pointer' },
                            }}
                          >
                            {user.feedbacks.received.length}
                          </Tag>
                        </Popover>
                      ) : user.feedbacks.received &&
                        user.feedbacks.received.length === 0 ? (
                        <Tag
                          color="rgba(249, 92, 75, 0.12)"
                          style={STYLE_TAG_RED}
                        >
                          0
                        </Tag>
                      ) : (
                        <NotApplicable reason="This person wasn't enrolled in the event, so they won't have received any feedback." />
                      )}
                    </Col>
                    <Col span={3}>
                      {user.feedbacks.responses.given.length > 0 ? (
                        <Popover
                          overlayClassName="overview-popover"
                          getPopupContainer={trigger =>
                            trigger.closest('.ant-modal-wrap')
                          }
                          content={
                            <div style={POPOVER_STYLE}>
                              {user.feedbacks.responses.given.length > 0 && (
                                <div>
                                  {renderPopoverList(
                                    user.feedbacks.responses.given,
                                  )}
                                </div>
                              )}
                            </div>
                          }
                        >
                          <Tag
                            color="rgba(119, 119, 159, 0.12)"
                            style={{
                              ...STYLE_TAG_BLUE,
                              ...{ cursor: 'pointer' },
                            }}
                          >
                            {user.feedbacks.responses.given.length}
                          </Tag>
                        </Popover>
                      ) : (
                        <Tag
                          color="rgba(119, 119, 159, 0.12)"
                          style={STYLE_TAG_BLUE}
                        >
                          0
                        </Tag>
                      )}
                    </Col>
                    <Col span={3}>
                      {user.feedbacks.responses.toGive.length > 0 ? (
                        <Popover
                          overlayClassName="overview-popover"
                          getPopupContainer={trigger =>
                            trigger.closest('.ant-modal-wrap')
                          }
                          content={
                            <div style={POPOVER_STYLE}>
                              {user.feedbacks.responses.toGive.length > 0 && (
                                <div>
                                  {renderPopoverList(
                                    user.feedbacks.responses.toGive,
                                  )}
                                </div>
                              )}
                            </div>
                          }
                        >
                          <Tag
                            color="rgba(249, 92, 75, 0.12)"
                            style={{
                              ...STYLE_TAG_RED,
                              ...{ cursor: 'pointer' },
                            }}
                          >
                            {user.feedbacks.responses.toGive.length}
                          </Tag>
                        </Popover>
                      ) : (
                        <Tag
                          color="rgba(119, 119, 159, 0.12)"
                          style={STYLE_TAG_BLUE}
                        >
                          0
                        </Tag>
                      )}
                    </Col>
                  </Row>
                </List.Item>
              )}
            ></List>
          </div>
        </div>
      )}
    </Modal>
  )
}
