import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'

import Card from '../../Card'
import Section from '../../Section'
import { COMPANY_PRIVATE_FEEDBACK } from './queries'

export default ({ timeInterval, isActive }) => {
  const [loading, setLoading] = useState(true)

  const [numbers, setNumbers] = useState({})

  const { data } = useQuery(COMPANY_PRIVATE_FEEDBACK, {
    fetchPolicy: 'cache-first',
    variables: {
      startDate: timeInterval[0],
      endDate: timeInterval[1],
    },
    skip: !isActive,
  })

  useEffect(() => {
    if (data) {
      setNumbers({
        privateStarted: data.companyPrivateFeedbackInsight.find(
          x => x.key === 'private_feedback_events_started',
        ).value,
        privateSuccessful: data.companyPrivateFeedbackInsight.find(
          x => x.key === 'private_feedback_users_successful_event',
        ).value,
        privateSubmissions: data.companyPrivateFeedbackInsight.find(
          x => x.key === 'private_feedback_users_submissions',
        ).value,
      })
      setLoading(false)
    }
  }, [data])

  return (
    <Section title="Company stats">
      <div className="insights__card-container">
        <Card
          number={numbers.privateStarted}
          unit="Private feedback events"
          tooltip="Number of Private feedback events started across the company"
          loading={loading}
        />
        <Card
          number={numbers.privateSuccessful}
          unit="successful events"
          tooltip="Number of Private feedback events where 2+ people responded and feedback was released"
          loading={loading}
        />
        <Card
          number={numbers.privateSubmissions}
          unit="feedback submissions"
          tooltip="Number of responses to Private feedback requests"
          loading={loading}
        />
      </div>
    </Section>
  )
}
