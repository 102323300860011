import { Typography } from 'antd'
import React from 'react'

import { Avatar, AvatarName } from './Avatar'

const { Text } = Typography

export default ({ user, truncateTextAt = '100%', disableTooltip = false }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar
        user={user}
        size={40}
        style={{
          flexShrink: 0,
          maxWidth: '100%',
          fontSize: 14,
          lineHeight: '32px',
        }}
        disableTooltip={disableTooltip}
      />
      <div
        style={{
          display: 'inline-block',
          marginLeft: '8px',
          maxWidth: truncateTextAt,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        <AvatarName
          user={user}
          style={{
            fontFamily: 'museo',
            color: '#1c1047',
            fontSize: 14,
            fontWeight: 400,
            display: 'block',
          }}
        />
        <Text
          title={user.jobTitle}
          style={{
            color: '#281765',
            fontWeight: 300,
            fontSize: 12,
            display: 'block',
          }}
        >
          {user.jobTitle}
        </Text>
      </div>
    </div>
  )
}
