import { Form, Input } from 'antd'

import PasswordStrengthInput from '../PasswordStrengthInput'
import React from 'react'

const Component = ({ form, onSubmit, children }) => {
  const { getFieldDecorator, getFieldValue } = form

  const validatePassword = (rule, { strength }) => {
    if (strength === 'weak') {
      return false
    }

    return true
  }

  const checkPasswordsMatch = () => {
    return getFieldValue('password').value === getFieldValue('repeatPassword')
  }

  return (
    <Form
      layout="horizontal"
      hideRequiredMark
      colon={false}
      onSubmit={onSubmit}
    >
      <Form.Item style={{ marginBottom: 16 }} label="New password">
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: 'You need to fill your password!',
            },
            {
              validator: validatePassword,
              message: 'Your password is not strong enough',
            },
          ],
        })(<PasswordStrengthInput placeholder="MyFirstPuppy123" />)}
      </Form.Item>
      <Form.Item style={{ marginBottom: 32 }} label="Confirm new password">
        {getFieldDecorator('repeatPassword', {
          rules: [
            {
              validator: checkPasswordsMatch,
              message: 'Both passwords need to match!',
            },
          ],
        })(
          <Input.Password
            style={{
              boxShadow: '0 8px 24px 0 rgba(119, 119, 159, 0.12)',
            }}
            placeholder="MyFirstPuppy123"
          />,
        )}
      </Form.Item>
      {children}
    </Form>
  )
}

export default Form.create({ name: 'SetPasswordForm' })(Component)
