import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React from 'react'

import QuestionCircle from '../_assets/img/question-circle.svg'

const titleStyle = {
  alignItems: 'center',
  color: '#281765',
  display: 'flex',
  flexWrap: 'wrap',
  fontFamily: 'Europa, sans-serif',
  fontSize: 26,
  fontWeight: 500,
  lineHeight: '40px',
  marginBottom: 38,
}

const subtitleStyle = {
  color: '#53536f',
  flex: '0 0 100%',
  fontSize: 14,
  fontWeight: 300,
  lineHeight: '22px',
}

export default ({
  style,
  explanation,
  subtitle,
  subtitleLink,
  children,
  ...props
}) => {
  return (
    <h1 {...props} style={{ ...titleStyle, ...style }}>
      {children}
      {explanation && (
        <Tooltip
          title={explanation}
          overlayClassName="tooltip--dark tooltip--dark-big"
        >
          <img
            src={QuestionCircle}
            alt="explanation"
            style={{
              cursor: 'pointer',
              height: 20,
              marginLeft: 12,
              width: 20,
            }}
          />
        </Tooltip>
      )}
      {subtitle && (
        <div style={subtitleStyle}>
          {subtitle}
          {subtitleLink && (
            <a
              href={subtitleLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#281765', textDecoration: 'underline' }}
            >
              <FontAwesomeIcon
                icon={['fal', 'play-circle']}
                style={{
                  color: '#281765',
                  marginLeft: 10,
                  marginRight: 4,
                  fontSize: 14,
                }}
              />
              Learn more
            </a>
          )}
        </div>
      )}
    </h1>
  )
}
