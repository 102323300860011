import './styles.less'

import { Button, Typography } from 'antd'
import isArray from 'lodash/isArray'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import Emoji from 'react-emoji-render'
import { useHistory, useLocation } from 'react-router-dom'

import randomMinMaxInteger from '../_helpers/randomMinMaxInteger'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import useInterval from '../hooks/useInterval'

const messages = [
  `When giving feedback, always separate the FACT (what you saw or heard) from the FEELING (your conclusion or interpretation). :face_with_monocle:`,
  `Did you know... Feedback was first discovered in the spiritual city of Los Angeles 13 years ago today? :upside_down_face:`,
  `When Adobe surveyed its employees in 2016, 20% of them admitted to crying because of their performance review. Poorly Designed Feedback? :face_with_raised_eyebrow:`,
  `Psychologists recommend giving constructive feedback within 24 hours of a slip-up, but not immediately after. Allow some time for emotions to settle. :tired_face:`,
  `Multi-tasking aka context-switching is the biggest productivity killer at work. It literally makes you dumber (according to a NASA experiment). :rocket:`,
  `Multi-tasking means taking longer and doing a worse job on each task vs doing one task to completion, without distraction, then moving to the next. :face_palm:`,
  `In sport, confidence inspires performance. In work, it's no different. Give your colleagues confidence and watch them soar. :first_place_medal:`,
  `In sport, all professionals have a coach. Is that why they're the best? :thinking_face:`,
  `Animals are amazing. Please don't ever hurt an animal. :dog:`,
  `When we surveyed 1,281 employed Brits in 2018, 56% of them had experienced anxiety because of their appraisal. That stat made us anxious. :pensive:`,
  `Drink more water. :potable_water:`,
  `We purposely use purple hues on our platform because purple evokes a sense of worth, value, kindness and sensitivity. :purple_heart:`,
  `When did you last get some praise or recognition? Sick of waiting? Send a Private feedback request and get some #goodvibes`,
  `Want to make someone's day? Be like Fatboy Slim and praise them like you should! Just click 'Good vibes' on the menu... :raised_hands:`,
  `The last time you checked your phone you got a hit of dopamine. The last time you thanked someone you got a hit of dopamine. Get hooked on the right stuff #goodvibes :raised_hands:`,
]

export default ({ onClose }) => {
  const MS_AVG_READING_SPEED = 1000 / 35
  const MS_PER_LETTER = MS_AVG_READING_SPEED * 1.25

  useMobileOptimized(false)
  const history = useHistory()
  const location = useLocation()
  const [sentence, setSentence] = useState([])
  const [currentSentenceChunkIndex, setCurrentSentenceChunkIndex] = useState(0)
  const [currentSentenceChunkLetterIndex, setcurrentSentenceChunkLetterIndex] =
    useState(-1)
  const [entering, setEntering] = useState(true)
  const [exiting, setExiting] = useState(false)
  const [homeRouteSuffix, setHomeRouteSuffix] = useState('')

  useEffect(() => {
    const selectedIndex = randomMinMaxInteger(0, messages.length - 1)
    const selectedMessage = messages[selectedIndex]
    const chunkedMessage = splitMessage(selectedMessage)
    setSentence(chunkedMessage)
    return () => setExiting(true)
  }, [])

  useEffect(() => {
    if (location.search === '?onboarded=1') {
      setHomeRouteSuffix('?onboarded=1')
    }
  }, [])

  useLayoutEffect(() => {
    setTimeout(() => setEntering(false))
  }, [])

  useInterval(() => {
    if (
      sentence.length === 0 ||
      currentSentenceChunkIndex === sentence.length
    ) {
      return
    }
    typeCharacter()
  }, MS_PER_LETTER)

  const typeCharacter = () => {
    const chunk = sentence[currentSentenceChunkIndex]
    if (!chunk) {
      return
    }
    const nextChunkLetterIndex = currentSentenceChunkLetterIndex + 1
    if (isArray(chunk.value) && chunk.value[nextChunkLetterIndex]) {
      const letter = chunk.value[nextChunkLetterIndex]
      letter.show = true
      setcurrentSentenceChunkLetterIndex(nextChunkLetterIndex)
    } else {
      chunk.show = true
      setCurrentSentenceChunkIndex(currentSentenceChunkIndex + 1)
      setcurrentSentenceChunkLetterIndex(-1)
    }
    setSentence(sentence)
  }

  const splitMessage = text => {
    const regex =
      // eslint-disable-next-line
      /(\s)|([\:\(\#\'])?(\b([A-Za-z_0-9\-\'\’]*)\b([\.\,\:\?\!\%\')])?(\.\.)?(\.)?(\))?)/g
    const chunks = text.match(regex) // splits by word, slack emoji and space
    return chunks.map(chunk => {
      const type = getChunkType(chunk)
      return {
        value:
          type === 'word'
            ? chunk.split('').map(x => {
                return { value: x, show: false, type: 'letter' }
              })
            : chunk,
        type: type,
        show: type === 'word' ? undefined : false,
      }
    })
  }

  const getChunkType = chunk => {
    if (chunk[0] === ':' && chunk[chunk.length - 1] === ':') {
      return 'emoji'
    }
    if (chunk === ' ') {
      return 'space'
    }
    return 'word'
  }

  const getClassName = char => {
    let result = 'welcome-component-content-char'
    if (char.show) {
      result += ' welcome-component-content-char-visible'
    } else {
      result += ' welcome-component-content-char-hidden'
    }
    result += ` welcome-component-content-char-${char.type}`
    return result
  }

  const handleOnClose = () => {
    setExiting(true)
    setTimeout(() => {
      if (!onClose) {
        history.push('/home' + homeRouteSuffix)
        return
      }
      onClose()
    }, 1000)
  }

  const setClassNameWithAnimationStates = className => {
    let result = className
    if (exiting) {
      result += ` ${className}-exiting`
    } else if (entering) {
      result += ` ${className}-entering`
    }
    return result
  }

  const renderText = () => {
    return sentence.map((sentenceChunk, i) => {
      if (sentenceChunk.type === 'emoji') {
        return (
          <Emoji
            key={i}
            className={getClassName(sentenceChunk)}
            text={sentenceChunk.value}
          />
        )
      }
      if (sentenceChunk.type === 'space') {
        return (
          <span key={i} className={getClassName(sentenceChunk)}>
            &nbsp;
          </span>
        )
      }
      return (
        <span key={i} className="welcome-component-content-word">
          {sentenceChunk.value.map((char, i2) => {
            return (
              <span key={`${i}-${i2}`} className={getClassName(char)}>
                {char.value}
              </span>
            )
          })}
        </span>
      )
    })
  }

  return (
    <div className={setClassNameWithAnimationStates('welcome-component')}>
      <div
        className={setClassNameWithAnimationStates('welcome-component-content')}
      >
        <Typography.Text
          className={setClassNameWithAnimationStates(
            'welcome-component-heading',
          )}
          style={{
            color: '#77779f',
          }}
        >
          A message from the Howmies
        </Typography.Text>
        <div
          className={setClassNameWithAnimationStates(
            'welcome-component-content-message',
          )}
        >
          {renderText()}
        </div>
        <Button
          className={setClassNameWithAnimationStates(
            'welcome-component-close-button',
          )}
          type="light-purple"
          size="large"
          onClick={handleOnClose}
        >
          Great, let's do this!
        </Button>
      </div>
    </div>
  )
}
