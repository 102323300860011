import {} from 'react-router-dom'

import '../ResetPassword/style.less'

import { Button, Card, Col, Row, notification } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Logo from '../_assets/img/logo.svg'
import SetPasswordForm from '../_components/Form/SetPassword'
import useMobileOptimized from '../hooks/device/useMobileOptimized'
import useQuery from '../hooks/routing/useQuery'
import { UserContext } from '../UserContext'

const ResetPasswordWithToken = () => {
  const user = useContext(UserContext)

  const { userId } = useParams()
  const query = useQuery()
  const token = query.get('t')
  const history = useHistory()
  const form = useRef(null)

  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useMobileOptimized(true)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()

    form.current.validateFields(async (errors, values) => {
      if (errors) {
        return
      }
      setIsSubmitting(true)
      try {
        await user.resetPassword({
          id: userId,
          token,
          password: values.password.value,
        })
        history.push('/resetPassword/confirm')
      } catch (err) {
        setIsSubmitting(false)
        notification.error({
          message: 'Error!',
          description:
            'There was a problem resetting your password. Please try again.',
        })
      }
    })
  }

  return (
    <Row
      type="flex"
      justify="center"
      align="top"
      style={{ minHeight: '100vh' }}
      className="screen-container"
    >
      <Col span={7} className="col-login">
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>
        <Card className="screen-container-card">
          <div
            className="font-secondary color-primary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 12,
            }}
          >
            Please enter your new bulletproof password:
          </div>

          <SetPasswordForm ref={form} />
          <Button
            disabled={isSubmitting}
            loading={isSubmitting}
            type="accent"
            size="large"
            onClick={handleSubmit}
          >
            Set new password
          </Button>
        </Card>
      </Col>
    </Row>
  )
}

export default ResetPasswordWithToken
