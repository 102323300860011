import './styles.less'

import { Icon } from 'antd'
import React, { useState } from 'react'

import UploadImageModal from './UploadImageModal'

export default ({
  src,
  originalSrc,
  alt,
  width = 'auto',
  height = 'auto',
  icon = 'camera',
  isPlaceholder = false,
  onSubmit = data => Promise.resolve(),
}) => {
  const [imageHovered, setImageHovered] = useState(false)
  const [uploadImageModalVisible, setUploadImageModalVisible] = useState(false)

  const onMouseOver = () => {
    setImageHovered(true)
  }

  const onMouseOut = () => {
    setImageHovered(false)
  }

  const onOverlayClick = () => {
    if (isPlaceholder) {
      openModal()
      return
    }
    openModal()
  }

  const handleOnSubmit = args => {
    return onSubmit(args).then(() => setUploadImageModalVisible(false))
  }

  const handleOnClose = () => closeModal()

  const openModal = () => {
    setUploadImageModalVisible(prevState => {
      if (prevState === true) {
        setTimeout(() => openModal())
        return false
      }
      return true
    })
  }

  const closeModal = () => {
    setUploadImageModalVisible(false)
  }

  return (
    <>
      <label
        htmlFor={isPlaceholder ? 'upload-image-browser' : ''}
        className="updatable-image"
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <img
          src={src ? src : originalSrc}
          alt={alt}
          width={width}
          height={height}
        />
        <div
          className="updatable-image-overlay"
          style={{
            width,
            height,
            ...(imageHovered ? { opacity: 1 } : undefined),
          }}
          onClick={onOverlayClick}
        >
          <Icon type={icon} className="updatable-image-overlay-icon" />
        </div>
      </label>
      {
        <UploadImageModal
          visible={uploadImageModalVisible}
          src={isPlaceholder ? null : originalSrc ? originalSrc : src}
          onSubmit={handleOnSubmit}
          onClose={handleOnClose}
          accept={['image/png', 'image/jpeg']}
        ></UploadImageModal>
      }
    </>
  )
}
