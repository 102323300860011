import { Button, Icon } from 'antd'
import React, { useContext, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import OrgAvatar from '../../../_components/OrgAvatar'
import { track } from '../../../_helpers/analytics'
import Overview from '../../../Feedback/modals/Overview/team360'
import { UserContext } from '../../../UserContext'

export default ({ reviewId, cycleId, minRequests }) => {
  const user = useContext(UserContext)
  const location = useLocation()
  const [overviewVisible, setOverviewVisible] = useState(false)

  const handleRequestFeedbackLinkClick = () => {
    if (location.pathname === '/') {
      track('team360.feedback.requestFeedbackToDo', {
        user: user.email,
        org: user.org.name,
        reviewId,
      })
    }
  }

  const handleOverviewClick = () => {
    setOverviewVisible(true)
  }

  return (
    <div className="dashboard-todo">
      <OrgAvatar />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>
          Request feedback from{' '}
          {minRequests ? `at least ${minRequests} people` : 'your colleagues'}
        </div>
        <Button
          type="link"
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            color: '#f95c4b',
            padding: 0,
            height: 24,
          }}
          onClick={handleOverviewClick}
        >
          <Icon type="eye" style={{ fontSize: 16 }} />
          <span
            style={{
              lineHeight: '16px',
              marginLeft: '6px',
              textDecoration: 'underline',
            }}
          >
            See how others are going
          </span>
        </Button>
      </div>
      <Link
        to={'/team360/requestFeedback/' + reviewId}
        onClick={handleRequestFeedbackLinkClick}
      >
        <Button type="primary" size="small">
          Request feedback
        </Button>
      </Link>
      <Overview
        cycleId={cycleId}
        showModal={overviewVisible}
        handleCloseModal={() => setOverviewVisible(false)}
      />
    </div>
  )
}
