import { Button } from 'antd'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Avatar from '../../../_components/Avatar'
import { track } from '../../../_helpers/analytics'

export default ({ reviewId, cycleType, cycleName, assignedTo }) => {
  const location = useLocation()

  const linkPrefix = cycleType === '360' ? '/team360' : '/personal360'

  const handleClick = () => {
    if (location.pathname === '/') {
      track('todo.viewFeedback.view', {
        provider: assignedTo.email,
        org: assignedTo.org.name,
      })
    }
  }

  return (
    <div className="dashboard-todo">
      <Avatar user={assignedTo} />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>
          Your feedback is ready to view
        </div>
        {cycleName && (
          <div style={{ color: '#6b6b8f', fontSize: '14px' }}>{cycleName}</div>
        )}
      </div>
      <Link to={`${linkPrefix}/viewFeedback/${reviewId}`} onClick={handleClick}>
        <Button type="primary" size="small">
          View feedback
        </Button>
      </Link>
    </div>
  )
}
