import './style.less'

import { useQuery } from '@apollo/react-hooks'
import { isArray, xor } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import Emoji from 'react-emoji-render'
import { useHistory } from 'react-router-dom'
import { useDebouncedCallback } from 'use-debounce'

import Search from '../../../_components/LandingPageSearch'
import Loader from '../../../_components/Loader'
import Pagination from '../../../_components/Pagination'
import { FeatureHeader } from '../../components'
import AskQuestionPage from '../../pages/AskQuestionPage'
import AMACardList from './AMACardList'
import EmptyState from './EmptyState'
import { ASK_ME_ANYTHINGS } from './queries'

const PAGE_SIZE = 10
const getOffset = pageNumber => pageNumber * PAGE_SIZE - PAGE_SIZE

const LandingPage = ({ menu, location }) => {
  const [modalVisible, setModalVisible] = useState()
  const showAskQuestionModal = () => setModalVisible(true)
  const hideAskQuestionModal = () => setModalVisible(false)

  const history = useHistory()

  const hasAMAsInAccount = useRef(false)
  const initialized = useRef(false)

  const readStateFromUrl = () => {
    const editIds = new URLSearchParams(history.location.search).getAll(
      'editIds',
    )
    return {
      editIds,
    }
  }

  const [pageState, setPageState] = useState(readStateFromUrl())
  const [pageNumber, setPageNumber] = useState(1)
  const [searchParams, setSearchParams] = useState({
    personName: undefined,
    teamId: undefined,
    endDate: undefined,
  })

  const pageStateMatches = (newState, oldState) => {
    if (!newState && !oldState) {
      return true
    }
    if (newState && !oldState) {
      return false
    }
    if (!newState && oldState) {
      return false
    }
    if (newState.length === 0 && oldState.length === 0) {
      return true
    }
    if (newState.editIds.length === 0 && oldState.editIds.length === 0) {
      return true
    }
    const editIdsValuesAreSame =
      xor(newState.editIds, oldState.editIds).length === 0
    if (editIdsValuesAreSame) {
      return true
    }
    return false
  }

  const writeStateToUrl = newState => {
    const path = history.location.pathname

    if (pageStateMatches(newState, pageState)) {
      return
    }

    const hasContents =
      newState && newState.editIds && newState.editIds.length > 0

    setPageState(newState)
    if (!hasContents) {
      history.push({
        pathname: path,
        search: '',
      })
      return
    }
    const searchParams = new URLSearchParams()
    Object.keys(newState).forEach(key => {
      const stateValue = newState[key]
      if (isArray(stateValue)) {
        stateValue.forEach(val => searchParams.append(key, val))
        return
      }
      searchParams.set(key, stateValue)
    })
    history.push({
      pathname: path,
      search: `?${searchParams.toString()}`,
    })
  }

  const saveEditing = id => {
    writeStateToUrl({
      ...pageState,
      editIds: [...pageState.editIds, id],
    })
  }

  const removeEditing = id => {
    const editIdsWithoutId = pageState.editIds.filter(editIds => editIds !== id)
    writeStateToUrl({
      ...pageState,
      editIds: [...editIdsWithoutId],
    })
  }

  // COULD BE INBOX, PRIVATE ARE OR PUBLIC WALL
  const currentWall = location.pathname.split('/')[2]

  const {
    data: response,
    error,
    loading,
  } = useQuery(ASK_ME_ANYTHINGS, {
    fetchPolicy: 'no-cache',
    variables: {
      search: {
        personName: searchParams.personName || undefined,
        teamId: searchParams.teamId || undefined,
        endDate: searchParams.endDate || undefined,
        includeNoTeam: searchParams.includeNoTeam || undefined,
        section: currentWall,
      },
      offset: getOffset(pageNumber),
      limit: PAGE_SIZE,
    },
    onCompleted: () => (initialized.current = true),
  })

  const onActionClick = (action, ama) => {
    switch (action) {
      case 'edit-answer':
        saveEditing(ama.id)
        break
      default:
        return
    }
  }

  const onEditAnswerSave = amaId => removeEditing(amaId)
  const onEditAnswerCancel = amaId => removeEditing(amaId)

  const onPageChange = newPageNumber => {
    setPageNumber(newPageNumber)
    window.scroll({
      top: 0,
    })
  }

  const [onSearchFormSubmit] = useDebouncedCallback(
    ({ personName, teamId, endDate }) => {
      const newParams = {
        personName: personName || undefined,
        teamId: teamId || undefined,
        endDate: endDate || undefined,
        includeNoTeam: teamId && teamId === 'no-team' ? true : undefined,
      }
      setSearchParams(newParams)
      setPageNumber(1)
    },
    400,
  )

  useEffect(() => {
    history.listen(() => {
      const urlState = readStateFromUrl()
      if (pageStateMatches(urlState, pageState)) {
        return
      }
      setPageState(urlState)
    })
  }, [])

  useEffect(() => {
    if (!response) return
    if (response.askMeAnythings.total > 0) {
      hasAMAsInAccount.current = true
    }
  }, [response])

  if (loading && !initialized.current) {
    return <Loader />
  }
  if (error) {
    return error
  }

  const { amas } = response.askMeAnythings

  const renderDescription = () => {
    const descriptionStyle = {
      color: '#6b6b8f',
      marginTop: '2rem',
      marginBottom: '2rem',
    }
    if (amas.length > 0) {
      switch (currentWall) {
        case 'publicwall':
          return (
            <p style={descriptionStyle}>
              Oh glorious transparency
              <Emoji text=":innocent:" />. Below are the AMAs that people in
              your account have publicly answered. Enjoy!
            </p>
          )
        case 'privatearea':
          return (
            <p style={descriptionStyle}>
              Here lives the AMAs you received and answered privately, and the
              AMAs you sent that received private answers
              <Emoji text=":shushing_face:" />
            </p>
          )
        default:
          return null
      }
    }
  }

  return (
    <>
      <div className="ask-me-anything-landing-page">
        <FeatureHeader
          title="Ask me anything"
          buttonLabel="Ask an anonymous question"
          onButtonClick={showAskQuestionModal}
        />
        {(amas.length > 0 || hasAMAsInAccount.current) && (
          <Search.Form onSubmit={onSearchFormSubmit} loading={loading} />
        )}
        {menu}
        {renderDescription()}
        {amas.length === 0 && hasAMAsInAccount.current ? (
          <Search.NoResults />
        ) : amas.length === 0 ? (
          <EmptyState showAskQuestionModal={showAskQuestionModal} />
        ) : (
          <>
            {amas.length > 0 && (
              <div>
                <AMACardList
                  heading={
                    currentWall === 'inbox' && (
                      <>
                        Somebody has sent you something important! Be a sport
                        and respond without too much delay
                        <Emoji text=":folded_hands:" />
                      </>
                    )
                  }
                  amas={amas}
                  editingIds={pageState.editIds}
                  onActionClick={onActionClick}
                  onEditAnswerSave={onEditAnswerSave}
                  onEditAnswerCancel={onEditAnswerCancel}
                />
              </div>
            )}
            {response.askMeAnythings.total > 10 && (
              <Pagination
                current={pageNumber}
                onChange={onPageChange}
                total={response.askMeAnythings.total}
                pageSize={PAGE_SIZE}
                disabled={loading}
                itemsName="questions"
              />
            )}
          </>
        )}
      </div>
      <AskQuestionPage
        visible={modalVisible}
        onCancel={hideAskQuestionModal}
        onOk={hideAskQuestionModal}
      />
    </>
  )
}

export default LandingPage
