import './style.less'

import { useMutation } from '@apollo/react-hooks'
import { Card, Icon, Select, notification } from 'antd'
import React, { useContext } from 'react'
import Emoji from 'react-emoji-render'

import { UserContext } from '../../UserContext'
import { UPDATE_NOTIFICATION_SETTINGS } from './queries'
import Rule from './Rule'
import SectionHeader from './SectionHeader'

const { Option } = Select

export default () => {
  const user = useContext(UserContext)

  const [updateSettings] = useMutation(UPDATE_NOTIFICATION_SETTINGS)

  const handleChange = async ({ key, value }) => {
    try {
      await updateSettings({
        variables: {
          settings: [
            {
              key,
              value,
            },
          ],
        },
      })
      notification.success({
        message: 'Changes saved',
      })
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong',
        description: `Please try again. If you're still having problems, please contact us`,
      })
    }
  }

  const WeeklyDigestTooltip = () => {
    return (
      <span>
        As it happens means… as it happens :-)
        <br />
        The weekly digest email is sent on Friday afternoons.
      </span>
    )
  }

  const MandatoryTooltip = () => {
    return (
      <span>
        As it happens means… as it happens :-)
        <br />
        To ensure you don’t miss out on giving or receiving feedback, these
        notifications can’t be changed.
      </span>
    )
  }

  return (
    <div className="notifications-page">
      <p className="notifications-page__description">
        We've carefully tailored our notifications so that you'll receive emails
        about the things that need your attention. If you have our awesome Slack
        integration, notifications appear there too. Here’s how it all breaks
        down:
      </p>

      <div>
        <Card title="Good vibes" className="notifications-page__settings-card">
          <SectionHeader tooltip={WeeklyDigestTooltip} />
          <Rule
            title="Good vibes received"
            emoji=":sparkles:"
            explanation="Get notified when you receive Good vibes"
            frequency="As it happens"
          />
          <Rule
            title="New comments"
            emoji=":woman-raising-hand:"
            explanation="Get notified when someone comments on the Good vibes you either sent or received"
            frequency="As it happens"
          />
          <Rule
            title="Happy news"
            emoji=":rolled_up_newspaper:"
            explanation={
              <span>
                Get notified when a colleague within
                <Select
                  defaultValue={
                    user.notificationSettings.goodVibes.receivedForColleagues
                  }
                  dropdownMatchSelectWidth={false}
                  onChange={value =>
                    handleChange({
                      key: 'goodVibes.receivedForColleagues',
                      value,
                    })
                  }
                  suffixIcon={<Icon type="down" style={{ fontSize: '10px' }} />}
                  dropdownClassName="notifications-page-rule__explanation-dropdown"
                >
                  <Option value="team-inc-manager">your team</Option>
                  <Option value="org">your company</Option>
                </Select>
                received Good vibes
              </span>
            }
            frequency={
              <Select
                defaultValue={
                  user.notificationSettings.goodVibes
                    .receivedForColleaguesFrequency
                }
                dropdownMatchSelectWidth={false}
                onChange={value =>
                  handleChange({
                    key: 'goodVibes.receivedForColleaguesFrequency',
                    value,
                  })
                }
                suffixIcon={<Icon type="caret-down" />}
                dropdownClassName="notifications-page-rule__frequency-dropdown"
              >
                <Option value="immediately">As it happens</Option>
                <Option value="weekly-digest">Weekly digest</Option>
                <Option value="never">Never</Option>
              </Select>
            }
          />
        </Card>
        <Card
          title="Ask me anything"
          className="notifications-page__settings-card"
        >
          <SectionHeader tooltip={WeeklyDigestTooltip} />
          <Rule
            title="Question received"
            emoji=":question:"
            explanation="Get notified when you receive an anonymous question"
            frequency="As it happens"
          />
          <Rule
            title="Question answered"
            emoji=":envelope_with_arrow:"
            explanation="Get notified when an anonymous question you asked is answered"
            frequency="As it happens"
          />
          <Rule
            title="Roar"
            emoji=":mega:"
            explanation="Get notified when a colleague within your company answers a question publicly"
            frequency={
              <Select
                defaultValue={
                  user.notificationSettings.askMeAnything
                    .submittedAnswerFrequency
                }
                dropdownMatchSelectWidth={false}
                onChange={value =>
                  handleChange({
                    key: 'askMeAnything.submittedAnswerFrequency',
                    value,
                  })
                }
                suffixIcon={<Icon type="caret-down" />}
                dropdownClassName="notifications-page-rule__frequency-dropdown"
              >
                <Option value="immediately">As it happens</Option>
                <Option value="weekly-digest">Weekly digest</Option>
                <Option value="never">Never</Option>
              </Select>
            }
          />
        </Card>
        <Card
          title="Private feedback"
          className="notifications-page__settings-card"
        >
          <SectionHeader tooltip={MandatoryTooltip} />
          <Rule
            title="Feedback requested"
            emoji=":gift:"
            explanation="Get notified when you are asked to give Private feedback"
            frequency="As it happens"
          />
          <Rule
            title="Nudge time"
            emoji=":pushpin:"
            explanation="Get notified when you’ve asked for Private feedback and you need to nudge your colleagues to respond"
            frequency="As it happens"
          />
          <Rule
            title="Feedback released"
            emoji=":eyes:"
            explanation="Get notified when the Private feedback event has ended and feedback is ready to view"
            frequency="As it happens"
          />
        </Card>
        <Card
          title="360 feedback"
          className="notifications-page__settings-card"
        >
          <SectionHeader tooltip={MandatoryTooltip} />
          <Rule
            title="Ask for feedback"
            emoji=":grinning:"
            explanation="Get notified when you’ve been enrolled in a 360 feedback event and you need to ask for feedback"
            frequency="As it happens"
          />
          <Rule
            title="Feedback requested"
            emoji=":gift:"
            explanation="Get notified when you are asked to give 360 feedback"
            frequency="As it happens"
          />
          <Rule
            title="Feedback released"
            emoji=":eyes:"
            explanation="Get notified when the 360 feedback event has ended and feedback is ready to view"
            frequency="As it happens"
          />
        </Card>
        <Card
          title="Pulse surveys"
          className="notifications-page__settings-card"
        >
          <SectionHeader tooltip={MandatoryTooltip} />
          <Rule
            title="Survey received"
            emoji=":mailbox_with_mail:"
            explanation="Get notified when you are asked to answer a Pulse survey"
            frequency="As it happens"
          />
          <Rule
            title="Survey released"
            emoji=":eyes:"
            explanation="If you set up the Pulse survey, get notified when it has ended and the responses are ready to view"
            frequency="As it happens"
          />
        </Card>

        <p className="notifications-page__description">
          You may also receive reminders on Tuesdays and Fridays if you have
          outstanding tasks. Want to get rid of these reminders? Then help out
          your colleagues by completing your tasks!
          <Emoji
            text=":grimacing:"
            style={{ fontSize: '20px', lineHeight: '16px' }}
          />
        </p>
      </div>
    </div>
  )
}
