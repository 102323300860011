import gql from 'graphql-tag'

export const REMOVE_USER = gql`
  mutation removeUser($userId: String!) {
    removeUser(userId: $userId)
  }
`

export const DELETE_MULTIPLE_USERS = gql`
  mutation deleteMultipleUsers($ids: [ID!]) {
    removeUser(userId: $userId)
  }
`

export const USERS = gql`
  query {
    users {
      id
    }
  }
`
