import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import MobileInterruptionGuard from '../MobileSupport/MobileInterruptionGuard'
import { UserContext } from '../UserContext'

const AppRoute = ({ path, component: Component, render }) => {
  const user = useContext(UserContext)
  return (
    <Route
      path={path}
      render={props => {
        const { location } = props
        if (!user.isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: '/login',
                search: `?redirect=${location.pathname}`,
                state: { from: location },
              }}
            />
          )
        }
        if (!user.onboarded) {
          return (
            <Redirect
              to={{
                pathname: '/onboarding',
                search: `?redirect=${location.pathname}`,
                state: { from: location },
              }}
            />
          )
        }
        return (
          <MobileInterruptionGuard>
            {render ? render(props) : <Component {...props} />}
          </MobileInterruptionGuard>
        )
      }}
    />
  )
}

export default AppRoute
