import { Button, notification } from 'antd'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'

import ConfirmationModal from '../../../_components/ConfirmationModal'
import { track } from '../../../_helpers/analytics'
import AMA from '../../../AskMeAnything'
import useDismissTodo from '../../../hooks/graphql/Todos/useDismissTodo'
import { UserContext } from '../../../UserContext'
import DismissButton from './DismissButton'

export default ({ id }) => {
  const history = useHistory()
  const assignedTo = useContext(UserContext)
  const [dismissTodo] = useDismissTodo()
  const [askModalVisible, setAskModalVisible] = useState(false)
  const [dismissModalVisible, setDismissModalVisible] = useState(false)

  const showDismissModal = () => setDismissModalVisible(true)
  const hideDismissModal = () => setDismissModalVisible(false)

  const showAskModal = () => setAskModalVisible(true)
  const hideAskModal = () => setAskModalVisible(false)

  const handleDismissModalOk = async () => {
    try {
      await dismissTodo({
        variables: {
          todoId: id,
        },
        refetchQueries: ['todos'],
      })
      hideDismissModal()
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description:
          'There was a problem dismissing the todo. Please try again.',
      })
    }
  }

  const onAskQuestionClicked = () => {
    showAskModal()
    track('todo.askAnAnonymousQuestion.opened', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  const onQuestionAsked = ({ recipientId }) => {
    hideAskModal()
    track('todo.askAnAnonymousQuestion.asked', {
      user: assignedTo.email,
      org: assignedTo.org.name,
      recipientId,
    })
    history.push('/askMeAnything')
  }

  const onAskQuestionCancelled = () => {
    hideAskModal()
    track('todo.askAnAnonymousQuestion.closed', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  return (
    <>
      <div className="dashboard-todo">
        <AMA.AnonymousAvatar />
        <div className="dashboard-todo-contents">
          <div style={{ fontFamily: 'museo, serif' }}>
            Ask an anonymous question
          </div>
          <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
            Don't sit there wondering! Safely get an answer to whatever's on
            your mind.
          </div>
        </div>
        <Button type="primary" size="small" onClick={onAskQuestionClicked}>
          Ask a question
        </Button>
        <DismissButton onClick={showDismissModal} />
      </div>
      <AMA.AskQuestionPage
        visible={askModalVisible}
        onOk={onQuestionAsked}
        onCancel={onAskQuestionCancelled}
      />
      <ConfirmationModal
        visible={dismissModalVisible}
        onOk={handleDismissModalOk}
        onCancel={hideDismissModal}
        title="Are you sure?"
        okText="Yes, I'm sure"
      >
        Now's your chance to ask someone a question with complete anonymity.
      </ConfirmationModal>
    </>
  )
}
