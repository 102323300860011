import './style.less'

import { Button, Collapse, Icon } from 'antd'
import React from 'react'

import { Avatar, AvatarName } from '../../../../_components/Avatar'
import ColleaguesReceivedNoFeedback from '../../../components/ColleaguesReceivedNoFeedback'
import { DEFAULT_SPACING } from '../CONSTANTS'
import DownloadPDFButton from '../DownloadPDFButton'
import FeedbackAnswers from '../FeedbackAnswers/FeedbackAnswers'

const ViewFeedbackSection = ({ title, reviews }) => {
  return (
    <div style={{ marginTop: DEFAULT_SPACING * 3 }}>
      <h3 style={{ marginTop: 0, marginBottom: 16 }}>{title}</h3>
      <Collapse
        className="collapse-direct-report"
        bordered={false}
        expandIconPosition="right"
        style={{ background: 'none' }}
        expandIcon={panelProps => {
          return (
            <Button
              type="primary"
              style={{
                width: 32,
                height: 32,
                padding: 0,
                borderRadius: 10,
              }}
            >
              <Icon
                type={panelProps.isActive ? 'up' : 'down'}
                style={{ color: 'white' }}
              />
            </Button>
          )
        }}
      >
        {reviews.map(review => (
          <Collapse.Panel
            header={
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Avatar user={review.user} />
                <div style={{ marginLeft: 12 }}>
                  <AvatarName
                    style={{ fontWeight: 500, fontFamily: 'museo' }}
                    user={review.user}
                  />
                  <div style={{ fontWeight: 300 }}>{review.user.jobTitle}</div>
                </div>
              </div>
            }
            key={review.id}
          >
            <div
              style={{
                backgroundColor: 'white',
                borderRadius: '0 0 16px 16px',
                padding: `${DEFAULT_SPACING}px ${DEFAULT_SPACING * 4}px ${
                  DEFAULT_SPACING * 2
                }px`,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                {review.feedbackGivers.length > 0 && (
                  <>
                    <div
                      className="small-letter"
                      style={{
                        marginBottom: DEFAULT_SPACING,
                      }}
                    >
                      Combined feedback from:
                    </div>
                    <div>
                      {review.feedbackGivers.map(user => (
                        <Avatar
                          key={user.id}
                          user={user}
                          withRightMargin
                          withBottomMargin
                        />
                      ))}
                    </div>
                  </>
                )}
                {review.feedbackGivers.length === 0 && (
                  <ColleaguesReceivedNoFeedback numColleagues={1} />
                )}
              </div>
              {review.feedbackGivers.length > 0 && (
                <DownloadPDFButton review={review} style={{ marginTop: 38 }} />
              )}
            </div>

            {review.feedbackGivers.length > 0 && (
              <FeedbackAnswers key={review.id} review={review} />
            )}
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  )
}

export default ViewFeedbackSection
