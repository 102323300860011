import React from 'react'

import Events from './Events'
import GeneralStats from './GeneralStats'

export default ({ timeInterval, userId }) => {
  return (
    <div className="insights__page">
      <GeneralStats
        timeInterval={timeInterval}
        userId={userId}
        style={{ marginBottom: '24px' }}
      />
      <Events timeInterval={timeInterval} userId={userId} />
    </div>
  )
}
