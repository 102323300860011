import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React from 'react'

const HelpIcon = ({ children, tooltip }) => {
  return (
    <>
      {children}
      {tooltip && (
        <Tooltip title={tooltip}>
          <FontAwesomeIcon
            icon={['fal', 'question-circle']}
            style={{
              color: '#77779f',
              cursor: 'pointer',
              fontSize: 14,
              marginLeft: 6,
            }}
          />
        </Tooltip>
      )}
    </>
  )
}

export default HelpIcon
