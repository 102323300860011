import React from 'react'
import { Switch, useLocation } from 'react-router-dom'
import { RouteWithSubRoutes } from '../AppRoutes'
import AskMeAnythingMenu from './components/AskMeAnythingMenu'
import { AMARoutes } from './routes'


export default () => {
  const location = useLocation();

  return (
    <div className="cycles">
      <Switch>
        {AMARoutes.routes.map((route, i) => {

          return (
            <RouteWithSubRoutes
              key={i}
              menu={
                route.menuKey ? (
                  <AskMeAnythingMenu
                    routes={AMARoutes}
                    pathname={location.pathname}
                  />
                ) : (
                  ''
                )
              }
              {...route}
            />
          )
        })}
      </Switch>
    </div>
  )
}
