import { useApolloClient, useMutation } from '@apollo/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Collapse, Form, Input, Tooltip } from 'antd'
import React, { useContext, useImperativeHandle, useState } from 'react'

import QuestionCircle from '../../../../_assets/img/question-circle.svg'
import ExternalLink from '../../../../_components/ExternalLink'
import isSubdomain from '../../../../_helpers/isSubdomain'
import trimmedMinLengthValidator from '../../../../_helpers/trimmedMinLengthValidator'
import { UserContext } from '../../../../UserContext'
import { GET_PERSONIO_INTEGRATION } from '../queries'
import {
  ACTIVATE_PERSONIO_INTEGRATION,
  TEST_PERSONIO_CREDENTIALS,
} from './queries'

const ActivateForm = React.forwardRef(
  ({ form, onSubmitStart, onSubmitEnd, onError, onValid }, ref) => {
    const currentUser = useContext(UserContext)
    const { getFieldDecorator, getFieldValue, setFields } = form
    const client = useApolloClient()
    const [hasEnteredSubdomain, setHasEnteredSubdomain] = useState(false)
    const [hasLoggedIn, setHasLoggedIn] = useState(false)
    const [hasCredentials, setHasDownloadedCredentials] = useState(false)
    const [hasValidCredentials, setHasValidCredentials] = useState(false)
    const [mustAddAttributes, setMustAddAttributes] = useState(false)
    const [mustConfigureIntegration, setMustConfigureIntegration] = useState(
      false,
    )

    const [exceptionMessage, setExceptionMessage] = useState(null)
    const [connecting, setConnecting] = useState(false)
    const [activatePersonioIntegration] = useMutation(
      ACTIVATE_PERSONIO_INTEGRATION,
      {
        refetchQueries: [{ query: GET_PERSONIO_INTEGRATION }],
        onCompleted: () => currentUser.refetchUser(),
      },
    )

    const loginURL = () => {
      const subdomain = getFieldValue('subdomain')
      if (!subdomain) {
        return null
      }
      return `https://${subdomain}.personio.de`
    }

    const apiCredentialsURL = () => {
      const subdomain = getFieldValue('subdomain')
      if (!subdomain) {
        return null
      }
      return `https://${subdomain}.personio.de/configuration/api/credentials/management`
    }

    const setInvalidCredentials = invalid => {
      setFields({
        clientId: {
          value: getFieldValue('clientId'),
          errors: invalid
            ? [new Error('Invalid credentials - please try again')]
            : undefined,
        },
        clientSecret: {
          value: getFieldValue('clientSecret'),
          errors: invalid
            ? [new Error('Invalid credentials - please try again')]
            : undefined,
        },
      })
    }

    const handleSubmit = evt => {
      evt.preventDefault()
      if (onSubmitStart) {
        onSubmitStart()
      }
      submit(err => {
        if (err && onError) {
          onError(err)
        }
        if (onSubmitEnd) {
          onSubmitEnd()
        }
      })
    }

    const submit = cb => {
      setExceptionMessage(null)
      form.setFieldsValue({
        subdomain: form.getFieldValue('subdomain').trim(),
      })

      form.validateFields(async (err, values) => {
        try {
          if (err) {
            return cb(err)
          }

          setConnecting(true)
          setHasEnteredSubdomain(true)
          if (!hasLoggedIn || !hasCredentials) {
            setConnecting(false)
            return
          }

          if (!hasValidCredentials || mustAddAttributes) {
            const testCredentialsResponse = await client.query({
              query: TEST_PERSONIO_CREDENTIALS,
              variables: {
                clientId: values.clientId,
                clientSecret: values.clientSecret,
              },
            })
            const credentialsCheck =
              testCredentialsResponse.data.testPersonioCredentials
            if (!credentialsCheck.success) {
              switch (credentialsCheck.reason) {
                case 'invalid_credentials':
                  setInvalidCredentials(true)
                  cb(new Error('Invalid credentials'))
                  break
                case 'missing_attributes':
                  setHasValidCredentials(true)
                  setMustAddAttributes(true)
                  cb(new Error('Missing attributes'))
                  break
                default:
              }
              setConnecting(false)
              cb()
              return
            }
            setInvalidCredentials(false)
            setMustAddAttributes(false)
            setHasValidCredentials(true)
            setConnecting(false)
            setMustConfigureIntegration(true)
            cb()
            if (onValid) {
              onValid()
            }
            return
          }

          setMustConfigureIntegration(true)

          activatePersonioIntegration({
            variables: {
              subdomain: values.subdomain,
              clientId: values.clientId,
              clientSecret: values.clientSecret,
              inviteNewUsers: values.inviteNewUsers,
            },
          }).then(activateResponse => {
            if (!activateResponse.data.activatePersonioIntegration.success) {
              return cb(
                new Error(
                  activateResponse.data.activatePersonioIntegration.message,
                ),
              )
            }
            setConnecting(false)
            cb()
          })
        } catch (e) {
          setConnecting(false)
          cb(e)
        }
      })
    }

    const getActivePanelKey = () => {
      if (mustConfigureIntegration) {
        return 'configure-integration'
      }
      if (mustAddAttributes) {
        return 'custom-attributes'
      }
      if (hasCredentials) {
        return 'enter-credentials'
      }
      if (hasLoggedIn) {
        return 'get-credentials'
      }
      if (hasEnteredSubdomain) {
        return 'login'
      }
      return 'subdomain'
    }

    useImperativeHandle(ref, () => {
      return {
        form,
        submit,
      }
    })

    const activeKey = getActivePanelKey()

    return (
      <Form
        className="activate-personio-form"
        colon={false}
        autoComplete="off"
        disabled={connecting}
        onSubmit={handleSubmit}
        hideRequiredMark
        layout="horizontal"
        ref={ref}
        name="activatePersonioForm"
      >
        <Collapse
          accordion={false}
          destroyInactivePanel={false}
          activeKey={activeKey}
        >
          <Collapse.Panel
            key="subdomain"
            showArrow={false}
            header={
              <PanelHeader
                title="1. Enter your Personio subdomain"
                isDone={hasEnteredSubdomain}
                isCurrentStep={activeKey === 'subdomain'}
              />
            }
          >
            <Form.Item
              help={
                <span style={{ display: 'block', marginTop: 8 }}>
                  E.g. https://
                  <span style={{ fontWeight: 'bold' }}>subdomain</span>
                  .personio.de
                </span>
              }
            >
              {getFieldDecorator('subdomain', {
                initialValue: '',
                rules: [
                  { required: true, message: 'Please enter the subdomain' },
                  {
                    validator: (rule, value, callback) => {
                      if (!value) {
                        return callback()
                      }
                      if (!isSubdomain(value.trim())) {
                        return callback('Invalid subdomain - please try again')
                      }
                      return callback()
                    },
                  },
                  {
                    validator: trimmedMinLengthValidator({
                      min: 1,
                      message: 'Please enter the subdomain',
                    }),
                  },
                ],
              })(
                <Input
                  placeholder="Enter the subdomain"
                  addonAfter=".personio.de"
                  disabled={connecting}
                />,
              )}
              {getFieldValue('subdomain') && (
                <Button
                  size="small"
                  type="ghost"
                  onClick={() => setHasEnteredSubdomain(true)}
                  disabled={form.getFieldError('subdomain')}
                >
                  Done
                </Button>
              )}
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="login"
            showArrow={false}
            header={
              <PanelHeader
                title="2. Log in to Personio — then return here"
                isDone={hasLoggedIn}
                isCurrentStep={activeKey === 'login'}
              />
            }
          >
            <Form.Item>
              <div>
                <Button
                  size="small"
                  type="ghost"
                  href={loginURL()}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setHasLoggedIn(true)}
                  disabled={connecting}
                >
                  Log in
                </Button>
                <span style={{ margin: '0 8px' }}>or</span>
                <Button
                  size="small"
                  type="ghost"
                  onClick={() => setHasLoggedIn(true)}
                >
                  I'm already logged in
                </Button>
              </div>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            key="get-credentials"
            showArrow={false}
            header={
              <PanelHeader
                title="3. Get your Personio credentials"
                help="Credentials are data that enable us to connect your Personio account to Howamigoing"
                isDone={hasCredentials}
                isCurrentStep={activeKey === 'get-credentials'}
              />
            }
          >
            <p>
              We need you to create 'API credentials' on Personio to link your Personio and Howamigoing accounts. It's really easy - here are the steps:
            </p>
            <ol>
              <li>Go to <ExternalLink href={apiCredentialsURL()} style={{ fontSize: 'inherit', marginLeft: 0, textDecoration: 'underline'  }}><strong>API credentials</strong></ExternalLink></li>
              <li>Click <strong>Generate new credential</strong></li>
              <li>Enter <strong>'Howamigoing'</strong> in the <strong>Name</strong> field</li>
              <li>Select <strong>'Howamigoing'</strong> from the <strong>Integration</strong> dropdown list</li>
              <li>Click <strong>Generate new credential</strong></li>
              <li>Return here to enter your credentials</li>
            </ol>
            <Button
              size="small"
              type="ghost"
              onClick={() => setHasDownloadedCredentials(true)}
            >
              Next
            </Button>
          </Collapse.Panel>
          <Collapse.Panel
            showArrow={false}
            key="enter-credentials"
            header={
              <PanelHeader
                title="4. Enter your Personio credentials"
                isDone={hasValidCredentials && hasCredentials}
                isCurrentStep={activeKey === 'enter-credentials'}
              />
            }
          >
            <p>Copy your <strong>client ID</strong> and <strong>secret</strong> from Personio, below:</p>
            <Form.Item label="Your client ID">
              {getFieldDecorator('clientId', {
                initialValue: '',
                rules:
                  activeKey === 'enter-credentials'
                    ? [
                        {
                          required: true,
                          message:
                            'Please enter the client ID from Personio',
                        },
                      ]
                    : [],
              })(<Input placeholder="Enter the client ID from Personio" />)}
            </Form.Item>
            <Form.Item label="Your Secret" style={{ marginTop: '24px' }}>
              {getFieldDecorator('clientSecret', {
                initialValue: '',
                rules:
                  activeKey === 'enter-credentials'
                    ? [
                        {
                          required: true,
                          message:
                            'Please enter the secret from Personio',
                        },
                      ]
                    : [],
              })(<Input placeholder="Enter the secret from Personio" type="password" />)}
            </Form.Item>
            <Form.Item>
              <Button
                size="small"
                type="ghost"
                htmlType="submit"
                loading={connecting}
              >
                Next
              </Button>
            </Form.Item>
          </Collapse.Panel>
          <Collapse.Panel
            showArrow={false}
            key="configure-integration"
            header={
              <PanelHeader
                title="5. Send Howamigoing invitation email"
                isDone={false}
                isCurrentStep={activeKey === 'configure-integration'}
              />
            }
          >
            <Form.Item
              help={
                <span style={{ display: 'block', marginTop: 8 }}>
                  You need to send invites so the people you’re importing from
                  Personio can join your Howamigoing account. If you don't send
                  email invites now, you can do so later via{' '}
                  <strong>Admin &gt; People</strong>.
                </span>
              }
            >
              {form.getFieldDecorator('inviteNewUsers', {
                initialValue: true,
                valuePropName: 'checked',
              })(<Checkbox>Send invites now</Checkbox>)}
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
        {exceptionMessage && <p>{exceptionMessage}</p>}
        <div style={{ display: 'none' }}>
          <input type="submit" value="Submit" />
        </div>
      </Form>
    )
  },
)

const PanelHeader = ({ title, isDone, help, isCurrentStep }) => {
  return (
    <div className="activate-personio-form__panel-header">
      <div
        style={{
          color: isDone ? '#6b6b8f' : undefined,
          fontWeight: isCurrentStep ? '400' : undefined,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {title}
        {help && (
          <Tooltip
            title={help}
            overlayClassName="tooltip--dark tooltip--dark-big"
          >
            <img
              src={QuestionCircle}
              alt="explanation"
              style={{
                cursor: 'pointer',
                height: 14,
                marginLeft: 12,
                width: 14,
              }}
            />
          </Tooltip>
        )}
      </div>
      {isDone && (
        <div className="activate-personio-form__done-icon">
          <FontAwesomeIcon
            icon={['fas', 'check-circle']}
            style={{ color: '#47d764', fontSize: '20px' }}
          />
        </div>
      )}
    </div>
  )
}

export default Form.create({ name: 'ActivatePersonio' })(ActivateForm)
