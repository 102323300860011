import * as Components from './components'

import {
  ActionMenu,
  AnonymousAvatar,
  AnonymousQuestion,
  Answer,
  AskQuestion,
  Card,
  EmptyState,
  FeatureHeader,
  ReportAbuse,
  Timestamp,
  Visibility,
} from './components'
import { AskQuestionPage, LandingPage } from './pages'

import { default as Router } from './Router'

const Root = () => null

Root.Router = Router
Object.assign(Root, Components)
Object.assign(Root, { LandingPage, AskQuestionPage })

export {
  AnonymousQuestion,
  AnonymousAvatar,
  Answer,
  AskQuestion,
  Card,
  EmptyState,
  FeatureHeader,
  ReportAbuse,
  Visibility,
  ActionMenu,
  Timestamp,
  LandingPage,
  AskQuestionPage,
  Router,
}

export default Root
