import React, { useRef, useState } from 'react'

import ConfirmationModal from '../../../_components/ConfirmationModal'
import Form from './Form'
import Item from './Item'
import { ReportAbuse } from '../../components'
import { notification } from 'antd'

const List = ({ comments, onEdit, onDelete, onReportAbuse }) => {
  const reportingAbuseCommentId = useRef(null)
  const deletingCommentId = useRef(null)
  const [editingId, setEditingId] = useState(null)
  const [deleteCommentModalVisible, setDeleteCommentModalVisible] = useState(
    false,
  )

  const showDeleteCommentModal = itemId => {
    deletingCommentId.current = itemId
    setDeleteCommentModalVisible(true)
  }
  const hideDeleteCommentModal = () => {
    deletingCommentId.current = null
    setDeleteCommentModalVisible(false)
  }

  const [reportAbuseModalVisible, setReportAbuseModalVisible] = useState(false)
  const showReportAbuseModal = itemId => {
    reportingAbuseCommentId.current = itemId
    setReportAbuseModalVisible(true)
  }
  const hideReportAbuseModal = () => {
    reportingAbuseCommentId.current = null
    setReportAbuseModalVisible(false)
  }

  const handleReportAbuse = async (itemId, abuse) => {
    if (onReportAbuse) {
      await onReportAbuse({
        id: itemId,
        abuse,
      })
    }
    hideReportAbuseModal()
  }

  const orderedComments = comments.sort((a, b) =>
    a.submittedAt > b.submittedAt ? 1 : -1,
  )

  const handleDeleteComment = async itemId => {
    if (onDelete) {
      await onDelete(itemId)
    }
    hideDeleteCommentModal()
  }

  const handleOnItemActionClick = (name, item) => {
    switch (name) {
      case 'report-abuse':
        if (item.reportedAbuse) {
          notification.info({
            message: `You've already reported this`,
            description: `We'll get back to you shortly`,
          })
        } else {
          showReportAbuseModal(item.id)
        }
        break
      case 'edit':
        setEditingId(item.id)
        break
      case 'delete':
        showDeleteCommentModal(item.id)
        break
      default:
    }
  }

  const handleOnEditFormCancel = () => setEditingId(null)

  const handleOnEditFormSubmit = async (itemId, value) => {
    try {
      if (onEdit) {
        await onEdit(itemId, value)
      }
      setEditingId(null)
    } catch (e) {
      e.error()
    }
  }

  return (
    <>
      {orderedComments.map(x => (
        <div className="comment__list-item" key={x.id}>
          {x.id === editingId ? (
            <Form
              author={x.author}
              initialValue={x.value}
              onCancel={handleOnEditFormCancel}
              onSubmit={value => handleOnEditFormSubmit(x.id, value)}
              submitLabel="Save"
              required={true}
            />
          ) : (
            <Item
              author={x.author}
              submittedAt={x.submittedAt}
              editedAt={x.editedAt}
              value={x.value}
              onActionClick={name => handleOnItemActionClick(name, x)}
            />
          )}
        </div>
      ))}
      <ConfirmationModal
        visible={deleteCommentModalVisible}
        onOk={() => handleDeleteComment(deletingCommentId.current)}
        onCancel={hideDeleteCommentModal}
        title="Delete comment"
      >
        Are you sure you want to delete this comment?
      </ConfirmationModal>
      <ReportAbuse.Modal
        visible={reportAbuseModalVisible}
        onOk={abuse =>
          handleReportAbuse(reportingAbuseCommentId.current, abuse)
        }
        onCancel={hideReportAbuseModal}
      />
    </>
  )
}

export default List
