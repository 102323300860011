import Confetti from 'react-dom-confetti'
import React from 'react'

const style = {
  container: {
    position: 'fixed',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    zIndex: 1001,
  },
}

const config = {
  angle: 360,
  spread: 360,
  startVelocity: 60,
  elementCount: 100,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '15px',
  perspective: '0px',
  colors: ['#f95c48', '#6b6b8f', '#695d93', '#f95c4b', '#c3bfd2', '#f9c748'],
}

const ConfettiOverlay = ({ visible }) => {
  return (
    <div style={style.container}>
      <Confetti active={visible} config={config} />
    </div>
  )
}

export default ConfettiOverlay
