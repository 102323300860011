import { useMutation } from '@apollo/react-hooks'
import { Button, Card, Typography, notification } from 'antd'
import React, { useContext, useRef, useState } from 'react'
import { withRouter } from 'react-router-dom'

import SetPasswordForm from '../../_components/Form/SetPassword'
import { track } from '../../_helpers/analytics'
import { UserContext } from '../../UserContext'
import { SET_PASSWORD } from '../queries'

const { Title } = Typography

const SetPassword = ({ onSubmit }) => {
  const user = useContext(UserContext)
  const form = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [setPassword] = useMutation(SET_PASSWORD)

  const handleSubmit = e => {
    e.preventDefault()

    form.current.validateFields(async (errors, values) => {
      if (errors) {
        return
      }
      setIsSubmitting(true)
      try {
        await setPassword({
          variables: {
            id: user.id,
            password: values.password.value,
          },
        })
        track(
          user.isOwner()
            ? 'admin.onboarding.password'
            : 'user.onboarding.password',
          {
            user: user.email,
            org: user.org.name,
          },
        )
        setIsSubmitting(false)
        if (onSubmit) {
          onSubmit()
        }
      } catch (err) {
        setIsSubmitting(false)
        notification.error({
          message: 'Error!',
          description:
            'There was a problem setting your password. Please try again.',
        })
      }
    })
  }

  return (
    <div className="onboarding-set-password">
      <Card
        bodyStyle={{
          padding: '40px',
          backgroundColor: '#fcfcff',
          borderRadius: '20px',
        }}
      >
        <Title
          level={3}
          style={{ marginBottom: '24px', fontWeight: 500, fontSize: '24px' }}
        >
          {user.firstName ? `Nice to meet you, ${user.firstName}! ` : null}
          {user.firstName ? <br /> : null}
          Let's set your bulletproof password
        </Title>

        <SetPasswordForm ref={form} onSubmit={handleSubmit}>
          <div style={{ marginTop: '32px' }}>
            <Button
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
              type="accent"
              size="large"
              htmlType="submit"
            >
              Set password
            </Button>
          </div>
        </SetPasswordForm>
      </Card>
    </div>
  )
}

export default withRouter(SetPassword)
