import gql from 'graphql-tag'

export const ACTIVATE_PERSONIO_INTEGRATION = gql`
  mutation activatePersonioIntegration(
    $subdomain: String!
    $clientId: String!
    $clientSecret: String!
    $inviteNewUsers: Boolean!
  ) {
    activatePersonioIntegration(
      subdomain: $subdomain
      clientId: $clientId
      clientSecret: $clientSecret
      inviteNewUsers: $inviteNewUsers
    )
  }
`

export const TEST_PERSONIO_CREDENTIALS = gql`
  query testPersonioCredentials($clientId: String!, $clientSecret: String!) {
    testPersonioCredentials(clientId: $clientId, clientSecret: $clientSecret)
  }
`
