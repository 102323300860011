import '../ResetPassword/style.less'

import { Button, Card, Col, Row } from 'antd'
import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
import Logo from '../_assets/img/logo.svg'
import useMobileOptimized from '../hooks/device/useMobileOptimized'

export default ({ location }) => {
  useEffect(() => {
    document.title = 'Howamigoing'
  }, [])

  useMobileOptimized(true)

  return (
    <Row
      type="flex"
      justify="center"
      align="top"
      style={{ minHeight: '100vh' }}
      className="screen-container"
    >
      <Col span={7} className="col-login">
        <div className="logo-container">
          <img src={Logo} alt="How am I going?" style={{ maxHeight: '40px' }} />
        </div>
        <Card className="screen-container-card" style={{ minHeight: 452 }}>
          <div
            className="font-secondary color-primary"
            style={{
              fontWeight: 500,
              fontSize: 24,
              marginBottom: 12,
            }}
          >
            Aaalright!
          </div>
          <div
            className="color-primary"
            style={{ fontWeight: 300, marginBottom: 24 }}
          >
            We've sent the password reset instructions to{' '}
            <div style={{ fontWeight: 'normal', color: '#ec5747' }}>
              {location.state.email ? location.state.email : ''}
            </div>
          </div>
          <div
            className="color-primary"
            style={{ fontWeight: 300, marginBottom: 24 }}
          >
            An email should arrive within 5 minutes. Please check your spam
            folder, too.
          </div>
          <Link to={'/login'}>
            <Button type="ghost">Take me back to Log in</Button>
          </Link>
        </Card>
      </Col>
    </Row>
  )
}
