import { Card } from 'antd'
import React, { useContext } from 'react'

import { UserContext } from '../../../UserContext'
import { CYCLE_TYPES, findEnrolledManagees } from '../../pages/helpers'
import CycleCardTitle from '../CycleCardTitle'
import { VERTICAL_MARGIN_DOUBLE, VERTICAL_MARGIN_TRIPLE } from './CONSTANTS'
import RowFeedbackPersonal from './personal'
import RowFeedbackPulse from './pulse'
import RowOtherFeedback from './RowOtherFeedback'
import RowProgress from './RowProgress'
import RowFeedbackThreeSixty from './threeSixty'

export default ({ cycle }) => {
  const currentUser = useContext(UserContext)

  const userEnrolled =
    cycle.enrolled && cycle.enrolled.find(x => x.id === currentUser.id)

  const enrolledManagees =
    cycle.enrolled && findEnrolledManagees({ cycle, user: currentUser })

  return (
    <Card title={<CycleCardTitle cycle={cycle} />} style={{ marginBottom: 32 }}>
      <RowProgress cycle={cycle} />

      {cycle.type === CYCLE_TYPES.personal && (
        <RowFeedbackPersonal cycle={cycle} />
      )}

      {cycle.type === CYCLE_TYPES.threeSixty && userEnrolled && (
        <div
          style={{
            borderBottom: enrolledManagees ? '1px solid #e8e8e8' : '',
            marginBottom: enrolledManagees ? VERTICAL_MARGIN_DOUBLE : '',
            paddingBottom: enrolledManagees ? VERTICAL_MARGIN_TRIPLE : '',
          }}
        >
          <RowFeedbackThreeSixty cycle={cycle} />
        </div>
      )}

      {cycle.type === CYCLE_TYPES.pulse && <RowFeedbackPulse cycle={cycle} />}

      {enrolledManagees && <RowOtherFeedback cycle={cycle} />}
    </Card>
  )
}
