import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'
import React from 'react'

import AvatarListItem from '../../../_components/AvatarListItem'

const Label = ({ children, text, truncateAt = '100%' }) => {
  return (
    <span
      style={{
        display: 'block',
        maxWidth: truncateAt,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      title={text}
    >
      {text}
    </span>
  )
}

export default [
  {
    title: 'Name',
    key: 'name',
    width: '42%',
    render: colleague => (
      <AvatarListItem user={colleague} truncateTextAt="220px" />
    ),
  },
  {
    title: 'Manager',
    key: 'manager',
    width: '15%',
    render: user =>
      user.manager ? (
        <Label
          truncateAt={100}
          text={user.manager.displayName || user.manager.email}
        />
      ) : null,
  },
  {
    title: 'Team',
    key: 'team',
    width: '15%',
    render: user =>
      user.team ? <Label truncateAt={100} text={user.team.name} /> : null,
  },
  {
    title: 'Type',
    key: 'type',
    width: '15%',
    render: user => {
      switch (user.type) {
        case 'owner':
          return 'Owner'
        case 'created':
          return (
            <>
              Not invited
              <Tooltip title="Please invite this person so they can login and take part in feedback events.">
                <FontAwesomeIcon
                  icon={['fal', 'question-circle']}
                  style={{
                    width: '1em',
                    marginLeft: '4px',
                    color: '#77779f',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </>
          )
        case 'invited':
          return (
            <>
              Invited
              <Tooltip
                title={`This person has been invited to your account but hasn't joined yet.`}
              >
                <FontAwesomeIcon
                  icon={['fal', 'question-circle']}
                  style={{
                    width: '1em',
                    marginLeft: '6px',
                    color: '#77779f',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </>
          )
        case 'onboarding':
          return (
            <>
              Onboarding
              <Tooltip
                title={`This person has started onboarding themselves to your account but hasn't finished yet.`}
              >
                <FontAwesomeIcon
                  icon={['fal', 'question-circle']}
                  style={{
                    width: '1em',
                    marginLeft: '6px',
                    color: '#77779f',
                    cursor: 'pointer',
                  }}
                />
              </Tooltip>
            </>
          )
        case 'admin':
          return 'Admin'
        case 'manager':
          return 'Manager'
        case 'standard':
          return 'User'
        case 'deactivated':
          return 'Deactivated'
        default:
          return 'User'
      }
    },
  },
]
