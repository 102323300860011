import { Card } from 'antd'
import React from 'react'

const Container = ({ header, children, ...props }) => {
  return (
    <Card {...props} title={header ? header : null}>
      {children}
    </Card>
  )
}

export default Container
