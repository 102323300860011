import { Form, Input } from 'antd'
import React from 'react'

import SelectTimezones from '../../../_components/SelectTimezones'

const EditCompanyForm = ({ form, orgName, timezone, handleChange }) => {
  const { getFieldDecorator } = form

  return (
    <Form colon={false}>
      <Form.Item label="Company name">
        {getFieldDecorator('orgName', {
          initialValue: orgName,
          rules: [{ required: true, message: 'This field is required!' }],
        })(<Input onKeyUp={handleChange} placeholder="Enter company name" />)}
      </Form.Item>

      <Form.Item label="Timezone">
        {getFieldDecorator('timezone', {
          initialValue: timezone,
          rules: [{ required: true, message: 'This field is required!' }],
        })(<SelectTimezones placeholder="Select timezone" />)}
      </Form.Item>
    </Form>
  )
}

export default Form.create({ name: 'EditCompanyForm' })(EditCompanyForm)
