import React, { useEffect, useState } from 'react'

import UserDisplayName from '../../../components/UserDisplayName'

export default ({ users }) => {
  const canUpdateManager = user => {
    if (!user.readonlyProperties) {
      return true
    }
    return !user.readonlyProperties.find(x => x.name === 'manager')
  }
  const [usersWithNoManager] = useState(
    users.filter(x => canUpdateManager(x)).filter(user => !user.manager),
  )
  const [usersGroupedByManager, setUsersGroupedByManager] = useState([])

  const groupUsersByManager = () => {
    const groups = []

    users
      .filter(user => user.manager)
      .forEach(user => {
        if (groups.length === 0) {
          groups.push({
            manager: user.manager,
            users: [user],
          })
        } else {
          const foundGroup = groups.find(
            group => group.manager.id === user.manager.id,
          )

          if (foundGroup) {
            foundGroup.users.push(user)
          } else {
            groups.push({
              manager: user.manager,
              users: [user],
            })
          }
        }
      })

    setUsersGroupedByManager(groups)
  }

  useEffect(() => {
    groupUsersByManager()
  }, [users])

  return (
    <div style={{ marginBottom: 24 }}>
      {usersWithNoManager.length > 0 && (
        <div style={{ marginBottom: 8 }}>
          {usersWithNoManager.map((user, i) => (
            <span key={i}>
              <span style={{ fontWeight: 500 }}>
                <UserDisplayName key={i} user={user} />
              </span>
              {i === usersWithNoManager.length - 1 ? '' : ', '}
            </span>
          ))}{' '}
          {usersWithNoManager.length === 1 ? 'does' : 'do'} not currently have a
          manager.
        </div>
      )}
      {usersGroupedByManager.map((group, i) => (
        <div
          style={{ marginBottom: i === group.users.length - 1 ? 0 : 8 }}
          key={i}
        >
          <>
            {group.users.map((user, iu) => (
              <span key={iu}>
                <span style={{ fontWeight: 500 }}>
                  <UserDisplayName key={iu} user={user} />
                </span>
                {iu === group.users.length - 1 ? '' : ', '}
              </span>
            ))}
          </>
          {group.users.length === 1 ? ' is' : ' are'} currently managed by{' '}
          <UserDisplayName user={group.manager} />.
        </div>
      ))}
    </div>
  )
}
