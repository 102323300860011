import { Card, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import Text from 'react-texty'

import { Avatar, AvatarPlus } from '../../../../_components/Avatar'
import extractDisplayName from '../../../../_helpers/extractDisplayName'
import Section from './Section'

const AvatarStyle = {
  borderWidth: '1px',
  fontSize: '12px',
  lineHeight: '24px',
}

const AvatarPlusStyle = {
  ...AvatarStyle,
  background: '#f0f0f5',
  border: 'none',
  boxShadow: 'none',
  color: '#77779f',
  marginLeft: '4px',
}

export default ({ loadedUser = undefined }) => {
  const [user, setUser] = useState(loadedUser || undefined)
  const [loading, setLoading] = useState(!!loadedUser === false)

  useEffect(() => {
    if (loadedUser) {
      setUser(loadedUser)
      setLoading(false)
    }
  }, [loadedUser])

  return (
    <div className="up-details__card-container">
      <Card bodyStyle={{ paddingTop: '16px' }}>
        {loading ? (
          <div className="up-details__loader">
            <Spin />
          </div>
        ) : (
          <div className="up-details__card-content">
            <div className="up-details__info">
              <Avatar
                user={user}
                size={64}
                disableTooltip
                style={{ borderRadius: '24px' }}
              />
              <div className="up-details__identification">
                <div className="up-details__name">
                  {extractDisplayName(user)}
                </div>
                <div className="up-details__job-title">{user.jobTitle}</div>
              </div>
            </div>

            <div className="up-details__company-positioning">
              <Section title="Team">
                {user.team ? (
                  <Text tooltip={user.team.name}>{user.team.name}</Text>
                ) : (
                  'Not part of a team'
                )}
              </Section>

              <Section title="Manager">
                {user.manager ? (
                  <div className="up-details__colleague">
                    <Avatar
                      user={user.manager}
                      size={24}
                      style={AvatarStyle}
                      disableTooltip
                    />
                    <div className="up-details__colleague-name">
                      <Text tooltip={extractDisplayName(user.manager)}>
                        {extractDisplayName(user.manager)}
                      </Text>
                    </div>
                  </div>
                ) : (
                  'No manager assigned'
                )}
              </Section>

              <Section title="360 viewer(s)">
                {user.viewingManagers && user.viewingManagers.length > 0 ? (
                  <div className="up-details__colleague">
                    <Avatar
                      user={user.viewingManagers[0]}
                      size={24}
                      style={AvatarStyle}
                      disableTooltip
                    />
                    <div
                      className="up-details__colleague-name"
                      style={{
                        maxWidth:
                          user.viewingManagers.length > 1
                            ? 'calc(100% - 48px)'
                            : undefined,
                      }}
                    >
                      <Text
                        tooltip={extractDisplayName(user.viewingManagers[0])}
                      >
                        {extractDisplayName(user.viewingManagers[0])}
                      </Text>
                    </div>
                    {user.viewingManagers.length > 1 ? (
                      <AvatarPlus
                        users={user.viewingManagers.slice(1)}
                        size={24}
                        content={user.viewingManagers.slice(1).length}
                        style={AvatarPlusStyle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <Text tooltip={'No viewing manager(s)'}>
                    No viewing manager(s)
                  </Text>
                )}
              </Section>

              <Section title="Direct report(s)">
                {user.directReports && user.directReports.length > 0 ? (
                  <div className="up-details__colleague">
                    <Avatar
                      user={user.directReports[0]}
                      size={24}
                      style={AvatarStyle}
                      disableTooltip
                    />
                    <div
                      className="up-details__colleague-name"
                      style={{
                        maxWidth:
                          user.directReports.length > 1
                            ? 'calc(100% - 48px)'
                            : undefined,
                      }}
                    >
                      <Text tooltip={extractDisplayName(user.directReports[0])}>
                        {extractDisplayName(user.directReports[0])}
                      </Text>
                    </div>
                    {user.directReports.length > 1 ? (
                      <AvatarPlus
                        users={user.directReports.slice(1)}
                        size={24}
                        content={user.directReports.slice(1).length}
                        style={AvatarPlusStyle}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <Text tooltip={'No direct report(s)'}>
                    No direct report(s)
                  </Text>
                )}
              </Section>
            </div>
          </div>
        )}
      </Card>
    </div>
  )
}
