import React from 'react'
import Text from 'react-texty'

import AvatarListItem from '../../../_components/AvatarListItem'

export default [
  {
    title: 'Name',
    key: 'name',
    width: '40%',
    render: colleague => <AvatarListItem disableTooltip user={colleague} />,
  },
  {
    title: 'Manager',
    key: 'manager',
    width: '20%',
    render: user =>
      user.manager ? (
        <Text>{user.manager.displayName || user.manager.email}</Text>
      ) : null,
  },
  {
    title: 'Team',
    key: 'team',
    width: '40%',
    render: user => (user.team ? <Text>{user.team.name}</Text> : null),
  },
]
