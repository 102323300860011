import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button } from 'antd'
import React, { useContext, useState } from 'react'

import AddPeopleModal from '../../../_components/AddPeopleModal'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'

const PlusAvatar = () => {
  const icon = (
    <FontAwesomeIcon
      icon={['fal', 'plus']}
      style={{ fontSize: 16, position: 'relative', top: -4 }}
    />
  )
  return (
    <Avatar
      icon={icon}
      size={48}
      style={{
        background: 'linear-gradient(180deg, #77779f, #5f5f7f)',
        borderWidth: '3px',
        lineHeight: '44px',
      }}
    />
  )
}

export default () => {
  const assignedTo = useContext(UserContext)
  const [modalVisible, setModalVisible] = useState(false)

  const handleClick = () => {
    setModalVisible(true)
    track('todo.invitePeople.opened', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  const onUsersAdded = emails => {
    setModalVisible(false)
    track('todo.invitePeople.added', {
      user: assignedTo.email,
      org: assignedTo.org.name,
      numEmails: emails.length,
    })
  }

  const onModalCancel = () => {
    setModalVisible(false)
    track('todo.invitePeople.closed', {
      user: assignedTo.email,
      org: assignedTo.org.name,
    })
  }

  return (
    <div className="dashboard-todo">
      <PlusAvatar />
      <div className="dashboard-todo-contents">
        <div style={{ fontFamily: 'museo, serif' }}>Get this party started</div>
        <div style={{ color: '#6b6b8f', fontSize: '14px' }}>
          Make the most of your free trial by inviting at least 4-5 people to
          give &amp; receive feedback
        </div>
      </div>
      <Button type="primary" size="small" onClick={handleClick}>
        Invite people
      </Button>
      <AddPeopleModal
        visible={modalVisible}
        onClose={onModalCancel}
        onSuccess={onUsersAdded}
      />
    </div>
  )
}
