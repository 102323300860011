import { Button } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { UserContext } from '../../UserContext'

const PersonioBody = ({ error, status, addPeopleUrl, onCancel }) => {
  const currentUser = useContext(UserContext)

  if (error) {
    return (
      <div style={{ padding: '0 32px' }}>
        Oops! There was an issue finding your Personio people, please email us
        at <a href="mailto:support@howamigoing.com">support@howamigoing.com</a>.
      </div>
    )
  }

  return (
    <div style={{ padding: '0 32px', fontWeight: 300 }}>
      {status !== 'active' ? (
        <>
          {currentUser.isAdmin() || currentUser.isOwner()
            ? `Activate our Personio integration so you can add the people in your Personio account to Howamigoing and always keep them in sync.`
            : `Ask your Howamigoing admin to activate our Personio integration so you can add the people in your Personio account to Howamigoing and always keep them in sync.`}

          <div
            style={{
              justifyContent: 'flex-end',
              display: 'flex',
              marginTop: '32px',
            }}
          >
            <Button
              type="ghost"
              style={{ marginRight: '10px' }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            {currentUser.isAdmin() && (
              <Link to="/admin/integrations/personio">
                <Button type="accent">Activate Personio integration</Button>
              </Link>
            )}
          </div>
        </>
      ) : (
        <>
          <p>
            Add people who are already set up in your Personio account to
            Howamigoing. They’ll then be automatically imported into Howamigoing
            within 24 hours.
          </p>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <a href={addPeopleUrl} target="_blank" rel="noopener noreferrer">
              <Button type="ghost">Go to Personio</Button>
            </a>
          </div>
        </>
      )}
    </div>
  )
}

export default PersonioBody
