import { useLazyQuery } from '@apollo/react-hooks'
import { Modal, Tabs } from 'antd'
import React, { useContext, useEffect } from 'react'

import mailSvg from '../../_assets/img/mail.svg'
import {
  GET_PERSONIO_INTEGRATION,
  GET_SLACK_INTEGRATION,
} from '../../Admin/Integrations/queries'
import { UserContext } from '../../UserContext'
import AddPeopleByEmailForm from '../AddPeopleByEmailForm'
import PersonioBody from './PersonioBody'
import PersonioTab from './PersonioTab'
import SlackBody from './SlackBody'
import SlackTab from './SlackTab'

export default ({ visible, onClose, onSuccess, forceEmailInvite }) => {
  const user = useContext(UserContext)

  const integrationsUnavailable = !!user.org.hasSAML

  const basePath = `${document.location.protocol}//${document.location.host}`
  const [
    getSlack,
    { loading: slackLoading, data: slackData, error: slackError },
  ] = useLazyQuery(GET_SLACK_INTEGRATION, {
    variables: {
      redirectUrl: `${basePath}/admin/integrations/slack`,
    },
    fetchPolicy: 'network-only',
  })
  const [
    getPersonio,
    { loading: personioLoading, data: personioData, error: personioError },
  ] = useLazyQuery(GET_PERSONIO_INTEGRATION, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (visible) {
      if (!integrationsUnavailable) {
        getSlack()
      }
      getPersonio()
    }
  }, [visible])

  const defaultActiveKey = (() => {
    if (personioData && personioData.personio.status === 'active') {
      return 'personio'
    }
    if (slackData && slackData.slack.status === 'active') {
      return 'slack'
    }
    return 'email'
  })()

  return (
    <Modal
      title={
        <div style={{ fontWeight: 500, fontFamily: 'museo' }}>Add people</div>
      }
      onClose={onClose}
      onCancel={onClose}
      closable={true}
      visible={visible}
      footer={null}
      destroyOnClose={true}
      width={610}
      bodyStyle={{ padding: '24px 0' }}
      centered
    >
      {slackLoading || personioLoading ? (
        <div style={{ padding: '0 32px' }}>Just a moment...</div>
      ) : (
        <Tabs
          defaultActiveKey={defaultActiveKey}
          animated={{ inkBar: false, tabPane: false }}
          className="invite-people"
        >
          <Tabs.TabPane
            tab={
              <div className="invite-people-tab-name">
                <img src={mailSvg} alt="Add via mail" />
                <div>Add via email</div>
                <div className="invite-people-tab-separator"></div>
              </div>
            }
            key="email"
          >
            <div style={{ padding: '0 32px' }}>
              <AddPeopleByEmailForm
                minRows={7}
                maxRows={15}
                cancelLabel="Cancel"
                preSubmitText="Please separate multiple addresses with commas, tabs or spaces"
                onSubmit={onSuccess}
                onCancel={onClose}
                forceEmailInvite={forceEmailInvite}
                buttonsRightAligned
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<SlackTab error={slackError} />}
            disabled={integrationsUnavailable}
            key="slack"
          >
            <SlackBody
              error={slackError}
              status={slackData && slackData.slack.status}
              onSuccess={onClose}
              onCancel={onClose}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={<PersonioTab error={personioError} />}
            disabled={integrationsUnavailable}
            key="personio"
          >
            <PersonioBody
              error={personioError}
              status={personioData && personioData.personio.status}
              addPeopleUrl={
                personioData && personioData.personio.employeeListUrl
              }
              onCancel={onClose}
            />
          </Tabs.TabPane>
        </Tabs>
      )}
    </Modal>
  )
}
