import { useMutation, useQuery } from '@apollo/react-hooks'
import { notification } from 'antd'
import React, { useContext } from 'react'

import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import RequestColleagues from '../../components/RequestColleagues'
import { CYCLE_TYPES, formatSelection } from '../helpers'
import queries from '../queries'
import { FEEDBACK_REQUESTS, UPDATE_FEEDBACK_REQUESTS } from './queries'

const FeedbackRequestPage = ({ match, history }) => {
  const reviewId = match.params.id
  const currentUser = useContext(UserContext)

  const { data, error, loading } = useQuery(FEEDBACK_REQUESTS, {
    variables: {
      reviewId,
    },
    fetchPolicy: 'no-cache',
  })

  const [updateFeedbackRequests] = useMutation(UPDATE_FEEDBACK_REQUESTS)

  const handleSubmit = async selection => {
    try {
      await updateFeedbackRequests({
        variables: {
          cycleId: data.threeSixtyFeedbackRequests.cycle.id,
          selection: formatSelection(selection),
        },
        refetchQueries: [{ query: queries[CYCLE_TYPES.threeSixty].GET_CYCLES }]
      })
      track('team360.feedback.requestSend', {
        user: currentUser.email,
        org: currentUser.org.name,
        reviewId,
        amountRequestedUsers: selection.total,
      })
      history.push('/team360/ongoing')
    } catch (e) {
      notification.error({
        message: 'Oops!',
        description: 'It appears some error ocurred. Please try again later.',
      })
    }
  }

  if (loading) return <p>Loading…</p>
  if (error) return <p>Error!</p>

  return (
    <div className="edit-review">
      <RequestColleagues
        onSubmit={handleSubmit}
        minRequests={data.threeSixtyFeedbackRequests.requirements.minRequests}
        maxRequests={data.threeSixtyFeedbackRequests.requirements.maxRequests}
        modalForm={data.threeSixtyFeedbackRequests.form}
        requests={data.threeSixtyFeedbackRequests.requests}
        mustAskUsers={data.threeSixtyFeedbackRequests.requirements.users}
      />
    </div>
  )
}

export default FeedbackRequestPage
