import './style.less'

import { Icon as AntdIcon } from 'antd'
import React from 'react'

const InfoMessage = ({ children, icon, ...props }) => {
  const Icon = () => {
    if (icon) {
      return <div className="haig-info-message__icon">{icon}</div>
    }
    return (
      <AntdIcon
        className="haig-info-message__icon"
        type="exclamation-circle"
        theme="filled"
      />
    )
  }

  return (
    <div
      {...props}
      className="haig-info-message__container"
      style={props.style}
    >
      <Icon />
      <div className="haig-info-message__message">{children}</div>
    </div>
  )
}

export default InfoMessage
