import React, { useState } from 'react'

import AskQuestionPage from './AskQuestionPage'
import { FeatureHeader } from '../components'

const PageContainer = ({ children, menu }) => {

  const [modalVisible, setModalVisible] = useState()
  const showAskQuestionModal = () => setModalVisible(true)
  const hideAskQuestionModal = () => setModalVisible(false)

  return (
    <>
      <div className="ask-me-anything-root__container">
        <div className="ask-me-anything-root__header">
          <FeatureHeader
            title="Ask me anything"
            buttonLabel="Ask an anonymous question"
            onButtonClick={showAskQuestionModal}
          />
        </div>
        <div className="ask-me-anything-root__content">{children}</div>
      </div>
      {menu}
      <AskQuestionPage
        visible={modalVisible}
        onCancel={hideAskQuestionModal}
        onOk={hideAskQuestionModal}
      />
    </>
  )
}

export default PageContainer
