import './style.less'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Icon, Menu } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'

import { ReactComponent as HomeIcon } from '../../_assets/img/menu/home.svg'
import { ReactComponent as Personal360Icon } from '../../_assets/img/menu/personal360.svg'
import ResourcesGraphic from '../../_assets/img/menu/resources.svg'
import { ReactComponent as Team360Icon } from '../../_assets/img/menu/team360.svg'
import { track } from '../../_helpers/analytics'
import { UserContext } from '../../UserContext'
import { AvatarName } from '../Avatar'
import UserAvatar from './UserAvatar'

const { SubMenu } = Menu

const STYLE_MENU_LINK = {
  fontFamily: 'Europa',
  fontSize: 15,
}

const getFAIconStyle = ({ width = 24, fontSize = '24px' }) => {
  return {
    width,
    fontSize,
    marginRight: 10,
  }
}

const userRoutes = [
  {
    key: 'home',
    link: '/home',
    activeLink: null,
    name: 'Home',
    icon: <Icon component={HomeIcon} className="menu-icon" />,
  },
  {
    key: 'goodVibes',
    link: '/goodVibes',
    activeLink: '/goodVibes',
    name: 'Good vibes',
    icon: (
      <FontAwesomeIcon
        icon={{ iconName: 'smile', prefix: 'fal' }}
        style={getFAIconStyle({ fontSize: 18.5 })}
        className="menu-icon"
      />
    ),
  },
  {
    key: 'askMeAnything',
    link: '/askMeAnything/inbox',
    activeLink: '/askMeAnything',
    name: 'Ask me anything',
    icon: (
      <FontAwesomeIcon
        icon={{ iconName: 'map-marker-question', prefix: 'fal' }}
        style={getFAIconStyle({ fontSize: 21 })}
        className="menu-icon"
      />
    ),
  },
  {
    key: 'personal360',
    link: '/personal360/ongoing',
    activeLink: '/personal360',
    name: 'Private feedback',
    icon: <Icon component={Personal360Icon} className="menu-icon" />,
  },
  {
    key: 'team360',
    link: '/team360/ongoing',
    activeLink: '/team360',
    name: '360 feedback',
    icon: <Icon component={Team360Icon} className="menu-icon" />,
  },
  {
    key: 'pulse',
    link: '/pulse/ongoing',
    activeLink: '/pulse',
    name: 'Pulse surveys',
    icon: (
      <FontAwesomeIcon
        icon={{ iconName: 'heart-rate', prefix: 'fal' }}
        style={getFAIconStyle({ fontSize: 18.5 })}
        className="menu-icon"
      />
    ),
    showForAdmins: true,
  },
]
const colleagueActivityRoute = {
  key: 'activity',
  link: '/activity',
  activeLink: '/activity',
  name: 'Colleague activity',
  icon: (
    <FontAwesomeIcon
      icon={{ iconName: 'hiking', prefix: 'fal' }}
      style={getFAIconStyle({ fontSize: 21 })}
      className="menu-icon"
    />
  ),
}

const adminInsightsRoute = {
  key: 'admin-insights',
  link: '/admin/insights',
  activeLink: '/admin/insights',
  name: 'Usage stats',
  showForAdmins: true,
  icon: (
    <FontAwesomeIcon
      icon={{ iconName: 'user-clock', prefix: 'fal' }}
      style={getFAIconStyle({ fontSize: 21 })}
      className="menu-icon"
    />
  ),
}

const adminRoutes = [
  {
    key: 'administration',
    name: 'Admin',
    link: '/admin',
    activeLink: '/admin',
    icon: (
      <FontAwesomeIcon
        icon={{ iconName: 'cog', prefix: 'fal' }}
        style={getFAIconStyle({ fontSize: 18.5 })}
        className="menu-icon"
      />
    ),
  },
]

const SideMenu = props => {
  const user = useContext(UserContext)
  const [activeKey, setActiveKey] = useState(null)
  useEffect(() => {
    let activeRoute = null
    if (props.location.pathname === '/home') {
      activeRoute = 'home'
    } else {
      let foundRoute = null

      const allRoutes = [
        ...userRoutes,
        colleagueActivityRoute,
        adminInsightsRoute,
        ...adminRoutes,
      ]

      if (props.location.pathname.indexOf('/profile/') > -1) {
        foundRoute = colleagueActivityRoute
      } else if (props.location.pathname.indexOf('/insights') > -1) {
        foundRoute = adminInsightsRoute
      }
      else {
        allRoutes.forEach(route => {
          if (
            route.activeLink &&
            props.location.pathname.indexOf(route.activeLink) > -1
          ) {
            foundRoute = route
          } else if (route.subroutes) {
            const subroute = route.subroutes.find(
              subroute =>
                props.location.pathname.indexOf(subroute.activeLink) > -1,
            )
            if (subroute) {
              foundRoute = subroute
            }
          }
        })
      }
      activeRoute = foundRoute && foundRoute.key
    }
    setActiveKey(activeRoute)
  }, [props.location.pathname])

  const renderMenuLink = (route, isUserAdmin) => {
    if ((route.showForAdmins && isUserAdmin) || !route.showForAdmins) {
      return (
        <Menu.Item key={route.key}>
          <Link
            to={route.link}
            style={{ display: 'flex', alignItems: 'center' }}
            data-cy={route.key}
          >
            {route.icon}
            <span style={{ ...STYLE_MENU_LINK, flexGrow: 2 }}>
              {route.name}
            </span>
            {route.key === "askMeAnything" && props.amasNotification && <div className="new-tag" id="totalWalls">1</div>}
            {route.new && <div className="new-tag">New</div>}
          </Link>
        </Menu.Item>
      )
    } else {
      return <></>
    }
  }

  const buildMenuWithSubroutes = (routes, isUserAdmin) => {
    return routes.map(route =>
      route.subroutes ? (
        <SubMenu
          key={route.key}
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {route.icon}
              <span style={{ ...STYLE_MENU_LINK, flexGrow: 2 }}>
                {route.name}
              </span>
            </div>
          }
          className="menu-item"
          data-cy={route.key}
        >
          {route.subroutes.map(subroute =>
            renderMenuLink(subroute, isUserAdmin),
          )}
        </SubMenu>
      ) : (
        renderMenuLink(route, isUserAdmin)
      ),
    )
  }

  const handleMenuItemClicked = ({ key }) => {
    track(`menu.${key}.clicked`, {
      user: user.email,
      org: user.org.name,
    })
  }

  const isUserAdmin = user.isAdmin() || user.isOwner() || user.isSuperUser()

  return (
    <div
      style={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: 40,
      }}
    >
      <UserAvatar user={user} />
      <div style={{ maxWidth: '100%', paddingLeft: '16px' }}>
        <div
          className="font-secondary color-primary"
          style={{ fontWeight: 500, fontSize: 16 }}
        >
          <AvatarName user={user} />
        </div>
        <div
          className="font-primary color-primary"
          style={{ fontWeight: 300, fontSize: 14 }}
        >
          {user.jobTitle}
        </div>
      </div>

      <Menu
        className="side-menu"
        inlineIndent={16}
        selectedKeys={[activeKey]}
        onClick={handleMenuItemClicked}
        mode="inline"
        style={{
          marginTop: 28,
          fontFamily: 'Europa',
        }}
      >
        {buildMenuWithSubroutes(userRoutes, isUserAdmin)}
        <Menu.ItemGroup>
          <Menu.Divider style={{ marginBottom: 12 }} key="divider" />
          {renderMenuLink(colleagueActivityRoute, isUserAdmin)}
          {renderMenuLink(adminInsightsRoute, isUserAdmin)}
          {isUserAdmin && buildMenuWithSubroutes(adminRoutes)}
        </Menu.ItemGroup>
      </Menu>

      <div style={{ marginTop: 'auto', paddingTop: 48, position: 'relative' }}>
        <div
          style={{
            fontSize: '12px',
            fontWeight: 300,
            marginBottom: '10px',
            paddingLeft: 18,
          }}
        >
          Get better at feedback
        </div>
        <a
          href="https://help.howamigoing.com/en/"
          style={{
            display: 'inline-block',
            margin: '0 0 56px 16px',
            position: 'relative',
            zIndex: 1,
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="primary" size="small">
            Help centre
          </Button>
        </a>
        <img
          src={ResourcesGraphic}
          alt="Help centre"
          style={{ bottom: 0, left: 0, maxWidth: '100%', position: 'absolute' }}
        />
      </div>
    </div>
  )
}

export default withRouter(SideMenu)
