import gql from 'graphql-tag'

export const SIGN_UP = gql`
  mutation signUpMutation(
    $firstName: String!
    $email: String!
    $org: String!
    $referral: String
    $timezone: String
  ) {
    signUpOrg(
      signUpData: {
        firstName: $firstName
        email: $email
        orgName: $org
        referral: $referral
        timezone: $timezone
      }
    )
  }
`

export const SIGN_UP_WITH_SLACK = gql`
  mutation signUpWithSlack(
    $code: String!
    $redirectUrl: String!
    $timezone: String
  ) {
    signUpWithSlack(
      authorizationCode: $code
      redirectUrl: $redirectUrl
      timeZone: $timezone
    )
  }
`

export const GET_SLACK_SIGN_UP_LINK = gql`
  query signUpWithSlackUrl($redirectUrl: String) {
    signUpWithSlackUrl(redirectUrl: $redirectUrl)
  }
`
