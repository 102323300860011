import Avatar, { AvatarName } from '../../../_components/Avatar'

import { Form } from 'antd'
import React from 'react'
import TextAreaFormItem from '../../../_components/Form/TextAreaFormItem'
import Visibility from '../Visibility'

const AnswerForm = ({
  form,
  recipient,
  defaultValues = {},
  onChange,
  disabled,
}) => {
  const { getFieldDecorator } = form

  const handleOnChange = fieldName => {
    if (onChange) {
      // Waiting for the next tick before checking the values of the form is the only way I've found to
      // get the latest form values without forcing the form to validate itself.
      // Feels like a hack, so let me know if you know of a a better way to do this
      setTimeout(() => {
        onChange({
          lastChangedField: fieldName,
          values: form.getFieldsValue(),
        })
      })
    }
  }

  return (
    <Form colon={false} disabled={disabled}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '12px',
        }}
      >
        <div style={{ marginRight: '12px' }}>
          <Avatar user={recipient} disableTooltip={true}></Avatar>
        </div>
        <AvatarName user={recipient}></AvatarName>
        <Form.Item style={{ marginBottom: 0 }}>
          {getFieldDecorator('visibility', {
            initialValue: defaultValues.visibility
              ? defaultValues.visibility
              : 'public',
            rules: [{ required: true, message: 'This field is required!' }],
          })(
            <Visibility.InputButton
              onChange={() => handleOnChange('visibility')}
              disabled={disabled}
            />,
          )}
        </Form.Item>
      </div>
      <div
        style={{
          fontSize: '16px',
          fontWeight: 300,
          color: '#1c1047',
        }}
      >
        <TextAreaFormItem
          min={2}
          max={500}
          required={true}
          placeholder="Type your answer here"
          name="answer"
          form={form}
          initialValue={defaultValues.answer ? defaultValues.answer : undefined}
          onChange={() => handleOnChange('answer')}
          disabled={disabled}
        />
      </div>
    </Form>
  )
}

export default Form.create({ name: 'ResponseForm' })(AnswerForm)
