import React from 'react'

import Checkbox from './Checkbox'

const ListGroup = ({
  title,
  selected,
  enableSelect = true,
  onSelected,
  onDeselected,
  selectedText = 'Select team',
  deselectedText = 'Deselect team',
  height = 56,
  style,
}) => {
  return (
    <div style={style}>
      <div
        style={{
          display: 'flex',
          paddingLeft: 24,
          paddingRight: 24,
          height,
          alignItems: 'center',
        }}
      >
        <div
          style={{
            fontSize: 16,
            color: '#1c1047',
          }}
        >
          {title}
        </div>
        {enableSelect && (
          <div style={{ marginLeft: 'auto', cursor: 'pointer' }}>
            <Checkbox
              selected={selected}
              onSelect={onSelected}
              onDeselect={onDeselected}
              selectedText={selectedText}
              deselectedText={deselectedText}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ListGroup
