import Avatar, { AvatarName } from '../../../_components/Avatar'

import React from 'react'

const Participant = ({ user, avatarText }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ marginRight: '12px', display: 'flex' }}>
        <div style={{ marginRight: '12px' }}>
          <Avatar
            user={user}
            disableTooltip={true}
            fallbackText={avatarText}
          ></Avatar>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>
            <AvatarName user={user}></AvatarName>
            {user.jobTitle && (
              <p
                style={{ color: '#6b6b8f', fontSize: '16px', marginBottom: 0 }}
              >
                {user.jobTitle}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Participant
