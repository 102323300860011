import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, notification } from 'antd'
import React, { useContext, useState } from 'react'

import { UserContext } from '../UserContext'
import AddPeopleModal from './AddPeopleModal'

const InvitePeopleButton = ({
  onInvited,
  onClick,
  style,
  forceEmailInvite,
}) => {
  const currentUser = useContext(UserContext)

  const canInvite = currentUser.type === 'admin' || currentUser.type === 'owner'

  const [modalVisible, setModalVisible] = useState(false)

  const onModalSuccess = invitedUsers => {
    let message
    let grammaticalNumberText

    if (invitedUsers.length > 1) {
      message = 'Invite sent!'
      grammaticalNumberText = 'invites were'
    } else {
      message = 'Invites sent!'
      grammaticalNumberText = 'invite was'
    }

    notification.success({
      message: message,
      description: `${invitedUsers.length} new ${grammaticalNumberText} just sent!`,
    })

    setModalVisible(false)

    if (onInvited) {
      onInvited(invitedUsers)
    }
  }

  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(style ? style : undefined),
  }

  if (!canInvite) return null

  return (
    <>
      <Button
        type="ghost"
        style={buttonStyle}
        onClick={() => {
          setModalVisible(true)
          if (onClick) {
            onClick()
          }
        }}
      >
        <FontAwesomeIcon
          icon={['fal', 'plus']}
          style={{ width: '1em', marginRight: 8 }}
        />
        Add people
      </Button>
      <AddPeopleModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        onSuccess={onModalSuccess}
        forceEmailInvite={forceEmailInvite}
      />
    </>
  )
}

export default InvitePeopleButton
