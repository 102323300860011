import { Button, Icon } from 'antd'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as pencilIcon } from '../../../_assets/img/pencilDark.svg'
import { track } from '../../../_helpers/analytics'
import { UserContext } from '../../../UserContext'
import { getCycleBaseUrl } from '../../pages/helpers'

export default ({ cycle }) => {
  const user = useContext(UserContext)

  const cyclePath = getCycleBaseUrl(cycle.type)

  return (
    <Button
      type="link"
      style={{ color: '#281765', fontWeight: 500, paddingRight: 0 }}
    >
      <Link
        to={`${cyclePath}/edit/${cycle.id}/1?skipToConfirmStep=1`}
        onClick={() => {
          track(cycle.type + '.cycle.edit', {
            user: user.email,
            org: user.org.name,
            cycleId: cycle.id,
            startDate: cycle.startDate,
          })
        }}
      >
        <span
          style={{
            width: '24px',
            height: '24px',
            borderRadius: '8px',
            backgroundColor: '#f1f1f5',
            marginRight: '8px',
            display: 'inline-block',
          }}
        >
          <Icon
            component={pencilIcon}
            style={{
              fontSize: '12px',
              verticalAlign: 'middle',
              lineHeight: 1,
            }}
          />
        </span>
        Edit event
      </Link>
    </Button>
  )
}
