import gql from 'graphql-tag'

import { CYCLE_TYPES } from '../helpers'

const formObject = `
    id
    title
    recipients
    description
    sections
    category {
      id
      name
      description
      default
    }
    formQuestions {
      id
      question {
        id
        titleVariants
        description
        template {
          id
          type
          settings
        }
      }
      isSentimentAnalysisActivated
    }
    isSentimentAnalysisActivated
`

export const GET_FORMS = gql`
  query defaultForms($type: String!) {
    defaultForms(type: $type) {
      ${formObject}
    }
    formsFromOrg(type: $type) {
      ${formObject}
    }
  }
`

export const GET_USERS_TOTAL = gql`
  query getUsersTotal {
    users(
      search: { types: ["invited", "onboarding", "standard", "admin", "owner"] }
    ) {
      total
    }
  }
`

export const CREATE_CYCLE_PERSONAL = gql`
  mutation createPrivateFeedbackEvent(
    $name: String!
    $formId: String!
    $timezone: String!
    $startDate: Int!
    $endDate: Int!
    $userSelection: UserSelection!
  ) {
    createPrivateFeedbackEvent(
      name: $name
      formId: $formId
      timeZone: $timezone
      startDate: $startDate
      endDate: $endDate
      userSelection: $userSelection
    ) {
      id
    }
  }
`

export const UPDATE_CYCLE_PERSONAL = gql`
  mutation editScheduledPrivateFeedbackEvent(
    $cycleId: ID!
    $name: String
    $formId: ID
    $userSelection: UserSelection!
    $startDate: Int
    $endDate: Int
    $timezone: String
  ) {
    editScheduledPrivateFeedbackEvent(
      id: $cycleId
      name: $name
      formId: $formId
      userSelection: $userSelection
      startDate: $startDate
      endDate: $endDate
      timeZone: $timezone
    ) {
      id
    }
  }
`

export const GET_CYCLE_PERSONAL = gql`
  query getPrivateFeedbackEvent($id: ID!) {
    cycle: getPrivateFeedbackEvent(id: $id) {
      id
      name
      startDate
      endDate
      timeZone
      form {
        id
        title
      }
      requests {
        id
        askedTo {
          id
          firstName
          lastName
          avatarUrl
          team {
            id
          }
        }
      }
      reviewId
    }
  }
`

export const CREATE_CYCLE_THREESIXTY = gql`
  mutation createThreeSixtyFeedbackEvent(
    $name: String!
    $formId: String!
    $timezone: String!
    $startDate: Int!
    $endDate: Int!
    $userSelection: UserSelection!
    $minRequests: Int!
    $maxRequests: Int!
  ) {
    createThreeSixtyFeedbackEvent(
      name: $name
      formId: $formId
      timeZone: $timezone
      startDate: $startDate
      endDate: $endDate
      minRequestsPerPerson: $minRequests
      maxRequestsPerPerson: $maxRequests
      userSelection: $userSelection
    ) {
      id
    }
  }
`

export const UPDATE_CYCLE_THREESIXTY = gql`
  mutation editScheduledThreeSixtyFeedbackEvent(
    $cycleId: ID!
    $name: String
    $startDate: Int
    $endDate: Int
    $formId: ID
    $userSelection: UserSelection!
    $minRequests: Int
    $maxRequests: Int
    $timezone: String
  ) {
    editScheduledThreeSixtyFeedbackEvent(
      id: $cycleId
      name: $name
      formId: $formId
      timeZone: $timezone
      startDate: $startDate
      endDate: $endDate
      minRequestsPerPerson: $minRequests
      maxRequestsPerPerson: $maxRequests
      userSelection: $userSelection
    ) {
      id
    }
  }
`

export const GET_CYCLE_THREESIXTY = gql`
  query getThreeSixtyFeedbackEvent($id: ID!) {
    cycle: getThreeSixtyFeedbackEvent(id: $id) {
      id
      name
      startDate
      endDate
      timeZone
      minRequests
      maxRequests
      form {
        id
        title
      }
      enrolled {
        id
        firstName
        lastName
        avatarUrl
        teamId
      }
    }
  }
`

export const CREATE_CYCLE_PULSE = gql`
  mutation createPulseSurvey(
    $name: String!
    $formId: String!
    $timezone: String!
    $startDate: Int!
    $endDate: Int!
    $userSelection: UserSelection!
  ) {
    createPulseSurvey(
      name: $name
      formId: $formId
      timeZone: $timezone
      startDate: $startDate
      endDate: $endDate
      userSelection: $userSelection
    ) {
      id
    }
  }
`

export const UPDATE_CYCLE_PULSE = gql`
  mutation editScheduledPulseSurvey(
    $cycleId: ID!
    $name: String
    $formId: ID
    $userSelection: UserSelection!
    $startDate: Int
    $endDate: Int
    $timezone: String
  ) {
    editScheduledPulseSurvey(
      id: $cycleId
      name: $name
      formId: $formId
      userSelection: $userSelection
      startDate: $startDate
      endDate: $endDate
      timeZone: $timezone
    ) {
      id
    }
  }
`

export const GET_CYCLE_PULSE = gql`
  query getPulseSurvey($id: ID!) {
    cycle: getPulseSurvey(id: $id) {
      id
      name
      startDate
      endDate
      timeZone
      form {
        id
        title
      }
      requests {
        id
        askedTo {
          id
          team {
            id
          }
        }
      }
    }
  }
`

export default {
  GET_FORMS,
  GET_USERS_TOTAL,
  [CYCLE_TYPES.personal]: {
    GET: GET_CYCLE_PERSONAL,
    CREATE: CREATE_CYCLE_PERSONAL,
    UPDATE: UPDATE_CYCLE_PERSONAL,
  },
  [CYCLE_TYPES.threeSixty]: {
    GET: GET_CYCLE_THREESIXTY,
    CREATE: CREATE_CYCLE_THREESIXTY,
    UPDATE: UPDATE_CYCLE_THREESIXTY,
  },
  [CYCLE_TYPES.pulse]: {
    GET: GET_CYCLE_PULSE,
    CREATE: CREATE_CYCLE_PULSE,
    UPDATE: UPDATE_CYCLE_PULSE,
  },
}
