import { Button, Input, Modal, Radio, Typography, notification } from 'antd'
import React, { useContext, useState } from 'react'

import IntegrationsContext from '../context'

const { Paragraph } = Typography

const RemoveIntegrationModal = ({ visible, setVisible, onOk }) => {
  const [reason, setReason] = useState()
  const [input, setInput] = useState()
  const [loading, setLoading] = useState(false)

  const onCancel = () => {
    setReason()
    setInput()
    setVisible(false)
  }

  const onSubmit = () => {
    if (reason) {
      setLoading(true)
      if (reason === 'radio_input') {
        onOk(input).finally(() => setLoading(false))
      } else {
        onOk(reason).finally(() => setLoading(false))
      }
    } else {
      notification.error({
        description: 'Please select a reason',
        message: `Uh-oh!`,
      })
    }
  }

  return (
    <Modal
      className="deactivate-slack-modal"
      title="Remove Slack integration"
      visible={visible}
      onCancel={onCancel}
      onOk={onSubmit}
      okText="Remove Slack integration"
      cancelText="Cancel"
      okButtonProps={{ loading, type: 'accent' }}
      cancelButtonProps={{ type: 'ghost' }}
      bodyStyle={{ fontWeight: 300, padding: '32px' }}
      centered
    >
      <Paragraph style={{ marginBottom: 16 }}>
        No biggie, we'll step out of Slack in a second. While we pack our
        things, please help us understand why you don't want us there anymore:
      </Paragraph>
      <Radio.Group onChange={e => setReason(e.target.value)} value={reason}>
        <Radio value="Too many notifications, guys">
          Too many notifications, guys
        </Radio>
        <Radio value="It's not you, it's us. We don't use Slack anymore">
          It's not you, it's us. We don't use Slack anymore
        </Radio>
        <Radio value="radio_input">
          <Input
            placeholder="Other reasons? Type ‘em here."
            onChange={e => setInput(e.target.value)}
            onFocus={() => setReason('radio_input')}
          />
        </Radio>
      </Radio.Group>
    </Modal>
  )
}

export default () => {
  const integrationsContext = useContext(IntegrationsContext)

  const [modalVisible, setModalVisible] = useState(false)

  const slackIntegration =
    integrationsContext.getIntegrations() &&
    integrationsContext.getIntegrations()['slack']

  if (!slackIntegration) return null

  const deactivateSlackIntegration = reason => {
    return integrationsContext
      .deactivateSlackIntegration({ reason })
      .then(data => {
        if (
          data &&
          data.deactivateSlackIntegration &&
          data.deactivateSlackIntegration.success === false
        ) {
          notification.error({
            description: 'There was an error. Please try again',
            message: `That didn't work out as planned`,
          })
        } else {
          notification.success({
            description: 'Integration? What integration?',
            message: `Doneburger!`,
          })
        }
      })
      .catch(() => {
        notification.error({
          description: 'There was an error. Please try again',
          message: `That didn't work out as planned`,
        })
      })
  }

  switch (slackIntegration.status) {
    case 'inactive':
    case 'deactivated':
      return (
        <a href={slackIntegration.installUrl}>
          <Button type="primary">Add to Slack</Button>
        </a>
      )

    case 'active':
      return (
        <span>
          <Button type="ghost" onClick={() => setModalVisible(true)}>
            Remove integration
          </Button>
          <RemoveIntegrationModal
            visible={modalVisible}
            setVisible={state => setModalVisible(state)}
            onOk={deactivateSlackIntegration}
          />
        </span>
      )

    default:
      return undefined
  }
}
