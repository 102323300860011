import { Button } from 'antd'
import React, { useState } from 'react'

export const OPTIONS = {
  asGatherer: 'feedbackGatherer',
  asGiver: 'feedbackGiver',
}

export default ({ onChange }) => {
  const [selectedOption, setSelectedOption] = useState(OPTIONS.asGatherer)

  const handleContentChange = option => {
    setSelectedOption(option)
    onChange(option)
  }

  return (
    <div style={{ marginBottom: '16px' }}>
      <Button
        onClick={() => handleContentChange(OPTIONS.asGatherer)}
        type="link"
        style={{ marginRight: '12px' }}
        className={`up-toggle__button ${
          selectedOption === OPTIONS.asGatherer && 'up-toggle__button--active'
        }`}
      >
        Stats as feedback gatherer
      </Button>
      <Button
        onClick={() => handleContentChange(OPTIONS.asGiver)}
        type="link"
        className={`up-toggle__button ${
          selectedOption === OPTIONS.asGiver && 'up-toggle__button--active'
        }`}
      >
        Stats as feedback giver
      </Button>
    </div>
  )
}
