import { Form } from 'antd'
import React from 'react'
import TextAreaFormItem from '../../../_components/Form/TextAreaFormItem'

const ReportAbuseForm = ({ form, defaultValues = {} }) => {
  return (
    <Form
      layout="vertical"
      hideRequiredMark={true}
      colon={false}
      className="report-abuse-form"
    >
      <TextAreaFormItem
        name="abuse"
        label="Why are you reporting this?"
        placeholder="Type your reason here"
        initialValue={defaultValues.abuse ? defaultValues.abuse : ''}
        required={true}
        max={280}
        form={form}
      />
    </Form>
  )
}

export default Form.create({ name: 'ReportAbuseForm' })(ReportAbuseForm)
