import { Button } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

import AvatarPlusList from '../../../../_components/AvatarPlusList'
import { VERTICAL_MARGIN_DOUBLE } from '../CONSTANTS'

const CONTAINER_STYLE = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const LABEL_STYLE = {
  marginBottom: VERTICAL_MARGIN_DOUBLE,
  color: '#77779f',
}

export default ({ feedbackRequests, reviewId }) => {
  return (
    <div style={CONTAINER_STYLE}>
      <div>
        <div style={LABEL_STYLE}>
          {feedbackRequests.length + ' '}{' '}
          {feedbackRequests.length > 1 ? 'people' : 'person'} gave you feedback
        </div>
        <AvatarPlusList
          users={feedbackRequests.map(x => x.askedTo)}
          limit={6}
        />
      </div>

      <Link to={'/personal360/viewFeedback/' + reviewId}>
        <Button type="light-purple">View feedback</Button>
      </Link>
    </div>
  )
}
