import { Form, Icon, Input, Select } from 'antd'
import moment from 'moment'
import React, { useMemo } from 'react'

import useTeams from '../../hooks/graphql/Teams/useTeams'
import Loader from '../Loader'

const LandingPageSearchForm = ({
  form,
  defaultValues = { personName: '', teamId: '', endDate: '' },
  loading,
  onSubmit,
}) => {
  const { data: teamsData, loading: teamsLoading } = useTeams({
    noTeamName: 'Not part of a team',
    variables: {
      userTypes: ['invited', 'active'],
    },
  })

  const now = moment.utc()

  const endDateOptions = useMemo(() => {
    return [
      {
        label: 'All time',
        value: '',
      },
      {
        label: 'Last 12 months',
        value: now.clone().subtract(12, 'months').format('YYYY-MM-DD'),
      },
      {
        label: 'Last 6 months',
        value: now.clone().subtract(6, 'months').format('YYYY-MM-DD'),
      },
      {
        label: 'Last 3 months',
        value: now.clone().subtract(3, 'months').format('YYYY-MM-DD'),
      },
      {
        label: 'Last month',
        value: now.clone().subtract(1, 'month').format('YYYY-MM-DD'),
      },
    ]
  }, [now.format('YYYY-MM-DD')])

  const handleOnChange = () => {
    const formValues = form.getFieldsValue()
    if (onSubmit) {
      onSubmit(formValues)
    }
  }

  return (
    <Form
      layout="horizontal"
      hideRequiredMark={true}
      colon={false}
      style={{ display: 'flex' }}
    >
      <Form.Item style={{ marginRight: 12 }}>
        {form.getFieldDecorator('personName', {
          initialValue: defaultValues.personName,
        })(
          <Input
            placeholder="Search people"
            prefix={<Icon type="search" style={{ color: '#77779f' }} />}
            style={{ width: 240 }}
            onChange={() => setTimeout(handleOnChange)}
          />,
        )}
      </Form.Item>
      <Form.Item style={{ marginRight: 12 }}>
        {form.getFieldDecorator('teamId', {
          initialValue: defaultValues.teamId,
        })(
          <Select
            disabled={teamsLoading}
            style={{ width: 192 }}
            onChange={() => setTimeout(handleOnChange)}
          >
            <Select.Option key="all-teams" value="">
              All teams
            </Select.Option>
            {teamsData &&
              teamsData.teams &&
              teamsData.teams.map(team => (
                <Select.Option key={team.id} value={team.id}>
                  {team.name}
                </Select.Option>
              ))}
          </Select>,
        )}
      </Form.Item>
      <Form.Item style={{ marginRight: 12 }}>
        {form.getFieldDecorator('endDate', {
          initialValue: defaultValues.endDate || endDateOptions[0].value,
        })(
          <Select
            style={{ width: 192 }}
            onChange={() => setTimeout(handleOnChange)}
          >
            {endDateOptions.map(option => (
              <Select.Option key={option.label} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>,
        )}
      </Form.Item>
      {loading && <Loader text="" style={{ marginTop: 17 }} />}
    </Form>
  )
}

export default Form.create({ name: 'LandingPageSearchForm' })(
  LandingPageSearchForm,
)
