import gql from 'graphql-tag'

export const GET_COMPANY_USERS = gql`
  query {
    users {
      id
      status
      firstName
      lastName
      email
      emailStatus
      jobTitle
      avatarUrl
      role
      manager {
        id
        firstName
        lastName
      }
      viewingManagers {
        id
        firstName
        lastName
      }
      type
      isOwner
      isAdmin
      team {
        id
        name
        members {
          id
          user {
            id
            firstName
            lastName
            email
            status
          }
        }
      }
    }
  }
`

export const EDIT_USER = gql`
  mutation updateUserInfo(
    $id: ID!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $teamId: String
    $role: String
    $managerId: String
    $viewingManagerIds: [String!]
    $directReportIds: [String!]
    $email: String!
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      jobTitle: $jobTitle
      teamId: $teamId
      role: $role
      managerId: $managerId
      viewingManagerIds: $viewingManagerIds
      directReportIds: $directReportIds
      email: $email
    ) {
      id
      firstName
      lastName
      jobTitle
      email
      emailStatus
      team {
        id
        name
      }
      manager {
        id
        firstName
        lastName
        email
      }
      viewingManagers {
        id
        firstName
        lastName
        email
      }
      directReports {
        id
        firstName
        lastName
        email
      }
    }
  }
`

export const SEND_INVITE = gql`
  mutation inviteUsers($userIds: [String!]!) {
    inviteUsers(userIds: $userIds) {
      id
    }
  }
`
