import 'whatwg-fetch'
import 'croppie/croppie.css'

import './_assets/fontawesome-loader'

import { ApolloProvider } from '@apollo/react-hooks'
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory'
import { ApolloClient } from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createUploadLink } from 'apollo-upload-client'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { Intercom } from './_helpers/analytics'
import { IntegrationsProvider } from './Admin/Integrations/context'
import App from './App'
import AppRedirectV2 from './AppRedirectV2'
import * as serviceWorker from './serviceWorker'
import { default as UserContextProvider } from './UserContext'

// This import needs to happen after App is imported. That way we make sure our LESS loads after AntDesign
import './_assets/less/app.less'

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Cycle':
        if (object.managerCycleId) {
          return `ManagerCycle:${object.managerCycleId}`
        }
        if (object.overviewCycleId) {
          return `OverviewCycle:${object.overviewCycleId}`
        }
        return `${defaultDataIdFromObject(object)}`

      case 'ThreeSixtyEnrolledUser':
        return object.reviewId

      default:
        return `${defaultDataIdFromObject(object)}`
    }
  },
})

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_BASE_URL}/graphql`,
})

export const apiLink = path => {
  return `${process.env.REACT_APP_API_ENDPOINT}${path}`
}

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  if (localStorage['HAIGToken']) {
    const token = localStorage['HAIGToken']
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? token : '',
      },
    }
  }
})

const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

const Root = () => {
  useEffect(() => {
    Intercom.load()
    Intercom.boot()
  }, [])

  return (
    <ApolloProvider client={client}>
      <UserContextProvider>
        <IntegrationsProvider>
          <Router>
            <App />
          </Router>
        </IntegrationsProvider>
      </UserContextProvider>
    </ApolloProvider>
  )
}

ReactDOM.render(
  process.env.REACT_APP_V2_REDIRECT_PAGE === 'true' ? (
    <AppRedirectV2 />
  ) : (
    <Root />
  ),
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
