import { Card, Icon } from 'antd'
import React from 'react'

const DEFAULT_SPACING = 8

export default () => {
  return (
    <Card
      className="top-title"
      style={{ marginBottom: DEFAULT_SPACING * 2, border: 'none' }}
    >
      <div
        style={{
          fontSize: 18,
          color: '#1c1047',
          marginBottom: DEFAULT_SPACING * 0.5,
        }}
      >
        <Icon
          type="eye-invisible"
          style={{
            color: '#f95c4b',
            marginRight: DEFAULT_SPACING,
            fontSize: 20,
          }}
        />
        This is a safe place:
      </div>
      <div
        style={{
          fontWeight: 300,
          marginBottom: 0,
          fontSize: 16,
          color: '#1c1047',
        }}
      >
        Your responses will not have your name attached to them and will be
        mixed amongst other people's responses.
      </div>
    </Card>
  )
}
