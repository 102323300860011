import React from 'react'

import InfoBanner from '../../../_components/InfoBanner'

export default ({ title, smallTitle, withInfo, withInfoTooltip, children }) => {
  return (
    <div className="up-section">
      <div className="up-section__title-container">
        <div
          className={`up-section__title ${
            smallTitle && 'up-section__title--small'
          }`}
        >
          {title}
        </div>
        {withInfo && withInfoTooltip && (
          <span>
            <InfoBanner info={withInfo} withTooltip={withInfoTooltip} />
          </span>
        )}
      </div>
      {children}
    </div>
  )
}
