import { Button, Icon, Tooltip } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'

import AvatarPlusList from '../../../../_components/AvatarPlusList'
import Overview from '../../../modals/Overview/pulse'
import { isScheduledCycle } from '../../helpers'

export default ({ cycle }) => {
  const [showModal, setShowModal] = useState(false)

  const isScheduled = isScheduledCycle(cycle.startDate)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 16,
      }}
    >
      <div>
        <div
          style={{
            fontFamily: 'museo',
            fontWeight: 300,
            marginBottom: 16,
            color: '#281765',
          }}
        >
          You surveyed {cycle.requests.length}{' '}
          {cycle.requests.length === 1 ? 'person' : 'people'} for feedback
        </div>
        <AvatarPlusList users={cycle.requests.map(x => x.askedTo)} limit={6} />
        {!isScheduled && (
          <div>
            <Button
              type="link"
              style={{
                alignItems: 'center',
                display: 'flex',
                fontSize: 14,
                color: '#f95c4b',
                padding: '6px 0 0 0',
                height: 'auto',
              }}
              onClick={() => setShowModal(true)}
            >
              <Icon type="eye" style={{ fontSize: 16 }} />
              <span
                style={{
                  lineHeight: '22px',
                }}
              >
                See how they're going
              </span>
            </Button>
            <Overview
              cycle={cycle}
              showModal={showModal}
              handleCloseModal={() => setShowModal(false)}
            />
          </div>
        )}
      </div>

      <div>
        <Tooltip
          title={
            <div>
              So long as 2+ people answer, your survey results will be available
              to view on{' '}
              <span style={{ color: '#281765' }}>
                {moment
                  .unix(cycle.endDate)
                  // .tz(cycle.settings.timezone)
                  .format('DD MMM YYYY')}
              </span>
              .
            </div>
          }
        >
          <Button type="ghost" disabled>
            View answers
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}
