import { useQuery } from '@apollo/react-hooks'
import React, { useEffect, useState } from 'react'
import Emoji from 'react-emoji-render'
import { useLocation } from 'react-router-dom'

import Pagination from '../../../../_components/Pagination'
import CyclesList from '../../../components/CyclesList'
import { CYCLE_TYPES, getCycleTypeFromUrl } from '../../helpers'
import queries from '../../queries'
import Title from '../Title'

const PAGE_SIZE = 10
const getOffset = pageNumber => pageNumber * PAGE_SIZE - PAGE_SIZE

export default ({ menu }) => {
  const location = useLocation()

  const [cycles, setCycles] = useState(null)

  const cycleType = getCycleTypeFromUrl(location)
  const [pageNumber, setPageNumber] = useState(1)

  const { data, loading, error } = useQuery(
    queries[cycleType].GET_SCHEDULED_CYCLES,
    {
      variables: {
        offset: getOffset(pageNumber),
        limit: PAGE_SIZE,
      },
    },
  )

  useEffect(() => {
    if (data && data.cycles && data.cycles.items) {
      setCycles(
        data.cycles.items.map(x => {
          return {
            ...x,
            type: cycleType,
          }
        }),
      )
    }
  }, [data])

  const handleDelete = cycleId => {
    const cycleIndex = cycles.findIndex(cycle => cycle.id === cycleId)

    if (cycleIndex > -1) {
      const newCycles = [...cycles]
      newCycles.splice(cycleIndex, 1)
      setCycles(newCycles)
    }
  }

  const onPageChange = newPageNumber => {
    setPageNumber(newPageNumber)
    window.scroll({
      top: 0,
    })
  }

  return (
    <div className="cycles">
      <Title cycleType={cycleType} />

      {menu}

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error!</p>
      ) : (
        <>
          {cycles && cycles.length > 0 ? (
            <>
              <CyclesList cycles={cycles} handleDelete={handleDelete} />
              {data.cycles.total > PAGE_SIZE && (
                <Pagination
                  current={pageNumber}
                  onChange={onPageChange}
                  total={data.cycles.total}
                  pageSize={PAGE_SIZE}
                  disabled={loading}
                  itemsName={
                    cycleType === CYCLE_TYPES.pulse ? 'surveys' : 'events'
                  }
                />
              )}
            </>
          ) : (
            <div
              style={{
                color: '#77779f',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 300,
              }}
            >
              <div style={{ margin: '16px 0 0' }}>
                Nothing to see here.
                <Emoji text={':shrug:'} style={{ fontSize: '24px' }} />
              </div>
              <div>To get the ball rolling please create a new event.</div>
            </div>
          )}
        </>
      )}
    </div>
  )
}
