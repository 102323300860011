import gql from 'graphql-tag'

export const DELETE_CYCLE = gql`
  mutation removeCycle($cycleId: ID!) {
    cancelThreeSixtyFeedbackEvent(id: $cycleId) {
      id
    }
  }
`

export const DELETE_CYCLE_PERSONAL = gql`
  mutation removeCyclePersonal($cycleId: ID!) {
    cancelPrivateFeedbackEvent(id: $cycleId) {
      id
    }
  }
`

export const DELETE_CYCLE_PULSE = gql`
  mutation removeCyclePersonal($cycleId: ID!) {
    cancelPulseSurvey(id: $cycleId) {
      id
    }
  }
`
